// MUI Modal v5: #root overflow-x,y will cause scrollbar position jump to page top after modified modal closing.
export const globalStyles = {
  '*::-webkit-scrollbar': {
    width: '1rem',
  },
  '*::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    borderRadius: '10px',
  },
  'html, #root': {
    backgroundColor: 'rgba(248, 248, 248, 0.4)',
  },
};
