import React from 'react';

import Box, { BoxProps } from '@mui/material/Box';

const CenterLeftBox = React.forwardRef<HTMLDivElement, BoxProps>(function CenterLeftBox(
  { sx, ...props },
  ref,
) {
  const _sx = Array.isArray(sx) ? sx : [sx];

  return (
    <Box
      ref={ref}
      sx={[
        {
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        },
        ..._sx,
      ]}
      {...props}
    />
  );
});

CenterLeftBox.displayName = 'CenterLeftBox';
export default CenterLeftBox;
