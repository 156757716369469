import { useRef } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';

import ToolsMenu, { ToolsMenuChildProps } from './ToolsMenu';
import { useStyle } from '../../../../context/StyleContext';
import BorderWidthOption from '../../../border/BorderWidthOption';

const StrokeWidthMenu = ({ subMenuFuncArr, popperRef }: ToolsMenuChildProps) => {
  const { selected } = useStyle();
  const lastStrokeWidthRef = useRef(0);

  return (
    <ToolsMenu
      subMenuFuncArr={subMenuFuncArr}
      label={
        <>
          <ListItemText primary="Stroke Width" />
          <ListItemIcon sx={{ minWidth: 'auto' }}>
            <NavigateNextIcon />
          </ListItemIcon>
        </>
      }
      refreshSubMenu={(() => {
        const currentBorderWidth = Number.parseFloat(`${selected?.style.borderWidth}`);
        const adjustNum = Math.abs(lastStrokeWidthRef.current - currentBorderWidth);

        return (
          Number.isFinite(currentBorderWidth) &&
          (lastStrokeWidthRef.current = adjustNum) >= 0 &&
          adjustNum > 1
        );
      })()}
      height={64}
      popperRef={popperRef}
    >
      <Paper>
        <List dense>
          <ListItem>
            <BorderWidthOption />
          </ListItem>
        </List>
      </Paper>
    </ToolsMenu>
  );
};

export default StrokeWidthMenu;
