import React from 'react';

import { arrayMove } from '@dnd-kit/sortable';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assign, cloneDeep, merge } from 'lodash-es';
import undoable, { excludeAction } from 'redux-undo';
import { v4 as uuidv4 } from 'uuid';

import { LayoutsType } from '../../admin/editStyle/components/SideLayout';
import { getDraggableIndexById } from '../../admin/editStyle/utils/draggables';
import {
  DraggableType,
  DraggableTag,
  DraggableAnswer,
  Layout,
  Copyright,
} from '../../graphql/resolver.types';
import { ToolSideLayoutsType } from '../../toolBar/component/ToolSideLayout';

export interface DraggableImage {
  id: string;
  imageUrl: string;
  name: string | null;
  copyright?: Copyright;
}
export interface DraggableAudio {
  id: string;
  audioUrl: string;
}
export interface DraggableVideo {
  id: string;
  videoUrl: string;
}

export interface Draggable {
  id: string;
  name: string;
  type: DraggableType;
  tags: Array<DraggableTag>;
  style: React.CSSProperties;
  lockAspectRatio: boolean;
  answer?: DraggableAnswer;
  image?: DraggableImage;
  audio?: DraggableAudio;
  video?: DraggableVideo;
  group?: Draggable[];
  page?: number; // local use, for group of group, zero-indexed
} // TODO: refactor draggable interface

export interface DraggableProperty extends Partial<Draggable> {
  type: DraggableType;
  tags: Array<DraggableTag>;
  lockAspectRatio: boolean;
} // For parameter passing

export interface AddDraggableToGroupInput {
  id: string;
  newDraggable: Draggable;
  offset?: number;
}

export interface AddGroupToGroupInput {
  id: string;
  type?: DraggableType;
}

export interface UpdateDraggableInput {
  id: string;
  name?: string;
  style?: React.CSSProperties;
  lockAspectRatio?: boolean;
  answer?: DraggableAnswer;
  page?: number; // for group of group
  image?: DraggableImage;
  audio?: DraggableAudio;
  video?: DraggableVideo;
  group?: Draggable[];
} // TODO: refactor ;draggable interface

export interface ScalingGroupInput {
  id: string;
  widthScaling: number;
  heightScaling: number;
}

export interface DeleteDraggableInput {
  id: string;
}

export interface LayerSwapInput {
  from: string;
  to: string;
}

export interface Selected {
  id?: string;
  clickedArea?: ClickedArea;
}

export interface Container {
  width?: number;
  height?: number;
}

export enum AnswerOptionsCategory {
  Image = 'image',
  Text = 'text',
  Other = 'other',
}

export enum ColorPickerMode {
  Color = 'color',
  BackgroundColor = 'backgroundColor',
  BorderColor = 'borderColor',
}

export enum ClickedArea {
  Style = 'style',
  Layers = 'layers',
}

export enum StyleMode {
  Editing = 'editing',
  Teaching = 'teaching',
  Preparing = 'preparing',
}

export interface Style {
  styleId: string;
  name: string;
  layout: Layout; // default style is for laptop
  selected: { [Layout.Landscape]?: Selected;[Layout.Portrait]?: Selected };
  counter: {
    [Layout.Landscape]: { [key: string]: number };
    [Layout.Portrait]: { [key: string]: number };
  };
  draggables: {
    [Layout.Landscape]: Draggable[];
    [Layout.Portrait]: Draggable[];
  };
  notes: string;
  skillIds: Array<string>;
  subskillIds: Array<string>;
  container: Container;
  sideLayout: {
    open: boolean;
    type: keyof LayoutsType | keyof ToolSideLayoutsType | '';
    answerOptions: {
      tab: AnswerOptionsCategory;
    };
    colorPicker: {
      mode: ColorPickerMode;
    };
  };
  mode: StyleMode | '';
}

const initialState: Style = {
  styleId: '',
  name: '',
  layout: Layout.Landscape,
  selected: { [Layout.Landscape]: {}, [Layout.Portrait]: {} },
  counter: { [Layout.Landscape]: {}, [Layout.Portrait]: {} },
  draggables: { [Layout.Landscape]: [], [Layout.Portrait]: [] },
  notes: '',
  skillIds: [],
  subskillIds: [],
  container: {},
  sideLayout: {
    open: false,
    type: '',
    answerOptions: {
      tab: AnswerOptionsCategory.Text,
    },
    colorPicker: {
      mode: ColorPickerMode.Color,
    },
  },
  mode: '',
};

// function autoSave(state: any) {
//   localStorage.setItem('styleBackup', JSON.stringify(state));
// }

function updateDraggableFunc(state: any, action: PayloadAction<UpdateDraggableInput>) {
  // Naming convention: first child is "draggable", second child is "drag"
  const { id } = action.payload;
  // recursive find and update target draggable
  function updateTarget(draggables: Draggable[]) {
    return draggables.map((draggable) => {
      if (draggable.id === id) {
        return action.type === 'style/updateDraggableAssign'
          ? assign(draggable, action.payload)
          : merge(draggable, action.payload);
      }
      if ('group' in draggable && draggable.group) {
        draggable.group = updateTarget(draggable.group);
      }
      return draggable;
    });
  }
  // start recursive
  state.draggables[state.layout] = updateTarget(state.draggables[state.layout]);
  // autoSave(state);
}

function scalingGroupFunc(state: any, action: PayloadAction<ScalingGroupInput>) {
  const { id, widthScaling, heightScaling } = action.payload;
  // recursive find and scale target draggable
  function scaleTarget(draggables: Draggable[], isTarget: boolean) {
    return draggables.map((draggable) => {
      let _isTarget = isTarget;
      if (draggable.id === id) {
        _isTarget = true;
      }
      if (
        isTarget &&
        draggable.style?.left &&
        draggable.style?.top &&
        draggable.style?.width &&
        draggable.style?.height
      ) {
        draggable.style.left = `${parseFloat(draggable.style.left as string) * widthScaling
          }%`;
        draggable.style.top = `${parseFloat(draggable.style.top as string) * heightScaling
          }%`;
        draggable.style.width = `${parseFloat(draggable.style.width as string) * widthScaling
          }%`;
        draggable.style.height = `${parseFloat(draggable.style.height as string) * heightScaling
          }%`;
      }
      if ('group' in draggable && draggable.group) {
        draggable.group = scaleTarget(draggable.group, _isTarget);
      }
      return draggable;
    });
  }
  // start recursive
  state.draggables[state.layout] = scaleTarget(state.draggables[state.layout], false);
  // autoSave(state);
}

export const styleSlice = createSlice({
  name: 'style',
  initialState,
  reducers: {
    addDraggable: (state, action: PayloadAction<Draggable | Draggable[]>) => {
      const draggables = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      for (const draggable of draggables) {
        state.draggables[state.layout] = [...state.draggables[state.layout], draggable];
        const counterType = state.counter[state.layout][draggable.type];

        if (!counterType) {
          state.counter[state.layout][draggable.type] = 1; // start from 1, total count
        } else {
          state.counter[state.layout][draggable.type] = counterType + 1;
        }
      }
      // autoSave(state);
    },
    addDraggableToGroup: (state, action: PayloadAction<AddDraggableToGroupInput>) => {
      const { id, newDraggable, offset = 1.5 } = action.payload;
      // recursive find target group and add payload to the group
      function addTargetToGroup(draggables: Draggable[]) {
        return draggables.map((draggable) => {
          if ('group' in draggable && draggable.group) {
            if (draggable.id === id) {
              if (draggable.group.length === 0) {
                if (
                  draggable.style.width &&
                  draggable.style.height &&
                  newDraggable.style.left &&
                  newDraggable.style.top &&
                  newDraggable.style.width &&
                  newDraggable.style.height
                ) {
                  const widthPercent = parseFloat(draggable.style.width as string) / 100;
                  const heightPercent =
                    parseFloat(draggable.style.height as string) / 100;
                  newDraggable.style.left = `${parseFloat(newDraggable.style.left as string) * widthPercent
                    }%`;
                  newDraggable.style.top = `${parseFloat(newDraggable.style.top as string) * heightPercent
                    }%`;
                  newDraggable.style.width = `${parseFloat(newDraggable.style.width as string) * widthPercent
                    }%`;
                  newDraggable.style.height = `${parseFloat(newDraggable.style.height as string) * heightPercent
                    }%`;
                }
                draggable.group = [...draggable.group, newDraggable];
              } else if (draggable.group.length >= 2) {
                const prev1 = draggable.group[draggable.group.length - 1];
                const prev2 = draggable.group[draggable.group.length - 2];
                const gapHorizontal =
                  parseFloat(prev1.style.left as string) -
                  parseFloat(prev2.style.left as string);
                const gapVertical =
                  parseFloat(prev1.style.top as string) -
                  parseFloat(prev2.style.top as string);
                newDraggable.style.left = `${parseFloat(prev1.style.left as string) + gapHorizontal
                  }%`;
                newDraggable.style.top = `${parseFloat(prev1.style.top as string) + gapVertical
                  }%`;
                newDraggable.style.width = prev1.style.width;
                newDraggable.style.height = prev1.style.height;
                draggable.group = [...draggable.group, newDraggable];

                draggable.style.width = `${parseFloat(draggable.style.width as string) + gapHorizontal
                  }%`;
                draggable.style.height = `${parseFloat(draggable.style.height as string) + gapVertical
                  }%`;
              } else {
                const prev = draggable.group[draggable.group.length - 1];
                newDraggable.style.left = `${parseFloat(prev.style.left as string) + offset
                  }%`;
                newDraggable.style.top = `${parseFloat(prev.style.top as string) + offset
                  }%`;
                newDraggable.style.width = prev.style.width;
                newDraggable.style.height = prev.style.height;
                draggable.group = [...draggable.group, newDraggable];
              }
            } else {
              // group of group
              draggable.group = addTargetToGroup(draggable.group);
              if (draggable.tags.includes(DraggableTag.GroupOfGroup)) {
                const width = parseFloat(draggable.style.width as string);
                const height = parseFloat(draggable.style.height as string);
                for (const item of draggable.group) {
                  const childX =
                    parseFloat(item.style.left as string) +
                    parseFloat(item.style.width as string);
                  const childY =
                    parseFloat(item.style.top as string) +
                    parseFloat(item.style.height as string);
                  if (childX > width) {
                    draggable.style.width = `${childX + offset}%`;
                  }
                  if (childY > height) {
                    draggable.style.height = `${childY + offset}%`;
                  }
                }
              }
            }
          }
          return draggable;
        });
      }
      // start recursive
      state.draggables[state.layout] = addTargetToGroup(state.draggables[state.layout]);
      // autoSave(state);
    },
    newGroupToGroup: (state, action: PayloadAction<AddGroupToGroupInput>) => {
      // for group of group
      const { id, type } = action.payload;
      // duplicate prev group and update all with new id
      function recursiveUpdateId(draggables: Draggable[]) {
        return draggables.map((draggable) => {
          draggable.id = uuidv4();
          draggable.name =
            type === DraggableType.ClozeTestAnswerGroup
              ? 'Please enter a cloze test'
              : 'New';

          if ('group' in draggable && draggable.group) {
            draggable.group = recursiveUpdateId(draggable.group);
          }
          return draggable;
        });
      }

      function initNewGroupToGroup(draggables: Draggable[]) {
        return draggables.map((draggable) => {
          if ('group' in draggable && draggable.group) {
            if (draggable.id === id) {
              const newDraggable = cloneDeep(draggable.group[draggable.group.length - 1]);
              newDraggable.id = uuidv4();
              newDraggable.group = recursiveUpdateId(newDraggable?.group ?? []);

              draggable.group = [...draggable.group, newDraggable];
            } else {
              draggable.group = initNewGroupToGroup(draggable.group);
            }
          }
          return draggable;
        });
      }
      // start recursive
      state.draggables[state.layout] = initNewGroupToGroup(
        state.draggables[state.layout],
      );
      // autoSave(state);
    },
    updateDraggable: updateDraggableFunc,
    updateDraggableAssign: updateDraggableFunc,
    updateDraggables2: (state, action: PayloadAction<UpdateDraggableInput[]>) => {
      for (const updateDraggableInput of action.payload) {
        updateDraggableFunc(state, {
          payload: updateDraggableInput,
          type: action.type,
        });
      }
    },
    updateDraggableWithoutHistory: updateDraggableFunc,
    updateDraggables: (state, action: PayloadAction<Draggable[]>) => {
      state.draggables[state.layout] = action.payload;
    },
    updateNotes: (state, action: PayloadAction<string>) => {
      state.notes = action.payload;
    },
    updateSkillIds: (state, action: PayloadAction<string[]>) => {
      state.skillIds = action.payload;
    },
    updateSubskillIds: (state, action: PayloadAction<string[]>) => {
      state.subskillIds = action.payload;
    },
    updateSideLayout: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.sideLayout = { ...state.sideLayout, ...action.payload };
    },
    updateContainer: (state, action: PayloadAction<Container>) => {
      state.container = { ...state.container, ...action.payload };
    },
    updateContainerWithoutHistory: (state, action: PayloadAction<Container>) => {
      state.container = { ...state.container, ...action.payload };
    },
    updateLayout: (state, action: PayloadAction<Layout>) => {
      state.layout = action.payload;
    },
    updateSelected: (state, action: PayloadAction<Selected>) => {
      const backgroundId =
        state.draggables[state.layout]?.[0]?.type === DraggableType.Background
          ? state.draggables[state.layout][0].id
          : null;

      if (backgroundId && backgroundId === action.payload.id) {
        return;
      }

      state.selected[state.layout] = {
        ...state.selected[state.layout],
        ...action.payload,
      };
    },
    updateStyleName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    updateStyleMode: (state, action: PayloadAction<StyleMode>) => {
      state.mode = action.payload;
    },
    updateStyleId: (state, action: PayloadAction<string>) => {
      state.styleId = action.payload;
    },
    scalingGroup: scalingGroupFunc,
    scalingGroupWithoutHistory: scalingGroupFunc,
    layerSwap: (state, action: PayloadAction<LayerSwapInput>) => {
      const { from, to } = action.payload;
      const draggables = state.draggables[state.layout];
      state.draggables[state.layout] = arrayMove(
        draggables,
        getDraggableIndexById(from, draggables),
        getDraggableIndexById(to, draggables),
      );
      // autoSave(state);
    },
    layerToFront: (state, action: PayloadAction<string>) => {
      const draggables = state.draggables[state.layout];
      const hasBackground = draggables?.[0]?.type === DraggableType.Background;
      const index = getDraggableIndexById(action.payload, draggables);
      if ((hasBackground && index > 0) || (!hasBackground && index >= 0)) {
        const fromIndex = index;
        const toIndex = draggables.length - 1;
        if (fromIndex !== toIndex) {
          state.draggables[state.layout] = arrayMove(draggables, fromIndex, toIndex);
          // autoSave(state);
        }
      }
    },
    layerForward: (state, action: PayloadAction<string>) => {
      const draggables = state.draggables[state.layout];
      const hasBackground = draggables?.[0]?.type === DraggableType.Background;
      const index = getDraggableIndexById(action.payload, draggables);
      if (
        ((hasBackground && index > 0) || (!hasBackground && index >= 0)) &&
        index + 1 < draggables.length
      ) {
        state.draggables[state.layout] = arrayMove(draggables, index, index + 1);
        // autoSave(state);
      }
    },
    layerBackward: (state, action: PayloadAction<string>) => {
      const draggables = state.draggables[state.layout];
      const hasBackground = draggables?.[0]?.type === DraggableType.Background;
      const index = getDraggableIndexById(action.payload, draggables);
      if (
        (hasBackground && index > 1) ||
        (!hasBackground && index > 0 && index - 1 >= 0)
      ) {
        state.draggables[state.layout] = arrayMove(draggables, index, index - 1);
        // autoSave(state);
      }
    },
    layerToBack: (state, action: PayloadAction<string>) => {
      const draggables = state.draggables[state.layout];
      const hasBackground = draggables?.[0]?.type === DraggableType.Background;
      const index = getDraggableIndexById(action.payload, draggables);
      if ((hasBackground && index > 1) || (!hasBackground && index >= 0)) {
        const fromIndex = index;
        const toIndex = hasBackground && draggables.length > 0 ? 1 : 0;
        if (fromIndex !== toIndex) {
          state.draggables[state.layout] = arrayMove(draggables, fromIndex, toIndex);
          // autoSave(state);
        }
      }
    },
    deleteDraggable: (
      state,
      action: PayloadAction<DeleteDraggableInput | DeleteDraggableInput[]>,
    ) => {
      const ids = Array.isArray(action.payload)
        ? action.payload.map(({ id }) => id)
        : [action.payload.id];
      // recursive find and delete target draggable
      function deleteTarget(draggables: Draggable[]) {
        return draggables.filter((draggable) => {
          if (ids.indexOf(draggable.id) > -1) return;
          if ('group' in draggable && draggable.group) {
            draggable.group = deleteTarget(draggable.group);
            if (draggable.group.length === 0) return; // clear empty group
            if (draggable.page && !draggable.group[draggable.page]) {
              draggable.page = draggable.group.length - 1; // make sure page number is valid after clear empty group
            }
          }
          return draggable;
        });
      }
      // start recursive
      state.draggables[state.layout] = deleteTarget(state.draggables[state.layout]);
      // autoSave(state);
    },
    reset: (state) => {
      return {
        ...initialState,
        container: state.container,
      };
    },
    restoreStyle: (state, action: PayloadAction<Style>) => action.payload,
    addBackground: (state, action: PayloadAction<Draggable>) => {
      const draggables = [...state.draggables[state.layout]];
      if (draggables?.[0]?.type === DraggableType.Background) {
        if (draggables[0].style.color !== action.payload.style.color) {
          draggables[0] = action.payload;
        } else {
          return;
        }
      } else {
        draggables.unshift(action.payload);
      }

      state.draggables[state.layout] = draggables;
      // autoSave(state);
    },
  },
});

export const {
  addDraggable,
  addDraggableToGroup,
  newGroupToGroup,
  updateDraggable,
  updateDraggableAssign,
  updateDraggables2,
  updateDraggableWithoutHistory,
  updateDraggables,
  updateNotes,
  updateSkillIds,
  updateSubskillIds,
  updateSideLayout,
  updateContainer,
  updateContainerWithoutHistory,
  updateLayout,
  updateSelected,
  updateStyleName,
  updateStyleMode,
  updateStyleId,
  scalingGroup,
  scalingGroupWithoutHistory,
  layerSwap,
  layerToFront,
  layerForward,
  layerBackward,
  layerToBack,
  deleteDraggable,
  reset,
  restoreStyle,
  addBackground,
} = styleSlice.actions;

export default undoable(styleSlice.reducer, {
  limit: 60,
  filter: excludeAction([
    'style/updateSideLayout',
    'style/updateContainerWithoutHistory',
    'style/updateLayout',
    'style/updateStyleName',
    'style/updateStyleMode',
    'style/updateStyleId',
    'style/updateSelected',
    'style/updateDraggableWithoutHistory',
    'style/scalingGroupWithoutHistory',
    'style/updateContainer',
  ]),
  syncFilter: true,
});
