import { createSlice } from '@reduxjs/toolkit';

interface SearchQuery {
  pexel: string;
  pixabay: string;
  upsplash: string;
  giphy: string;
}

const initialState: SearchQuery = {
  pexel: '',
  pixabay: '',
  upsplash: '',
  giphy: '',
};

export const searchQuerySlice = createSlice({
  name: 'searchquery',
  initialState,
  reducers: {
    updateSearchQuery: (state, action) => {
      const { platform, query } = action.payload;
      return {
        ...state,
        [platform.toLowerCase()]: query,
      };
    },
    initialSearchQuery: (state, action) => {
      return { ...initialState };
    },
  },
});

export const { updateSearchQuery, initialSearchQuery } = searchQuerySlice.actions;

export default searchQuerySlice.reducer;
