import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import AccountForTabletOrMobileSvg from './AccountForTabletOrMobileSvg';
import AccountSvg from './AccountSvg';
import ChineseForTabletOrMobileSvg from './ChineseForTabletOrMobileSvg';
import ChineseSvg from './ChineseSvg';
import ClassesSvg from './ClassesSvg';
import DailyForTabletOrMobileSvg from './DailyForTabletOrMobileSvg';
import DailySvg from './DailySvg';
import FunForTabletOrMobileSvg from './FunForTabletOrMobileSvg';
import FunSvg from './FunSvg';
import HomeworkForTabletOrMobileSvg from './HomeworkForTabletOrMobileSvg';
import HomeworkSvg from './HomeworkSvg';
import { useScripts, useLanguage } from './LanguageHelper';
import LeadsForTabletOrMobileSvg from './LeadsForTabletOrMobileSvg';
import LeadsSvg from './LeadsSvg';
import LearnForTabletOrMobileSvg from './LearnForTabletOrMobileSvg';
import LearnSvg from './LearnSvg';
import LogoutForTabletOrMobileSvg from './LogoutForTabletOrMobileSvg';
import LogoutSvg from './LogoutSvg';
import MoreForTabletOrMobileSvg from './MoreForTabletOrMobileSvg';
import MoreSvg from './MoreSvg';
import OtherButton from './OtherButton';
import ResourcesForTabletOrMobileSvg from './ResourcesForTabletOrMobileSvg';
import ResourcesSvg from './ResourcesSvg';
import ScheduleForTabletOrMobileSvg from './ScheduleForTabletOrMobileSvg';
import ScheduleSvg from './ScheduleSvg';
import SchoolForTabletOrMobileSvg from './SchoolForTabletOrMobileSvg';
import SchoolSvg from './SchoolSvg';
import TeachForTabletOrMobileSvg from './TeachForTabletOrMobileSvg';
import TeachSvg from './TeachSvg';
import ThemeModeForTabletOrMobileSvg from './ThemeModeForTabletOrMobileSvg';
import ThemeModeSvg from './ThemeModeSvg';
import TodayForTabletOrMobileSvg from './TodayForTabletOrMobileSvg';
import TodaySvg from './TodaySvg';
import { useAuth } from '../../globalHooks/useAuth';
import { MenuItemProps, ThemeMode } from '../components/MainMenu';

export interface SvgProps {
  width: number;
  height: number;
  colorForCustomer?: string;
}

interface MenuItem2Props extends MenuItemProps {
  itemType:
    | 'Account'
    | 'Today'
    | 'Learn'
    | 'Teach'
    | 'Resources'
    | 'Schedule'
    | 'Classes'
    | 'School'
    | 'Leads'
    | 'Daily'
    | 'Fun'
    | 'Homework'
    | 'Logout'
    | 'Chinese'
    | 'ThemeMode'
    | 'More'
    | 'Other';
  initWidth: number;
  initHeight: number;
  baseWidth?: number;
  initUnderlineWidth?: number;
  initUnderlineHeight?: number;
  currentThemeMode?: ThemeMode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const MenuItem = ({
  itemType,
  initWidth,
  initHeight,
  baseWidth = 1680,
  initUnderlineWidth = 25,
  initUnderlineHeight = 5,
  selected = false,
  isMobile,
  isMore,
  currentThemeMode,
  onClick,
}: MenuItem2Props) => {
  const scripts = useScripts();

  const { managerRole } = useAuth();

  const widthRatio = initWidth / baseWidth;
  const heightRatio = initHeight / initWidth;

  const underlineWidthRatio = initUnderlineWidth / baseWidth;
  const underlineHeightRatio = initUnderlineHeight / initUnderlineWidth;

  const [width, setWidth] = useState(initWidth);
  const [underlineWidth, setUnderlineWidth] = useState(initUnderlineWidth);
  const [isMinSize, setIsMinSize] = useState(true);

  const webMode = localStorage.getItem('theme');
  const { language } = useLanguage();

  useEffect(() => {
    const resize = () => {
      const width = window.innerWidth * widthRatio;
      const underlineWidth = window.innerWidth * underlineWidthRatio;
      const isMinSize = width <= initWidth;
      setWidth(isMinSize ? initWidth : width);
      setUnderlineWidth(isMinSize ? initUnderlineWidth : underlineWidth);
      setIsMinSize(isMinSize);
    };
    resize();

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  const height = width === initWidth ? initHeight : width * heightRatio;
  const colorForCustomer = selected ? '#00a591' : '#FFF';

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      spacing={isMobile ? 0.5 : 2}
    >
      {!isMobile && !isMore && (
        <>
          {itemType === 'Today' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <TodaySvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />
              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Today}
              </Typography>
            </Stack>
          )}
          {itemType === 'Learn' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <LearnSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />
              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Learn}
              </Typography>
            </Stack>
          )}
          {itemType === 'Teach' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <TeachSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />
              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Teach}
              </Typography>
            </Stack>
          )}
          {itemType === 'Resources' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <ResourcesSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />
              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Resources}
              </Typography>
            </Stack>
          )}
          {itemType === 'Schedule' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <ScheduleSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />
              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Schedule}
              </Typography>
            </Stack>
          )}
          {itemType === 'Classes' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <ClassesSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />
              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Classes}
              </Typography>
            </Stack>
          )}
          {itemType === 'School' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <SchoolSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.School}
              </Typography>
            </Stack>
          )}
          {itemType === 'Leads' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <LeadsSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Leads}
              </Typography>
            </Stack>
          )}
          {itemType === 'Daily' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <DailySvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Daily}
              </Typography>
            </Stack>
          )}
          {itemType === 'Fun' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <FunSvg width={width} height={height} colorForCustomer={colorForCustomer} />

              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Fun}
              </Typography>
            </Stack>
          )}
          {itemType === 'Homework' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <HomeworkSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Homework}
              </Typography>
            </Stack>
          )}
          {itemType === 'Account' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <AccountSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Account}
              </Typography>
            </Stack>
          )}
          {itemType === 'Logout' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <LogoutSvg width={width} height={height} />

              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Logout}
              </Typography>
            </Stack>
          )}
          {itemType === 'Chinese' && (
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <ChineseSvg width={width} height={height} />

              <Typography
                variant="inherit"
                m={0}
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {managerRole ? scripts.Language : ''}
              </Typography>
            </Stack>
          )}
          {itemType === 'ThemeMode' && typeof currentThemeMode === 'number' && (
            <ThemeModeSvg
              width={width}
              height={height}
              currentThemeMode={currentThemeMode}
            />
          )}
          {itemType === 'More' && <MoreSvg width={width} height={height} />}
          {itemType === 'Other' && (
            <OtherButton width={width} height={height} onClick={onClick} />
          )}
        </>
      )}
      {(isMobile || isMore) && managerRole && (
        <>
          {itemType === 'Today' && (
            <Stack
              direction="column"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <TodaySvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Today}
              </Typography>
            </Stack>
          )}
          {itemType === 'Learn' && (
            <Stack
              direction="column"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <LearnSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Learn}
              </Typography>
            </Stack>
          )}
          {itemType === 'Teach' && (
            <Stack
              direction="column"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <TeachSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Teach}
              </Typography>
            </Stack>
          )}
          {itemType === 'Resources' && (
            <Stack
              direction="column"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <ResourcesSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Resources}
              </Typography>
            </Stack>
          )}
          {itemType === 'Schedule' && <ScheduleForTabletOrMobileSvg />}
          {itemType === 'School' && (
            <Stack
              direction="column"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <SchoolSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.School}
              </Typography>
            </Stack>
          )}
          {itemType === 'Leads' && (
            <Stack
              direction="column"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <LeadsSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                fontSize={language === 'TW' ? '0.8em' : '1em'}
                variant="inherit"
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Leads}
              </Typography>
            </Stack>
          )}
          {itemType === 'Daily' && (
            <Stack
              direction="column"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <DailySvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Daily}
              </Typography>
            </Stack>
          )}
          {itemType === 'Fun' && <FunForTabletOrMobileSvg />}
          {itemType === 'Homework' && <HomeworkForTabletOrMobileSvg />}
          {itemType === 'Account' && (
            <Stack
              direction="column"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <AccountSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Account}
              </Typography>
            </Stack>
          )}
          {itemType === 'Logout' && (
            <Stack
              direction="column"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <LogoutSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.Logout}
              </Typography>
            </Stack>
          )}
          {itemType === 'Chinese' && (
            <Stack
              direction="column"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <ChineseSvg
                width={width}
                height={height}
                colorForCustomer={colorForCustomer}
              />

              <Typography
                variant="inherit"
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {managerRole ? scripts.Language : ''}
              </Typography>
            </Stack>
          )}
          {itemType === 'ThemeMode' && typeof currentThemeMode === 'number' && (
            <ThemeModeForTabletOrMobileSvg
              width={width}
              currentThemeMode={currentThemeMode}
            />
          )}
          {itemType === 'More' && (
            <Stack
              direction="column"
              sx={{
                alignItems: 'center',
                fontWeight: '600',
              }}
              spacing={1}
            >
              <MoreForTabletOrMobileSvg />

              <Typography
                variant="inherit"
                color={managerRole ? '#090446' : colorForCustomer}
              >
                {scripts.More}
              </Typography>
            </Stack>
          )}
        </>
      )}
      {isMobile && !managerRole && itemType === 'Other' && (
        <OtherButton width={width} height={height} onClick={onClick} />
      )}
      {!isMore && managerRole && (
        <>
          {selected && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
              width={`${underlineWidth}px`}
              height={`${
                isMinSize ? initUnderlineHeight : underlineWidth * underlineHeightRatio
              }px`}
              sx={{ backgroundColor: webMode === 'Light' ? '#090446' : '#fff' }}
            />
          )}
          {!selected && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
            />
          )}
        </>
      )}
    </Stack>
  );
};

export default MenuItem;
