import { useLayoutEffect, useState, useEffect, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { cloneDeep, defaultsDeep } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import CenterLeftBox from '../../../../globalContainers/CenterLeftBox';
import { useAuth } from '../../../../globalHooks/useAuth';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import { Draggable } from '../../../../redux/features/styleSlice';
import { useAppSelector } from '../../../../redux/store';
import { getDefaultFontSizeNum } from '../../context/FontEditorContext';
import { useStyleAnswer } from '../../context/StyleAnswerContext';
import { useStyle } from '../../context/StyleContext';
import { getDraggables } from '../../utils/draggables';

export const fillBlankChild: Array<Draggable> = [
  {
    id: '',
    type: DraggableType.TextRectAnswer2,
    tags: [DraggableTag.TextField],
    name: 'big',
    style: {
      width: '45%',
      height: '14%',
      left: '5%',
      top: '2%',
      color: '#000000',
      textAlign: 'left',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
      fontFamily: 'Roboto',
      backgroundColor: '#d9d9d9',
    },
    lockAspectRatio: false,
    answer: {
      correct: 'big',
    },
  },
  {
    id: '',
    type: DraggableType.CustomText,
    tags: [DraggableTag.TextField],
    name: 'This is a ___ cat.',
    style: {
      width: '90%',
      height: '20%',
      left: '5%',
      top: '19%',
      color: '#000000',
      textAlign: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
      fontFamily: 'Roboto',
      backgroundColor: '#d9d9d9',
      borderRadius: '10px',
    },
    lockAspectRatio: false,
  },
  {
    id: '',
    type: DraggableType.RandomTextBoard,
    tags: [DraggableTag.Elements],
    name: 'Board',
    style: {
      width: '90%',
      height: '50%',
      left: '5%',
      top: '45%',
      color: '#000000',
      backgroundColor: '#e6e6e6',
    },
    lockAspectRatio: false,
  },
];

const landscapeProps: DraggableInitProps = {
  type: DraggableType.FillBlankAnswer,
  tags: [DraggableTag.Group],
  style: {
    width: '28.87112%',
    height: '51.71717%',
  },
  lockAspectRatio: false,
  group: [
    {
      ...fillBlankChild[0],
      style: {
        ...fillBlankChild[0].style,
        width: '12.992%',
        height: '7.2404%',
        left: '1.44355%',
        top: '1.03434%',
      },
    },
    {
      ...fillBlankChild[1],
      style: {
        ...fillBlankChild[1].style,
        width: '25.98401%',
        height: '10.34343%',
        left: '1.44355%',
        top: '9.82626%',
      },
    },
    {
      ...fillBlankChild[2],
      style: {
        ...fillBlankChild[2].style,
        width: '25.98401%',
        height: '25.85858%',
        left: '1.44355%',
        top: '23.27272%',
      },
    },
  ],
};

export const defaultFillBlankAnswerProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '77.778972%',
      height: '43.752469%',
    },
    group: [
      {
        ...fillBlankChild[0],
        style: {
          ...fillBlankChild[0].style,
          width: '35.000537%',
          height: '6.125345%',
          left: '3.888948%',
          top: '0.875049%',
        },
      },
      {
        ...fillBlankChild[1],
        style: {
          ...fillBlankChild[1].style,
          width: '70.001075%',
          height: '8.750493%',
          left: '3.888948%',
          top: '8.312969%',
        },
      },
      {
        ...fillBlankChild[2],
        style: {
          ...fillBlankChild[2].style,
          width: '70.001075%',
          height: '21.876234%',
          left: '3.888948%',
          top: '19.688611%',
        },
      },
    ],
  },
};

export default function FillBlankAnswer(props: DraggableOuterProps) {
  //### props.group[0] is CorrectAnswerBox
  //### props.group[1] is UserAnswerBox
  //### props.group[2] is BoardBox
  const { layout, draggablesPropsRef, defineCompletelyDeleteDraggable } = useStyle();
  const adminViewMode = useAppSelector((state) => state.adminViewMode.currentMode);
  const [groupProps, setGroupProps] = useState(cloneDeep(props.group?.slice(1)));
  const { styleAnswers } = useStyleAnswer();
  const { styleId = '' } = useParams();

  const { isStudent } = useAuth();
  const [serial, setSerial] = useState(uuidv4());

  const originGroupProps = cloneDeep(props.group?.slice(1));

  useEffect(() => {
    const currentAnswer = styleAnswers.find((ele) => ele.styleId === styleId);

    const currentUseAnswer = currentAnswer?.userAnswers.find(
      (ele) => ele.draggableId === props.id,
    );

    const updatedGroupProps = groupProps?.map((item, index) => {
      if (item.name === 'Board') {
        return item;
      } else {
        if (
          item.style &&
          item.style.color &&
          typeof currentUseAnswer?.answer === 'string'
        ) {
          const userFillLength = currentUseAnswer?.answer.length;
          const originGroupPropsAbbr = originGroupProps?.[index].style.color;

          const firstUnderlinePos = originGroupPropsAbbr?.indexOf('_') ?? -1;
          const lastUnderlinePos = originGroupPropsAbbr?.lastIndexOf('_') ?? -1;
          const underlineNum = lastUnderlinePos - firstUnderlinePos + 1; //equal correct answer length

          item.style.color = `${originGroupPropsAbbr?.substring(0, firstUnderlinePos)}${
            currentUseAnswer.answer
          }${'_'.repeat(
            Math.max(underlineNum - userFillLength, 0),
          )}${originGroupPropsAbbr?.substring(lastUnderlinePos + 1)}`;
        }
        return item;
      }
    });
    setGroupProps(updatedGroupProps);
  }, [styleAnswers]);

  const defaultProps = layout
    ? defaultFillBlankAnswerProps[layout]
    : defaultFillBlankAnswerProps.landscape;

  const defaultFillBlankAnswer: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '35vmin',
      height: '35vmin',
    },
    group: props.isClone ? undefined : fillBlankChild,
    originWrapperStyle: {
      height: 'auto',
      backgroundColor: '#f3f3f3',
      borderRadius: '6px',
      padding: 1,
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultFillBlankAnswer,
  );

  useEffect(() => {
    if (isStudent) {
      setSerial(uuidv4());
    }
  }, []);

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep(props);
      defineCompletelyDeleteDraggable(id);
    }
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps} {...(isStudent ? { key: serial } : null)}>
      {dragProps.isClone && dragProps.group && groupProps ? (
        // getDraggables(groupProps)
        getDraggables(adminViewMode === 'edit' ? dragProps.group : groupProps)
      ) : (
        <>
          <CenterLeftBox
            sx={{
              width: '17vmin',
              height: '5vmin',
              backgroundColor: '#d9d9d9',
              pl: 1,
              mb: 1,
            }}
          >
            <Typography sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}>
              big
            </Typography>
          </CenterLeftBox>
          <CenterBox
            sx={{
              height: '5vmin',
              backgroundColor: '#d9d9d9',
              borderRadius: '10px',
            }}
          >
            <Typography sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}>
              This is a ___ cat.
            </Typography>
          </CenterBox>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            mt={1}
          >
            {['p', 'i', 'b', 'g', 'f'].map((str) => (
              <CenterBox
                key={str}
                sx={{
                  width: '100%',
                  backgroundColor: '#d9d9d9',
                  borderRadius: '5px',
                }}
              >
                <Typography sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}>
                  {str}
                </Typography>
              </CenterBox>
            ))}
          </Stack>
        </>
      )}
    </WrapperRND>
  );
}
