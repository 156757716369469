import IconType from './IconType';

const TestScoresIcon = ({
  width = 15,
  height = 13,
  color = '#090446',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M14.6096 12.2613H12.5298V1.47122L13.063 2.20294C13.1389 2.30687 13.2605 2.36279 13.3848 2.36279C13.4612 2.36279 13.5382 2.34168 13.6062 2.29737C13.7836 2.18183 13.8282 1.95182 13.7061 1.78353L12.522 0.159191C12.4518 0.0626482 12.3369 0.00356206 12.213 -0.000130823C12.0887 -0.00435126 11.971 0.0484042 11.894 0.140199L10.5878 1.70598C10.4545 1.86636 10.483 2.09848 10.6525 2.22457C10.8221 2.35118 11.0675 2.32375 11.2008 2.16337L11.749 1.50604V12.2608H7.27942V4.49252L7.8126 5.22424C7.88845 5.32817 8.01004 5.38409 8.13441 5.38409C8.21082 5.38409 8.28779 5.36299 8.35583 5.31867C8.53319 5.20314 8.5778 4.97313 8.45566 4.80484L7.27161 3.1805C7.20134 3.08395 7.08645 3.02487 6.96263 3.02117C6.83938 3.01695 6.72058 3.06971 6.64361 3.1615L5.33742 4.72729C5.20413 4.88714 5.23257 5.11979 5.40212 5.24587C5.57167 5.37249 5.81707 5.34505 5.95036 5.18468L6.49861 4.52734V12.2613H2.67652V8.29936L3.2097 9.03108C3.28555 9.135 3.40714 9.19093 3.53151 9.19093C3.60792 9.19093 3.68489 9.16982 3.75293 9.12551C3.93028 9.00997 3.9749 8.77996 3.85276 8.61167L2.66871 6.98733C2.59844 6.89079 2.48355 6.8317 2.35973 6.82801C2.23648 6.82273 2.11768 6.87654 2.04071 6.96834L0.734523 8.53412C0.601227 8.69397 0.629671 8.92662 0.799219 9.05271C0.968767 9.17932 1.21417 9.15189 1.34746 8.99151L1.89571 8.33418V12.2608H0.390407C0.174568 12.2608 0 12.4259 0 12.6301C0 12.8342 0.174568 12.9993 0.390407 12.9993H14.6096C14.8254 12.9993 15 12.8342 15 12.6301C15 12.4259 14.8254 12.2608 14.6096 12.2608V12.2613Z"
        fill={color}
      />
    </svg>
  );
};

export default TestScoresIcon;
