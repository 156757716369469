import React, { useId, useRef, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';

import { useScripts } from './LanguageHelper';
import { useAuth } from '../../globalHooks/useAuth';
import usePopoverAnchorEl from '../../globalHooks/usePopoverAnchorEl';
import { updateBarIcon, updateBarIsOpen } from '../../redux/features/classSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import CheckGradesIcon from '../../svg/CheckGradesIcon';
import ClassesIcon from '../../svg/ClassesIcon';
import HomeworkIcon from '../../svg/HomeworkIcon';
import StudentsOfClassesIcon from '../../svg/StudentsOfClassesIcon';
import TestScoresIcon from '../../svg/TestScoresIcon';

type MobileMenuListProps = {
  menuList: {
    path: string;
    title: string;
    itemIndex: number;
  }[];
  isMobile: boolean;
  currentIndex: number | null;
};

type itemsProps = {
  title: string;
  icon?: JSX.Element;
  onClick?: () => void;
};

const MobileMenuList = ({ menuList, isMobile, currentIndex }: MobileMenuListProps) => {
  const scripts = useScripts();

  const [openClassesList, setOpenClassesList] = useState(false);

  const handleClickClasses = () => {
    setOpenClassesList(!openClassesList);
  };

  const { allRole } = useAuth();

  const menuId = useId();
  const menuItemSerialRef = useRef(0);

  const dispatch = useAppDispatch();
  const type = useAppSelector((state) => state.class.barIcon.type);

  const sideLayoutIsOpen = useAppSelector((state) => state.class.barIcon.open);

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const navigate = useNavigate();

  const lists = [
    {
      title: scripts.Classes,
      icon: <ClassesIcon />,
      onClick: () => {
        setSelectedIndex(0);
        navigate('/teacher/classes');
      },
    },
    {
      title: scripts.students,
      icon: <StudentsOfClassesIcon />,
      onClick: () => {
        setSelectedIndex(1);
        navigate('/teacher/students');
      },
    },
    {
      title: scripts.homework,
      icon: <HomeworkIcon />,
      onClick: () => {
        setSelectedIndex(2);
        navigate('/teacher/homework');
      },
    },
    {
      title: scripts.checkGrades,
      icon: <CheckGradesIcon />,
      onClick: () => {
        setSelectedIndex(3);
        navigate('/teacher/checkGrades');
      },
    },
    {
      title: scripts.testScores,
      icon: <TestScoresIcon />,
      onClick: () => {
        setSelectedIndex(4);
        navigate('/teacher/testScores');
      },
    },
  ];

  function items(list: itemsProps[]): React.ReactNode {
    return list.map(({ title, icon, onClick }, index) =>
      title === 'Divider' ? (
        <Divider key={`Divider${index}`} />
      ) : (
        <Tooltip key={title} title={open ? '' : title} placement="left">
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={index === selectedIndex}
              onClick={() => {
                if (onClick) {
                  onClick();
                }
                const moduleType = title.replace(/\s/g, '');
                if (sideLayoutIsOpen && type === moduleType) {
                  dispatch(updateBarIcon({ open: false, type: '' })); //TODO: close this?
                } else {
                  dispatch(updateBarIcon({ open: true, type: moduleType }));
                }
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </Tooltip>
      ),
    );
  }

  const { anchorEl, open, handleClick, handleClose } = usePopoverAnchorEl();

  return (
    <React.Fragment key={`${menuId}-${allRole}`}>
      <IconButton onClick={handleClick}>
        {!open && <MenuIcon sx={{ width: '40px', height: '40px' }} />}
        {open && <MenuOpenIcon sx={{ width: '40px', height: '40px' }} />}
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuList dense={isMobile}>
          {menuList.map(({ path, title, itemIndex }) => (
            <div key={itemIndex}>
              <MenuItem
                key={`${menuId}-${allRole}-${menuItemSerialRef.current++}`}
                onClick={() => {
                  if (title === 'Classes') {
                    handleClickClasses();
                  }
                  if (title !== 'Classes') {
                    setSelectedIndex(null);
                    setOpenClassesList(false);
                    dispatch(updateBarIcon({ open: true, type: title }));
                    dispatch(updateBarIsOpen(false));
                    handleClose();
                    navigate(path);
                  }
                }}
                selected={itemIndex === currentIndex}
              >
                <ListItemText>{scripts[title]}</ListItemText>
                {title === 'Classes' &&
                  (openClassesList ? <ExpandLess /> : <ExpandMore />)}
              </MenuItem>
              {title === 'Classes' && (
                <div
                  onClick={() => {
                    handleClickClasses();
                    handleClose();
                  }}
                >
                  {openClassesList && (
                    <List component="div" disablePadding>
                      {items(lists)}
                    </List>
                  )}
                </div>
              )}
            </div>
          ))}
        </MenuList>
      </Menu>
    </React.Fragment>
  );
};

export default MobileMenuList;
