import { createContext, useContext, useState } from 'react';

const defaultValue: {
  setIsShowDetailInfo: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<boolean[]> | null>
  >;
  isShowDetailInfo: React.MutableRefObject<boolean[]> | null;
} = {
  setIsShowDetailInfo: (func) => undefined,
  isShowDetailInfo: null,
};

const CustomTableContext = createContext(defaultValue);

export const CustomTableProvider = ({ children }: any) => {
  const [isShowDetailInfo, setIsShowDetailInfo] = useState<React.MutableRefObject<
    boolean[]
  > | null>(null);

  return (
    <CustomTableContext.Provider value={{ setIsShowDetailInfo, isShowDetailInfo }}>
      {children}
    </CustomTableContext.Provider>
  );
};

export const useCustomTableContext = () => {
  return useContext(CustomTableContext);
};
