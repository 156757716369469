import { useEffect, useRef, useState } from 'react';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import Box from '@mui/material/Box';

import useUpdateFontStyle, { EditTextType } from './hooks/useUpdateFontStyle';
import CustomAutocomplete from '../../../../globalComponents/CustomAutocomplete';
import CustomButton from '../../../../globalComponents/CustomButton';
import { getFontWeight, useFontEditorContext } from '../../context/FontEditorContext';
import { useStyle } from '../../context/StyleContext';
import { getFontWeightForToolbar } from '../draggable/components/TextFieldBase';

export enum FontWeightType {
  ExtraLight = 'ExtraLight',
  Light = 'Light',
  Regular = 'Regular',
  SemiBold = 'Semi Bold',
  Bold = 'Bold',
  ExtraBold = 'ExtraBold',
  Black = 'Black',
}

const FontWeightOption2 = () => {
  const { selectedId, isEditingText } = useStyle();
  const { editorRefs, setFontWeightNumRef } = useFontEditorContext();
  const { updateFontStyle } = useUpdateFontStyle(EditTextType.FontWeight);

  const fontWeightDictRef = useRef<{ [key: string]: FontWeightType }>({
    '200': FontWeightType.ExtraLight,
    '300': FontWeightType.Light,
    '400': FontWeightType.Regular,
    '600': FontWeightType.SemiBold,
    '700': FontWeightType.Bold,
    '800': FontWeightType.ExtraBold,
    '900': FontWeightType.Black,
  });
  const optionsRef = useRef(
    (['200', '300', '400', '600', '700', '800', '900'] as const).map((fontWidth) => ({
      label: fontWeightDictRef.current[fontWidth],
      value: fontWidth,
    })),
  );
  const [value, setValue] = useState<string | null>(null);
  const [isNum, setIsNum] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setFontWeightNumRef) {
      setFontWeightNumRef.current = (fontWeightNum: number | null) => {
        setValue(typeof fontWeightNum === 'number' ? `${fontWeightNum}` : null);
        setIsNum(typeof fontWeightNum === 'number');
      };

      const editorState = editorRefs?.editorStateRef.current;
      if (editorState) {
        const fontWeightNum = getFontWeightForToolbar(editorState, isEditingText);
        setValue(typeof fontWeightNum === 'number' ? `${fontWeightNum}` : null);
        setIsNum(typeof fontWeightNum === 'number');
      }

      return () => {
        setFontWeightNumRef.current = null;
      };
    }
  }, []);

  useEffect(() => {
    const input = boxRef.current?.nextElementSibling
      ?.getElementsByTagName('input')
      ?.item(0);
    if (input) {
      input.readOnly = true;
    }
  }, []);

  return (
    <>
      <Box ref={boxRef} display="none" />
      <CustomAutocomplete
        fullWidth
        disableClearable
        size="small"
        disabled={!selectedId}
        filterOptions={(options, state) => options}
        options={optionsRef.current}
        value={isNum && value ? [{ label: fontWeightDictRef.current[value], value }] : []}
        onChangeForSingleValue={(e, option) => {
          if (option?.value) {
            setValue(option.value);
            updateFontStyle(getFontWeight(option.value), EditTextType.FontWeight);
          } else {
            setValue(null);
          }
        }}
        placeholder="------"
        startAdornment={
          <CustomButton
            variant="text"
            size="small"
            sx={{
              position: 'absolute',
              height: '100%',
              top: 0,
              left: 0,
              minWidth: '36px',
              borderRadius: '4px',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              color: '#000',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
            disabled
          >
            <FormatBoldIcon sx={{ color: '#000' }} />
          </CustomButton>
        }
        inputSx={{
          paddingLeft: '48px !important',
          paddingRight: '14px !important',
        }}
        dialogProps={{
          willSubmit: false,
        }}
      />
    </>
  );
};

export default FontWeightOption2;
