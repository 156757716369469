import { useEffect, useState, useRef } from "react";

type usePopoverAnchorElProps = {
    bottomNavigationId?: string;
    currentMenuItemIndex?: number | null;
};

const usePopoverAnchorEl = (customArgs?: usePopoverAnchorElProps) => {
    const bottomNavigationId = customArgs?.bottomNavigationId;
    const currentMenuItemIndex = customArgs?.currentMenuItemIndex;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const bottomNavigationRef = useRef<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (bottomNavigationRef.current && window.innerHeight < (document.getElementsByTagName('html')?.item(0)?.scrollHeight ?? 0)) {
            const bottomNavigation = bottomNavigationRef.current;
            bottomNavigation.style.width = 'auto';
            bottomNavigation.style.position = 'sticky';
        }

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!bottomNavigationRef.current && bottomNavigationId) {
            bottomNavigationRef.current = document.getElementById(bottomNavigationId);
        }
        if (bottomNavigationRef.current) {
            const bottomNavigation = bottomNavigationRef.current;
            bottomNavigation.style.width = '100%';
            bottomNavigation.style.position = 'fixed';
        }
    }, [currentMenuItemIndex]);

    return {
        anchorEl, open, handleClick, handleClose
    };
};

export default usePopoverAnchorEl;
