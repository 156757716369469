import { useEffect, useState } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import { Theme } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DesktopDateTimePicker,
  DesktopDateTimePickerSlotsComponentsProps,
} from '@mui/x-date-pickers/DesktopDateTimePicker';
import {
  DesktopTimePicker,
  DesktopTimePickerSlotsComponentsProps,
} from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import dayjs, { Dayjs } from 'dayjs';

import { useScripts } from '../layout/utils/LanguageHelper';

type CustomDateTimePickerProps = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>> | ((value: string) => void);
  startDateTime?: string;
  endDateTime?: string;
  label?: string;
  toolbarTitle?: string;
  required?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  size?: 'medium' | 'small';
  readOnly?: boolean;
  disabled?: boolean;
  doNotAutosaveOnSelect?: boolean;
  dialogProps?: {
    willSubmit: boolean;
  };
  backgroundColor?: string;
  borderLine?: string;
  onlyTime?: boolean;
};

const CustomDateTimePicker = ({
  dialogProps = { willSubmit: false },
  backgroundColor = '#FFF',
  ...props
}: CustomDateTimePickerProps): JSX.Element => {
  const scripts = useScripts();

  const isScreenDownLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  // states
  const [valueTemp, setValueTemp] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [isClickAway, setIsClickAway] = useState(false);

  // picker props
  const label = props.label ? { label: props.label } : {};
  const required = { required: props.required ? true : false };
  const minDateTime = props.startDateTime
    ? { minDateTime: dayjs(props.startDateTime) }
    : {};
  const maxDateTime = props.endDateTime ? { maxDateTime: dayjs(props.endDateTime) } : {};
  const defaultDateTime = props.value ? dayjs(props.value).format() : '';
  const error = props.error
    ? {
        error:
          (props.error || (props.required ? !props.value : false)) &&
          dialogProps.willSubmit,
      }
    : typeof props.error === 'boolean'
    ? { error: props.error }
    : { error: !!(props.required && !props.value && dialogProps.willSubmit) };
  const fullWidth = { fullWidth: !!props.fullWidth };
  const helperText = (
    <>
      {props.required && !props.value && dialogProps.willSubmit ? (
        <Typography variant="caption" sx={{ display: 'block' }}>
          {scripts.pleaseSelectA} {props.onlyTime ? 'time' : 'datetime'}.
        </Typography>
      ) : (
        ''
      )}
      {props.helperText ? (
        <Typography variant="caption" sx={{ display: 'block' }}>
          {props.helperText}
        </Typography>
      ) : (
        ''
      )}
    </>
  );
  const size: { size: 'medium' | 'small' } = props.size
    ? { size: props.size }
    : { size: 'medium' };
  const readOnly = { readOnly: !!props.readOnly };
  const disabled = { disabled: !!props.disabled };
  const doNotAutosaveOnSelect = props.doNotAutosaveOnSelect;
  const componentsProps: Partial<
    DesktopDateTimePickerSlotsComponentsProps | DesktopTimePickerSlotsComponentsProps
  > = {
    actionBar: {
      actions: ['accept', 'cancel'],
    },
  };
  const doNotAutosaveProps = doNotAutosaveOnSelect
    ? {
        closeOnSelect: false,
        onOpen: () => {
          if (isScreenDownLg) setOpen(true);
          if (!isScreenDownLg) setIsClickAway(false);
        },
        onClose: () => {
          if (isScreenDownLg) setOpen(false);
          if (!isScreenDownLg && isClickAway) setValueTemp(props.value);
        },
        onAccept: (event: Dayjs | null) => {
          if ((!isScreenDownLg && isClickAway) || !event) return;

          setValueTemp(event.format());
          props.setValue(event.format());

          if (isScreenDownLg) setOpen(false);
        },
        componentsProps,
      }
    : null;

  useEffect(() => {
    if (props.value) {
      props.setValue(defaultDateTime);
    }

    if (props.doNotAutosaveOnSelect) {
      setValueTemp(props.value);
    } else {
      setValueTemp(null);
    }

    if (!isScreenDownLg) {
      setIsClickAway(false);
    }
  }, [props.value]);

  const renderInput = (isMobile = false) => {
    const renderInputFunc = (params: TextFieldProps) => {
      return (
        <TextField
          {...params}
          {...required}
          {...label}
          {...size}
          helperText={helperText}
          {...error}
          {...disabled}
          {...(props.onlyTime
            ? {
                InputProps: {
                  ...params?.InputProps,
                  readOnly: true,
                  sx: { backgroundColor: backgroundColor },
                },
                inputProps: { ...params?.inputProps, readOnly: true },
              }
            : null)}
          sx={{
            '& label.Mui-focused': {
              color: '#00A591',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor:
                  props?.borderLine === 'false'
                    ? 'rgba(0, 0, 0, 0)'
                    : 'rgba(0, 0, 0, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.87)',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#00A591',
              },
            },
            //backgroundColor: backgroundColor,
            ...(!isMobile
              ? {
                  '& .MuiSvgIcon-root': {
                    '&:hover ': {
                      color: '#00A591',
                    },
                  },
                }
              : null),
          }}
        />
      );
    };

    return renderInputFunc;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ClickAwayListener
        onClickAway={() => {
          if (!isScreenDownLg) {
            setIsClickAway(true);
          }
        }}
      >
        <FormControl {...fullWidth} {...required} {...disabled} {...error}>
          {!isScreenDownLg &&
            (props.onlyTime ? (
              <DesktopTimePicker
                {...readOnly}
                {...disabled}
                value={doNotAutosaveOnSelect ? valueTemp : props.value}
                {...doNotAutosaveProps}
                onChange={(event: Dayjs | null) => {
                  if (!event) {
                    return;
                  }
                  if (doNotAutosaveOnSelect) {
                    setValueTemp(event.format());
                  } else {
                    props.setValue(event.format());
                  }

                  setIsClickAway(false);
                }}
                components={{
                  OpenPickerIcon: AccessTimeIcon,
                }}
                renderInput={renderInput()}
              />
            ) : (
              <DesktopDateTimePicker
                {...readOnly}
                {...disabled}
                {...minDateTime}
                {...maxDateTime}
                inputFormat="YYYY/MM/DD hh:mm A"
                value={doNotAutosaveOnSelect ? valueTemp : props.value}
                {...doNotAutosaveProps}
                onChange={(event: Dayjs | null) => {
                  if (!event) {
                    return;
                  }
                  if (doNotAutosaveOnSelect) {
                    setValueTemp(event.format());
                  } else {
                    props.setValue(event.format());
                  }

                  setIsClickAway(false);
                }}
                components={{
                  OpenPickerIcon: CalendarTodayIcon,
                }}
                renderInput={renderInput()}
                PopperProps={{
                  //The date option can be hidden in certain sections. Adjust as needed.
                  placement: 'auto',
                }}
              />
            ))}
          {isScreenDownLg &&
            (props.onlyTime ? (
              <MobileTimePicker
                {...(doNotAutosaveOnSelect
                  ? {
                      open,
                      DialogProps: {
                        onClose: () => {
                          setValueTemp(props.value);
                          setOpen(false);
                        },
                      },
                    }
                  : null)}
                {...minDateTime}
                {...maxDateTime}
                value={doNotAutosaveOnSelect ? valueTemp : props.value}
                {...doNotAutosaveProps}
                onChange={(event: Dayjs | null) => {
                  if (!event) {
                    return;
                  }
                  if (doNotAutosaveOnSelect) {
                    setValueTemp(event.format());
                  } else {
                    props.setValue(event.format());
                  }
                }}
                renderInput={renderInput(true)}
                toolbarTitle={props.toolbarTitle ?? 'Select datetime'}
              />
            ) : (
              <MobileDateTimePicker
                {...(doNotAutosaveOnSelect
                  ? {
                      open,
                      DialogProps: {
                        onClose: () => {
                          setValueTemp(props.value);
                          setOpen(false);
                        },
                      },
                    }
                  : null)}
                {...minDateTime}
                {...maxDateTime}
                inputFormat="YYYY/MM/DD hh:mm A"
                value={doNotAutosaveOnSelect ? valueTemp : props.value}
                {...doNotAutosaveProps}
                onChange={(event: Dayjs | null) => {
                  if (!event) {
                    return;
                  }
                  if (doNotAutosaveOnSelect) {
                    setValueTemp(event.format());
                  } else {
                    props.setValue(event.format());
                  }
                }}
                renderInput={renderInput(true)}
                toolbarTitle={props.toolbarTitle ?? 'Select datetime'}
              />
            ))}
        </FormControl>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;
