import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import ToolsMenu, { ToolsMenuChildProps } from './ToolsMenu';
import ColorPicker from '../../../ColorPicker';

const ColorPickerMenu = ({
  subMenuFuncArr,
  isTextType,
  popperRef,
}: ToolsMenuChildProps) => {
  return (
    <ToolsMenu
      subMenuFuncArr={subMenuFuncArr}
      label={
        <>
          <ListItemText primary="Color" />
          <ListItemIcon sx={{ minWidth: 'auto' }}>
            <NavigateNextIcon />
          </ListItemIcon>
        </>
      }
      height={236}
      popperRef={popperRef}
    >
      <Paper>
        <List dense>
          <ListItem sx={{ height: '220px' }} disablePadding>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              width="260px"
              height="260px"
              sx={{ transform: 'scale(0.9)' }}
            >
              <ColorPicker
                editText={isTextType}
                showDefaultColor={false}
                showTitle={false}
              />
            </Stack>
          </ListItem>
        </List>
      </Paper>
    </ToolsMenu>
  );
};

export default ColorPickerMenu;
