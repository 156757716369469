import React from 'react';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuth } from '../../globalHooks/useAuth';
import { MusenWeb_MeDocument } from '../../graphql/resolver.types';
import { useScripts } from '../../layout/utils/LanguageHelper';
import { updateUser } from '../../redux/features/userSlice';
import { useAppDispatch } from '../../redux/store';
import { FromLocation } from '../../routes/ProtectedRoutes';
import { MusenLoginlogo } from '../../svg/MusenLoginlogo';

type LoginProps = {
  url?: string;
};

const Login = ({ url }: LoginProps) => {
  const scripts = useScripts();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const prevPath = (state as FromLocation)?.from?.pathname;
  const {
    signinMutation,
    loginError,
    isAuth,
    role,
    isUserNameError,
    isPasswordError,
    isError,
  } = useAuth();
  const token = localStorage.getItem('token');
  const { loading, data } = useQuery(MusenWeb_MeDocument, {
    skip: !token,
  });

  const webMode = localStorage.getItem('theme');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { username, password } = document.forms[0];

    signinMutation({
      variables: {
        username: username.value,
        password: password.value,
      },
    });
  };

  React.useEffect(() => {
    if (url) {
      navigate(url);
    } else if (isAuth && role) {
      navigate(prevPath && prevPath.includes(role) ? prevPath : `/${role}`);
    }
  }, [isAuth, role, url]);

  React.useEffect(() => {
    if (!loading && data?.me) {
      const me = data.me;
      dispatch(
        updateUser({
          userId: me?.masterId,
          isAuth: true,
          role: me.role,
          group: me.group,
          username: me.username,
          name: me.name,
          email: me?.email,
          token: token,
          tickets: me.tickets,
          loginError: null,
        }),
      );
    }
  }, [data, loading, token]);

  const passwordHelperText =
    isPasswordError || (!isUserNameError && isError)
      ? scripts[
          loginError && loginError.indexOf('Enum "Role" cannot represent value') > -1
            ? 'unknownRole'
            : loginError ?? ''
        ]
      : undefined;

  return (
    <Container component="div" maxWidth="xs">
      <Box sx={{ position: 'absolute', top: '5%', right: '5%' }}>
        <Link href="https://musenenglish.org" sx={{ color: '#00A591' }}>
          {scripts.goToMusen}
        </Link>
      </Box>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '3em',
        }}
      >
        <MusenLoginlogo />
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <TextField
            id="username"
            label={scripts.enterUsername}
            margin="normal"
            required
            autoFocus
            error={isUserNameError}
            helperText={isUserNameError && scripts[loginError ?? '']}
            InputProps={{
              sx: {
                backgroundColor: '#F5F5F5',
                borderRadius: '15px',
              },
            }}
            sx={{
              '& .MuiInputLabel-shrink': {
                color: webMode === 'Light' ? '' : 'white',
                transform: 'translate(14px, -20px) scale(0.75)', // 調整收縮後的標籤位置
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: webMode === 'Light' ? '' : 'white',
              },
              '& .MuiFormHelperText-root': {
                color: 'red',
              },
            }}
          />
          <TextField
            id="password"
            label={scripts.enterPassword}
            margin="normal"
            required
            name="password"
            type="password"
            autoComplete="current-password"
            error={isPasswordError || !!passwordHelperText}
            helperText={passwordHelperText} // password error or unknown error
            InputProps={{
              sx: {
                backgroundColor: '#F5F5F5',
                borderRadius: '15px',
              },
            }}
            sx={{
              '& .MuiInputLabel-shrink': {
                color: webMode === 'Light' ? '' : 'white',
                transform: 'translate(14px, -20px) scale(0.75)', // 調整收縮後的標籤位置
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: webMode === 'Light' ? '' : 'white',
              },
              '& .MuiFormHelperText-root': {
                color: 'red',
              },
            }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              sx={{
                m: 1.5,
                background: '#D9D9D9',
                borderRadius: '10px',
                '&:hover': {
                  borderColor: '#00A591',
                  background: '#00A591',
                  color: '#fff',
                },
              }}
              variant="contained"
              onClick={() => {
                navigate('/signup');
              }}
            >
              {scripts.signUp}
            </Button>
            <Button
              sx={{
                m: 1.5,
                background: '#D9D9D9',
                borderRadius: '10px',
                '&:hover': {
                  borderColor: '#00A591',
                  background: '#00A591',
                  color: '#fff',
                },
              }}
              variant="contained"
              type="submit"
            >
              {scripts.logIn}
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
