import { useLayoutEffect } from 'react';

import { cloneDeep, defaultsDeep } from 'lodash-es';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import { useScripts } from '../../../../layout/utils/LanguageHelper';
import { useStyle } from '../../context/StyleContext';
import { AudioButtonN2 } from '../interactive/AudioButton';

const landscapeProps: DraggableInitProps = {
  type: DraggableType.Audio,
  tags: [DraggableTag.Audio],
  style: {
    width: '12.46722%',
    height: '22.16394%',
  },
  lockAspectRatio: true,
};

export const defaultAudioProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '33.333333%',
      height: '18.750251%',
    },
  },
};

export default function Audio(props: DraggableOuterProps) {
  const scripts = useScripts();

  const { layout, draggablesPropsRef } = useStyle();
  const defaultProps = layout ? defaultAudioProps[layout] : defaultAudioProps.landscape;

  const defaultAudio: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '15vmin',
      height: '15vmin',
    },
    originWrapperStyle: {
      backgroundColor: '#f3f3f3',
      borderRadius: '6px',
      padding: 1,
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(cloneDeep(props), defaultAudio);

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep({
        ...props,
        selectedBar: {
          ...dragProps?.selectedBar,
          getDeleteLabel: () => scripts.deleteThisAudio,
        },
      });
    }
  }, [dragProps]);

  return (
    <WrapperRND {...dragProps}>
      <CenterBox>
        <AudioButtonN2 src={dragProps.audio?.audioUrl} />
      </CenterBox>
    </WrapperRND>
  );
}
