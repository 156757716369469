import IconType from './IconType';

const AddRoundedIcon = ({ width = 40, height = 40, color = '#FFF', style }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M19.6078 25.8823L19.6078 13.3333"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8824 19.6079L13.3334 19.6079"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddRoundedIcon;
