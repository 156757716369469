import { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import { default as Grid } from '@mui/material/Unstable_Grid2';
import { useParams } from 'react-router-dom';

import { useVideoLazyQuery } from '../../graphql/resolver.types';
import Login from '../../landing/views/Login';
import { useScripts } from '../../layout/utils/LanguageHelper';

const SingleVideoPage = () => {
  const scripts = useScripts();

  const token = localStorage.getItem('token');

  const videoURL = window.location.href.slice(window.location.href.indexOf('/video'));
  const aspectRatio = 9 / 16; // 16:9 比例
  const iframeWidth = 0.7; // 與視窗寬度的比例

  const { videoId = '' } = useParams();
  const [youtubeVideoId, setYoutubeVideoId] = useState<string>('');
  const [iframeHeight, setIframeHeight] = useState(
    window.innerWidth * iframeWidth * aspectRatio,
  );

  const [videoWidth, setVideoWidth] = useState<number | null>(null);
  const [videoHeight, setVideoHeight] = useState<number | null>(null);

  // 監聽 video 元素的 loadedmetadata 事件，在影片加載後獲取影片的原始寬度和高度
  const handleVideoLoadedMetadata = (
    event: React.SyntheticEvent<HTMLVideoElement, Event>,
  ) => {
    const target = event.target as HTMLVideoElement;
    setVideoWidth(target.videoWidth);
    setVideoHeight(target.videoHeight);
  };

  useEffect(() => {
    const handleResize = () => {
      setIframeHeight(window.innerWidth * iframeWidth * aspectRatio);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [iframeWidth, aspectRatio]);

  function extractYouTubeVideoId(url: string) {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
    );
    return match && match[1];
  }

  const [videoQuery, { data, loading, error }] = useVideoLazyQuery({
    onCompleted(data) {
      const youtubeVideoId = extractYouTubeVideoId(data?.video?.videoUrl) || '';
      setYoutubeVideoId(youtubeVideoId);
    },
  });

  useEffect(() => {
    if (token) {
      videoQuery({
        variables: {
          videoId,
        },
      });
    }
  }, [token]);

  return (
    <Grid container alignItems="center" justifyContent="center" mt="2em" width="100%">
      {loading ? (
        <Typography>{scripts.loading}</Typography>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{
            width: '80%',
          }}
        >
          {token ? (
            data?.video?.key ? (
              <div
                style={{
                  maxWidth: '800px',
                  aspectRatio: `${videoWidth || 16}/${videoHeight || 9}`,
                  margin: '0 auto',
                  position: 'relative',
                }}
              >
                <video
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  controls
                  controlsList="nodownload"
                  onLoadedMetadata={handleVideoLoadedMetadata}
                >
                  <source src={`${data.video.videoUrl}`} type="video/mp4" />
                </video>
              </div>
            ) : (
              <iframe
                style={{
                  width: `${iframeWidth * 100}vw`, // 使用 vw 單位，相對於視窗寬度的百分比
                  height: `${iframeHeight}px`, // 使用固定高度，根據視窗寬度計算得出
                }}
                src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                title={data?.video?.filename || 'some thing error'}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            )
          ) : (
            <Login url={videoURL} />
          )}

          <Typography
            variant="h5"
            fontWeight={600}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            mt={2.5}
          >
            {data?.video?.filename || data?.video?.urlname}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default SingleVideoPage;
