import IconType from './IconType';

const PlayIcon = ({ width = 19, height = 19, color = '#073015', style }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19Z"
        fill={color}
      />
      <path
        d="M5.90332 9.59378V7.68091C5.90332 5.21228 7.56419 4.21708 9.59415 5.44493L11.1689 6.40136L12.7437 7.35779C14.7736 8.58565 14.7736 10.6019 12.7437 11.8298L11.1689 12.7862L9.59415 13.7426C7.56419 14.9705 5.90332 13.9623 5.90332 11.5066V9.59378Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayIcon;
