import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MicIcon from '@mui/icons-material/Mic';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { cloneDeep, defaultsDeep } from 'lodash-es';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import { useAppSelector } from '../../../../redux/store';
import MicCircleIcon from '../../../../svg/MicCircleIcon';
import PauseIcon from '../../../../svg/PauseIcon';
import PlayIcon from '../../../../svg/PlayIcon';
import RecordingCircleIcon from '../../../../svg/RecordingCircleIcon';
import { getRecognizedText } from '../../../../utils/recognizeAudio';
import { useStyleAnswer } from '../../context/StyleAnswerContext';
import { useStyle } from '../../context/StyleContext';

const landscapeProps: DraggableInitProps = {
  type: DraggableType.RecordAnswer,
  tags: [DraggableTag.Audio],
  style: { width: '8.31043%', height: '14.77633%', color: '#d9d9d9' },
  lockAspectRatio: true,
};

export const defaultRecordAnswerProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '22.221027%',
      height: '12.500705%',
    },
  },
};

export default function RecordAnswer(props: DraggableOuterProps) {
  const {
    layout,
    draggablesPropsRef,
    draggableMapRef,
    selectedEl,
    setSelectedEl,
    getParentId,
    isUploadingAnswer,
    isRecording,
    setIsRecording,
  } = useStyle();
  const defaultProps = layout
    ? defaultRecordAnswerProps[layout]
    : defaultRecordAnswerProps.landscape;

  const defaultRecordAnswer: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '10vmin',
      height: '10vmin',
    },
    originWrapperStyle: {
      width: '12vmin',
      height: 'auto',
      backgroundColor: '#f3f3f3',
      borderRadius: '6px',
      p: 1,
      px: 2,
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultRecordAnswer,
  );

  const recorderRef = useRef<RecordRTC | null>(null);
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | null>(null);

  const startRecording = async () => {
    setMediaBlobUrl(null);
    setIsRecording(true);

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    recorderRef.current = new RecordRTC(stream, {
      type: 'audio',
      mimeType: 'audio/webm;codecs=pcm',
      recorderType: StereoAudioRecorder,
      disableLogs: true,
    });
    recorderRef.current?.startRecording();
  };
  const stopRecording = async () => {
    setIsRecording(false);
    recorderRef.current?.stopRecording(async () => {
      const data = await recorderRef.current?.getBlob();
      if (data) {
        setMediaBlobUrl(URL.createObjectURL(data));
      }
    });
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const containerWidth =
    useAppSelector((state) => state.style.present.container.width) ?? 1;
  const containerHeight =
    useAppSelector((state) => state.style.present.container.height) ?? 1;
  const adminViewMode = useAppSelector((state) => state.adminViewMode.currentMode);

  const { restoreRecordingCounter } = useStyle();
  const { styleAnswers } = useStyleAnswer();

  const recordAgain = useRef(() => {
    startRecording();
  }).current;

  const parentId = getParentId(props?.id ?? '');

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep(props);
      draggablesPropsRef.current[id].selectedBar = {
        recordAgain: () => {
          recordAgain();
          setIsPlaying(true);
          audioRef.current?.pause();
        },
        submitRecording: () => {
          return (async () => {
            if (!mediaBlobUrl) {
              return;
            }

            const response = await fetch(mediaBlobUrl);
            const audio = await response.arrayBuffer();
            const audioBlob = new Blob([audio], { type: 'audio/webm;codecs=pcm' });

            const answer = `${await getRecognizedText(audioBlob)}`;
            const audioBlobUrl = mediaBlobUrl;

            return {
              answer,
              audioBlobUrl,
            };
          })();
        },
      };
    }
  }, [dragProps, mediaBlobUrl]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const ended = () => {
        setIsPlaying(false);
      };

      audio.addEventListener('ended', ended);

      return () => audio.removeEventListener('ended', ended);
    }
  }, []);

  useEffect(() => {
    if (mediaBlobUrl && parentId) {
      const el = draggableMapRef?.current?.[parentId]?.ref?.current?.getSelfElement();
      if (el) {
        setSelectedEl(el);

        return () => {
          setSelectedEl(null);
        };
      }
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (restoreRecordingCounter > 0) {
      setMediaBlobUrl(null);
      setIsPlaying(false);
    }
  }, [restoreRecordingCounter]);

  const mainButtonWidth =
    (Number.parseFloat(`${props.style?.width}`.slice(0, -1)) / 100) * containerWidth;
  const mainButtonHeight =
    (Number.parseFloat(`${props.style?.height}`.slice(0, -1)) / 100) * containerHeight;

  return (
    <WrapperRND {...dragProps}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          ...(adminViewMode === 'view'
            ? null
            : { backgroundColor: dragProps?.style?.backgroundColor }),
          textAlign: 'center',
        }}
      >
        <CenterBox
          sx={[
            {
              width: '100%',
              height: '100%',
              ...(adminViewMode === 'view'
                ? null
                : { backgroundColor: dragProps?.style?.color }),
              borderRadius: '50%',
              mb: 1,
            },
          ]}
        >
          {adminViewMode === 'view' &&
          (() => {
            const parentId = getParentId(props.id ?? '');
            if (parentId) {
              for (const element of styleAnswers) {
                for (const userAnswer of element.userAnswers) {
                  if (userAnswer.draggableId === parentId) {
                    return true;
                  }
                }
              }
            }

            return false;
          })() ? (
            <CheckCircleIcon
              fontSize="large"
              sx={{
                width: `${mainButtonWidth}px`,
                height: `${mainButtonHeight}px`,
              }}
            />
          ) : (
            <>
              {adminViewMode === 'view' && dragProps.isClone ? (
                <>
                  <IconButton
                    disabled={
                      (isRecording && !isPlaying) ||
                      isUploadingAnswer ||
                      (!!selectedEl &&
                        selectedEl !==
                          draggableMapRef?.current?.[
                            parentId
                          ]?.ref?.current?.getSelfElement())
                    }
                    onClick={() => {
                      if (mediaBlobUrl) {
                        if (!isPlaying) {
                          audioRef.current?.play();
                        } else {
                          audioRef.current?.pause();
                        }
                      } else {
                        if (isPlaying) {
                          stopRecording();
                        } else {
                          recordAgain();
                        }
                      }

                      setIsPlaying((isPlaying) => !isPlaying);
                    }}
                  >
                    {isPlaying && !mediaBlobUrl ? (
                      <RecordingCircleIcon
                        width={mainButtonWidth}
                        height={mainButtonHeight}
                      />
                    ) : mediaBlobUrl ? (
                      <>
                        {isPlaying ? (
                          <PauseIcon width={mainButtonWidth} height={mainButtonHeight} />
                        ) : (
                          <PlayIcon width={mainButtonWidth} height={mainButtonHeight} />
                        )}
                      </>
                    ) : (
                      <MicCircleIcon width={mainButtonWidth} height={mainButtonHeight} />
                    )}
                  </IconButton>
                  <audio
                    ref={audioRef}
                    {...(mediaBlobUrl ? { src: mediaBlobUrl } : null)}
                  />
                </>
              ) : (
                <MicIcon sx={{ width: '100%', height: '100%', color: '#9b9b9b' }} />
              )}
            </>
          )}
        </CenterBox>
        {!dragProps.isClone && (
          <Typography sx={{ color: '#000', fontSize: '1.2vw' }}>Record</Typography>
        )}
      </Box>
    </WrapperRND>
  );
}
