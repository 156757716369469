import IconType from './IconType';

const CancelIcon = ({ width = 33, height = 33, color = '#FFF', style }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="16.465" cy="16.4201" r="16.4122" fill={color} />
      <path
        d="M12.0601 21.8306L21.6876 12.2031"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6876 21.8306L12.0601 12.2031"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CancelIcon;
