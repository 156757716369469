import { useLayoutEffect } from 'react';

import { cloneDeep, defaultsDeep } from 'lodash-es';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import Image from './Image';
import {
  DraggableType,
  DraggableTag,
  PositionAlign,
  Layout,
} from '../../../../graphql/resolver.types';
import { useStyle } from '../../context/StyleContext';

const landscapeProps: DraggableInitProps = {
  type: DraggableType.ImageAnswer,
  tags: [DraggableTag.Image],
  style: {
    width: '18.70004%',
    height: '22.16394%',
  },
  lockAspectRatio: true,
  answer: {
    correct: false,
    correctCheckBoxPosition: PositionAlign.TopLeft,
  },
};

export const defaultImageAnswerProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '50%',
      height: '18.750251%',
    },
  },
};

export default function ImageAnswer(props: DraggableOuterProps) {
  const { layout, draggablesPropsRef } = useStyle();
  const defaultProps = layout
    ? defaultImageAnswerProps[layout]
    : defaultImageAnswerProps.landscape;

  const defaultImageAnswer: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '22.5vmin',
      height: '16vmin',
    },
    originWrapperStyle: {
      backgroundColor: '#f3f3f3',
      borderRadius: '6px',
      p: 1,
      pb: 4.25,
      ':before': {
        content: '""',
        display: 'block',
        width: '18px',
        height: '18px',
        border: '2px solid rgba(0, 0, 0, 0.6)',
        borderRadius: '3px',
        mb: 1,
      },
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultImageAnswer,
  );

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = props;
    }
  }, [dragProps]);

  // main
  return <Image {...dragProps} isClone={props?.isClone} />;
}
