import { useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import useUpdateFontStyle, { EditTextType } from './hooks/useUpdateFontStyle';
import CustomAutocomplete, {
  Option,
} from '../../../../globalComponents/CustomAutocomplete';
import { DraggableTag } from '../../../../graphql/resolver.types';
import {
  getDefaultFontSizeNum,
  useFontEditorContext,
} from '../../context/FontEditorContext';
import { useStyle } from '../../context/StyleContext';
import { getFontSizeNumForToolbar } from '../draggable/components/TextFieldBase';
import SelectBase from '../SelectBase';

const FontSizeOption3 = () => {
  const { layout, selectedId, isEditingText } = useStyle();
  const { editorRefs, setFontSizeNumRef } = useFontEditorContext();
  const { updateFontSize } = useUpdateFontStyle(EditTextType.FontSize);

  const optionsRef = useRef(
    (() => {
      const options: Option[] = [];
      for (let i = 12; i < 145; i++) {
        const label = `${i}`;
        options.push({ label, value: label });
      }

      return options;
    })(),
  );

  const [value, setValue] = useState(`${getDefaultFontSizeNum(layout)}`);
  const [isNum, setIsNum] = useState(true);
  const [open, setOpen] = useState(false);
  const newValueRef = useRef(value);
  const numRexRef = useRef(/^\d+(\.{1}\d+)?$/);

  const options = Array.from({ length: 520 - 12 + 1 }, (_, i) => (12 + i).toString());

  const getFontSizeNum = (fontSizeNum: number) => Math.max(fontSizeNum, 12);

  useEffect(() => {
    if (setFontSizeNumRef) {
      setFontSizeNumRef.current = (fontSizeNum: number | null) => {
        if (typeof fontSizeNum === 'number') {
          setValue(`${fontSizeNum}`);
          setIsNum(true);
        } else {
          setIsNum(false);
        }
      };

      const editorState = editorRefs?.editorStateRef.current;
      if (editorState) {
        const fontSizeNum = getFontSizeNumForToolbar(layout, editorState, isEditingText);
        if (typeof fontSizeNum === 'number') {
          setValue(`${fontSizeNum}`);
          setIsNum(true);
        } else {
          setIsNum(false);
        }
      }

      return () => {
        setFontSizeNumRef.current = null;
      };
    }
  }, []);

  const onClose = () => {
    if (numRexRef.current.test(newValueRef.current)) {
      const newFontSizeNum = getFontSizeNum(Number.parseFloat(newValueRef.current));
      setValue(`${newFontSizeNum}`);
      updateFontSize(newFontSizeNum);
      setIsNum(true);
    }
    setOpen(false);
  };
  const customOpenBehavior = {
    open,
    onOpen: () => {
      setOpen(true);
      newValueRef.current = '';
    },
    onClose,
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={0}>
      <IconButton
        disabled={!selectedId}
        onClick={() => {
          newValueRef.current = `${Number.parseFloat(value) + 1}`;
          if (isNum) {
            onClose();
          } else {
            updateFontSize(null, true);
          }
        }}
      >
        <AddIcon />
      </IconButton>
      <SelectBase
        label=""
        property="fontSize"
        acceptTags={[DraggableTag.TextField]}
        options={options}
        currentValue={value.toString()}
        defaultValue={'29'}
      />
      <IconButton
        disabled={!selectedId}
        onClick={() => {
          newValueRef.current = `${Number.parseFloat(value) - 1}`;
          if (isNum) {
            onClose();
          } else {
            updateFontSize(null, false);
          }
        }}
      >
        <RemoveIcon />
      </IconButton>
    </Stack>
  );
};

export default FontSizeOption3;
