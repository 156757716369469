import { DraggableTag } from '../../../../graphql/resolver.types';
import SelectBase from '../SelectBase';

export type FontFamilyList = ['Roboto', 'Arial', 'sans-serif'];
export const fontFamilyList: FontFamilyList = ['Roboto', 'Arial', 'sans-serif'];

export default function FontFamilyOption() {
  const options = fontFamilyList;
  // main
  return (
    <SelectBase
      label=""
      property="fontFamily"
      acceptTags={[DraggableTag.TextField]}
      options={options}
      defaultValue="Roboto"
    />
  );
}
