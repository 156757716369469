import { useRef, useEffect } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import SubMenu, { SubMenuFunc } from './SubMenu';
import { useStyle } from '../../../../context/StyleContext';

export type ToolsMenuChildProps = {
  subMenuFuncArr: (() => void)[];
  isTextType?: boolean;
  popperRef: React.RefObject<HTMLDivElement>;
};

type ToolsMenuProps = {
  subMenuFuncArr: (() => void)[];
  label: React.ReactElement;
  refreshSubMenu?: boolean;
  height?: number;
  popperRef: React.RefObject<HTMLDivElement>;
  children: React.ReactElement;
};

const ToolsMenu = ({
  subMenuFuncArr,
  label,
  refreshSubMenu = false,
  height,
  popperRef,
  children,
}: ToolsMenuProps) => {
  const { selected } = useStyle();
  const subMenuRef = useRef<SubMenuFunc>(null);
  const indexRef = useRef(-1);
  const listItemButtonRef = useRef<HTMLDivElement>(null);
  const isOpenRef = useRef(false);

  const handleOpenSubMenu = () => {
    const subMenu = subMenuRef.current;
    const listItemButton = listItemButtonRef.current;
    if (subMenu && listItemButton) {
      subMenu.open(listItemButton, listItemButton.offsetHeight);
      isOpenRef.current = true;
    }
  };
  const handleCloseSubMenu = () => {
    const subMenu = subMenuRef.current;
    if (subMenu) {
      subMenu.close();
      isOpenRef.current = false;
    }
  };

  useEffect(() => {
    if (indexRef.current === -1) {
      indexRef.current = subMenuFuncArr.length;
      subMenuFuncArr[indexRef.current] = () => {
        listItemButtonRef.current?.classList.remove('Mui-focusVisible');
        handleCloseSubMenu();
      };
    }
  }, []);

  useEffect(() => {
    if (!refreshSubMenu || !isOpenRef.current) {
      return;
    }

    handleCloseSubMenu();
    setTimeout(() => {
      handleOpenSubMenu();
    }, 100);
  }, [selected?.style]);

  return (
    <ListItem sx={{ position: 'relative' }} disablePadding>
      <ListItemButton
        ref={listItemButtonRef}
        onMouseEnter={(e) => {
          const currentTarget = e.currentTarget;
          if (currentTarget instanceof HTMLElement) {
            for (let i = 0; i < subMenuFuncArr.length; i++) {
              if (i !== indexRef.current) {
                subMenuFuncArr[i]();
              }
            }

            listItemButtonRef.current?.classList.add('Mui-focusVisible');
            handleOpenSubMenu();
          }
        }}
        onMouseLeave={() => {
          handleCloseSubMenu();
        }}
      >
        <SubMenu ref={subMenuRef} height={height} popperRef={popperRef}>
          {children}
        </SubMenu>
        {label}
      </ListItemButton>
    </ListItem>
  );
};

export default ToolsMenu;
