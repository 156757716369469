import { useScripts } from '../layout/utils/LanguageHelper';

interface IconType {
  width?: number;
  height?: number;
  color?: string;
  isSelected?: boolean;
  selectedColor?: string;
  style?: React.CSSProperties;
  hoverEffect?: boolean;
  score?: number;
}

const FinalScoreIcon = ({
  width = 259,
  height = 233,
  //color = '#090446',
  style,
  score = 0,
}: IconType) => {
  const scripts = useScripts();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 259 233"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="146" cy="131" r="102" fill="url(#paint0_linear_0_1)" />
      <ellipse cx="249" cy="223.5" rx="10" ry="9.5" fill="url(#paint1_linear_0_1)" />
      <circle cx="235.5" cy="88.5" r="15.5" fill="url(#paint2_linear_0_1)" />
      <circle cx="226" cy="17" r="17" fill="url(#paint3_linear_0_1)" />
      <circle cx="59" cy="202" r="26" fill="url(#paint4_linear_0_1)" />
      <circle cx="8.5" cy="80.5" r="8.5" fill="url(#paint5_linear_0_1)" />
      <circle cx="34" cy="68" r="4" fill="url(#paint6_linear_0_1)" />

      <defs>
        <linearGradient
          id="paint0_linear_0_1"
          x1="104.826"
          y1="29"
          x2="258.294"
          y2="416.413"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A591" />
          <stop offset="1" stopColor="#1FE7CF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_1"
          x1="244.963"
          y1="214"
          x2="258.724"
          y2="250.566"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A591" />
          <stop offset="1" stopColor="#1FE7CF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_1"
          x1="229.243"
          y1="73"
          x2="252.564"
          y2="131.872"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A591" />
          <stop offset="1" stopColor="#1FE7CF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_1"
          x1="219.138"
          y1="-7.90225e-07"
          x2="244.716"
          y2="64.5688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A591" />
          <stop offset="1" stopColor="#1FE7CF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_0_1"
          x1="48.5046"
          y1="176"
          x2="87.6239"
          y2="274.752"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A591" />
          <stop offset="1" stopColor="#1FE7CF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_0_1"
          x1="5.06881"
          y1="72"
          x2="17.8578"
          y2="104.284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A591" />
          <stop offset="1" stopColor="#1FE7CF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_0_1"
          x1="32.3853"
          y1="64"
          x2="38.4037"
          y2="79.1927"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A591" />
          <stop offset="1" stopColor="#1FE7CF" stopOpacity="0" />
        </linearGradient>
      </defs>
      <text
        x="55%"
        y="55%"
        textAnchor="middle"
        fill="white"
        fontSize="80"
        fontFamily="Poppins"
        dy=".3em"
      >
        {score}
      </text>
      <text
        x="55%"
        y="75%"
        textAnchor="middle"
        fill="white"
        fontSize="22"
        fontFamily="Poppins"
        dy=".3em"
      >
        {scripts.finalScore}
      </text>
    </svg>
  );
};

export default FinalScoreIcon;
