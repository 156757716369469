import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { SvgProps } from './MenuItem';
import { useAppSelector } from '../../redux/store';

interface OtherButtonProps extends SvgProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const OtherButton = ({ width, onClick }: OtherButtonProps) => {
  const isScreenDownLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const name = useAppSelector((state) => state.user.name) ?? '';
  const n = name.slice(0, 1).toUpperCase();

  const colorInfo: { [key: string]: string } = {
    A: '#10167f',
    B: '#fe230a',
    C: '#ff9600',
    D: '#000064',
    E: '#b41428',
    F: '#f57814',
    G: '#e6d7aa',
    H: '#505050',
    I: '#f0e6c8',
    J: '#969696',
    K: '#7c81b8',
    L: '#ff967d',
    M: '#ffc87d',
    N: '#10167f',
    O: '#fe230a',
    P: '#ff9600',
    Q: '#000064',
    R: '#b41428',
    S: '#f57814',
    T: '#e6d7aa',
    U: '#505050',
    V: '#f0e6c8',
    W: '#969696',
    X: '#7c81b8',
    Y: '#ff967d',
    Z: '#ffc87d',
  };

  return (
    <Button
      color="inherit"
      sx={{ fontSize: `${width}px`, textTransform: 'none' }}
      {...(onClick ? { onClick } : null)}
    >
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1.5}>
        <Avatar sx={{ bgcolor: colorInfo?.[n] ?? '#bdbdbd' }}>{n}</Avatar>
        {!isScreenDownLg && (
          <>
            <Typography variant="inherit" color="#6B6B6B">
              {name}
            </Typography>
            <ExpandMoreIcon sx={{ color: '#6B6B6B' }} />
          </>
        )}
      </Stack>
    </Button>
  );
};

export default OtherButton;
