import { useEffect, useState } from 'react';

import CustomAutocomplete from '../../../globalComponents/CustomAutocomplete';
import {
  useLevelsWithIdNameBooksWithIdNameLevelProductIdLazyQuery,
  LevelType,
} from '../../../graphql/resolver.types';

type SelectBookProps = {
  levelId?: string;
  required?: boolean;
  onChildSetClickedBookId: (arg: string) => void;
  reset?: (arg: string) => void;
  classId?: string;
  levelType: LevelType;
  willSubmit?: boolean;
};

const SelectBook = ({
  levelId,
  required = false,
  onChildSetClickedBookId,
  reset,
  classId,
  levelType,
  willSubmit = false,
}: SelectBookProps) => {
  const [fetchLevels, { data: levelsData, loading: levelsLoading }] =
    useLevelsWithIdNameBooksWithIdNameLevelProductIdLazyQuery({
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    });

  const levels = levelsData?.levels?.edges;

  const books = levels?.filter(({ node }) => node.id === levelId)?.[0]?.node?.books;

  const [clickedBookId, setClickedBookId] = useState('');

  useEffect(() => {
    if (levelId && books?.[0]?.id) {
      setClickedBookId(books?.[0]?.id);
    } else {
      setClickedBookId('');
    }
  }, [levelId, books]);

  useEffect(() => {
    setClickedBookId('');
    if (classId) {
      fetchLevels({
        variables: {
          first: 0,
          type: levelType,
          classroomId: classId,
        },
      });
    }
  }, [classId]);

  useEffect(() => {
    if (clickedBookId) {
      onChildSetClickedBookId(clickedBookId);
      if (reset) {
        reset('');
      }
    } else {
      onChildSetClickedBookId('');
    }
  }, [clickedBookId]);

  return (
    <CustomAutocomplete
      disabled={!books}
      loading={levelsLoading}
      options={
        books?.map(({ id, name }) => ({
          label: name,
          value: id,
        })) ?? []
      }
      value={
        books
          ?.filter(({ id }) => id === clickedBookId)
          .map(({ id, name }) => ({
            label: name,
            value: id,
          })) ?? []
      }
      onChangeForSingleValue={(e, option) => {
        setClickedBookId(option?.value ?? '');
      }}
      label="Book"
      fullWidth={true}
      dialogProps={{ willSubmit }}
      required={required}
      helperText={willSubmit && !clickedBookId ? 'Required for lesson data' : ''}
    />
  );
};

export default SelectBook;
