import { SvgProps } from './MenuItem';
import { useAuth } from '../../globalHooks/useAuth';

const TeachSvg = ({ width, height, colorForCustomer }: SvgProps) => {
  const { managerRole } = useAuth();

  const webMode = localStorage.getItem('theme');

  if (managerRole) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 17C13.6597 17 17.952 16.9822 18.4717 16.9198C21.2404 16.6437 21.99 15.4857 21.99 12.3147V6.68527C21.99 3.51426 21.2404 2.35632 18.4717 2.08019C17.952 2.01784 17.3723 2 16.7226 2H7.25735C6.60767 2 6.02796 2.01784 5.50822 2.08019C2.7396 2.35632 1.98999 3.51426 1.98999 6.68527V12.3147C1.98999 14.8095 2.45396 16.0582 4 16.6118"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 5.5H19"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 9L19 9"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99999 15.5631C9.5362 15.5631 10.7816 14.3177 10.7816 12.7815C10.7816 11.2453 9.5362 10 7.99999 10C6.46378 10 5.21841 11.2453 5.21841 12.7815C5.21841 14.3177 6.46378 15.5631 7.99999 15.5631Z"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7752 22.3199C12.7752 20.1711 10.6383 18.4282 7.99999 18.4282C5.36169 18.4282 3.22479 20.1711 3.22479 22.3199"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={colorForCustomer} />
    </svg>
  );
};

export default TeachSvg;
