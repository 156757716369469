import IconType from './IconType';

const LayerBringToFrontIcon = ({
  width = 23,
  height = 27,
  color = '#000',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10.7589 9.91332L10.7595 9.91305C11.2289 9.69565 11.7711 9.69565 12.2405 9.91305L12.2411 9.91332L22.061 14.4509L22.0628 14.4517C22.1748 14.5031 22.25 14.6165 22.25 14.7494C22.25 14.8824 22.1748 14.9958 22.0628 15.0472L22.061 15.048L12.2411 19.5856L12.2405 19.5858C11.7711 19.8032 11.2289 19.8032 10.7595 19.5858L10.7589 19.5856L0.942065 15.0494C0.822338 14.9923 0.75 14.8742 0.75 14.7494C0.75 14.6192 0.826827 14.5024 0.937208 14.4517L0.93901 14.4509L10.7589 9.91332ZM22.0608 20.2013L22.0628 20.2023C22.1748 20.2537 22.25 20.367 22.25 20.5C22.25 20.633 22.1748 20.7463 22.0628 20.7977L22.061 20.7986L12.2411 25.3361L12.2405 25.3364C11.7711 25.5538 11.2289 25.5538 10.7595 25.3364L10.7589 25.3361L0.943787 20.8008C0.943215 20.8005 0.942645 20.8003 0.942076 20.8C0.822343 20.7428 0.75 20.6248 0.75 20.5C0.75 20.3697 0.826827 20.2529 0.937209 20.2023L0.93922 20.2013L2.49009 19.4841C2.82276 19.3303 3.20618 19.3302 3.53892 19.4839L9.52789 22.2501L9.52806 22.2502C10.7787 22.8275 12.2213 22.8275 13.4719 22.2502L13.4721 22.2501L19.4611 19.4839C19.7938 19.3302 20.1772 19.3303 20.5099 19.4841L22.0608 20.2013ZM13.1576 21.5692C12.1064 22.0544 10.8936 22.0544 9.84238 21.5692L20.8247 18.8034C20.2924 18.5573 19.679 18.5571 19.1466 18.803L13.1576 21.5692Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M12.7071 0.292893C12.3166 -0.0976311 11.6834 -0.0976311 11.2929 0.292893L4.92893 6.65685C4.53841 7.04738 4.53841 7.68054 4.92893 8.07107C5.31946 8.46159 5.95262 8.46159 6.34315 8.07107L12 2.41421L17.6569 8.07107C18.0474 8.46159 18.6805 8.46159 19.0711 8.07107C19.4616 7.68054 19.4616 7.04738 19.0711 6.65685L12.7071 0.292893ZM13 17V1H11L11 17H13Z"
        fill={color}
      />
    </svg>
  );
};

export default LayerBringToFrontIcon;
