import { createTheme } from '@mui/material/styles';

//#23272F 背景色

const adminDarkTheme = createTheme({
  // palette: {
  //   mode: 'dark', // 确保主题模式为暗色
  //   primary: {
  //     main: '#00A591', // 使用与浅色主题相同的主色
  //   },
  //   background: {
  //     default: '#121212', // 深色背景
  //     paper: '#1E1E1E', // 深色纸张背景
  //   },
  // text: {
  //   primary: '#FFFFFF', // 主要文本颜色为白色
  //   secondary: '#B0B0B0', // 次要文本颜色
  // },
  // },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          // backgroundColor: '#23272F',
        },
        body: {
          backgroundColor: '#23272f',
          //   color: 'red !important',
          //   backgroundColor: 'black',
          //   margin: 0,
          //   padding: 0,
          //   height: '100%',
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          // backgroundColor: '#23272f',
          backgroundColor: 'gray',
          // backgroundColor: 'red',
        },

        // root: {
        //   color: 'gray', // 未选中状态下的颜色
        //   '&.Mui-selected': {
        //     color: 'red', // 选中状态下的颜色
        //   },
        //   '&:hover': {
        //     color: 'blue', // 悬停状态下的颜色
        //   },
        // },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: '#000',
        },
        separator: {
          color: '#000',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },

    MuiButton: {
      // defaultProps: {
      //   // 在这里设置按钮的默认属性
      //   disableRipple: true, // 禁用点击涟漪效果
      // },
      styleOverrides: {
        // 在这里覆盖按钮的默认样式
        root: {
          // 应用于按钮的根元素
          color: '#fff',
          // borderRadius: 8, // 设置按钮的圆角
          // padding: '8px 16px', // 设置按钮的内边距
          // backgroundColor: 'rgba(0, 0, 0, 0.04)', // 设置按钮的背景颜色
          // '&:hover': {
          //   backgroundColor: 'rgba(0, 0, 0, 0.08)', // 设置按钮悬停时的背景颜色
          // },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'gray',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          // color: '#fff !important',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiStack: {
      variants: [
        {
          props: {},
          style: {
            backgroundColor: 'gray ',
          },
        },
      ],
    },

    MuiGrid2: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: 'gray',
        },
      },
    },
    // MuiButton: {
    //   defaultProps: {
    //     // 在这里设置按钮的默认属性
    //     disableRipple: true, // 禁用点击涟漪效果
    //   },
    //   styleOverrides: {
    //     // 在这里覆盖按钮的默认样式
    //     root: {
    //       // 应用于按钮的根元素
    //       color: '#fff !important',
    //       borderRadius: 8, // 设置按钮的圆角
    //       padding: '8px 16px', // 设置按钮的内边距
    //       backgroundColor: 'rgba(0, 0, 0, 0.04)', // 设置按钮的背景颜色
    //       '&:hover': {
    //         backgroundColor: 'rgba(0, 0, 0, 0.08)', // 设置按钮悬停时的背景颜色
    //       },
    //     },
    //   },
    // },
  },
});
export default adminDarkTheme;
