import IconType from './IconType';

const RecordingCircleIcon = ({
  width = 36,
  height = 36,
  color = '#FFF',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="40.5" cy="40" rx="40.5" ry="40" fill="#B8B8B8" />
      <circle cx="41" cy="40" r="15" fill="#FF312D" />
    </svg>
  );
};

export default RecordingCircleIcon;
