import { useLayoutEffect, useState, useEffect } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { cloneDeep, defaultsDeep } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import FallbackImage from './components/FallbackImage';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import { useAuth } from '../../../../globalHooks/useAuth';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import { Draggable } from '../../../../redux/features/styleSlice';
import { useAppSelector } from '../../../../redux/store';
import { getDefaultFontSizeNum } from '../../context/FontEditorContext';
import { useStyleAnswer } from '../../context/StyleAnswerContext';
import { useStyle } from '../../context/StyleContext';
import { getDraggables } from '../../utils/draggables';

export const imageFillBlankChild: Array<Draggable> = [
  {
    id: '',
    type: DraggableType.Image,
    tags: [DraggableTag.Image],
    name: '',
    style: {
      width: '90%',
      height: '37.5%', // 15vmin
      left: '5%',
      top: '2%',
    },
    lockAspectRatio: true,
  },
  {
    id: '',
    type: DraggableType.TextRectAnswer2,
    tags: [DraggableTag.TextField],
    name: 'answer',
    style: {
      width: '90%',
      height: '12.25%', // 4.9vmin
      left: '5%',
      top: '41.5%',
      color: '#000000',
      textAlign: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
      fontFamily: 'Roboto',
      backgroundColor: '#d9d9d9',
    },
    lockAspectRatio: false,
    answer: {
      correct: 'answer',
    },
  },
  {
    id: '',
    type: DraggableType.RandomTextBoard,
    tags: [DraggableTag.Elements],
    name: 'Board',
    style: {
      width: '90%',
      height: '42.5%', // 17vmin
      left: '5%',
      top: '55.75%',
      color: '#000000',
      backgroundColor: '#e6e6e6',
    },
    lockAspectRatio: false,
  },
];

const landscapeProps: DraggableInitProps = {
  type: DraggableType.ImageFillBlankAnswer,
  tags: [DraggableTag.Group],
  style: {
    width: '24.93506%',
    height: '59.10533%',
  },
  lockAspectRatio: false,
  group: [
    {
      ...imageFillBlankChild[0],
      style: {
        ...imageFillBlankChild[0].style,
        width: '22.44155%',
        height: '22.1645%',
        left: '1.24675%',
        top: '1.1821%',
      },
    },
    {
      ...imageFillBlankChild[1],
      style: {
        ...imageFillBlankChild[1].style,
        width: '22.44155%',
        height: '7.2404%',
        left: '1.24675%',
        top: '24.52871%',
      },
    },
    {
      ...imageFillBlankChild[2],
      style: {
        ...imageFillBlankChild[2].style,
        width: '22.44155%',
        height: '25.11976%',
        left: '1.24675%',
        top: '32.95122%',
      },
    },
  ],
};

export const defaultImageFillBlankAnswerProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '66.669533%',
      height: '50.002822%',
    },
    group: [
      {
        ...imageFillBlankChild[0],
        style: {
          ...imageFillBlankChild[0].style,
          width: '60.00258%',
          height: '18.751058%',
          left: '3.333476%',
          top: '1.000056%',
        },
      },
      {
        ...imageFillBlankChild[1],
        style: {
          ...imageFillBlankChild[1].style,
          width: '60.00258%',
          height: '6.125345%',
          left: '3.333476%',
          top: '20.751171%',
        },
      },
      {
        ...imageFillBlankChild[2],
        style: {
          ...imageFillBlankChild[2].style,
          width: '60.00258%',
          height: '21.251199%',
          left: '3.333476%',
          top: '27.876573%',
        },
      },
    ],
  },
};

export default function ImageFillBlankAnswer(props: DraggableOuterProps) {
  //### props.group[0] is ImageBox
  //### props.group[1] is CorrectAnswerBox/UserAnswerBox
  //### props.group[2] is BoardBox
  const { layout, draggablesPropsRef, defineCompletelyDeleteDraggable, getParentId } =
    useStyle();
  const adminViewMode = useAppSelector((state) => state.adminViewMode.currentMode);
  const { styleAnswers } = useStyleAnswer();
  const { styleId = '' } = useParams();

  const { isStudent } = useAuth();
  const [serial, setSerial] = useState(uuidv4());

  const [groupProps, setGroupProps] = useState(() => {
    const modifiedGroupProps = cloneDeep(props.group)?.map((item) => {
      if (item.answer && item.style && typeof item.style.color === 'string') {
        try {
          const colorObj = JSON.parse(item.style.color);
          // Assuming there is always a single block in the blocks array
          if (colorObj.blocks && colorObj.blocks.length > 0) {
            // Modify the text property of the first block
            colorObj.blocks[0].text = '_'.repeat(item.answer.correct.length);
          }
          // Convert the modified object back to a string
          item.style.color = JSON.stringify(colorObj);
        } catch (error) {
          console.error('Error parsing or modifying color string:', error);
        }
      }
      return item;
    });

    return modifiedGroupProps;
  });

  const originGroupProps = cloneDeep(props.group)?.map((item) => {
    if (item.answer && item.style && typeof item.style.color === 'string') {
      try {
        const colorObj = JSON.parse(item.style.color);
        // Assuming there is always a single block in the blocks array
        if (colorObj.blocks && colorObj.blocks.length > 0) {
          // Modify the text property of the first block
          colorObj.blocks[0].text = '_'.repeat(item.answer.correct.length);
        }
        // Convert the modified object back to a string
        item.style.color = JSON.stringify(colorObj);
      } catch (error) {
        console.error('Error parsing or modifying color string:', error);
      }
    }
    return item;
  });

  useEffect(() => {
    const currentAnswer = styleAnswers.find((ele) => ele.styleId === styleId);

    const currentUseAnswer = currentAnswer?.userAnswers.find(
      (ele) => ele.draggableId === getParentId(props.id ?? ''),
    );
    const updatedGroupProps = groupProps?.map((item, index) => {
      if (item.name === 'Board' || item.type === 'Image') {
        return item;
      } else {
        if (
          item.style &&
          item.style.color &&
          typeof currentUseAnswer?.answer === 'string'
        ) {
          const userFillLength = currentUseAnswer?.answer.length;
          const originGroupPropsAbbr = originGroupProps?.[index].style.color;

          const firstUnderlinePos = originGroupPropsAbbr?.indexOf('_') ?? -1;
          const lastUnderlinePos = originGroupPropsAbbr?.lastIndexOf('_') ?? -1;
          const underlineNum = lastUnderlinePos - firstUnderlinePos + 1; //equal correct answer length

          item.style.color = `${originGroupPropsAbbr?.substring(0, firstUnderlinePos)}${
            currentUseAnswer.answer
          }${'_'.repeat(
            Math.max(underlineNum - userFillLength, 0),
          )}${originGroupPropsAbbr?.substring(lastUnderlinePos + 1)}`;
        }
        return item;
      }
    });
    setGroupProps(updatedGroupProps);
  }, [styleAnswers]);

  const defaultProps = layout
    ? defaultImageFillBlankAnswerProps[layout]
    : defaultImageFillBlankAnswerProps.landscape;

  const defaultImageFillBlankAnswer: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '30vmin',
      height: '40vmin',
    },
    group: props.isClone ? undefined : imageFillBlankChild,
    originWrapperStyle: {
      width: '22.5vmin',
      height: 'auto',
      backgroundColor: '#f3f3f3',
      borderRadius: '6px',
      padding: 1,
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultImageFillBlankAnswer,
  );

  useEffect(() => {
    if (isStudent) {
      setSerial(uuidv4());
    }
  }, []);

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep(props);
      defineCompletelyDeleteDraggable(id);
    }
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps} {...(isStudent ? { key: serial } : null)}>
      {dragProps.isClone && dragProps.group && groupProps ? (
        // getDraggables(dragProps.group)
        getDraggables(adminViewMode === 'edit' ? dragProps.group : groupProps)
      ) : (
        <>
          <CenterBox
            sx={{
              width: '20.25vmin',
              height: '13.5vmin',
            }}
          >
            <FallbackImage />
          </CenterBox>
          <CenterBox
            sx={{
              mt: 0.5,
              width: '20.25vmin',
              height: '5vmin',
              backgroundColor: '#d9d9d9',
              pl: 1,
              mb: 1,
            }}
          >
            <Typography sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}>
              answer
            </Typography>
          </CenterBox>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            mt={1}
          >
            {['p', 'i', 'b', 'g', 'f'].map((str) => (
              <CenterBox
                key={str}
                sx={{
                  width: '100%',
                  backgroundColor: '#d9d9d9',
                  borderRadius: '5px',
                }}
              >
                <Typography sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}>
                  {str}
                </Typography>
              </CenterBox>
            ))}
          </Stack>
        </>
      )}
    </WrapperRND>
  );
}
