import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { default as Grid } from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';

import AccountPasswordResetButton from '../../admin/editSchool/components/AccountPasswordResetButton';
import CustomContainer from '../../globalComponents/CustomContainer';
import { useAuth } from '../../globalHooks/useAuth';
import { useScripts } from '../../layout/utils/LanguageHelper';
import { useAppSelector } from '../../redux/store';

const AccountPage = () => {
  const scripts = useScripts();

  const userId = useAppSelector((state) => state.user.userId);
  const username = useAppSelector((state) => state.user.username);
  const name = useAppSelector((state) => state.user.name);
  const role = useAppSelector((state) => state.user.role);
  const email = useAppSelector((state) => state.user.email);
  const isScreenDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { roleText } = useAuth();

  return (
    <CustomContainer hasData={!!username} loading={false} sx={{ mt: -2 }} fixed>
      <Grid container spacing={2} mt={2}>
        <Grid xs={12} position="sticky" top={0} zIndex={1000}>
          <Typography
            variant={isScreenDownSm ? 'h4' : 'h3'}
            fontWeight={600}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            mt={2.5}
          >
            {scripts.myAccount}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{
                      backgroundColor: '#00A591',
                      color: '#FFF',
                      textTransform: 'capitalize',
                      fontSize: '24px',
                      fontWeight: 'bold',
                    }}
                  >
                    {scripts[roleText ?? '']} {scripts.role}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                    {scripts.username}
                  </TableCell>
                  <TableCell>{username}</TableCell>
                  <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                    {scripts.fullName}
                  </TableCell>
                  <TableCell>{name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                    {scripts.email}
                  </TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell colSpan={2}>
                    {role !== 'student' && userId && (
                      <AccountPasswordResetButton userId={userId} variant="outlined" />
                      //   <CustomButton startIcon={<KeyIcon />} variant="outlined">
                      //     Reset Password
                      //   </CustomButton>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </CustomContainer>
  );
};

export default AccountPage;
