import { useEffect, useRef, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { cloneDeep } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import ColorPickerMenu from './ColorPickerMenu';
import CopyDraggableMenu from './CopyDraggableMenu';
import DeleteMenu from './DeleteMenu';
import FontAlignMenu from './FontAlignMenu';
import FontFamilyMenu from './FontFamilyMenu';
import FontSizeMenu from './FontSizeMenu';
import FontWidthMenu from './FontWidthMenu';
import LayerMenu from './LayerMenu';
import StrokeRadiusMenu from './StrokeRadiusMenu';
import StrokeStyleMenu from './StrokeStyleMenu';
import StrokeWidthMenu from './StrokeWidthMenu';
import { DraggableType } from '../../../../../../graphql/resolver.types';
import {
  ClickedArea,
  Draggable,
  addDraggable,
  updateSelected,
} from '../../../../../../redux/features/styleSlice';
import { useAppDispatch } from '../../../../../../redux/store';
import { useStyle } from '../../../../context/StyleContext';

type RightClickMenuProps = {
  id?: string;
  type: DraggableType;
  updateToolsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  draggableElement: HTMLElement | null | undefined;
  isTextType: boolean;
};

const RightClickMenu = ({
  id,
  type,
  updateToolsMenuOpen: updateParentToolsMenuOpen,
  draggableElement,
  isTextType,
}: RightClickMenuProps) => {
  const dispatch = useAppDispatch();

  const { draggables, selectedId, selected, hasRightClickMenuOpenRef } = useStyle();

  const draggablesRef = useRef(draggables);
  draggablesRef.current = draggables;
  const selectedIdRef = useRef(selectedId);
  selectedIdRef.current = selectedId;
  const selectedRef = useRef(selected);
  selectedRef.current = selected;

  const isSelected = selectedId === id;
  const isSelectedRef = useRef(isSelected);
  isSelectedRef.current = isSelected;

  const [toolsMenuAnchorEl, setToolsMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [toolsMenuOpen, setToolsMenuOpen] = useState(false);
  const toolsMenuOpenRef = useRef(toolsMenuOpen);
  toolsMenuOpenRef.current = toolsMenuOpen;

  const subMenuFuncArrRef = useRef<(() => void)[]>([]);
  const isSubDraggableRef = useRef(
    draggables.findIndex((draggable) => draggable.id === id) === -1,
  );
  const hasSubDraggableEnterRef = useRef(false);

  const updateToolsMenuOpen = (toolsMenuOpen: boolean) => {
    setToolsMenuOpen(toolsMenuOpen);
    updateParentToolsMenuOpen(toolsMenuOpen);
  };

  const handleToolsMenuOpen = (target: HTMLElement) => {
    setToolsMenuAnchorEl(target);
    updateToolsMenuOpen(true);
    if (hasRightClickMenuOpenRef) {
      hasRightClickMenuOpenRef.current = true;
    }
  };
  const handleToolsMenuClose = () => {
    setToolsMenuAnchorEl(null);
    updateToolsMenuOpen(false);
    if (hasRightClickMenuOpenRef && toolsMenuOpen) {
      hasRightClickMenuOpenRef.current = false;
    }
  };

  useEffect(() => {
    if (!draggableElement) {
      return;
    }

    const contextmenu = (e: MouseEvent) => {
      e.preventDefault();

      const currentTarget = e.currentTarget;
      if (currentTarget instanceof HTMLElement) {
        const hasChildSelected = (draggables: Draggable[] | undefined) => {
          if (!draggables) {
            return;
          }

          for (const draggable of draggables) {
            if (draggable.id !== selectedIdRef.current) {
              hasChildSelected(draggable?.group as Draggable[] | undefined);
            }
          }
        };

        hasChildSelected(
          ((draggablesRef.current ?? []) as Draggable[]).find(
            (draggable) => draggable.id === id,
          )?.group as Draggable[] | undefined,
        );

        if (!isSelectedRef.current && !hasSubDraggableEnterRef.current) {
          dispatch(
            updateSelected({
              id,
              clickedArea: ClickedArea.Style,
            }),
          );
        }

        if (hasSubDraggableEnterRef.current) {
          handleToolsMenuClose();
        } else {
          handleToolsMenuOpen(currentTarget);
        }
      }
    };
    const click = handleToolsMenuClose;

    draggableElement.addEventListener('contextmenu', contextmenu);
    draggableElement.addEventListener('click', click);

    return () => {
      draggableElement.removeEventListener('contextmenu', contextmenu);
      draggableElement.removeEventListener('click', click);
    };
  }, [draggableElement]);

  useEffect(() => {
    if (selectedId !== id) {
      handleToolsMenuClose();
    }
  }, [selectedId]);

  useEffect(() => {
    if (!draggableElement) {
      return;
    }

    const subDraggableArr: HTMLElement[] = [];
    for (let i = 0, children = draggableElement.children; i < children.length; i++) {
      const element = children.item(i);
      if (
        element &&
        element instanceof HTMLElement &&
        element.classList.contains('react-draggable')
      ) {
        subDraggableArr.push(element);
      }
    }

    if (subDraggableArr.length === 0) {
      for (let i = 0, children = draggableElement.children; i < children.length; i++) {
        const parentElement = children.item(i);
        if (parentElement && parentElement instanceof HTMLElement) {
          for (let j = 0, children = parentElement.children; j < children.length; j++) {
            const element = children.item(j);
            if (
              element &&
              element instanceof HTMLElement &&
              element.classList.contains('react-draggable')
            ) {
              subDraggableArr.push(element);
            }
          }
        }
      }
    }

    const mouseenter = () => {
      hasSubDraggableEnterRef.current = true;
    };
    const mouseleave = () => {
      hasSubDraggableEnterRef.current = false;
    };

    subDraggableArr.forEach((subDraggable) => {
      subDraggable.addEventListener('mouseenter', mouseenter);
      subDraggable.addEventListener('mouseleave', mouseleave);
    });

    return () => {
      subDraggableArr.forEach((subDraggable) => {
        subDraggable.removeEventListener('mouseenter', mouseenter);
        subDraggable.removeEventListener('mouseleave', mouseleave);
      });
    };
  }, [
    draggableElement,
    draggableElement?.children?.length,
    (draggableElement?.children?.length ?? 0) > 0
      ? draggableElement?.children?.item(0)?.children?.length
      : null,
  ]);

  const popperRef = useRef<HTMLDivElement>(null);

  const updateId = (draggable: Draggable) => {
    draggable.id = uuidv4();
    const group = draggable?.group;
    if (group && group.length > 0) {
      group.forEach((subDraggable) => updateId(subDraggable));
    }
  };

  const getNewDraggable = () => {
    if (!selectedRef.current || selectedIdRef.current !== id) {
      return;
    }

    const selectedCopy = cloneDeep(selectedRef.current);
    updateId(selectedCopy);

    const width = Number.parseFloat(`${selectedCopy.style?.width}`);
    const height = Number.parseFloat(`${selectedCopy.style?.height}`);
    const top = Number.parseFloat(`${selectedCopy.style?.top}`);
    const left = Number.parseFloat(`${selectedCopy.style?.left}`);

    if (
      !Number.isFinite(width) ||
      !Number.isFinite(height) ||
      !Number.isFinite(top) ||
      !Number.isFinite(left)
    ) {
      return selectedCopy;
    }

    selectedCopy.style.top = `${Math.min(top + 2.3743 * 2, Math.abs(99 - height))}%`;
    selectedCopy.style.left = `${Math.min(left + 1.3343 * 2, Math.abs(99 - width))}%`;

    return selectedCopy;
  };

  const duplicate = () => {
    const newDraggable = getNewDraggable();
    if (newDraggable) {
      dispatch(addDraggable(newDraggable));
      handleToolsMenuClose();

      const id = newDraggable.id;
      if (typeof id === 'string') {
        dispatch(
          updateSelected({
            id,
            clickedArea: ClickedArea.Style,
          }),
        );
      }
    }
  };

  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (toolsMenuOpenRef.current || isSubDraggableRef.current) {
        return;
      }

      const isMac = /macintosh|mac os x/i.test(navigator?.userAgent ?? '');
      const isKeyD = e.key.toLowerCase() === 'd';
      if (isKeyD && ((!isMac && e.ctrlKey) || (isMac && e.metaKey))) {
        e.preventDefault();
        e.stopPropagation();
        duplicate();
      }
    };
    window.addEventListener('keydown', keydown);

    return () => {
      window.removeEventListener('keydown', keydown);
    };
  }, []);

  const hasCopyDraggableMenu = !isSubDraggableRef.current;
  const hasMenuNotContainCopyDraggableMenu =
    isTextType ||
    type === DraggableType.Square ||
    type === DraggableType.Rectangle ||
    type === DraggableType.Circle ||
    type === DraggableType.Triangle ||
    type === DraggableType.Star;

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleToolsMenuClose}
    >
      <Popper
        ref={popperRef}
        open={toolsMenuOpen}
        anchorEl={toolsMenuAnchorEl}
        placement="right-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <List dense>
                {hasCopyDraggableMenu && (
                  <>
                    <CopyDraggableMenu
                      subMenuFuncArr={subMenuFuncArrRef.current}
                      handleToolsMenuClose={handleToolsMenuClose}
                      duplicate={duplicate}
                    />
                    <LayerMenu
                      subMenuFuncArr={subMenuFuncArrRef.current}
                      popperRef={popperRef}
                    />
                  </>
                )}
                <DeleteMenu
                  subMenuFuncArr={subMenuFuncArrRef.current}
                  handleToolsMenuClose={handleToolsMenuClose}
                />
                {hasCopyDraggableMenu && hasMenuNotContainCopyDraggableMenu && (
                  <Divider />
                )}
                {isTextType && (
                  <>
                    <FontFamilyMenu
                      subMenuFuncArr={subMenuFuncArrRef.current}
                      popperRef={popperRef}
                    />
                    <FontAlignMenu
                      subMenuFuncArr={subMenuFuncArrRef.current}
                      popperRef={popperRef}
                    />
                    <FontSizeMenu
                      subMenuFuncArr={subMenuFuncArrRef.current}
                      popperRef={popperRef}
                    />
                    <FontWidthMenu
                      subMenuFuncArr={subMenuFuncArrRef.current}
                      popperRef={popperRef}
                    />
                  </>
                )}
                {hasMenuNotContainCopyDraggableMenu && (
                  <>
                    <StrokeStyleMenu
                      subMenuFuncArr={subMenuFuncArrRef.current}
                      popperRef={popperRef}
                    />
                    <StrokeWidthMenu
                      subMenuFuncArr={subMenuFuncArrRef.current}
                      popperRef={popperRef}
                    />
                    <StrokeRadiusMenu
                      subMenuFuncArr={subMenuFuncArrRef.current}
                      popperRef={popperRef}
                    />
                    <ColorPickerMenu
                      subMenuFuncArr={subMenuFuncArrRef.current}
                      isTextType={isTextType}
                      popperRef={popperRef}
                    />
                  </>
                )}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default RightClickMenu;
