import { useState, useEffect } from 'react';

import Stack from '@mui/material/Stack';
import { default as Grid } from '@mui/material/Unstable_Grid2';

import {
  CustomAddOrEditDialog,
  CustomTextField,
  OperationResultDialog,
  CustomDateTimePicker,
} from '../../../globalComponents';
import { useOperationResultContext } from '../../../globalContexts/OperationResultContext';
import { useDialog } from '../../../globalHooks';
import { EventsQuery, useAddEventMutation } from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';

type AddEventButtonProps = {
  schoolId: string;
  inputValue: string | undefined;
  setInputValue: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
  updateQuery: (mapFn: (previousQueryResult: EventsQuery) => EventsQuery) => void;
};

const AddEventButton = ({
  schoolId,
  inputValue,
  setInputValue,
  updateQuery,
}: AddEventButtonProps): JSX.Element => {
  const scripts = useScripts();

  const { operationLoading, operationSuccess, commonErrorMessages } =
    useOperationResultContext();

  // states
  const [openDialog, setOpenDialog] = useState(false);
  const [eventName, setEventName] = useState('');
  const [eventStartsAt, setEventStartsAt] = useState(String(new Date()));

  // graphql
  const [addEventMutation, { data, error, loading }] = useAddEventMutation({
    onCompleted(data) {
      const newEvent = data.addEvent;

      // update query events
      updateQuery((previousQueryResult) => {
        if (previousQueryResult.events.pageInfo.hasNextPage) {
          return previousQueryResult;
        }

        const events = { ...previousQueryResult.events };
        const pageInfo = { ...previousQueryResult.events.pageInfo };
        const newEvents = [...events.edges];
        newEvents.push({
          __typename: 'EventEdge',
          cursor: '',
          node: newEvent,
        });
        events.edges = newEvents;
        pageInfo.itemTotal = pageInfo.itemTotal + 1;
        events.pageInfo = pageInfo;

        const newQueryResult = { ...previousQueryResult };
        newQueryResult.events = events;
        return newQueryResult;
      });

      setInputValue({ label: newEvent.name, value: newEvent.id });
      setOpenDialog(false);
      operationSuccess();
    },
    onError(error) {
      commonErrorMessages(error);
    },
  });

  const { dialogProps } = useDialog({
    tooltipTitle: scripts.addEvent,
    dialogTitle: scripts.addEvent,
    dialogTitleAlign: 'center',
    justifyContent: 'center',
    inputCheckResult: () => !!(eventName && eventStartsAt),
    onSubmit: () => {
      addEventMutation({
        variables: {
          schoolId,
          eventInput: { name: eventName, startsAt: eventStartsAt },
        },
      });
      operationLoading();
    },
    onClose: () => {
      setEventName('');
      setEventStartsAt('');
      setOpenDialog(false);
    },
    fullWidth: false,
  });

  useEffect(() => {
    if (inputValue) {
      setOpenDialog(true);
      setEventName(inputValue);
    }
  }, [inputValue]);

  return (
    <>
      <CustomAddOrEditDialog
        isAdd={true}
        {...dialogProps}
        open={openDialog}
        noIcon={true}
      >
        <Grid xs={12} sx={{ justifyContent: 'center' }}>
          <Stack spacing={2}>
            <CustomTextField
              required
              label={scripts.name}
              value={eventName}
              setValue={setEventName}
              dialogProps={dialogProps}
              readOnly={true}
            />
            <CustomDateTimePicker
              required
              label={scripts.date}
              value={eventStartsAt}
              setValue={setEventStartsAt}
              dialogProps={dialogProps}
              endDateTime={String(new Date())}
            />
          </Stack>
        </Grid>
      </CustomAddOrEditDialog>
      {dialogProps.isSubmit && (
        <OperationResultDialog data={data} error={error} loading={loading} />
      )}
    </>
  );
};

export default AddEventButton;
