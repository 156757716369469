import { useState } from 'react';

import { ButtonProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { default as Grid } from '@mui/material/Unstable_Grid2';

import CustomAddOrEditDialog from '../../../globalComponents/CustomAddOrEditDialog';
import CustomButton from '../../../globalComponents/CustomButton';
import CustomTextField from '../../../globalComponents/CustomTextField';
import { useOperationResultContext } from '../../../globalContexts/OperationResultContext';
import useDialog from '../../../globalHooks/useDialog';
import { useResetPasswordMutation } from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';
import KeyIcon from '../../../svg/KeyIcon';

type AccountPasswordResetButtonProps = {
  userId: string;
  variant?: ButtonProps['variant'];
};

const AccountPasswordResetButton = ({
  userId,
  variant,
}: AccountPasswordResetButtonProps) => {
  const scripts = useScripts();

  const [password, setPassword] = useState('');
  const [randomNum] = useState(49 + Math.floor(Math.random() * 100));
  const [defaultPassword] = useState(
    String.fromCodePoint(
      ...Array.from({ length: 6 }, (v, i) => randomNum - (randomNum - (20 * 2 + 9)) + i),
    ),
  );

  const { operationLoading, operationSuccess, commonErrorMessages } =
    useOperationResultContext();

  const [
    resetPasswordMutation,
    {
      // data, error, loading
    },
  ] = useResetPasswordMutation({
    onCompleted() {
      operationSuccess();
    },
    onError(error) {
      commonErrorMessages(error);
    },
  });

  const [isHovered, setIsHovered] = useState(false);

  const { dialogProps } = useDialog({
    tooltipTitle: scripts.resetPassword,
    dialogTitle: scripts.resetPassword,
    resetInput: () => {
      setPassword('');
    },
    onSubmit: () => {
      resetPasswordMutation({
        variables: {
          userId,
          newPassword: password ? password : defaultPassword,
        },
      });
      operationLoading();
    },
    fullWidth: false,
    buttonSize: 'small',
    getCustomButton: (onClick) =>
      variant ? (
        <CustomButton startIcon={<KeyIcon />} variant={variant} onClick={onClick}>
          {scripts.resetPassword}
        </CustomButton>
      ) : (
        <Tooltip title={scripts.resetPassword}>
          <IconButton
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              ':hover': {
                backgroundColor: '#08A590',
              },
            }}
          >
            <KeyIcon color={isHovered ? '#FFF' : '#00A591'} />
          </IconButton>
        </Tooltip>
      ),
  });

  return (
    <CustomAddOrEditDialog
      isAdd={false}
      icon={<KeyIcon color={'#08A590'} />}
      {...dialogProps}
    >
      <Grid xs={12}>
        <Stack spacing={2}>
          <Typography variant="body2">{scripts.newPassword}</Typography>
          <CustomTextField
            value={password}
            setValue={setPassword}
            label={scripts.password}
            type="password"
            size="small"
            dialogProps={dialogProps}
            noTrim
            firstInput
            backgroundColor="#F8F8F8"
            borderLine={'false'}
          />
          <Typography variant="body2">
            {scripts.leaveItBlankToResetThePasswordTo} {defaultPassword}
          </Typography>
        </Stack>
      </Grid>
    </CustomAddOrEditDialog>
  );
};

export default AccountPasswordResetButton;
