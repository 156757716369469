const setSpeechAPI = (req: XMLHttpRequest, userCountry: string) => {
    const {
        REACT_APP_AZURE_SPEECH_KEY = '',
        REACT_APP_AZURE_SERVICE_REGION = '',
    } = process.env;

    const KEY = REACT_APP_AZURE_SPEECH_KEY;
    const URL = `https://${REACT_APP_AZURE_SERVICE_REGION}.stt.speech.microsoft.com/speech/recognition/conversation/cognitiveservices/v1?language=en-US`;

    req.open('POST', URL, true);
    req.setRequestHeader('Ocp-Apim-Subscription-Key', KEY);
    req.setRequestHeader(
        'Content-type',
        'audio/wav;codecs=audio/pcm;samplerate=16000',
    );
    req.setRequestHeader('Accept', 'application/json');
};

export const getRecognizedText = (file: any, userCountry = 'CN') =>
    new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        setSpeechAPI(req, userCountry);
        req.send(file);
        req.onreadystatechange = async () => {
            if (req?.readyState === 4) {
                if (!req?.response) {
                    reject('');
                }

                const res = JSON.parse(req?.response);
                if (res?.DisplayText) {
                    resolve(res?.DisplayText);
                } else {
                    reject('');
                }
            }
        };
        req.onerror = () => reject('');
    });
