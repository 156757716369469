type Configs = {
  [key: string]: { local: string; dev: string; prod: string };
};

const configs: Configs = {};

export const registerConfig = (
  name: string,
  options: { local: string; dev: string; prod: string },
) => {
  const { local, dev, prod } = options;
  configs[name] = { local, dev, prod };
};

registerConfig('musenserver', {
  local: 'http://127.0.0.1:8888',
  dev: 'https://dev.api.musenenglish.org',
  prod: 'https://api.musenenglish.org',
});

export const getConfig = (name: string): string => {
  if (process.env.NODE_ENV === 'production') {
    const host = window.location.hostname?.includes('dev') ? 'dev' : 'prod';
    return configs[name][host];
  }

  return configs[name].local;
};
