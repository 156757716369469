import { makeVar } from '@apollo/client';

export const isDarkModeVar = makeVar(
  window.matchMedia('(prefers-color-scheme: dark)').matches,
);
export const languageVar = makeVar<'US' | 'TW'>('US');

export const hasNewSchoolsDataVar = makeVar<{ [key: string]: boolean }>({});
export const hasNewMakeUpClassesDataVar = makeVar<{ [key: string]: boolean }>({});
export const classroomsPageInfoForDailyVar = makeVar<{
  [key: string]: {
    firstIndex: number;
    lastIndex: number;
  };
}>({});
export const specifyDailyDateVar = makeVar<string | null>(null);
export const checkTeachDailyVar = makeVar<{
  date: Date;
  userId: string;
} | null>(null);
// export const menuHeightVar = makeVar(0);
