import { useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { cloneDeep, defaultsDeep } from 'lodash-es';

import { DraggableInnerProps, DraggableOuterProps } from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import { DraggableTag, DraggableType } from '../../../../graphql/resolver.types';
import { ClickedArea, updateSelected } from '../../../../redux/features/styleSlice';
import { useAppDispatch } from '../../../../redux/store';
import { useStyle } from '../../context/StyleContext';

const Background = (props: DraggableOuterProps) => {
  const defaultBackground: DraggableInnerProps = {
    type: DraggableType.Background,
    tags: [DraggableTag.Elements],
    lockAspectRatio: true,
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultBackground,
  );

  const dispatch = useAppDispatch();
  const { selectedIsResizing } = useStyle();

  const lastSelectedIsResizingRef = useRef(false);
  useEffect(() => {
    if (selectedIsResizing) {
      lastSelectedIsResizingRef.current = selectedIsResizing;
    } else {
      setTimeout(() => {
        lastSelectedIsResizingRef.current = false;
      }, 250);
    }
  }, [selectedIsResizing]);

  const background =
    (dragProps.style?.color ?? '').indexOf('gradient') > -1
      ? dragProps.style?.color ?? null
      : null;
  const bgcolor = !background ? dragProps.style?.color ?? null : null;

  return (
    <WrapperRND {...dragProps}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        width="100%"
        height="100%"
        onClick={() => {
          if (!lastSelectedIsResizingRef.current) {
            dispatch(updateSelected({ id: '', clickedArea: ClickedArea.Style }));
          }
        }}
      >
        <Box
          sx={{
            ...dragProps.style,
            ...(bgcolor ? { bgcolor } : null),
            ...(background ? { background } : null),
            width: 'calc(100% - 4px)',
            height: 'calc(100% - 3px)',
          }}
        />
      </Stack>
    </WrapperRND>
  );
};

export default Background;
