import { useState, useEffect, useMemo, useRef } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { default as Grid } from '@mui/material/Unstable_Grid2';

import { CustomAutocomplete } from '../../../globalComponents';
import CustomButton from '../../../globalComponents/CustomButton';
import { CustomTableProvider } from '../../../globalContexts/CustomTableContext';
import { useDialog, useDataGrid } from '../../../globalHooks';
import { useAuth } from '../../../globalHooks/useAuth';
import {
  useLeadsLazyQuery,
  LeadsQuery,
  LeadForLeadsFragment,
  useLeadsOverviewLazyQuery,
  LeadStatus,
  useSourcesLazyQuery,
  useEventsLazyQuery,
} from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';
import AddCallButton from '../components/AddCallButton';
import AddEventAttendedButton from '../components/AddEventAttendedButton';
import AddOrEditLeadButton from '../components/AddOrEditLeadButton';
import ChangeLeadStatusButton from '../components/ChangeLeadStatusButton';
import LeadInfo from '../components/LeadInfo';
import SelectSchool from '../components/SelectSchool';

type EditLeadProps = {
  node: LeadForLeadsFragment;
  showDetailInfo: JSX.Element | null;
  clickedSchoolId: string;
  updateQuery: (mapFn: (previousQueryResult: LeadsQuery) => LeadsQuery) => void;
};

const EditLead = ({
  node,
  showDetailInfo,
  clickedSchoolId,
  updateQuery,
}: EditLeadProps) => {
  const scripts = useScripts();

  return (
    <Stack
      direction="row"
      justifyContent={{ xs: 'center', lg: 'flex-end' }}
      alignItems="center"
      spacing={0.5}
      position="relative"
      zIndex={1}
    >
      <Stack
        direction="row"
        justifyContent={{ xs: 'space-between', lg: 'flex-end' }}
        alignItems="center"
        spacing={0.5}
        width="100%"
      >
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <AddOrEditLeadButton
            isAdd={false}
            currentLead={node}
            clickedSchoolId={clickedSchoolId}
            updateQuery={updateQuery}
          />
          <Typography variant="body2">{scripts.edit}</Typography>
        </Stack>

        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <ChangeLeadStatusButton selectedLead={node} />
          <Typography variant="body2">{scripts.change}</Typography>
        </Stack>

        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <AddCallButton selectedLead={node} />
          <Typography variant="body2">{scripts.call}</Typography>
        </Stack>

        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <AddEventAttendedButton schoolId={clickedSchoolId} selectedLead={node} />
          <Typography variant="body2">{scripts.event}</Typography>
        </Stack>

        {showDetailInfo}
      </Stack>
    </Stack>
  );
};

const PrincipalLeads = () => {
  const isWidthDown370 = useMediaQuery('(max-width:370px)');
  const isScreenDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const scripts = useScripts();

  // states
  const [clickedSchoolId, setClickedSchoolId] = useState('');
  const [clickedSchoolName, setClickedSchoolName] = useState('');
  const [clickedStatus, setClickedStatus] = useState<string | undefined>();
  const [filterVar, setFilterVar] = useState({});
  const [clickedSourceId, setClickedSourceId] = useState<string | undefined>();
  const [clickedEventId, setClickedEventId] = useState<string | undefined>();
  const [notCalled, setNotCalled] = useState<boolean | undefined>();
  const [noEventsAttended, setNoEventsAttended] = useState<boolean | undefined>();

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isScreenDownLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { managerRole } = useAuth();
  const managerRoleRef = useRef(managerRole);
  managerRoleRef.current = managerRole;
  const isDesktop = !isScreenDownLg;
  const isDesktopRef = useRef(isDesktop);
  isDesktopRef.current = isDesktop;
  // const rootRef = useRef<HTMLDivElement | null>(null);
  //const [titleTop, setTitleTop] = useState(0);

  // const resizeTimerRef = useRef<NodeJS.Timeout | null>(null);

  // useEffect(() => {
  //   const resize = () => {
  //     if (resizeTimerRef.current) {
  //       clearTimeout(resizeTimerRef.current);
  //     }

  //     resizeTimerRef.current = setTimeout(() => {
  //       if (!rootRef.current) {
  //         rootRef.current = document.getElementById('root') as HTMLDivElement | null;
  //       }
  //       const root = rootRef.current as HTMLDivElement;
  //       if (!managerRoleRef.current) {
  //         const menu = root.children.item(0) as HTMLDivElement | null;
  //         if (menu) {
  //           setTitleTop(menu.offsetHeight);
  //           // !isScreenDownLg ? menu.offsetHeight : menu.offsetHeight - 620
  //         }
  //       } else {
  //         setTitleTop(0);
  //       }
  //     }, 100);
  //   };
  //   resize();
  //   window.addEventListener('resize', resize);

  //   return () => {
  //     window.removeEventListener('resize', resize);
  //   };
  // }, []);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // extra query args
  const extraQueryParameters = useMemo(() => {
    return Object.assign({}, { ...(clickedSchoolId && { schoolId: clickedSchoolId }) });
  }, [clickedSchoolId]);

  // graphql
  const [fetchLeadsOverview, { data: leadsOverviewData }] = useLeadsOverviewLazyQuery();
  const [
    fetchLeads,
    { data: leadsData, loading: leadsLoading, error: leadsError, fetchMore, updateQuery },
  ] = useLeadsLazyQuery(filterVar);
  const [fetchSources, { data: sourcesData, loading: sourcesLoading }] =
    useSourcesLazyQuery();
  const [fetchEvents, { data: eventsData, loading: eventsLoading }] =
    useEventsLazyQuery();

  const customerStatusMap: Record<string, string> = {
    customer: scripts.customer,
    lost_customer: scripts.lostCustomers,
    prospect: scripts.leads,
  };

  const checkCustomerStatus = (user: string): string => customerStatusMap[user] || '';

  // main leads table
  const { dataGrid: leadsDataGrid } = useDataGrid<LeadForLeadsFragment>({
    increaseNum: 10,
    fetchDatas: fetchLeads,
    fetchMore,
    data: leadsData,
    error: leadsError,
    loading: leadsLoading || !leadsData,
    extraQueryParameters,
    fieldName: 'leads',
    pauseQuery: !clickedSchoolId,
    noHeadComponent: true,
    type: [
      { cellName: 'status', fieldNameLabel: scripts.status },
      { fieldName: 'name', fieldNameLabel: scripts.parentName },
      { fieldName: 'phone', fieldNameLabel: scripts.phone },
      { cellName: 'childrenNames', label: scripts.childsNames },
      { cellName: 'childrenAge', label: scripts.childsAge },
      { cellName: 'childrenSchool', label: scripts.school },
      { cellName: 'editLead' },
    ],
    customCell: {
      status: (node) => {
        const status = node.status;
        return (
          <Tooltip title={checkCustomerStatus(status)}>
            <IconButton size="small">
              {status === 'lost_customer' ? (
                <CancelIcon color="error" />
              ) : (
                <CheckCircleIcon color={status === 'customer' ? 'success' : 'disabled'} />
              )}
            </IconButton>
          </Tooltip>
        );
      },
      childrenNames: (node) => {
        return node.children.map((child, index) => (
          <Typography key={index} variant="body2">
            {child.englishName + (child.name ? ', ' : '') + child.name}
          </Typography>
        ));
      },
      childrenAge: (node) => {
        return node.children.map((child, index) => (
          <Typography key={index} variant="body2">
            {child.age}
          </Typography>
        ));
      },
      childrenSchool: (node) => {
        return node.children.map((child, index) => (
          <Typography key={index} variant="body2">
            {child.schoolName}
          </Typography>
        ));
      },
      editLead: (node, showDetailInfo) => {
        return (
          <EditLead
            node={node}
            showDetailInfo={showDetailInfo}
            clickedSchoolId={clickedSchoolId}
            updateQuery={updateQuery}
          />
        );
      },
    },
    detailInfo: (node, index) => {
      return <LeadInfo key={`${node.id}-detailInfo`} leadId={node.id} index={index} />;
    },
    getDetailInfoExpandButton: (e) => {
      return (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <IconButton onClick={e}>
            <ExpandCircleDownIcon />
          </IconButton>
          <Typography
            variant="body2"
            display={{ xs: 'block', lg: 'none' }}
            width={{ xs: '56px', lg: 'auto' }}
            textAlign="center"
          >
            {scripts.more}
          </Typography>
        </Stack>
      );
    },
    getDetailInfoCloseButton: (e) => {
      return (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <IconButton onClick={e}>
            <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)' }} />
          </IconButton>
          <Typography
            variant="body2"
            display={{ xs: 'block', lg: 'none' }}
            width={{ xs: '56px', lg: 'auto' }}
            textAlign="center"
          >
            {scripts.collapse}
          </Typography>
        </Stack>
      );
    },
    hasLastColumnButtonGroup: true,
  });

  useEffect(() => {
    if (clickedSchoolId) {
      fetchLeadsOverview({
        variables: {
          schoolId: clickedSchoolId,
        },
      });
    }
  }, [clickedSchoolId]);

  useEffect(() => {
    if (clickedSchoolId) {
      setFilterVar({
        variables: {
          schoolId: clickedSchoolId,
          status: clickedStatus,
          sourceId: clickedSourceId,
          eventId: clickedEventId,
          notCalled: notCalled,
          noEventsAttended: noEventsAttended,
        },
      });
    }
  }, [
    clickedStatus,
    clickedSchoolId,
    clickedSourceId,
    clickedEventId,
    notCalled,
    noEventsAttended,
  ]);

  const { dialogProps } = useDialog({
    dialogTitle: '',
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        position: 'relative',
        width: '100%',
      }}
      fixed
      disableGutters={isWidthDown370}
    >
      <Grid
        xs={12}
        position="sticky"
        top={isScreenDownLg ? -600 : managerRole ? 0 : 60}
        bgcolor="#fcfcfc"
        zIndex={1000}
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography
              variant="h5"
              style={{
                textAlign: 'center',
                fontWeight: 600,
                fontFamily: 'Poppins',
              }}
            >
              {scripts.leads}
            </Typography>
          </Grid>
          <Grid xs={4} md={2}></Grid>
          <Grid
            xs={12}
            md={8}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
            }}
          >
            <SelectSchool
              onChildSetClickedSchoolId={setClickedSchoolId}
              required
              startQuery
              onChildSetClickedSchoolName={setClickedSchoolName}
            />
          </Grid>
          <Grid xs={4} md={2}></Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            container
            sx={{
              width: '100%',
            }}
          >
            <Grid
              sx={{
                width: '40%',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 400,
                  fontFamily: 'Poppins',
                  padding: '16px 8px 0',
                }}
              >
                {scripts.thisMonth}
              </Typography>
            </Grid>
            <Grid
              sx={{
                width: '60%',
              }}
            >
              {isScreenDownMd ? null : (
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 400,
                    fontFamily: 'Poppins',
                    padding: '16px 8px 0',
                  }}
                >
                  {scripts.overview}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid
            spacing={2}
            sx={{
              overflowX: 'scroll',
              width: isScreenDownMd ? '100%' : '40%',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '130px',
                display: 'inline-flex',
                flexDirection: 'row',
              }}
            >
              <Grid xs={3.3}>
                <div style={gridStyle}>
                  <Typography
                    variant="subtitle2"
                    overflow="hidden"
                    sx={{
                      textOverflow: 'ellipsis',
                      fontWeight: 400,
                      width: '100%',
                      padding: 1,
                      fontFamily: 'Poppins',
                    }}
                  >
                    {scripts.newLeads}
                  </Typography>

                  <Typography variant="h4" sx={{ fontWeight: 700, color: '#00A591' }}>
                    {leadsOverviewData?.leadsOverview?.newLeads}
                  </Typography>
                </div>
              </Grid>

              <Grid xs={4.7}>
                <div style={gridStyle}>
                  <Typography
                    variant="subtitle2"
                    overflow="hidden"
                    sx={{
                      textOverflow: 'ellipsis',
                      fontWeight: 400,
                      width: '100%',
                      padding: 1,
                      fontFamily: 'Poppins',
                    }}
                  >
                    {scripts.leadsToCustomers}
                  </Typography>

                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#00A591' }}>
                    {leadsOverviewData?.leadsOverview?.leadsToCustomers}
                  </Typography>
                </div>
              </Grid>
              <Grid xs={4}>
                <div style={gridStyle}>
                  <Typography
                    variant="subtitle2"
                    overflow="hidden"
                    sx={{
                      textOverflow: 'ellipsis',
                      fontWeight: 400,
                      whiteSpace: 'normal',
                      padding: 1,
                      fontFamily: 'Poppins',
                    }}
                  >
                    {scripts.customersLost}
                  </Typography>

                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#00A591' }}>
                    {leadsOverviewData?.leadsOverview?.customersLost}
                  </Typography>
                </div>
              </Grid>
            </div>
          </Grid>

          {isScreenDownMd ? (
            <Typography
              variant="subtitle2"
              sx={{
                margin: 1,
                fontWeight: 400,
                fontFamily: 'Poppins',
              }}
            >
              {scripts.overview}
            </Typography>
          ) : null}

          <Grid
            spacing={2}
            sx={{
              overflowX: 'auto',
              width: isScreenDownMd ? '100%' : '60%',
              '::-webkit-scrollbar': {
                height: '6px',
              },
              scrollbarWidth: 'thin',
            }}
          >
            <div
              style={{
                height: '130px',
                display: 'inline-flex',
                flexDirection: 'row',
                width: isScreenDownMd ? '200%' : '100%',
              }}
            >
              <Grid sx={{ width: '33%' }}>
                <div style={gridStyle}>
                  <Typography
                    variant="subtitle2"
                    overflow="hidden"
                    sx={{
                      textOverflow: 'ellipsis',
                      fontWeight: 400,
                      padding: 1,
                      fontFamily: 'Poppins',
                    }}
                  >
                    {scripts.totalLeads}
                  </Typography>

                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#00A591' }}>
                    {leadsOverviewData?.leadsOverview?.numberOfLeads}
                  </Typography>
                </div>
              </Grid>
              <Grid sx={{ width: '33%' }}>
                <div style={gridStyle}>
                  <Typography
                    variant="subtitle2"
                    overflow="hidden"
                    sx={{
                      textOverflow: 'ellipsis',
                      fontWeight: 400,
                      padding: 1,
                      fontFamily: 'Poppins',
                    }}
                  >
                    {scripts.totalCustomers}
                  </Typography>

                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#00A591' }}>
                    {leadsOverviewData?.leadsOverview?.numberOfCustomers}
                  </Typography>
                </div>
              </Grid>
              <Grid md={0.5}></Grid>

              <Grid sx={{ width: '33%' }}>
                <div style={gridStyle}>
                  <Typography
                    variant="subtitle2"
                    overflow="hidden"
                    sx={{
                      textOverflow: 'ellipsis',
                      fontWeight: 400,
                      padding: 1,
                      fontFamily: 'Poppins',
                    }}
                  >
                    {scripts.averageCallsToClose}
                  </Typography>

                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#00A591' }}>
                    {leadsOverviewData?.leadsOverview?.averageCallsToClose}
                  </Typography>
                </div>
              </Grid>
              <Grid sx={{ width: '33%' }}>
                <div style={gridStyle}>
                  <Typography
                    variant="subtitle2"
                    overflow="hidden"
                    sx={{
                      textOverflow: 'ellipsis',
                      fontWeight: 400,
                      padding: 1,
                      fontFamily: 'Poppins',
                    }}
                  >
                    {scripts.averageEventsToClose}
                  </Typography>

                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#00A591' }}>
                    {leadsOverviewData?.leadsOverview?.averageEventsToClose}
                  </Typography>
                </div>
              </Grid>
            </div>
          </Grid>

          <Grid xs={12} sx={{ paddingTop: 0 }}>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 400, fontFamily: 'Poppins' }}
            >
              {scripts.filter}
            </Typography>
          </Grid>
          <Grid md={12} container spacing={1.5}>
            <Grid
              sx={{
                overflowX: isScreenDownMd ? 'scroll' : 'auto',
                '::-webkit-scrollbar': {
                  height: '6px',
                },
                scrollbarWidth: 'thin',
              }}
            >
              <div
                style={{
                  display: 'inline-flex',
                  flexDirection: 'row',
                  width: isScreenDownMd ? '170%' : '100%',
                }}
              >
                <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    sx={[
                      filterStyle,
                      {
                        backgroundColor:
                          clickedStatus === LeadStatus.Prospect ? '#00A591' : '#D9D9D9',
                      },
                    ]}
                    onClick={() =>
                      setClickedStatus(
                        clickedStatus === LeadStatus.Prospect
                          ? undefined
                          : LeadStatus.Prospect,
                      )
                    }
                  >
                    {scripts.leads}
                  </Button>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    sx={[
                      filterStyle,
                      {
                        backgroundColor:
                          clickedStatus === LeadStatus.Customer ? '#00A591' : '#D9D9D9',
                      },
                    ]}
                    onClick={() =>
                      setClickedStatus(
                        clickedStatus === LeadStatus.Customer
                          ? undefined
                          : LeadStatus.Customer,
                      )
                    }
                  >
                    {scripts.customer}
                  </Button>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    sx={[
                      filterStyle,
                      {
                        backgroundColor:
                          clickedStatus === LeadStatus.LostCustomer
                            ? '#00A591'
                            : '#D9D9D9',
                      },
                    ]}
                    onClick={() =>
                      setClickedStatus(
                        clickedStatus === LeadStatus.LostCustomer
                          ? undefined
                          : LeadStatus.LostCustomer,
                      )
                    }
                  >
                    {scripts.lostCustomers}
                  </Button>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    sx={[
                      filterStyle,
                      {
                        backgroundColor: notCalled ? '#00A591' : '#D9D9D9',
                      },
                    ]}
                    onClick={() => setNotCalled(!notCalled)}
                  >
                    {scripts.notCalled}
                  </Button>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    sx={[
                      filterStyle,
                      {
                        backgroundColor: noEventsAttended ? '#00A591' : '#D9D9D9',
                      },
                    ]}
                    onClick={() => setNoEventsAttended(!noEventsAttended)}
                  >
                    {scripts.noEventsAttended}
                  </Button>
                </Grid>
              </div>
            </Grid>

            <Grid
              xs={6}
              lg={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <CustomAutocomplete
                  loading={sourcesLoading}
                  options={
                    sourcesData?.sources.edges.map(({ node }) => ({
                      label: node.name,
                      value: node.id,
                    })) ?? []
                  }
                  value={
                    sourcesData?.sources.edges
                      ?.filter(({ node }) => node.id === clickedSourceId)
                      .map(({ node }) => ({
                        label: node.name,
                        value: node.id,
                      })) ?? []
                  }
                  onFocusCapture={() =>
                    fetchSources({
                      variables: {
                        first: 0,
                        schoolId: clickedSchoolId,
                      },
                    })
                  }
                  onChangeForSingleValue={(e, option) => {
                    setClickedSourceId(option?.value ?? undefined);
                  }}
                  label={scripts.source}
                  fullWidth
                  size="small"
                  dialogProps={dialogProps}
                  canClearInput
                  //backgroundColor="rgba(0, 0, 0, 0.2)"
                />
              </div>
            </Grid>
            <Grid
              xs={6}
              lg={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <CustomAutocomplete
                  loading={eventsLoading}
                  options={
                    eventsData?.events.edges.map(({ node }) => ({
                      label: node.name,
                      value: node.id,
                    })) ?? []
                  }
                  value={
                    eventsData?.events.edges
                      ?.filter(({ node }) => node.id === clickedEventId)
                      .map(({ node }) => ({
                        label: node.name,
                        value: node.id,
                      })) ?? []
                  }
                  onFocusCapture={() =>
                    fetchEvents({
                      variables: {
                        first: 0,
                        schoolId: clickedSchoolId,
                      },
                    })
                  }
                  onChangeForSingleValue={(e, option) => {
                    setClickedEventId(option?.value ?? undefined);
                  }}
                  label={scripts.events}
                  fullWidth
                  size="small"
                  dialogProps={dialogProps}
                  canClearInput
                  //backgroundColor="rgba(0, 0, 0, 0.2)"
                />
              </div>
            </Grid>

            <Grid
              xs={12}
              lg
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexGrow: 1,
              }}
            >
              <AddOrEditLeadButton
                isAdd={true}
                clickedSchoolId={clickedSchoolId}
                clickedSchoolName={clickedSchoolName}
                updateQuery={updateQuery}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Card variant="outlined" sx={{ width: '100%' }}>
        <CardContent
          sx={{
            backgroundColor: '#FFF',
          }}
        >
          <CustomTableProvider>{leadsDataGrid}</CustomTableProvider>
        </CardContent>
      </Card>
      {scrollPosition > 700 && (
        <Grid xs={6}>
          <CustomButton
            onClick={scrollToTop}
            sx={{
              position: 'fixed',
              bottom: '50px',
              right: '0',
              padding: '0',
              width: '100px',
              margin: '15px',
              textTransform: 'none',
              zIndex: 9999,
            }}
          >
            {scripts.scrollToTop}
          </CustomButton>
        </Grid>
      )}
    </Container>
  );
};

export default PrincipalLeads;

const gridStyle: React.CSSProperties = {
  borderRadius: '10px',
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', //'space-between',
  backgroundColor: '#FFF', //'#D9D9D9',
  boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
};

const filterStyle: React.CSSProperties = {
  backgroundColor: '#D9D9D9',
  borderRadius: '5px',
  color: 'black',
  textTransform: 'none',
};
