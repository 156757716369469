import React, { createContext, useContext, useState, useRef } from 'react';

interface AudioPlay {
  url: string;
  onPlay: () => void;
  onPause: () => void;
  onEnded?: () => void;
}

interface AudioPlayInOrder {
  urls: string[];
  startIndex: number;
  endIndex: number;
  onPlay: () => void;
  onPause: () => void;
  onFinally: () => void;
}

interface AudioContextProps {
  audioSrc: string;
  audioInit: () => void;
  playAudio: (payload: AudioPlay) => void;
  pauseAudio: () => void;
  playAudiosInOrder: (payload: AudioPlayInOrder) => void;
  isPlayingAudios: boolean;
  playBgm: (url: string) => void;
  pauseBgm: () => void;
  errorAudios: string[];
}

const defaultValue: AudioContextProps = {
  audioSrc: '',
  audioInit: () => undefined,
  playAudio: () => undefined,
  pauseAudio: () => undefined,
  playAudiosInOrder: () => undefined,
  isPlayingAudios: false,
  playBgm: () => undefined,
  pauseBgm: () => undefined,
  errorAudios: [],
};

export const AudioContext = createContext(defaultValue);
export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context && typeof window !== 'undefined') {
    throw new Error(`useAudio must be used within a AudioContext `);
  }
  return context;
};

interface AudioProviderProps {
  children: React.ReactNode;
}

export const AudioProvider = ({ children }: AudioProviderProps) => {
  const audio = useRef(new Audio());
  const [audioSrc, setAudioSrc] = useState('');
  const [isPlayingAudios, setIsPlayingAudios] = useState(false);
  const [errorAudios, setErrorAudios] = useState<string[]>([]);
  const [bgm, setBgm] = useState<HTMLAudioElement>();
  // for removeEventListeners func
  const onPlayArr = useRef<any[]>([]);
  const onPauseArr = useRef<any[]>([]);
  const onEndedArr = useRef<any[]>([]);
  // func
  const audioInit = () => {
    // for iOS-Specific Considerations, ref: https://stackoverflow.com/questions/19469881/remove-all-event-listeners-of-specific-type
    audio.current.src =
      'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
  };
  const removeEventListeners = () => {
    // Clean all previous event listeners to avoid repeat triggering
    for (const onPlay of onPlayArr.current)
      audio.current.removeEventListener('play', onPlay);
    for (const onPause of onPauseArr.current)
      audio.current.removeEventListener('pause', onPause);
    for (const onEnded of onEndedArr.current)
      audio.current.removeEventListener('ended', onEnded);
    onPlayArr.current = [];
    onPauseArr.current = [];
    onEndedArr.current = [];
  };
  const playAudio = ({
    url,
    onPlay = () => undefined,
    onPause = () => undefined,
    onEnded = () => undefined,
  }: AudioPlay) => {
    if (!url) return;
    if (errorAudios.length > 0 && errorAudios.includes(url)) return;
    // clean previous event listeners
    removeEventListeners();
    // new audio
    audio.current.src = url;
    audio.current.play().catch(() => {
      // When uploading audio files fails, the 403 error is caught (DOMException: Failed to load because no supported source was found.)
      setErrorAudios((prev) => prev.concat(audio.current.src));
      audio.current.pause();
    });
    setAudioSrc(url);
    audio.current.addEventListener('play', onPlay);
    audio.current.addEventListener('pause', onPause);
    audio.current.addEventListener('ended', onEnded);
    onPlayArr.current.push(onPlay);
    onPauseArr.current.push(onPause);
    onEndedArr.current.push(onEnded);
  };

  const pauseAudio = () => audio.current && audio.current.pause();

  const playAudiosInOrder = (payload: AudioPlayInOrder) => {
    const {
      urls,
      startIndex = 0,
      endIndex = 1,
      onPlay = () => undefined,
      onPause = () => undefined,
      onFinally = () => undefined,
    } = payload;

    playAudio({
      url: urls[startIndex],
      onPlay: () => {
        setIsPlayingAudios(true);
        onPlay();
      },
      onPause: () => {
        setIsPlayingAudios(false);
        onPause();
      },
      onEnded: () => {
        if (startIndex + 1 < endIndex) {
          playAudiosInOrder({
            ...payload,
            startIndex: startIndex + 1,
          });
        } else onFinally();
      },
    });
  };

  const playBgm = (url: string) => {
    if (!url) return;
    bgm && bgm.pause();
    const newBgm = new Audio(url);
    if (typeof newBgm.loop == 'boolean') {
      newBgm.loop = true;
    } else {
      newBgm.addEventListener(
        'ended',
        function () {
          this.currentTime = 0;
          this.play();
        },
        false,
      );
    }
    newBgm.autoplay = true;
    newBgm.volume = 0.1;
    newBgm.play();
    setBgm(newBgm);
  };

  const pauseBgm = () => {
    bgm && bgm.pause();
  };
  // main
  return (
    <AudioContext.Provider
      value={{
        audioSrc,
        audioInit,
        playAudio,
        pauseAudio,
        playAudiosInOrder,
        isPlayingAudios,
        playBgm,
        pauseBgm,
        errorAudios,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};
