import IconType from './IconType';

const BorderRadiusSelectIcon = ({
  width = 24,
  height = 24,
  color = '#000',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M5 13V9C5 6.79086 6.79086 5 9 5H13" stroke={color} strokeLinecap="round" />
    </svg>
  );
};

export default BorderRadiusSelectIcon;
