import React, { useState, useEffect } from 'react';

import Checkbox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../../../../globalHooks/useAuth';
import {
  PositionAlign,
  DraggableAnswer,
  DraggableType,
} from '../../../../../graphql/resolver.types';
import { updateDraggable } from '../../../../../redux/features/styleSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useStyleAnswer, StyleAnswerItem } from '../../../context/StyleAnswerContext';
import { useStyle } from '../../../context/StyleContext';

interface CorrectCheckBoxProps {
  id?: string;
  answer?: DraggableAnswer;
  type: DraggableType;
}

export default function CorrectCheckBox({ id, answer, type }: CorrectCheckBoxProps) {
  const dispatch = useAppDispatch();
  const correctCheckBoxPosition = answer?.correctCheckBoxPosition;
  const correctIsGroupIndex = answer?.correctIsGroupIndex;
  const adminViewMode = useAppSelector((state) => state.adminViewMode.currentMode);
  const { isStudent } = useAuth();

  const { styleId = '' } = useParams();
  const { styleAnswers, setStyleAnswers, disableSelect, styleDraggables } =
    useStyleAnswer();
  const { getParentId, draggables } = useStyle();

  const [checkboxState, setCheckboxState] = useState(false);

  const currentStyleAnswers = styleAnswers.find(
    (styleAnswer) => styleAnswer.styleId === styleId,
  );

  const checkEditorHasChecked = () => {
    //meaning user only can select one option
    let result = false;
    const TrueFalseDraggables = draggables.filter(
      (draggable) => draggable.type === 'TrueFalseAnswer',
    );

    const currentTrueFalseDraggable = TrueFalseDraggables.find(
      (draggable) => draggable.id === getParentId(id ?? ''),
    );

    if (currentTrueFalseDraggable?.group) {
      const selected = currentTrueFalseDraggable?.group.find((ele) => ele.id === id);

      if (selected?.answer?.correct) {
        result = false;
      } else {
        result = currentTrueFalseDraggable.group.some(
          (ele) => ele.answer?.correct === true,
        );
      }
    }
    return result;
  };

  const isMaxAnswerReached = () => {
    const parentId = getParentId(id ?? '');
    const subParentId = getParentId(
      id ?? '',
      draggables.find(({ id }) => id === parentId)?.group ?? [],
    );

    const styleDraggable = styleDraggables.find((styleDraggable) => {
      if (type === 'TrueAnswer' || type === 'FalseAnswer') {
        // ### True/False type
        return styleDraggable.parentDraggableId === parentId;
      } else if (subParentId) {
        // ### cloze type
        return styleDraggable.parentDraggableId === subParentId;
      } else {
        return styleDraggable.parentDraggableId.startsWith('noParentId');
      }
    });

    const selectedChoice = currentStyleAnswers?.userAnswers.find((userAnswer) => {
      return userAnswer.draggableId === id;
    })
      ? false
      : true;

    return styleDraggable?.isDone && selectedChoice;
  };

  useEffect(() => {
    const currentStyleAnswer = styleAnswers.find((styleAnswer) => {
      return styleAnswer.styleId === styleId;
    });
    const checked = currentStyleAnswer?.userAnswers.some((userAnswer) => {
      return userAnswer.draggableId === id;
    });

    if (checked) {
      setCheckboxState(true);
    }
  }, [id, styleAnswers, styleId]);

  // PositionAlign
  const pos: { [key: string]: { left: string; top: string } } = {
    [PositionAlign.TopLeft]: {
      left: '-13px',
      top: '-40px',
    },
    [PositionAlign.Left]: {
      left: '-35px',
      top: '0',
    },
  };
  // func
  function handleCorrectCheckBox(e: React.ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    if (id && typeof answer?.correct === 'boolean') {
      dispatch(
        updateDraggable({
          id: id,
          answer: { correct: !answer.correct },
        }),
      );
    }
  }

  function handleboxState(e: React.ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    setCheckboxState(!checkboxState);

    const updatedAnswerItem = {
      styleId,
      userAnswers: [
        {
          draggableId: id,
          answer: !checkboxState,
          type: 'Choice',
        },
      ],
      isDone: false,
    };

    setStyleAnswers((prevUserAnswer) => {
      const existingStyleAnswer = prevUserAnswer.find((ele) => ele.styleId === styleId);

      if (existingStyleAnswer) {
        // 如果找到相同的 styleId，更新該項

        const existingUserAnswerIndex = existingStyleAnswer.userAnswers.findIndex(
          (ele) => {
            return ele.draggableId === id;
          },
        );

        if (existingUserAnswerIndex !== -1) {
          const newUserAnswers = [...existingStyleAnswer.userAnswers];
          newUserAnswers.splice(existingUserAnswerIndex, 1);

          return [
            ...prevUserAnswer.filter((ele) => ele.styleId !== styleId),
            { ...existingStyleAnswer, userAnswers: newUserAnswers },
          ];
        } else {
          const newUserAnswer = {
            draggableId: id || '',
            answer: !checkboxState,
            type: 'Choice',
          };

          return [
            ...prevUserAnswer.filter((ele) => ele.styleId !== styleId),
            {
              ...existingStyleAnswer,
              userAnswers: [...existingStyleAnswer.userAnswers, newUserAnswer],
            },
          ];
        }
      } else {
        // 如果没有找到相同的 styleId，添加一个新的项StyleAnswer
        return [...prevUserAnswer, updatedAnswerItem] as StyleAnswerItem[];
      }
    });
  }

  return !correctIsGroupIndex &&
    correctCheckBoxPosition &&
    typeof answer?.correct === 'boolean' ? (
    <Checkbox
      // checked={answer?.correct}
      // onChange={handleCorrectCheckBox}
      {...(adminViewMode === 'view' || isStudent
        ? {
            checked: checkboxState,
            onChange: handleboxState,
          }
        : {
            checked: answer?.correct,
            onChange: handleCorrectCheckBox,
          })}
      color="default"
      sx={{
        position: 'absolute',
        ...pos[correctCheckBoxPosition],
      }}
      disabled={
        disableSelect ||
        isMaxAnswerReached() ||
        (adminViewMode === 'edit' && checkEditorHasChecked())
      }
    />
  ) : null;
}
