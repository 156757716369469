import { SvgProps } from './MenuItem';
import { useAuth } from '../../globalHooks/useAuth';
const LeadsSvg = ({ width, height, colorForCustomer }: SvgProps) => {
  const { managerRole } = useAuth();

  const webMode = localStorage.getItem('theme');

  if (managerRole) {
    return (
      <svg
        width="34"
        height="25"
        viewBox="0 0 34 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.37247 20.2363V20.9161C3.37247 21.5776 4.04982 22.114 4.8859 22.114C5.62869 22.114 6.23573 21.6332 6.23573 21.0457V3.42649C6.23573 2.08592 7.60853 1 9.30296 1H29.4335C31.1272 1 32.5 2.08665 32.5 3.42649V20.8056C32.5 22.7082 30.5508 24.25 28.1463 24.25H5.33213C2.93947 24.25 1 22.7155 1 20.8217V6.1874C1 4.8461 2.17231 3.72066 3.74978 3.41844"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6993 13.0403H14.7178C16.0048 13.0403 17.0492 14.1966 17.0492 15.6215V17.1073C17.0492 17.6905 16.6214 18.1642 16.0946 18.1642H10.3218C9.79501 18.1642 9.36719 17.6905 9.36719 17.1073V15.6215C9.36719 14.1966 10.4117 13.0403 11.6986 13.0403H11.6993Z"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.3212 9.74407C15.3212 11.2229 14.3749 12.4225 13.2078 12.4225C12.0407 12.4225 11.0945 11.2237 11.0945 9.74407C11.0945 8.26445 12.0407 7.30786 13.2078 7.30786C14.3749 7.30786 15.3212 8.26445 15.3212 9.74407Z"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.7796 13.8253C22.903 12.8532 22.6039 12.0065 22.4691 11.7464C22.3344 11.4864 22.3469 11.3288 22.5002 11.1621C22.6536 10.9954 23.1724 10.4485 23.4052 10.255C23.638 10.0615 23.5889 9.8848 23.4957 9.64464C23.4024 9.40448 22.7496 8.12414 22.6149 7.86333C22.4802 7.60252 22.4104 7.42813 22.3061 7.36771C22.2018 7.30729 21.7176 7.33712 21.1642 7.59563C20.6109 7.85415 20.1218 8.98229 20.1066 9.47561C20.0914 9.96894 19.9546 10.4401 20.8762 12.652C21.292 13.6501 21.9186 14.4937 22.5486 15.1645L22.57 15.1882C23.1758 15.8857 23.9378 16.5795 24.8393 17.0399C26.8371 18.0602 27.2619 17.9088 27.7082 17.8919C28.1538 17.8751 29.1727 17.3336 29.4062 16.721C29.6397 16.1083 29.6673 15.5722 29.612 15.4567C29.5575 15.3412 29.3993 15.2639 29.1644 15.1148C28.9295 14.9656 27.7731 14.2429 27.5555 14.1396C27.3386 14.0364 27.179 13.9821 27.0043 14.2398C26.8295 14.4976 26.3349 15.072 26.185 15.2418C26.0344 15.4115 25.8921 15.4253 25.6572 15.2762C25.4223 15.127 24.6576 14.7951 23.7796 13.8253Z"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={colorForCustomer} />
    </svg>
  );
};

export default LeadsSvg;
