import { useReactiveVar } from '@apollo/client';

import { SvgProps } from './MenuItem';
import { useAuth } from '../../globalHooks/useAuth';

const ChineseSvg = ({ width, height }: SvgProps) => {
  const { managerRole } = useAuth();
  const language = localStorage.getItem('language');

  const webMode = localStorage.getItem('theme');

  if (managerRole) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0603 18.67L16.9203 14.4L14.7803 18.67"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1699 17.91H18.6899"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9198 22C14.1198 22 11.8398 19.73 11.8398 16.92C11.8398 14.12 14.1098 11.84 16.9198 11.84C19.7198 11.84 21.9998 14.11 21.9998 16.92C21.9998 19.73 19.7298 22 16.9198 22Z"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.02 2H8.94C11.01 2 12.01 3.00002 11.96 5.02002V8.94C12.01 11.01 11.01 12.01 8.94 11.96H5.02C3 12 2 11 2 8.92999V5.01001C2 3.00001 3 2 5.02 2Z"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.01019 5.84998H4.9502"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.9707 5.16998V5.84998"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99043 5.84003C7.99043 7.59003 6.62043 9.01001 4.94043 9.01001"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.01015 9.01001C8.28015 9.01001 7.62016 8.62 7.16016 8"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 15C2 18.87 5.13 22 9 22L7.95 20.25"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 9C22 5.13 18.87 2 15 2L16.05 3.75"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  if (language === 'US' || language === null) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="12" fill="white" />
        <path
          d="M6.256 7.66V12.976C6.256 13.608 6.42 14.084 6.748 14.404C7.084 14.724 7.548 14.884 8.14 14.884C8.74 14.884 9.204 14.724 9.532 14.404C9.868 14.084 10.036 13.608 10.036 12.976V7.66H11.404V12.952C11.404 13.632 11.256 14.208 10.96 14.68C10.664 15.152 10.268 15.504 9.772 15.736C9.276 15.968 8.728 16.084 8.128 16.084C7.528 16.084 6.98 15.968 6.484 15.736C5.996 15.504 5.608 15.152 5.32 14.68C5.032 14.208 4.888 13.632 4.888 12.952V7.66H6.256ZM15.9332 16.084C15.3732 16.084 14.8692 15.988 14.4212 15.796C13.9732 15.596 13.6212 15.316 13.3652 14.956C13.1092 14.596 12.9812 14.176 12.9812 13.696H14.4452C14.4772 14.056 14.6172 14.352 14.8652 14.584C15.1212 14.816 15.4772 14.932 15.9332 14.932C16.4052 14.932 16.7732 14.82 17.0372 14.596C17.3012 14.364 17.4332 14.068 17.4332 13.708C17.4332 13.428 17.3492 13.2 17.1812 13.024C17.0212 12.848 16.8172 12.712 16.5692 12.616C16.3292 12.52 15.9932 12.416 15.5612 12.304C15.0172 12.16 14.5732 12.016 14.2292 11.872C13.8932 11.72 13.6052 11.488 13.3652 11.176C13.1252 10.864 13.0052 10.448 13.0052 9.928C13.0052 9.448 13.1252 9.028 13.3652 8.668C13.6052 8.308 13.9412 8.032 14.3732 7.84C14.8052 7.648 15.3052 7.552 15.8732 7.552C16.6812 7.552 17.3412 7.756 17.8532 8.164C18.3732 8.564 18.6612 9.116 18.7172 9.82H17.2052C17.1812 9.516 17.0372 9.256 16.7732 9.04C16.5092 8.824 16.1612 8.716 15.7292 8.716C15.3372 8.716 15.0172 8.816 14.7692 9.016C14.5212 9.216 14.3972 9.504 14.3972 9.88C14.3972 10.136 14.4732 10.348 14.6252 10.516C14.7852 10.676 14.9852 10.804 15.2252 10.9C15.4652 10.996 15.7932 11.1 16.2092 11.212C16.7612 11.364 17.2092 11.516 17.5532 11.668C17.9052 11.82 18.2012 12.056 18.4412 12.376C18.6892 12.688 18.8132 13.108 18.8132 13.636C18.8132 14.06 18.6972 14.46 18.4652 14.836C18.2412 15.212 17.9092 15.516 17.4692 15.748C17.0372 15.972 16.5252 16.084 15.9332 16.084Z"
          fill="#6B6B6B"
        />
      </svg>
    );
  }

  if (language === 'TW') {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="12" fill="white" />
        <path
          d="M9.288 7.66V8.776H7.068V16H5.7V8.776H3.468V7.66H9.288ZM21.5334 7.66L19.0614 16H17.5134L15.7614 9.688L13.9014 16L12.3654 16.012L10.0014 7.66H11.4534L13.1814 14.452L15.0534 7.66H16.5894L18.3294 14.416L20.0694 7.66H21.5334Z"
          fill="#6B6B6B"
        />
      </svg>
    );
  }

  return null;
};

export default ChineseSvg;
