import IconType from './IconType';

const ReportProblemOutlinedIcon = ({
  width = 25,
  height = 21,
  color = '#FF6663',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M12.5 7.2114V12.0585"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4999 19.2422H5.83241C2.01455 19.2422 0.419193 16.838 2.26761 13.9006L5.70038 8.45246L8.93511 3.33389C10.8936 0.222036 14.1063 0.222036 16.0647 3.33389L19.2994 8.46216L22.7322 13.9103C24.5806 16.8477 22.9743 19.2519 19.1674 19.2519H12.4999V19.2422Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4941 14.9669H12.504"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReportProblemOutlinedIcon;
