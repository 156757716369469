import MenuItem from './MenuItem';
import { useAuth } from '../../globalHooks/useAuth';
import { MenuItemProps } from '../components/MainMenu';

const LeadsMenuItem = ({ selected, isMobile, isMore }: MenuItemProps) => {
  const { managerRole } = useAuth();

  return (
    <MenuItem
      itemType="Leads"
      initWidth={31}
      initHeight={24}
      selected={selected}
      isMobile={isMobile}
      isMore={isMore}
    />
  );
};

export default LeadsMenuItem;
