import IconType from './IconType';

const TextIcon = ({
  width = 24,
  height = 24,
  color = '#073015',
  style,
  isSelected = false,
  selectedColor = '#000',
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        className="custom-icon-selected"
        d="M2.66998 7.16979V5.34979C2.66998 4.19979 3.59998 3.27979 4.73998 3.27979H19.26C20.41 3.27979 21.33 4.20979 21.33 5.34979V7.16979"
        stroke={isSelected ? selectedColor : color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="custom-icon-selected"
        d="M12 20.7199V4.10986"
        stroke={isSelected ? selectedColor : color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="custom-icon-selected"
        d="M8.06 20.7202H15.94"
        stroke={isSelected ? selectedColor : color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TextIcon;
