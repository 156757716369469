export const combineFetchMoreResult = (
  fieldName: string,
  prevResult: any,
  options: any,
  keyFieldName?: string,
) => {
  if (
    !(prevResult && fieldName in prevResult) ||
    !(options?.fetchMoreResult && fieldName in options.fetchMoreResult)
  ) {
    return prevResult;
  }

  const existingDataIds =
    (prevResult[fieldName].edges as any[])
      .map(({ cursor, node }) => (keyFieldName ? node[keyFieldName] : node?.id ?? cursor))
      .join(',') ?? '';
  const fetchMoreResult = { ...options.fetchMoreResult };
  const newEdges = [...(prevResult[fieldName].edges ?? [])];
  const newData = [
    ...(fetchMoreResult[fieldName].edges as any[]).filter(
      ({ cursor, node }) =>
        existingDataIds.indexOf(
          keyFieldName ? node[keyFieldName] : node?.id ?? cursor,
        ) === -1,
    ),
  ];
  const isBefore =
    options &&
    'variables' in options &&
    options['variables'] &&
    'before' in options['variables']
      ? true
      : false;
  if (isBefore) {
    newEdges.unshift(...newData);
  } else {
    newEdges.push(...newData);
  }
  const newDatas = { ...fetchMoreResult[fieldName] };
  newDatas.edges = newEdges;
  fetchMoreResult[fieldName].pageInfo.startCursor = newDatas.edges[0].cursor;
  fetchMoreResult[fieldName].pageInfo.endCursor =
    newDatas.edges[newDatas.edges.length - 1].cursor;

  const newResult = fetchMoreResult;
  newResult[fieldName] = newDatas;

  return newResult;
};
