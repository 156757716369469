import React, { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import { cloneDeep } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { DraggableType, Layout } from '../../../../../graphql/resolver.types';
import {
  ClickedArea,
  Draggable,
  updateSelected,
  DraggableProperty,
  addDraggable,
} from '../../../../../redux/features/styleSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useFontEditorContext } from '../../../context/FontEditorContext';
import { useStyle } from '../../../context/StyleContext';
import { vmin } from '../../../utils/units';
import { updateInitText } from '../../text/hooks/useUpdateFontStyle';
import { defaultAudioProps } from '../Audio';
import { defaultCircleProps } from '../Circle';
import { defaultClozeTestAnswerGroupProps } from '../ClozeTestAnswerGroup';
import { defaultTextFieldProps } from '../CustomText';
import { defaultFillBlankAnswerProps } from '../FillBlankAnswer';
import { defaultImageProps } from '../Image';
import { defaultImageAnswerProps } from '../ImageAnswer';
import { defaultImageAudioAnswerProps } from '../ImageAudioAnswer';
import { defaultImageFillBlankAnswerProps } from '../ImageFillBlankAnswer';
import { defaultRecordAnswerProps } from '../RecordAnswer';
import { defaultRectangleProps } from '../Rectangle';
import { defaultReorderAnswerProps } from '../ReorderAnswer';
import { defaultSquareProps } from '../Square';
import { defaultStarProps } from '../Star';
import { defaultTextCircleAnswerProps } from '../TextCircleAnswer';
import { defaultTextRectAnswerProps } from '../TextRectAnswer';
import { defaultTriangleProps } from '../Triangle';
import { defaultTrueFalseAnswerProps } from '../TrueFalseAnswer';
import { defaultVoiceToTextAnswerProps } from '../VoiceToTextAnswer';

export interface DraggableOuterProps extends Partial<DraggableInnerProps> {
  isClone: boolean; // true is clone, false is origin
  isCORS?: boolean;
  draggables?: Draggable[];
}

export interface DraggableInnerProps extends DraggableProperty {
  isClone?: boolean; // true is clone, false is origin
  index?: number;
  children?: React.ReactNode;
  bounds?: string;
  originWrapperStyle?: SxProps<Theme>; // only used when isClone is false
  groupElement?: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  originId?: string;
  group?: Draggable[];
  selectedBar?: {
    getDeleteLabel?: () => string;
    delete?: (() => void) | boolean;
    lockAspectRatio?: boolean;
    getAddGroupChildLabel?: () => string;
    addGroupChild?: () => void;
    getAddGroupLabel?: () => string;
    addGroup?: () => void;
    recordAgain?: () => void;
    submitRecording?: () => void | Promise<
      { answer: string; audioBlobUrl: string } | undefined
    >;
  };
} // Does not exist in redux styleSlice

export interface DataTransferProps {
  id?: string;
  width: number;
  height: number;
  x: number;
  y: number;
  default: DraggableProperty;
}

export type DraggableInitProps = Omit<Draggable, 'id' | 'name' | 'style'> & {
  id?: string;
  name?: string;
} & { style?: React.CSSProperties } & {
  page?: number;
} & {
  group?: (Draggable & { group?: Draggable[] })[];
};

export type DefaultDraggableProps = {
  [Layout.Landscape]: DraggableInitProps;
  [Layout.Portrait]: DraggableInitProps;
};

export default function DraggableOrigin({
  children,
  type,
  tags,
  style,
  lockAspectRatio,
  answer,
  group,
  page,
  image,
  audio,
  video,
  originWrapperStyle,
}: DraggableInnerProps) {
  const {
    layout,
    droppableRef,
    draggables,
    isEditingMediaRef,
    hasDialogOpenRef,
    largeImageMapRef,
  } = useStyle();

  const updateId = (draggableInnerProps: DraggableInitProps) => {
    draggableInnerProps.id = uuidv4();

    const group = draggableInnerProps?.group;
    if (group) {
      group.forEach((draggableInnerProps) => updateId(draggableInnerProps));
    }
  };

  const initPropsRef = useRef<Partial<Record<DraggableType, DraggableInitProps>>>({
    [DraggableType.Audio]: defaultAudioProps[layout],
    [DraggableType.Circle]: defaultCircleProps[layout],
    [DraggableType.ClozeTestAnswerGroup]: defaultClozeTestAnswerGroupProps[layout],
    [DraggableType.CustomText]: defaultTextFieldProps[layout],
    [DraggableType.FillBlankAnswer]: defaultFillBlankAnswerProps[layout],
    [DraggableType.Image]: defaultImageProps[layout],
    [DraggableType.ImageAnswer]: defaultImageAnswerProps[layout],
    [DraggableType.ImageAudioAnswer]: defaultImageAudioAnswerProps[layout],
    [DraggableType.ImageFillBlankAnswer]: defaultImageFillBlankAnswerProps[layout],
    [DraggableType.RecordAnswer]: defaultRecordAnswerProps[layout],
    [DraggableType.Rectangle]: defaultRectangleProps[layout],
    [DraggableType.ReorderAnswer]: defaultReorderAnswerProps[layout],
    [DraggableType.Square]: defaultSquareProps[layout],
    [DraggableType.Star]: defaultStarProps[layout],
    [DraggableType.TextCircleAnswer]: defaultTextCircleAnswerProps[layout],
    [DraggableType.TextRectAnswer]: defaultTextRectAnswerProps[layout],
    [DraggableType.Triangle]: defaultTriangleProps[layout],
    [DraggableType.TrueFalseAnswer]: defaultTrueFalseAnswerProps[layout],
    [DraggableType.VoiceToTextAnswer]: defaultVoiceToTextAnswerProps[layout],
  });

  const dispatch = useAppDispatch();
  const draggablesRef = useRef(draggables);
  draggablesRef.current = draggables;
  const counter = useAppSelector((state) => state.style.present.counter[layout]);
  const counterRef = useRef(counter);
  counterRef.current = counter;
  const { getTextSizePercent } = useFontEditorContext();

  const boxRef = useRef<HTMLDivElement>(null);

  const [isLoadedImage, setIsLoadedImage] = useState(false);
  const isLoadedImageRef = useRef(isLoadedImage);
  isLoadedImageRef.current = isLoadedImage;

  const isImageType = type === DraggableType.Image;

  const getActualSize = () => {
    const serial = (counterRef.current[DraggableType.CustomText] ?? 0) + 1;
    const isLandscape = layout === Layout.Landscape;
    const textSizePercent = getTextSizePercent(serial, isLandscape);
    const actualWidth =
      type === DraggableType.CustomText
        ? `${
            (((droppableRef?.current?.offsetWidth ?? 0) * textSizePercent.width + 2) /
              (droppableRef?.current?.offsetWidth ?? 0)) *
            100
          }%`
        : isImageType || !initPropsRef.current[type]?.style?.width
        ? `${
            ((boxRef.current?.offsetWidth ?? 0) /
              (droppableRef?.current?.offsetWidth ?? 0)) *
            100
          }%`
        : `${initPropsRef.current[type]?.style?.width}`;
    const actualHeight =
      type === DraggableType.CustomText
        ? `${
            (((droppableRef?.current?.offsetHeight ?? 0) * textSizePercent.height + 2) /
              (droppableRef?.current?.offsetHeight ?? 0)) *
            100
          }%`
        : isImageType || !initPropsRef.current[type]?.style?.height
        ? `${
            ((boxRef.current?.offsetHeight ?? 0) /
              (droppableRef?.current?.offsetHeight ?? 0)) *
            100
          }%`
        : `${initPropsRef.current[type]?.style?.height}`;

    return {
      actualWidth,
      actualHeight,
    };
  };

  const actualSizeRef = useRef(getActualSize());

  const lastDraggablesRef = useRef([...draggables]);
  const locRef = useRef<string[]>([]);
  const topIndexRef = useRef(-1);

  const onClick = () => {
    if (hasDialogOpenRef?.current) {
      return;
    }

    const draggableInnerProps = initPropsRef.current[type];
    if ((isImageType && !isLoadedImageRef.current) || isEditingMediaRef?.current) {
      return;
    }
    if (!draggableInnerProps) {
      return;
    }

    const draggableInnerPropsCopy = cloneDeep(draggableInnerProps);
    updateId(draggableInnerPropsCopy);

    const serial = (counterRef.current[type] ?? 0) + 1;

    topIndexRef.current =
      topIndexRef.current === locRef.current.length - 1 ||
      JSON.stringify(lastDraggablesRef.current) !== JSON.stringify(draggablesRef.current)
        ? -1
        : topIndexRef.current;

    const actualSizeNum = Number.parseFloat(actualSizeRef.current.actualWidth);

    const draggableObj = {
      ...draggableInnerPropsCopy,
      id: `${draggableInnerPropsCopy.id}`,
      name: `${type} ${serial}`, // start from 1, next number
      style: {
        ...draggableInnerPropsCopy.style,
        left: `${Math.abs(100 - actualSizeNum) / 2}%`,
        top: locRef.current[++topIndexRef.current],
        width: actualSizeRef.current.actualWidth,
        height: actualSizeRef.current.actualHeight,
      },
      ...(image ? { image } : null),
      ...(audio ? { audio } : null),
      ...(video ? { video } : null),
    };

    if (image?.imageUrl && draggableObj.image) {
      const imageUrl = largeImageMapRef?.current?.[image.imageUrl] ?? image.imageUrl;
      draggableObj.image = {
        ...draggableObj.image,
        imageUrl,
      };
    }

    if (
      !draggableObj.style.left ||
      !draggableObj.style.top ||
      !draggableObj.style.width ||
      !draggableObj.style.height
    ) {
      topIndexRef.current = -1;
      return;
    }

    updateInitText(draggableObj, false);

    dispatch(addDraggable(draggableObj));
    dispatch(updateSelected({ id: draggableObj.id, clickedArea: ClickedArea.Style }));

    lastDraggablesRef.current = [...draggablesRef.current, draggableObj];
  };

  // func
  function onDragStart(e: React.DragEvent<HTMLDivElement>) {
    const currentRect = e.currentTarget.getBoundingClientRect();
    // originWrapperStyle used in the case of different width or height before and after dragging
    const newWidth =
      (originWrapperStyle as React.CSSProperties)?.width && style?.width
        ? vmin(parseFloat(style.width as string))
        : currentRect.width;
    const newHeight =
      (originWrapperStyle as React.CSSProperties)?.height && style?.height
        ? vmin(parseFloat(style.height as string))
        : currentRect.height;

    const data: DataTransferProps = {
      width: newWidth,
      height: newHeight,
      x: e.clientX - currentRect.left,
      y: e.clientY - currentRect.top,
      default: {
        type: type,
        tags: tags,
        lockAspectRatio,
        style: style,
        answer: answer,
        group: group,
        page: page,
        image: image,
        audio: audio,
        video: video,
      },
    };

    if (image?.imageUrl) {
      const imageUrl = largeImageMapRef?.current?.[image.imageUrl] ?? image.imageUrl;
      data.default.image = {
        ...image,
        imageUrl,
      };
    }

    const draggableInnerProps = initPropsRef.current?.[type];
    if (draggableInnerProps) {
      const draggableInnerPropsCopy = cloneDeep(draggableInnerProps);
      updateId(draggableInnerPropsCopy);

      const droppableWidth = droppableRef?.current?.offsetWidth ?? 0;
      const droppableHeight = droppableRef?.current?.offsetHeight ?? 0;

      data.id = draggableInnerPropsCopy.id;
      data.width =
        (Number.parseFloat(`${actualSizeRef.current.actualWidth ?? 40}`) / 100) *
        droppableWidth;
      data.height =
        (Number.parseFloat(`${actualSizeRef.current.actualHeight ?? 40}`) / 100) *
        droppableHeight;
      data.default.type = draggableInnerPropsCopy.type;
      data.default.tags = draggableInnerPropsCopy.tags;
      data.default.style = draggableInnerPropsCopy.style;
      data.default.answer = draggableInnerPropsCopy.answer;
      data.default.group = draggableInnerPropsCopy.group;
      data.default.page = draggableInnerPropsCopy.page;
      // data.default.image = draggableInnerPropsCopy.image;
      // data.default.audio = draggableInnerPropsCopy.audio;
      // data.default.video = draggableInnerPropsCopy.video;
    }

    e.dataTransfer.setData('style/draggable', JSON.stringify(data));
  }

  const updateActualSizeTimerRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    const updateActualSizeTimer = updateActualSizeTimerRef.current;
    if (updateActualSizeTimer) {
      clearTimeout(updateActualSizeTimer);
    }

    updateActualSizeTimerRef.current = setTimeout(() => {
      actualSizeRef.current = getActualSize();

      const locArr: string[] = [];
      const actualHeightPercent = Number.parseFloat(actualSizeRef.current.actualHeight);
      for (
        let i = 0, firstTopPercent = Math.abs(100 - actualHeightPercent) / 2;
        Number.isFinite(firstTopPercent) &&
        Number.isFinite(actualHeightPercent) &&
        i < 10;
        i++
      ) {
        if (i === 0 && firstTopPercent + actualHeightPercent > 100) {
          firstTopPercent = 100 - actualHeightPercent;
        }

        if (
          locArr.length > 0 &&
          100 - (Number.parseFloat(locArr[locArr.length - 1]) + actualHeightPercent) <
            actualHeightPercent
        ) {
          break;
        }

        locArr.push(`${Math.min(firstTopPercent + actualHeightPercent * i, 99)}%`);
      }
      locRef.current = locArr;
    }, 250);

    if (!isImageType) {
      return;
    }

    const parentElement = boxRef.current?.parentElement;
    if (parentElement) {
      parentElement.addEventListener('click', onClick);

      return () => {
        parentElement.removeEventListener('click', onClick);
      };
    }
  }, [style, isLoadedImage]);

  useEffect(() => {
    if (!isImageType) {
      return;
    }

    const image = boxRef.current?.children.item(0) as HTMLImageElement | null | undefined;
    if (image) {
      const load = () => {
        setIsLoadedImage(true);
      };

      image.addEventListener('load', load);

      return () => {
        image.removeEventListener('load', load);
      };
    }
  }, []);

  return (
    <Box
      ref={boxRef}
      draggable="true"
      onDragStart={onDragStart}
      onMouseDown={(e) => e.stopPropagation()}
      sx={{
        ...style,
        ...originWrapperStyle,
      }}
      {...(!isImageType ? { onClick } : null)}
    >
      {children}
    </Box>
  );
}
