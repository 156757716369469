import LayersIcon from '@mui/icons-material/Layers';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ToolsMenu, { ToolsMenuChildProps } from './ToolsMenu';
import { useAppSelector } from '../../../../../../redux/store';
import LayerBringForwardIcon from '../../../../../../svg/LayerBringForwardIcon';
import LayerBringToFrontIcon from '../../../../../../svg/LayerBringToFrontIcon';
import LayerSendBackwardIcon from '../../../../../../svg/LayerSendBackwardIcon';
import LayerSendToBackIcon from '../../../../../../svg/LayerSendToBackIcon';
import { useStyle } from '../../../../context/StyleContext';

const LayerMenu = ({ subMenuFuncArr, popperRef }: ToolsMenuChildProps) => {
  const isMac = /macintosh|mac os x/i.test(navigator?.userAgent ?? '');
  const {
    draggables,
    selectedId,
    selected,
    hasRightClickMenuOpenRef,
    bringForward,
    bringToFront,
    sendBackward,
    sendToBack,
    showLayers,
  } = useStyle();

  const type = useAppSelector((state) => state.style.present.sideLayout.type);

  const isFront = !!selectedId && draggables?.[draggables.length - 1]?.id === selectedId;
  const isBack = !!selectedId && draggables?.[0]?.id === selectedId;

  return (
    <ToolsMenu
      subMenuFuncArr={subMenuFuncArr}
      label={
        <>
          <ListItemText
            primary={
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                width="100%"
              >
                <LayersIcon />
                <Typography variant="inherit">Layer</Typography>
              </Stack>
            }
          />
          <ListItemIcon sx={{ minWidth: 'auto' }}>
            <NavigateNextIcon />
          </ListItemIcon>
        </>
      }
      refreshSubMenu
      //   height={184}
      popperRef={popperRef}
    >
      <Paper sx={{ '& p': { whiteSpace: 'nowrap' } }}>
        <List dense>
          <ListItem disablePadding>
            <ListItemButton disabled={isFront} onClick={bringForward}>
              <ListItemText
                primary={
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    width="100%"
                  >
                    <LayerBringForwardIcon />
                    <Typography pr={2}>Bring forward</Typography>
                    <Typography
                      variant="inherit"
                      bgcolor="#e6e6e6"
                      borderRadius="4px"
                      px={1}
                      py={0.5}
                      ml="auto !important"
                    >
                      {isMac ? '⌘ Cmd ' : 'Ctrl '}+ {' ]'}
                    </Typography>
                  </Stack>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton disabled={isFront} onClick={bringToFront}>
              <ListItemText
                primary={
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    width="100%"
                  >
                    <LayerBringToFrontIcon />
                    <Typography pr={2}>Bring to front</Typography>
                    <Typography
                      variant="inherit"
                      bgcolor="#e6e6e6"
                      borderRadius="4px"
                      px={1}
                      py={0.5}
                      ml="auto !important"
                    >
                      {isMac ? '⌘ Cmd + ⌥ Option ' : 'Ctrl + Alt '}+ {']'}
                    </Typography>
                  </Stack>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton disabled={isBack} onClick={sendBackward}>
              <ListItemText
                primary={
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    width="100%"
                  >
                    <LayerSendBackwardIcon />
                    <Typography pr={2}>Send backward</Typography>
                    <Typography
                      variant="inherit"
                      bgcolor="#e6e6e6"
                      borderRadius="4px"
                      px={1}
                      py={0.5}
                      ml="auto !important"
                    >
                      {isMac ? '⌘ Cmd ' : 'Ctrl '}+ {' ['}
                    </Typography>
                  </Stack>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton disabled={isBack} onClick={sendToBack}>
              <ListItemText
                primary={
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    width="100%"
                  >
                    <LayerSendToBackIcon />
                    <Typography pr={2}>Send to back</Typography>
                    <Typography
                      variant="inherit"
                      bgcolor="#e6e6e6"
                      borderRadius="4px"
                      px={1}
                      py={0.5}
                      ml="auto !important"
                    >
                      {isMac ? '⌘ Cmd + ⌥ Option ' : 'Ctrl + Alt '}+ {'['}
                    </Typography>
                  </Stack>
                }
              />
            </ListItemButton>
          </ListItem>
          {type !== 'Layers' && (
            <>
              <Divider />
              <ListItem disablePadding>
                <ListItemButton disabled={isBack} onClick={showLayers}>
                  <ListItemText
                    primary={
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        width="100%"
                      >
                        <Typography pr={2}>Show layers</Typography>
                        <Typography
                          variant="inherit"
                          bgcolor="#e6e6e6"
                          borderRadius="4px"
                          px={1}
                          py={0.5}
                          ml="auto !important"
                        >
                          {isMac ? '⌥ Option ' : 'Alt '}+ {'1'}
                        </Typography>
                      </Stack>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
      </Paper>
    </ToolsMenu>
  );
};

export default LayerMenu;
