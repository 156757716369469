import { SvgProps } from './MenuItem';
import { useAuth } from '../../globalHooks/useAuth';

const TodaySvg = ({ width, height, colorForCustomer }: SvgProps) => {
  const { managerRole } = useAuth();

  const webMode = localStorage.getItem('theme');

  if (managerRole) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 2V5"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 2V5"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5 9.09009H20.5"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6947 13.7H15.7037"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6947 16.7H15.7037"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9955 13.7H12.0045"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9955 16.7H12.0045"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.29431 13.7H8.30329"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.29431 16.7H8.30329"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={colorForCustomer} />
    </svg>
  );
};

export default TodaySvg;
