import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CustomButton from '../../../../globalComponents/CustomButton';
import CustomInputText from '../../../../globalComponents/CustomInputText';
import { DraggableTag } from '../../../../graphql/resolver.types';
import BorderRadiusSelectIcon from '../../../../svg/BorderRadiusSelectIcon';
import AutocompleteBase from '../AutocompleteBase';

export default function RadiusOption() {
  const options = ['0', '10', '20', '30', '40', '50'];
  // main
  return (
    <AutocompleteBase
      label=""
      property="borderRadius"
      acceptTags={[DraggableTag.TextField, DraggableTag.Elements]}
      options={options}
      defaultValue="0"
      disableClearable
      sx={{ width: '100%' }}
      filterOptions={(options) => options}
      renderOption={(props, option) => (
        <li {...props}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              width="30px"
              height="30px"
              borderTop="2px solid #000"
              borderLeft="2px solid #000"
              sx={{ borderTopLeftRadius: `${option}px` }}
            />
            <Typography variant="caption">{option}</Typography>
          </Stack>
        </li>
      )}
      renderInput={(params) => {
        return (
          <CustomInputText
            {...params}
            variant="outlined"
            size="small"
            type="number"
            onKeyDown={(e) => e.stopPropagation()}
            sx={{ position: 'relative', width: '100%', p: 0 }}
            inputProps={{ ...params.inputProps, min: 0 }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <CustomButton
                  variant="text"
                  size="small"
                  sx={{
                    position: 'absolute',
                    height: '100%',
                    top: 0,
                    left: 0,
                    minWidth: '36px',
                    borderRadius: '4px',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    color: '#000',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                  }}
                  disabled
                >
                  <BorderRadiusSelectIcon />
                </CustomButton>
              ),
              sx: {
                paddingLeft: '48px !important',
                paddingRight: '14px !important',
              },
            }}
          />
        );
      }}
    />
  );
}
