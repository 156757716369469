import { useId } from 'react';

import { Check } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import { Language, useLanguage, useScripts } from './LanguageHelper';
import MenuItem from './MenuItem';
import { languageVar } from '../../apollo/cacheVar';
import { useAuth } from '../../globalHooks/useAuth';
import usePopoverAnchorEl from '../../globalHooks/usePopoverAnchorEl';
import { MenuItemProps } from '../components/MainMenu';

const ChineseMenuItem = ({ selected, isMobile, isMore }: MenuItemProps) => {
  const scripts = useScripts();

  const { managerRole } = useAuth();

  const { anchorEl, open, handleClick, handleClose } = usePopoverAnchorEl();

  const { language, changeLanguage } = useLanguage();
  const createLanguageHandler = (code: Language) => () => changeLanguage(code);

  const menuId = useId();
  const menuItemId = useId();

  if (managerRole) {
    return (
      <MenuItem
        itemType="Chinese"
        initWidth={91}
        initHeight={24}
        selected={selected}
        isMobile={isMobile}
        isMore={isMore}
      />
    );
  }

  if (isMobile && !managerRole) {
    return [
      <Divider key={`${menuId}-2`}>{scripts.language}</Divider>,
      <MuiMenuItem
        key={`${menuId}-0`}
        selected={language === 'US'}
        onClick={createLanguageHandler('US')}
      >
        English
      </MuiMenuItem>,
      <MuiMenuItem
        key={`${menuId}-1`}
        selected={language === 'TW'}
        onClick={createLanguageHandler('TW')}
      >
        繁體中文
      </MuiMenuItem>,
    ] as unknown as JSX.Element;
  }

  if (language === 'US' || language === 'TW') {
    return (
      <>
        <Tooltip title={scripts.language}>
          <IconButton onClick={handleClick}>
            <MenuItem
              itemType="Chinese"
              initWidth={24}
              initHeight={24}
              selected={selected}
              isMobile={isMobile}
              isMore={isMore}
            />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{ zIndex: 10000 }}
        >
          {language === 'US' && [
            <MuiMenuItem
              key={`${menuItemId}-${managerRole}-0`}
              selected
              onClick={createLanguageHandler('US')}
            >
              <ListItemIcon>
                <Check />
              </ListItemIcon>
              English
            </MuiMenuItem>,
            <MuiMenuItem
              key={`${menuItemId}-${managerRole}-1`}
              onClick={createLanguageHandler('TW')}
            >
              <ListItemText inset>繁體中文</ListItemText>
            </MuiMenuItem>,
          ]}
          {language === 'TW' && [
            <MuiMenuItem
              key={`${menuItemId}-${managerRole}-2`}
              onClick={createLanguageHandler('US')}
            >
              <ListItemText inset>English</ListItemText>
            </MuiMenuItem>,
            <MuiMenuItem
              key={`${menuItemId}-${managerRole}-3`}
              selected
              onClick={createLanguageHandler('TW')}
            >
              <ListItemIcon>
                <Check />
              </ListItemIcon>
              繁體中文
            </MuiMenuItem>,
          ]}
        </Menu>
      </>
    );
  }

  return null;
};

export default ChineseMenuItem;
