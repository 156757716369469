import React from 'react';

import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';

type Points = [{ x: number; y: number }];
type CurrentToolType = string;
type Color = string;
type Brush = string;
type EraserSize = number;
export type HistoryRecord = ([Points, CurrentToolType, Color, Brush, EraserSize] | [])[];

type InitialState = {
  currentToolType: string;
  brushType: string;
  brushColor: string;
  eraserSize: number;
  spotlightSize: number;
  spotlightType: string;
  toolbarHistory: HistoryRecord;
  toolbarHistoryStep: number;
  historyRedoUndoStore: HistoryRecord;
};

const initialState: InitialState = {
  currentToolType: '',
  brushType: 'brush', //brush heightlighter
  brushColor: '#000000',
  eraserSize: 15,
  spotlightSize: 10, //small normal big
  spotlightType: 'circle', //circle square rectangle
  toolbarHistory: [],
  toolbarHistoryStep: 0,
  historyRedoUndoStore: [],
};

const toolbarSlice = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    setCurrentToolType: (state, action) => {
      const type = ['Notes', 'Redo', 'Undo', 'NextStyle', 'PrevStyle'];
      if (type.includes(action.payload)) {
        return;
      }
      state.currentToolType = action.payload;
    },
    setBrushType: (state, action) => {
      state.brushType = action.payload;
    },

    setBrushColor: (state, action) => {
      state.brushColor = action.payload;
    },

    setEraserSize: (state, action) => {
      state.eraserSize = action.payload;
    },

    setSpotlightSize: (state, action) => {
      state.spotlightSize = action.payload;
    },

    setSpotlightType: (state, action) => {
      state.spotlightType = action.payload;
    },

    setDrawlineToolbarHistory: (state, action) => {
      const newState = cloneDeep(state);

      if (newState.historyRedoUndoStore.length > 0) {
        newState.historyRedoUndoStore.length = 0;
      }

      newState.toolbarHistory.push([
        cloneDeep(action.payload.points),
        action.payload.currentToolType,
        action.payload.brushColor,
        action.payload.brushType,
        action.payload.eraserSize,
      ]);

      newState.toolbarHistoryStep++;

      return newState;
    },

    undoToolbarHistory: (state) => {
      const newState = cloneDeep(state);

      if (newState.toolbarHistory.length === 0) {
        return newState;
      }

      const commandObj = newState.toolbarHistory.pop();
      if (commandObj) {
        newState.historyRedoUndoStore.unshift(commandObj);
      }

      return newState;
    },

    redoToolbarHistory: (state) => {
      const newState = cloneDeep(state);

      if (newState.historyRedoUndoStore.length === 0) {
        return newState;
      }

      const commandObj = newState.historyRedoUndoStore.shift();
      if (commandObj) {
        newState.toolbarHistory.push(commandObj);
      }

      return newState;
    },

    deleteAllToolbarHistory: (state, action) => {
      return initialState;
    },
  },
});

export const {
  setCurrentToolType,
  setBrushType,
  setBrushColor,
  setEraserSize,
  setSpotlightSize,
  setSpotlightType,
  setDrawlineToolbarHistory,
  undoToolbarHistory,
  redoToolbarHistory,
  deleteAllToolbarHistory,
} = toolbarSlice.actions;
export default toolbarSlice.reducer;
