import IconType from './IconType';

const LayerSendBackwardIcon = ({
  width = 23,
  height = 23,
  color = '#000',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M12.2411 10.5855L12.2405 10.5857C11.7711 10.8031 11.2289 10.8031 10.7595 10.5857L10.7589 10.5855L0.939013 6.0479L0.937208 6.04707C0.825226 5.99569 0.75 5.8823 0.75 5.74934C0.75 5.61638 0.825226 5.50299 0.93721 5.45161L0.939013 5.45078L10.7589 0.913225L10.7595 0.912951C11.2289 0.695552 11.7711 0.695552 12.2405 0.912951L12.2411 0.913225L22.0579 5.44937C22.1777 5.50651 22.25 5.62459 22.25 5.74934C22.25 5.87959 22.1732 5.99642 22.0628 6.04707L22.061 6.0479L12.2411 10.5855Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M10.2929 22.2059C10.6834 22.5964 11.3166 22.5964 11.7071 22.2059L18.0711 15.8419C18.4616 15.4514 18.4616 14.8182 18.0711 14.4277C17.6805 14.0372 17.0474 14.0372 16.6569 14.4277L11 20.0846L5.34315 14.4277C4.95262 14.0372 4.31946 14.0372 3.92893 14.4277C3.53841 14.8182 3.53841 15.4514 3.92893 15.8419L10.2929 22.2059ZM10 5.49878L10 21.4988H12L12 5.49878H10Z"
        fill={color}
      />
    </svg>
  );
};

export default LayerSendBackwardIcon;
