import IconType from './IconType';

const CheckCircleIcon = ({
  width = 31,
  height = 31,
  color = '#FFF',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="15.5" cy="15.5" r="15.5" fill={color} />
      <path
        d="M9 15.5L13.3282 20L22 11"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckCircleIcon;
