import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { convertToRaw } from 'draft-js';
import { cloneDeep } from 'lodash-es';
import { ResizeDirection } from 're-resizable';
import { Rnd, DraggableData, RndDragEvent, ResizableDelta } from 'react-rnd';
import { v4 as uuidv4 } from 'uuid';

import ButtonBar from './ButtonBar';
import { DraggableInnerProps, DataTransferProps } from './DraggableOrigin';
import RightClickMenu from './menu/RightClickMenu';
import { useAudio } from '../../../../../globalContexts/AudioContext';
import { useAuth } from '../../../../../globalHooks/useAuth';
import { DraggableTag, DraggableType } from '../../../../../graphql/resolver.types';
import {
  ClickedArea,
  scalingGroupWithoutHistory,
  updateDraggable,
  updateDraggableWithoutHistory,
  updateSelected,
} from '../../../../../redux/features/styleSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useFontEditorContext } from '../../../context/FontEditorContext';
import { useStyle } from '../../../context/StyleContext';
import useSideBar from '../../../hooks/useSideBar';
import ResizeHandle from '../../ResizeHandle';

export default function DraggableRND({
  children,
  id,
  type,
  tags,
  style,
  lockAspectRatio,
  answer,
  index,
  bounds = 'parent',
  page,
  group,
  groupElement,
  originId,
}: DraggableInnerProps) {
  const dispatch = useAppDispatch();
  const containerWidth =
    useAppSelector((state) => state.style.present.container.width) ?? 1;
  const containerHeight =
    useAppSelector((state) => state.style.present.container.height) ?? 1;

  const adminViewMode = useAppSelector((state) => state.adminViewMode.currentMode);

  const {
    draggables,
    selectedId,
    setSelectedEl,
    setSelectedIsDragging,
    selectedIsResizing,
    setSelectedIsResizing,
    isEditingText,
    draggableMapRef,
    draggablesPropsRef,
    groupAdjusterRef,
    notShownWhenSavingClass,
  } = useStyle();
  const selectedIsResizingRef = useRef(selectedIsResizing);
  selectedIsResizingRef.current = selectedIsResizing;
  const { switchItemByTags } = useSideBar();
  const { editorRefs, textLockAspectRatioRef, minDivElInfoRef } = useFontEditorContext();
  const isTextType = tags.indexOf(DraggableTag.TextField) > -1;
  const isEditingTextRef = useRef(isEditingText);
  isEditingTextRef.current = isEditingText;
  const isSubDraggableRef = useRef(
    draggables.findIndex((draggable) => draggable.id === (originId ?? id)) === -1,
  );
  const rndRef = useRef<Rnd>(null);
  const [draggableElement, setDraggableElement] = useState<HTMLElement | null>();
  const { isManager, isCustomer } = useAuth();
  const isSelected = selectedId === id;
  const relativeWidth = toDecimal(style?.width as string);
  const relativeHeight = toDecimal(style?.height as string);
  const relativeX = toDecimal(style?.left as string);
  const relativeY = toDecimal(style?.top as string);
  const shiftResize = React.useRef(false);
  const prevLockAspectRatio = React.useRef(false);
  const { pauseAudio } = useAudio();
  const [isOver, setIsOver] = React.useState(false);
  const nextTime = React.useRef<number>(0);
  const isGroup = tags?.some(
    (tag) => tag === DraggableTag.Group || tag === DraggableTag.GroupOfGroup,
  );
  const isBackgroundType = type === DraggableType.Background;
  const textMaxWidthPercentForCalcLeft = 99.8;
  const [textLockAspectRatio, setTextLockAspectRatio] = useState(false);
  const [textIsResizing, setTextIsResizing] = useState(false);
  const lastStyleRef = useRef(style);
  const styleRef = useRef(style);
  styleRef.current = style;
  const [toolsMenuOpen, setToolsMenuOpen] = useState(false);
  const [isMultipleSelected, setIsMultipleSelected] = useState(false);
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [rndKey, setRndKey] = useState<string | null>(null);
  const hasPage = typeof page === 'number';
  const [minSize, setMinSize] = useState<{
    minWidth: number;
    minHeight: number;
  } | null>(null);
  const [delta, setDelta] = useState<ResizableDelta | null>(null);
  const isFromMouseDownRef = useRef(false);
  const [currentResizeDirection, setCurrentResizeDirection] =
    useState<ResizeDirection | null>(null);
  const originWidthRef = useRef(0);
  const originHeightRef = useRef(0);
  const currentLockAspectRatio = isBackgroundType
    ? false
    : isTextType && !isSubDraggableRef.current
    ? textLockAspectRatio
    : lockAspectRatio;
  const isNormalResizing =
    !currentLockAspectRatio ||
    (currentResizeDirection !== 'topRight' && currentResizeDirection !== 'bottomLeft');
  const lastIsNormalResizingRef = useRef<boolean | null>(null);
  const isTopRight = !isNormalResizing && currentResizeDirection === 'topRight';
  const isBottomLeft = !isNormalResizing && currentResizeDirection === 'bottomLeft';

  // func
  const getMaxPercent = (percent: number) => Math.min(percent, 100);
  const getMinPercent = (percent: number) => Math.max(percent, 0);
  const getPercent = (percent: number) => getMinPercent(getMaxPercent(percent));
  function toDecimal(percent: string | undefined) {
    if (!percent) return 1;
    const parsed = parseFloat(percent);

    if (!Number.isNaN(parsed)) {
      return parseFloat(percent) / 100;
    } else {
      return 1;
    }
  }

  const updateCurrentResizeDirection = (
    currentResizeDirection: ResizeDirection | null,
  ) => {
    if (selectedIsResizingRef.current) {
      return;
    }

    setCurrentResizeDirection(currentResizeDirection);
  };

  function onMouseOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    setIsOver(true);
  }
  function onMouseLeave(e: React.DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    setIsOver(false);

    updateCurrentResizeDirection(null);
  }

  function onDragStart() {
    if (isSelected) {
      setSelectedIsDragging(true);
    }
  }

  function onDragStop(e: RndDragEvent, data: DraggableData) {
    if (id) {
      if (isSelected) {
        setSelectedIsDragging(false);
      }
      const newLeftPercentNum = getPercent((data.x / containerWidth) * 100);
      const newLeft = `${newLeftPercentNum}%`;
      const newTop = `${getPercent((data?.y / containerHeight) * 100)}%`;
      if (style?.left === newLeft && style.top === newTop) return; // prevent same

      const textWidthPercentNum = Number.parseFloat(`${style?.width}`);
      const left =
        isTextType &&
        !isSubDraggableRef.current &&
        `${style?.width}`.indexOf('%') > -1 &&
        textWidthPercentNum + newLeftPercentNum > textMaxWidthPercentForCalcLeft
          ? `${getPercent(
              Math.floor((textMaxWidthPercentForCalcLeft - textWidthPercentNum) * 1000) /
                1000,
            )}%`
          : newLeft;

      dispatch(
        updateDraggable({
          id: id,
          style: {
            left,
            top: newTop,
          },
        }),
      );
    }
  }

  function onResizeStart(
    e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
    dir: ResizeDirection,
    elementRef: HTMLElement,
  ) {
    if (!id) return;
    if (isSelected) {
      setSelectedIsResizing(true);
    }

    if (textLockAspectRatioRef) {
      textLockAspectRatioRef.current =
        !isSubDraggableRef.current && isTextType
          ? textLockAspectRatio ||
            dir === 'topLeft' ||
            dir === 'topRight' ||
            dir === 'bottomLeft' ||
            dir === 'bottomRight'
          : null;

      if (!isSubDraggableRef.current && isTextType && !textLockAspectRatioRef.current) {
        setTextIsResizing(true);
      }
    }

    prevLockAspectRatio.current = lockAspectRatio;
    if (e.shiftKey) {
      shiftResize.current = true;
      dispatch(
        updateDraggable({
          id: id,
          lockAspectRatio: isGroup ? false : !lockAspectRatio,
        }),
      );
    } else {
      shiftResize.current = false;
      if (isGroup) {
        dispatch(updateDraggable({ id: id, lockAspectRatio: lockAspectRatio })); // save point for onResize
      }
    }
  }

  function onResize(
    x: number,
    y: number,
    width: number,
    height: number,
    delta: ResizableDelta,
    isStop: boolean, // true is onResizeStop,  false is onResize
  ) {
    if (!id) return;
    if (!isStop) {
      if (!isNormalResizing) {
        setDelta(delta);
      }

      const currentTime = performance.now();
      if (currentTime < nextTime.current || !isGroup) {
        return;
      } else {
        nextTime.current = currentTime + 100;
      }
    } else {
      // setCurrentResizeDirection(null);
      setDelta(null);
      lastIsNormalResizingRef.current = isNormalResizing;

      if (isSelected) {
        setSelectedIsResizing(false);
      }

      setTextLockAspectRatio(false);

      setTimeout(() => {
        lastStyleRef.current = styleRef.current;
      }, 250);
    }

    const leftPercent = getPercent((x / containerWidth) * 100);

    const topPercent = getPercent(
      ((isTopRight
        ? containerHeight * relativeY -
          Math.abs(delta?.height ?? 0) * ((delta?.height ?? 0) > 0 ? 1 : -1)
        : isBottomLeft
        ? containerHeight * relativeY
        : y) /
        containerHeight) *
        100,
    );
    const widthPercent = getPercent((width / containerWidth) * 100);
    const heightPercent = getPercent((height / containerHeight) * 100);
    const updateFunc = isStop ? updateDraggable : updateDraggableWithoutHistory;
    const editorState = editorRefs?.editorStateRef.current;
    const editorElement = rndRef.current
      ?.getSelfElement()
      ?.getElementsByClassName('DraftEditor-root')
      .item(0) as HTMLDivElement | null | undefined;
    if (typeof textLockAspectRatioRef?.current === 'boolean' && editorState) {
      const setEditorState = editorRefs.setEditorStateRef.current;
      setEditorState && setEditorState(editorState);
    }
    dispatch(
      updateFunc({
        id: id,
        style: {
          left: `${
            isTextType &&
            !isSubDraggableRef.current &&
            leftPercent + widthPercent > textMaxWidthPercentForCalcLeft
              ? getPercent(
                  Math.floor((textMaxWidthPercentForCalcLeft - widthPercent) * 1000) /
                    1000,
                )
              : leftPercent
          }%`,
          top: `${topPercent}%`,
          width: `${widthPercent}%`,
          height:
            isTextType && !isSubDraggableRef.current
              ? `${getPercent(
                  ((editorElement?.offsetHeight ?? 0) / containerHeight) * 100,
                )}%`
              : `${heightPercent}%`,
          ...(textLockAspectRatioRef?.current === true && editorState
            ? {
                color: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
                ...(style?.maxWidth ? { maxWidth: `${widthPercent}%` } : null),
              }
            : null),
          ...(textLockAspectRatioRef?.current === false
            ? { maxWidth: `${widthPercent}%` }
            : null),
        },
        lockAspectRatio:
          isStop && shiftResize.current && isGroup
            ? prevLockAspectRatio.current
            : isStop && shiftResize.current
            ? !lockAspectRatio
            : lockAspectRatio,
      }),
    );
    if (
      isGroup &&
      style?.left &&
      style?.top &&
      style?.width &&
      style?.height &&
      !shiftResize.current
    ) {
      const widthScaling = widthPercent / parseFloat(style.width as string);
      const heightScaling = heightPercent / parseFloat(style.height as string);
      const payload = { id, widthScaling, heightScaling };
      dispatch(scalingGroupWithoutHistory(payload));
    }
    if (isStop && shiftResize.current) {
      shiftResize.current = false;
    }

    if (textLockAspectRatioRef) {
      textLockAspectRatioRef.current = null;
    }
  }

  function onClick(e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) {
    e.stopPropagation();
    if (isCustomer) {
      return;
    }

    const lastLeftNum = Number.parseFloat(`${lastStyleRef.current?.left}`);
    const lastTopNum = Number.parseFloat(`${lastStyleRef.current?.top}`);
    const lastWidthNum = Number.parseFloat(`${lastStyleRef.current?.width}`);
    const lastHeightNum = Number.parseFloat(`${lastStyleRef.current?.height}`);
    const leftNum = Number.parseFloat(`${style?.left}`);
    const topNum = Number.parseFloat(`${style?.top}`);
    const widthNum = Number.parseFloat(`${style?.width}`);
    const heightNum = Number.parseFloat(`${style?.height}`);

    if (
      isTextType &&
      isSelected &&
      Number.isFinite(lastLeftNum) &&
      Number.isFinite(lastTopNum) &&
      Number.isFinite(leftNum) &&
      Number.isFinite(topNum) &&
      Number.isFinite(lastWidthNum) &&
      Number.isFinite(lastHeightNum) &&
      Number.isFinite(widthNum) &&
      Number.isFinite(heightNum) &&
      Math.abs(lastLeftNum - leftNum) < 0.0001 &&
      Math.abs(lastTopNum - topNum) < 0.0001 &&
      Math.abs(lastWidthNum - widthNum) < 0.0001 &&
      Math.abs(lastHeightNum - heightNum) < 0.0001
    ) {
      if (textIsResizing) {
        setTextIsResizing(false);
        return;
      }

      const textElement = editorRefs?.elementRef.current;
      if (textElement && !isEditingText) {
        setTimeout(() => {
          textElement.dispatchEvent(
            new MouseEvent('dblclick', {
              view: window,
              bubbles: true,
              cancelable: true,
            }),
          );
        }, 100);
      }
    }

    lastStyleRef.current = styleRef.current;

    dispatch(
      updateSelected({
        id: id,
        clickedArea: ClickedArea.Style,
      }),
    );

    switchItemByTags(tags);
  }

  function onDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    const sourceIsImage = tags?.includes(DraggableTag.Image);
    const sourceIsAudio = tags?.includes(DraggableTag.Audio);
    const sourceIsVideo = tags?.includes(DraggableTag.Video);
    if (
      e.dataTransfer.types.includes('style/draggable') &&
      (sourceIsImage || sourceIsAudio || sourceIsVideo)
    ) {
      const data: DataTransferProps = JSON.parse(
        e.dataTransfer.getData('style/draggable'),
      );
      const destIsImage = data.default.tags?.includes(DraggableTag.Image);
      const destIsAudio = data.default.tags?.includes(DraggableTag.Audio);
      const destIsVideo = data.default.tags?.includes(DraggableTag.Video);
      const updateImage = sourceIsImage && destIsImage;
      const updateAudio = sourceIsAudio && destIsAudio;
      const updateVideo = sourceIsVideo && destIsVideo;
      if ((updateImage || updateAudio || updateVideo) && id) {
        e.stopPropagation();
        if (updateAudio) pauseAudio();
        const params = updateImage
          ? {
              style: {
                width: `${(data.width / containerWidth) * 100}%`,
                height: `${(data.height / containerHeight) * 100}%`,
              },
              image: data.default.image,
            }
          : updateAudio
          ? { audio: data.default.audio }
          : updateVideo
          ? { video: data.default.video }
          : {};
        dispatch(
          updateDraggable({
            id: id,
            ...params,
          }),
        );
      }
    }
  }

  useEffect(() => {
    if (!isTextType || isSubDraggableRef.current) {
      return;
    }

    const editorElement = rndRef.current?.getSelfElement();
    if (editorElement) {
      if (type === DraggableType.TextCircleAnswer) {
        editorElement.style.minWidth = `${40}px`;
        return;
      }

      const cssCollection = window.getComputedStyle(editorElement, null);

      const minWidth = Number.parseFloat(cssCollection.getPropertyValue('min-width'));
      const maxWidth = Number.parseFloat(cssCollection.getPropertyValue('max-width'));

      if (minWidth > maxWidth) {
        editorElement.style.minWidth = `${maxWidth}px`;
      }
    }
  }, [isSelected, isOver]);

  useEffect(() => {
    if (isBackgroundType) {
      return;
    }

    if (!isSelected) {
      setIsOver(false);
      setToolsMenuOpen(false);
    } else {
      const draggableElement = rndRef.current?.getSelfElement();
      if (draggableElement && draggableElement instanceof HTMLElement) {
        setSelectedEl(draggableElement);
      }
    }
  }, [isSelected, rndKey]);

  useEffect(() => {
    setDraggableElement(rndRef.current?.getSelfElement());

    if (!isBackgroundType) {
      return;
    }

    const draggableElement = rndRef.current?.getSelfElement();
    if (!draggableElement) {
      return;
    }

    const contextmenu = (e: MouseEvent) => {
      e.preventDefault();
    };
    draggableElement.addEventListener('contextmenu', contextmenu);

    return () => {
      draggableElement.removeEventListener('contextmenu', contextmenu);
    };
  }, []);

  useEffect(() => {
    if (!isBackgroundType && draggableMapRef && id) {
      draggableMapRef.current[id] = {
        ref: rndRef,
        type,
        setIsMultipleSelected: !isSubDraggableRef.current
          ? setIsMultipleSelected
          : () => undefined,
        isMultipleSelected,
        setIsEditingGroup,
        isTextType,
        isSubDraggable: isSubDraggableRef.current,
        style: style ? cloneDeep(style) : undefined,
        // isTemp: !!groupElement,
      };
    }
  }, [isMultipleSelected]);

  const [groupAdjusterCheck, setGroupAdjusterCheck] = useState(false);
  useEffect(() => {
    if (isEditingGroup && !groupAdjusterRef?.current) {
      setIsMultipleSelected(false);
      setIsEditingGroup(false);
    }
  }, [groupAdjusterCheck]);

  useEffect(() => {
    if (isEditingGroup) {
      setGroupAdjusterCheck((groupAdjusterCheck) => !groupAdjusterCheck);
    }
  }, [isEditingGroup]);

  useEffect(() => {
    if (hasPage && !isSubDraggableRef.current) {
      if (typeof rndKey !== 'string') {
        setRndKey('');
      } else {
        setRndKey(uuidv4());
      }
    }
  }, [page, group?.length]);

  useEffect(() => {
    if (hasPage && !isSubDraggableRef.current) {
      setDraggableElement(rndRef.current?.getSelfElement());
    }
  }, [rndKey]);

  useEffect(() => {
    return () => {
      if (id) {
        if (draggableMapRef && draggableMapRef.current?.[id]) {
          delete draggableMapRef.current[id];
        }
        if (draggablesPropsRef && draggablesPropsRef.current?.[id]) {
          delete draggablesPropsRef.current[id];
        }
      }
    };
  }, []);

  useEffect(() => {
    if (isTextType && !isSubDraggableRef.current) {
      let minWidth = minSize?.minWidth ?? 40;
      // let minHeight = minSize?.minHeight ?? 30;
      let minHeight = 30;

      if (type === DraggableType.TextCircleAnswer) {
        minWidth = 40;
        minHeight = 40;
      } else {
        const minDivChildEl = (
          minDivElInfoRef?.current?.id === id
            ? minDivElInfoRef?.current?.minDivEl?.children.item(0)
            : null
        ) as HTMLDivElement | null | undefined;
        if (minDivChildEl) {
          minDivChildEl.style.width = 'auto';
          minDivChildEl.style.minWidth = 'none';
          minDivChildEl.style.minHeight = 'none';

          minWidth = minDivChildEl?.offsetWidth ?? 40;
          // minHeight = minDivChildEl?.offsetHeight ?? 30;
          minHeight = 30;
        }
      }

      if (!minSize || minSize.minWidth !== minWidth || minSize.minHeight !== minHeight) {
        setMinSize({
          minWidth,
          minHeight,
        });
      }
    }
  }, [isSelected, style]);

  useEffect(() => {
    if (selectedIsResizing) {
      return;
    }

    if (isTextType) {
      originWidthRef.current = rndRef.current?.getSelfElement()?.offsetWidth ?? 0;
      originHeightRef.current = rndRef.current?.getSelfElement()?.offsetHeight ?? 0;
    } else {
      originWidthRef.current = containerWidth * relativeWidth;
      originHeightRef.current = containerHeight * relativeHeight;
    }
  }, [
    selectedIsResizing,
    containerWidth,
    relativeWidth,
    containerHeight,
    relativeHeight,
  ]);

  useLayoutEffect(() => {
    if (selectedIsResizing) {
      return;
    }

    if (lastIsNormalResizingRef.current === false) {
      setRndKey(uuidv4());
    }
    lastIsNormalResizingRef.current = null;
  }, [selectedIsResizing]);

  const defaultEnableResizing = isManager || isSelected;
  const leftEnableResizing = isSubDraggableRef.current
    ? defaultEnableResizing
    : !isSubDraggableRef.current &&
      (lockAspectRatio || isTextType || (!lockAspectRatio && !isTextType));
  const bottomEnableResizing = isSubDraggableRef.current
    ? defaultEnableResizing
    : !isSubDraggableRef.current && (lockAspectRatio || isTextType)
    ? false
    : defaultEnableResizing;

  const updateLockAspectRatio = () => {
    if (isSubDraggableRef.current) {
      return;
    }

    setTextLockAspectRatio(true);
  };
  const updateUnLockAspectRatio = () => {
    if (selectedIsResizing || isSubDraggableRef.current) {
      return;
    }

    setTextLockAspectRatio(false);
  };

  const defaultResizeHandleComponent = {
    topRight: isSelected ? <ResizeHandle /> : undefined,
    bottomRight: isSelected ? <ResizeHandle /> : undefined,
    bottomLeft: isSelected ? <ResizeHandle /> : undefined,
    topLeft: isSelected ? <ResizeHandle /> : undefined,
  };
  const resizeHandleStyleAndFunc = {
    position: 'absolute' as const,
    width: '10px',
    height: '10px',
    border: '1px solid #f33',
    bgcolor: '#FFF',
    onMouseEnter: updateLockAspectRatio,
    onMouseLeave: updateUnLockAspectRatio,
  };

  const groupElementEvent = {
    onMouseOver: (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    },
    onMouseLeave: (e: React.DragEvent<HTMLDivElement>) => {
      if (isBackgroundType) {
        return;
      }

      e.stopPropagation();
    },
    onClick: (e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
      e.stopPropagation();
    },
    onMouseDown: (e: MouseEvent) => {
      if (isBackgroundType || groupElement) {
        return;
      }

      e.stopPropagation();
    },
  };

  if (groupElement) {
    return (
      <Rnd
        style={{ cursor: 'move' }}
        ref={rndRef}
        bounds={bounds}
        lockAspectRatio
        disableDragging
        enableResizing={false}
        size={{ width: groupElement.width, height: groupElement.height }}
        position={{ x: groupElement.x, y: groupElement.y }}
        {...groupElementEvent}
        {...(isTextType
          ? {
              minWidth: 40,
              minHeight: 30,
            }
          : null)}
      >
        <Box width="100%" height="100%">
          {children}
        </Box>
      </Rnd>
    );
  }

  if (
    isTextType &&
    isSubDraggableRef.current &&
    style?.display === 'none' &&
    adminViewMode === 'view'
  ) {
    return null;
  }

  // main
  return (
    <Rnd
      {...(hasPage || rndKey ? { key: rndKey } : null)}
      style={{
        ...(isNormalResizing || !currentLockAspectRatio || !selectedIsResizing
          ? null
          : isTopRight
          ? {
              top: `${(delta?.height ?? 0) > 0 ? '-' : ''}${Math.abs(
                delta?.height ?? 0,
              )}px`,
            }
          : isBottomLeft
          ? {
              top: `${delta?.height ?? 0}px`,
            }
          : null),
        ...(isEditingGroup && !isSubDraggableRef.current ? { opacity: 0 } : null),
        userSelect: 'none',
      }}
      ref={rndRef}
      bounds={!isNormalResizing && currentLockAspectRatio ? '' : bounds}
      lockAspectRatio={currentLockAspectRatio}
      disableDragging={
        adminViewMode === 'view' ||
        isEditingGroup ||
        isEditingText ||
        toolsMenuOpen ||
        isCustomer ||
        isBackgroundType
      }
      enableResizing={
        adminViewMode === 'view' || isEditingGroup || !isSelected || isBackgroundType
          ? false
          : {
              bottom: bottomEnableResizing,
              bottomLeft: bottomEnableResizing,
              bottomRight: bottomEnableResizing,
              left: leftEnableResizing,
              right: leftEnableResizing,
              top: bottomEnableResizing,
              topLeft: bottomEnableResizing,
              topRight: bottomEnableResizing,
            }
      }
      size={{
        width: containerWidth * relativeWidth,
        height: containerHeight * relativeHeight,
      }}
      position={{ x: containerWidth * relativeX, y: containerHeight * relativeY }}
      resizeHandleComponent={{
        ...(!isSubDraggableRef.current && (isTextType || (!isTextType && lockAspectRatio))
          ? {
              ...(!isTextType ? defaultResizeHandleComponent : null),
              left: isSelected ? (
                <Box
                  className={notShownWhenSavingClass}
                  position="relative"
                  width="100%"
                  height="100%"
                  onMouseLeave={() => {
                    if (isFromMouseDownRef.current || selectedIsResizing) {
                      isFromMouseDownRef.current = false;
                      return;
                    }

                    updateCurrentResizeDirection(null);
                  }}
                >
                  <Box
                    {...resizeHandleStyleAndFunc}
                    top="-5px"
                    left="0px"
                    sx={{
                      cursor: 'nw-resize',
                    }}
                    onMouseDown={() => {
                      isFromMouseDownRef.current = true;
                    }}
                    onMouseMove={(e) => e.stopPropagation()}
                    onMouseEnter={() => {
                      if (selectedIsResizing) {
                        return;
                      }

                      resizeHandleStyleAndFunc.onMouseEnter();
                      updateCurrentResizeDirection('topLeft');
                    }}
                  />
                  <Box
                    {...resizeHandleStyleAndFunc}
                    bottom="-5px"
                    left="0px"
                    sx={{
                      cursor: 'sw-resize',
                    }}
                    onMouseDown={() => {
                      isFromMouseDownRef.current = true;
                    }}
                    onMouseMove={(e) => e.stopPropagation()}
                    onMouseEnter={() => {
                      if (selectedIsResizing) {
                        return;
                      }

                      resizeHandleStyleAndFunc.onMouseEnter();
                      updateCurrentResizeDirection('bottomLeft');
                    }}
                  />
                </Box>
              ) : undefined,
              right: isSelected ? (
                <Box
                  className={notShownWhenSavingClass}
                  position="relative"
                  width="100%"
                  height="100%"
                  onMouseLeave={() => {
                    if (isFromMouseDownRef.current || selectedIsResizing) {
                      isFromMouseDownRef.current = false;
                      return;
                    }

                    updateCurrentResizeDirection(null);
                  }}
                >
                  <Box
                    {...resizeHandleStyleAndFunc}
                    top="-5px"
                    right="0px"
                    sx={{
                      cursor: 'ne-resize',
                    }}
                    onMouseDown={() => {
                      isFromMouseDownRef.current = true;
                    }}
                    onMouseMove={(e) => e.stopPropagation()}
                    onMouseEnter={() => {
                      if (selectedIsResizing) {
                        return;
                      }

                      resizeHandleStyleAndFunc.onMouseEnter();
                      updateCurrentResizeDirection('topRight');
                    }}
                  />
                  <Box
                    {...resizeHandleStyleAndFunc}
                    bottom="-5px"
                    right="0px"
                    sx={{
                      cursor: 'se-resize',
                    }}
                    onMouseDown={() => {
                      isFromMouseDownRef.current = true;
                    }}
                    onMouseMove={(e) => e.stopPropagation()}
                    onMouseEnter={() => {
                      if (selectedIsResizing) {
                        return;
                      }

                      resizeHandleStyleAndFunc.onMouseEnter();
                      updateCurrentResizeDirection('bottomRight');
                    }}
                  />
                </Box>
              ) : undefined,
            }
          : defaultResizeHandleComponent),
      }}
      {...(adminViewMode === 'view' || isEditingGroup || isBackgroundType
        ? groupElementEvent
        : {
            onMouseOver,
            onDragStart,
            onDragStop,
            onMouseLeave,
            onResizeStart,
            onResize: (e, direction, ref, delta, position) => {
              onResize(
                position.x,
                position.y,
                ref.clientWidth,
                ref.clientHeight,
                delta,
                false,
              );
            },
            onResizeStop: (e, direction, ref, delta, position) =>
              onResize(
                position.x,
                position.y,
                ref.clientWidth,
                ref.clientHeight,
                delta,
                true,
              ),
            onClick,
            onMouseDown: (e) => !isBackgroundType && e.stopPropagation(),
            onDrop,
          })}
      tabIndex={0}
      {...minSize}
      {...(!isNormalResizing && currentLockAspectRatio
        ? {
            maxWidth: isTopRight
              ? containerWidth - containerWidth * relativeX
              : containerWidth * relativeX + originWidthRef.current,
            maxHeight: isTopRight
              ? containerHeight -
                (containerHeight - containerHeight * relativeY - originHeightRef.current)
              : containerHeight - containerHeight * relativeY,
          }
        : null)}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          ...(isTextType && selectedId === id && isEditingText
            ? {
                border:
                  isSelected || isMultipleSelected
                    ? '1px solid #f33'
                    : isOver && isManager
                    ? '1px solid #bf55ec'
                    : '1px solid transparent',
              }
            : null),
          ...(groupElement || (isTextType && !isEditingText)
            ? { '& *': { userSelect: 'none' } }
            : null),
        }}
        {...(!isNormalResizing && currentLockAspectRatio
          ? {
              onMouseMove: () => updateCurrentResizeDirection(null),
            }
          : null)}
      >
        <>
          {!(isTextType && selectedId === id && isEditingText) &&
            adminViewMode === 'edit' && (
              <Box
                position="absolute"
                width="100%"
                height="100%"
                border={
                  isSelected || isMultipleSelected
                    ? '1px solid #f33'
                    : isOver && isManager
                    ? '1px solid #bf55ec'
                    : '1px solid transparent'
                }
                className={notShownWhenSavingClass}
              />
            )}
          {children}
          <ButtonBar
            id={id}
            type={type}
            tags={tags}
            lockAspectRatio={lockAspectRatio}
            isSelected={isSelected}
            answer={answer}
            index={index}
          />
          {!isBackgroundType && adminViewMode === 'edit' && isManager ? (
            <RightClickMenu
              id={id}
              type={type}
              updateToolsMenuOpen={setToolsMenuOpen}
              draggableElement={draggableElement}
              isTextType={isTextType}
            />
          ) : null}
        </>
      </Box>
    </Rnd>
  );
}
