import { useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { default as Grid } from '@mui/material/Unstable_Grid2';

import {
  CustomAddOrEditDialog,
  CustomDateTimePicker,
  CustomTextField,
  OperationResultDialog,
  CustomTable,
} from '../../../globalComponents';
import { useOperationResultContext } from '../../../globalContexts/OperationResultContext';
import { useDialog } from '../../../globalHooks';
import {
  useAddCallMutation,
  CallsQuery,
  LeadForLeadsFragment,
} from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';

type AddCallButtonProps = {
  updateQuery?: (mapFn: (previousQueryResult: CallsQuery) => CallsQuery) => void;
  selectedLead: LeadForLeadsFragment;
};

type LeadTableProps = {
  types: (
    | { fieldName: keyof LeadForLeadsFragment; fieldNameLabel: string }
    | { cellName: string; label?: string }
  )[];
  customCell?: { [key: string]: any };
};

const AddCallButton = ({
  //updateQuery,
  selectedLead,
}: AddCallButtonProps): JSX.Element => {
  const scripts = useScripts();

  const { operationLoading, operationSuccess, commonErrorMessages } =
    useOperationResultContext();

  // states
  const [callBy, setCallBy] = useState('');
  const [callAt, setCallAt] = useState(String(new Date()));
  const [callNotes, setCallNotes] = useState('');

  // graphql
  const [addCallMutation, { data, error, loading }] = useAddCallMutation({
    update(cache, { data }) {
      if (!data) {
        return;
      }

      cache.modify({
        fields: {
          calls(existingCalls) {
            const newResult = { ...existingCalls };
            newResult.edges = [
              {
                __typename: 'CallEdge',
                cursor: '',
                node: data.addCall,
              },
              ...newResult.edges,
            ];
            newResult.pageInfo = {
              ...newResult.pageInfo,
              itemTotal: newResult.pageInfo.itemTotal + 1,
              startCursor: '',
            };

            return newResult;
          },
        },
      });
    },
    onCompleted() {
      operationSuccess();
    },
    onError(error) {
      commonErrorMessages(error);
    },
  });

  // lead info table
  const types: LeadTableProps['types'] = [
    { fieldName: 'name', fieldNameLabel: scripts.parentName },
    { fieldName: 'phone', fieldNameLabel: scripts.phone },
    { cellName: 'childrenNames', label: scripts.childsNames },
    { cellName: 'childrenAge', label: scripts.childsAge },
    { cellName: 'childrenSchool', label: scripts.school },
  ];

  const customCell: LeadTableProps['customCell'] = {
    childrenNames: selectedLead.children.map((child, index) => (
      <Typography key={index} variant="body2">
        {child.englishName + (child.name ? ', ' : '') + child.name}
      </Typography>
    )),
    childrenAge: selectedLead.children.map((child, index) => (
      <Typography key={index} variant="body2">
        {child.age}
      </Typography>
    )),
    childrenSchool: selectedLead.children.map((child, index) => (
      <Typography key={index} variant="body2">
        {child.schoolName}
      </Typography>
    )),
  };

  // dialogProps
  const { dialogProps } = useDialog({
    tooltipTitle: scripts.newCall,
    dialogTitle: scripts.newCall,
    dialogTitleAlign: 'center',
    justifyContent: 'center',
    inputCheckResult: () => !!(callBy && callAt && callNotes),
    onSubmit: () => {
      addCallMutation({
        variables: {
          leadId: selectedLead.id,
          callInput: { callBy, callAt, notes: callNotes },
        },
      });
      operationLoading();
    },
    onClose: () => {
      setCallBy('');
      setCallNotes('');
      setCallAt(String(new Date()));
    },
    fullWidth: false,
  });

  return (
    <>
      <CustomAddOrEditDialog
        isAdd={true}
        {...dialogProps}
        showHoverBackgroundColor
        addIcon
      >
        <Grid xs={12}>
          <Stack spacing={4}>
            <CustomTable
              sx={{
                marginBottom: '10px',
                '& th': { fontWeight: '700', fontFamily: 'Poppins', fontSize: '14px' },
              }}
              tableHead={[
                ...types.map((type) =>
                  'fieldNameLabel' in type ? type.fieldNameLabel : type.label ?? '',
                ),
              ]}
              tableBody={[
                {
                  ...Object.assign({
                    key: selectedLead.id,
                    content: [
                      ...types.map((type) => {
                        if ('fieldName' in type) {
                          return selectedLead[type.fieldName];
                        } else if (type.cellName) {
                          return customCell[type.cellName];
                        }
                      }),
                    ],
                  }),
                },
              ]}
              lastBorderNone={true}
            />
            <CustomTextField
              required
              firstInput
              label={scripts.callBy}
              value={callBy}
              setValue={setCallBy}
              dialogProps={dialogProps}
              backgroundColor={'#F8F8F8'}
              borderLine={'false'}
            />
            <CustomDateTimePicker
              required
              label={scripts.callDate}
              value={callAt}
              setValue={setCallAt}
              dialogProps={dialogProps}
              endDateTime={String(new Date())}
              backgroundColor={'#F8F8F8'}
              borderLine={'false'}
            />
            <CustomTextField
              required
              label={scripts.notes}
              value={callNotes}
              setValue={setCallNotes}
              dialogProps={dialogProps}
              backgroundColor={'#F8F8F8'}
              borderLine={'false'}
              multiline={true}
            />
          </Stack>
        </Grid>
      </CustomAddOrEditDialog>
      {dialogProps.isSubmit && (
        <OperationResultDialog data={data} error={error} loading={loading} />
      )}
    </>
  );
};

export default AddCallButton;
