import { useLayoutEffect } from 'react';

import Box from '@mui/material/Box';
import { cloneDeep, defaultsDeep } from 'lodash-es';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import { useScripts } from '../../../../layout/utils/LanguageHelper';
import { useStyle } from '../../context/StyleContext';

const landscapeProps: DraggableInitProps = {
  type: DraggableType.Triangle,
  tags: [DraggableTag.Elements],
  style: {
    width: '8.411274%',
    height: '12.950792%',
    color: '#808080',
    borderStyle: 'none',
    borderWidth: 0,
    borderRadius: 0,
    borderColor: '#000000',
  },
  lockAspectRatio: true,
};

export const defaultTriangleProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '22.221027%',
      height: '10.82443%',
    },
  },
};

export default function Triangle(props: DraggableOuterProps) {
  const scripts = useScripts();

  const { layout, draggablesPropsRef } = useStyle();
  const defaultProps = layout
    ? defaultTriangleProps[layout]
    : defaultTriangleProps.landscape;

  const defaultTriangle: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '10vmin',
      height: '8.66vmin',
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(cloneDeep(props), defaultTriangle);

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep({
        ...props,
        selectedBar: {
          ...dragProps?.selectedBar,
          getDeleteLabel: () => scripts.deleteThisElement,
        },
      });
    }
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: dragProps?.style?.backgroundColor,
          borderStyle: dragProps?.style?.borderStyle,
          borderWidth: `${dragProps?.style?.borderWidth}px`,
          borderRadius: dragProps?.style?.borderRadius,
          borderColor: dragProps?.style?.borderColor,
        }}
      >
        <Box
          sx={[
            {
              width: '100%',
              height: '100%',
              backgroundColor: dragProps?.style?.color,
              clipPath: 'polygon(50% 0, 100% 100%, 0 100%)',
            },
          ]}
        />
      </Box>
    </WrapperRND>
  );
}
