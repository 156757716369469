import { useLayoutEffect } from 'react';

import Typography from '@mui/material/Typography';
import { cloneDeep, defaultsDeep } from 'lodash-es';

import { DraggableOuterProps, DraggableInnerProps } from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import {
  DraggableType,
  DraggableTag,
  PositionAlign,
  Layout,
} from '../../../../graphql/resolver.types';
import { Draggable } from '../../../../redux/features/styleSlice';
import { getDefaultFontSizeNum } from '../../context/FontEditorContext';
import { useStyle } from '../../context/StyleContext';
import { getDraggables } from '../../utils/draggables';

export const clozeTestChild: Array<Draggable> = [
  {
    id: '',
    type: DraggableType.CustomText,
    tags: [DraggableTag.TextField],
    name: 'I like to ___ candy and ___ .',
    style: {
      width: '90%',
      height: '30%',
      left: '5%',
      top: '2%',
      color: '#000000',
      textAlign: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
      fontFamily: 'Roboto',
      backgroundColor: '#d9d9d9',
      borderRadius: '10px',
    },
    lockAspectRatio: false,
  },
  {
    id: '',
    type: DraggableType.TextRectAnswer,
    tags: [DraggableTag.TextField],
    name: 'eat; bananas',
    style: {
      width: '80%',
      height: '20%',
      left: '15%',
      top: '50%',
      color: '#000000',
      textAlign: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
      fontFamily: 'Roboto',
      backgroundColor: '#d9d9d9',
      borderRadius: '10px',
    },
    lockAspectRatio: false,
    answer: {
      correct: false,
      correctCheckBoxPosition: PositionAlign.Left,
    },
  },
  {
    id: '',
    type: DraggableType.TextRectAnswer,
    tags: [DraggableTag.TextField],
    name: 'and; burgers',
    style: {
      width: '80%',
      height: '20%',
      left: '15%',
      top: '75%',
      color: '#000000',
      textAlign: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
      fontFamily: 'Roboto',
      backgroundColor: '#d9d9d9',
      borderRadius: '10px',
    },
    lockAspectRatio: false,
    answer: {
      correct: false,
      correctCheckBoxPosition: PositionAlign.Left,
    },
  },
];

export const clozeTestNewChild: Draggable = {
  id: '',
  type: DraggableType.TextRectAnswer,
  tags: [DraggableTag.TextField],
  name: 'New',
  style: {
    width: '80%',
    height: '20%',
    left: '0%',
    top: '0%',
    color: '#000000',
    textAlign: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
    fontFamily: 'Roboto',
    backgroundColor: '#d9d9d9',
    borderRadius: '10px',
  },
  lockAspectRatio: false,
  answer: {
    correct: false,
    correctCheckBoxPosition: PositionAlign.Left,
  },
};

export default function ClozeTestAnswer(props: DraggableOuterProps) {
  const defaultClozeTestAnswer: DraggableInnerProps = {
    type: DraggableType.ClozeTestAnswer,
    tags: [DraggableTag.Group],
    style: {
      width: '35vmin',
      height: '35vmin',
    },
    lockAspectRatio: false,
    group: props.isClone ? undefined : clozeTestChild,
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultClozeTestAnswer,
  );

  const { draggablesPropsRef } = useStyle();
  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = props;
    }
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps}>
      {dragProps.isClone && dragProps.group ? (
        getDraggables(dragProps.group)
      ) : (
        <CenterBox
          sx={{
            height: '7vmin',
            backgroundColor: '#d9d9d9',
            borderRadius: '10px',
          }}
        >
          <Typography sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}>
            I like to ___ candy and ___ .
          </Typography>
        </CenterBox>
      )}
    </WrapperRND>
  );
}
