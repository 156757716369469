import React, { CSSProperties, useState } from 'react';

import ExcelJs from 'exceljs';

import DownloadFileIcon from '../../../svg/DownloadFileIcon';

export type SheetData = {
  sheetName: string; // 工作表名稱
  thead: Array<string>; // 欄位標題
  tbody: Array<Array<string>>;
  columnWidths?: Array<{ number: number; width: number }>; //用來指定欄寬的
};

interface DownloadClassGradeOfAllBooksButtonProps {
  fileName: string; // 檔案名稱
  //sheetDatas: Array<SheetData>; // 要匯出的表格資料
  disabled?: boolean; // 是不是要禁止按鈕動作
  buttonRef?: React.MutableRefObject<any>; // 外面用useRef傳進來
  style?: CSSProperties; // 按鈕的style
  classroomName: string;
  lessons?: {
    __typename?: 'LessonGrade' | undefined;
    id: string;
    name: string;
    progress: number;
    averageScore: number;
    parts: {
      __typename?: 'Part' | undefined;
      id: string;
      name: string;
    }[];
    studentGrades: {
      id: string;
      name: string;
      username: string;
      parts: {
        id: string;
        name: string;
        progress: number;
        averageScore: number;
      }[];
    }[];
  }[];
  classData: {
    __typename?: 'ClassroomGrade' | undefined;
    books: {
      __typename?: 'BookGrade' | undefined;
      id: string;
      name: string;
      lessons: {
        __typename?: 'LessonGrade' | undefined;
        id: string;
        name: string;
        progress: number;
        averageScore: number;
        parts: {
          id: string;
          name: string;
        }[];
        studentGrades: {
          id: string;
          name: string;
          username: string;
          parts: {
            id: string;
            name: string;
            progress: number;
            averageScore: number;
          }[];
        }[];
      }[];
    }[];
    tests: {
      id: string;
      name: string;
      averageScore: number;
    }[];
  };
}

export function DownloadClassGradeOfAllBooksButton(
  props: DownloadClassGradeOfAllBooksButtonProps,
) {
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleDownload = async () => {
    setLoading(true);

    try {
      const workbook = new ExcelJs.Workbook();

      props.classData.books
        .map(({ name, lessons }) => {
          return {
            sheetName: name,
            thead: lessons.map(({ parts }) => {
              return ['No.', 'username', 'name', ...parts.map(({ name }) => name)];
            }),
            tbody: lessons.map(({ studentGrades }) =>
              studentGrades.map((each, index) =>
                [
                  (index + 1).toString(),
                  each.username,
                  each.name,
                  each.parts.map(({ averageScore }) => averageScore.toString()),
                ].flat(),
              ),
            ),
            lessonName: lessons.map(({ name }) => name),
          };
        })
        .forEach(
          (sheetData: {
            sheetName: string;
            lessonName: string[];
            thead: string[][];
            tbody: string[][][];
            columnWidths?: { number: number; width: number }[];
          }) => {
            const sheet = workbook.addWorksheet(sheetData.sheetName);

            const toLetters = (num: number): string => {
              const mod: number = num % 26;
              let pow: number = Math.floor(num / 26);
              const out: string = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
              return pow ? toLetters(pow) + out : out;
            };

            let cumulativeColumnIndex = 1;

            sheetData.thead.forEach((lesson, lessonIndex) => {
              const startColumnIndex = cumulativeColumnIndex;
              const endColumnIndex = startColumnIndex + lesson.length;

              const start = toLetters(startColumnIndex);

              const end = toLetters(endColumnIndex);

              const headerCell = sheet.getCell(`${start}1`);
              headerCell.value = `${props.fileName}, ${sheetData.sheetName}, ${sheetData.lessonName[lessonIndex]}`;
              sheet.mergeCells(`${start}1:${end}1`);

              const subHeaderCell = sheet.getCell(`${start}2`);
              subHeaderCell.value = `${props.classroomName} ${sheetData.tbody[lessonIndex].length}位學生`;
              sheet.mergeCells(`${start}2:${end}2`);

              sheet.addTable({
                name: sheetData.sheetName.replace(/ /g, '').replace(/-/g, '_'), // remove space and replace "-"// TODO: fix other sign
                ref: lessonIndex === 0 ? `A3` : `${start}3`,
                headerRow: true,
                columns: lesson.map((s: string) => {
                  return {
                    name: s,
                  };
                }),
                rows: sheetData.tbody[lessonIndex],
              });

              sheet.getRow(3).eachCell({ includeEmpty: false }, (cell) => {
                if (cell) {
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '4f81bd' },
                  };
                }
              });

              cumulativeColumnIndex = endColumnIndex + 1; // Move to the next set of columns
            });

            if (sheetData.columnWidths) {
              sheetData.columnWidths.forEach(
                (column: { number: number; width: number }) => {
                  sheet.getColumn(column.number).width = column.width;
                },
              );
            }

            const row = sheet.getRow(1);
            row.eachCell((cell: any, rowNumber: number) => {
              const col = sheet.getColumn(rowNumber);
              col.alignment = {
                vertical: 'middle',
                horizontal: 'center',
              };
              col.font = {
                size: 12,
                family: 2,
              };
              col.width = 20;
            });

            sheet.getRow(1).font = {
              family: 1,
              color: { argb: '3a5785' },
              size: 14,
              bold: true,
            };
            sheet.getRow(2).font = {
              bold: true,
              size: 14,
              family: 1,
              color: { argb: '374f49' },
            };
            sheet.getRow(3).font = {
              bold: true,
              size: 12,
              family: 1,
            };
          },
        );

      props.classData.tests
        .map(({ name, averageScore }) => {
          return {
            sheetName: name,
            thead: ['score'],
            tbody: averageScore,
          };
        })
        .forEach((sheetData: { sheetName: string; thead: string[]; tbody: number }) => {
          const sheet = workbook.addWorksheet(sheetData.sheetName);
          sheet.addRow([sheetData.sheetName.replace(/ /g, '').replace(/-/g, '_')]);
          sheet.addRow([props.classroomName]);
          sheet.addRow(sheetData.thead);
          sheet.addRow([sheetData.tbody]);

          //style
          const row = sheet.getRow(1);
          row.eachCell((cell: any, rowNumber: number) => {
            const col = sheet.getColumn(rowNumber);
            col.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };
            col.font = {
              size: 12,
              family: 2,
            };
            col.width = 30;
          });

          sheet.getRow(1).font = {
            family: 1,
            color: { argb: '3a5785' },
            size: 14,
            bold: true,
          };
          sheet.getRow(2).font = {
            bold: true,
            size: 14,
            family: 1,
            color: { argb: '374f49' },
          };
          sheet.getRow(3).font = {
            bold: true,
            size: 14,
            family: 1,
            color: { argb: '374f49' },
          };
        });

      // 表格裡面的資料都填寫完成之後，訂出下載的callback function
      // 異步的等待他處理完之後，創建url與連結，觸發下載
      const content = await workbook.xlsx.writeBuffer();
      const link = document.createElement('a');
      const blob = new Blob([content], {
        type: 'applicationi/xlsx',
      });

      link.download = `${
        props.fileName.replace(/ /g, '').replace(/-/g, '_') +
        ', ' +
        props.classroomName +
        ' Grades'
      }.xlsx`;
      link.href = URL.createObjectURL(blob);
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const style: CSSProperties = {
    ...props?.style,
  };

  return (
    <button
      ref={props.buttonRef}
      disabled={loading} //disabled={props.disabled}
      onClick={handleDownload}
      style={style}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <DownloadFileIcon
        color={isHovered ? '#00A591' : '#585858'}
        width={33}
        height={22}
      />
    </button>
  );
}

export default DownloadClassGradeOfAllBooksButton;
