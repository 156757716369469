import { useEffect, useRef } from 'react';

import {
  FilledInputProps,
  FormLabel,
  InputBaseComponentProps,
  InputProps,
  OutlinedInputProps,
  SxProps,
  Theme,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import CustomInputText from './CustomInputText';
import { useScripts } from '../layout/utils/LanguageHelper';

type customTextFieldProps = {
  formLabel?: boolean;
  value: string;
  setValue?: React.Dispatch<React.SetStateAction<string>> | ((value: string) => void);
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  label?: string | JSX.Element;
  emptyLabel?: boolean;
  required?: boolean;
  type?: 'text' | 'password';
  variant?: 'filled' | 'outlined' | 'standard';
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  size?: 'medium' | 'small';
  firstInput?: boolean;
  noTrim?: boolean;
  readOnly?: boolean;
  endAdornment?: JSX.Element;
  placeholder?: string;
  disabled?: boolean;
  dialogProps?: {
    firstInputRef?: React.RefObject<HTMLDivElement>;
    willSubmit: boolean;
    open?: boolean;
  };
  backgroundColor?: string;
  width?: number;
  borderLine?: string;
  multiline?: boolean;
  InputProps?:
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>;
  inputProps?: InputBaseComponentProps;
  sx?: SxProps<Theme>;
};

const CustomTextField = ({
  dialogProps = { willSubmit: false, open: false },
  backgroundColor = '#FFF',
  ...props
}: customTextFieldProps): JSX.Element => {
  const scripts = useScripts();

  const required = props.required ? { required: true } : { required: false };
  const fullWidth = props.fullWidth ? { fullWidth: true } : { fullWidth: false };
  const helperText =
    props.required && !props.value && dialogProps.willSubmit && !props.emptyLabel ? (
      <Typography
        variant="caption"
        sx={{
          display: 'block',
          backgroundColor: '#FFF',
        }}
      >
        {`${scripts.pleaseEnterA} ${props.label}.`}
      </Typography>
    ) : (
      ''
    );
  const error = props.error
    ? {
        error:
          (props.error || (props.required ? !props.value : false)) &&
          dialogProps.willSubmit,
      }
    : { error: !!(props.required && !props.value && dialogProps.willSubmit) };
  const disabled = props.disabled ? { disabled: true } : { disabled: false };
  const preventAutoFill = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (!(e.nativeEvent instanceof InputEvent)) {
      e.preventDefault();
      e.target.value = props.value;
      if (props.firstInput) {
        e.target.focus();
      } else {
        e.target.blur();
      }

      return true;
    }

    return false;
  };
  const onKeyDown = props?.onKeyDown ? { onKeyDown: props.onKeyDown } : null;

  const isFirstInputFocusRef = useRef(false);
  useEffect(() => {
    if (!dialogProps?.firstInputRef) {
      return;
    }

    if (!dialogProps?.open) {
      isFirstInputFocusRef.current = false;
      return;
    }

    const formControl = dialogProps.firstInputRef.current;
    if (formControl && !isFirstInputFocusRef.current) {
      formControl.focus();
      isFirstInputFocusRef.current = true;
    }
  }, [dialogProps?.firstInputRef?.current, dialogProps?.open]);

  return (
    <FormControl
      {...required}
      {...error}
      {...fullWidth}
      {...disabled}
      {...(!props.formLabel ? { variant: 'standard' } : null)}
      {...(props?.sx ? { sx: { borderRadius: '5px', ...props.sx } } : null)}
    >
      {props.formLabel && (
        <FormLabel {...required} {...error} {...disabled}>
          {props.label}
        </FormLabel>
      )}
      <CustomInputText
        value={props.value}
        {...(!props.formLabel ? { label: props.label } : null)}
        {...required}
        {...error}
        {...fullWidth}
        {...(props.borderLine ? { borderline: props.borderLine } : null)}
        InputProps={{
          readOnly: !!props.readOnly,
          ...(props.endAdornment ? { endAdornment: props.endAdornment } : null),
          ...props.InputProps,
        }}
        {...disabled}
        onChange={
          props?.onChange
            ? (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (preventAutoFill(e)) {
                  return;
                }
                props?.onChange && props.onChange(e);
              }
            : (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (preventAutoFill(e)) {
                  return;
                }
                props?.setValue && props.setValue(e.target.value);
              }
        }
        {...onKeyDown}
        type={props.type ? props.type : 'text'}
        variant={props.variant ? props.variant : 'outlined'}
        helperText={
          props.helperText ? (
            <>
              {typeof props.helperText === 'string' && (
                <Typography
                  variant="caption"
                  sx={{
                    display: 'block',
                    backgroundColor: '#FFF',
                  }}
                >
                  {props.helperText}
                </Typography>
              )}
              {typeof props.helperText !== 'string' && props.helperText}
              {helperText}
            </>
          ) : (
            helperText
          )
        }
        size={props.size ? props.size : 'medium'}
        {...(props.firstInput && dialogProps?.firstInputRef
          ? { inputRef: dialogProps.firstInputRef }
          : null)}
        {...(!props.noTrim
          ? {
              onBlur: () => props?.setValue && props.setValue(props.value.trim()),
            }
          : null)}
        {...(props.placeholder ? { placeholder: props.placeholder } : null)}
        sx={{
          backgroundColor: backgroundColor,
          width: props.width,
          borderRadius: '5px',
          '& .MuiFormHelperText-root': {
            margin: 0,
          },
          ...props.sx,
        }}
        multiline={props.multiline}
        {...(props?.inputProps ? { inputProps: props.inputProps } : null)}
      />
    </FormControl>
  );
};

export default CustomTextField;
