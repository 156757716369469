import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

import adminViewModeReducer from './features/adminViewModeSlice';
import classReducer from './features/classSlice';
import levelsReducer from './features/levelsSlice';
import searchReducer from './features/searchQuerySlice';
import styleReducer from './features/styleSlice';
import toolbarReducer from './features/toolbarSlice';
import userReducer from './features/userSlice';
import webModeReducer from './features/webModeSlice';

const reducer = {
  user: userReducer,
  levels: levelsReducer,
  style: styleReducer,
  class: classReducer,
  toolbar: toolbarReducer,
  searchQuery: searchReducer,
  adminViewMode: adminViewModeReducer,
  webMode: webModeReducer,
};

export const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
