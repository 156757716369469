import IconType from './IconType';

const AddObjectIcon = ({ width = 18, height = 18, color = '#FFF', style }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M12 1.5H6C3 1.5 1.5 3 1.5 6V15.75C1.5 16.1625 1.8375 16.5 2.25 16.5H12C15 16.5 16.5 15 16.5 12V6C16.5 3 15 1.5 12 1.5Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.375 9H11.625"
        stroke={color}
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11.625V6.375"
        stroke={color}
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddObjectIcon;
