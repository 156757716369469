import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { Outlet, useLocation } from 'react-router-dom';

import { globalStyles } from '../../configs/theme/globalStyles';
import { managerStyles } from '../../configs/theme/managerStyle';
import { useAuth } from '../../globalHooks/useAuth';
import MainMenu from '../components/MainMenu';

const Layout = () => {
  const location = useLocation();
  const noMenu =
    location.pathname.indexOf('toolbar') > 0 || location.pathname.match(/\/style\/.*/);
  const mainMenu = noMenu ? null : <MainMenu />;
  const { managerRole, isManager } = useAuth();

  const webMode = localStorage.getItem('theme');

  return (
    <>
      <CssBaseline />
      <GlobalStyles styles={webMode === 'Light' ? globalStyles : managerStyles} />
      {/* top menu bar */}
      {managerRole ? null : mainMenu}
      <Outlet />
      {!managerRole ? null : mainMenu}
    </>
  );
};

export default Layout;
