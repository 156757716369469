import { useEffect, useRef, useState } from 'react';

type useDialogProps = {
  tooltipTitle?: string;
  dialogTitle: string | JSX.Element;
  dialogTitleAlign?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  getCustomButton?: (
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  ) => JSX.Element;
  resetInput?: () => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickForNoButton?: () => void;
  onClose?: () => void | boolean;
  inputCheckResult?: () => boolean;
  onSubmit?: () => void;
  setWillSubmitArr?: React.Dispatch<React.SetStateAction<boolean>>[];
  buttonSize?: 'medium' | 'large' | 'small';
  buttonColor?: string;
  hoverButtonColor?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
  spacing?: number;
  danger?: boolean;
  justifyContent?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  ContentTitle?: JSX.Element;
  showActionButton?: boolean;
};

const useDialog = (props: useDialogProps) => {
  const tooltipTitle = props.tooltipTitle;
  const dialogTitle = props.dialogTitle;
  const dialogTitleAlign = props.dialogTitleAlign ?? 'left';
  const buttonSize = props.buttonSize ?? 'medium';
  const buttonColor = props.buttonColor ?? '#00A591';
  const hoverButtonColor = props.hoverButtonColor ?? '#FFF';
  const maxWidth = props.maxWidth ?? 'md';
  const getCustomButton = props.getCustomButton;
  const fullWidth = typeof props.fullWidth !== 'undefined' ? props.fullWidth : true;
  const spacing = typeof props.spacing !== 'undefined' ? props.spacing : 2;
  const danger = props.danger ?? false;
  const justifyContent =
    typeof props.justifyContent !== undefined ? props.justifyContent : 'space-between';
  const alignItems = typeof props.alignItems !== undefined ? props.alignItems : 'stretch';
  const ContentTitle: JSX.Element =
    typeof props.alignItems !== undefined ? <>{props.ContentTitle}</> : <></>;
  const showActionButton = props.showActionButton ?? true;

  const [dialogOpen, setDialogOpen] = useState(false);
  const firstInputRef = useRef<HTMLDivElement>(null);
  const [willSubmit, setWillSubmit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.currentTarget?.blur();
    if (props.resetInput) {
      props.resetInput();
    }
    if (props.onClick) {
      props.onClick(e);
    }

    setDialogOpen(true);
  };
  const onClickForNoButton = () => {
    if (props.resetInput) {
      props.resetInput();
    }
    if (props.onClickForNoButton) {
      props.onClickForNoButton();
    }

    setDialogOpen(true);
  };
  const onClose = () => {
    const canCloseDialog =
      typeof props.onClose === 'undefined' || (props.onClose() ?? true);

    if (canCloseDialog) {
      setDialogOpen(false);
    }
  };
  const onSubmit = () => {
    setWillSubmit(true);
    if (props.setWillSubmitArr) {
      props.setWillSubmitArr.forEach((setWillSubmit) => {
        setWillSubmit(true);
      });
    }

    if (
      typeof props.inputCheckResult !== 'undefined' &&
      props.inputCheckResult() === false
    ) {
      return;
    }

    props.onSubmit && props.onSubmit();
    setIsSubmit(true);
    onClose();
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (dialogOpen) {
      setWillSubmit(false);
      if (props.setWillSubmitArr) {
        props.setWillSubmitArr.forEach((setWillSubmit) => {
          setWillSubmit(false);
        });
      }

      setIsSubmit(false);
    }
  }, [dialogOpen]);

  return {
    dialogProps: {
      tooltipTitle,
      dialogTitle,
      dialogTitleAlign,
      getCustomButton,
      onClick,
      onClickForNoButton,
      onClose,
      onSubmit,
      closeDialog,
      firstInputRef,
      willSubmit,
      isSubmit,
      buttonSize,
      buttonColor,
      hoverButtonColor,
      maxWidth,
      fullWidth,
      spacing,
      danger,
      justifyContent,
      alignItems,
      ContentTitle,
      showActionButton,
      open: dialogOpen,
    },
  };
};

export default useDialog;
