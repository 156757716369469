import { createContext, useContext, useRef } from 'react';

import Box from '@mui/material/Box';
import {
  DefaultDraftBlockRenderMap,
  Editor,
  EditorState,
  RawDraftContentState,
  SelectionState,
} from 'draft-js';
import Immutable from 'immutable';

import { Layout } from '../../../graphql/resolver.types';
import {
  EditTextAlignBlock,
  EditTextType,
  getEditorState,
  getRaw,
} from '../components/text/hooks/useUpdateFontStyle';

type FontEditorContextProps = {
  editorRefs: {
    elementRef: React.MutableRefObject<HTMLDivElement | null>;
    selectedIdRef: React.MutableRefObject<string>;
    editorRef: React.MutableRefObject<React.RefObject<Editor> | null>;
    editorStateRef: React.MutableRefObject<EditorState | null>;
    setEditorStateRef: React.MutableRefObject<React.Dispatch<
      React.SetStateAction<EditorState>
    > | null>;
  } | null;
  cursorRefs: {
    anchorKeyRef: React.MutableRefObject<string>;
    anchorOffsetRef: React.MutableRefObject<number>;
    focusKeyRef: React.MutableRefObject<string>;
    focusOffsetRef: React.MutableRefObject<number>;
  } | null;
  textLockAspectRatioRef: React.MutableRefObject<boolean | null> | null;
  setFontSizeNumRef: React.MutableRefObject<
    ((fontSizeNum: number | null) => void) | null
  > | null;
  setFontAlignRef: React.MutableRefObject<
    ((fontAlign: 'left' | 'center' | 'right' | null) => void) | null
  > | null;
  setFontWeightNumRef: React.MutableRefObject<
    ((fontWeightNum: number | null) => void) | null
  > | null;
  minDivElInfoRef: React.MutableRefObject<{
    id: string;
    minDivEl: HTMLDivElement;
  } | null> | null;
  extendedBlockRenderMap?: Immutable.Map<any, any>;
  currentUpdateType: (type?: EditTextType) => string;
  getTextSizePercent: (
    serial: number,
    isLandscape: boolean,
  ) => { width: number; height: number };
  addUndo: (editorState: EditorState) => void;
  getUndo: () => EditorState | undefined;
  getRedo: () => EditorState | undefined;
  clearUndoAndRedo: () => void;
  textContentRef: React.MutableRefObject<{
    [key: string]: string;
  }> | null;
  copyTextInfoRef: React.MutableRefObject<{
    raw: RawDraftContentState;
    selection: SelectionState;
  } | null> | null;
};
export const FontEditorContext = createContext<FontEditorContextProps>({
  editorRefs: null,
  cursorRefs: null,
  textLockAspectRatioRef: null,
  setFontSizeNumRef: null,
  setFontAlignRef: null,
  setFontWeightNumRef: null,
  minDivElInfoRef: null,
  currentUpdateType: () => '',
  getTextSizePercent: () => ({ width: 1, height: 1 }),
  addUndo: () => undefined,
  getUndo: () => undefined,
  getRedo: () => undefined,
  clearUndoAndRedo: () => undefined,
  textContentRef: null,
  copyTextInfoRef: null,
});

export const defaultFontWeightNum = 400;
export const getFontWeightNum = (fontWeightNum: number | string) => {
  const fontWeightNumCopy = Number(fontWeightNum);

  return Number.isNaN(fontWeightNumCopy) || !`${fontWeightNum}`.trim()
    ? 400
    : fontWeightNumCopy;
};
export const getFontWeight = (fontWeightNum: number | string) => {
  return `${EditTextType.FontWeight}${getFontWeightNum(fontWeightNum)}`;
};

export const getDefaultFontSizeNum = (layout: Layout) => {
  return layout === Layout.Landscape ? 29 : 27;
};
export const getFontSizeNum = (layout: Layout, fontSize: number | string) => {
  const fontSizeCopy = Number(fontSize);

  return Number.isNaN(fontSizeCopy) || !`${fontSize}`.trim()
    ? getDefaultFontSizeNum(layout)
    : fontSizeCopy <= 1
    ? 1
    : fontSizeCopy;
};
export const getFontSize = (layout: Layout, fontSizeNum: number | string) => {
  return `${EditTextType.FontSize}${getFontSizeNum(layout, fontSizeNum)}px`;
};
export const getFontFamily = (fontFamily: string) => {
  return `${EditTextType.FontFamily}${fontFamily}`;
};

const FontEditorProvider = ({ children }: any) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const selectedIdRef = useRef('');
  const editorRef = useRef<React.RefObject<Editor> | null>(null);
  const editorStateRef = useRef<EditorState | null>(null);
  const setEditorStateRef = useRef<React.Dispatch<
    React.SetStateAction<EditorState>
  > | null>(null);

  const editorRefs = {
    elementRef,
    selectedIdRef,
    editorRef,
    editorStateRef,
    setEditorStateRef,
  };

  const anchorKeyRef = useRef('');
  const anchorOffsetRef = useRef(0);
  const focusKeyRef = useRef('');
  const focusOffsetRef = useRef(0);

  const cursorRefs = {
    anchorKeyRef,
    anchorOffsetRef,
    focusKeyRef,
    focusOffsetRef,
  };

  const textLockAspectRatioRef = useRef<boolean | null>(null);
  const setFontSizeNumRef = useRef<((fontSizeNum: number | null) => void) | null>(null);
  const setFontAlignRef = useRef<
    ((fontAlign: 'left' | 'center' | 'right' | null) => void) | null
  >(null);
  const setFontWeightNumRef = useRef<((fontWeightNum: number | null) => void) | null>(
    null,
  );
  const minDivElInfoRef = useRef<{
    id: string;
    minDivEl: HTMLDivElement;
  } | null>(null);

  const extendedBlockRenderMapRef = useRef(
    DefaultDraftBlockRenderMap.merge(
      Immutable.Map({
        [`${EditTextAlignBlock.Left}`]: {
          element: 'div',
          wrapper: <Box textAlign="left" />,
        },
        [`${EditTextAlignBlock.Center}`]: {
          element: 'div',
          wrapper: <Box textAlign="center" />,
        },
        [`${EditTextAlignBlock.Right}`]: {
          element: 'div',
          wrapper: <Box textAlign="right" />,
        },
      }),
    ),
  );

  const updateTypeRef = useRef('');

  const undoListRef = useRef<EditorState[]>([]);
  const redoListRef = useRef<EditorState[]>([]);

  const textContentRef = useRef<{ [key: string]: string }>({});

  const copyTextInfoRef = useRef<{
    raw: RawDraftContentState;
    selection: SelectionState;
  } | null>(null);

  return (
    <FontEditorContext.Provider
      value={{
        editorRefs,
        cursorRefs,
        textLockAspectRatioRef,
        setFontSizeNumRef,
        setFontAlignRef,
        setFontWeightNumRef,
        minDivElInfoRef,
        extendedBlockRenderMap: extendedBlockRenderMapRef.current,
        currentUpdateType: (type) => {
          if (typeof type === 'string') {
            updateTypeRef.current = type;
            return type;
          } else {
            const typeCopy = updateTypeRef.current;
            updateTypeRef.current = '';
            return typeCopy;
          }
        },
        getTextSizePercent: (serial: number, isLandscape: boolean) => {
          const additionalIncreaseWidth = 0.02;
          let width = 1;
          if (serial < 10) {
            width = (isLandscape ? 0.137974 : 0.403162) + additionalIncreaseWidth;
          } else if (serial < 100) {
            width = (isLandscape ? 0.149905 : 0.438025) + additionalIncreaseWidth;
          } else if (serial < 1000) {
            width = (isLandscape ? 0.161852 : 0.472934) + additionalIncreaseWidth;
          } else {
            width = (isLandscape ? 0.173791 : 0.50782) + additionalIncreaseWidth;
          }

          return {
            width,
            height: isLandscape ? 0.061705 : 0.057045,
          };
        },
        addUndo: (editorState: EditorState) => {
          if (undoListRef.current.length > 0) {
            if (
              JSON.stringify(
                getRaw(undoListRef.current[undoListRef.current.length - 1]),
              ) !== JSON.stringify(getRaw(editorState))
            ) {
              undoListRef.current.push(getEditorState(getRaw(editorState)));
            }
          } else {
            undoListRef.current.push(getEditorState(getRaw(editorState)));
          }
        },
        getUndo: () => {
          if (editorStateRef.current) {
            const undo = undoListRef.current.pop();

            if (undo) {
              redoListRef.current.push(editorStateRef.current);

              return getEditorState(getRaw(undo));
            }
          }
        },
        getRedo: () => {
          const redo = redoListRef.current.pop();
          if (redo && editorStateRef.current) {
            if (redoListRef.current.length > 0) {
              if (undoListRef.current.length === 0) {
                undoListRef.current.push(editorStateRef.current);
              }
              undoListRef.current.push(redo);
            } else if (undoListRef.current.length === 0) {
              undoListRef.current.push(editorStateRef.current);
            }

            return getEditorState(getRaw(redo));
          }
        },
        clearUndoAndRedo: () => {
          undoListRef.current.splice(0);
          redoListRef.current.splice(0);
        },
        textContentRef,
        copyTextInfoRef,
      }}
    >
      {children}
    </FontEditorContext.Provider>
  );
};
export default FontEditorProvider;

export const useFontEditorContext = () => {
  return useContext<FontEditorContextProps>(FontEditorContext);
};
