import IconType from './IconType';

const MicCircleIcon = ({ width = 32, height = 32, color = '#FFF', style }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="40.5" cy="40" rx="40.5" ry="40" fill="#B8B8B8" />
      <path
        d="M40.998 46.9922C45.418 46.9922 48.998 43.4122 48.998 38.9922V27.9922C48.998 23.5722 45.418 19.9922 40.998 19.9922C36.578 19.9922 32.998 23.5722 32.998 27.9922V38.9922C32.998 43.4122 36.578 46.9922 40.998 46.9922Z"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6978 35.3066V38.7066C25.6978 47.1466 32.5578 54.0066 40.9978 54.0066C49.4378 54.0066 56.2978 47.1466 56.2978 38.7066V35.3066"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="38.5164"
        y1="28.6172"
        x2="43.483"
        y2="28.6172"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <line
        x1="38.5164"
        y1="32.8838"
        x2="43.483"
        y2="32.8838"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M41 54.0078V60.0078"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MicCircleIcon;
