import ImageBasic, { ImageBasicProps } from './ImageBasic';

export const Button = (props: ImageBasicProps) => {
  return (
    <ImageBasic
      imgSx={{ '&:active': { transform: 'scale(0.8)', opacity: 0.9 } }}
      {...props}
    />
  );
};
