import Box from '@mui/material/Box';

import FakeImage from '../../../../../globalComponents/FakeImage';

export default function FallbackImage() {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        backgroundImage: `url("${FakeImage}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 25%',
        backgroundSize: 'cover',
      }}
    />
  );
}
