import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { default as Grid } from '@mui/material/Unstable_Grid2';

import { useWarningModalContext } from '../globalHooks/useWarningModalHooks';
import { useScripts } from '../layout/utils/LanguageHelper';

const WarningModal = () => {
  const warningModal = useWarningModalContext();

  const scripts = useScripts();

  const handleClose = () => {
    warningModal?.setModalState({ alertMessage: '', cancel: false, show: false });
  };

  const buttonWrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
  };

  return (
    <Container>
      <Box>
        <Grid xs={12}>
          <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={warningModal?.modalState.show ? true : false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              sx={{ textAlign: 'center', color: '#6B6B6B' }}
              id="alert-dialog-title"
            >
              {warningModal?.modalState.alertTitle}
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'center' }}>
              <DialogContentText
                sx={{ textAlign: 'center' }}
                id="alert-dialog-description"
              >
                {warningModal?.modalState.alertMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={buttonWrapperStyle}>
              {warningModal?.modalState?.cancel && (
                <Button variant="contained" onClick={handleClose}>
                  {scripts.cancel}
                </Button>
              )}
              <Button
                // variant="contained"
                sx={{ background: '#D9D9D9', borderRadius: '10px' }}
                onClick={warningModal?.modalState.confirm || handleClose}
                autoFocus
              >
                {scripts.ok}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    </Container>
  );
};

export default WarningModal;
