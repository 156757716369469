import { useEffect, useState } from 'react';

import CustomAutocomplete from '../../../globalComponents/CustomAutocomplete';
import { useClassroomsWithIdNameSchoolIdLazyQuery } from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';

type SelectClassroomProps = {
  classId?: string;
  required?: boolean;
  onChildSetClickedClassId: (arg: string) => void;
  reset?: (arg: string) => void;
  helperText?: string;
  startQuery?: boolean;
  onChildSetClickedClassSchoolId?: (arg: string) => void;
  willSubmit?: boolean;
  dataLoading?: boolean;
  resetArray?: ((arg: string) => void)[];
  defaultSelectFirst?: boolean;
};

const SelectClassroom = ({
  classId,
  required = false,
  onChildSetClickedClassId,
  reset,
  helperText,
  startQuery,
  onChildSetClickedClassSchoolId,
  willSubmit = false,
  dataLoading,
  resetArray,
  defaultSelectFirst = false,
}: SelectClassroomProps) => {
  // const { dialogProps } = useDialog({
  //   dialogTitle: 'Select Classroom',
  //   // inputCheckResult: () => {
  //   //   return !!!clickedClassId;
  //   // },
  // });

  const scripts = useScripts();

  const [
    fetchTeacherClasses,
    { data: teacherClassesData, loading: teacherClassesLoading },
  ] = useClassroomsWithIdNameSchoolIdLazyQuery();

  const [clickedClassId, setClickedClassId] = useState(classId);

  const [clickedClassSchoolId, setClickedClassSchoolId] = useState('');

  const clickedClassData = teacherClassesData?.classrooms.edges?.filter(
    ({ node }) => node.id === clickedClassId,
  );

  useEffect(() => {
    if (startQuery) {
      fetchTeacherClasses({ variables: { first: 0 } });
    }
  }, [startQuery]);

  useEffect(() => {
    if (defaultSelectFirst && !classId && teacherClassesData) {
      setClickedClassId(teacherClassesData?.classrooms?.edges?.[0]?.node?.id);
    }
  }, [defaultSelectFirst, classId, teacherClassesData]);

  useEffect(() => {
    if (reset) {
      reset('');
    }
    if (resetArray) {
      resetArray.forEach((fn) => {
        fn('');
      });
    }
    if (clickedClassId) {
      onChildSetClickedClassId(clickedClassId);
      setClickedClassSchoolId(
        teacherClassesData?.classrooms?.edges?.filter(
          ({ node }) => node.id === clickedClassId,
        )?.[0]?.node?.schoolId || '',
      );
    }
    if (clickedClassId === '') {
      onChildSetClickedClassId('');
      setClickedClassSchoolId('');
    }
  }, [clickedClassId]);

  useEffect(() => {
    if (onChildSetClickedClassSchoolId && clickedClassSchoolId) {
      onChildSetClickedClassSchoolId(clickedClassSchoolId);
    }
  }, [clickedClassSchoolId]);

  return (
    <CustomAutocomplete
      disabled={!teacherClassesData || dataLoading}
      loading={teacherClassesLoading}
      options={
        teacherClassesData?.classrooms.edges?.map(({ node }) => ({
          label: node.name,
          value: node.id,
        })) ?? []
      }
      value={
        clickedClassData?.map(({ node }) => ({
          label: node.name,
          value: node.id,
        })) ?? []
      }
      onChangeForSingleValue={(e, option) => {
        setClickedClassId(option?.value ?? '');
      }}
      label={scripts.classroom}
      fullWidth={true}
      dialogProps={{ willSubmit }}
      required={required}
      helperText={
        helperText
          ? helperText
          : willSubmit && !clickedClassId
          ? 'Required for level data'
          : ''
      }
    />
  );
};

export default SelectClassroom;
