import { Suspense } from 'react';

import { ApolloProvider } from '@apollo/client';
import { lazy } from '@loadable/component';
import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import AccountPage from './account/views/AccountPage';
import MediaPage from './account/views/MediaPage';
import SingleAudioPage from './account/views/SingleAudioPage';
import SingleVideoPage from './account/views/SingleVideoPage';
import { client } from './apollo/apollo.client';
import adminDarkTheme from './configs/theme/adminDarkTheme';
import adminLightTheme from './configs/theme/adminLightTheme';
import WarningModal from './globalComponents/WarningModal';
import { WarningModalContextProvider } from './globalHooks/useWarningModalHooks';
import { LevelType } from './graphql/resolver.types';
import { LanguageProvider } from './layout/utils/LanguageHelper';
import NoResultFoundSvg from './layout/utils/NoResultFoundSvg';
import Layout from './layout/views/Layout';
import PrincipalLeads from './principal/leads/views/PrincipalLeads';
import { useAppSelector, store } from './redux/store';
import TeacherCheckGrades from './teacher/editClasses/views/TeacherCheckGrades';
import { UserRouteAuth } from './types/types.users';

const AdminToday = lazy(() => import('./admin/today/views/Admin.today'));
const AdminEditLevel = lazy(() => import('./admin/editTeach/views/AdminEditLevel'));
const AdminEditLesson = lazy(() => import('./admin/editTeach/views/AdminEditLesson'));
const AdminEditPart = lazy(() => import('./admin/editTeach/views/AdminEditStyle'));
const AdminEditResource = lazy(
  () => import('./admin/editResource/views/AdminEditResource'),
);
const AdminLevelTrash = lazy(() => import('./admin/editTeach/views/AdminLevelTrash'));
const AdminBookTrash = lazy(() => import('./admin/editTeach/views/AdminBookTrash'));
const AdminLessonTrash = lazy(() => import('./admin/editTeach/views/AdminLessonTrash'));
const AdminPartTrash = lazy(() => import('./admin/editTeach/views/AdminPartTrash'));
const AdminStyleTrash = lazy(() => import('./admin/editTeach/views/AdminStyleTrash'));
const AdminResourceTrash = lazy(
  () => import('./admin/editResource/views/AdminResourceTrash'),
);
const AdminResourceContent = lazy(
  () => import('./admin/editResource/views/AdminResourceContent'),
);
const AdminResourceMediaTrash = lazy(
  () => import('./admin/editResource/views/AdminResourceMediaTrash'),
);
const AdminEditSchools = lazy(() => import('./admin/editSchool/views/AdminEditSchools'));
const AdminEditSchoolContent = lazy(
  () => import('./admin/editSchool/views/AdminEditSchoolContent'),
);
const AdminEditSchoolClassroom = lazy(
  () => import('./admin/editSchool/views/AdminEditSchoolClassroom'),
);
const AdminTeacherCodes = lazy(
  () => import('./admin/editSchool/views/AdminTeacherCodes'),
);
const AdminEditStyle = lazy(() => import('./admin/editStyle/views/EditStyle'));
// const AdminEditDailies = lazy(() => import('./admin/editDaily/views/AdminEditDailies'));
const AdminEditDailies = lazy(() => import('./admin/editDaily/views/AdminEditDailies'));
const AdminClassroomDaily = lazy(
  () => import('./admin/editDaily/views/AdminClassroomDaily'),
);
const AdminRosterDaily = lazy(() => import('./admin/editDaily/views/AdminRosterDaily'));
const AdminMakeUpClassDaily = lazy(
  () => import('./admin/editDaily/views/AdminMakeUpClassDaily'),
);
const AdminTeachDaily = lazy(() => import('./admin/editDaily/views/AdminTeachDaily'));
const PrincipalToday = lazy(() => import('./principal/today/views/Principal.today'));
const StudentHomework = lazy(() => import('./student/homework/views/StudentHomework'));
const TeacherToday = lazy(() => import('./teacher/today/views/TeacherToday'));
const TeacherSchedule = lazy(() => import('./teacher/schedule/views/TeacherSchedule'));
// const TeacherTeachStyle = lazy(() => import('./teacher/teach/views/TeacherTeachStyle')); //discard by 2023/11/03,when style stable can del
const TeacherClasses = lazy(() => import('./teacher/editClasses/views/TeacherClasses'));
const TeacherStudents = lazy(() => import('./teacher/editClasses/views/TeacherStudents'));
const TeacherHomework = lazy(() => import('./teacher/editClasses/views/TeacherHomework'));
const TeacherTestScores = lazy(
  () => import('./teacher/editClasses/views/TeacherTestScores'),
);
const Login = lazy(() => import('./landing/views/Login'));
const Register = lazy(() => import('./landing/views/Register'));
const ErrorFallback = lazy(() => import('./landing/views/ErrorFallback'));
const ProtectedRoutes = lazy(() => import('./routes/ProtectedRoutes'));
const TeacherHomeworkDetail = lazy(
  () => import('./teacher/editClasses/views/TeacherHomeworkDetail'),
);

const App = () => {
  const AppContent = () => {
    const theme = useSelector((state: any) => state.webMode.currentMode);

    return (
      <ThemeProvider theme={theme === 'Light' ? adminLightTheme : adminDarkTheme}>
        <AppRoutes />
        <WarningModal />
      </ThemeProvider>
    );
  };

  return (
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <WarningModalContextProvider>
          <LanguageProvider>
            <AppContent />
          </LanguageProvider>
        </WarningModalContextProvider>
      </ReduxProvider>
    </ApolloProvider>
  );
};

const AppRoutes = () => {
  const role = useAppSelector((state) => state.user.role);
  const group = useAppSelector((state) => state.user.group);

  const user: UserRouteAuth = {
    role,
    group,
  };

  const superRoutes = {
    element: <ProtectedRoutes canAccessRoles={['super']} user={user} />,
    children: [
      { path: '/super', element: <AdminToday /> },
      { path: '/super/editLearn', element: <AdminEditLevel type={LevelType.Learn} /> },
      { path: '/super/editTeach', element: <AdminEditLevel type={LevelType.Teach} /> },
      {
        path: '/super/editLearn/level/trash',
        element: <AdminLevelTrash type={LevelType.Learn} />,
      },
      {
        path: '/super/editTeach/level/trash',
        element: <AdminLevelTrash type={LevelType.Teach} />,
      },
      {
        path: '/super/editLearn/level/:levelId/trash',
        element: <AdminBookTrash type={LevelType.Learn} />,
      },
      {
        path: '/super/editTeach/level/:levelId/trash',
        element: <AdminBookTrash type={LevelType.Teach} />,
      },
      {
        path: '/super/editLearn/level/:levelId/book/:bookId/trash',
        element: <AdminLessonTrash type={LevelType.Learn} />,
      },
      {
        path: '/super/editTeach/level/:levelId/book/:bookId/trash',
        element: <AdminLessonTrash type={LevelType.Teach} />,
      },
      {
        path: '/super/editLearn/level/:levelId/book/:bookId/lesson/:lessonId/trash',
        element: <AdminPartTrash type={LevelType.Learn} />,
      },
      {
        path: '/super/editTeach/level/:levelId/book/:bookId/lesson/:lessonId/trash',
        element: <AdminPartTrash type={LevelType.Teach} />,
      },
      {
        path: '/super/editLearn/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/trash',
        element: <AdminStyleTrash type={LevelType.Learn} />,
      },
      {
        path: '/super/editTeach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/trash',
        element: <AdminStyleTrash type={LevelType.Teach} />,
      },
      {
        path: '/super/editLearn/level/:levelId/book/:bookId',
        element: <AdminEditLesson type={LevelType.Learn} />,
      },
      {
        path: '/super/editTeach/level/:levelId/book/:bookId',
        element: <AdminEditLesson type={LevelType.Teach} />,
      },
      {
        path: '/super/editLearn/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId',
        element: <AdminEditPart type={LevelType.Learn} />,
      },
      {
        path: '/super/editTeach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId',
        element: <AdminEditPart type={LevelType.Teach} />,
      },
      {
        path: '/super/editLearn/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId',
        element: <AdminEditStyle />,
      },
      {
        path: '/super/editTeach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId',
        element: <AdminEditStyle />,
      },
      { path: '/super/editResource', element: <AdminEditResource /> },
      { path: '/super/editResource/trash', element: <AdminResourceTrash /> },
      { path: '/super/editResource/:resourceId', element: <AdminResourceContent /> },
      {
        path: '/super/editResource/:resourceId/folder/:parentFolderId',
        element: <AdminResourceContent />,
      },
      {
        path: '/super/editResource/:resourceId/trash',
        element: <AdminResourceMediaTrash />,
      },
      {
        path: '/super/editResource/:resourceId/folder/:parentFolderId/trash',
        element: <AdminResourceMediaTrash />,
      },
      { path: '/super/editSchools', element: <AdminEditSchools /> },
      { path: '/super/editSchools/:schoolId', element: <AdminEditSchoolContent /> },
      {
        path: '/super/editSchools/:schoolId/classroom/:classroomId',
        element: <AdminEditSchoolClassroom />,
      },
      {
        path: '/super/editSchools/teacherCodes/:schoolId',
        element: <AdminTeacherCodes />,
      },
      { path: '/super/editStyle', element: <AdminEditStyle /> },
      { path: '/super/leads', element: <PrincipalLeads /> },
      { path: '/super/editDaily', element: <AdminEditDailies /> },
      {
        path: '/super/editDaily/classroom/:classroomId',
        element: <AdminClassroomDaily />,
      },
      { path: '/super/editDaily/roster/:classroomId', element: <AdminRosterDaily /> },
      {
        path: '/super/editDaily/makeUpClass/:schoolId',
        element: <AdminMakeUpClassDaily />,
      },
      {
        path: '/super/editDaily/teach/:classroomId',
        element: <AdminTeachDaily />,
      },
      {
        path: '/super/account',
        element: <AccountPage />,
      },
    ],
  };

  const adminRoutes = {
    element: <ProtectedRoutes canAccessRoles={['admin']} user={user} />,
    children: [
      { path: '/admin', element: <AdminToday /> },
      { path: '/admin/editLearn', element: <AdminEditLevel type={LevelType.Learn} /> },
      { path: '/admin/editTeach', element: <AdminEditLevel type={LevelType.Teach} /> },
      {
        path: '/admin/editLearn/level/trash',
        element: <AdminLevelTrash type={LevelType.Learn} />,
      },
      {
        path: '/admin/editTeach/level/trash',
        element: <AdminLevelTrash type={LevelType.Teach} />,
      },
      {
        path: '/admin/editLearn/level/:levelId/trash',
        element: <AdminBookTrash type={LevelType.Learn} />,
      },
      {
        path: '/admin/editTeach/level/:levelId/trash',
        element: <AdminBookTrash type={LevelType.Teach} />,
      },
      {
        path: '/admin/editLearn/level/:levelId/book/:bookId/trash',
        element: <AdminLessonTrash type={LevelType.Learn} />,
      },
      {
        path: '/admin/editTeach/level/:levelId/book/:bookId/trash',
        element: <AdminLessonTrash type={LevelType.Teach} />,
      },
      {
        path: '/admin/editLearn/level/:levelId/book/:bookId/lesson/:lessonId/trash',
        element: <AdminPartTrash type={LevelType.Learn} />,
      },
      {
        path: '/admin/editTeach/level/:levelId/book/:bookId/lesson/:lessonId/trash',
        element: <AdminPartTrash type={LevelType.Teach} />,
      },
      {
        path: '/admin/editLearn/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/trash',
        element: <AdminStyleTrash type={LevelType.Learn} />,
      },
      {
        path: '/admin/editTeach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/trash',
        element: <AdminStyleTrash type={LevelType.Teach} />,
      },
      {
        path: '/admin/editLearn/level/:levelId/book/:bookId',
        element: <AdminEditLesson type={LevelType.Learn} />,
      },
      {
        path: '/admin/editTeach/level/:levelId/book/:bookId',
        element: <AdminEditLesson type={LevelType.Teach} />,
      },
      {
        path: '/admin/editLearn/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId',
        element: <AdminEditPart type={LevelType.Learn} />,
      },
      {
        path: '/admin/editTeach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId',
        element: <AdminEditPart type={LevelType.Teach} />,
      },
      {
        path: '/admin/editLearn/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId',
        element: <AdminEditStyle />,
      },
      {
        path: '/admin/editTeach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId',
        element: <AdminEditStyle />,
      },
      { path: '/admin/editResource', element: <AdminEditResource /> },
      { path: '/admin/editResource/trash', element: <AdminResourceTrash /> },
      { path: '/admin/editResource/:resourceId', element: <AdminResourceContent /> },
      {
        path: '/admin/editResource/:resourceId/folder/:parentFolderId',
        element: <AdminResourceContent />,
      },
      {
        path: '/admin/editResource/:resourceId/trash',
        element: <AdminResourceMediaTrash />,
      },
      {
        path: '/admin/editResource/:resourceId/folder/:parentFolderId/trash',
        element: <AdminResourceMediaTrash />,
      },
      { path: '/admin/editSchools', element: <AdminEditSchools /> },
      { path: '/admin/editSchools/:schoolId', element: <AdminEditSchoolContent /> },
      {
        path: '/admin/editSchools/:schoolId/classroom/:classroomId',
        element: <AdminEditSchoolClassroom />,
      },
      {
        path: '/admin/editSchools/teacherCodes/:schoolId',
        element: <AdminTeacherCodes />,
      },
      { path: '/admin/editStyle', element: <AdminEditStyle /> },
      { path: '/admin/leads', element: <PrincipalLeads /> },
      { path: '/admin/editDaily', element: <AdminEditDailies /> },
      {
        path: '/admin/editDaily/classroom/:classroomId',
        element: <AdminClassroomDaily />,
      },
      { path: '/admin/editDaily/roster/:classroomId', element: <AdminRosterDaily /> },
      {
        path: '/admin/editDaily/makeUpClass/:schoolId',
        element: <AdminMakeUpClassDaily />,
      },
      {
        path: '/admin/editDaily/teach/:classroomId',
        element: <AdminTeachDaily />,
      },
      {
        path: '/admin/account',
        element: <AccountPage />,
      },
    ],
  };

  const principalRoutes = {
    element: <ProtectedRoutes canAccessRoles={['principal']} user={user} />,
    children: [
      { path: '/principal', element: <PrincipalToday /> },
      { path: '/principal/schools', element: <AdminEditSchools /> },
      { path: '/principal/editSchools', element: <AdminEditSchools /> },
      { path: '/principal/editSchools/:schoolId', element: <AdminEditSchoolContent /> },
      {
        path: '/principal/editSchools/:schoolId/classroom/:classroomId',
        element: <AdminEditSchoolClassroom />,
      },
      {
        path: '/principal/editSchools/teacherCodes/:schoolId',
        element: <AdminTeacherCodes />,
      },
      { path: '/principal/schedule', element: <TeacherSchedule /> },
      { path: '/principal/learn', element: <AdminEditLevel type={LevelType.Learn} /> },
      { path: '/principal/teach', element: <AdminEditLevel type={LevelType.Teach} /> },
      {
        path: '/principal/learn/level/:levelId/book/:bookId',
        element: <AdminEditLesson type={LevelType.Learn} />,
      },
      {
        path: '/principal/teach/level/:levelId/book/:bookId',
        element: <AdminEditLesson type={LevelType.Teach} />,
      },
      {
        path: '/principal/teach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId',
        element: <AdminEditPart type={LevelType.Teach} />,
      },
      {
        path: '/principal/teach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId/preparing',
        element: <AdminEditStyle />,
      },
      {
        path: '/principal/learn/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId',
        element: <AdminEditStyle />,
      },
      {
        path: '/principal/teach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId/schedule/:scheduleId/preparing',
        element: <AdminEditStyle />, //enter from teacherpreparing form
      },
      {
        path: '/principal/learn/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId/schedule/:scheduleId/teaching',
        element: <AdminEditStyle />, //enter from teacherpreparing form
      },
      { path: '/principal/resources', element: <AdminEditResource /> },
      { path: '/principal/resources/:resourceId', element: <AdminResourceContent /> },
      {
        path: '/principal/resources/:resourceId/folder/:parentFolderId',
        element: <AdminResourceContent />,
      },
      { path: '/principal/editResource/:resourceId', element: <AdminResourceContent /> },
      {
        path: '/principal/editResource/:resourceId/folder/:parentFolderId',
        element: <AdminResourceContent />,
      },
      { path: '/principal/leads', element: <PrincipalLeads /> },
      { path: '/principal/daily', element: <AdminEditDailies /> },
      {
        path: '/principal/daily/classroom/:classroomId',
        element: <AdminClassroomDaily />,
      },
      { path: '/principal/daily/roster/:classroomId', element: <AdminRosterDaily /> },
      {
        path: '/principal/daily/makeUpClass/:schoolId',
        element: <AdminMakeUpClassDaily />,
      },
      {
        path: '/principal/daily/teach/:classroomId',
        element: <AdminTeachDaily />,
      },
      {
        path: '/principal/account',
        element: <AccountPage />,
      },
    ],
  };

  const secretaryRoutes = {
    element: <ProtectedRoutes canAccessRoles={['secretary']} user={user} />,
    children: [
      { path: '/secretary', element: <PrincipalToday /> },
      { path: '/secretary/schools', element: <AdminEditSchools /> },
      { path: '/secretary/editSchools', element: <AdminEditSchools /> },
      { path: '/secretary/editSchools/:schoolId', element: <AdminEditSchoolContent /> },
      {
        path: '/secretary/editSchools/:schoolId/classroom/:classroomId',
        element: <AdminEditSchoolClassroom />,
      },
      {
        path: '/secretary/editSchools/teacherCodes/:schoolId',
        element: <AdminTeacherCodes />,
      },
      { path: '/secretary/schedule', element: <TeacherSchedule /> },
      { path: '/secretary/learn', element: <AdminEditLevel type={LevelType.Learn} /> },
      { path: '/secretary/teach', element: <AdminEditLevel type={LevelType.Teach} /> },
      {
        path: '/secretary/learn/level/:levelId/book/:bookId',
        element: <AdminEditLesson type={LevelType.Learn} />,
      },
      {
        path: '/secretary/teach/level/:levelId/book/:bookId',
        element: <AdminEditLesson type={LevelType.Teach} />,
      },
      {
        path: '/secretary/teach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId/preparing',
        element: <AdminEditStyle />,
      },
      {
        path: '/secretary/learn/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId',
        element: <AdminEditStyle />,
      },
      { path: '/secretary/resources', element: <AdminEditResource /> },
      { path: '/secretary/resources/:resourceId', element: <AdminResourceContent /> },
      {
        path: '/secretary/resources/:resourceId/folder/:parentFolderId',
        element: <AdminResourceContent />,
      },
      { path: '/secretary/editResource/:resourceId', element: <AdminResourceContent /> },
      {
        path: '/secretary/editResource/:resourceId/folder/:parentFolderId',
        element: <AdminResourceContent />,
      },
      { path: '/secretary/daily', element: <AdminEditDailies /> },
      {
        path: '/secretary/daily/classroom/:classroomId',
        element: <AdminClassroomDaily />,
      },
      { path: '/secretary/daily/roster/:classroomId', element: <AdminRosterDaily /> },
      {
        path: '/secretary/daily/makeUpClass/:schoolId',
        element: <AdminMakeUpClassDaily />,
      },
      {
        path: '/secretary/daily/teach/:classroomId',
        element: <AdminTeachDaily />,
      },
      {
        path: '/secretary/account',
        element: <AccountPage />,
      },
    ],
  };

  const teacherRoutes = {
    element: <ProtectedRoutes canAccessRoles={['teacher']} user={user} />,
    children: [
      { path: '/teacher', element: <TeacherToday /> },
      { path: '/teacher/schedule', element: <TeacherSchedule /> },
      { path: '/teacher/learn', element: <AdminEditLevel type={LevelType.Learn} /> },
      { path: '/teacher/teach', element: <AdminEditLevel type={LevelType.Teach} /> },
      {
        path: '/teacher/learn/level/:levelId/book/:bookId',
        element: <AdminEditLesson type={LevelType.Learn} />,
      },
      {
        path: '/teacher/teach/level/:levelId/book/:bookId',
        element: <AdminEditLesson type={LevelType.Teach} />,
      },
      {
        path: '/teacher/teach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId/preparing',
        element: <AdminEditStyle />, //enter from teach
      },
      {
        path: '/teacher/learn/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId',
        element: <AdminEditStyle />,
      },
      {
        path: '/teacher/teach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId/schedule/:scheduleId/preparing',
        element: <AdminEditStyle />, //enter from teacherpreparing form
      },
      {
        path: '/teacher/teach/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId/schedule/:scheduleId/teaching',
        element: <AdminEditStyle />, //enter from teacherpreparing form
      },
      { path: '/teacher/resources', element: <AdminEditResource /> },
      { path: '/teacher/resources/:resourceId', element: <AdminResourceContent /> },
      {
        path: '/teacher/resources/:resourceId/folder/:parentFolderId',
        element: <AdminResourceContent />,
      },
      { path: '/teacher/editResource/:resourceId', element: <AdminResourceContent /> },
      {
        path: '/teacher/editResource/:resourceId/folder/:parentFolderId',
        element: <AdminResourceContent />,
      },

      { path: '/teacher/classes', element: <TeacherClasses /> },
      { path: '/teacher/students', element: <TeacherStudents /> },
      {
        path: '/teacher/students/:classId',
        element: <TeacherStudents />,
      },
      { path: '/teacher/homework', element: <TeacherHomework /> },
      { path: '/teacher/homework/:classId', element: <TeacherHomework /> },
      { path: '/teacher/homeworkDetail/:homeworkId', element: <TeacherHomeworkDetail /> },
      {
        path: '/teacher/testScores',
        element: <TeacherTestScores />,
      },
      {
        path: '/teacher/testScores/:classId',
        element: <TeacherTestScores />,
      },
      {
        path: '/teacher/testScores/class/:classId/level/:currentLevel',
        element: <TeacherTestScores />,
      },
      {
        path: '/teacher/checkGrades',
        element: <TeacherCheckGrades />,
      },
      {
        path: '/teacher/checkGrades/class/:classId',
        element: <TeacherCheckGrades />,
      },
      {
        path: '/teacher/checkGrades/class/:classId/student/:studentId',
        element: <TeacherCheckGrades />,
      },
      { path: '/teacher/daily', element: <AdminEditDailies /> },
      {
        path: '/teacher/daily/classroom/:classroomId',
        element: <AdminClassroomDaily />,
      },
      { path: '/teacher/daily/roster/:classroomId', element: <AdminRosterDaily /> },
      {
        path: '/teacher/daily/makeUpClass/:schoolId',
        element: <AdminMakeUpClassDaily />,
      },
      {
        path: '/teacher/daily/teach/:classroomId',
        element: <AdminTeachDaily />,
      },
      {
        path: '/teacher/account',
        element: <AccountPage />,
      },
    ],
  };

  const studentRoutes = {
    element: <ProtectedRoutes canAccessRoles={['student', 'super']} user={user} />,
    children: [
      { path: '/student', element: <StudentHomework /> },
      { path: '/student/learn', element: <AdminEditLevel type={LevelType.Learn} /> },
      {
        path: '/student/learn/level/:levelId/book/:bookId',
        element: <AdminEditLesson type={LevelType.Learn} />,
      },
      {
        path: '/student/learn/level/:levelId/book/:bookId/lesson/:lessonId/part/:partId/style/:styleId',
        element: <AdminEditStyle />,
      },
      {
        path: '/student/homework/:homeworkId/style/:styleId',
        element: <AdminEditStyle />,
      },
      {
        path: '/student/account',
        element: <AccountPage />,
      },
    ],
  };

  const videoRoutes = {
    element: (
      <ProtectedRoutes
        canAccessRoles={[
          'super',
          'admin',
          'secretary',
          'principal',
          'teacher',
          'student',
        ]}
        user={user}
      />
    ),
    children: [
      {
        path: '/video/:videoId',
        element: <SingleVideoPage />,
      },
    ],
  };

  const audioRoutes = {
    element: (
      <ProtectedRoutes
        canAccessRoles={[
          'super',
          'admin',
          'secretary',
          'principal',
          'teacher',
          'student',
        ]}
        user={user}
      />
    ),
    children: [
      {
        path: '/audio/:audioId',
        element: <SingleAudioPage />,
      },
    ],
  };

  const mediaRoutes = {
    element: (
      <ProtectedRoutes
        canAccessRoles={[
          'super',
          'admin',
          'secretary',
          'principal',
          'teacher',
          'student',
        ]}
        user={user}
      />
    ),
    children: [
      {
        path: '/media/:media/:resourceId',
        element: <MediaPage />,
      },
      {
        path: '/media/:media/:resourceId/:parentFolderId',
        element: <MediaPage />,
      },
    ],
  };

  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        { index: true, element: <Login /> },
        { path: '/', element: <Login /> },
        { path: '/signup', element: <Register /> },
        superRoutes,
        adminRoutes,
        principalRoutes,
        secretaryRoutes,
        teacherRoutes,
        studentRoutes,
        videoRoutes,
        audioRoutes,
        mediaRoutes,
        {
          path: '*',
          element: (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <NoResultFoundSvg />
            </div>
          ),
        },
      ],
    },
  ]);

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <Suspense>
          <RouterProvider router={router} />
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export default App;
