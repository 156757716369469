import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum AdminViewMode {
  EDIT = 'edit',
  VIEW = 'view',
}

type InitialState = {
  currentMode: AdminViewMode;
};

const initialState: InitialState = {
  currentMode: AdminViewMode.EDIT,
};

export const adminViewModeSlice = createSlice({
  name: 'adminViewMode',
  initialState,
  reducers: {
    updateAdminViewMode: (state, action: PayloadAction<AdminViewMode>) => {
      state.currentMode = action.payload;
    },
    initialAdminViewMode: () => {
      return { ...initialState };
    },
  },
});

export const { updateAdminViewMode, initialAdminViewMode } = adminViewModeSlice.actions;

export default adminViewModeSlice.reducer;
