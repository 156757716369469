import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import { useLanguage, useScripts } from './LanguageHelper';
import { ThemeMode } from '../components/MainMenu';

type ThemeModeForTabletOrMobileSvgProps = {
  width: number;
  currentThemeMode: ThemeMode;
};

const ThemeModeForTabletOrMobileSvg = ({
  width,
  currentThemeMode,
}: ThemeModeForTabletOrMobileSvgProps) => {
  const scripts = useScripts();

  const webMode = localStorage.getItem('theme');
  const { language } = useLanguage();

  return (
    <Stack
      position="relative"
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0.5}
      height="45px"
    >
      {webMode === 'Auto' && (
        <>
          <SettingsBrightnessIcon sx={{ fontSize: `${width}px`, color: '#090446' }} />
          <Typography variant="inherit" color="#090446" fontWeight="600">
            {scripts.auto}
          </Typography>
        </>
      )}
      {webMode === 'Dark' && (
        <>
          <DarkModeIcon sx={{ fontSize: `${width}px`, color: '#090446' }} />
          <Typography
            fontSize={language === 'TW' ? '0.8em' : '0.67em'}
            variant="inherit"
            color="#090446"
            fontWeight="600"
          >
            {scripts.darkMode}
          </Typography>
        </>
      )}
      {webMode === 'Light' && (
        <>
          <LightModeIcon sx={{ fontSize: `${width}px`, color: '#090446' }} />
          <Typography
            fontSize={language === 'TW' ? '0.8em' : '0.67em'}
            variant="inherit"
            color="#090446"
            fontWeight="600"
          >
            {scripts.lightMode}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default ThemeModeForTabletOrMobileSvg;
