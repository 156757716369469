import { useEffect, useLayoutEffect } from 'react';

import MicIcon from '@mui/icons-material/Mic';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { cloneDeep, defaultsDeep } from 'lodash-es';
import converter from 'number-to-words';
import { useParams } from 'react-router-dom';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import { Draggable } from '../../../../redux/features/styleSlice';
import { useAppSelector } from '../../../../redux/store';
import { getDefaultFontSizeNum } from '../../context/FontEditorContext';
import { useStyleAnswer } from '../../context/StyleAnswerContext';
import { useStyle } from '../../context/StyleContext';
import { getDraggables } from '../../utils/draggables';

export const voiceToTextChild: Array<Draggable> = [
  {
    id: '',
    type: DraggableType.RecordAnswer,
    tags: [DraggableTag.Audio],
    name: '',
    style: { width: '83%', height: '50%', left: '9%', top: '2.5%' },
    lockAspectRatio: true,
  },
  {
    id: '',
    type: DraggableType.TextRectAnswer2,
    tags: [DraggableTag.TextField],
    name: 'Answer',
    style: {
      width: '90%',
      height: '40%',
      left: '5%',
      top: '55.5%',
      color: '#000000',
      textAlign: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
      fontFamily: 'Roboto',
      backgroundColor: '#d9d9d9',
    },
    lockAspectRatio: false,
    answer: {
      correct: 'Answer',
    },
  },
];

const landscapeProps: DraggableInitProps = {
  type: DraggableType.VoiceToTextAnswer,
  tags: [DraggableTag.Group],
  style: {
    width: '9.97402%',
    height: '29.55266%',
  },
  lockAspectRatio: false,
  group: [
    {
      ...voiceToTextChild[0],
      style: {
        ...voiceToTextChild[0].style,
        width: '8.27844%',
        height: '14.77633%',
        left: '0.89766%',
        top: '0.73881%',
      },
    },
    {
      ...voiceToTextChild[1],
      style: {
        ...voiceToTextChild[1].style,
        width: '8.97662%',
        height: '11.82106%',
        left: '0.4987%',
        top: '16.40173%',
      },
    },
  ],
};

export const defaultVoiceToTextAnswerProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '26.667813%',
      height: '25.001411%',
    },
    group: [
      {
        ...voiceToTextChild[0],
        style: {
          ...voiceToTextChild[0].style,
          width: '22.134285%',
          height: '12.500705%',
          left: '2.400103%',
          top: '0.625035%',
        },
      },
      {
        ...voiceToTextChild[1],
        style: {
          ...voiceToTextChild[1].style,
          width: '24.001032%',
          height: '10.000564%',
          left: '1.33339%',
          top: '13.875783%',
        },
      },
    ],
  },
};

export default function VoiceToTextAnswer(props: DraggableOuterProps) {
  const {
    layout,
    draggablesPropsRef,
    defineCompletelyDeleteDraggable,
    setSelectedEl,
    setIsUploadingAnswer,
  } = useStyle();
  const adminViewMode = useAppSelector((state) => state.adminViewMode.currentMode);

  const { setRestoreRecordingCounter } = useStyle();
  const { setStyleAnswers } = useStyleAnswer();

  const { styleId = '' } = useParams();

  const defaultProps = layout
    ? defaultVoiceToTextAnswerProps[layout]
    : defaultVoiceToTextAnswerProps.landscape;

  const defaultVoiceToTextAnswer: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '12vmin',
      height: '20vmin',
    },
    group: props.isClone ? undefined : voiceToTextChild,
    originWrapperStyle: {
      width: '12vmin',
      height: 'auto',
      backgroundColor: '#f3f3f3',
      borderRadius: '6px',
      p: 1,
      px: 2,
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultVoiceToTextAnswer,
  );

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep(props);
      defineCompletelyDeleteDraggable(id);
    }
  }, [dragProps]);

  useEffect(() => {
    const id = props.id ?? '';
    const childId = draggablesPropsRef?.current[id]?.group?.find(
      (draggable) => draggable.type === DraggableType.RecordAnswer,
    )?.id;
    const draggablesProps = draggablesPropsRef?.current[id];
    if (!id || !childId || !draggablesProps) {
      return;
    }

    const selectedBar =
      draggablesProps?.selectedBar ?? (draggablesProps.selectedBar = {});

    const recordAgain = draggablesPropsRef?.current?.[childId]?.selectedBar?.recordAgain;
    if (recordAgain) {
      selectedBar.recordAgain = recordAgain;
    }

    const submitRecording =
      draggablesPropsRef?.current?.[childId]?.selectedBar?.submitRecording;
    if (submitRecording) {
      const numberConv = (w: string) => {
        return w
          .split(' ')
          .map((e) => {
            try {
              //number method
              let wordNumber = converter.toWords(e.replace(/,/gi, ''));
              wordNumber = wordNumber.replace(/,/gi, '');
              const hundred = parseInt(e, 10) % 100;
              const thousand = parseInt(e, 10) % 1000;
              if (hundred) {
                wordNumber = wordNumber.replace('hundred', 'hundred and');
                if (thousand) {
                  wordNumber = wordNumber.replace('thousand', 'thousand and');
                }
              }
              return wordNumber;
            } catch {
              return e;
            }
          })
          .join(' ');
      };

      selectedBar.submitRecording = () => {
        setIsUploadingAnswer(true);

        const recordingInfo = submitRecording();
        if (recordingInfo) {
          recordingInfo
            .then((recording) => {
              if (recording) {
                const { answer: originAnswer, audioBlobUrl } = recording;
                const answer = numberConv(originAnswer);

                fetch(audioBlobUrl)
                  .then((response) => {
                    response
                      .arrayBuffer()
                      .then((response) => {
                        const audioBlob = new Blob([response], { type: 'audio/wav' });
                        const audioFile = new File(
                          [audioBlob],
                          `${new Date().getTime()}`,
                          {
                            type: 'audio/wav',
                          },
                        );

                        setStyleAnswers((prev) => {
                          const currentUserAnswer = cloneDeep(prev);
                          let index = currentUserAnswer.findIndex(
                            (ele) => ele.styleId === styleId,
                          );
                          if (index < 0) {
                            index = currentUserAnswer.length;
                          }

                          const userAnswer = {
                            draggableId: props.id ?? '',
                            answer,
                            audio: audioFile,
                            type: 'Audio',
                          };

                          if (currentUserAnswer?.[index]) {
                            currentUserAnswer[index].userAnswers.push(userAnswer);
                          } else {
                            currentUserAnswer[index] = {
                              styleId,
                              userAnswers: [userAnswer],
                              isDone: false, //not sure
                            };
                          }

                          setSelectedEl(null);
                          setIsUploadingAnswer(false);

                          return currentUserAnswer;
                        });
                      })
                      .catch(() => {
                        setRestoreRecordingCounter((prev) => prev + 1);
                      });
                  })
                  .catch(() => {
                    setRestoreRecordingCounter((prev) => prev + 1);
                  });
              }
            })
            .catch(() => {
              setRestoreRecordingCounter((prev) => prev + 1);
            });
        }
      };
    }
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps}>
      {dragProps.isClone && dragProps.group ? (
        getDraggables(
          (() => {
            if (adminViewMode === 'view') {
              const index =
                dragProps.group?.findIndex(
                  (draggable) => draggable?.tags?.indexOf(DraggableTag.TextField) > -1,
                ) ?? -1;

              if (index < 0) {
                return dragProps.group;
              } else {
                const groupCopy = cloneDeep(dragProps.group);
                groupCopy[index].style = {
                  ...groupCopy[index]?.style,
                  display: 'none',
                };

                return groupCopy;
              }
            }

            return dragProps.group;
          })(),
        )
      ) : (
        <>
          <CenterBox
            sx={[
              {
                width: '100%',
                height: '100%',
                backgroundColor: '#d9d9d9',
                borderRadius: '50%',
              },
            ]}
          >
            <MicIcon sx={{ width: '100%', height: '100%', color: '#9b9b9b' }} />
          </CenterBox>
          <CenterBox
            sx={{
              // width: '9vmin',
              // height: '5vmin',
              mt: 1.5,
              textAlign: 'center',
            }}
          >
            <Typography component={Box} bgcolor="#d9d9d9" sx={{ fontSize: '1.2vw' }}>
              Voice To Text
            </Typography>
          </CenterBox>
        </>
      )}
    </WrapperRND>
  );
}
