import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

export interface ImageBasicProps {
  src: string;
  width?: string;
  height?: string;
  aspectRatio?: string;
  sx?: SxProps;
  imgSx?: SxProps;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  children?: React.ReactNode;
}

export default function ImageBasic({
  src,
  width = '100%',
  height = '100%',
  aspectRatio = '1 / 1',
  sx,
  imgSx,
  onClick,
  children,
}: ImageBasicProps) {
  return (
    <Box
      sx={{
        width,
        height,
        aspectRatio,
        position: 'relative',
        ...sx,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          ...imgSx,
        }}
        component="img"
        src={src}
        draggable={false}
      />
      {children}
    </Box>
  );
}
