import { ApolloClient, InMemoryCache, ApolloLink, makeVar } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { relayStylePagination } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';

import { SubjectPage, SubjectPageInfo } from '../globalHooks/useSubjectPage';
import { PageInfo } from '../graphql/resolver.types';
import { getConfig } from '../utils/configHelper';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message && message.includes('Authentication is needed to get response')) {
        localStorage.removeItem('token');
      }

      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);

    // user offline
    if (typeof window !== 'undefined' && !window.navigator.onLine) {
      alert('Oh! You have no internet connection.');
    }
  }
});

// NOTE: please do not change the uri here
const apiWithUpload = createUploadLink({
  credentials: 'include',
  uri: `${getConfig('musenserver')}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from localStorage
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 300, // wait 1 second before retrying
    jitter: true,
  },
  attempts: {
    max: 5,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    retryIf: (error, _operation) => !!error,
  },
});

export const pageInfoVar = makeVar<{ [key: string]: PageInfo | null }>({});
export const subjectPageInfoVar = makeVar<
  Partial<Record<SubjectPage, SubjectPageInfo | null>>
>({});
const booksVar = makeVar<
  {
    bookId: string;
    userIds: Set<string>;
    schoolIds: Set<string>;
    classroomIds: Set<string>;
  }[]
>([]);

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, retryLink, authLink, apiWithUpload]),
  name: 'reader-web-client',
  cache: new InMemoryCache({
    typePolicies: {
      Level: {
        fields: {
          books: {
            merge(existing: any[] = [], incoming: any[], { readField, variables }) {
              if (
                new Set([
                  ...existing.map((book) => book.__ref),
                  ...incoming.map((book) => book.__ref),
                ]).size === existing.length &&
                existing.length === incoming.length
              ) {
                return incoming;
              }

              const newBooks = [
                ...existing.filter((book) => !readField('isTrash', book)),
              ];

              const userId = variables?.userId;
              const schoolId = variables?.schoolId;
              const classroomId = variables?.classroomId;
              for (const book of incoming) {
                const booksForCheck = booksVar();
                const bookId = book.__ref.slice(5);
                if (
                  newBooks.findIndex(
                    (existingBook) => existingBook.__ref === book.__ref,
                  ) === -1
                ) {
                  newBooks.push(book);
                }
                const bookForCheck = booksForCheck.find((book) => book.bookId === bookId);
                if (!bookForCheck) {
                  booksVar([
                    ...booksForCheck,
                    {
                      bookId,
                      userIds: userId ? new Set([userId]) : new Set(),
                      schoolIds: schoolId ? new Set([schoolId]) : new Set(),
                      classroomIds: classroomId ? new Set([classroomId]) : new Set(),
                    },
                  ]);
                } else {
                  if (userId) {
                    bookForCheck.userIds.add(userId);
                  }
                  if (schoolId) {
                    bookForCheck.schoolIds.add(schoolId);
                  }
                  if (classroomId) {
                    bookForCheck.classroomIds.add(classroomId);
                  }
                }
              }

              return newBooks;
            },
            read(books: any[] = [], { variables }) {
              const booksForCheck = booksVar();
              const userId = variables?.userId;
              const schoolId = variables?.schoolId;
              const classroomId = variables?.classroomId;

              const newBooks = books.filter((book) => {
                const bookForCheck = booksForCheck.find(
                  (bookForCheck) => `Book:${bookForCheck.bookId}` === book.__ref,
                );

                return (
                  bookForCheck &&
                  (!userId || bookForCheck.userIds.has(userId)) &&
                  (!schoolId || bookForCheck.schoolIds.has(schoolId)) &&
                  (!classroomId || bookForCheck.classroomIds.has(classroomId))
                );
              });

              return newBooks;
            },
          },
        },
      },
      Query: {
        fields: {
          levels: relayStylePagination([
            'type',
            'search',
            'userId',
            'schoolId',
            'classroomId',
            'isAccessControl',
          ]),
          lessons: relayStylePagination(['bookId', 'search']),
          styles: relayStylePagination(['partId', 'folderId', 'layout']),
          resources: relayStylePagination(['search']),
          classrooms: relayStylePagination([
            'search',
            'schoolId',
            'withSameProductsClassroomId',
            'withoutSameProductsStudentId',
          ]),
          users: relayStylePagination([
            'search',
            'roles',
            'schoolId',
            'classroomId',
            'withoutSameProductsClassroomId',
          ]),
          schools: relayStylePagination([
            'search',
            'status',
            'productId',
            'levelId',
            'bookId',
            'resourceId',
          ]),
          classroomSchedules: relayStylePagination(['isScheduled']),
          schedulesInDay: relayStylePagination(['classroomId']),
          folder: relayStylePagination(['folderId']),
          folders: relayStylePagination([
            'type',
            'search',
            'resourceId',
            'parentFolderId',
          ]),
          skills: relayStylePagination(['search', 'partIds']),
          images: relayStylePagination(['search', 'folderId', 'isDeleted']),
          audios: relayStylePagination(['search', 'folderId', 'resourceId', 'isDeleted']),
          videos: relayStylePagination(['search', 'folderId', 'resourceId', 'isDeleted']),
          homeworks: relayStylePagination(['studentId', 'classroomId', 'status']),
          studentGrade: relayStylePagination(['lessonId', 'userId']),
          leads: relayStylePagination([
            'schoolId',
            'status',
            'sourceId',
            'eventId',
            'notCalled',
            'noEventsAttended',
          ]),
          calls: relayStylePagination(['leadId']),
          events: relayStylePagination(['schoolId']),
          sources: relayStylePagination(['schoolId']),
          classroomGrade: relayStylePagination(['levelId', 'classroomId']),
          daily: relayStylePagination(['classroomId', 'date', 'userId']),
          makeUpClasses: relayStylePagination(['schoolId', 'order']),
          // rosters: relayStylePagination(['classroomId']),
        },
      },
      Code: {
        fields: {
          expiresAt: {
            read(expiresAt: string) {
              if (typeof expiresAt === 'string' && expiresAt.length >= 16) {
                return expiresAt.slice(0, -3);
              }

              return expiresAt;
            },
          },
        },
      },
      SchedulesInDay: {
        fields: {
          dueDate: {
            read(dueDate: string) {
              if (typeof dueDate === 'string' && dueDate.length >= 10) {
                return dueDate.slice(0, 10);
              }

              return dueDate;
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'none',
    },
    watchQuery: {
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'none',
    },
    mutate: {
      errorPolicy: 'none',
    },
  },
});
