import { SvgProps } from './MenuItem';

const MoreSvg = ({ width, height }: SvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 18V18.154" stroke="#090446" strokeWidth="3" strokeLinecap="round" />
      <path d="M17 10V10.154" stroke="#090446" strokeWidth="3" strokeLinecap="round" />
      <path d="M17 2V2.15398" stroke="#090446" strokeWidth="3" strokeLinecap="round" />
      <path
        d="M9.684 26.814V35.154H8.316V29.442L5.772 35.154H4.824L2.268 29.442V35.154H0.9V26.814H2.376L5.304 33.354L8.22 26.814H9.684ZM15.286 35.238C14.51 35.238 13.794 35.058 13.138 34.698C12.49 34.33 11.974 33.822 11.59 33.174C11.214 32.518 11.026 31.782 11.026 30.966C11.026 30.15 11.214 29.418 11.59 28.77C11.974 28.122 12.49 27.618 13.138 27.258C13.794 26.89 14.51 26.706 15.286 26.706C16.07 26.706 16.786 26.89 17.434 27.258C18.09 27.618 18.606 28.122 18.982 28.77C19.358 29.418 19.546 30.15 19.546 30.966C19.546 31.782 19.358 32.518 18.982 33.174C18.606 33.822 18.09 34.33 17.434 34.698C16.786 35.058 16.07 35.238 15.286 35.238ZM15.286 34.05C15.838 34.05 16.33 33.926 16.762 33.678C17.194 33.422 17.53 33.062 17.77 32.598C18.018 32.126 18.142 31.582 18.142 30.966C18.142 30.35 18.018 29.81 17.77 29.346C17.53 28.882 17.194 28.526 16.762 28.278C16.33 28.03 15.838 27.906 15.286 27.906C14.734 27.906 14.242 28.03 13.81 28.278C13.378 28.526 13.038 28.882 12.79 29.346C12.55 29.81 12.43 30.35 12.43 30.966C12.43 31.582 12.55 32.126 12.79 32.598C13.038 33.062 13.378 33.422 13.81 33.678C14.242 33.926 14.734 34.05 15.286 34.05ZM25.2242 35.154L23.3042 31.818H22.2602V35.154H20.8922V26.814H23.7722C24.4122 26.814 24.9522 26.926 25.3922 27.15C25.8402 27.374 26.1722 27.674 26.3882 28.05C26.6122 28.426 26.7242 28.846 26.7242 29.31C26.7242 29.854 26.5642 30.35 26.2442 30.798C25.9322 31.238 25.4482 31.538 24.7922 31.698L26.8562 35.154H25.2242ZM22.2602 30.726H23.7722C24.2842 30.726 24.6682 30.598 24.9242 30.342C25.1882 30.086 25.3202 29.742 25.3202 29.31C25.3202 28.878 25.1922 28.542 24.9362 28.302C24.6802 28.054 24.2922 27.93 23.7722 27.93H22.2602V30.726ZM29.8422 27.918V30.366H32.7222V31.482H29.8422V34.038H33.0822V35.154H28.4742V26.802H33.0822V27.918H29.8422Z"
        fill="#090446"
      />
    </svg>
  );
};

export default MoreSvg;
