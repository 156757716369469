import CorrectCheckBox from './CorrectCheckBox';
import {
  DraggableType,
  DraggableTag,
  DraggableAnswer,
} from '../../../../../graphql/resolver.types';
import { useAppSelector } from '../../../../../redux/store';

interface ButtonBarProps {
  id?: string;
  type: DraggableType;
  tags: Array<DraggableTag>;
  lockAspectRatio?: boolean;
  isSelected?: boolean;
  answer?: DraggableAnswer;
  index?: number;
}

export default function ButtonBar({ id, answer, index, type }: ButtonBarProps) {
  const correctIsGroupIndex = answer?.correctIsGroupIndex;
  const adminViewMode = useAppSelector((state) => state.adminViewMode.currentMode);
  const displayIndex = typeof index === 'number' ? index + 1 : undefined;
  // main
  return (
    <>
      {/* Index */}
      {correctIsGroupIndex && displayIndex && adminViewMode === 'edit' && (
        <div
          style={{
            position: 'absolute',
            left: '0px',
            top: '-30px',
            color: '#008000',
            fontWeight: 'bold',
          }}
        >
          {displayIndex}
        </div>
      )}
      {/* Correct CheckBox */}
      {<CorrectCheckBox id={id} answer={answer} type={type} />}
    </>
  );
}
