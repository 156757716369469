//ref: AssignHomeworkButton;

import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { default as Grid } from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';

import CustomAddOrEditDialog from '../../../globalComponents/CustomAddOrEditDialog';
import CustomDatePicker from '../../../globalComponents/CustomDatePicker';
import CustomText from '../../../globalComponents/CustomText';
import CustomTextField from '../../../globalComponents/CustomTextField';
import OperationResultDialog from '../../../globalComponents/OperationResultDialog';
import { useOperationResultContext } from '../../../globalContexts/OperationResultContext';
import useDialog from '../../../globalHooks/useDialog';
import {
  useAssignHomeworkMutation,
  HomeworkType,
  AssignTo,
  useNumberOfStylesLazyQuery,
  HomeworksQuery,
  HomeworksDocument,
  HomeworksQueryVariables,
} from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';

type AssignSkillPracticeHomeworkButtonProps = {
  skill?: string;
  subSkill?: string;
  //buttonId:string;
  subSkillIds: string[];
  classroomId: string;
  studentIds: string[];
  schoolId: string;
};

const checkIsNumber = (input: string) => {
  return !/\D/.test(input);
};
const ThreeDaysFromNow = dayjs(new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000));

const ThreeDaysDue = `${ThreeDaysFromNow.year()}-${`0${
  ThreeDaysFromNow.month() + 1
}`.slice(-2)}-${`0${ThreeDaysFromNow.date()}`.slice(-2)}`;

const AssignSkillPracticeHomeworkButton = ({
  skill,
  subSkill,
  subSkillIds,
  classroomId,
  studentIds,
  schoolId,
}: AssignSkillPracticeHomeworkButtonProps): JSX.Element => {
  const scripts = useScripts();

  const [openBlock, setOpenBlock] = useState(false);

  const [questionLimit, setQuestionLimit] = useState(
    //numberOfStylesData?.numberOfStyles?.toString() ||
    '30',
  );

  const [
    fetchNumberOfStyles,
    { data: numberOfStylesData, loading: numberOfStylesLoading },
  ] = useNumberOfStylesLazyQuery();

  useEffect(() => {
    if (openBlock) {
      fetchNumberOfStyles({
        variables: {
          classroomId: classroomId,
          subskillIds: subSkillIds,
        },
      });
    }
  }, [openBlock]);

  useEffect(() => {
    if (numberOfStylesData) {
      setQuestionLimit(numberOfStylesData?.numberOfStyles?.toString());
    }
  }, [numberOfStylesData]);

  const [timeLimitHour, setTimeLimitHour] = useState('0');
  const [timeLimitMin, setTimeLimitMin] = useState('00');
  const [timeLimit, setTimeLimit] = useState(0); // unit: sec
  const [dueDate, setDueDate] = useState(ThreeDaysDue);
  const startDate = new Date().toDateString();
  const isEarlierThanToday = new Date(dueDate).getTime() - new Date().getTime() < 0;

  const { operationLoading, operationSuccess, commonErrorMessages } =
    useOperationResultContext();

  const [
    assignHomeworkMutation,
    {
      data: assignHomeworkData,
      error: assignHomeworkError,
      loading: assignHomeworkLoading,
    },
  ] = useAssignHomeworkMutation({
    update(cache, { data }) {
      const homeworksQuery = cache.readQuery<HomeworksQuery, HomeworksQueryVariables>({
        query: HomeworksDocument,
        variables: {
          first: 12,
          classroomId: classroomId,
          studentId: studentIds[0],
        },
      });
      const assignHomework = data?.assignHomework;

      if (!homeworksQuery || !assignHomework) {
        return;
      }

      let homeworksData = { ...homeworksQuery };

      const newHomeworks = [...homeworksData.homeworks.edges];

      newHomeworks.unshift({
        __typename: 'HomeworkEdge',
        cursor: '',
        node: assignHomework,
      });

      homeworksData = {
        homeworks: {
          edges: newHomeworks,
          pageInfo: {
            ...homeworksData.homeworks.pageInfo,
            itemTotal: homeworksData.homeworks.pageInfo.itemTotal + 1,
            startCursor: '',
          },
        },
      };

      cache.writeQuery<HomeworksQuery, HomeworksQueryVariables>({
        query: HomeworksDocument,
        variables: {
          first: 12,
          classroomId: classroomId,
          studentId: studentIds[0],
        },
        data: homeworksData,
      });
      // no choose class & student cache
      cache.writeQuery<HomeworksQuery, HomeworksQueryVariables>({
        query: HomeworksDocument,
        variables: {
          first: 12,
          classroomId: '',
          studentId: '',
        },
        data: homeworksData,
      });
    },
    onCompleted() {
      operationSuccess();
    },
    onError(error) {
      commonErrorMessages(error);
    },
  });

  useEffect(() => {
    if (timeLimitHour && timeLimitMin) {
      setTimeLimit(parseInt(timeLimitHour) * 3600 + parseInt(timeLimitMin) * 60);
    }
  }, [timeLimitHour, timeLimitMin]);

  const { dialogProps } = useDialog({
    dialogTitle: (
      <Typography
        textAlign="center"
        variant="h5"
        sx={{ fontWeight: 500, fontFamily: 'Poppins', color: '#073015' }}
      >
        {scripts.assignHomework}
      </Typography>
    ),
    onClick: () => {
      setOpenBlock(true);
    },
    onClose: () => {
      setOpenBlock(false);
    },
    getCustomButton: (onClick) => (
      <Button
        variant="contained"
        sx={{
          width: '100%',
          backgroundColor: '#D9D9D9',
          color: '#073015',
          display: 'inline',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#848484',
            color: '#FFF',
          },
          borderRadius: '0 0 10px 10px',
        }}
        onClick={onClick}
      >
        <Typography variant="body2" sx={{ fontWeight: 500, fontFamily: 'Poppins' }}>
          {scripts.assignMore}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 900, fontFamily: 'Poppins' }}>
          {subSkill}
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {scripts.questions}
        </Typography>
      </Button>
    ),
    inputCheckResult: () => {
      return !!(
        checkIsNumber(questionLimit) &&
        numberOfStylesData &&
        parseInt(questionLimit) <= numberOfStylesData?.numberOfStyles &&
        checkIsNumber(timeLimitHour) &&
        checkIsNumber(timeLimitMin) &&
        !isEarlierThanToday &&
        parseInt(questionLimit) !== 0
      );
    },
    onSubmit: () => {
      assignHomeworkMutation({
        variables: {
          type: HomeworkType.SkillsPractice,
          assignTo: AssignTo.Student,
          assignedTarget: {
            classroomId: classroomId,
            schoolId: schoolId,
            studentIds: studentIds,
          },
          questionDetail: {
            numberOfQuestions: parseInt(questionLimit),
            subskillIds: subSkillIds,
          },
          dueDate: dueDate,
          timeLimit,
        },
      });
      operationLoading();
    },
  });

  return (
    <>
      <CustomAddOrEditDialog isAdd={true} {...dialogProps} maxWidth="xs">
        <Grid xs={12}>
          <Stack spacing={1.2}>
            <Typography variant="h6" sx={{ fontWeight: 400, fontFamily: 'Poppins' }}>
              {scripts.numberOfQuestion}
            </Typography>
            <CustomTextField
              required
              fullWidth
              value={questionLimit}
              setValue={setQuestionLimit}
              error={
                !checkIsNumber(questionLimit) ||
                parseInt(questionLimit) === 0 ||
                (numberOfStylesData &&
                  parseInt(questionLimit) > numberOfStylesData?.numberOfStyles)
              }
              helperText={
                !checkIsNumber(questionLimit) || questionLimit === ''
                  ? scripts.pleaseEnterAValidNumber
                  : parseInt(questionLimit) === 0
                  ? scripts.pleaseEnterABiggerNumber
                  : numberOfStylesData &&
                    parseInt(questionLimit) > numberOfStylesData?.numberOfStyles
                  ? scripts.pleaseEnterASmallerNumber
                  : ''
              }
              disabled={numberOfStylesLoading}
              dialogProps={dialogProps}
            />
            <Typography
              variant="caption"
              component="div"
              color="rgba(0, 0, 0, 0.3)"
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 400,
                fontFamily: 'Martel Sans',
              }}
            >
              {scripts.weakSkill}: {skill} -
              <Typography sx={{ fontWeight: 900, fontSize: '12px' }}>
                &nbsp;{subSkill}
              </Typography>
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 400, fontFamily: 'Poppins' }}>
              {scripts.dueDate}
            </Typography>
            <CustomDatePicker
              required
              value={dueDate}
              setValue={setDueDate}
              fullWidth
              error={isEarlierThanToday} // check error
              helperText={
                isEarlierThanToday
                  ? scripts.cannotBeEarlierThanStartDate
                  : dueDate
                  ? scripts.timeIsSetToTheDateAtAlmost24
                  : undefined
              }
              dialogProps={dialogProps}
              startDate={startDate}
              helperTextStyle={{
                fontWeight: 400,
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.3)',
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 400, fontFamily: 'Poppins' }}>
              {scripts.timeLimit}?
            </Typography>
            <Typography
              variant="caption"
              component="div"
              color="rgba(0, 0, 0, 0.3)"
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 400,
                fontFamily: 'Martel Sans',
              }}
            >
              {scripts.studentHomeWorkLimitTimeWarning}
              <br />
              {scripts.noTimeLimit}
            </Typography>
            <Stack
              spacing={4}
              direction={'row'}
              sx={{
                alignItems: 'center',
              }}
            >
              <CustomTextField
                required
                value={timeLimitHour}
                setValue={setTimeLimitHour}
                error={!checkIsNumber(timeLimitHour)} //TODO: add max number?
                helperText={
                  !checkIsNumber(timeLimitHour) ? scripts.pleaseEnterAValidNumber : ''
                }
                dialogProps={dialogProps}
              />
              <CustomText
                sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#073015',
                }}
              >
                {scripts.hour}
              </CustomText>
              <CustomTextField
                required
                value={timeLimitMin}
                setValue={setTimeLimitMin}
                error={!checkIsNumber(timeLimitMin)} //TODO: add max number?
                helperText={
                  !checkIsNumber(timeLimitMin) ? scripts.pleaseEnterAValidNumber : ''
                }
                dialogProps={dialogProps}
              />
              <CustomText
                sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#073015',
                }}
              >
                {scripts.min}
              </CustomText>
            </Stack>
          </Stack>
        </Grid>
      </CustomAddOrEditDialog>
      {dialogProps.isSubmit && (
        <OperationResultDialog
          data={assignHomeworkData}
          error={assignHomeworkError}
          loading={assignHomeworkLoading}
        />
      )}
    </>
  );
};

export default AssignSkillPracticeHomeworkButton;
