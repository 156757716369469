import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AxiosError } from 'axios';

import OperationResultMessage, {
  UpdateOperationResult,
} from '../globalComponents/OperationResultMessage';
import { useScripts } from '../layout/utils/LanguageHelper';

type CommonErrorMessages = {
  commonErrorMessages: (
    error: ApolloError | AxiosError,
    objInfo?: {
      name?: string | null;
      filename?: string | null;
      urlname?: string | null;
    } | null,
  ) => void;
};
type OperationResultContextProps = UpdateOperationResult & CommonErrorMessages;
const defaultValue: OperationResultContextProps = {
  setSnackbarState: () => undefined,
  operationLoading: () => undefined,
  operationSuccess: () => undefined,
  operationError: () => undefined,
  operationLoaded: () => undefined,
  commonErrorMessages: () => undefined,
};

const OperationResultContext = createContext<OperationResultContextProps>(defaultValue);

export const OperationResultProvider = ({ children }: any) => {
  const operationResultRef = useRef<UpdateOperationResult>(null);
  const operationTimer = useRef<NodeJS.Timeout>();
  const [setSnackbarState, setSetSnackbarState] = useState<
    | ((style: {
        vertical?: 'top' | 'bottom' | undefined;
        horizontal?: 'center' | 'left' | 'right' | undefined;
      }) => void)
    | null
  >(null);
  const [operationLoading, setOperationLoading] = useState<
    UpdateOperationResult['operationLoaded'] | null
  >(null);
  const [operationSuccess, setOperationSuccess] = useState<(() => void) | null>(null);
  const [operationError, setOperationError] = useState<
    ((msg?: string | JSX.Element, error?: ApolloError | AxiosError) => void) | null
  >(null);
  const operationErrorRef = useRef(operationError);
  operationErrorRef.current = operationError;
  const [operationLoaded, setOperationLoaded] = useState<(() => void) | null>(null);

  const isApolloError = (error: ApolloError | AxiosError): error is ApolloError => {
    return !!(error as ApolloError)?.graphQLErrors;
  };
  const scripts = useScripts();

  const commonErrorMessages = (
    error: ApolloError | AxiosError,
    objInfo?: {
      name?: string | null;
      filename?: string | null;
      urlname?: string | null;
    } | null,
  ) => {
    const operationError = operationErrorRef.current;
    if (!operationError) {
      return;
    }

    if (!isApolloError(error)) {
      operationError('', error);
    } else {
      const code = error.graphQLErrors?.[0]?.extensions?.code as string | undefined;
      const message = error.message;

      const name = objInfo?.name ?? objInfo?.filename ?? objInfo?.urlname ?? 'object';

      if (message === 'You are not authorized as admin or super.') {
        operationError(
          'Sorry, this account is not authorized to perform this operation.',
          error,
        );
      } else if (message === 'Failed to fetch') {
        operationError(
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
          >
            <Typography variant="inherit">
              {scripts.sorry}, {scripts.theFollowingProblemsMayHaveOccurred}:
            </Typography>
            <Typography variant="inherit">
              1. {scripts.yourNetworkHasBeenDisconnected}.
            </Typography>
            <Typography variant="inherit">
              2. {scripts.theServerIsNotResponding}.
            </Typography>
          </Stack>,
          error,
        );
      } else if (message === 'Authentication is needed to get response.') {
        operationError(
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
          >
            <Typography variant="inherit">
              {scripts.sorry},{scripts.theFollowingProblemsMayHaveOccurred}:
            </Typography>
            <Typography variant="inherit">
              1. {scripts.yourNetworkIsDisconnected}.
            </Typography>
            <Typography variant="inherit">
              2. {scripts.thisAccountHasBeenLoggedOut}.
            </Typography>
          </Stack>,
          error,
        );
      } else if (message.indexOf('Delete') === 0 && message.slice(-6) === 'first.') {
        // const apiName = error.graphQLErrors?.[0]?.path?.[0];
        operationError(
          <Typography variant="inherit">
            {scripts.youCannotDeleteThis} {name} {scripts.becauseItIsNotEmpty}
          </Typography>,
          error,
        );
      } else if (code) {
        if (`${code}` === '404') {
          operationError(
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography variant="inherit" component={Box}>
                {scripts.sorry}, the
                <Typography variant="inherit" component="span" fontWeight="bold">
                  {` ${name} `}
                </Typography>
                {scripts.couldNotBeFound}.
              </Typography>
            </Stack>,
            error,
          );
        } else {
          operationError('', error);
        }
      } else {
        operationError('', error);
      }
    }
  };

  useEffect(() => {
    if (!operationResultRef.current) {
      return;
    }

    if (!setSnackbarState) {
      setSetSnackbarState(
        () => operationResultRef.current?.setSnackbarState ?? setSnackbarState,
      );
    }
    if (!operationLoading) {
      setOperationLoading(
        () => operationResultRef.current?.operationLoading ?? operationLoading,
      );
    }
    if (!operationSuccess) {
      setOperationSuccess(() => () => {
        if (operationTimer.current) {
          clearTimeout(operationTimer.current);
        }

        operationTimer.current = setTimeout(() => {
          operationResultRef.current?.operationSuccess();
        }, 100);
      });
    }
    if (!operationError) {
      setOperationError(
        () => (msg?: string | JSX.Element, error?: ApolloError | AxiosError) => {
          if (operationTimer.current) {
            clearTimeout(operationTimer.current);
          }

          operationTimer.current = setTimeout(() => {
            operationResultRef.current?.operationError(msg, error);
          }, 100);
        },
      );
    }
    if (!operationLoaded) {
      setOperationLoaded(
        () => operationResultRef.current?.operationLoaded ?? operationLoaded,
      );
    }
  }, [operationResultRef.current]);

  return (
    <OperationResultContext.Provider
      value={
        setSnackbarState &&
        operationLoading &&
        operationSuccess &&
        operationError &&
        operationLoaded
          ? {
              setSnackbarState,
              operationLoading,
              operationSuccess,
              operationError,
              operationLoaded,
              commonErrorMessages,
            }
          : defaultValue
      }
    >
      {setSnackbarState &&
        operationLoading &&
        operationSuccess &&
        operationError &&
        operationLoaded &&
        children}
      <OperationResultMessage ref={operationResultRef} />
    </OperationResultContext.Provider>
  );
};

export const useOperationResultContext = () => {
  return useContext<OperationResultContextProps>(OperationResultContext);
};
