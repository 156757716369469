import { forwardRef } from 'react';

import { Button, ButtonProps, SxProps, Theme } from '@mui/material';
import { colord } from 'colord';

type CustomButtonProps = Omit<ButtonProps, 'color' | 'ref'> & {
  danger?: boolean;
  color?: string;
};

const CustomButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
  ({ color, ...rest }, ref) => {
    const baseColor =
      color && colord(color).isValid() ? color : rest?.danger ? '#FF6663' : '#00A591';
    const color1 = baseColor;
    const color2 = colord(baseColor).alpha(0.5).toRgbString();
    const color3 = colord(baseColor).alpha(0.4).toRgbString();
    const color4 = color1;

    // const danger = rest?.danger ?? false;
    // const color1 = danger ? '#FF6663' : '#00A591';
    // const color2 = danger ? 'rgba(255, 102, 99, 0.5)' : 'rgba(0, 165, 145, 0.5)';
    // const color3 = danger ? 'rgba(255, 102, 99, 0.4)' : 'rgba(0, 165, 145, 0.4)';
    // const color4 = danger ? color1 : '#009987';

    const hoverFieldName = '&:hover' as const;
    const hoverObj =
      !!rest?.sx &&
      hoverFieldName in rest.sx &&
      typeof rest.sx[hoverFieldName] === 'object'
        ? rest.sx[hoverFieldName]
        : null;

    const sx: SxProps<Theme> = {
      ...(rest.variant === 'text'
        ? {
            color: color1,
          }
        : rest.variant === 'outlined'
        ? {
            color: color1,
            border: `1px solid ${color2}`,
            // color: '#FFF', //'#00A591',
            // backgroundColor: '#D9D9D9',
            // border: '1px solid #D9D9D9',
          }
        : {
            color: '#FFF',
            bgcolor: color1,
          }),
      borderRadius: '10px',
      ...rest.sx,
      ...(rest.variant === 'text'
        ? {
            '&:hover': {
              bgcolor: color3,
              ...hoverObj,
            },
          }
        : rest.variant === 'outlined'
        ? {
            '&:hover': {
              bgcolor: color3,
              border: `1px solid ${color1}`,
              ...hoverObj,
            },
          }
        : {
            '&:hover': {
              bgcolor: color4,
              ...hoverObj,
            },
          }),
    };
    const propsCopy = {
      ...rest,
      sx,
    };
    if (typeof propsCopy?.danger !== 'undefined') {
      delete propsCopy.danger;
    }

    return <Button {...propsCopy} ref={ref} />;
  },
);
CustomButton.displayName = 'CustomButton';

export default CustomButton;
