import IconType from './IconType';

const ClassesIcon = ({ width = 26, height = 11, color = '#090446', style }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0.645894 6.22423H1.60274C1.92015 8.91629 3.97871 11 6.46448 11C8.95024 11 11.1361 8.78386 11.3502 5.97075C11.9084 5.72244 13.1753 5.32825 14.6489 5.98213C14.8676 8.79007 16.9741 11 19.5346 11C22.0951 11 24.0789 8.91526 24.3963 6.22423H25.3541C25.7112 6.22423 26 5.90039 26 5.5C26 5.09961 25.7112 4.77577 25.3541 4.77577H24.3963C24.0789 2.08371 22.0204 0 19.5346 0C17.0488 0 15.1462 1.92955 14.7162 4.47366C13.3026 3.97498 12.0653 4.19639 11.2819 4.46746C10.8492 1.92645 8.85428 0 6.46448 0C4.07467 0 1.92015 2.08474 1.60274 4.77577H0.645894C0.288807 4.77577 0 5.09961 0 5.5C0 5.90039 0.288807 6.22423 0.645894 6.22423ZM19.5346 1.44846C21.5267 1.44846 23.1479 3.26627 23.1479 5.5C23.1479 7.73373 21.5267 9.55154 19.5346 9.55154C17.5425 9.55154 15.9213 7.73373 15.9213 5.5C15.9213 3.26627 17.5425 1.44846 19.5346 1.44846ZM6.46448 1.44846C8.4566 1.44846 10.0778 3.26627 10.0778 5.5C10.0778 7.73373 8.4566 9.55154 6.46448 9.55154C4.47235 9.55154 2.85116 7.73373 2.85116 5.5C2.85116 3.26627 4.47235 1.44846 6.46448 1.44846Z"
        fill={color}
      />
    </svg>
  );
};

export default ClassesIcon;
