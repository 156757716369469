import { useId } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import { useScripts } from '../layout/utils/LanguageHelper';

type CustomRadioGroupProps = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  options: Array<{ label: string | JSX.Element; value: string }>;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  required?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  size?: 'medium' | 'small';
  direction?: 'row' | 'column';
  disabled?: boolean;
  dialogProps: {
    willSubmit: boolean;
  };
};

const CustomRadioGroup = (props: CustomRadioGroupProps) => {
  const scripts = useScripts();

  const radioGroupId = useId();

  const required = props.required ? { required: true } : { required: false };
  const fullWidth = props.fullWidth ? { fullWidth: true } : { fullWidth: false };
  const error = props.error
    ? {
        error:
          (props.error || (props.required ? !props.value : false)) &&
          props.dialogProps.willSubmit,
      }
    : { error: props.required && !props.value && props.dialogProps.willSubmit };
  error.error = !error.error ? undefined : true;
  let helperText =
    props.required && !props.value && props.dialogProps.willSubmit ? (
      <Typography variant="caption" sx={{ display: 'block' }}>
        {scripts.pleaseSelectAnOption}
      </Typography>
    ) : (
      ''
    );
  helperText = props.helperText ? (
    <>
      <Typography variant="caption" sx={{ display: 'block' }}>
        {props.helperText}
      </Typography>
      {helperText}
    </>
  ) : (
    helperText
  );
  const variant = props.variant ? props.variant : 'outlined';
  const size = props.size ? props.size : 'medium';
  const disabled = props.disabled ? { disabled: true } : { disabled: false };
  const params = Object.assign(
    {},
    { value: props.value },
    props.onChange
      ? { onChange: props.onChange }
      : {
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            props.setValue(e.target.value),
        },
    { label: props.label },
  );

  return (
    <FormControl
      {...required}
      {...error}
      {...fullWidth}
      variant={variant}
      size={size}
      {...disabled}
    >
      <FormLabel id={radioGroupId} {...disabled}>
        {props.label}
      </FormLabel>
      <RadioGroup
        aria-labelledby={radioGroupId}
        {...params}
        sx={{ flexDirection: props.direction === 'row' ? 'row' : 'column' }}
      >
        {props.options.map((option) => (
          <FormControlLabel
            key={`${option.label}-${option.value}`}
            value={option.value}
            control={
              <Radio
                {...disabled}
                sx={{
                  '&.Mui-checked': {
                    color: '#00A591',
                  },
                }}
              />
            }
            label={option.label}
            {...disabled}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomRadioGroup;
