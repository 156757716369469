import { useState, useEffect } from 'react';

import {
  Divider,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { default as Grid } from '@mui/material/Unstable_Grid2';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

//import AddSourceButton from './AddSourceButton';
import {
  CustomAddOrEditDialog,
  CustomTextField,
  OperationResultDialog,
} from '../../../globalComponents';
import { useOperationResultContext } from '../../../globalContexts/OperationResultContext';
import { useDialog } from '../../../globalHooks';
import {
  useSourcesLazyQuery,
  useAddLeadMutation,
  useCheckPhoneMutation,
  useUpdateLeadMutation,
  Maybe,
  Child,
  User,
  Source,
  LeadStatus,
  Scalars,
  LeadsQuery,
  useAddSourceMutation,
} from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';
import AddCircleIcon from '../../../svg/AddCircleIcon';

type AddOrEditLeadButtonProps = {
  isAdd: boolean;
  clickedSchoolId?: string;
  clickedSchoolName?: string;
  currentLead?: {
    __typename?: 'Lead';
    address?: Maybe<Scalars['String']>;
    children: Array<Child>;
    creator?: Maybe<User>;
    id: Scalars['ID'];
    leadBy: Scalars['String'];
    name: string;
    phone: Scalars['String'];
    source?: Maybe<Source>;
    sourceId: Scalars['ID'];
    status: LeadStatus;
    transitions: {
      __typename?: 'Transition';
      id: Scalars['ID'];
      notes: Scalars['String'];
      status: LeadStatus;
    }[];
  };
  updateQuery: (mapFn: (previousQueryResult: LeadsQuery) => LeadsQuery) => void;
};

interface ChildInputRow {
  age: string;
  name: string;
  englishName: string;
  schoolName: string;
}

const filter = createFilterOptions<{
  label: string;
  value: string;
  inputValue?: string;
}>();

const AddOrEditLeadButton = ({
  isAdd,
  clickedSchoolId,
  clickedSchoolName,
  updateQuery,
  currentLead,
}: AddOrEditLeadButtonProps): JSX.Element => {
  const scripts = useScripts();

  const { operationLoading, operationSuccess, commonErrorMessages } =
    useOperationResultContext();

  const isScreenDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // states
  // add lead states

  const [phone, setPhone] = useState(currentLead?.phone || '');
  const [name, setParentName] = useState(currentLead?.name || '');
  const [leadBy, setLeadBy] = useState(currentLead?.leadBy || '');

  const [
    fetchSources,
    { data: sourcesData, loading: sourcesLoading, updateQuery: updateSourcesQuery },
  ] = useSourcesLazyQuery();

  const currentSourceName = sourcesData?.sources.edges.filter(({ node: { id } }) => {
    return id === currentLead?.sourceId;
  })?.[0]?.node?.name;

  const [source, setSource] = useState<{
    label: string;
    value: string;
    inputValue?: string;
  }>({
    label: '',
    value: currentLead?.sourceId || '',
  });

  useEffect(() => {
    if (currentSourceName) {
      setSource({
        label: currentSourceName,
        value: currentLead?.sourceId || '',
      });
    }
  }, [currentSourceName, currentLead?.sourceId]);

  const [address, setAddress] = useState(currentLead?.address || '');
  const [childInputRows, setChildInputRows] = useState<ChildInputRow[]>(
    currentLead?.children?.map(({ age, englishName, name, schoolName }) => {
      return {
        englishName: englishName || '',
        name: name || '',
        age: age || '',
        schoolName: schoolName || '',
      };
    }) || [{ englishName: '', name: '', age: '', schoolName: clickedSchoolName || '' }],
  );

  const handleChange = (
    newValue: string,
    //info: MuiTelInputInfo
  ) => {
    setPhone(newValue);
  };

  const handleClose = () => {
    if (isAdd) {
      setPhone('');
    }
  };

  const [
    checkPhoneMutation,
    {
      data: checkPhoneData,
      loading: checkPhoneLoading,
      //error: checkPhoneError
    },
  ] = useCheckPhoneMutation();

  useEffect(() => {
    if (matchIsValidTel(phone) && clickedSchoolId && phone !== currentLead?.phone) {
      checkPhoneMutation({
        variables: {
          phone,
          schoolId: clickedSchoolId,
        },
      });
    }
  }, [matchIsValidTel(phone)]);

  const [
    addLeadMutation,
    { data: addLeadData, loading: addLeadLoading, error: addLeadError },
  ] = useAddLeadMutation({
    onCompleted(data) {
      if (data) {
        updateQuery((previousQueryResult) => {
          const leads = { ...previousQueryResult.leads };
          const pageInfo = { ...previousQueryResult.leads.pageInfo };
          const newLeads = [...leads.edges];
          newLeads.unshift({
            __typename: 'LeadEdge',
            cursor: '',
            node: data.addLead,
          });
          leads.edges = newLeads;
          pageInfo.itemTotal = pageInfo.itemTotal + 1;
          leads.pageInfo = pageInfo;

          const newQueryResult = { ...previousQueryResult };
          newQueryResult.leads = leads;
          return newQueryResult;
        });
        handleClose();
      }
    },
  });

  const [
    updateLeadMutation,
    { data: updateLeadData, loading: updateLeadLoading, error: updateLeadError },
  ] = useUpdateLeadMutation();

  const handleAddRow = () => {
    setChildInputRows([
      ...childInputRows,
      { englishName: '', name: '', age: '', schoolName: clickedSchoolName || '' },
    ]);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const { name, value } = event.target;
    const newRows = [...childInputRows];
    newRows[index][name as keyof ChildInputRow] = value;
    setChildInputRows(newRows);
  };

  const handleAddNewLead = () => {
    if (clickedSchoolId && name && phone && source.value && childInputRows && leadBy) {
      addLeadMutation({
        variables: {
          schoolId: clickedSchoolId,
          leadInput: {
            name,
            phone,
            address,
            leadBy,
            sourceId: source.value,
            children: childInputRows,
          },
        },
      });
    }
    handleClose();
  };

  const handleEditLead = () => {
    if (currentLead?.id && name && phone && source.value && childInputRows && leadBy) {
      updateLeadMutation({
        variables: {
          leadId: currentLead?.id,
          leadInput: {
            name,
            phone,
            address,
            leadBy,
            sourceId: source.value,
            children: childInputRows,
          },
        },
      });
    }
    handleClose();
  };

  const { dialogProps } = useDialog({
    tooltipTitle: isAdd ? scripts.addLead : scripts.editLead,
    dialogTitle: isAdd ? (
      <Typography variant="h5" fontWeight="bold">
        {scripts.newLead}
      </Typography>
    ) : (
      <Typography variant="h5" fontWeight="bold">
        {scripts.editLead}
      </Typography>
    ),
    dialogTitleAlign: 'center',
    //resetInput: () => {},
    inputCheckResult: () => {
      return !!(
        clickedSchoolId &&
        name &&
        phone &&
        matchIsValidTel(phone) &&
        ((!isAdd && phone !== currentLead?.phone && checkPhoneData?.checkPhone) ||
          (!isAdd && phone === currentLead?.phone) ||
          (isAdd && checkPhoneData?.checkPhone)) &&
        leadBy
      );
    },
    onSubmit: () => {
      isAdd && handleAddNewLead();
      !isAdd && handleEditLead();
      operationLoading();
    },
    onClose: () => {
      handleClose();
    },
    fullWidth: true,
    getCustomButton: isAdd
      ? (onClick) => (
          <Button
            variant="contained"
            startIcon={<AddCircleIcon color="#FEB95F" width={24} height={24} />}
            size="medium"
            sx={{
              justifyContent: {
                xs: 'space-between',
                sm: 'flex-start',
              },
              borderRadius: 2,
              backgroundColor: '#FFF', //'#D9D9D9',
              color: 'black',
              '&:hover': {
                backgroundColor: '#FAFAFA',
              },
              textTransform: 'none',
            }}
            onClick={onClick}
          >
            {scripts.newLead}
          </Button>
        )
      : undefined,
  });

  // graphql
  const [
    addSourceMutation,
    { data: addSourceData, error: addSourceError, loading: addSourceLoading },
  ] = useAddSourceMutation({
    onCompleted(data) {
      const newSource = data.addSource;

      // update query sources
      updateSourcesQuery((previousQueryResult) => {
        if (previousQueryResult.sources.pageInfo.hasNextPage) {
          return previousQueryResult;
        }

        const sources = { ...previousQueryResult.sources };
        const pageInfo = { ...previousQueryResult.sources.pageInfo };
        const newSources = [...sources.edges];
        newSources.push({
          __typename: 'SourceEdge',
          cursor: '',
          node: newSource,
        });
        sources.edges = newSources;
        pageInfo.itemTotal = pageInfo.itemTotal + 1;
        sources.pageInfo = pageInfo;

        const newQueryResult = { ...previousQueryResult };
        newQueryResult.sources = sources;

        return newQueryResult;
      });

      operationSuccess();
      setSource({
        label: newSource.name,
        value: newSource.id,
      });
    },
    onError(error) {
      commonErrorMessages(error);
    },
  });

  return (
    <>
      <CustomAddOrEditDialog
        isAdd={isAdd}
        {...dialogProps}
        showHoverBackgroundColor={true}
      >
        <DialogContent sx={{ padding: 0 }}>
          <Divider />
          <Grid container spacing={2} sx={{ margin: 1 }}>
            <Grid
              style={{
                width: isScreenDownSm ? '100%' : '50%',
              }}
            >
              <CustomTextField
                required
                value={name}
                setValue={setParentName}
                label={scripts.parentName}
                fullWidth
                dialogProps={dialogProps}
                backgroundColor="#F8F8F8"
                borderLine={'false'}
              />
            </Grid>
            <Grid
              style={{
                width: isScreenDownSm ? '100%' : '50%',
              }}
            >
              <MuiTelInput
                value={phone}
                onChange={handleChange}
                defaultCountry={'TW'}
                forceCallingCode
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#00A591',
                    },
                    '& fieldset': {
                      borderColor: 'rgba(0, 0, 0, 0)',
                    },
                  },
                  backgroundColor: '#F8F8F8',
                  '& .MuiFormHelperText-root': {
                    margin: 0,
                    backgroundColor: '#FFF',
                  },
                }}
                helperText={
                  matchIsValidTel(phone) ? (
                    phone === currentLead?.phone || checkPhoneData?.checkPhone ? (
                      ''
                    ) : checkPhoneLoading ? (
                      scripts.checking
                    ) : (
                      <span style={{ color: 'red' }}>
                        {scripts.thisPhoneNumberHasAlreadyBeenEntered}
                      </span>
                    )
                  ) : phone === '' ? (
                    <span style={{ color: 'red' }}>{scripts.pleaseEnterPhoneNumber}</span>
                  ) : (
                    <span style={{ color: 'red' }}>
                      {scripts.thisPhoneNumberIsInvalid}
                    </span>
                  )
                }
              />
            </Grid>
            <Grid
              style={{
                width: isScreenDownSm ? '100%' : '50%',
              }}
            >
              <CustomTextField
                value={address}
                setValue={setAddress}
                label={scripts.address}
                fullWidth
                backgroundColor="#F8F8F8"
                borderLine={'false'}
              />
            </Grid>
          </Grid>
          <br />
          <TableContainer
            sx={{
              '::-webkit-scrollbar': {
                height: '6px',
              },
            }}
          >
            <Table
              sx={{
                width: isScreenDownSm ? '250%' : '100%',
                overflow: 'auto',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: 0 }}>
                    <Tooltip title={scripts.addAChild}>
                      <IconButton
                        onClick={handleAddRow}
                        // sx={{ padding: 0 }}
                      >
                        <AddCircleIcon color="#FEB95F" width={24} height={24} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ padding: 0.5, textAlign: 'center' }}>
                    {scripts.childsEnglishName}
                  </TableCell>
                  <TableCell sx={{ padding: 0.5, textAlign: 'center' }}>
                    {scripts.childsChineseName}
                  </TableCell>
                  <TableCell sx={{ padding: 0.5, textAlign: 'center' }}>
                    {scripts.childsAge}
                  </TableCell>
                  <TableCell sx={{ padding: 0.5, textAlign: 'center' }}>
                    {scripts.childsSchool}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {childInputRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="englishName"
                        value={row.englishName}
                        onChange={(event) => handleInputChange(event, index)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#00A591',
                            },
                            '& fieldset': {
                              borderColor: 'rgba(0, 0, 0, 0)',
                            },
                            backgroundColor: '#F8F8F8',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="name"
                        value={row.name}
                        onChange={(event) => handleInputChange(event, index)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#00A591',
                            },
                            '& fieldset': {
                              borderColor: 'rgba(0, 0, 0, 0)',
                            },
                            backgroundColor: '#F8F8F8',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="age"
                        value={row.age}
                        onChange={(event) => handleInputChange(event, index)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#00A591',
                            },
                            '& fieldset': {
                              borderColor: 'rgba(0, 0, 0, 0)',
                            },
                            backgroundColor: '#F8F8F8',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="schoolName"
                        value={
                          row.schoolName //|| clickedSchoolName
                        }
                        onChange={(event) => handleInputChange(event, index)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                              borderColor: '#00A591',
                            },
                            '& fieldset': {
                              borderColor: 'rgba(0, 0, 0, 0)',
                            },
                            backgroundColor: '#F8F8F8',
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Grid container spacing={2} sx={{ margin: 1 }}>
            <Grid
              style={{
                width: isScreenDownSm ? '100%' : '50%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Autocomplete
                value={source}
                fullWidth
                size="medium"
                clearOnBlur
                selectOnFocus
                disableClearable
                handleHomeEndKeys
                disabled={sourcesLoading}
                options={
                  (sourcesData?.sources?.edges?.map(({ node: { id, name } }) => ({
                    label: name,
                    value: id,
                  })) ?? [{ label: '', value: '' }]) as {
                    label: string;
                    value: string;
                    inputValue?: string;
                  }[]
                }
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.label,
                  );
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      label: `Add "${inputValue}"`,
                      value: '',
                    });
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }

                  return option.label;
                }}
                onChange={(_, newValue) => {
                  if (
                    newValue.label.startsWith('Add "') &&
                    newValue.inputValue &&
                    clickedSchoolId
                  ) {
                    addSourceMutation({
                      variables: {
                        name: newValue.inputValue,
                        schoolId: clickedSchoolId,
                      },
                    });
                  } else {
                    setSource(newValue);
                  }
                }}
                onFocusCapture={() =>
                  clickedSchoolId &&
                  fetchSources({ variables: { first: 0, schoolId: clickedSchoolId } })
                }
                renderOption={(props, option) => <li {...props}>{option.label}</li>}
                renderInput={(params) => {
                  const isEmptyValue = !source.value && dialogProps.willSubmit;

                  const helperText = isEmptyValue ? (
                    <Typography variant="caption" sx={{ display: 'block' }}>
                      {scripts.pleaseChooseASource}
                    </Typography>
                  ) : (
                    ''
                  );

                  return (
                    <TextField
                      {...params}
                      required
                      autoFocus
                      label={scripts.leadSource}
                      disabled={sourcesLoading}
                      helperText={helperText}
                      error={!!isEmptyValue}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {!!sourcesLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      sx={{
                        '& label.Mui-focused': {
                          color: '#00A591',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#00A591',
                          },
                          '& fieldset': {
                            borderColor: 'rgba(0, 0, 0, 0)',
                          },
                          backgroundColor: '#F8F8F8',
                        },
                      }}
                    />
                  );
                }}
                ListboxProps={{
                  style: {
                    maxHeight: '100px',
                  },
                }}
              />
              {/* {clickedSchoolId && (
                <AddSourceButton
                  schoolId={clickedSchoolId}
                  inputValue={source.inputValue}
                  setInputValue={setSource}
                  updateQuery={updateSourcesQuery}
                />
              )} */}
            </Grid>
            <Grid
              style={{
                width: isScreenDownSm ? '100%' : '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CustomTextField
                fullWidth
                required
                value={leadBy}
                setValue={setLeadBy}
                label={scripts.leadBy}
                dialogProps={dialogProps}
                backgroundColor="#F8F8F8"
                borderLine={'false'}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </CustomAddOrEditDialog>
      {dialogProps.isSubmit && (
        <OperationResultDialog
          data={updateLeadData}
          error={updateLeadError}
          loading={updateLeadLoading}
        />
      )}
      {dialogProps.isSubmit && (
        <OperationResultDialog
          data={addLeadData}
          error={addLeadError}
          loading={addLeadLoading}
        />
      )}
      <OperationResultDialog
        data={addSourceData}
        error={addSourceError}
        loading={addSourceLoading}
      />
    </>
  );
};

export default AddOrEditLeadButton;
