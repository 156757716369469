import IconType from './IconType';

const BorderWidthSelectIcon = ({
  width = 24,
  height = 16,
  color = '#000',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M0 1H12" stroke={color} />
      <path d="M0 4H12" stroke={color} strokeWidth="1.5" />
      <path d="M0 7H12" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default BorderWidthSelectIcon;
