import React, { useEffect } from 'react';

import type { SxProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import useUpdateFontStyle, { EditTextType } from './text/hooks/useUpdateFontStyle';
import { DraggableTag } from '../../../graphql/resolver.types';
import { updateDraggable } from '../../../redux/features/styleSlice';
import { useAppDispatch } from '../../../redux/store';
import { getFontFamily } from '../context/FontEditorContext';
import { useStyle } from '../context/StyleContext';
import { accept } from '../utils/draggables';

interface SelectBaseProps {
  label: string;
  property: keyof React.CSSProperties;
  acceptTags: Array<DraggableTag>;
  options: Array<string>;
  currentValue?: string;
  defaultValue: string;
  formControlSx?: SxProps;
}

export default function SelectBase({
  label,
  property,
  acceptTags,
  options,
  currentValue = '',
  defaultValue,
  formControlSx,
}: SelectBaseProps) {
  const dispatch = useAppDispatch();
  const { selectedId, selected } = useStyle();
  const selectedProperty = selected?.style?.[property]?.toString();
  const isAcceptType = accept(acceptTags, selected?.tags);
  const [value, setValue] = React.useState('');
  const [fontSize, setFontsize] = React.useState('');

  const { updateFontStyle } = useUpdateFontStyle(EditTextType.FontFamily);
  const { updateFontSize } = useUpdateFontStyle(EditTextType.FontSize);

  const fillFontInfo = (value: string) => {
    if (property === 'fontFamily') {
      updateFontStyle(getFontFamily(value), EditTextType.FontFamily);
    }
    if (property === 'fontSize') {
      updateFontSize(Number(value));
    }
  };

  useEffect(() => {
    setFontsize(currentValue);
  }, [currentValue]);

  // func
  useEffect(() => {
    if (property === 'fontFamily') {
      setValue(isAcceptType ? (selectedProperty ? selectedProperty : defaultValue) : ''); // not trigger onChange, you need set global default here.
    } else if (property === 'fontSize') {
      setValue(isAcceptType ? (currentValue ? currentValue : defaultValue) : '');
    }
    // }
  }, [selectedProperty, selectedId]);

  const handleChange = (e: SelectChangeEvent) => {
    const newValue = e.target.value as string;
    setValue(newValue);
    setFontsize(newValue);
    if (selectedId) {
      fillFontInfo(newValue);

      dispatch(
        updateDraggable({
          id: selectedId,
          style: { [property]: newValue },
        }),
      );
    }
  };
  // main
  return (
    <FormControl fullWidth size="small" disabled={!isAcceptType} sx={formControlSx}>
      <InputLabel id={`editStyle-${property}-toolbar`}>{label}</InputLabel>
      <Select
        labelId={`editStyle-${property}-toolbar`}
        id={`editStyle-${property}-select`}
        value={property === 'fontFamily' ? value : fontSize}
        label={label}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
              width: '150px',
            },
          },
        }}
      >
        {options.map((value) => (
          <MenuItem key={`${property} ${value}`} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
