import React from 'react';

import { SxProps, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import { DraggableTag } from '../../../graphql/resolver.types';
import { updateDraggable } from '../../../redux/features/styleSlice';
import { useAppDispatch } from '../../../redux/store';
import { useStyle } from '../context/StyleContext';
import { accept } from '../utils/draggables';

interface ToolButtonProps {
  value?: React.CSSProperties;
  tooltip: string;
  acceptTags?: Array<DraggableTag>;
  children: React.ReactNode;
  sx?: SxProps;
}

export const useToolButton = ({
  value,
  acceptTags,
}: Omit<ToolButtonProps, 'tooltip' | 'children' | 'sx'>) => {
  const dispatch = useAppDispatch();

  const { selectedId, selected } = useStyle();
  const isAcceptType = accept(acceptTags, selected?.tags); // acceptTags

  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [needUpdateRedux, setNeedUpdateRedux] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isAcceptType && value && selected?.style) {
      let _needUpdateRedux = true;
      let _active = true;

      const properties = Object.keys(value) as (keyof typeof value)[];
      properties.forEach((property) => {
        const selectedProperty = selected?.style[property]; // possible undefined
        const valueProperty = value[property]; // must have value

        if (valueProperty === selectedProperty) {
          _needUpdateRedux = false; // both have value and there are same
        } else {
          _active = false;
        }
      });
      setNeedUpdateRedux(_needUpdateRedux);
      setIsActive(_active);
    } else {
      setNeedUpdateRedux(false);
      setIsActive(false);
    }
  }, [isAcceptType, value, selected?.style]);

  const update = () => {
    if (!isAcceptType || !needUpdateRedux || !selectedId) {
      return;
    }

    dispatch(
      updateDraggable({
        id: selectedId,
        style: {
          ...value,
          ...(value &&
          value.borderStyle &&
          value.borderStyle !== 'none' &&
          !selected?.style.borderWidth
            ? { borderWidth: 1 }
            : null),
        },
      }),
    );
  };

  return {
    isAcceptType,
    isActive,
    needUpdateRedux,
    update,
  };
};

export default function ToolButton({
  value,
  tooltip,
  acceptTags,
  children,
  sx,
  ...props
}: ToolButtonProps) {
  const dispatch = useAppDispatch();
  const { isAcceptType, isActive, needUpdateRedux } = useToolButton({
    value,
    acceptTags,
  });
  const { selectedId } = useStyle();
  const isScreenDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const _sx = Array.isArray(sx) ? sx : [sx];

  // func
  function update(value?: React.CSSProperties) {
    if (!isAcceptType || !needUpdateRedux) return;

    selectedId &&
      dispatch(
        updateDraggable({
          id: selectedId,
          style: {
            ...value,
          },
        }),
      );
  }
  // main
  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton
          disabled={!isAcceptType}
          size={isScreenDownSm ? 'small' : 'medium'}
          sx={[isActive && { bgcolor: '#ddd' }, ..._sx]}
          onClick={() => update(value)}
          {...props}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
}

export function TextToolButton(props: ToolButtonProps) {
  return <ToolButton acceptTags={[DraggableTag.TextField]} {...props} />;
}

export function BorderStyleButton(props: ToolButtonProps) {
  return (
    <ToolButton
      acceptTags={[DraggableTag.TextField, DraggableTag.Elements]}
      sx={{
        width: 40,
        height: 40,
        borderRadius: '5px',
        border: '1px solid',
        borderColor: '#ccc',
      }}
      {...props}
    />
  );
}
