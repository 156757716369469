import { useEffect, useState } from 'react';

import CustomAutocomplete from '../../../globalComponents/CustomAutocomplete';
import {
  useLevelsWithIdNameBooksWithIdNameLevelProductIdLazyQuery,
  LevelType,
} from '../../../graphql/resolver.types';

type SelectLevelProps = {
  classId?: string;
  required?: boolean;
  onChildSetClickedLevelId: (arg: string) => void;
  reset?: (arg: string) => void;
  levelType: LevelType;
  currentLevel?: number;
  currentProductId?: string;
  willSubmit?: boolean;
  resetArray?: ((arg: string) => void)[];
  onChildSetClickedLevelName?: (arg: string) => void;
  levelsFromSelectLevel?: (
    arg: {
      __typename?: 'LevelEdge' | undefined;
      node: {
        __typename?: 'Level' | undefined;
        id: string;
        name: string;
        books: {
          __typename?: 'Book' | undefined;
          id: string;
          name: string;
          level: number;
          productId: string;
          product: {
            id: string;
            name: string;
            withoutGrades: boolean;
          };
        }[];
      };
    }[],
  ) => void;
  levelIdFromOtherPage?: string;
};

const SelectLevel = ({
  classId,
  required = false,
  onChildSetClickedLevelId,
  reset,
  levelType,
  currentLevel = 0,
  currentProductId,
  willSubmit = false,
  resetArray,
  onChildSetClickedLevelName,
  levelsFromSelectLevel,
  levelIdFromOtherPage,
}: SelectLevelProps) => {
  const [fetchLevels, { data: levelsData, loading: levelsLoading }] =
    useLevelsWithIdNameBooksWithIdNameLevelProductIdLazyQuery();
  const levels = levelsData?.levels.edges;

  useEffect(() => {
    if (levelsFromSelectLevel && levels) {
      levelsFromSelectLevel(levels);
    }
  }, [levels]);

  // current class level Id
  const levelsLessEqualCurrentLevel = levels?.filter(({ node }) => {
    return node.books.every(({ level }) => level <= currentLevel); // every book' level need to less than current class level
  });

  const levelsWithCurrentProduct = levelsLessEqualCurrentLevel?.filter(({ node }) =>
    node.books.some(({ productId }) => productId === currentProductId),
  );

  const currentLevelId =
    levelsWithCurrentProduct?.filter(({ node }) =>
      node.books.some(({ level }) => level === currentLevel),
    )?.[0]?.node?.id ||
    levelsWithCurrentProduct?.[levelsWithCurrentProduct?.length - 1]?.node?.id; // if no match class current level, use Max(last one) level

  const [clickedLevelId, setClickedLevelId] = useState('');
  const [clickedLevelName, setClickedLevelName] = useState('');

  useEffect(() => {
    if (levelsData && currentLevelId) {
      setClickedLevelId(currentLevelId);
    }
  }, [currentLevelId, levelsData]);

  useEffect(() => {
    setClickedLevelId(''); // reset level id if class id change
    if (classId) {
      fetchLevels({
        variables: {
          first: 0,
          type: levelType,
          classroomId: classId,
        },
      });
    }
  }, [classId]);

  useEffect(() => {
    if (reset) {
      reset('');
    }
    if (resetArray) {
      resetArray.forEach((fn) => {
        fn('');
      });
    }
    if (clickedLevelId) {
      onChildSetClickedLevelId(clickedLevelId); // pass new id to parent
    } else {
      onChildSetClickedLevelId('');
    }
    if (onChildSetClickedLevelName && clickedLevelName) {
      onChildSetClickedLevelName(clickedLevelName); // pass new id to parent
    }
  }, [clickedLevelId, clickedLevelName]);

  return (
    <CustomAutocomplete
      disabled={!levelsData || !classId}
      loading={levelsLoading}
      options={
        levels?.map(({ node }) => ({
          label: node.name,
          value: node.id,
        })) ?? []
      }
      value={
        levels
          ?.filter(
            ({ node }) => node.id === clickedLevelId || node.id === levelIdFromOtherPage,
          )
          .map(({ node }) => ({
            label: node.name,
            value: node.id,
          })) ?? []
      }
      onChangeForSingleValue={(e, option) => {
        setClickedLevelId(option?.value ?? '');
        setClickedLevelName(option?.label ?? '');
      }}
      label="Level"
      fullWidth={true}
      dialogProps={{ willSubmit }}
      required={required}
      helperText={willSubmit && !clickedLevelId ? 'Required for book data' : ''}
    />
  );
};

export default SelectLevel;
