import { useState, useEffect } from 'react';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { default as Grid } from '@mui/material/Unstable_Grid2';

import {
  CustomAddOrEditDialog,
  CustomTextField,
  OperationResultDialog,
} from '../../../globalComponents';
import CustomButton from '../../../globalComponents/CustomButton';
import { useOperationResultContext } from '../../../globalContexts/OperationResultContext';
import { useDialog } from '../../../globalHooks';
import {
  LeadStatus,
  LeadForLeadsFragment,
  useUpdateLeadStatusMutation,
} from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';
import ChangeIcon from '../../../svg/ChangeIcon';

type ChangeLeadStatusButtonProps = {
  selectedLead: LeadForLeadsFragment;
};

type StatusButtonProps = {
  buttonText: string;
  isCurrentStatus: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  onClick?: () => void;
};

const StatusButton = ({ buttonText, isCurrentStatus, onClick }: StatusButtonProps) => {
  return (
    <CustomButton
      variant={isCurrentStatus ? 'contained' : 'outlined'}
      onClick={onClick}
      sx={{
        textTransform: 'none',
      }}
    >
      {buttonText}
    </CustomButton>
  );
};

const ChangeLeadStatusButton = ({
  selectedLead,
}: ChangeLeadStatusButtonProps): JSX.Element => {
  const scripts = useScripts();

  const { operationLoading, operationSuccess, commonErrorMessages } =
    useOperationResultContext();

  // exitsting data
  const { id: leadId, status: leadStatus, transitions } = selectedLead;

  // function
  const getStatusNotes = (currentStatus?: LeadStatus) =>
    transitions.find(({ status }) => status === currentStatus)?.notes || '';

  // states
  const [transitionStatus, setTransitionStatus] = useState(leadStatus);
  const [transitionNotes, setTransitionNotes] = useState(getStatusNotes(leadStatus));

  // graphql
  const [updateLeadMutation, { data, error, loading }] = useUpdateLeadStatusMutation({
    onCompleted() {
      operationSuccess();
    },
    onError(error) {
      commonErrorMessages(error);
    },
  });

  const { dialogProps } = useDialog({
    tooltipTitle: scripts.changeStatus,
    dialogTitle: scripts.changeCustomerStatus,
    dialogTitleAlign: 'center',
    inputCheckResult: () => {
      return !!(
        leadStatus === transitionStatus && transitionNotes === getStatusNotes(leadStatus)
      )
        ? true
        : !!(transitionStatus && transitionNotes);
    },
    onSubmit: () => {
      // status or notes has been changed
      if (
        leadStatus !== transitionStatus ||
        transitionNotes !== getStatusNotes(leadStatus)
      ) {
        updateLeadMutation({
          variables: {
            leadId,
            leadInput: {
              transition: { status: transitionStatus, notes: transitionNotes },
            },
          },
        });
        operationLoading();
      }
    },
    onClose: () => {
      // set to initial
      setTransitionStatus(leadStatus);
      setTransitionNotes(getStatusNotes(leadStatus));
    },
    fullWidth: false,
    getCustomButton: (onClick) => (
      <Tooltip title={scripts.changeCustomerStatus}>
        <IconButton
          onClick={onClick}
          size={'small'}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            ':hover': {
              backgroundColor: '#08A590',
            },
            width: '40px',
            height: '40px',
          }}
        >
          <ChangeIcon width={16} height={16} color={isHovered ? 'white' : '#00A591'} />
        </IconButton>
      </Tooltip>
    ),
  });

  useEffect(() => {
    if (selectedLead) {
      setTransitionStatus(leadStatus);
      setTransitionNotes(getStatusNotes(leadStatus));
    }
  }, [selectedLead]);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <CustomAddOrEditDialog
        isAdd={false}
        {...dialogProps}
        showHoverBackgroundColor
        hoverBackgroundColor="#00A591"
      >
        <Grid xs={12}>
          <Stack spacing={4}>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid
                xs={12}
                container
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: 0,
                  padding: 0,
                  width: '315px',
                }}
              >
                <Grid
                  sx={{
                    padding: 0.5,
                  }}
                >
                  <StatusButton
                    buttonText="Lead"
                    isCurrentStatus={!!(transitionStatus === 'prospect')}
                    onClick={() => {
                      setTransitionStatus((prev) => {
                        if (prev !== 'prospect') {
                          setTransitionNotes(getStatusNotes(LeadStatus.Prospect));
                        }
                        return LeadStatus.Prospect;
                      });
                    }}
                  />
                </Grid>
                <Grid
                  sx={{
                    padding: 0.5,
                  }}
                >
                  <StatusButton
                    buttonText="Customer"
                    isCurrentStatus={!!(transitionStatus === 'customer')}
                    onClick={() => {
                      setTransitionStatus((prev) => {
                        if (prev !== 'customer') {
                          setTransitionNotes(getStatusNotes(LeadStatus.Customer));
                        }
                        return LeadStatus.Customer;
                      });
                    }}
                  />
                </Grid>
                <Grid
                  sx={{
                    padding: 0.5,
                  }}
                >
                  <StatusButton
                    buttonText="Lost Customer"
                    isCurrentStatus={!!(transitionStatus === 'lost_customer')}
                    onClick={() => {
                      setTransitionStatus((prev) => {
                        if (prev !== 'lost_customer') {
                          setTransitionNotes(getStatusNotes(LeadStatus.LostCustomer));
                        }
                        return LeadStatus.LostCustomer;
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Stack>
              <CustomTextField
                required
                firstInput
                label={scripts.notes}
                value={transitionNotes}
                setValue={setTransitionNotes}
                dialogProps={dialogProps}
                backgroundColor={'#F8F8F8'}
                borderLine={'false'}
                multiline={true}
              />
            </Stack>
          </Stack>
        </Grid>
      </CustomAddOrEditDialog>
      {dialogProps.isSubmit && (
        <OperationResultDialog data={data} error={error} loading={loading} />
      )}
    </>
  );
};

export default ChangeLeadStatusButton;
