import { useEffect, useState, useRef } from 'react';

import { Slider, Stack, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useParams } from 'react-router-dom';

import { useAudioLazyQuery } from '../../graphql/resolver.types';
import Login from '../../landing/views/Login';
import { useScripts } from '../../layout/utils/LanguageHelper';
import PauseIcon from '../../svg/PauseIcon';
import PlayIcon from '../../svg/PlayIcon';

interface SimpleAudioPlayerProps {
  audioSrc: string;
  audioName: string;
}

const SimpleAudioPlayer = ({ audioSrc, audioName }: SimpleAudioPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      const handleTimeUpdate = () => {
        setCurrentTime(audio.currentTime);
      };

      const handleLoadedMetadata = () => {
        setDuration(audio.duration);
      };

      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, []);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const audio = audioRef.current;
    if (audio && typeof newValue === 'number') {
      audio.currentTime = newValue;
      setCurrentTime(newValue);
    }
  };

  const handleEnded = () => {
    setIsPlaying(false);
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        padding: '16px',
        paddingRight: '24px',
        borderRadius: '10px',
        backgroundColor: '#FFF',
        width: '50%',
        height: '100%',
        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
        ':hover': {
          backgroundColor: '#FFF',
          border: '1px solid #08A590',
          color: '#08A590',
        },
      }}
    >
      <Stack direction="row" alignItems="center">
        <IconButton onClick={handlePlayPause}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </IconButton>
        <Typography>{formatTime(currentTime)}</Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
        sx={{ width: '30%' }}
      >
        <Slider
          min={0}
          max={duration}
          value={currentTime}
          onChange={handleSliderChange}
          sx={{
            width: '100%',
            height: '4px',
            '& .MuiSlider-thumb': {
              width: '8px',
              height: '8px',
              '&.Mui-active': {
                width: '20px',
                height: '20px',
              },
            },
            color: '#00A591',
          }}
        />
      </Stack>

      <audio ref={audioRef} src={audioSrc} onEnded={handleEnded}>
        <source src={`${audioSrc}`} />
      </audio>

      <Typography>{audioName}</Typography>
    </Grid>
  );
};

const SingleAudioPage = () => {
  const scripts = useScripts();
  const token = localStorage.getItem('token');

  const audioURL = window.location.href.slice(window.location.href.indexOf('/audio'));

  const { audioId = '' } = useParams();

  const [audioQuery, { data, loading, error }] = useAudioLazyQuery();

  useEffect(() => {
    if (token) {
      audioQuery({
        variables: {
          audioId,
        },
      });
    }
  }, [token, audioId]);

  return (
    <Grid container alignItems="center" justifyContent="center" mt="2em" width="100%">
      {loading ? (
        <Typography>{scripts.loading}</Typography>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{
            width: '80%',
          }}
        >
          {token ? (
            data?.audio ? (
              <SimpleAudioPlayer
                audioSrc={data.audio.audioUrl}
                audioName={data.audio.filename ?? ''}
              />
            ) : (
              <div>NO data found</div>
            )
          ) : (
            <Login url={audioURL} />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SingleAudioPage;
