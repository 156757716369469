import IconType from './IconType';

const KeyIcon = ({ width = 14, height = 14, color = '#00A591', style }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M8.13258 5.86742L8.57452 5.42548L8.13258 5.86742C8.45491 6.18975 8.89208 6.37083 9.34792 6.37083C9.80376 6.37083 10.2409 6.18975 10.5633 5.86742C10.8856 5.54509 11.0667 5.10792 11.0667 4.65208C11.0667 4.19624 10.8856 3.75907 10.5633 3.43674C10.2409 3.11442 9.80376 2.93333 9.34792 2.93333C8.89208 2.93333 8.45491 3.11442 8.13258 3.43674C7.81025 3.75907 7.62917 4.19624 7.62917 4.65208C7.62917 5.10792 7.81025 5.54509 8.13258 5.86742ZM13.375 4.8125C13.375 7.12513 11.5001 9 9.1875 9C8.92611 9 8.67018 8.97628 8.42253 8.93095C7.89697 8.83474 7.28548 8.94782 6.84626 9.38705L6.36665 9.86665C6.36242 9.87088 6.35528 9.875 6.34375 9.875H6.25C5.35254 9.875 4.625 10.6025 4.625 11.5V11.5938C4.625 11.6002 4.62383 11.6042 4.62266 11.607C4.62131 11.6102 4.61921 11.6134 4.61631 11.6163C4.61341 11.6192 4.61019 11.6213 4.607 11.6227C4.6042 11.6238 4.60017 11.625 4.59375 11.625H4.5C3.60254 11.625 2.875 12.3525 2.875 13.25V13.3438C2.875 13.3502 2.87383 13.3542 2.87266 13.357C2.87131 13.3602 2.86921 13.3634 2.86631 13.3663C2.86341 13.3692 2.86019 13.3713 2.857 13.3727C2.8542 13.3738 2.85017 13.375 2.84375 13.375H0.65625C0.649829 13.375 0.645798 13.3738 0.643004 13.3727C0.639812 13.3713 0.636589 13.3692 0.63369 13.3663C0.63079 13.3634 0.628688 13.3602 0.627343 13.357C0.626166 13.3542 0.625 13.3502 0.625 13.3438V11.1562C0.625 11.1447 0.629118 11.1376 0.633348 11.1333L4.61295 7.15374C5.05218 6.71452 5.16526 6.10303 5.06905 5.57747C5.02372 5.32982 5 5.07389 5 4.8125C5 2.49987 6.87487 0.625 9.1875 0.625C11.5001 0.625 13.375 2.49987 13.375 4.8125Z"
        stroke={color}
        strokeWidth="1.25"
      />
    </svg>
  );
};

export default KeyIcon;
