import { useEffect, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';

import { client } from '../apollo/apollo.client';
import { useScripts } from '../layout/utils/LanguageHelper';
import { resetUser } from '../redux/features/userSlice';
import { useAppDispatch } from '../redux/store';

type MutationResult = {
  data: any | null | undefined;
  error: any | undefined;
  loading: boolean;
  successMessage?: string;
  errorMessage?: string;
};

const OperationResultDialog = ({
  data,
  error,
  loading,
  successMessage,
  errorMessage,
}: MutationResult): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const scripts = useScripts();

  useEffect(() => {
    if (loading) {
      setOpen(true);
    }
  }, [loading]);

  useEffect(() => {
    if (open && !loading && data) {
      setSnackbarState({ ...snackbarState, open: true });
    }
  }, [data]);

  return (
    <>
      {(open && !data && (
        <Dialog
          open={open}
          maxWidth="xs"
          fullWidth={true}
          PaperProps={{ sx: { alignItems: 'center' } }}
        >
          {(loading && (
            <DialogContent
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <DialogContentText sx={{ marginBottom: 6 }}>
                {scripts.pleaseWait}
              </DialogContentText>
              <CircularProgress sx={{ marginBottom: 6 }} />
            </DialogContent>
          )) ||
            (error && (
              <>
                <DialogContent
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  <DialogContentText sx={{ marginBottom: 2 }}>
                    {errorMessage ? errorMessage : 'Operation failed.'}
                  </DialogContentText>
                  <Box>
                    <CancelIcon sx={{ fontSize: 60 }} color="error" />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpen(false);
                      const token = localStorage.getItem('token');
                      if (!token) {
                        dispatch(resetUser());
                        localStorage.clear();
                        client.resetStore();
                        client.clearStore();
                        navigate('/');
                      }
                    }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </>
            ))}
        </Dialog>
      )) ||
        ''}
      {(open && data && !error && snackbarState.open && (
        <Box sx={{ width: '100%', position: 'absolute' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarState.open}
            onClose={() => setSnackbarState({ ...snackbarState, open: false })}
            autoHideDuration={3000}
          >
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {successMessage ? successMessage : scripts.operationSuccessfully}
            </Alert>
          </Snackbar>
        </Box>
      )) ||
        ''}
    </>
  );
};

export default OperationResultDialog;
