import { useLayoutEffect } from 'react';

import Box from '@mui/material/Box';
import { cloneDeep, defaultsDeep } from 'lodash-es';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import { useScripts } from '../../../../layout/utils/LanguageHelper';
import { useStyle } from '../../context/StyleContext';

const landscapeProps: DraggableInitProps = {
  type: DraggableType.Square,
  tags: [DraggableTag.Elements],
  style: {
    width: '8.411274%',
    height: '14.953376%',
    color: '#808080',
    borderStyle: 'none',
    borderWidth: 0,
    borderRadius: 0,
    borderColor: '#000000',
  },
  lockAspectRatio: true,
};

export const defaultSquareProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '22.221027%',
      height: '12.499496%',
    },
  },
};

export default function Square(props: DraggableOuterProps) {
  const scripts = useScripts();

  const { layout, draggablesPropsRef } = useStyle();
  const defaultProps = layout ? defaultSquareProps[layout] : defaultSquareProps.landscape;

  const defaultSquare: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '10vmin',
      height: '10vmin',
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(cloneDeep(props), defaultSquare);

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep({
        ...props,
        selectedBar: {
          ...dragProps?.selectedBar,
          getDeleteLabel: () => scripts.deleteThisElement,
        },
      });
    }
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps}>
      <Box
        sx={[
          {
            width: '100%',
            height: '100%',
            backgroundColor: dragProps?.style?.color,
            borderStyle: dragProps?.style?.borderStyle,
            borderWidth: `${dragProps?.style?.borderWidth}px`,
            borderRadius: dragProps?.style?.borderRadius,
            borderColor: dragProps?.style?.borderColor,
          },
        ]}
      />
    </WrapperRND>
  );
}
