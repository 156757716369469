import IconType from './IconType';

const FillColorIcon = ({ width = 20, height = 20, color = '#000', style }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M5.25808 13.4986L2.25058 10.4911C1.24558 9.48613 1.24558 8.48863 2.25058 7.48363L7.26058 2.47363L12.7731 7.98616C13.0506 8.26366 13.0506 8.71363 12.7731 8.99113L8.25808 13.5061C7.26808 14.4961 6.26308 14.4961 5.25808 13.4986Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.2627 1.46387L7.2677 2.46884"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.55298 8.94322L12.893 8.44824"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 16.499H12"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1377 11.25C14.1377 11.25 12.7502 12.7575 12.7502 13.68C12.7502 14.445 13.3727 15.0675 14.1377 15.0675C14.9027 15.0675 15.5252 14.445 15.5252 13.68C15.5252 12.7575 14.1377 11.25 14.1377 11.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FillColorIcon;
