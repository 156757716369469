const MoreForTabletOrMobileSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 18V18.154" stroke="#090446" strokeWidth="3" strokeLinecap="round" />
      <path d="M13 10V10.154" stroke="#090446" strokeWidth="3" strokeLinecap="round" />
      <path d="M13 2V2.15398" stroke="#090446" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
};

export default MoreForTabletOrMobileSvg;
