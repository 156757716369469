// Ref: SideBar.tsx

import * as React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { useScripts } from '../../../layout/utils/LanguageHelper';
import { updateBarIcon, updateBarIsOpen } from '../../../redux/features/classSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import CheckGradesIcon from '../../../svg/CheckGradesIcon';
import ClassesIcon from '../../../svg/ClassesIcon';
import HomeworkIcon from '../../../svg/HomeworkIcon';
import StudentsOfClassesIcon from '../../../svg/StudentsOfClassesIcon';
import TestScoresIcon from '../../../svg/TestScoresIcon';

type itemsProps = {
  title: string;
  icon?: JSX.Element;
  onClick?: () => void;
};

const SIDEBAR_WIDTH = 180;

const openedMixin = (theme: Theme): CSSObject => ({
  width: SIDEBAR_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  height: `calc(${theme.spacing(7)})`,
  [theme.breakpoints.up('sm')]: { height: '93%' },
  overflowY: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)})`,
  height: `calc(${theme.spacing(7.1)})`,
  overflowY: 'hidden',
  [theme.breakpoints.up('sm')]: { height: '93%' },
  zIndex: 0,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: SIDEBAR_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
function ClassSideBar() {
  const scripts = useScripts();

  const isScreenUpSM = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isWidthDown1200 = useMediaQuery('(max-width:1200px)');

  const dispatch = useAppDispatch();
  const type = useAppSelector((state) => state.class.barIcon.type);

  const sideLayoutIsOpen = useAppSelector((state) => state.class.barIcon.open);

  const [open, setOpen] = React.useState(false);

  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
  const navigate = useNavigate();

  // lists
  const lists = [
    {
      title: scripts.classes,
      icon: <ClassesIcon />,
      onClick: () => navigate('/teacher/classes'),
    },
    {
      title: scripts.students,
      icon: <StudentsOfClassesIcon />,
      onClick: () => navigate('/teacher/students'),
    },
    {
      title: scripts.homework,
      icon: <HomeworkIcon />,
      onClick: () => navigate('/teacher/homework'),
    },
    {
      title: scripts.checkGrades,
      icon: <CheckGradesIcon />,
      onClick: () => navigate('/teacher/checkGrades'),
    },
    {
      title: scripts.testScores,
      icon: <TestScoresIcon />,
      onClick: () => navigate('/teacher/testScores'),
    },
  ];

  React.useEffect(() => {
    if (type) {
      for (let i = 0; i < lists.length; i++) {
        const moduleType = lists[i].title.replace(/\s/g, '');
        if (type === moduleType) {
          setSelectedIndex(i);
          dispatch(updateBarIsOpen(false));
        }
      }
    } else {
      setSelectedIndex(null);
    }
  }, [type]);
  // func
  function handleDrawerOpen() {
    setOpen(true);
    dispatch(updateBarIsOpen(true));
  }

  function handleDrawerClose() {
    setOpen(false);
    dispatch(updateBarIsOpen(false));
  }

  function items(list: itemsProps[]): React.ReactNode {
    return list.map(({ title, icon, onClick }, index) =>
      title === 'Divider' ? (
        <Divider key={`Divider${index}`} />
      ) : (
        <Tooltip key={title} title={open ? '' : title} placement="left">
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={index === selectedIndex}
              onClick={() => {
                if (onClick) {
                  onClick();
                }
                const moduleType = title.replace(/\s/g, '');
                if (sideLayoutIsOpen && type === moduleType) {
                  dispatch(updateBarIcon({ open: false, type: '' }));
                } else {
                  dispatch(updateBarIcon({ open: true, type: moduleType }));
                }
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </Tooltip>
      ),
    );
  }

  if (isWidthDown1200) {
    return <></>;
  }

  // main
  return (
    <Drawer
      anchor={isScreenUpSM ? 'left' : 'bottom'}
      variant="permanent"
      open={open && isScreenUpSM}
    >
      {isScreenUpSM && (
        <DrawerHeader>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              {isScreenUpSM ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              {isScreenUpSM ? (
                <ChevronRightIcon />
              ) : (
                <Typography
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 5,
                  }}
                >
                  <MenuIcon />
                  Menu
                </Typography>
              )}
            </IconButton>
          )}
        </DrawerHeader>
      )}
      <Divider />
      <List>{items(lists)}</List>
      {/* {isScreenUpSM ? (
        <List>{items(lists)}</List>
      ) : (
        <SwipeableDrawer
          anchor={'bottom'}
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          sx={{
            '.MuiDrawer-paper ': {
              height: '250px',
              bottom: 56,
            },
          }}
        >
          {items(lists)}
        </SwipeableDrawer>
      )} */}
    </Drawer>
  );
}
export default ClassSideBar;
