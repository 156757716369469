// ref: SelectClassroom
import { useEffect, useState } from 'react';

import CustomAutocomplete from '../../../globalComponents/CustomAutocomplete';
import { useSchoolsWithIdNameLazyQuery } from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';

type SelectClassroomProps = {
  schoolId?: string;
  required?: boolean;
  onChildSetClickedSchoolId: (arg: string) => void;
  reset?: (arg: string) => void;
  helperText?: string;
  startQuery?: boolean;
  onChildSetClickedClassSchoolId?: (arg: string) => void;
  willSubmit?: boolean;
  dataLoading?: boolean;
  resetArray?: ((arg: string) => void)[];
  onChildSetClickedSchoolName: (arg: string) => void;
};

const SelectSchool = ({
  schoolId,
  required = false,
  onChildSetClickedSchoolId,
  reset,
  helperText,
  startQuery,
  willSubmit = false,
  dataLoading,
  resetArray,
  onChildSetClickedSchoolName,
}: SelectClassroomProps) => {
  const scripts = useScripts();

  const [fetchUserSchools, { data: userSchoolsData, loading: userSchoolsLoading }] =
    useSchoolsWithIdNameLazyQuery();

  const [clickedSchoolId, setClickedSchoolId] = useState(schoolId);
  const [clickedSchoolName, setClickedSchoolName] = useState('');
  const clickedSchoolData = userSchoolsData?.schools.edges?.filter(
    ({ node }) => node.id === clickedSchoolId,
  );

  useEffect(() => {
    setClickedSchoolId(userSchoolsData?.schools?.edges?.[0]?.node.id);
    setClickedSchoolName(userSchoolsData?.schools?.edges?.[0]?.node.name || '');
  }, [userSchoolsData]);

  useEffect(() => {
    if (startQuery) {
      fetchUserSchools({ variables: { first: 0 } });
    }
  }, [startQuery]);

  useEffect(() => {
    if (reset) {
      reset('');
    }
    if (resetArray) {
      resetArray.forEach((fn) => {
        fn('');
      });
    }
    if (clickedSchoolId) {
      onChildSetClickedSchoolId(clickedSchoolId);
    }
    if (clickedSchoolId === '') {
      onChildSetClickedSchoolId('');
    }
  }, [clickedSchoolId]);

  useEffect(() => {
    if (clickedSchoolName) {
      onChildSetClickedSchoolName(clickedSchoolName);
    }
    if (clickedSchoolName === '') {
      onChildSetClickedSchoolName('');
    }
  }, [clickedSchoolName]);

  return (
    <CustomAutocomplete
      disabled={!userSchoolsData || dataLoading}
      loading={userSchoolsLoading}
      options={
        userSchoolsData?.schools.edges?.map(({ node }) => ({
          label: node.name,
          value: node.id,
        })) ?? []
      }
      value={
        clickedSchoolData?.map(({ node }) => ({
          label: node.name,
          value: node.id,
        })) ?? []
      }
      onChangeForSingleValue={(e, option) => {
        setClickedSchoolId(option?.value ?? '');
      }}
      label={scripts.school}
      fullWidth={true}
      dialogProps={{ willSubmit }}
      required={required}
      helperText={
        helperText
          ? helperText
          : willSubmit && !clickedSchoolId
          ? scripts.requiredForLeadData
          : ''
      }
      disableClearable
    />
  );
};

export default SelectSchool;
