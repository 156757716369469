import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum webMode {
  LIGHT = 'Light',
  DART = 'Dark',
  AUTO = 'Auto',
}

const webModeType = localStorage.getItem('theme') ?? 'Light';

const initialState = {
  currentMode: webModeType,
};

export const webModeSlice = createSlice({
  name: 'webMode',
  initialState,

  reducers: {
    updateWebMode: (state, action: PayloadAction<string>) => {
      state.currentMode = action.payload;
      localStorage.setItem('theme', action.payload);
    },
  },
});

export const { updateWebMode } = webModeSlice.actions;

export default webModeSlice.reducer;
