import { useEffect, useState } from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DesktopDatePicker,
  DesktopDatePickerSlotsComponentsProps,
} from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';

import { useScripts } from '../layout/utils/LanguageHelper';

type CustomDatePickerProps = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  startDate?: string;
  endDate?: string;
  label?: string;
  toolbarTitle?: string;
  required?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  size?: 'medium' | 'small';
  readOnly?: boolean;
  disabled?: boolean;
  doNotAutosaveOnSelect?: boolean;
  shouldDisableDate?: (date: dayjs.Dayjs) => boolean;
  dialogProps?: {
    willSubmit: boolean;
  };
  backgroundColor?: string;
  borderLine?: string;
  helperTextStyle?: React.CSSProperties;
};

const CustomDatePicker = ({
  dialogProps = { willSubmit: false },
  backgroundColor = '#FFF',
  shouldDisableDate,
  ...props
}: CustomDatePickerProps): JSX.Element => {
  const scripts = useScripts();

  const isScreenDownLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const [valueTemp, setValueTemp] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [isClickAway, setIsClickAway] = useState(false);
  useEffect(() => {
    if (props.doNotAutosaveOnSelect) {
      setValueTemp(props.value);
    } else {
      setValueTemp(null);
    }

    if (!isScreenDownLg) {
      setIsClickAway(false);
    }
  }, [props.value]);

  const label = props.label ? { label: props.label } : {};
  const required = { required: props.required ? true : false };
  const minDate = props.startDate ? { minDate: dayjs(props.startDate) } : {};
  const maxDate = props.endDate ? { maxDate: dayjs(props.endDate) } : {};
  const error = props.error
    ? {
        error:
          (props.error || (props.required ? !props.value : false)) &&
          dialogProps.willSubmit,
      }
    : { error: !!(props.required && !props.value && dialogProps.willSubmit) };
  const fullWidth = { fullWidth: !!props.fullWidth };
  const helperText = (
    <>
      {props.required && !props.value && dialogProps.willSubmit ? (
        <Typography variant="caption" sx={{ display: 'block' }}>
          {scripts.pleaseSelectADate}
        </Typography>
      ) : (
        ''
      )}
      {props.helperText ? (
        <Typography
          variant="caption"
          sx={{ display: 'block', ...props?.helperTextStyle }}
        >
          {props.helperText}
        </Typography>
      ) : (
        ''
      )}
    </>
  );
  const size: { size: 'medium' | 'small' } = props.size
    ? { size: props.size }
    : { size: 'medium' };
  const readOnly = { readOnly: !!props.readOnly };
  const disabled = { disabled: !!props.disabled };
  const doNotAutosaveOnSelect = props.doNotAutosaveOnSelect;

  const getSelectedDate = (event: Dayjs) => {
    const year = event.year();
    const month = event.month();
    const date = event.date();

    if (
      typeof year !== 'number' ||
      Number.isNaN(year) ||
      typeof month !== 'number' ||
      Number.isNaN(month) ||
      typeof date !== 'number' ||
      Number.isNaN(date)
    ) {
      return props.value;
    }

    const selectedDate = `${year}-${`0${month + 1}`.slice(-2)}-${`0${date}`.slice(-2)}`;

    return selectedDate;
  };

  const componentsProps: Partial<DesktopDatePickerSlotsComponentsProps> = {
    actionBar: {
      actions: ['accept', 'cancel'],
    },
  };
  const doNotAutosaveProps = doNotAutosaveOnSelect
    ? {
        closeOnSelect: false,
        onOpen: () => {
          if (!isScreenDownLg) {
            setIsClickAway(false);
          }

          if (isScreenDownLg) {
            setOpen(true);
          }
        },
        onClose: () => {
          if (!isScreenDownLg && isClickAway) {
            setValueTemp(props.value);
          }

          if (isScreenDownLg) {
            setOpen(false);
          }
        },
        onAccept: (event: Dayjs | null) => {
          if ((!isScreenDownLg && isClickAway) || !event) {
            return;
          }

          const selectedDate = getSelectedDate(event);
          setValueTemp(selectedDate);
          props.setValue(selectedDate);

          if (isScreenDownLg) {
            setOpen(false);
          }
        },
        componentsProps,
      }
    : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ClickAwayListener
        onClickAway={() => {
          if (!isScreenDownLg) {
            setIsClickAway(true);
          }
        }}
      >
        <FormControl {...fullWidth} {...required} {...disabled} {...error}>
          {!isScreenDownLg && (
            <DesktopDatePicker
              {...readOnly}
              {...disabled}
              {...minDate}
              {...maxDate}
              inputFormat="YYYY/MM/DD"
              value={doNotAutosaveOnSelect ? valueTemp : props.value}
              {...doNotAutosaveProps}
              onChange={(event: Dayjs | null) => {
                if (!event) {
                  return;
                }

                const selectedDate = getSelectedDate(event);
                if (doNotAutosaveOnSelect) {
                  setValueTemp(selectedDate);
                } else {
                  props.setValue(selectedDate);
                }

                setIsClickAway(false);
              }}
              components={{
                OpenPickerIcon: CalendarTodayIcon,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...required}
                  {...label}
                  {...size}
                  helperText={helperText}
                  {...error}
                  {...disabled}
                  sx={{
                    '& label.Mui-focused': {
                      color: '#00A591',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor:
                          props?.borderLine === 'false'
                            ? 'rgba(0, 0, 0, 0)'
                            : 'rgba(0, 0, 0, 0.23)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.87)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#00A591',
                      },
                    },
                    '& .MuiSvgIcon-root': {
                      '&:hover ': {
                        color: '#00A591',
                      },
                    },
                    '& .MuiFormHelperText-root': {
                      margin: 0,
                      backgroundColor: '#FFF',
                    },
                    backgroundColor: backgroundColor,
                    borderRadius: '5px',
                  }}
                />
              )}
              PopperProps={{ sx: { zIndex: 10000 } }}
              {...(shouldDisableDate ? { shouldDisableDate } : null)}
            />
          )}
          {isScreenDownLg && (
            <MobileDatePicker
              {...(doNotAutosaveOnSelect
                ? {
                    open,
                    DialogProps: {
                      onClose: () => {
                        setValueTemp(props.value);
                        setOpen(false);
                      },
                    },
                  }
                : null)}
              {...minDate}
              {...maxDate}
              inputFormat="YYYY/MM/DD"
              value={doNotAutosaveOnSelect ? valueTemp : props.value}
              {...doNotAutosaveProps}
              onChange={(event: Dayjs | null) => {
                if (!event) {
                  return;
                }

                const selectedDate = getSelectedDate(event);
                if (doNotAutosaveOnSelect) {
                  setValueTemp(selectedDate);
                } else {
                  props.setValue(selectedDate);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...required}
                  {...label}
                  {...size}
                  helperText={helperText}
                  {...error}
                  {...disabled}
                />
              )}
              toolbarTitle={props.toolbarTitle ?? 'Select date'}
              {...(shouldDisableDate ? { shouldDisableDate } : null)}
            />
          )}
        </FormControl>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
