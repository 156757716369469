import React, { useEffect } from 'react';

import type { FilterOptionsState, SxProps } from '@mui/material';
import Autocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import useUpdateFontStyle, { EditTextType } from './text/hooks/useUpdateFontStyle';
import { DraggableTag } from '../../../graphql/resolver.types';
import { updateDraggable } from '../../../redux/features/styleSlice';
import { useAppDispatch } from '../../../redux/store';
import { getFontSize, getFontWeight } from '../context/FontEditorContext';
import { useStyle } from '../context/StyleContext';
import { accept } from '../utils/draggables';

interface AutocompleteBaseProps {
  label: string;
  property: keyof React.CSSProperties;
  acceptTags: Array<DraggableTag>;
  options: Array<string>;
  sx?: SxProps;
  defaultValue?: string;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  disableClearable?: boolean;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: string,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  filterOptions?: (options: string[], state: FilterOptionsState<string>) => string[];
  readOnly?: boolean;
}

export default function AutocompleteBase({
  label,
  property,
  acceptTags,
  options,
  sx,
  defaultValue,
  renderInput,
  disableClearable,
  renderOption,
  filterOptions,
  readOnly = false,
}: AutocompleteBaseProps) {
  const dispatch = useAppDispatch();
  const { layout, selectedId, selected, setIsEditingText } = useStyle();
  const selectedProperty = selected?.style?.[property]?.toString();
  const isAcceptType = accept(acceptTags, selected?.tags);
  const [value, setValue] = React.useState<string | null>('');
  const [inputValue, setInputValue] = React.useState('');

  const { updateFontStyle: updateFontSize } = useUpdateFontStyle(EditTextType.FontSize);
  const { updateFontStyle: updateFonttWeight } = useUpdateFontStyle(
    EditTextType.FontWeight,
  );

  // func
  useEffect(() => {
    setInputValue(selectedProperty ?? defaultValue ?? '');
  }, [selectedProperty, selectedId]);
  const [open, setOpen] = React.useState(false);

  const isUpdateFont = () => property === 'fontSize' || property === 'fontWeight';

  const fillBorderInfo = (style: React.CSSProperties, inputValueNum: number) => {
    const borderStyle = selected?.style.borderStyle;
    const borderWidth = (selected?.style?.borderWidth as number) ?? 0;

    if (property === 'borderWidth') {
      style.borderWidth = inputValueNum;
      if (
        inputValueNum > 0 &&
        (!borderStyle || borderStyle === 'none') &&
        (!borderWidth || borderWidth === 0)
      ) {
        style.borderStyle = 'solid';
      } else if (inputValueNum === 0) {
        style.borderStyle = 'none';
      }
    } else if (
      property === 'borderRadius' &&
      selected?.tags.includes(DraggableTag.TextField)
    ) {
      style.borderRadius = inputValueNum;
    }
  };
  const fillFontSizeInfo = (style: React.CSSProperties, inputValueNum: number) => {
    if (property === 'fontSize') {
      style.fontSize = inputValueNum;

      const newFontSize = getFontSize(layout, inputValueNum);
      updateFontSize(newFontSize, EditTextType.FontSize);

      return true;
    }

    return false;
  };
  const fillFontWeightInfo = (style: React.CSSProperties, inputValueNum: number) => {
    if (property === 'fontWeight') {
      style.fontWeight = inputValueNum;

      const newFontWeight = getFontWeight(inputValueNum);
      updateFonttWeight(newFontWeight, EditTextType.FontWeight);

      return true;
    }

    return false;
  };

  // main
  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disabled={!isAcceptType}
      readOnly={readOnly}
      freeSolo
      disableClearable={disableClearable}
      size="small"
      value={value}
      onChange={(e: React.SyntheticEvent<Element, Event>, newValue: string | null) => {
        setValue(newValue);
      }}
      // onFocus={() => {
      //   if (!isUpdateFont()) {
      //     setIsEditingText(true);
      //   }
      // }}
      // onBlur={() => {
      //   if (!isUpdateFont()) {
      //     setIsEditingText(false);
      //   }
      // }}
      onKeyDown={(e) => e.stopPropagation()}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => {
        setOpen(false);
        const number = parseFloat(newInputValue); // filtering text, only accept number
        if (selectedId && Number.isFinite(number)) {
          const newValue = number.toString();
          setInputValue(newValue);
          setValue(newValue);

          if (selectedProperty !== newValue || isUpdateFont()) {
            const style: React.CSSProperties = { [property]: number };
            fillBorderInfo(style, number);

            let isEditFont = false;
            isEditFont = fillFontSizeInfo(style, number) || isEditFont;
            isEditFont = fillFontWeightInfo(style, number) || isEditFont;

            // avoid same value dispatch
            if (!isEditFont) {
              dispatch(
                updateDraggable({
                  id: selectedId,
                  style,
                }),
              );
            }
          }
        } else if (newInputValue === '') {
          setInputValue(''); // for UX, display ''
        }
      }}
      options={options}
      renderInput={renderInput ?? ((params) => <TextField {...params} label={label} />)}
      renderOption={renderOption}
      sx={sx}
      filterOptions={filterOptions}
    />
  );
}
