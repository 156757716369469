import { useLayoutEffect } from 'react';

import { cloneDeep, defaultsDeep } from 'lodash-es';

import { DraggableOuterProps, DraggableInnerProps } from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import {
  DraggableType,
  DraggableTag,
  PositionAlign,
} from '../../../../graphql/resolver.types';
import IncorrectIcon from '../../../../img/style/option/incorrect.png';
import { useStyle } from '../../context/StyleContext';
import { Button } from '../interactive/Button';

export default function FalseAnswer(props: DraggableOuterProps) {
  const defaultFalseAnswer: DraggableInnerProps = {
    type: DraggableType.FalseAnswer,
    tags: [DraggableTag.Button],
    style: {
      width: '15vmin',
      height: '15vmin',
    },
    lockAspectRatio: true,
    answer: {
      correct: false,
      correctCheckBoxPosition: PositionAlign.TopLeft,
    },
    originWrapperStyle: {
      backgroundColor: '#f3f3f3',
      borderRadius: '6px',
      padding: 1,
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultFalseAnswer,
  );

  const { draggablesPropsRef } = useStyle();
  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep(props);
    }
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps}>
      <Button src={IncorrectIcon} />
    </WrapperRND>
  );
}
