import IconType from './IconType';

const ChangeIcon = ({ width = 14, height = 14, color = '#FFF', style }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.877392 2.62595H9.62398V0.876332C9.62398 0.523674 9.83717 0.203822 10.1652 0.0671326C10.4932 -0.0695564 10.8676 0.00698945 11.1191 0.255763L13.7431 2.88019C13.9071 3.04422 14 3.26566 14 3.49803C14 3.7304 13.9071 3.95183 13.7431 4.11586L11.1191 6.74029C10.8676 6.9918 10.4932 7.06561 10.1652 6.92892C9.83717 6.79223 9.62398 6.47511 9.62398 6.11972V4.37557H0.877392C0.393596 4.37557 0.00273331 3.98464 0.00273331 3.50076C0.00273331 3.01688 0.393596 2.62595 0.877392 2.62595ZM13.1226 9.62443C13.6064 9.62443 13.9973 10.0154 13.9973 10.4992C13.9973 10.9831 13.6064 11.374 13.1226 11.374H4.37603V13.1237C4.37603 13.4763 4.16283 13.7962 3.83483 13.9329C3.50683 14.0696 3.13237 13.993 2.88091 13.7442L0.256931 11.1198C0.0929325 10.9558 0 10.7343 0 10.502C0 10.2696 0.0929325 10.0482 0.256931 9.88414L2.88091 7.25971C3.13237 7.0082 3.50683 6.93439 3.83483 7.07108C4.16283 7.20777 4.37603 7.52489 4.37603 7.88028V9.6299H13.1226V9.62443Z"
        fill={color}
      />
    </svg>
  );
};

export default ChangeIcon;
