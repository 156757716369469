import React from 'react';

import { useWarningModalContext } from './useWarningModalHooks';
import { useSigninMutation } from '../graphql/resolver.types';
import { updateUser, updateLoginError } from '../redux/features/userSlice';
import { useAppSelector, useAppDispatch } from '../redux/store';
import { Role, Group } from '../types/types.users';

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const warningModal = useWarningModalContext();

  const [signinMutation, { data, error, loading }] = useSigninMutation({
    // onError(error) {
    //   if (error.graphQLErrors[0].extensions.errorname === 'FORBIDDEN') {
    //     warningModal?.setModalState({
    //       alertTitle: 'SORRY',
    //       alertMessage: `${error?.graphQLErrors?.[0].message}`,
    //       show: true,
    //       cancel: false,
    //     });
    //   }
    // },
  });

  const isAuth = useAppSelector((state) => state.user.isAuth);
  const role = useAppSelector((state) => state.user.role);
  const group = useAppSelector((state) => state.user.group);
  const username = useAppSelector((state) => state.user.username);
  const name = useAppSelector((state) => state.user.name);
  const token = useAppSelector((state) => state.user.token);
  const tickets = useAppSelector((state) => state.user.tickets);
  const loginError = useAppSelector((state) => state.user.loginError);

  const errorname = error?.graphQLErrors?.[0].extensions?.errorname;
  // Access control: role only
  const isSuper = role === 'super';
  const isAdmin = role === 'admin';
  const isTeacher = role === 'teacher';
  const isPrincipal = role === 'principal';
  const isSecretary = role === 'secretary';
  const isStudent = role === 'student';
  // Access control check function
  const canEdit = (...roles: boolean[]) => roles.findIndex((isAuth) => isAuth) > -1;
  // Access control: roles
  const isManager = isSuper || isAdmin;
  const isSchoolManager = isPrincipal || isSecretary;
  const isEducator = isSchoolManager || isTeacher;
  const isCustomer = isEducator || isStudent;
  const isAll = isManager || isCustomer;
  const isStaff = isManager || isEducator; // or !isStudent? avoid exclude expression
  // Access control: role: return current role string
  const superRole = isSuper ? role : null;
  const adminRole = isAdmin ? role : null;
  const teacherRole = isTeacher ? role : null;
  const principalRole = isPrincipal ? role : null;
  const secretaryRole = isSecretary ? role : null;
  const studentRole = isStudent ? role : null;
  // Access control: roles
  const managerRole = superRole ?? adminRole;
  const schoolManagerRole = principalRole ?? secretaryRole;
  const educatorRole = schoolManagerRole ?? teacherRole;
  const customerRole = educatorRole ?? studentRole;
  const allRole = managerRole ?? customerRole;
  const staffRole = educatorRole ?? managerRole;

  React.useEffect(() => {
    if (!loading) {
      if (error) {
        dispatch(updateLoginError(error.message.replace('AuthError: ', '')));
      } else if (data?.signin?.accessToken) {
        const signin = data.signin;

        const usetData = {
          userName: data.signin.name,
          userId: data.signin.id,
          userRole: data.signin.role,
        };

        localStorage.setItem('token', signin.accessToken);
        localStorage.setItem('user', JSON.stringify(usetData));

        dispatch(
          updateUser({
            userId: signin.masterId,
            isAuth: true,
            role: signin.role as Role,
            group: signin.group as Group,
            username: signin.username,
            name: signin.name,
            email: signin.email,
            token: signin.accessToken,
            tickets: signin.tickets,
            loginError: null,
          }),
        );
      }
    }
  }, [data, error, loading]);

  const adminTeacherText = 'Admin Teacher';
  const secretaryText = 'secretary';
  const roleText = role === secretaryText ? adminTeacherText : role;
  const getRoleText = (role: string) =>
    role === secretaryText ? adminTeacherText : role;

  return {
    isAuth,
    role,
    roleText,
    group,
    username,
    name,
    tickets,
    token,
    loginError,
    signinMutation,
    loading,
    error,
    isUserNameError: errorname === 'NON_EXISTENT_ACCOUNT',
    isPasswordError: errorname === 'INCORRECT_PASSWORD',
    isError: error ? true : false, // other error
    // Access control,
    isSuper,
    isAdmin,
    isTeacher,
    isPrincipal,
    isSecretary,
    isStudent,
    isManager,
    isSchoolManager,
    isEducator,
    isCustomer,
    isAll,
    isStaff,
    canEdit,
    superRole,
    adminRole,
    teacherRole,
    principalRole,
    secretaryRole,
    studentRole,
    managerRole,
    schoolManagerRole,
    educatorRole,
    customerRole,
    allRole,
    staffRole,
    getRoleText,
  };
};
