import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import { Coordinates } from '@dnd-kit/utilities';
import { Rnd } from 'react-rnd';
import { useParams, useLocation } from 'react-router-dom';

import {
  DraggableTag,
  DraggableType,
  Layout,
  UpdateStyleMutationVariables,
} from '../../../graphql/resolver.types';
import {
  ClickedArea,
  Draggable,
  deleteDraggable,
  layerSwap,
  updateSelected,
  updateSideLayout,
} from '../../../redux/features/styleSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { LayoutsType } from '../../../toolBar/component/ToolSideLayout';
import {
  DraggableInnerProps,
  DraggableOuterProps,
} from '../components/draggable/components/DraggableOrigin';
import { getDraggableById, getDraggableIndexById } from '../utils/draggables';

type UpdateToolItem = {
  sendTouchesLoc: (x1: number, y1: number, x2: number, y2: number) => void;
  updateLoc: () => void;
  setStartResize: (startResize: boolean) => void;
};
export type ToolItemSize = {
  left: number;
  top: number;
  width: number;
  height: number;
};

interface StyleContextProps {
  layout: Layout;
  draggables: Draggable[];
  clickedArea?: ClickedArea;
  hasBackground: boolean;
  // selected props
  selectedId?: string;
  selected?: Draggable;
  selectedIndex: number;
  selectedIsFront: boolean;
  selectedIsBack: boolean;
  selectedEl: HTMLElement | null;
  setSelectedEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  selectedIsDragging: boolean;
  setSelectedIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  selectedIsResizing: boolean;
  setSelectedIsResizing: React.Dispatch<React.SetStateAction<boolean>>;
  selectedIsGroupOfGroup?: boolean;
  // others
  subGroup?: Draggable;
  isNewStyle: boolean;
  isTeachStyle: boolean;
  isEditingText: boolean;
  setIsEditingText: React.Dispatch<React.SetStateAction<boolean>>;
  notes?: string;
  droppableRef: React.RefObject<HTMLDivElement> | null;
  isEditingMediaRef: React.MutableRefObject<boolean> | null;
  backgroundColor: string | null;
  groupSelectorRef: React.RefObject<Rnd> | null;
  groupAdjusterRef: React.RefObject<Rnd> | null;
  draggableMapRef: React.MutableRefObject<{
    [key: string]: {
      ref: React.RefObject<Rnd>;
      type: DraggableType;
      setIsMultipleSelected: React.Dispatch<React.SetStateAction<boolean>>;
      isMultipleSelected: boolean;
      setIsEditingGroup: React.Dispatch<React.SetStateAction<boolean>>;
      isTextType: boolean;
      isSubDraggable: boolean;
      style?: React.CSSProperties;
      // isTemp: boolean;
    };
  }> | null;
  headerRef: React.RefObject<HTMLDivElement> | null;
  draggablesPropsRef: React.MutableRefObject<{
    [key: string]: DraggableOuterProps & DraggableInnerProps['selectedBar'];
  }> | null;
  hasRightClickMenuOpenRef: React.MutableRefObject<boolean> | null;
  videoCardIndex: number;
  setVideoCardIndex: React.Dispatch<React.SetStateAction<number>>;
  hasDialogOpenRef: React.MutableRefObject<boolean> | null;
  defineCompletelyDeleteDraggable: (id: string) => void;
  getParentId: (draggableId: string, draggables?: Draggable[]) => string;
  isUploadingAnswer: boolean;
  setIsUploadingAnswer: React.Dispatch<React.SetStateAction<boolean>>;
  isRecording: boolean;
  setIsRecording: React.Dispatch<React.SetStateAction<boolean>>;
  sendToolItemDeltaRef: React.MutableRefObject<(delta: Coordinates) => void> | null;
  pushToolItemRef: React.MutableRefObject<(draggable: JSX.Element | null) => void> | null;
  toolItemSerialRef: React.MutableRefObject<number> | null;
  mouseLocRef: React.MutableRefObject<{
    x: number;
    y: number;
  }> | null;
  touchLocRef: React.MutableRefObject<{
    x: number;
    y: number;
  }> | null;
  mouseStartLocRef: React.MutableRefObject<{
    x: number;
    y: number;
  }> | null;
  touchStartLocRef: React.MutableRefObject<{
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  }> | null;
  updateToolItemRef: React.MutableRefObject<UpdateToolItem[]> | null;
  refreshToolItemRef: React.MutableRefObject<(index: number) => void> | null;
  removeToolItemRef: React.MutableRefObject<(index: number) => void> | null;
  toolItemSizeRef: React.MutableRefObject<ToolItemSize[]> | null;
  toolSideBarLayoutRef: React.RefObject<HTMLDivElement> | null;
  toolItemResizingRef: React.MutableRefObject<boolean> | null;
  toolItemDraggingRef: React.MutableRefObject<boolean> | null;
  restoreRecordingCounter: number;
  setRestoreRecordingCounter: React.Dispatch<React.SetStateAction<number>>;
  sideBarRef: React.RefObject<HTMLDivElement> | null;
  largeImageMapRef: React.MutableRefObject<{
    [key: string]: string;
  }> | null;
  bringForward: () => void;
  bringToFront: () => void;
  sendBackward: () => void;
  sendToBack: () => void;
  showLayers: () => void;
  notShownWhenSavingClass: string;
  originDraggablesRef: React.MutableRefObject<Draggable[]> | null;
  imageBlobRef: React.MutableRefObject<Blob | null> | null;
  updateStyleVariablesRef: React.MutableRefObject<UpdateStyleMutationVariables | null> | null;
  tempStyleIdRef: React.MutableRefObject<string | null> | null;
  getIndexedDBVariablesFieldName: (styleId: string) => string;
  getIndexedDBScreenshotFieldName: (styleId: string) => string;
}

const defaultValue: StyleContextProps = {
  layout: Layout.Landscape,
  draggables: [],
  clickedArea: undefined,
  hasBackground: false,
  selectedId: undefined,
  selected: undefined,
  selectedIndex: -1,
  selectedIsFront: false,
  selectedIsBack: false,
  selectedEl: null,
  setSelectedEl: () => undefined,
  selectedIsDragging: false,
  setSelectedIsDragging: () => undefined,
  selectedIsResizing: false,
  setSelectedIsResizing: () => undefined,
  selectedIsGroupOfGroup: false,
  subGroup: undefined,
  isNewStyle: false,
  isTeachStyle: false,
  isEditingText: false,
  setIsEditingText: () => undefined,
  notes: '',
  droppableRef: null,
  isEditingMediaRef: null,
  backgroundColor: null,
  groupSelectorRef: null,
  groupAdjusterRef: null,
  draggableMapRef: null,
  headerRef: null,
  draggablesPropsRef: null,
  hasRightClickMenuOpenRef: null,
  videoCardIndex: -1,
  setVideoCardIndex: () => undefined,
  hasDialogOpenRef: null,
  defineCompletelyDeleteDraggable: (id: string) => {
    return;
  },
  getParentId: () => '',
  isUploadingAnswer: false,
  setIsUploadingAnswer: () => undefined,
  isRecording: false,
  setIsRecording: () => undefined,
  sendToolItemDeltaRef: null,
  pushToolItemRef: null,
  toolItemSerialRef: null,
  mouseLocRef: null,
  touchLocRef: null,
  mouseStartLocRef: null,
  touchStartLocRef: null,
  updateToolItemRef: null,
  refreshToolItemRef: null,
  removeToolItemRef: null,
  toolItemSizeRef: null,
  toolSideBarLayoutRef: null,
  toolItemResizingRef: null,
  toolItemDraggingRef: null,
  restoreRecordingCounter: 0,
  setRestoreRecordingCounter: () => undefined,
  sideBarRef: null,
  largeImageMapRef: null,
  bringForward: () => {
    return;
  },
  bringToFront: () => {
    return;
  },
  sendBackward: () => {
    return;
  },
  sendToBack: () => {
    return;
  },
  showLayers: () => {
    return;
  },
  notShownWhenSavingClass: '',
  originDraggablesRef: null,
  imageBlobRef: null,
  updateStyleVariablesRef: null,
  tempStyleIdRef: null,
  getIndexedDBVariablesFieldName: (styleId: string) => '',
  getIndexedDBScreenshotFieldName: (styleId: string) => '',
};

const StyleContext = createContext(defaultValue);
export const useStyle = () => {
  const context = useContext(StyleContext);
  if (!context && typeof window !== 'undefined') {
    throw new Error(`useStyle must be used within a StyleContext `);
  }
  return context;
};

interface StyleProviderProps {
  children: React.ReactNode;
}

function IsSelectedGroupOfGroup(selected: Draggable | undefined) {
  return selected &&
    selected.tags.includes(DraggableTag.GroupOfGroup) &&
    'group' in selected &&
    selected.group
    ? true
    : false;
}

function getSubGroupByPage(selected: Draggable | undefined) {
  const selectedIsGroupOfGroup = IsSelectedGroupOfGroup(selected);
  if (!selectedIsGroupOfGroup) return;

  const target = selected as DraggableInnerProps;
  return target.page !== undefined &&
    'group' in target &&
    target.group &&
    target.group[target.page]
    ? target.group[target.page]
    : undefined;
}

export const StyleProvider = ({ children }: StyleProviderProps) => {
  const dispatch = useAppDispatch();
  const layout = useAppSelector((state) => state.style.present.layout);
  const draggables = useAppSelector((state) => state.style.present.draggables[layout]);
  const draggablesRef = useRef(draggables);
  draggablesRef.current = draggables;
  const clickedArea = useAppSelector(
    (state) => state.style.present.selected[layout]?.clickedArea,
  );
  const hasBackground = draggables?.[0]?.type === DraggableType.Background;
  // selected props
  const selectedId = useAppSelector((state) => state.style.present.selected[layout]?.id);
  const selected = getDraggableById(selectedId, draggables);
  const selectedIdRef = useRef(selectedId);
  selectedIdRef.current = selectedId;
  const selectedIndex = selectedId ? getDraggableIndexById(selectedId, draggables) : -1;
  const selectedIsFront = selectedIndex === draggables.length - 1;
  const selectedIsBack =
    selectedIndex === (hasBackground && draggables.length > 0 ? 1 : 0);
  const [selectedEl, setSelectedEl] = React.useState<HTMLElement | null>(null); // Meaningful only when selectedId exists
  const [selectedIsDragging, setSelectedIsDragging] = React.useState(false);
  const [selectedIsResizing, setSelectedIsResizing] = React.useState(false);
  const selectedIsGroupOfGroup = IsSelectedGroupOfGroup(selected);
  // others
  const subGroup = getSubGroupByPage(selected); // only return DraggableGroup when selectedIsGroupOfGroup is true, other is undefined
  const { styleId = '' } = useParams();
  const isNewStyle = styleId === 'new';
  const { pathname } = useLocation();
  const isTeachStyle = pathname?.includes('editTeach'); // false is LearnStyle (editLearn)
  const [isEditingText, setIsEditingText] = React.useState(false); // Can not be put into the style redux, it is hard to handle with history(redo/undo)
  const notes = useAppSelector((state) => state.style.present.notes);
  const droppableRef = React.useRef<HTMLDivElement>(null);
  const isEditingMediaRef = React.useRef<boolean>(false);
  const backgroundColor =
    hasBackground && draggables?.[0]?.style?.color
      ? `${draggables[0].style.color}`
      : null;
  const groupSelectorRef = useRef<Rnd>(null);
  const groupAdjusterRef = useRef<Rnd>(null);
  const draggableMapRef = useRef<{
    [key: string]: {
      ref: React.RefObject<Rnd>;
      type: DraggableType;
      setIsMultipleSelected: React.Dispatch<React.SetStateAction<boolean>>;
      isMultipleSelected: boolean;
      setIsEditingGroup: React.Dispatch<React.SetStateAction<boolean>>;
      isTextType: boolean;
      isSubDraggable: boolean;
      style?: React.CSSProperties;
      // isTemp: boolean;
    };
  }>({});
  const headerRef = useRef<HTMLDivElement>(null);
  const draggablesPropsRef = useRef<{
    [key: string]: DraggableOuterProps;
  }>({});
  const hasRightClickMenuOpenRef = useRef(false);
  const [videoCardIndex, setVideoCardIndex] = React.useState(-1); // selected video card index
  const hasDialogOpenRef = useRef(false);
  const defineCompletelyDeleteDraggable = (id: string) => {
    draggablesPropsRef.current[id]?.group?.forEach(({ id: draggableId }) => {
      const draggable = draggablesPropsRef.current[draggableId];
      if (draggable) {
        draggable.selectedBar = {
          ...draggable?.selectedBar,
          delete: () => {
            dispatch(deleteDraggable({ id }));
            dispatch(updateSelected({ id: '', clickedArea: ClickedArea.Style }));
          },
          getDeleteLabel: undefined,
        };
      }
    });
  };

  const getParentId = (draggableId: string, draggableArr?: Draggable[]) => {
    // ### get draggables parent ID
    let parentId = '';
    let hasFound = false;

    const draggableList = draggableArr ?? draggables;

    const search = (draggables: Draggable[]) => {
      for (let i = 0; i < draggables.length && !hasFound; i++) {
        if (draggables[i].id === draggableId) {
          hasFound = true;
          return;
        }

        const group = draggables[i]?.group;
        if (Array.isArray(group)) {
          search(group);
        }
      }
    };

    for (let i = 0; i < draggableList.length && !hasFound; i++) {
      parentId = draggableList[i].id;
      if (draggableId === parentId) {
        return parentId;
      }

      const group = draggableList[i]?.group;
      if (Array.isArray(group)) {
        search(group);
      }
    }

    return hasFound ? parentId : '';
  };

  const [isUploadingAnswer, setIsUploadingAnswer] = React.useState(false);
  const [isRecording, setIsRecording] = React.useState(false);

  const sendToolItemDeltaRef = useRef((delta: Coordinates) => {
    return;
  });
  const pushToolItemRef = useRef((draggable: JSX.Element | null) => {
    return;
  });
  const toolItemSerialRef = useRef(0);
  const mouseLocRef = useRef({ x: -1, y: -1 });
  const touchLocRef = useRef({ x: -1, y: -1 });
  const mouseStartLocRef = useRef({ x: -1, y: -1 });
  const touchStartLocRef = useRef({ x1: -1, y1: -1, x2: -1, y2: -1 });
  const updateToolItemRef = useRef<UpdateToolItem[]>([]);
  const refreshToolItemRef = useRef((index: number) => {
    return;
  });
  const removeToolItemRef = useRef((index: number) => {
    return;
  });
  const toolItemSizeRef = useRef<ToolItemSize[]>([]);
  const toolSideBarLayoutRef = useRef<HTMLDivElement>(null);
  const toolItemResizingRef = useRef(false);
  const toolItemDraggingRef = useRef(false);

  const [restoreRecordingCounter, setRestoreRecordingCounter] = useState(0);

  const sideBarRef = useRef<HTMLDivElement>(null);

  const largeImageMapRef = useRef<{ [key: string]: string }>({});

  useEffect(() => {
    if (restoreRecordingCounter > 0) {
      setIsUploadingAnswer(false);
      setIsRecording(false);
      setSelectedEl(null);
    }
  }, [restoreRecordingCounter]);

  const bringForward = () => {
    const id = selectedIdRef.current;
    const draggables = draggablesRef.current;
    const currentIndex = draggables.findIndex(
      ({ id: draggableId }) => draggableId === id,
    );
    const nextIndex = currentIndex + 1;
    if (nextIndex >= draggables.length) {
      return;
    }

    dispatch(
      layerSwap({
        from: draggables[currentIndex].id,
        to: draggables[nextIndex].id,
      }),
    );
  };
  const bringToFront = () => {
    const id = selectedIdRef.current;
    const draggables = draggablesRef.current;
    const lastIndex = draggables.length - 1;
    if (draggables.length <= 0) {
      return;
    }
    const currentIndex = draggables.findIndex(
      ({ id: draggableId }) => draggableId === id,
    );

    dispatch(
      layerSwap({
        from: draggables[currentIndex].id,
        to: draggables[lastIndex].id,
      }),
    );
  };
  const sendBackward = () => {
    const id = selectedIdRef.current;
    const draggables = draggablesRef.current;
    const currentIndex = draggables.findIndex(
      ({ id: draggableId }) => draggableId === id,
    );
    const prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      return;
    }

    dispatch(
      layerSwap({
        from: draggables[currentIndex].id,
        to: draggables[prevIndex].id,
      }),
    );
  };
  const sendToBack = () => {
    const id = selectedIdRef.current;
    const draggables = draggablesRef.current;
    if (!draggables?.[0]) {
      return;
    }
    const currentIndex = draggables.findIndex(
      ({ id: draggableId }) => draggableId === id,
    );

    dispatch(
      layerSwap({
        from: draggables[currentIndex].id,
        to: draggables[0].id,
      }),
    );
  };
  const showLayers = () => {
    const layersKey: keyof LayoutsType = 'Layers';
    dispatch(updateSideLayout({ open: true, type: layersKey }));
  };

  const notShownWhenSavingClass = 'not-shown-when-saving';
  const originDraggablesRef = useRef([...draggables]);
  const imageBlobRef = useRef<Blob | null>(null);
  const updateStyleVariablesRef = useRef<UpdateStyleMutationVariables | null>(null);
  const tempStyleIdRef = useRef<string | null>(null);

  const getIndexedDBVariablesFieldName = (styleId: string) =>
    `musen-style-${styleId}-variables`;
  const getIndexedDBScreenshotFieldName = (styleId: string) =>
    `musen-style-${styleId}-screenshot`;

  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      const code = e.code?.toLowerCase();
      const isKeyDigit1 =
        code === 'Numpad1'.toLowerCase() || code === 'Digit1'.toLowerCase();

      if (e.altKey && isKeyDigit1) {
        showLayers();
      }
    };
    window.addEventListener('keydown', keydown);

    return () => window.removeEventListener('keydown', keydown);
  }, []);

  useEffect(() => {
    const isMac = /macintosh|mac os x/i.test(navigator?.userAgent ?? '');
    const id = selectedId;
    const isSubDraggable =
      draggables.findIndex((draggable) => draggable.id === id) === -1;
    if (!id || isSubDraggable) {
      return;
    }

    const keydown = (e: KeyboardEvent) => {
      const code = e.code?.toLowerCase();
      const isKeyBracketLeft = code === 'BracketLeft'.toLowerCase();
      const isKeyBracketRight = code === 'BracketRight'.toLowerCase();

      const isCombo1 = (!isMac && e.ctrlKey) || (isMac && e.metaKey);
      const isCombo2 = ((!isMac && e.ctrlKey) || (isMac && e.metaKey)) && e.altKey;

      if (isKeyBracketRight) {
        if (isCombo2) {
          bringToFront();
        } else if (isCombo1) {
          bringForward();
        }
      } else if (isKeyBracketLeft) {
        if (isCombo2) {
          sendToBack();
        } else if (isCombo1) {
          e.preventDefault();
          sendBackward();
        }
      }
    };
    window.addEventListener('keydown', keydown);

    return () => window.removeEventListener('keydown', keydown);
  }, [selectedId, draggables]);

  // main
  return (
    <StyleContext.Provider
      value={{
        layout,
        draggables,
        clickedArea,
        hasBackground,
        selectedId,
        selected,
        selectedIndex,
        selectedIsFront,
        selectedIsBack,
        selectedEl,
        setSelectedEl,
        selectedIsDragging,
        setSelectedIsDragging,
        selectedIsResizing,
        setSelectedIsResizing,
        selectedIsGroupOfGroup,
        subGroup,
        isNewStyle,
        isTeachStyle,
        isEditingText,
        setIsEditingText,
        notes,
        droppableRef,
        isEditingMediaRef,
        backgroundColor,
        groupSelectorRef,
        groupAdjusterRef,
        draggableMapRef,
        headerRef,
        draggablesPropsRef,
        hasRightClickMenuOpenRef,
        videoCardIndex,
        setVideoCardIndex,
        hasDialogOpenRef,
        defineCompletelyDeleteDraggable,
        getParentId,
        isUploadingAnswer,
        setIsUploadingAnswer,
        isRecording,
        setIsRecording,
        sendToolItemDeltaRef,
        pushToolItemRef,
        toolItemSerialRef,
        mouseLocRef,
        touchLocRef,
        mouseStartLocRef,
        touchStartLocRef,
        updateToolItemRef,
        refreshToolItemRef,
        removeToolItemRef,
        toolItemSizeRef,
        toolSideBarLayoutRef,
        toolItemResizingRef,
        toolItemDraggingRef,
        restoreRecordingCounter,
        setRestoreRecordingCounter,
        sideBarRef,
        largeImageMapRef,
        bringForward,
        bringToFront,
        sendBackward,
        sendToBack,
        showLayers,
        notShownWhenSavingClass,
        originDraggablesRef,
        imageBlobRef,
        updateStyleVariablesRef,
        tempStyleIdRef,
        getIndexedDBVariablesFieldName,
        getIndexedDBScreenshotFieldName,
      }}
    >
      {children}
    </StyleContext.Provider>
  );
};
