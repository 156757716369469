import React from 'react';

import Box, { BoxProps } from '@mui/material/Box';

// import { useIsOverflow } from '../../../hooks/overflowHooks';

const CenterBox = React.forwardRef<HTMLDivElement, BoxProps>(function CenterBox(
  { sx, ...props },
  ref,
) {
  const _sx = Array.isArray(sx) ? sx : [sx];

  return (
    <Box
      ref={ref}
      sx={[
        {
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        ..._sx,
      ]}
      {...props}
    />
  );
});

CenterBox.displayName = 'CenterBox';
export default CenterBox;

// export const ScrollableCenterBox = ({ sx, ...others }) => {
//   const _sx = Array.isArray(sx) ? sx : [sx];
//   // hooks
//   const ref = useRef(null);
//   const isOverflow = useIsOverflow(ref);
//   return (
//     <CenterBox
//       ref={ref}
//       sx={[
//         {
//           overflowY: 'auto',
//         },
//         isOverflow && { alignItems: 'flex-start' }, // important for overflowY
//         ..._sx,
//       ]}
//       {...others}
//     />
//   );
// };
