import { useRef } from 'react';

import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import useUpdateFontStyle, {
  EditTextAlignBlock,
  EditTextType,
} from './hooks/useUpdateFontStyle';
import { useScripts } from '../../../../layout/utils/LanguageHelper';
import { useStyle } from '../../context/StyleContext';
import { TextAlign } from '../FontAlign';

type FontAlignButtonProps = {
  textAlign: TextAlign;
  setCurrentFontAlign: React.Dispatch<React.SetStateAction<TextAlign | null>>;
  currentFontAlign: TextAlign | null;
};

const FontAlignButton = ({
  textAlign,
  setCurrentFontAlign,
  currentFontAlign,
}: FontAlignButtonProps) => {
  const scripts = useScripts();

  const { selectedId } = useStyle();
  const { updateContentBlockAlign } = useUpdateFontStyle(EditTextType.FontAlign);

  const isLeftRef = useRef(textAlign === 'left');
  const isLeft = isLeftRef.current;
  const isCenterRef = useRef(textAlign === 'center');
  const isCenter = isCenterRef.current;
  const isRightRef = useRef(textAlign === 'right');
  const isRight = isRightRef.current;

  const titleRef = useRef(
    isLeft
      ? scripts.alignLeft
      : isCenter
      ? scripts.alignHorizontalCenter
      : isRight
      ? scripts.alignRight
      : '',
  );
  const iconRef = useRef(
    isLeft ? (
      <FormatAlignLeftIcon />
    ) : isCenter ? (
      <FormatAlignCenterIcon />
    ) : isRight ? (
      <FormatAlignRightIcon />
    ) : null,
  );
  const button = (
    <IconButton
      onClick={() => {
        if (isLeft) {
          updateContentBlockAlign(EditTextAlignBlock.Left);
        } else if (isCenter) {
          updateContentBlockAlign(EditTextAlignBlock.Center);
        } else if (isRight) {
          updateContentBlockAlign(EditTextAlignBlock.Right);
        }

        setCurrentFontAlign(textAlign);
      }}
      disabled={!selectedId}
      {...(currentFontAlign === textAlign ? { sx: { bgcolor: '#cbcbcb' } } : null)}
    >
      {iconRef.current}
    </IconButton>
  );

  return selectedId ? <Tooltip title={titleRef.current}>{button}</Tooltip> : button;
};

export default FontAlignButton;
