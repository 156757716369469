import { useState } from 'react';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { default as Grid } from '@mui/material/Unstable_Grid2';

import AddEventButton from './AddEventButton';
import {
  CustomAddOrEditDialog,
  CustomTable,
  CustomTextField,
  OperationResultDialog,
} from '../../../globalComponents';
import { useOperationResultContext } from '../../../globalContexts/OperationResultContext';
import { useDialog } from '../../../globalHooks';
import {
  useAddEventRecordMutation,
  LeadForLeadsFragment,
  useEventsLazyQuery,
} from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';

type AddEventAttendedButtonProps = {
  schoolId: string;
  selectedLead: LeadForLeadsFragment;
};

type LeadTableProps = {
  types: (
    | { fieldName: keyof LeadForLeadsFragment; fieldNameLabel: string }
    | { cellName: string; label?: string }
  )[];
  customCell?: { [key: string]: any };
};

const filter = createFilterOptions<{
  label: string;
  value: string;
  inputValue?: string;
}>();

const AddEventAttendedButton = ({
  schoolId,
  selectedLead,
}: AddEventAttendedButtonProps): JSX.Element => {
  const scripts = useScripts();

  const leadId = selectedLead.id;
  const { operationLoading, operationSuccess, commonErrorMessages } =
    useOperationResultContext();

  // states
  const [event, setEvent] = useState<{
    label: string;
    value: string;
    inputValue?: string;
  }>({
    label: '',
    value: '',
  });
  const [callBy, setCallBy] = useState('');
  const [eventNotes, setEventNotes] = useState('');

  // graphql
  const [
    fetchEvents,
    { data: eventsData, loading: eventsLoading, updateQuery: updateEventsQuery },
  ] = useEventsLazyQuery();
  const [addEventRecordMutation, { data, error, loading }] = useAddEventRecordMutation({
    update(cache, { data }) {
      if (!data) {
        return;
      }

      cache.modify({
        fields: {
          eventRecords(existingEventRecords) {
            const newResult = { ...existingEventRecords };
            newResult.edges = [
              {
                __typename: 'EventRecordEdge',
                cursor: '',
                node: data.addEventRecord,
              },
              ...newResult.edges,
            ];
            newResult.pageInfo = {
              ...newResult.pageInfo,
              itemTotal: newResult.pageInfo.itemTotal + 1,
            };

            return newResult;
          },
        },
      });
    },
    onCompleted() {
      operationSuccess();
    },
    onError(error) {
      commonErrorMessages(error);
    },
  });

  // lead info table
  const types: LeadTableProps['types'] = [
    { fieldName: 'name', fieldNameLabel: scripts.parentName },
    { fieldName: 'phone', fieldNameLabel: scripts.phone },
    { cellName: 'childrenNames', label: scripts.childsNames },
    { cellName: 'childrenAge', label: scripts.childsAge },
    { cellName: 'childrenSchool', label: scripts.school },
  ];
  const customCell: LeadTableProps['customCell'] = {
    childrenNames: selectedLead.children.map((child, index) => (
      <Typography key={index} variant="body2">
        {child.englishName + (child.name ? ', ' : '') + child.name}
      </Typography>
    )),
    childrenAge: selectedLead.children.map((child, index) => (
      <Typography key={index} variant="body2">
        {child.age}
      </Typography>
    )),
    childrenSchool: selectedLead.children.map((child, index) => (
      <Typography key={index} variant="body2">
        {child.schoolName}
      </Typography>
    )),
  };

  const { dialogProps } = useDialog({
    tooltipTitle: scripts.addEvent,
    dialogTitle: scripts.addEventAttended,
    dialogTitleAlign: 'center',
    justifyContent: 'center',
    inputCheckResult: () => !!(event?.value && callBy && eventNotes),
    onSubmit: () => {
      addEventRecordMutation({
        variables: {
          leadId,
          eventRecordInput: {
            eventId: event.value,
            callBy,
            notes: eventNotes,
          },
        },
      });
      operationLoading();
    },
    onClose: () => {
      setEvent({
        label: '',
        value: '',
      });
      setCallBy('');
      setEventNotes('');
    },
    fullWidth: false,
  });

  return (
    <>
      <CustomAddOrEditDialog
        isAdd={true}
        {...dialogProps}
        showHoverBackgroundColor
        addIcon
      >
        <Grid xs={12} sx={{ justifyContent: 'center' }}>
          <Stack spacing={4}>
            <CustomTable
              sx={{
                marginBottom: '10px',
                '& th': {
                  fontWeight: '700',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  textAlign: 'center',
                  p: 1,
                },
                '& td': {
                  fontWeight: '400',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  textAlign: 'center',
                  p: 1,
                },
              }}
              tableHead={[
                ...types.map((type) =>
                  'fieldNameLabel' in type ? type.fieldNameLabel : type.label ?? '',
                ),
              ]}
              tableBody={[
                {
                  ...Object.assign({
                    key: selectedLead.id,
                    content: [
                      ...types.map((type) => {
                        if ('fieldName' in type) {
                          return selectedLead[type.fieldName];
                        } else if (type.cellName) {
                          return customCell[type.cellName];
                        }
                      }),
                    ],
                  }),
                },
              ]}
              lastBorderNone={true}
            />
            <Autocomplete
              size="medium"
              clearOnBlur
              selectOnFocus
              disableClearable
              handleHomeEndKeys
              disabled={eventsLoading}
              options={
                (eventsData?.events.edges?.map(({ node: { id, name } }) => ({
                  label: name,
                  value: id,
                })) ?? [{ label: '', value: '' }]) as {
                  label: string;
                  value: string;
                  inputValue?: string;
                }[]
              }
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    label: `Add "${inputValue}"`,
                    value: '',
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }

                return option.label;
              }}
              onChange={(_, newValue) => {
                if (newValue) setEvent(newValue);
              }}
              onFocusCapture={() => fetchEvents({ variables: { first: 0, schoolId } })}
              renderOption={(props, option) => <li {...props}>{option.label}</li>}
              renderInput={(params) => {
                const isEmptyValue = !event.value && dialogProps.willSubmit;
                const helperText = isEmptyValue ? (
                  <Typography variant="caption" sx={{ display: 'block' }}>
                    {scripts.pleaseChooseAEvent}
                  </Typography>
                ) : (
                  ''
                );

                return (
                  <TextField
                    {...params}
                    required
                    autoFocus
                    label={scripts.event}
                    disabled={eventsLoading}
                    helperText={helperText}
                    error={!!isEmptyValue}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {!!eventsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    sx={{
                      '& label.Mui-focused': {
                        color: '#00A591',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.87)',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#00A591',
                        },
                        backgroundColor: '#F8F8F8',
                      },
                      '& .MuiSvgIcon-root': {
                        '&:hover ': {
                          color: '#00A591',
                        },
                      },
                    }}
                  />
                );
              }}
              ListboxProps={{
                style: {
                  maxHeight: '100px',
                },
              }}
            />
            <AddEventButton
              schoolId={schoolId}
              inputValue={event.inputValue}
              setInputValue={setEvent}
              updateQuery={updateEventsQuery}
            />
            <CustomTextField
              required
              label={scripts.callBy}
              value={callBy}
              setValue={setCallBy}
              dialogProps={dialogProps}
              backgroundColor={'#F8F8F8'}
              borderLine={'false'}
            />
            <CustomTextField
              required
              label={scripts.notes}
              value={eventNotes}
              setValue={setEventNotes}
              dialogProps={dialogProps}
              backgroundColor={'#F8F8F8'}
              borderLine={'false'}
              multiline={true}
            />
          </Stack>
        </Grid>
      </CustomAddOrEditDialog>
      {dialogProps.isSubmit && (
        <OperationResultDialog data={data} error={error} loading={loading} />
      )}
    </>
  );
};

export default AddEventAttendedButton;
