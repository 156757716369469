import MenuItem from './MenuItem';
import { MenuItemProps } from '../components/MainMenu';

const ClassesMenuItem = ({ selected, isMobile, isMore }: MenuItemProps) => {
  return (
    <MenuItem
      itemType="Classes"
      initWidth={24}
      initHeight={24}
      selected={selected}
      isMobile={isMobile}
      isMore={isMore}
    />
  );
};

export default ClassesMenuItem;
