import { useLayoutEffect, useRef } from 'react';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { genKey } from 'draft-js';
import { cloneDeep, defaultsDeep } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { clozeTestChild } from './ClozeTestAnswer';
import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import PageBar from './components/PageBar';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import { useScripts } from '../../../../layout/utils/LanguageHelper';
import {
  Draggable,
  deleteDraggable,
  updateDraggables,
} from '../../../../redux/features/styleSlice';
import { useAppDispatch } from '../../../../redux/store';
import { getDefaultFontSizeNum } from '../../context/FontEditorContext';
import { useStyle } from '../../context/StyleContext';
import { getDraggables } from '../../utils/draggables';
import { getInitEditorJson } from '../text/hooks/useUpdateFontStyle';

export const clozeTestGroupChild: Draggable[] = [
  {
    id: '',
    type: DraggableType.ClozeTestAnswer,
    tags: [DraggableTag.Group],
    name: '',
    style: {
      width: '100%',
      height: '100%',
      left: '0%',
      top: '0%',
    },
    lockAspectRatio: false,
    group: clozeTestChild,
  },
];

const landscapeProps: DraggableInitProps = {
  type: DraggableType.ClozeTestAnswerGroup,
  tags: [DraggableTag.GroupOfGroup],
  style: {
    width: '28.87112%',
    height: '51.71717%',
  },
  lockAspectRatio: false,
  group: [
    {
      ...clozeTestGroupChild[0],
      style: {
        ...clozeTestGroupChild[0].style,
        width: '28.87112%',
        height: '51.71717%',
        left: '0%',
        top: '0%',
      },
      group: [
        {
          ...clozeTestChild[0],
          style: {
            ...clozeTestChild[0].style,
            width: '25.98401%',
            height: '15.51515%',
            left: '1.44355%',
            top: '1.03434%',
          },
        },
        {
          ...clozeTestChild[1],
          style: {
            ...clozeTestChild[1].style,
            width: '23.0969%',
            height: '10.34343%',
            left: '4.33066%',
            top: '25.85858%',
          },
        },
        {
          ...clozeTestChild[2],
          style: {
            ...clozeTestChild[2].style,
            width: '23.0969%',
            height: '10.34343%',
            left: '4.33066%',
            top: '38.78787%',
          },
        },
      ],
    },
  ],
  page: 0,
};

export const defaultClozeTestAnswerGroupProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '77.778972%',
      height: '43.752469%',
    },
    group: [
      {
        ...clozeTestGroupChild[0],
        style: {
          ...clozeTestGroupChild[0].style,
          width: '77.778972%',
          height: '43.752469%',
          left: '0%',
          top: '0%',
        },
        group: [
          {
            ...clozeTestChild[0],
            style: {
              ...clozeTestChild[0].style,
              width: '70.001075%',
              height: '13.12574%',
              left: '3.888948%',
              top: '0.875049%',
            },
          },
          {
            ...clozeTestChild[1],
            style: {
              ...clozeTestChild[1].style,
              width: '62.223177%',
              height: '8.750493%',
              left: '11.666845%',
              top: '21.876234%',
            },
          },
          {
            ...clozeTestChild[2],
            style: {
              ...clozeTestChild[2].style,
              width: '62.223177%',
              height: '8.750493%',
              left: '11.666845%',
              top: '32.814351%',
            },
          },
        ],
      },
    ],
  },
};

export default function ClozeTestAnswerGroup(props: DraggableOuterProps) {
  const scripts = useScripts();

  const { layout, droppableRef, draggables, draggablesPropsRef, draggableMapRef } =
    useStyle();
  const draggablesRef = useRef(draggables);
  draggablesRef.current = draggables;
  const defaultProps = layout
    ? cloneDeep(defaultClozeTestAnswerGroupProps)[layout]
    : cloneDeep(defaultClozeTestAnswerGroupProps).landscape;

  const defaultClozeTestAnswerGroup: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '35vmin',
      height: '35vmin',
    },
    group: props.isClone ? undefined : clozeTestGroupChild,
    originWrapperStyle: {
      height: 'auto',
      backgroundColor: '#f3f3f3',
      borderRadius: '6px',
    },
    selectedBar: {
      delete: true,
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultClozeTestAnswerGroup,
  );
  const pageIndex = dragProps?.page ?? 0;

  const getNum = (num: number) => Number.parseFloat(num.toFixed(8));

  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const id = props.id;
    if (!id || !draggablesPropsRef) {
      return;
    }

    const rnd = draggableMapRef?.current?.[id]?.ref?.current;
    if (!rnd) {
      return;
    }

    const containerWidth = droppableRef?.current?.offsetWidth ?? 0;
    const containerHeight = droppableRef?.current?.offsetHeight ?? 0;
    const defaultStyle = defaultClozeTestAnswerGroupProps[layout].style;
    const defaultGroup = defaultClozeTestAnswerGroupProps?.[layout]?.group?.[0];
    const originHeight =
      getNum(Number.parseFloat(`${defaultStyle?.height ?? 0}`) / 100) * containerHeight;
    const originWidth =
      getNum(Number.parseFloat(`${defaultStyle?.width ?? 0}`) / 100) * containerWidth;
    const optionRelativeHeight = getNum(
      Number.parseFloat(`${defaultGroup?.group?.[1]?.style?.height}`) / 100,
    );
    const relativeWidth1 = getNum(
      Number.parseFloat(`${defaultGroup?.group?.[1]?.style?.width}`) / 100,
    );
    const relativeHeight1 = getNum(
      Number.parseFloat(`${defaultGroup?.group?.[1]?.style?.height}`) / 100,
    );
    const relativeX1 = getNum(
      Number.parseFloat(`${defaultGroup?.group?.[1]?.style?.left}`) / 100,
    );
    const relativeY1 = getNum(
      Number.parseFloat(`${defaultGroup?.group?.[1]?.style?.top}`) / 100,
    );
    const relativeY2 = getNum(
      Number.parseFloat(`${defaultGroup?.group?.[2]?.style?.top}`) / 100,
    );
    const pt = Math.abs(
      containerHeight * relativeY2 -
        containerHeight * relativeY1 -
        containerHeight * optionRelativeHeight,
    );
    const headerHeightWithPt = Math.abs(relativeY1 * containerHeight - pt);
    const optionHeightWithPt = Math.abs(
      containerHeight * relativeY2 - containerHeight * relativeY1,
    );

    draggablesPropsRef.current[id] = cloneDeep({
      ...props,
      selectedBar: {
        ...dragProps.selectedBar,
        getAddGroupChildLabel: () => scripts.addANewOption,
        getAddGroupLabel: () => scripts.addANewQuestion,
        // lockAspectRatio: false,
      },
    });

    const draggablesProp = draggablesPropsRef.current[id];
    const firstDraggable = draggablesProp?.group?.[pageIndex]?.group?.[0];
    const firstDraggableId = draggablesProp?.group?.[pageIndex]?.group?.[0]?.id;
    if (!firstDraggable || !firstDraggableId) {
      return;
    }

    draggablesPropsRef.current[firstDraggableId].selectedBar = {
      delete:
        (draggablesProp?.group ?? [])?.length === 1 &&
        (draggablesProp?.group?.[0]?.group?.length ?? 1) === 1
          ? () => {
              dispatch(deleteDraggable({ id: props?.id ?? '' }));
            }
          : false,
    };

    const optionDraggableArr = (draggablesProp?.group?.[pageIndex]?.group ?? []).slice(1);
    const sortedDraggables = [...optionDraggableArr].sort(
      (x, y) => Number.parseFloat(`${x.style.top}`) - Number.parseFloat(`${y.style.top}`),
    );

    const selectedBar = draggablesProp?.selectedBar;
    if (!selectedBar) {
      return;
    }
    selectedBar.addGroupChild = () => {
      const sampleOption =
        defaultClozeTestAnswerGroupProps?.[layout]?.group?.[0]?.group?.[1];
      if (!sampleOption) {
        return;
      }

      let draggable = draggables.find((draggable) => draggable.id === props.id);
      if (!draggable) {
        return;
      }

      draggable = cloneDeep(draggable);
      const group = draggable.group?.[pageIndex];
      if (!group) {
        return;
      }

      const optionRnd =
        sortedDraggables.length > 0
          ? draggableMapRef?.current?.[sortedDraggables[sortedDraggables.length - 1].id]
              ?.ref?.current
          : null;
      const lastOptionBottom = optionRnd
        ? getNum(
            Number.parseFloat(`${optionRnd.props.position?.y ?? 0}`) +
              Number.parseFloat(`${optionRnd.props.size?.height ?? 0}`),
          )
        : headerHeightWithPt;

      group.group?.push({
        ...sampleOption,
        id: uuidv4(),
        style: {
          ...sampleOption.style,
          top: `${getNum(((pt + lastOptionBottom) / containerHeight) * 100)}%`,
          ...(sortedDraggables.length > 1
            ? (() => {
                const lastOption = sortedDraggables[sortedDraggables.length - 1];
                const style = lastOption.style;

                if (!style) {
                  return {};
                }

                return {
                  left: style.left,
                  width: style.width,
                  height: style.height,
                };
              })()
            : {
                left: `${getNum(
                  ((((containerWidth * relativeX1) / originWidth) * originWidth) /
                    containerWidth) *
                    100,
                )}%`,
                width: `${getNum(
                  ((((containerWidth * relativeWidth1) / originWidth) * originWidth) /
                    containerWidth) *
                    100,
                )}%`,
                height: `${getNum(
                  ((((containerHeight * relativeHeight1) / originHeight) * originHeight) /
                    containerHeight) *
                    100,
                )}%`,
              }),
        },
      });

      draggable.style = {
        ...draggable.style,
        height: `${getNum(
          ((headerHeightWithPt +
            (group?.group ?? [])
              .slice(1)
              .reduce<number>(
                (prev, draggable) =>
                  prev +
                  pt +
                  (Number.parseFloat(`${draggable?.style?.height ?? 0}`) / 100) *
                    containerHeight,
                0,
              ) +
            (originHeight - headerHeightWithPt - optionHeightWithPt * 2)) /
            containerHeight) *
            100,
        )}%`,
      };
      group.style = {
        ...group.style,
        height: draggable.style.height,
      };

      if (
        getNum(
          Number.parseFloat(`${draggable.style.top ?? 0}`) +
            Number.parseFloat(`${draggable.style.height ?? 0}`),
        ) > 100
      ) {
        return;
      }

      dispatch(
        updateDraggables(
          (draggablesRef.current ?? []).map((d) =>
            d.id === draggable?.id ? draggable : d,
          ),
        ),
      );
    };
    selectedBar.addGroup = () => {
      let draggable = draggables.find((draggable) => draggable.id === props.id);
      if (!draggable) {
        return;
      }

      draggable = cloneDeep(draggable);
      const defaultProps = defaultClozeTestAnswerGroupProps?.[layout];
      let newGroup = defaultProps?.group?.[0];
      if (newGroup) {
        newGroup = cloneDeep(newGroup);
        newGroup.id = uuidv4();
        (newGroup?.group ?? [])[0].id = uuidv4();
        (newGroup?.group ?? [])[0].style = {
          ...(newGroup?.group ?? [])[0].style,
          color: getInitEditorJson(
            layout,
            (newGroup?.group ?? [])[0].name ?? '',
            getDefaultFontSizeNum(layout),
            genKey(),
          ),
        };
        (newGroup?.group ?? [])[1].id = uuidv4();
        (newGroup?.group ?? [])[2].id = uuidv4();
        draggable.group?.push(newGroup);

        const style = draggable.style;
        if (style) {
          style.width = defaultProps?.style?.width;
          style.height = defaultProps?.style?.height;
        }

        draggable.page = (draggable.group?.length ?? 1) - 1;

        dispatch(
          updateDraggables(
            (draggablesRef.current ?? []).map((d) =>
              d.id === draggable?.id ? draggable : d,
            ),
          ),
        );
      }
    };

    sortedDraggables.forEach((draggable1, index1, array) => {
      draggablesPropsRef.current[draggable1.id].selectedBar = {
        getDeleteLabel: () => scripts.deleteThisOption,
        delete: () => {
          let originDraggable = draggables.find((draggable) => draggable.id === props.id);
          if (!originDraggable) {
            return;
          }

          originDraggable = cloneDeep(originDraggable);
          const group = originDraggable.group?.[pageIndex];
          if (!group) {
            return;
          }

          const childGroup = array.flatMap((draggable2, index2) => {
            if (draggable2.id === draggable1.id) {
              return [];
            }

            const style = draggable2?.style;

            if (index1 < index2 && style) {
              const sortedDraggable = sortedDraggables?.[index2 - 1];
              if (sortedDraggable) {
                style.top = `${
                  ((draggableMapRef?.current?.[sortedDraggable.id]?.ref?.current?.props
                    ?.position?.y ?? 0) /
                    containerHeight) *
                  100
                }%`;
              }
            }

            return draggable2;
          });

          group.group = childGroup;
          const newParentHeight =
            headerHeightWithPt +
            childGroup.reduce<number>(
              (prev, { id }) =>
                prev +
                pt +
                Number.parseFloat(
                  `${
                    draggableMapRef?.current?.[id]?.ref?.current?.props?.size?.height ?? 0
                  }`,
                ),
              0,
            ) +
            (originHeight - headerHeightWithPt - optionHeightWithPt * 2);

          childGroup.unshift(firstDraggable);

          group.style = {
            ...group?.style,
            height: `${Number.parseFloat(
              `${((newParentHeight / containerHeight) * 100).toFixed(8)}`,
            )}%`,
          };

          (originDraggable.style ?? {}).height = group.style.height;

          dispatch(
            updateDraggables(
              (draggablesRef.current ?? []).map((d) =>
                d.id === originDraggable?.id ? originDraggable : d,
              ),
            ),
          );
        },
      };
    });
  }, [
    dragProps,
    pageIndex,
    dragProps?.group?.length,
    dragProps?.group?.[pageIndex]?.group?.length,
  ]);

  // main
  return (
    <WrapperRND {...dragProps}>
      {dragProps.isClone &&
      'group' in dragProps &&
      dragProps?.group?.[pageIndex] &&
      'group' in dragProps.group[pageIndex] &&
      dragProps.group[pageIndex].group ? (
        <>
          {getDraggables(dragProps?.group?.[pageIndex]?.group ?? [])}
          <PageBar
            id={dragProps.id}
            subId={dragProps.group[pageIndex].id}
            page={pageIndex}
            maxPage={dragProps.group.length}
            dragProps={dragProps}
          />
        </>
      ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1.5}
          p={1}
          width="100%"
          height="100%"
        >
          <CenterBox
            sx={{
              height: '6vmin',
              backgroundColor: '#d9d9d9',
              borderRadius: '10px',
            }}
          >
            <Typography
              component={Box}
              p={1}
              sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}
            >
              I like to ___ candy and ___ .
            </Typography>
          </CenterBox>
          {[0, 1].map((serial) => (
            <Stack
              key={serial}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              width="100%"
              height="5vmin"
            >
              <CheckBoxOutlineBlankIcon
                sx={{
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
              />
              <CenterBox
                sx={{
                  backgroundColor: '#d9d9d9',
                  borderRadius: '10px',
                }}
              >
                <Typography
                  component={Box}
                  p={1}
                  sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}
                >
                  Please enter an answer
                </Typography>
              </CenterBox>
            </Stack>
          ))}
        </Stack>
      )}
    </WrapperRND>
  );
}
