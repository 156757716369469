import IconType from './IconType';

const LayerBringForwardIcon = ({
  width = 23,
  height = 23,
  color = '#000',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10.7589 11.9133L10.7595 11.913C11.2289 11.6957 11.7711 11.6957 12.2405 11.913L12.2411 11.9133L22.061 16.4509L22.0628 16.4517C22.1748 16.5031 22.25 16.6165 22.25 16.7494C22.25 16.8824 22.1748 16.9958 22.0628 17.0472L22.061 17.048L12.2411 21.5856L12.2405 21.5858C11.7711 21.8032 11.2289 21.8032 10.7595 21.5858L10.7589 21.5856L0.942065 17.0494C0.822338 16.9923 0.75 16.8742 0.75 16.7494C0.75 16.6192 0.826827 16.5024 0.937208 16.4517L0.93901 16.4509L10.7589 11.9133Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M12.7071 0.292893C12.3166 -0.0976311 11.6834 -0.0976311 11.2929 0.292893L4.92893 6.65685C4.53841 7.04738 4.53841 7.68054 4.92893 8.07107C5.31946 8.46159 5.95262 8.46159 6.34315 8.07107L12 2.41421L17.6569 8.07107C18.0474 8.46159 18.6805 8.46159 19.0711 8.07107C19.4616 7.68054 19.4616 7.04738 19.0711 6.65685L12.7071 0.292893ZM13 17V1H11L11 17H13Z"
        fill={color}
      />
    </svg>
  );
};

export default LayerBringForwardIcon;
