import { useEffect, useState } from 'react';

import { getFontAlignForToolbar } from './draggable/components/TextFieldBase';
import FontAlignButton from './text/FontAlignButton';
import { useFontEditorContext } from '../context/FontEditorContext';
import { useStyle } from '../context/StyleContext';

export type TextAlign = 'left' | 'center' | 'right';

const FontAlign = () => {
  const { isEditingText, selected } = useStyle();
  const { editorRefs, setFontAlignRef } = useFontEditorContext();
  const [currentFontAlign, setCurrentFontAlign] = useState<TextAlign | null>(null);

  useEffect(() => {
    if (setFontAlignRef) {
      setFontAlignRef.current = (fontAlign: 'left' | 'center' | 'right' | null) => {
        setCurrentFontAlign(fontAlign);
      };

      const editorState = editorRefs?.editorStateRef.current;
      if (editorState) {
        setCurrentFontAlign(getFontAlignForToolbar(editorState, isEditingText));
      }

      return () => {
        setFontAlignRef.current = null;
      };
    }
  }, [selected?.style]);

  return (
    <>
      <FontAlignButton
        textAlign="left"
        setCurrentFontAlign={setCurrentFontAlign}
        currentFontAlign={currentFontAlign}
      />
      <FontAlignButton
        textAlign="center"
        setCurrentFontAlign={setCurrentFontAlign}
        currentFontAlign={currentFontAlign}
      />
      <FontAlignButton
        textAlign="right"
        setCurrentFontAlign={setCurrentFontAlign}
        currentFontAlign={currentFontAlign}
      />
    </>
  );
};

export default FontAlign;
