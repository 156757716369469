import { useEffect, useLayoutEffect, useState } from 'react';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { cloneDeep, defaultsDeep } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import TextFieldBase from './components/TextFieldBase';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import { useAuth } from '../../../../globalHooks/useAuth';
import {
  DraggableType,
  DraggableTag,
  PositionAlign,
  Layout,
} from '../../../../graphql/resolver.types';
import { getDefaultFontSizeNum } from '../../context/FontEditorContext';
import { useStyle } from '../../context/StyleContext';

const landscapeProps: DraggableInitProps = {
  type: DraggableType.TextCircleAnswer,
  tags: [DraggableTag.TextField],
  name: 'Your answer',
  style: {
    width: '10.80482%',
    height: '19.208569%',
    color: '#000000',
    textAlign: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
    fontFamily: 'Roboto',
    backgroundColor: '#d9d9d9',
    borderStyle: 'none',
    borderWidth: 0,
    borderRadius: '50%',
    borderColor: '#000000',
  },
  lockAspectRatio: true,
  answer: {
    correct: false,
    correctCheckBoxPosition: PositionAlign.TopLeft,
  },
};

export const defaultTextCircleAnswerProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '28.889844%',
      height: '16.250755%',
    },
  },
};

export default function TextCircleAnswer(props: DraggableOuterProps) {
  const { layout, draggablesPropsRef } = useStyle();

  const { isStudent } = useAuth();
  const [serial, setSerial] = useState(uuidv4());

  const defaultProps = layout
    ? defaultTextCircleAnswerProps[layout]
    : defaultTextCircleAnswerProps.landscape;

  const paddingTop = '24px';

  const defaultTextCircleAnswer: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '15vmin',
      height: `calc(15vmin + ${paddingTop})`,
      borderRadius: '6px',
    },
    originWrapperStyle: {
      paddingTop,
      backgroundColor: '#f3f3f3',
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultTextCircleAnswer,
  );

  useEffect(() => {
    if (isStudent) {
      setSerial(uuidv4());
    }
  }, []);

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = props;
    }
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps} {...(isStudent ? { key: serial } : null)}>
      {dragProps.isClone ? (
        <TextFieldBase {...dragProps} multiline={true} />
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          p={1}
          width="100%"
          height="100%"
        >
          <CenterBox
            position="relative"
            bgcolor={defaultProps.style?.backgroundColor ?? '#d9d9d9'}
            borderRadius={defaultProps.style?.borderRadius ?? '50%'}
          >
            <Typography
              component={Box}
              sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}
            >
              <CheckBoxOutlineBlankIcon
                sx={{
                  position: 'absolute',
                  left: '0px',
                  top: `-${paddingTop}`,
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
              />
              {dragProps?.name}
            </Typography>
          </CenterBox>
        </Stack>
      )}
    </WrapperRND>
  );
}
