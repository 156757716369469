import React, { createContext, useContext, useState } from 'react';

export type WarningModal = {
  alertTitle?: string;
  alertMessage: string;
  show: boolean;
  confirm?: (() => void) | null;
  cancel: boolean;
};

export type WarningStateProps = {
  modalState: WarningModal;
  setModalState: (modalState: WarningModal) => void;
};
type Props = {
  children: React.ReactNode;
};

const WarningModalContext = createContext<WarningStateProps | null>(null);

export const WarningModalContextProvider = ({ children }: Props) => {
  const [modalState, setModalState] = useState<WarningModal>({
    alertTitle: 'Notification',
    alertMessage: 'Something Error',
    show: false,
    confirm: null,
    cancel: false,
  });

  const providerValue: WarningStateProps = {
    modalState,
    setModalState,
  };

  return (
    <WarningModalContext.Provider value={providerValue}>
      {children}
    </WarningModalContext.Provider>
  );
};

export const useWarningModalContext = () => {
  return useContext(WarningModalContext);
};
