import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Ticket } from '../../graphql/resolver.types';
import { User, Role, Group } from '../../types/types.users';

const initialState: User = {
  userId: null,
  isAuth: false,
  role: null,
  group: null,
  username: null,
  name: null,
  email: null,
  token: null,
  tickets: null,
  loginError: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<User>) => {
      return { ...state, ...action.payload };
    },
    updateIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    updateRole: (state, action: PayloadAction<Role | null>) => {
      state.role = action.payload;
    },
    updateGroup: (state, action: PayloadAction<Group | null>) => {
      state.group = action.payload;
    },
    updateUsername: (state, action: PayloadAction<string | null | undefined>) => {
      state.username = action.payload;
    },
    updateName: (state, action: PayloadAction<string | null | undefined>) => {
      state.name = action.payload;
    },
    updateToken: (state, action: PayloadAction<string | null | undefined>) => {
      state.token = action.payload;
    },
    updateTickets: (
      state,
      action: PayloadAction<(Ticket | null)[] | null | undefined>,
    ) => {
      state.tickets = action.payload;
    },
    updateLoginError: (state, action: PayloadAction<string | undefined | null>) => {
      state.loginError = action.payload;
    },
    resetUser: () => {
      return { ...initialState };
    },
  },
});

export const {
  updateUser,
  updateIsAuth,
  updateRole,
  updateGroup,
  updateUsername,
  updateName,
  updateToken,
  updateTickets,
  updateLoginError,
  resetUser,
} = userSlice.actions;

export default userSlice.reducer;
