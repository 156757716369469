import IconType from './IconType';

const SearchIcon = ({ width = 18, height = 18, color = '#FFF', style }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M8.62451 15.75C12.5595 15.75 15.7495 12.56 15.7495 8.625C15.7495 4.68997 12.5595 1.5 8.62451 1.5C4.68948 1.5 1.49951 4.68997 1.49951 8.625C1.49951 12.56 4.68948 15.75 8.62451 15.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4995 16.5L14.9995 15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
