import { useEffect, useRef } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Check } from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import ChineseMenuItem from './ChineseMenuItem';
import { Language, useLanguage } from './LanguageHelper';
import { languageVar } from '../../apollo/cacheVar';
import usePopoverAnchorEl from '../../globalHooks/usePopoverAnchorEl';
import { MenuItemProps } from '../components/MainMenu';

interface ChineseBottomNavigationAction extends MenuItemProps {
  bottomNavigationId: string;
  currentMenuItemIndex: number | null;
}

const ChineseBottomNavigationAction = ({
  isMobile,
  bottomNavigationId,
  currentMenuItemIndex,
}: ChineseBottomNavigationAction) => {
  const { anchorEl, open, handleClick, handleClose } = usePopoverAnchorEl({
    bottomNavigationId,
    currentMenuItemIndex,
  });

  const boxRef = useRef<HTMLDivElement | null>(null);

  const { language, changeLanguage } = useLanguage();
  const createLanguageHandler = (code: Language) => () => changeLanguage(code);

  // const language = localStorage.getItem('language') ?? 'US';

  const handleUSClick = () => {
    // localStorage.setItem('language', 'US');
    createLanguageHandler('US');
    languageVar('US');
  };
  const handleTWClick = () => {
    // localStorage.setItem('language', 'TW');
    createLanguageHandler('TW');
    languageVar('TW');
  };

  useEffect(() => {
    const button = boxRef.current?.parentElement?.parentElement as HTMLButtonElement;
    if (!button) {
      return;
    }

    const customClick = handleClick as unknown as (event: MouseEvent) => void;

    button.addEventListener('click', customClick);

    return () => {
      button.removeEventListener('click', customClick);
    };
  }, []);

  return (
    <Stack
      ref={boxRef}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={0}
    >
      <ChineseMenuItem isMobile={isMobile} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ zIndex: 10000 }}
      >
        {language === 'US' && (
          <MuiMenuItem onClick={createLanguageHandler('US')}>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            English
          </MuiMenuItem>
        )}
        {language !== 'US' && (
          <MuiMenuItem onClick={createLanguageHandler('US')}>
            <ListItemText inset>English</ListItemText>
          </MuiMenuItem>
        )}
        {language === 'TW' && (
          <MuiMenuItem onClick={createLanguageHandler('TW')}>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            繁體中文
          </MuiMenuItem>
        )}
        {language !== 'TW' && (
          <MuiMenuItem onClick={createLanguageHandler('TW')}>
            <ListItemText inset>繁體中文</ListItemText>
          </MuiMenuItem>
        )}
      </Menu>
    </Stack>
  );
};

export default ChineseBottomNavigationAction;
