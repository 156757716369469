import React, { useState, useEffect } from 'react';

// components
// hooks
import { SxProps } from '@mui/material';

import { Button } from './Button';
import { useAudio } from '../../../../globalContexts/AudioContext';
import ErrorIcon from '../../../../img/style/audio/error_icon.png';
// assets
import PlayIconA2 from '../../../../img/style/audio/play_icon_A2.png';
import PlayIconN2 from '../../../../img/style/audio/play_icon_N2.png';
import PlayIconX2 from '../../../../img/style/audio/play_icon_X2.png';

interface AudioButtonBaseProps {
  src?: string;
  imgSrc?: string;
  playingImgSrc?: string;
  nullImgSrc?: string;
  width?: string;
  height?: string;
  aspectRatio?: string;
  sx?: SxProps;
}

export const AudioButtonBase = ({
  src,
  imgSrc = '',
  playingImgSrc,
  nullImgSrc = '',
  width = '100%',
  height = '100%',
  aspectRatio = '1 / 1',
  sx,
}: AudioButtonBaseProps) => {
  const { playAudio, pauseAudio, audioSrc, isPlayingAudios, errorAudios } = useAudio();
  const [thisAudioIsPlaying, setThisAudioIsPlaying] = useState(false);
  const [thisAudioIsError, setThisAudioIsError] = useState(false);
  const onPlay = () => {
    setThisAudioIsPlaying(true);
  };
  const onPause = () => {
    setThisAudioIsPlaying(false);
  };
  useEffect(() => {
    if (!src) return; // avoid src === undefined === audioSrc
    if (isPlayingAudios && src === audioSrc) {
      setThisAudioIsPlaying(true);
    } else {
      setThisAudioIsPlaying(false);
    }
    // error scenarios
    if (errorAudios.length > 0 && errorAudios.includes(src)) {
      setThisAudioIsError(true);
    } else {
      setThisAudioIsError(false);
    }
  }, [audioSrc, isPlayingAudios, src, errorAudios]);
  // main
  return (
    <Button
      src={
        !src
          ? nullImgSrc
          : thisAudioIsError
          ? ErrorIcon
          : thisAudioIsPlaying
          ? playingImgSrc || imgSrc
          : imgSrc
      }
      width={width}
      height={height}
      aspectRatio={aspectRatio}
      sx={sx}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        if (src) {
          thisAudioIsPlaying ? pauseAudio() : playAudio({ url: src, onPlay, onPause });
        }
      }}
    />
  );
};

export const AudioButtonN2 = (props: AudioButtonBaseProps) => (
  <AudioButtonBase
    imgSrc={PlayIconN2}
    playingImgSrc={PlayIconA2}
    nullImgSrc={PlayIconX2}
    width="80%"
    height="80%"
    {...props}
  />
);
