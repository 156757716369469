import { Box, Typography, TypographyProps } from '@mui/material';

const CustomText = ({ children, sx, ...rest }: TypographyProps): JSX.Element => {
  return (
    <Typography
      {...rest}
      sx={{
        fontWeight: 600,
        fontSize: '24px',
        fontFamily: 'Poppins',
        ...sx,
      }}
      component={Box}
    >
      {children}
    </Typography>
  );
};

export default CustomText;

// type customTextProps = {
//   label?: string | JSX.Element;
//   sx?: TypographyProps['sx'];
// };

// const CustomText = ({ label, sx, ...rest }: customTextProps): JSX.Element => {
//   return (
//     <Typography
//       sx={{
//         fontWeight: 600,
//         fontSize: '24px',
//         fontFamily: 'Poppins',
//         ...sx,
//       }}
//       {...rest}
//     >
//       {label}
//     </Typography>
//   );
// };

// export default CustomText;
