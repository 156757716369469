import CheckIcon from '@mui/icons-material/Check';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ToolsMenu, { ToolsMenuChildProps } from './ToolsMenu';
import { ReactComponent as DashedIcon } from '../../../../../../img/border/line-dashed.svg';
import { useToolButton } from '../../../ToolButton';

const StrokeStyleMenu = ({ subMenuFuncArr, popperRef }: ToolsMenuChildProps) => {
  const { isActive: isActiveForBorderNone, update: updateForBorderNone } = useToolButton({
    value: { borderStyle: 'none' },
  });
  const { isActive: isActiveForBorderSolid, update: updateForBorderSolid } =
    useToolButton({
      value: { borderStyle: 'solid' },
    });
  const { isActive: isActiveForBorderDashed, update: updateForBorderDashed } =
    useToolButton({
      value: { borderStyle: 'dashed' },
    });
  const { isActive: isActiveForBorderDotted, update: updateForBorderDotted } =
    useToolButton({
      value: { borderStyle: 'dotted' },
    });
  const { isActive: isActiveForBorderDouble, update: updateForBorderDouble } =
    useToolButton({
      value: { borderStyle: 'double' },
    });

  const getCheckIcon = (isActive: boolean) =>
    isActive ? (
      <ListItemIcon>
        <CheckIcon />
      </ListItemIcon>
    ) : (
      <ListItemIcon />
    );

  return (
    <ToolsMenu
      subMenuFuncArr={subMenuFuncArr}
      label={
        <>
          <ListItemText primary="Stroke Style" />
          <ListItemIcon sx={{ minWidth: 'auto' }}>
            <NavigateNextIcon />
          </ListItemIcon>
        </>
      }
      refreshSubMenu
      height={184}
      popperRef={popperRef}
    >
      <Paper>
        <List dense>
          <ListItem disablePadding>
            <ListItemButton
              selected={isActiveForBorderNone}
              onClick={() => updateForBorderNone()}
            >
              {getCheckIcon(isActiveForBorderNone)}
              <ListItemText primary={<Typography>No&nbsp;Outline</Typography>} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              selected={isActiveForBorderSolid}
              onClick={() => updateForBorderSolid()}
            >
              {getCheckIcon(isActiveForBorderSolid)}
              <ListItemIcon>
                <HorizontalRuleIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              selected={isActiveForBorderDashed}
              onClick={() => updateForBorderDashed()}
            >
              {getCheckIcon(isActiveForBorderDashed)}
              <ListItemIcon>
                <DashedIcon width={24} height={24} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              selected={isActiveForBorderDotted}
              onClick={() => updateForBorderDotted()}
            >
              {getCheckIcon(isActiveForBorderDotted)}
              <ListItemIcon>
                <MoreHorizIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              selected={isActiveForBorderDouble}
              onClick={() => updateForBorderDouble()}
            >
              {getCheckIcon(isActiveForBorderDouble)}
              <ListItemIcon>
                <DragHandleIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Paper>
    </ToolsMenu>
  );
};

export default StrokeStyleMenu;
