import Box from '@mui/material/Box';

import CenterBox from '../../../globalContainers/CenterBox';

export default function ResizeHandle() {
  return (
    <CenterBox sx={{ width: '100%', height: '100%' }}>
      <Box
        sx={[
          {
            width: '50%',
            height: '50%',
            backgroundColor: 'white',
            border: '1px solid #f33',
          },
        ]}
      />
    </CenterBox>
  );
}
