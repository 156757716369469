import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import ToolsMenu, { ToolsMenuChildProps } from './ToolsMenu';
import FontAlign from '../../../FontAlign';

const FontAlignMenu = ({ subMenuFuncArr, popperRef }: ToolsMenuChildProps) => {
  return (
    <ToolsMenu
      subMenuFuncArr={subMenuFuncArr}
      label={
        <>
          <ListItemText primary="Font Align" />
          <ListItemIcon sx={{ minWidth: 'auto' }}>
            <NavigateNextIcon />
          </ListItemIcon>
        </>
      }
      height={64}
      popperRef={popperRef}
    >
      <Paper>
        <List dense>
          <ListItem>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <FontAlign />
            </Stack>
          </ListItem>
        </List>
      </Paper>
    </ToolsMenu>
  );
};

export default FontAlignMenu;
