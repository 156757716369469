import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import { gql } from '@apollo/client';
import {
  //Stack,
  Dialog,
  DialogTitle,
  //DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { cloneDeep } from 'lodash-es';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import stringSimilarity from 'string-similarity-js';
import { v4 as uuidv4 } from 'uuid';

import { useStyle } from './StyleContext';
import { useTime } from './TimeContext';
import { client } from '../../../apollo/apollo.client';
import { CustomAddOrEditDialog } from '../../../globalComponents';
import { useDialog } from '../../../globalHooks';
import { useAuth } from '../../../globalHooks/useAuth';
import {
  Scalars,
  useRecordPartViewMutation,
  useRecordStyleViewMutation,
  useStyleQuery,
  useRecordHomeworkStyleViewMutation,
  useRecordHomeworkViewMutation,
  useStudentHomeworkLazyQuery,
  useStyleViewLazyQuery,
  LessonsQuery,
  LessonsQueryVariables,
  LessonsDocument,
  HomeworkOverviewDocument,
  HomeworkOverviewQuery,
  HomeworkOverviewQueryVariables,
  StudentHomeworksDocument,
  StudentHomeworksQuery,
  StudentHomeworksQueryVariables,
  HomeworkStatus,
} from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';
import { useAppSelector } from '../../../redux/store';
import StudentScoreBoard from '../components/StudentScoreBoard';

export interface UserAnswerItem {
  draggableId: string;
  answer?:
    | boolean
    | string
    | number
    | { parentId?: string; draggableId: string; answer: string | boolean }[];
  audio?: Scalars['Upload'];
  type: string;
  answers?: { answer: string; draggableId: string }[];
}

export interface StyleAnswerItem {
  styleId: string;
  userAnswers: UserAnswerItem[];
  isDone: boolean;
}

export interface StyleDraggableItem {
  parentDraggableId: string;
  maxAnswerClicks: number;
  userAnswerClicks: number;
  isDone: boolean;
}

interface StyleAnswerContextProps {
  styleAnswers: StyleAnswerItem[];
  setStyleAnswers: React.Dispatch<React.SetStateAction<StyleAnswerItem[]>>;
  disableSelect: boolean;
  setDisableSelect: React.Dispatch<React.SetStateAction<boolean>>;
  styleDraggables: StyleDraggableItem[];
  setStyleDraggables: React.Dispatch<React.SetStateAction<StyleDraggableItem[]>>;
  mode: string;
}

const defaultValue: StyleAnswerContextProps = {
  styleAnswers: [], // record user answer status
  setStyleAnswers: () => undefined,
  disableSelect: false,
  setDisableSelect: () => undefined,
  styleDraggables: [], //record style draggable status
  setStyleDraggables: () => undefined,
  mode: 'todo',
};

const StyleAnswerContext = createContext(defaultValue);
export const useStyleAnswer = () => {
  const context = useContext(StyleAnswerContext);
  if (!context && typeof window !== 'undefined') {
    throw new Error(`useStyle must be used within a StyleContext `);
  }
  return context;
};

interface StyleAnswerProviderProps {
  children: React.ReactNode;
  timeLimit?: number | undefined;
}

export const StyleAnswerProvider = ({
  children,
  timeLimit,
}: StyleAnswerProviderProps) => {
  const scripts = useScripts();

  const role = useAppSelector((state) => state.user.role);

  const { pathname: url } = useLocation();
  const [currentUrl, setCurrentUrl] = useState(url);
  const navigate = useNavigate();
  const {
    styleId = '',
    partId = '',
    bookId = '',
    lessonId = '',
    homeworkId = '',
  } = useParams();
  const { isStudent } = useAuth();
  const { timeLeft, timeSpent, resetTimeSpent } = useTime();
  const [timeUpDialogOpen, setTimeUpDialogOpen] = useState(false);

  const [mode, setMode] = useState('todo');

  const { data, loading, error } = useStyleQuery({
    variables: {
      styleId: styleId,
    },
    skip: !styleId,
  });

  const { getParentId, restoreRecordingCounter, setRestoreRecordingCounter, draggables } =
    useStyle();

  const [
    recordPartViewMutation,
    {
      data: recordPartViewData,
      loading: recordPartViewLoading,
      error: recordPartViewError,
    },
  ] = useRecordPartViewMutation({
    onCompleted(data) {
      const partviewQuery = client.cache.readQuery<LessonsQuery, LessonsQueryVariables>({
        query: LessonsDocument,
        variables: {
          bookId,
          isHidden: false,
        },
      });

      if (!partviewQuery) {
        return;
      }

      const partviewQueryCopy = cloneDeep(partviewQuery);
      const currentLesson = partviewQueryCopy.lessons.edges.find(
        (lesson) => lesson.node.id === lessonId,
      );

      if (!currentLesson) {
        return;
      }

      const currentPart = currentLesson.node.parts.find((part) => part.id === partId);
      const partView = data.recordPartView;

      if (!currentPart || !partView || !('partView' in currentPart)) {
        return;
      }

      currentPart.partView = partView;

      client.cache.writeQuery<LessonsQuery, LessonsQueryVariables>({
        query: LessonsDocument,
        variables: {
          bookId,
          isHidden: false,
        },
        data: partviewQueryCopy,
      });
    },
  });

  const [
    fetchHomework,
    { data: homeworkData, loading: homeworkLoading, error: homeworkError },
  ] = useStudentHomeworkLazyQuery();

  const [
    fetchHomeworkStyleView,
    {
      data: homeworkStyleViewData,
      loading: homeworkStyleViewLoading,
      error: homeworkStyleViewError,
    },
  ] = useStyleViewLazyQuery({
    onCompleted(data) {
      setDisableSelect(false);
      const styleView = data?.style?.styleView;

      if (styleView && 'userAnswers' in styleView) {
        setStyleAnswers([
          {
            styleId: styleId,
            isDone: true,
            userAnswers: styleView.userAnswers.map(({ answer, draggableId, answers }) => {
              return {
                answer:
                  answers && answers?.length !== 0
                    ? answers.map((each) => {
                        return {
                          answer: each?.answer,
                          draggableId: each?.draggableId || '',
                        };
                      })
                    : answer,
                draggableId: draggableId,
                type: '', //TODO: how to get this type? now not required
              };
            }),
          },
        ]);
      }
    },
  });

  useEffect(() => {
    if (!timeLimit && homeworkId && styleId !== undefined) {
      //review done HW mode, get student done HW style view data
      fetchHomeworkStyleView({
        variables: {
          homeworkId,
          styleId,
        },
      });
      setMode('done');
    }
  }, [timeLimit, homeworkId, styleId]);

  const [
    recordStyleViewMutation,
    {
      data: RecordStyleViewData,
      loading: RecordStyleViewLoading,
      error: RecordStyleViewError,
    },
  ] = useRecordStyleViewMutation({
    onCompleted() {
      const partData = client.cache.readFragment({
        id: `Part:${partId}`,
        fragment: gql`
          fragment part on Part {
            id
            name
            styleIds(isHidden: false)
          }
        `,
      }) as { id: string; name: string; styleIds: string[] };

      const currenStyleIndex = partData.styleIds.findIndex((id) => id === styleId);

      const nextStyleUrl = url.replace(
        /\/style\/[^\/]+/,
        `/style/${partData.styleIds[currenStyleIndex + 1]}`,
      );

      // 使用正則表達式匹配到 lesson 部分
      const match = url.match(/\/student\/learn\/level\/[^\/]+\/book\/[^\/]+/);

      // 如果有匹配，取出匹配的部分
      const backToLessonUrl = match ? match[0] : '';

      setDisableSelect(false);

      if (currenStyleIndex === partData.styleIds.length - 1) {
        //setCurrentUrl(backToLessonUrl);
        recordPartViewMutation({
          variables: {
            partId,
          },
        });
        setOpen(true);
        //navigate(backToLessonUrl);
      } else {
        setCurrentUrl(nextStyleUrl);
        navigate(nextStyleUrl);
      }
    },
    onError() {
      setRestoreRecordingCounter((prev) => prev + 1);
    },
  });

  const [
    recordHomeworkStyleViewMutation,
    {
      data: RecordHomeworkStyleViewData,
      loading: RecordHomeworkStyleViewLoading,
      error: RecordHomeworkStyleViewError,
    },
  ] = useRecordHomeworkStyleViewMutation({
    onCompleted() {
      const homework = client.cache.readFragment({
        id: `Homework:${homeworkId}`,
        fragment: gql`
          fragment selectedHomework2 on Homework {
            id
            questionDetail {
              questions {
                numberOfQuestions
                part {
                  id
                  name
                }
                styleIds
              }
            }
          }
        `,
      }) as {
        id: string;
        questionDetail: {
          questions: {
            numberOfQuestions: number;
            part: { id: string; name: string };
            styleIds: string[];
          }[];
        };
      };

      const currentStyleIndex =
        homework?.questionDetail?.questions[0].styleIds?.findIndex(
          (id) => id === styleId,
        );

      const nextStyleUrl = url.replace(
        /\/style\/[^\/]+/,
        `/style/${
          homework?.questionDetail?.questions[0].styleIds[currentStyleIndex + 1]
        }`,
      );
      setDisableSelect(false);
      if (
        currentStyleIndex ===
        homework?.questionDetail?.questions[0].styleIds.length - 1
      ) {
        //modify cache before go back to homework page
        const homeworkOverviewData = client.cache.readQuery<
          HomeworkOverviewQuery,
          HomeworkOverviewQueryVariables
        >({
          query: HomeworkOverviewDocument,
        });

        if (homeworkOverviewData) {
          client.cache.writeQuery({
            query: HomeworkOverviewDocument,
            data: {
              homeworkOverview: {
                done: homeworkOverviewData?.homeworkOverview.done + 1,
                overdue: homeworkOverviewData?.homeworkOverview.overdue || 0,
                todo: homeworkOverviewData?.homeworkOverview.todo - 1,
                __typename: 'HomeworkOverview',
              },
            },
          });
        }

        const studentHomeworkData = client.cache.readQuery<
          StudentHomeworksQuery,
          StudentHomeworksQueryVariables
        >({
          query: StudentHomeworksDocument,
          variables: { status: HomeworkStatus.Todo },
        });
        const newData = studentHomeworkData?.homeworks.edges.filter(
          (each) => each.node.id !== homeworkId,
        );
        const newPageInfo = studentHomeworkData?.homeworks.pageInfo;

        if (studentHomeworkData && newPageInfo) {
          client.cache.writeQuery({
            query: StudentHomeworksDocument,
            data: {
              homeworks: {
                edges: newData,
                pageInfo: {
                  ...newPageInfo,
                  itemTotal: newPageInfo.itemTotal - 1,
                },
                __typename: 'HomeworkConnection"',
              },
            },
            variables: { status: 'todo' },
          });
        }

        const studentDoneHomeworks = client.cache.readQuery<
          StudentHomeworksQuery,
          StudentHomeworksQueryVariables
        >({
          query: StudentHomeworksDocument,
          variables: { status: HomeworkStatus.Done },
        });

        const newDoneData = studentHomeworkData?.homeworks.edges.filter(
          (each) => each.node.id === homeworkId,
        );

        if (studentDoneHomeworks) {
          const newDonePageInfo = studentDoneHomeworks.homeworks.pageInfo;

          client.cache.writeQuery({
            query: StudentHomeworksDocument,
            data: {
              homeworks: {
                edges: [newDoneData?.[0], ...studentDoneHomeworks.homeworks.edges],
                pageInfo: {
                  ...newDonePageInfo,
                  itemTotal: newDonePageInfo.itemTotal + 1,
                },
                __typename: 'HomeworkConnection"',
              },
            },
            variables: { status: 'done' },
          });
        }

        setCurrentUrl('/student');
        navigate('/student'); //finish hw then go back to student page
      } else {
        if (homework) {
          setCurrentUrl(nextStyleUrl);
          navigate(nextStyleUrl); //next page
        }
      }
    },
    onError() {
      setRestoreRecordingCounter((prev: number) => prev + 1);
    },
  });

  const [
    recordHomeworkViewMutation,
    {
      data: RecordHomeworkViewData,
      loading: RecordHomeworkViewLoading,
      error: RecordHomeworkViewError,
    },
  ] = useRecordHomeworkViewMutation();
  // use cache to write new data
  //   {
  //   onCompleted(data) {
  //     client.cache.writeFragment({
  //       id: `Homework:${homeworkId}`,
  //       fragment: gql`
  //         fragment selectedHomework3 on Homework {
  //           id
  //           myHomeworkView {
  //             numberOfDone
  //             progress
  //             score
  //             timeSpent
  //           }
  //         }
  //       `,
  //       data: {
  //         myHomeworkView: {
  //           numberOfDone: data.recordHomeworkView.numberOfDone,
  //           progress: data.recordHomeworkView.progress,
  //           score: data.recordHomeworkView.score,
  //           timeSpent: data.recordHomeworkView.timeSpent,
  //         },
  //       },
  //     });
  //   },
  // }

  useEffect(() => {
    if (url !== currentUrl) {
      navigate(currentUrl);
    }
  }, [RecordStyleViewData, RecordHomeworkStyleViewData]);

  const [styleAnswers, setStyleAnswers] = useState<StyleAnswerItem[]>([]); //record user answer
  const initialStyleDraggables: StyleDraggableItem[] = [];
  const [styleDraggables, setStyleDraggables] =
    useState<StyleDraggableItem[]>(initialStyleDraggables); //record style draggable status
  const [disableSelect, setDisableSelect] = useState(false);

  const newStyleDraggablesRef = useRef<StyleDraggableItem[]>([]);

  const currentAnswer = styleAnswers.find((ele) => ele.styleId === styleId);
  const styleData = data?.style?.draggables.portrait; //correct answer and test type in this data

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const newStyleDraggables: StyleDraggableItem[] = [];

    const choiceTypeData = styleData?.filter((ele) => {
      return (
        ele.type !== 'ReorderAnswer' &&
        ele.type !== 'ClozeTestAnswerGroup' &&
        ele.type !== 'ImageFillBlankAnswer' &&
        ele.type !== 'FillBlankAnswer' &&
        ele.type !== 'TrueFalseAnswer' &&
        ele.type !== 'VoiceToTextAnswer' &&
        ele.type !== 'CustomText' &&
        ele.type !== 'Video' &&
        ele.type !== 'Image' &&
        ele.type !== 'Audio' &&
        ele.type !== 'Square' &&
        ele.type !== 'Rectangle' &&
        ele.type !== 'Circle' &&
        ele.type !== 'Triangle' &&
        ele.type !== 'Star'
      );
    });

    let maxAnswerClicks = 0;

    choiceTypeData?.forEach((ele) => {
      ele.answer?.correct ? maxAnswerClicks++ : null;
    });

    const addDraggableItemIfValid = (
      parentDraggableId: string,
      maxAnswerClicks: number,
    ) => {
      const draggableItem = {
        parentDraggableId,
        maxAnswerClicks,
        userAnswerClicks: 0,
        isDone: false,
      };
      if (maxAnswerClicks > 0 || parentDraggableId.startsWith('noParentId')) {
        newStyleDraggables.push(draggableItem);
      }
    };

    if (choiceTypeData && choiceTypeData.length > 0) {
      addDraggableItemIfValid(`noParentId_${uuidv4()}`, maxAnswerClicks);
    }

    styleData?.forEach((style) => {
      if (style.type === 'ReorderAnswer') {
        addDraggableItemIfValid(style.id, style?.group?.length ?? 0);
      } else if (style.type === 'ClozeTestAnswerGroup') {
        style.group?.forEach((innerGroup) => {
          let maxAnswerClicks = 0;
          innerGroup.group?.forEach((ele) => {
            if (ele.answer?.correct === true) {
              maxAnswerClicks++;
            }
          });
          addDraggableItemIfValid(innerGroup.id, maxAnswerClicks);
        });
      } else if (
        style.type === 'ImageFillBlankAnswer' ||
        style.type === 'FillBlankAnswer'
      ) {
        let maxAnswerClicks = 0;
        style.group?.forEach((ele) => {
          if (ele.answer) {
            maxAnswerClicks = ele.answer.correct.length;
          }
        });

        addDraggableItemIfValid(style.id, maxAnswerClicks);
      } else if (style.type === 'TrueFalseAnswer') {
        let maxAnswerClicks = 0;
        style.group?.forEach((ele) => {
          if (ele.answer?.correct) {
            maxAnswerClicks++;
          }
        });

        addDraggableItemIfValid(style.id, maxAnswerClicks);
      } else if (style.type === 'VoiceToTextAnswer') {
        addDraggableItemIfValid(style.id, 1);
      } else if (style.type === 'Video') {
        let maxAnswerClicks = 0;

        style.answer?.videoAns?.forEach((videoAns) =>
          videoAns.options.forEach((option) => {
            if (option.correct) {
              maxAnswerClicks++;
            }
          }),
        );

        addDraggableItemIfValid(style.id, maxAnswerClicks);
      }
    });

    setStyleDraggables(newStyleDraggables);
    newStyleDraggablesRef.current = newStyleDraggables;
  }, [styleData]);

  useEffect(() => {
    setStyleDraggables(newStyleDraggablesRef.current);

    const currentStyleAnswer = styleAnswers.find(
      (styleAnswer) => styleAnswer.styleId === styleId,
    );

    let noParentIdNums = 0; //沒有父元件Id選擇題的個數

    const onlyChoiceAnswer = currentStyleAnswer?.userAnswers.filter(
      (userAnswer) => userAnswer.type === 'Choice',
    );

    onlyChoiceAnswer?.forEach((ele) => {
      const parentId = getParentId(ele.draggableId);
      const subParentId = getParentId(
        ele.draggableId,
        draggables.find(({ id }) => id === parentId)?.group,
      );

      const parentDraggable = styleDraggables.find(
        (styleDraggable) => styleDraggable.parentDraggableId === parentId,
      );

      const subParentDraggable = styleDraggables.find(
        (styleDraggable) => styleDraggable.parentDraggableId === subParentId,
      );

      if (!parentDraggable && !subParentDraggable) {
        noParentIdNums++;
      }
    });

    if (currentStyleAnswer?.userAnswers.length === 0) {
      setStyleDraggables((prev) =>
        prev.map((draggable) => ({ ...draggable, userAnswerClicks: 0, isDone: false })),
      );
    }

    currentStyleAnswer?.userAnswers.forEach((userAnswer) => {
      const updatedDraggable = styleDraggables.find(
        (styleDraggable) => styleDraggable.parentDraggableId === userAnswer.draggableId,
      );

      if (userAnswer.type === 'ReOrder' && updatedDraggable) {
        const answer = userAnswer?.answer;
        if (Array.isArray(answer)) {
          setStyleDraggables((prev) =>
            prev.map((styleDraggable) =>
              styleDraggable.parentDraggableId === userAnswer.draggableId
                ? {
                    ...styleDraggable,
                    userAnswerClicks: answer.length,
                    isDone: styleDraggable?.maxAnswerClicks === answer.length,
                  }
                : styleDraggable,
            ),
          );
        }
      } else if (userAnswer.type === 'FillBlank' && updatedDraggable) {
        const answer = userAnswer?.answer;
        if (typeof answer === 'string') {
          setStyleDraggables((prev) =>
            prev.map((styleDraggable) =>
              styleDraggable.parentDraggableId === userAnswer.draggableId
                ? {
                    ...styleDraggable,
                    userAnswerClicks: answer.length,
                    isDone: styleDraggable?.maxAnswerClicks === answer.length,
                  }
                : styleDraggable,
            ),
          );
        }
      } else if (userAnswer.type === 'Audio' && updatedDraggable) {
        setStyleDraggables((prev) =>
          prev.map((styleDraggable) =>
            styleDraggable.parentDraggableId === userAnswer.draggableId
              ? {
                  ...styleDraggable,
                  userAnswerClicks: styleDraggable.userAnswerClicks + 1,
                  isDone:
                    styleDraggable?.maxAnswerClicks ===
                    styleDraggable?.userAnswerClicks + 1,
                }
              : styleDraggable,
          ),
        );
      } else if (userAnswer.type === 'Video' && updatedDraggable) {
        setStyleDraggables((prev) =>
          prev.map((styleDraggable) =>
            styleDraggable.parentDraggableId === userAnswer.draggableId
              ? {
                  ...styleDraggable,
                  userAnswerClicks: Array.isArray(userAnswer?.answer)
                    ? userAnswer.answer.length
                    : 0,
                  isDone:
                    styleDraggable?.maxAnswerClicks ===
                    (Array.isArray(userAnswer?.answer) ? userAnswer.answer.length : 0),
                }
              : styleDraggable,
          ),
        );
      } else if (userAnswer.type === 'Choice') {
        const parentId = getParentId(userAnswer.draggableId);
        const subParentId = getParentId(
          userAnswer.draggableId,
          draggables.find(({ id }) => id === parentId)?.group ?? [],
        );

        const parentDraggable = styleDraggables.find(
          (styleDraggable) => styleDraggable.parentDraggableId === parentId,
        );

        const subParentDraggable = styleDraggables.find(
          (styleDraggable) => styleDraggable.parentDraggableId === subParentId,
        );

        if (parentDraggable) {
          // ### True/False question
          setStyleDraggables((prev) =>
            prev.map((styleDraggable) =>
              styleDraggable.parentDraggableId === parentDraggable.parentDraggableId
                ? {
                    ...styleDraggable,
                    userAnswerClicks: styleDraggable.userAnswerClicks + 1,
                    isDone:
                      styleDraggable?.maxAnswerClicks ===
                      styleDraggable?.userAnswerClicks + 1,
                  }
                : styleDraggable,
            ),
          );
        } else if (subParentDraggable) {
          // ### cloze question
          setStyleDraggables((prev) =>
            prev.map((styleDraggable) =>
              styleDraggable.parentDraggableId ===
              getParentId(
                userAnswer.draggableId,
                draggables.find(({ id }) => id === parentId)?.group,
              )
                ? {
                    ...styleDraggable,
                    userAnswerClicks: styleDraggable.userAnswerClicks + 1,
                    isDone:
                      styleDraggable?.maxAnswerClicks ===
                      styleDraggable?.userAnswerClicks + 1,
                  }
                : styleDraggable,
            ),
          );
        } else {
          // ###沒有父組件選擇題型
          setStyleDraggables((prev) =>
            prev.map((styleDraggable) =>
              styleDraggable.parentDraggableId.indexOf('noParentId') !== -1
                ? {
                    ...styleDraggable,
                    userAnswerClicks: noParentIdNums,
                    isDone: styleDraggable?.maxAnswerClicks === noParentIdNums,
                  }
                : styleDraggable,
            ),
          );
        }
      }
    });
  }, [styleAnswers]);

  let totalPoints = data?.style?.totalPoints ?? 0;

  const handleCheckAnswer = () => {
    // console.log('開始對答案');
    const cleanStr = (v: string) => v?.toLowerCase().replace(/\n|\W|\t|\u005F|\./g, '');
    const userAnswers = currentAnswer?.userAnswers.map((userAnswer) => {
      if (userAnswer.type === 'Choice') {
        // console.log('處理選擇部分');
        const choiceStyleData = styleData?.filter((data) => {
          return (
            data.type !== 'FillBlankAnswer' &&
            data.type !== 'ImageFillBlankAnswer' &&
            data.type !== 'VoiceToTextAnswer' &&
            data.type !== 'ReorderAnswer' &&
            data.type !== 'Video' &&
            data.type !== 'CustomText' &&
            data.type !== 'Image' &&
            data.type !== 'Audio' &&
            data.type !== 'Square' &&
            data.type !== 'Rectangle' &&
            data.type !== 'Circle' &&
            data.type !== 'Triangle' &&
            data.type !== 'Star'
          );
        });

        let foundAnswer: { correct?: boolean } = {};

        // 遍歷 choiceStyleData 數組
        choiceStyleData?.forEach((item) => {
          // 如果在外部的 group 中找到了答案，則設置 foundAnswer 並退出循環
          if (item.id === userAnswer.draggableId && item.answer) {
            foundAnswer = item.answer;
            return;
          }

          // 遍歷每個元素的 group
          item?.group?.forEach((innerGroup) => {
            // 如果在內部的 group 中找到了答案，則設置 foundAnswer 並退出循環
            if (innerGroup.id === userAnswer.draggableId && innerGroup.answer) {
              foundAnswer = innerGroup.answer;
              return;
            }

            // 如果答案不在直接的 group 中，則進一步遍歷內部的 group（如果有的話）
            if (innerGroup.group) {
              innerGroup.group.forEach((nestedGroup) => {
                // 檢查是否在內部的嵌套 group 中找到了答案
                if (nestedGroup.id === userAnswer.draggableId && nestedGroup.answer) {
                  foundAnswer = nestedGroup.answer;
                  return;
                }
              });
            }
          });
        });

        if (userAnswer.answer !== foundAnswer?.correct) {
          totalPoints -= 1;
        }

        return userAnswer;
      } else if (userAnswer.type === 'FillBlank') {
        // console.log('處理填空部分');
        const correspondAnswer = styleData?.find((ele) => {
          return ele.id === userAnswer.draggableId;
        });

        const foundAnswer = correspondAnswer?.group?.find((ele) => {
          return ele.answer;
        });

        const correctAnswer = foundAnswer?.answer?.correct;

        if (userAnswer.answer !== correctAnswer) {
          totalPoints -= 1;
        }

        return userAnswer;
      } else if (userAnswer.type === 'ReOrder') {
        // console.log('處理排序部分');
        const correspondAnswer = styleData?.find((ele) => {
          return ele.id === userAnswer?.draggableId;
        });

        let userAnswerText = '';
        let answerText = '';

        if (Array.isArray(userAnswer.answer)) {
          userAnswer.answer?.forEach((ele: any) => {
            userAnswerText += `${ele.answer} `;
          });
        }

        correspondAnswer?.group?.forEach((ele) => {
          answerText += `${ele.answer?.correct} `;
        });

        if (userAnswerText !== answerText) {
          totalPoints -= 1;
        }

        return {
          draggableId: userAnswer.draggableId,
          answer: userAnswerText.trim(),
          answers: userAnswer.answer,
        };
      } else if (userAnswer.type === 'Audio') {
        // console.log('處理音檔部分');
        const draggableId = userAnswer?.draggableId;
        const answer = userAnswer?.answer;

        const parent = styleData?.find(({ id }) => id && id === draggableId);
        if (!parent) {
          return;
        }

        const correct = parent.group?.[1]?.answer?.correct;
        if (correct && answer) {
          if (
            stringSimilarity(cleanStr(`${answer}`), cleanStr(`${correct}`)) * 100 - 60 <
            0
          ) {
            totalPoints -= 1;
          }
        }
        return userAnswer;
      } else if (userAnswer.type === 'Video') {
        // console.log('處理影片部分');

        const correspondAnswer = styleData?.find((ele) => {
          return ele.id === userAnswer?.draggableId;
        });

        correspondAnswer?.answer?.videoAns?.forEach((videoAns) => {
          videoAns.options.forEach((option) => {
            if (Array.isArray(userAnswer.answer)) {
              userAnswer.answer.forEach((answer) => {
                if (answer.draggableId === option.id) {
                  if (answer.answer !== option.correct) {
                    totalPoints -= 1;
                  }
                }
              });
            }
          });
        });

        let newAnswers;
        if (Array.isArray(userAnswer?.answer)) {
          newAnswers = userAnswer?.answer.map((answerItem) => ({
            draggableId: answerItem.draggableId,
            answer: answerItem.answer,
          }));

          return {
            draggableId: userAnswer.draggableId,
            answers: newAnswers,
          };
        }
      }
    });

    let userAnswersFilterType: any[] = []; //TODO: 過濾type屬性要寫成這樣?
    if (userAnswers !== undefined) {
      userAnswersFilterType = userAnswers
        .filter((item): item is UserAnswerItem => item !== undefined)
        .map(({ draggableId, answer, answers }) => ({
          draggableId,
          answer,
          answers, //TODO: check on style view: no answers
        }));
    }

    if (isStudent) {
      if (homeworkId === '') {
        //not homework
        recordStyleViewMutation({
          variables: {
            styleId,
            styleViewInput: {
              points: totalPoints,
              isDone: true,
              userAnswers: userAnswersFilterType,
            },
          },
        });
      } else {
        if (timeLimit) {
          recordHomeworkStyleViewMutation({
            variables: {
              styleId,
              styleViewInput: {
                points: totalPoints,
                isDone: true,
                userAnswers: userAnswersFilterType,
              },
              homeworkId,
            },
          });
          if (timeSpent) {
            // record how many time spent on this question
            recordHomeworkViewMutation({
              variables: {
                homeworkId,
                timeSpent: timeSpent,
              },
            });
            resetTimeSpent(); // reset timeSpent
          }
        }
      }
    }
  };

  useEffect(() => {
    if (mode === 'done' && styleAnswers.length > 0) {
      //review mode and data is set to user data
      setDisableSelect(true); //let user can't click
    }
  }, [styleAnswers]);

  useEffect(() => {
    if (timeLeft === 0 && timeSpent > 0) {
      // Time is up!
      recordHomeworkViewMutation({
        variables: {
          homeworkId,
          timeSpent: timeSpent,
        },
      });

      setTimeUpDialogOpen(true);

      //dialogProps.onClickForNoButton();
    }
  }, [timeLeft, timeSpent]);

  useEffect(() => {
    let doneAnswerNum = 0; //user done answer
    currentAnswer?.userAnswers.forEach((userAnswer) => {
      if (userAnswer.answer === true) {
        doneAnswerNum += 1;
      } else if (userAnswer.type === 'ReOrder') {
        let totalOrderAnsLen = 0;
        let userOrderAnsLen = 0;

        const currentStyleData = styleData?.find((data) => {
          return data.id === userAnswer.draggableId;
        });

        currentStyleData?.group?.forEach((ele) => {
          totalOrderAnsLen += 1;
        });

        if (Array.isArray(userAnswer.answer)) {
          userAnswer?.answer?.forEach((ele) => {
            userOrderAnsLen += 1;
          });
        }

        if (totalOrderAnsLen === userOrderAnsLen) {
          doneAnswerNum += 1;
        }
      } else if (userAnswer.type === 'FillBlank') {
        let totalFillBlankAnsLen = 0;
        let userFillBlankAnsLen = 0;

        const currentStyleData = styleData?.find((data) => {
          return data.id === userAnswer.draggableId;
        });

        currentStyleData?.group?.forEach((ele) => {
          if (ele.answer !== null) {
            totalFillBlankAnsLen += ele.answer?.correct.length;
          }
        });

        if (typeof userAnswer.answer === 'string') {
          userFillBlankAnsLen += userAnswer?.answer?.length;
        }

        if (totalFillBlankAnsLen === userFillBlankAnsLen) {
          doneAnswerNum += 1;
        }
      } else if (userAnswer.type === 'Audio') {
        doneAnswerNum += 1;
      } else if (userAnswer.type === 'Video') {
        if (Array.isArray(userAnswer.answer)) {
          doneAnswerNum += userAnswer.answer.length;
        }
      }
    });

    const isStyleDone = styleDraggables.find((styleDraggable) => {
      return styleDraggable.isDone === false;
    })
      ? false
      : true;

    if (
      role === 'student' &&
      isStyleDone &&
      doneAnswerNum === totalPoints &&
      totalPoints !== 0 &&
      !disableSelect &&
      mode !== 'done'
    ) {
      setDisableSelect(true);
      setStyleAnswers((prev) => {
        return prev.map((styleAnswer) => {
          if (styleAnswer.styleId === styleId) {
            return { ...styleAnswer, isDone: true };
          }
          return styleAnswer;
        });
      });
      handleCheckAnswer();
    }
  }, [styleAnswers, styleDraggables]);

  useEffect(() => {
    if (restoreRecordingCounter > 0) {
      setStyleAnswers((prev) => prev.filter((ele) => ele.styleId !== styleId));
    }
  }, [restoreRecordingCounter]);

  useEffect(() => {
    setRestoreRecordingCounter(0);
  }, [styleId]);

  const contextValue: StyleAnswerContextProps = {
    styleAnswers,
    setStyleAnswers,
    disableSelect,
    setDisableSelect,
    styleDraggables,
    setStyleDraggables,
    mode,
  };

  // main
  return (
    <StyleAnswerContext.Provider value={contextValue}>
      {children}

      <Dialog open={timeUpDialogOpen} onClose={() => setTimeUpDialogOpen(false)}>
        <DialogTitle>{scripts.timeIsUp}</DialogTitle>

        <DialogActions>
          <Button
            onClick={() => {
              setTimeUpDialogOpen(false);
              setCurrentUrl('/student');
              navigate('/student');
            }}
          >
            {scripts.ok}
          </Button>
        </DialogActions>
      </Dialog>

      {/* {!loading && timeLimit ? (
        <CustomAddOrEditDialog isAdd={false} {...dialogProps}>
          <Stack></Stack>
        </CustomAddOrEditDialog>
      ) : (
        <></>
      )} */}

      {open && (
        <StudentScoreBoard
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          onSubmit={() => {
            // 使用正則表達式匹配到 lesson 部分
            const match = url.match(/\/student\/learn\/level\/[^\/]+\/book\/[^\/]+/);
            // 如果有匹配，取出匹配的部分
            const backToLessonUrl = match ? match[0] : '';
            navigate(backToLessonUrl);
          }}
          title={scripts.finish}
          titleStyle={{
            display: 'flex',
            justifyContent: 'center',
            fontFamily: 'Poppins',
            fontWeight: 700,
            fontSize: '15px',
          }}
          correct={recordPartViewData?.recordPartView?.achievement?.rights || 0}
          wrong={recordPartViewData?.recordPartView?.achievement?.wrongs || 0}
          score={recordPartViewData?.recordPartView?.score || 0}
        />
      )}
    </StyleAnswerContext.Provider>
  );
};
