import MenuItem from './MenuItem';
import { MenuItemProps } from '../components/MainMenu';

const MoreMenuItem = ({ selected, isMobile, isMore }: MenuItemProps) => {
  return (
    <MenuItem
      itemType="More"
      initWidth={24}
      initHeight={24}
      selected={selected}
      isMobile={isMobile}
      isMore={isMore}
    />
  );
};

export default MoreMenuItem;
