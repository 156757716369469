import { useRef, useEffect } from 'react';

import { Check } from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useScripts } from './LanguageHelper';
import ThemeModeMenuItem from './ThemeModeMenuItem';
import { isDarkModeVar } from '../../apollo/cacheVar';
import usePopoverAnchorEl from '../../globalHooks/usePopoverAnchorEl';
import { updateWebMode } from '../../redux/features/webModeSlice';
import { useAppDispatch } from '../../redux/store';
import { MenuItemProps, ThemeMode } from '../components/MainMenu';

interface ThemeModeBottomNavigationActionProps extends MenuItemProps {
  bottomNavigationId: string;
  currentMenuItemIndex: number | null;
  currentThemeMode: ThemeMode;
  setCurrentThemeMode: React.Dispatch<React.SetStateAction<ThemeMode>>;
}

const ThemeModeBottomNavigationAction = ({
  isMobile,
  bottomNavigationId,
  currentMenuItemIndex,
  currentThemeMode,
  setCurrentThemeMode,
}: ThemeModeBottomNavigationActionProps) => {
  const scripts = useScripts();

  const dispatch = useAppDispatch();

  const { anchorEl, open, handleClick, handleClose } = usePopoverAnchorEl({
    bottomNavigationId,
    currentMenuItemIndex,
  });

  const webMode = localStorage.getItem('theme');

  const boxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const button = boxRef.current?.parentElement?.parentElement as HTMLButtonElement;
    if (!button) {
      return;
    }

    const customClick = handleClick as unknown as (event: MouseEvent) => void;

    button.addEventListener('click', customClick);

    return () => {
      button.removeEventListener('click', customClick);
    };
  }, []);

  const matches = useMediaQuery('(prefers-color-scheme: dark)');

  const handleSystemClick = () => {
    dispatch(updateWebMode('Auto'));
    isDarkModeVar(matches);
    setCurrentThemeMode(ThemeMode.SYSTEM);
  };
  const handleDarkClick = () => {
    dispatch(updateWebMode('Dark'));
    isDarkModeVar(true);
    setCurrentThemeMode(ThemeMode.DARK);
  };
  const handleLightClick = () => {
    dispatch(updateWebMode('Light'));
    isDarkModeVar(false);
    setCurrentThemeMode(ThemeMode.LIGHT);
  };

  return (
    <Stack
      ref={boxRef}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={0}
    >
      <ThemeModeMenuItem
        isMobile={isMobile}
        currentThemeMode={currentThemeMode}
        setCurrentThemeMode={setCurrentThemeMode}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ zIndex: 10000 }}
      >
        {webMode === 'Auto' ? (
          <MuiMenuItem selected onClick={handleSystemClick}>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            {scripts.auto}
          </MuiMenuItem>
        ) : (
          <MuiMenuItem onClick={handleSystemClick}>
            <ListItemText inset>{scripts.auto}</ListItemText>
          </MuiMenuItem>
        )}

        {webMode === 'Dark' ? (
          <MuiMenuItem selected onClick={handleDarkClick}>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            {scripts.darkMode}
          </MuiMenuItem>
        ) : (
          <MuiMenuItem onClick={handleDarkClick}>
            <ListItemText inset>{scripts.darkMode}</ListItemText>
          </MuiMenuItem>
        )}

        {webMode === 'Light' ? (
          <MuiMenuItem selected onClick={handleLightClick}>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            {scripts.lightMode}
          </MuiMenuItem>
        ) : (
          <MuiMenuItem onClick={handleLightClick}>
            <ListItemText inset>{scripts.lightMode}</ListItemText>
          </MuiMenuItem>
        )}
      </Menu>
    </Stack>
  );
};

export default ThemeModeBottomNavigationAction;
