import { useLayoutEffect } from 'react';

import Typography from '@mui/material/Typography';
import { cloneDeep, defaultsDeep } from 'lodash-es';

import { DraggableOuterProps, DraggableInnerProps } from './components/DraggableOrigin';
import TextFieldBase from './components/TextFieldBase';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import { getDefaultFontSizeNum } from '../../context/FontEditorContext';
import { useStyle } from '../../context/StyleContext';

export default function TextRectAnswer2(props: DraggableOuterProps) {
  // based on TextRectAnswer, no CheckBox and name is same as answer.correct
  const defaultTextRectAnswer2: DraggableInnerProps = {
    type: DraggableType.TextRectAnswer2,
    tags: [DraggableTag.TextField],
    name: 'Your answer',
    style: {
      width: '32vmin',
      height: '7vmin',
      color: '#000000',
      textAlign: 'left',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
      fontFamily: 'Roboto',
      backgroundColor: '#d9d9d9',
      borderStyle: 'none',
      borderWidth: 0,
      borderRadius: 0,
      borderColor: '#000000',
    },
    lockAspectRatio: false,
    answer: {
      correct: 'Your answer',
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultTextRectAnswer2,
  );

  const { draggablesPropsRef } = useStyle();
  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep(props);
    }
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps}>
      {dragProps.isClone ? (
        <TextFieldBase {...dragProps} multiline={true} correctIsSameAsName />
      ) : (
        <CenterBox>
          <Typography sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}>
            {dragProps?.name}
          </Typography>
        </CenterBox>
      )}
    </WrapperRND>
  );
}
