import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type CopyDraggableMenuProps = {
  subMenuFuncArr: (() => void)[];
  handleToolsMenuClose: () => void;
  duplicate: () => void;
};

const CopyDraggableMenu = ({
  subMenuFuncArr,
  handleToolsMenuClose,
  duplicate,
}: CopyDraggableMenuProps) => {
  const isMac = /macintosh|mac os x/i.test(navigator?.userAgent ?? '');

  const onMouseEnter = () => {
    subMenuFuncArr.forEach((subMenuFunc) => {
      subMenuFunc();
    });
  };

  return (
    <ListItem onMouseEnter={onMouseEnter} disablePadding>
      <ListItemButton onClick={duplicate}>
        <ListItemIcon sx={{ minWidth: '32px' }}>
          <ContentCopyIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              width="100%"
            >
              <Typography variant="inherit">Duplicate</Typography>
              <Typography
                variant="inherit"
                bgcolor="#e6e6e6"
                borderRadius="4px"
                px={1}
                py={0.5}
              >
                {isMac ? '⌘ Cmd ' : 'Ctrl '}+ D
              </Typography>
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default CopyDraggableMenu;
