import DeleteIcon from '@mui/icons-material/Delete';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useScripts } from '../../../../../../layout/utils/LanguageHelper';
import { deleteDraggable } from '../../../../../../redux/features/styleSlice';
import { useAppDispatch } from '../../../../../../redux/store';
import { useStyle } from '../../../../context/StyleContext';

type DeleteMenuProps = {
  subMenuFuncArr: (() => void)[];
  handleToolsMenuClose: () => void;
};

const DeleteMenu = ({ subMenuFuncArr, handleToolsMenuClose }: DeleteMenuProps) => {
  const dispatch = useAppDispatch();

  const scripts = useScripts();

  const { selectedId, hasRightClickMenuOpenRef } = useStyle();

  const onMouseEnter = () => {
    subMenuFuncArr.forEach((subMenuFunc) => {
      subMenuFunc();
    });
  };

  const handleDeleteDraggable = (myselfMenuOpen?: boolean) => {
    if (!selectedId || (hasRightClickMenuOpenRef?.current && !myselfMenuOpen)) {
      return;
    }

    dispatch(deleteDraggable({ id: selectedId }));
  };

  return (
    <ListItem onMouseEnter={onMouseEnter} disablePadding>
      <ListItemButton onClick={() => handleDeleteDraggable(true)}>
        <ListItemIcon sx={{ minWidth: '32px' }}>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              width="100%"
            >
              <Typography variant="inherit">{scripts.delete}</Typography>
              <Typography
                variant="inherit"
                bgcolor="#e6e6e6"
                borderRadius="4px"
                px={1}
                py={0.5}
              >
                {scripts.delete}
              </Typography>
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default DeleteMenu;
