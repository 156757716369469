import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Typography from '@mui/material/Typography';
import { cloneDeep, defaultsDeep } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import { useAuth } from '../../../../globalHooks/useAuth';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import { useScripts } from '../../../../layout/utils/LanguageHelper';
import {
  Draggable,
  deleteDraggable,
  updateDraggables,
} from '../../../../redux/features/styleSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { getDefaultFontSizeNum } from '../../context/FontEditorContext';
import { useStyleAnswer } from '../../context/StyleAnswerContext';
import { useStyle } from '../../context/StyleContext';
import { getDraggables } from '../../utils/draggables';
import { getInitEditorJson } from '../text/hooks/useUpdateFontStyle';

export const reorderChild: Array<Draggable> = [
  {
    id: '',
    type: DraggableType.TextRectAnswer2,
    tags: [DraggableTag.TextField],
    name: 'She',
    style: {
      width: '25%',
      height: '85%',
      left: '7.5%',
      top: '7.5%',
      color: '#000000',
      textAlign: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
      fontFamily: 'Roboto',
      backgroundColor: '#d9d9d9',
      borderRadius: '10px',
    },
    lockAspectRatio: false,
    answer: {
      correct: 'She',
      correctIsGroupIndex: true,
    },
  },
  {
    id: '',
    type: DraggableType.TextRectAnswer2,
    tags: [DraggableTag.TextField],
    name: 'likes',
    style: {
      width: '25%',
      height: '85%',
      left: '37.5%',
      top: '7.5%',
      color: '#000000',
      textAlign: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
      fontFamily: 'Roboto',
      backgroundColor: '#d9d9d9',
      borderRadius: '10px',
    },
    lockAspectRatio: false,
    answer: {
      correct: 'likes',
      correctIsGroupIndex: true,
    },
  },
  {
    id: '',
    type: DraggableType.TextRectAnswer2,
    tags: [DraggableTag.TextField],
    name: 'candies',
    style: {
      width: '25%',
      height: '85%',
      left: '67.5%',
      top: '7.5%',
      color: '#000000',
      textAlign: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
      fontFamily: 'Roboto',
      backgroundColor: '#d9d9d9',
      borderRadius: '10px',
    },
    lockAspectRatio: false,
    answer: {
      correct: 'candies',
      correctIsGroupIndex: true,
    },
  },
];

const landscapeProps: DraggableInitProps = {
  type: DraggableType.ReorderAnswer,
  tags: [DraggableTag.Group],
  style: {
    width: '45.636363%',
    height: '40.298701%',
  },
  lockAspectRatio: false,
  group: [
    {
      ...reorderChild[0],
      style: {
        ...reorderChild[0].style,
        width: '10.90909%',
        height: '11.303896%',
        left: '3.272727%',
        top: '0.997402%',
      },
    },
    {
      ...reorderChild[1],
      style: {
        ...reorderChild[1].style,
        width: '10.90909%',
        height: '11.303896%',
        left: '16.363636%',
        top: '0.997402%',
      },
    },
    {
      ...reorderChild[2],
      style: {
        ...reorderChild[2].style,
        width: '10.90909%',
        height: '11.303896%',
        left: '29.454545%',
        top: '0.997402%',
      },
    },
  ],
};

export const defaultReorderAnswerProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '100%',
      height: '30.580645%',
    },
    group: [
      {
        ...reorderChild[0],
        style: {
          ...reorderChild[0].style,
          width: '25%',
          height: '8.993548%',
          left: '7.499999%',
          top: '0.793548%',
        },
      },
      {
        ...reorderChild[1],
        style: {
          ...reorderChild[1].style,
          width: '25%',
          height: '8.993548%',
          left: '37.5%',
          top: '0.793548%',
        },
      },
      {
        ...reorderChild[2],
        style: {
          ...reorderChild[2].style,
          width: '25%',
          height: '8.993548%',
          left: '67.499999%',
          top: '0.793548%',
        },
      },
    ],
  },
};

export default function ReorderAnswer(props: DraggableOuterProps) {
  const { layout, draggables, droppableRef, draggableMapRef, draggablesPropsRef } =
    useStyle();
  const adminViewMode = useAppSelector((state) => state.adminViewMode.currentMode);
  const { styleAnswers, disableSelect } = useStyleAnswer();

  const scripts = useScripts();

  const { styleId = '' } = useParams();

  const { isStudent } = useAuth();
  const [serial, setSerial] = useState(uuidv4());

  const [groupProps, setGroupProps] = useState(() => {
    const initialGroupProps = cloneDeep(props.group) || [];

    const landscapeWidth = parseInt('43%');
    const portraitWidth = parseInt('90%');
    const gap = 5;
    let layer = 0;

    let accumulatedLeft = 0; //累積寬度

    // Fisher-Yates shuffle algorithm to shuffle the elements randomly
    for (let i = initialGroupProps.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [initialGroupProps[i], initialGroupProps[j]] = [
        initialGroupProps[j],
        initialGroupProps[i],
      ];
    }

    initialGroupProps.forEach((ele, i) => {
      const roundedWidthNumber =
        typeof ele.style.width === 'string'
          ? Math.round(parseFloat(ele.style.width?.replace('%', '')))
          : 0;

      const previousWidthNumber = initialGroupProps[i - 1]?.style.width;

      const previousRoundedWidthNumber =
        typeof previousWidthNumber === 'string'
          ? Math.round(parseFloat(previousWidthNumber.replace('%', '')))
          : 0;

      const answerAreaWidthNumber = initialGroupProps[0]?.style.width;

      const answerAreaRoundedWidthNumber =
        typeof answerAreaWidthNumber === 'string'
          ? Math.round(parseFloat(answerAreaWidthNumber.replace('%', '')))
          : 0;

      if (i === 0) {
        // reOrder第一個位置
        if (
          gap + roundedWidthNumber >
          (layout === 'landscape' ? landscapeWidth : portraitWidth)
        ) {
          layer += 1;
        }
        accumulatedLeft = gap;

        initialGroupProps[i].style.left = accumulatedLeft + '%';
        initialGroupProps[i].style.top = 12 + layer * (layout === 'landscape' ? 15 : 10);
      } else {
        // 累加前面所有元素的宽度
        accumulatedLeft += previousRoundedWidthNumber + gap;
        if (
          accumulatedLeft + roundedWidthNumber >
          (layout === 'landscape' ? landscapeWidth : portraitWidth)
        ) {
          accumulatedLeft = gap;
          layer += 1;
        }

        initialGroupProps[i].style.left = accumulatedLeft + '%';
        initialGroupProps[i].style.top = 12 + layer * (layout === 'landscape' ? 15 : 10);
      }
    });

    initialGroupProps.unshift({
      id: '',
      type: DraggableType.TextRectAnswer2,
      tags: [DraggableTag.TextField],
      name: '',
      style: {
        width: layout === 'landscape' ? landscapeWidth : portraitWidth,
        height: '10%',
        left: '5%',
        top: '0%',
        color: '#000000',
        textAlign: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
        fontFamily: 'Roboto',
        backgroundColor: '#d9d9d9',
        borderRadius: '10px',
      },
      lockAspectRatio: false,
    });

    return initialGroupProps;
  });

  useEffect(() => {
    if (isStudent) {
      setSerial(uuidv4());
    }
  }, []);

  useEffect(() => {
    if (disableSelect) {
      return;
    }
    const currentAnswer = styleAnswers.find((ele) => ele.styleId === styleId);

    const currentUserAnswer = currentAnswer?.userAnswers.find(
      (userAnswer) => userAnswer.draggableId === props.id,
    );

    if (currentUserAnswer) {
      const updatedGroupProps = groupProps.map((groupProp, index) => {
        const updatedGroupProp = { ...groupProp };

        // 處理reOrder選項是否顯示跟隱藏
        if (Array.isArray(currentUserAnswer?.answer)) {
          const matchedAnswer = currentUserAnswer.answer.find(
            (ele) => groupProp.id === ele.draggableId,
          );

          updatedGroupProp.style = {
            ...updatedGroupProp.style,
            display: matchedAnswer ? 'none' : 'block',
          };
        }

        // 處理顯示reOrder答案區塊
        if (index === 0 && Array.isArray(currentUserAnswer.answer)) {
          const text = currentUserAnswer.answer.map((ele) => ele.answer).join(' ');

          let currentTextLength = 0;

          currentTextLength += text.length;

          updatedGroupProp.style = {
            ...updatedGroupProp.style,
            color: getInitEditorJson(layout, text, getDefaultFontSizeNum(layout)),
          };

          const jsonUpdatedGroupProp =
            typeof updatedGroupProp.style.color === 'string' &&
            JSON.parse(updatedGroupProp.style.color);

          if (currentTextLength > 30) {
            jsonUpdatedGroupProp?.blocks.forEach((block: any) => {
              const text = block.text;
              if (text.length > 30) {
                const words = text.split(' ');
                let wrappedText = '';
                let lineLength = 0;

                words.forEach((word: string) => {
                  if (lineLength + word.length > 30) {
                    wrappedText += '\n';
                    lineLength = 0;
                  }
                  wrappedText += word + ' ';
                  lineLength += word.length + 1; // +1 for the space
                });

                block.text = wrappedText.trim();
              }
            });
          }

          updatedGroupProp.style.color = JSON.stringify(jsonUpdatedGroupProp);
        }

        return updatedGroupProp;
      });

      setGroupProps(updatedGroupProps);
    }
  }, [styleAnswers]);

  const draggablesRef = useRef(draggables);
  draggablesRef.current = draggables;
  const defaultProps = layout
    ? defaultReorderAnswerProps[layout]
    : defaultReorderAnswerProps.landscape;

  const defaultReorderAnswer: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '52.5vmin',
      height: '9vmin',
    },
    group: props.isClone ? undefined : reorderChild,
    originWrapperStyle: {
      width: '35vmin',
      height: 'auto',
      backgroundColor: '#f3f3f3',
      borderRadius: '6px',
      padding: 1,
    },
    selectedBar: {
      delete: true,
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultReorderAnswer,
  );

  const getMainDraggable = () => draggablesRef.current.find(({ id }) => id === props.id);

  const getNum = (num: number) => Number.parseFloat(num.toFixed(8));

  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const id = props.id;
    if (!draggablesPropsRef || !id) {
      return;
    }

    const rnd = draggableMapRef?.current?.[id]?.ref?.current;
    if (!rnd) {
      return;
    }

    const containerWidth = droppableRef?.current?.offsetWidth ?? 0;
    const originWidth =
      getNum(
        Number.parseFloat(`${defaultReorderAnswerProps[layout].style?.width ?? 0}`) / 100,
      ) * containerWidth;
    const defaultGroup = defaultReorderAnswerProps?.[layout]?.group ?? [];
    const relativeWidth1 = getNum(
      Number.parseFloat(`${defaultGroup[0]?.style?.width}`) / 100,
    );
    const relativeX1 = getNum(Number.parseFloat(`${defaultGroup[0]?.style?.left}`) / 100);
    const relativeX2 = getNum(Number.parseFloat(`${defaultGroup[1]?.style?.left}`) / 100);
    const relativeX3 = getNum(Number.parseFloat(`${defaultGroup[2]?.style?.left}`) / 100);
    const pl = Math.abs(
      containerWidth * relativeX2 -
        containerWidth * relativeX1 -
        containerWidth * relativeWidth1,
    );
    const ml = Math.abs(containerWidth * relativeX1);
    const mr = Math.abs(
      originWidth - (containerWidth * relativeX3 + containerWidth * relativeWidth1),
    );

    let originDraggable = getMainDraggable();
    if (!originDraggable) {
      return;
    }

    originDraggable = cloneDeep(originDraggable);

    draggablesPropsRef.current[id] = cloneDeep({
      ...props,
      selectedBar: {
        ...dragProps.selectedBar,
        getAddGroupChildLabel: () => scripts.addANewWord,
        // lockAspectRatio: false,
      },
    });

    const sortedDraggables = [...(originDraggable?.group ?? [])];

    // let hasFound = false;
    sortedDraggables.forEach((draggable1, index1, array) => {
      draggablesPropsRef.current[draggable1.id].selectedBar = {
        getDeleteLabel: () => scripts.deleteThisWord,
        delete: () => {
          if (array.length < 2) {
            dispatch(deleteDraggable({ id: props.id ?? '' }));
            return;
          }

          let originDraggable = getMainDraggable();
          if (!originDraggable) {
            return;
          }

          originDraggable = cloneDeep(originDraggable);

          const newGroup = sortedDraggables.filter(({ id }) => id !== draggable1.id);
          originDraggable.group = newGroup;

          originDraggable.style = {
            ...originDraggable.style,
            width: `${Number.parseFloat(`${originDraggable.style.width ?? 0}`)}%`,
          };

          dispatch(
            updateDraggables(
              (draggablesRef.current ?? []).map((d) =>
                d.id === originDraggable?.id ? originDraggable : d,
              ),
            ),
          );
        },
      };
    });

    draggablesPropsRef.current[id] = {
      ...draggablesPropsRef.current[id],
      selectedBar: {
        ...draggablesPropsRef.current[id].selectedBar,
        addGroupChild: () => {
          let originDraggable = getMainDraggable();
          if (!originDraggable) {
            return;
          }

          const lastDraggable = sortedDraggables[sortedDraggables.length - 1];
          originDraggable = cloneDeep(originDraggable);
          originDraggable.group?.push({
            ...lastDraggable,
            id: uuidv4(),
            name: 'New',
            style: {
              ...lastDraggable.style,
              top: layout === 'landscape' ? `20%` : '15%',
              left: `${
                sortedDraggables.length - 3 === 0
                  ? layout === 'landscape'
                    ? 5
                    : 10
                  : layout === 'landscape'
                  ? 5 + (sortedDraggables.length - 3) * 3
                  : 10 + (sortedDraggables.length - 3) * 3
              }%`,
            },
          });

          originDraggable.style = {
            ...originDraggable.style,
            width: `${Number.parseFloat(`${originDraggable.style.width ?? 0}`)}%`,
          };

          dispatch(
            updateDraggables(
              (draggablesRef.current ?? []).map((d) =>
                d.id === originDraggable?.id ? originDraggable : d,
              ),
            ),
          );
        },
      },
    };
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps} {...(isStudent ? { key: serial } : null)}>
      {dragProps.isClone && dragProps.group && groupProps ? (
        getDraggables(adminViewMode === 'edit' ? dragProps.group : groupProps)
      ) : (
        <CenterBox sx={{ height: '5.2vmin' }}>
          <CenterBox
            sx={{
              backgroundColor: '#d9d9d9',
              borderRadius: '10px',
            }}
          >
            <Typography sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}>
              She
            </Typography>
          </CenterBox>
          <CenterBox
            sx={{
              ml: 1,
              backgroundColor: '#d9d9d9',
              borderRadius: '10px',
            }}
          >
            <Typography sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}>
              likes
            </Typography>
          </CenterBox>
          <CenterBox
            sx={{
              ml: 1,
              backgroundColor: '#d9d9d9',
              borderRadius: '10px',
            }}
          >
            <Typography sx={{ color: dragProps.style?.color, fontSize: '1.2vw' }}>
              candies
            </Typography>
          </CenterBox>
        </CenterBox>
      )}
    </WrapperRND>
  );
}
