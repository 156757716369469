import { SvgProps } from './MenuItem';
import { useAuth } from '../../globalHooks/useAuth';

const DailySvg = ({ width, height, colorForCustomer }: SvgProps) => {
  const { managerRole } = useAuth();

  const webMode = localStorage.getItem('theme');

  if (managerRole) {
    return (
      <svg
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6956 2.42836H20.1705V1.49853C20.1705 1.22303 19.9475 1 19.6719 1C19.3964 1 19.1734 1.22303 19.1734 1.49853V2.42836H15.7198V1.49853C15.7198 1.22303 15.4968 1 15.2212 1C14.9457 1 14.7227 1.22303 14.7227 1.49853V2.42836H11.2707V1.49853C11.2707 1.22303 11.0477 1 10.7722 1C10.4967 1 10.2737 1.22303 10.2737 1.49853V2.42836H6.82002V1.49853C6.82002 1.22303 6.59699 1 6.32149 1C6.04598 1 5.82296 1.22303 5.82296 1.49853V2.42836H4.29785C2.47919 2.42836 1 3.90755 1 5.7262V21.2807C1 23.0994 2.47919 24.5785 4.29785 24.5785H21.7022C23.5208 24.5785 25 23.0994 25 21.2807V5.72784C25 3.90919 23.5208 2.43 21.7022 2.43L21.6956 2.42836ZM23.9964 21.2807C23.9964 22.5483 22.9649 23.5815 21.6956 23.5815H4.29129C3.02364 23.5815 1.9905 22.55 1.9905 21.2807V8.12702H21.8235C22.099 8.12702 22.322 7.904 22.322 7.62849C22.322 7.35299 22.099 7.12996 21.8235 7.12996H1.99378V5.72948C1.99378 4.46184 3.02528 3.4287 4.29457 3.4287H5.81968V5.4753C5.81968 5.7508 6.04271 5.97383 6.31821 5.97383C6.59371 5.97383 6.81674 5.7508 6.81674 5.4753V3.4287H10.2704V5.4753C10.2704 5.7508 10.4934 5.97383 10.7689 5.97383C11.0444 5.97383 11.2674 5.7508 11.2674 5.4753V3.4287H14.7194V5.4753C14.7194 5.7508 14.9425 5.97383 15.218 5.97383C15.4935 5.97383 15.7165 5.7508 15.7165 5.4753V3.4287H19.1701V5.4753C19.1701 5.7508 19.3932 5.97383 19.6687 5.97383C19.9442 5.97383 20.1672 5.7508 20.1672 5.4753V3.4287H21.6923C22.96 3.4287 23.9931 4.4602 23.9931 5.72948V7.62849V21.2823L23.9964 21.2807Z"
          fill={webMode === 'Light' ? '#090446' : '#fff'}
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="0.5"
        />
        <path
          d="M15.4244 14.1585L12.3938 17.189L10.3423 15.1375C10.1472 14.9423 9.83065 14.9423 9.63715 15.1375C9.442 15.3326 9.442 15.6491 9.63715 15.8426L12.0429 18.2484C12.1396 18.3451 12.2676 18.3943 12.3955 18.3943C12.5234 18.3943 12.6513 18.3451 12.748 18.2484L16.1312 14.8653C16.3263 14.6701 16.3263 14.3536 16.1312 14.1601C15.936 13.9649 15.6195 13.9649 15.426 14.1601L15.4244 14.1585Z"
          fill={webMode === 'Light' ? '#090446' : '#fff'}
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="0.5"
        />
        <path
          d="M12.994 9.82925C9.66174 9.82925 6.94934 12.54 6.94934 15.8739C6.94934 19.2079 9.6601 21.9186 12.994 21.9186C16.328 21.9186 19.0387 19.2079 19.0387 15.8739C19.0387 12.54 16.3263 9.82925 12.994 9.82925ZM12.994 20.9183C10.2111 20.9183 7.94804 18.6552 7.94804 15.8723C7.94804 13.0894 10.2111 10.8263 12.994 10.8263C15.7769 10.8263 18.04 13.0894 18.04 15.8723C18.04 18.6552 15.7769 20.9183 12.994 20.9183Z"
          fill={webMode === 'Light' ? '#090446' : '#fff'}
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="0.5"
        />
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={colorForCustomer} />
    </svg>
  );
};

export default DailySvg;
