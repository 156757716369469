import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Stack from '@mui/material/Stack';
import {
  ContentBlock,
  DraftInlineStyle,
  DraftInlineStyleType,
  Editor,
  EditorState,
  KeyBindingUtil,
  Modifier,
  RawDraftContentState,
  SelectionState,
  convertFromRaw,
  convertToRaw,
  genKey,
  getDefaultKeyBinding,
} from 'draft-js';
import { cloneDeep } from 'lodash-es';
import { useParams } from 'react-router-dom';

import { DraggableType, Layout } from '../../../../../graphql/resolver.types';
import { updateDraggable } from '../../../../../redux/features/styleSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
  getDefaultFontSizeNum,
  defaultFontWeightNum,
  getFontSizeNum,
  getFontWeightNum,
  useFontEditorContext,
  getFontWeight,
  getFontSize,
} from '../../../context/FontEditorContext';
import { useStyleAnswer, StyleAnswerItem } from '../../../context/StyleAnswerContext';
import { useStyle } from '../../../context/StyleContext';
import { TextAlign } from '../../FontAlign';
import { FontWeightType } from '../../text/FontWeightOption2';
import {
  EditTextAlignBlock,
  EditTextType,
  getEditorState,
  getEditorStateFromJson,
  getInitEditorJson,
  getRaw,
  isEditorJSONString,
} from '../../text/hooks/useUpdateFontStyle';

export interface TextFieldBaseProps {
  id?: string;
  name?: string;
  isClone?: boolean; // true is clone, false is origin
  style?: React.CSSProperties;
  multiline?: boolean;
  maxRows?: number;
  correctIsSameAsName?: boolean;
  type: DraggableType;
  originId?: string;
}
export const getDroppableMaxWidth = (layout: Layout) => {
  return layout === Layout.Landscape ? 1378 : 436;
};

export const getFontSizeAndFontWeight = (layout: Layout, editorState: EditorState) => {
  const raw = convertToRaw(editorState.getCurrentContent());
  const hasFontSizeStyle =
    raw.blocks.findIndex(
      (block) =>
        block.inlineStyleRanges.findIndex(
          (inlineStyleRange) =>
            inlineStyleRange.style.indexOf(EditTextType.FontSize) === 0,
        ) > -1,
    ) > -1;
  const hasFontWeightStyle =
    raw.blocks.findIndex(
      (block) =>
        block.inlineStyleRanges.findIndex(
          (inlineStyleRange) =>
            inlineStyleRange.style.indexOf(EditTextType.FontWeight) === 0,
        ) > -1,
    ) > -1;
  let fontSize = hasFontSizeStyle
    ? Number.MIN_SAFE_INTEGER
    : getDefaultFontSizeNum(layout);
  let fontWeight = hasFontWeightStyle ? Number.MIN_SAFE_INTEGER : defaultFontWeightNum;
  if (hasFontSizeStyle || hasFontWeightStyle) {
    fontSize = Number.MIN_SAFE_INTEGER;
    raw.blocks.forEach((block) => {
      block.inlineStyleRanges.forEach((inlineStyleRange) => {
        const style = inlineStyleRange.style;
        if (style.indexOf(EditTextType.FontSize) === 0) {
          fontSize = Math.max(
            fontSize,
            getFontSizeNum(layout, style.slice(EditTextType.FontSize.length, -2)),
          );
        }
        if (style.indexOf(EditTextType.FontWeight) === 0) {
          fontWeight = Math.max(
            fontWeight,
            getFontWeightNum(style.slice(EditTextType.FontWeight.length)),
          );
        }
      });
    });
  }

  return {
    fontSize: `${fontSize}`,
    fontWeight: `${fontWeight}`,
  };
};
export const getFontWeightForToolbar = (
  editorState: EditorState,
  isEditingText: boolean,
): number | null => {
  const raw = getRaw(editorState);

  if (raw.blocks.length === 1 && raw.blocks[0].text.length === 0) {
    return null;
  }

  let fontWidth: number | null = null;
  if (!isEditingText) {
    if (
      new Set(
        raw.blocks.flatMap((block) =>
          block.inlineStyleRanges.flatMap(({ style }) =>
            style.indexOf(EditTextType.FontWeight) > -1
              ? (fontWidth = Number.parseFloat(
                  style.slice(EditTextType.FontWeight.length),
                ))
              : [],
          ),
        ),
      ).size > 1
    ) {
      return null;
    }

    return fontWidth;
  } else {
    const blocks = editorState.getCurrentContent().getBlocksAsArray();
    const anchorKeyIndex = blocks.findIndex(
      (block) => block.getKey() === editorState.getSelection().getAnchorKey(),
    );
    const focusKeyIndex = blocks.findIndex(
      (block) => block.getKey() === editorState.getSelection().getFocusKey(),
    );

    let minIndex = focusKeyIndex;
    let minLoc = editorState.getSelection().getFocusOffset();
    let maxIndex = anchorKeyIndex;
    let maxLoc = editorState.getSelection().getAnchorOffset();
    if (anchorKeyIndex <= focusKeyIndex) {
      const isSameIndex = anchorKeyIndex === focusKeyIndex;
      const achorOffset = editorState.getSelection().getAnchorOffset();
      const focusOffset = editorState.getSelection().getFocusOffset();
      minIndex = anchorKeyIndex;
      minLoc = isSameIndex ? Math.min(achorOffset, focusOffset) : achorOffset;
      maxIndex = focusKeyIndex;
      maxLoc = isSameIndex ? Math.max(achorOffset, focusOffset) : focusOffset;
    }
    if (minIndex === maxIndex && minLoc === maxLoc) {
      if (minLoc === 0) {
        maxLoc = 1;
      } else {
        minLoc--;
      }
    }

    if (
      new Set(
        raw.blocks.flatMap((block, blockIndex) => {
          if (!(blockIndex >= minIndex && blockIndex <= maxIndex)) {
            return [];
          } else if (blockIndex > minIndex && blockIndex < maxIndex) {
            return block.inlineStyleRanges.flatMap(({ style }) =>
              style.indexOf(EditTextType.FontWeight) > -1
                ? (fontWidth = Number.parseFloat(
                    style.slice(EditTextType.FontWeight.length),
                  ))
                : [],
            );
          } else if (blockIndex === minIndex) {
            return block.inlineStyleRanges.flatMap(({ offset, length, style }) =>
              style.indexOf(EditTextType.FontWeight) > -1 &&
              offset <= minLoc &&
              !(offset + length <= minLoc) &&
              (blockIndex < maxIndex || offset + length >= maxLoc)
                ? (fontWidth = Number.parseFloat(
                    style.slice(EditTextType.FontWeight.length),
                  ))
                : [],
            );
          } else if (blockIndex === maxIndex) {
            return block.inlineStyleRanges.flatMap(({ offset, length, style }) =>
              style.indexOf(EditTextType.FontWeight) > -1 &&
              blockIndex > minIndex &&
              maxLoc > offset &&
              maxLoc <= offset + length
                ? (fontWidth = Number.parseFloat(
                    style.slice(EditTextType.FontWeight.length),
                  ))
                : [],
            );
          } else {
            return [];
          }
        }),
      ).size > 1
    ) {
      return null;
    }

    return fontWidth;
  }
};
export const getFontAlignForToolbar = (
  editorState: EditorState,
  isEditingText: boolean,
): TextAlign | null => {
  const raw = getRaw(editorState);
  const types = raw.blocks.map((block) => block.type);
  if (types.length === 0) {
    return null;
  }

  let type: string | null = null;
  if (!isEditingText) {
    if (new Set(raw.blocks.map((block) => block.type)).size > 1) {
      return null;
    } else {
      type = types[0];
      if (type === EditTextAlignBlock.Left) {
        return 'left';
      }
      if (type === EditTextAlignBlock.Center) {
        return 'center';
      }
      if (type === EditTextAlignBlock.Right) {
        return 'right';
      }
    }
  } else {
    const blocks = editorState.getCurrentContent().getBlocksAsArray();
    const anchorKeyIndex = blocks.findIndex(
      (block) => block.getKey() === editorState.getSelection().getAnchorKey(),
    );
    const focusKeyIndex = blocks.findIndex(
      (block) => block.getKey() === editorState.getSelection().getFocusKey(),
    );
    const minIndex = Math.min(anchorKeyIndex, focusKeyIndex);
    const maxIndex = Math.max(anchorKeyIndex, focusKeyIndex);
    if (
      new Set(
        raw.blocks.flatMap((block, blockIndex) =>
          blockIndex >= minIndex && blockIndex <= maxIndex ? (type = block.type) : [],
        ),
      ).size > 1
    ) {
      return null;
    }

    if (type === EditTextAlignBlock.Left) {
      return 'left';
    }
    if (type === EditTextAlignBlock.Center) {
      return 'center';
    }
    if (type === EditTextAlignBlock.Right) {
      return 'right';
    }
  }

  return null;
};
export const getFontSizeNumForToolbar = (
  layout: Layout,
  editorState: EditorState,
  isEditingText: boolean,
) => {
  const raw = getRaw(editorState);
  let fontSizeNum = getDefaultFontSizeNum(layout);
  if (!isEditingText) {
    if (
      new Set(
        raw.blocks.flatMap((block) =>
          block.inlineStyleRanges.flatMap((inlineStyleRange) =>
            inlineStyleRange.style.indexOf(EditTextType.FontSize) > -1
              ? (fontSizeNum = Number.parseFloat(
                  inlineStyleRange.style.slice(EditTextType.FontSize.length),
                ))
              : [],
          ),
        ),
      ).size > 1
    ) {
      return null;
    }

    return Math.max(fontSizeNum, 12);
  } else {
    const blocks = editorState.getCurrentContent().getBlocksAsArray();
    const anchorKeyIndex = blocks.findIndex(
      (block) => block.getKey() === editorState.getSelection().getAnchorKey(),
    );
    const focusKeyIndex = blocks.findIndex(
      (block) => block.getKey() === editorState.getSelection().getFocusKey(),
    );
    const minIndex = Math.min(anchorKeyIndex, focusKeyIndex);
    const maxIndex = Math.max(anchorKeyIndex, focusKeyIndex);
    if (
      new Set(
        raw.blocks.flatMap((block, blockIndex) =>
          blockIndex >= minIndex && blockIndex <= maxIndex
            ? block.inlineStyleRanges.flatMap((inlineStyleRange) =>
                inlineStyleRange.style.indexOf(EditTextType.FontSize) > -1
                  ? (fontSizeNum = Number.parseFloat(
                      inlineStyleRange.style.slice(EditTextType.FontSize.length),
                    ))
                  : [],
              )
            : [],
        ),
      ).size > 1
    ) {
      return null;
    }

    return Math.max(fontSizeNum, 12);
  }

  // return null;
};

export function getCustomStyleFn(
  layout: Layout,
  droppableRef: React.RefObject<HTMLDivElement> | null,
) {
  return function (style: DraftInlineStyle, block: ContentBlock) {
    const cssProps: React.CSSProperties = {};
    style.forEach((value) => {
      if (value?.indexOf(EditTextType.FontColor) === 0) {
        cssProps.color = value.slice(EditTextType.FontColor.length);
      } else if (value?.indexOf(EditTextType.FontFamily) === 0) {
        cssProps.fontFamily = value.slice(EditTextType.FontFamily.length);
      } else if (value?.indexOf(EditTextType.FontSize) === 0) {
        const fontSize = value.slice(EditTextType.FontSize.length, -2);
        const fontSizeNum =
          getFontSizeNum(layout, fontSize) *
          ((droppableRef?.current?.offsetWidth ?? 0) / getDroppableMaxWidth(layout));
        cssProps.fontSize = `${fontSizeNum > 12 ? fontSizeNum : 12}px`;
      } else if (value?.indexOf(EditTextType.FontWeight) === 0) {
        cssProps.fontWeight = value.slice(EditTextType.FontWeight.length);
      }
    });

    if (typeof cssProps?.fontSize === 'undefined') {
      const fontSizeNum =
        getFontSizeNum(layout, getDefaultFontSizeNum(layout)) *
        ((droppableRef?.current?.offsetWidth ?? 0) / getDroppableMaxWidth(layout));
      cssProps.fontSize = `${fontSizeNum > 12 ? fontSizeNum : 12}px`;
    }

    return cssProps;
  };
}

export function responsiveFontSize(fontSize: string | number | undefined) {
  // responsive fontSize formula
  return typeof fontSize === 'string'
    ? parseFloat(fontSize) / 6
    : typeof fontSize === 'number'
    ? fontSize / 6
    : 1.5; // unit is "vw". You need to add vw after the return
}

export default function TextFieldBase({
  id,
  name,
  isClone,
  style,
  multiline = false,
  maxRows,
  correctIsSameAsName,
  type,
  originId,
}: TextFieldBaseProps) {
  const dispatch = useAppDispatch();
  const {
    layout,
    selectedId,
    isEditingText,
    setIsEditingText,
    droppableRef,
    draggables,
    draggableMapRef,
    getParentId,
  } = useStyle();
  const isEditingTextRef = useRef(isEditingText);
  isEditingTextRef.current = isEditingText;
  const isSelected = selectedId === id;
  const isSelectedRef = useRef(isSelected);
  isSelectedRef.current = isSelected;
  const isSubDraggableRef = useRef(
    draggables.findIndex((draggable) => draggable.id === (originId ?? id)) === -1,
  );

  const isCustomText = type === DraggableType.CustomText;
  const isTextCircleAnswer = type === DraggableType.TextCircleAnswer;
  const isTextRectAnswer = type === DraggableType.TextRectAnswer;
  const isTextRectAnswer2 = type === DraggableType.TextRectAnswer2;
  const [text] = React.useState(
    (isTextCircleAnswer || isTextRectAnswer
      ? ''
      : isCustomText && !isSubDraggableRef.current
      ? ''
      : isTextRectAnswer2 && name === 'New'
      ? 'word'
      : null) ??
      name ??
      '',
  );
  const [editing, setEditing] = React.useState<boolean>(false);
  const editingRef = useRef(editing);
  editingRef.current = editing;

  const { styleAnswers, setStyleAnswers, disableSelect } = useStyleAnswer();
  const adminViewMode = useAppSelector((state) => state.adminViewMode.currentMode);
  const { styleId = '' } = useParams();

  const {
    editorRefs,
    cursorRefs,
    extendedBlockRenderMap,
    addUndo,
    getUndo,
    getRedo,
    clearUndoAndRedo,
    textLockAspectRatioRef,
    setFontSizeNumRef,
    setFontAlignRef,
    setFontWeightNumRef,
    minDivElInfoRef,
    copyTextInfoRef,
  } = useFontEditorContext();

  const isFromUndoOrRedoRef = useRef(false);

  const maxWidth = style?.maxWidth;
  const borderRadius = style?.borderRadius;
  const backgroundColor = isClone ? style?.backgroundColor : '#d9d9d9';
  const alignItems = style?.alignItems;
  // const overflow = style?.overflow;
  const newBlockKeyRef = useRef(genKey());
  const color = isEditorJSONString(style?.color)
    ? style?.color
    : getInitEditorJson(
        layout,
        text,
        getDefaultFontSizeNum(layout),
        newBlockKeyRef.current,
      );
  const WebkitTextFillColor = '#000';
  const borderStyle = style?.borderStyle;
  const borderWidth = `${style?.borderWidth}px`;
  const borderColor = style?.borderColor;

  const colorRef = useRef(JSON.parse(color ?? ''));
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(convertFromRaw(colorRef.current)),
  );
  const editorStateRef = useRef(editorState);
  editorStateRef.current = editorState;
  const prevEditorStateRef = useRef(editorState);

  const editorRef = useRef<Editor>(null);
  const stackRef = useRef<HTMLDivElement>(null);

  const getForceLastBlockEditorState = (editorState: EditorState) => {
    const currentContent = editorState.getCurrentContent();
    const blocks = currentContent.getBlocksAsArray();
    const lastBlock = blocks?.[blocks.length - 1];
    if (lastBlock) {
      return EditorState.forceSelection(
        editorState,
        editorState.getSelection().merge({
          anchorKey: lastBlock.getKey(),
          anchorOffset: lastBlock.getText().length,
          focusKey: lastBlock.getKey(),
          focusOffset: lastBlock.getText().length,
        }),
      );
    }

    return editorState;
  };

  const getDroppableWidth = () => {
    return droppableRef?.current?.offsetWidth ?? 0;
  };
  const getDroppableHeight = () => {
    return droppableRef?.current?.offsetHeight ?? 0;
  };
  const getWrapperWidth = () => {
    return stackRef.current?.parentElement?.parentElement?.offsetWidth ?? 0;
  };
  const getWrapperHeight = () => {
    return stackRef.current?.parentElement?.parentElement?.offsetHeight ?? 0;
  };
  const getCurrentWidth = () => {
    return `${Math.min((getWrapperWidth() / getDroppableWidth()) * 100, 100)}%`;
  };
  const getCurrentHeight = () => {
    return `${Math.min((getWrapperHeight() / getDroppableHeight()) * 100, 100)}%`;
  };
  const getWrapperLeft = () => {
    return (Number.parseFloat(`${style?.left}`) / 100) * getDroppableWidth();
  };
  const getWrapperTop = () => {
    return (Number.parseFloat(`${style?.top}`) / 100) * getDroppableHeight();
  };
  // const getWrapperWidthPx = () => {
  //   return `${(Number.parseFloat(`${style?.width}`) / 100) * getDroppableWidth()}px`;
  // };
  // const getWrapperHeightPx = () => {
  //   return `${(Number.parseFloat(`${style?.height}`) / 100) * getDroppableHeight()}px`;
  // };
  const getWrapperDefaultMaxWidth = () => {
    return maxWidth
      ? (Number.parseFloat(`${maxWidth}`) / 100) * getDroppableWidth()
      : getDroppableWidth() - getWrapperLeft();
  };
  const getWrapperDefaultMaxWidthPx = () => {
    return `${getWrapperDefaultMaxWidth()}px`;
  };
  const getWrapperDefaultMaxHeightPx = () => {
    return `${getDroppableHeight() - getWrapperTop()}px`;
  };

  const updateSelect = (editorState: EditorState) => {
    if (!isSelectedRef.current) {
      return;
    }

    const setFontSizeNum = setFontSizeNumRef?.current;
    if (setFontSizeNum) {
      setFontSizeNum(
        getFontSizeNumForToolbar(layout, editorState, isEditingTextRef.current),
      );
    }
    const setFontAlign = setFontAlignRef?.current;
    if (setFontAlign) {
      setFontAlign(getFontAlignForToolbar(editorState, isEditingTextRef.current));
    }
    const setFontWeightNum = setFontWeightNumRef?.current;
    if (setFontWeightNum) {
      setFontWeightNum(getFontWeightForToolbar(editorState, isEditingTextRef.current));
    }
  };

  const isFromDoubleClickRef = useRef(false);
  const lastRawStringRef = useRef<string | null>(null);

  const currentSelectionStateRef = useRef<SelectionState | null>(null);

  const getDispatchObj = (id: string, editing: boolean) => {
    const newName = getName(editorStateRef.current);
    const raw = getRaw(editorStateRef.current);
    const rawJSON = JSON.stringify(raw);

    return {
      id,
      ...(style?.color !== rawJSON
        ? {
            style: {
              color: JSON.stringify(
                convertToRaw(editorStateRef.current.getCurrentContent()),
              ),
              ...getFontSizeAndFontWeight(layout, editorStateRef.current),
              ...(!isSubDraggableRef.current
                ? {
                    width: getCurrentWidth(),
                    height: getCurrentHeight(),
                  }
                : null),
              ...(!maxWidth &&
              !isSubDraggableRef.current &&
              textLockAspectRatioRef?.current === null &&
              editing &&
              Math.abs(getDroppableWidth() - getWrapperWidth() - getWrapperLeft()) < 4 &&
              Math.abs(
                ((
                  stackRef.current?.getElementsByClassName('DraftEditor-root').item(0) as
                    | HTMLDivElement
                    | null
                    | undefined
                )?.offsetHeight ?? 0) -
                  (Number.parseFloat(`${style?.height}`) / 100) *
                    (droppableRef?.current?.offsetHeight ?? 0),
              ) > 4
                ? { maxWidth: getCurrentWidth() }
                : null),
            },
          }
        : null),
      // name: newName,
      answer: correctIsSameAsName
        ? {
            correct: newName,
          }
        : undefined,
    };
  };

  // func
  function handleEdit(e?: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) {
    e && e.stopPropagation();
    if (!isClone || !id) {
      return;
    }

    if (!editing) {
      isFromDoubleClickRef.current = true;
    }

    setEditing((prev) => !prev);
    setIsEditingText(!editing);

    const raw = getRaw(editorStateRef.current);
    const rawJSON = JSON.stringify(raw);

    if (!editing || lastRawStringRef.current !== rawJSON) {
      dispatch(updateDraggable(getDispatchObj(id, editing)));
    }

    setEditorState(getForceLastBlockEditorState(editorState));
  }

  const defaultCursorRef = useRef(
    Array.isArray(colorRef.current?.blocks) && colorRef.current?.blocks.length > 0
      ? {
          anchorKey: colorRef.current.blocks[0].key,
          anchorOffset: 0,
          focusKey: colorRef.current?.blocks?.[1]?.key ?? colorRef.current.blocks[0].key,
          focusOffset: 0,
        }
      : null,
  );
  const forceNextBlockCounterRef = useRef(0);
  const getForceNextBlockEditorState = (editorState: EditorState, isFirst?: boolean) => {
    //為了觸發 editor 的 customStyleFn
    const increaseNum = 2;
    if (isFirst) {
      forceNextBlockCounterRef.current = 0;
    }
    const forceNextBlockCounter = forceNextBlockCounterRef.current;

    const currentContent = editorState.getCurrentContent();
    const blocks = currentContent.getBlocksAsArray();
    const firstBlock = blocks?.[forceNextBlockCounter];
    const lastBlock =
      blocks?.[forceNextBlockCounter + increaseNum - 1] ??
      blocks?.[forceNextBlockCounter + increaseNum - 2];
    if (firstBlock && lastBlock) {
      if (blocks.length > 2) {
        forceNextBlockCounterRef.current += increaseNum;
      } else {
        const cursor = defaultCursorRef.current;
        if (!isSelected && cursor) {
          return EditorState.forceSelection(
            editorState,
            editorState.getSelection().merge({
              anchorKey: cursor.anchorKey,
              anchorOffset: 0,
              focusKey: cursor.focusKey,
              focusOffset: 0,
              hasFocus: false,
            }),
          );
        }
      }

      return EditorState.forceSelection(
        editorState,
        editorState.getSelection().merge({
          anchorKey: firstBlock.getKey(),
          anchorOffset: 0,
          focusKey: lastBlock.getKey(),
          focusOffset: 0,
          hasFocus: false,
        }),
      );
    }

    forceNextBlockCounterRef.current = 0;

    const cursor = defaultCursorRef.current;
    if (!isSelected && cursor) {
      return EditorState.forceSelection(
        editorState,
        editorState.getSelection().merge({
          anchorKey: cursor.anchorKey,
          anchorOffset: 0,
          focusKey: cursor.anchorKey,
          focusOffset: 0,
          hasFocus: false,
        }),
      );
    }

    return editorState;
  };

  const onDoubleClick = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!editing) {
      handleEdit(e);
    }
  };

  function handleReorderAnswer({ text }: { text?: string }) {
    setStyleAnswers((prev) => {
      const prevUserAnswer = cloneDeep(prev);

      const prevUserAnswerIndex = prevUserAnswer.findIndex(
        (ele) => ele.styleId === styleId,
      );

      const existingStyleAnswer = prevUserAnswer[prevUserAnswerIndex];

      if (existingStyleAnswer && text) {
        //先找styleAnswer
        const existingUserAnswerIndex = existingStyleAnswer.userAnswers.findIndex(
          (ele) => {
            return ele.draggableId === getParentId(id ?? '');
          },
        );

        if (existingStyleAnswer.userAnswers[existingUserAnswerIndex]) {
          const answer =
            existingStyleAnswer?.userAnswers[existingUserAnswerIndex]?.answer;

          if (Array.isArray(answer)) {
            const existingInnerUserIndex = answer.findIndex((ele: any) => {
              return ele.draggableId === id;
            });

            if (existingInnerUserIndex !== -1) {
              answer.splice(existingInnerUserIndex, 1);
            } else {
              answer.push({
                draggableId: id || '',
                answer: text,
              });
            }
          }
        } else {
          existingStyleAnswer.userAnswers.push({
            draggableId: getParentId(id ?? '') || '',
            answer: [{ draggableId: id as string, answer: text }],
            type: 'ReOrder',
          });
        }
      } else {
        const updatedAnswerItem = {
          styleId,
          userAnswers: [
            {
              draggableId: getParentId(id ?? '') || '',
              answer: [{ draggableId: id, answer: text }],
              type: 'ReOrder',
            },
          ],
          isDone: false,
        };
        return [...prevUserAnswer, updatedAnswerItem] as StyleAnswerItem[]; //add new Answer
      }

      return [...prevUserAnswer];
    });
  }

  const onChange = (internalEditorState: EditorState) => {
    currentSelectionStateRef.current = internalEditorState.getSelection();

    prevEditorStateRef.current = editorStateRef.current;

    if (cursorRefs) {
      cursorRefs.anchorKeyRef.current = internalEditorState.getSelection().getAnchorKey();
      cursorRefs.anchorOffsetRef.current = internalEditorState
        .getSelection()
        .getAnchorOffset();
      cursorRefs.focusKeyRef.current = internalEditorState.getSelection().getFocusKey();
      cursorRefs.focusOffsetRef.current = internalEditorState
        .getSelection()
        .getFocusOffset();
    }

    if (
      adminViewMode !== 'view' &&
      !(!isEditingText && isSelected) &&
      typeof textLockAspectRatioRef?.current !== 'boolean'
    ) {
      //console.log(text, '有執行17');
      if (isSelected) {
        focusEditor(internalEditorState);
        updateSelect(internalEditorState);
      }
      setEditorState(internalEditorState);
    }
  };

  const onClick = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const updateStyleAnswers = () => {
      if (name !== '') {
        return;
      }
      const updatedStyleAnswers = styleAnswers.map((styleAnswer) => {
        if (styleAnswer.styleId === styleId) {
          const updatedUserAnswers = styleAnswer.userAnswers.map((userAnswer) => {
            if (userAnswer.type === 'ReOrder' && Array.isArray(userAnswer.answer)) {
              const newAnswer = [...userAnswer.answer];
              newAnswer.pop();
              return {
                ...userAnswer,
                answer: newAnswer,
              };
            }
            return userAnswer;
          });
          return {
            ...styleAnswer,
            userAnswers: updatedUserAnswers,
          };
        }
        return styleAnswer;
      });

      setStyleAnswers(updatedStyleAnswers);
    };

    updateStyleAnswers();

    const reOrderQuestion = draggables.find((draggable) => {
      return (
        draggable.name.includes('ReorderAnswer') &&
        draggable.group?.some((ele) => {
          return ele.id === id;
        })
      );
    });

    if (reOrderQuestion) {
      const isReOrderEle = reOrderQuestion.group?.some((ele) => {
        return ele.id === id;
      });
      if (isReOrderEle && !disableSelect) {
        if (e && e.target) {
          const target = e.target as HTMLElement;

          handleReorderAnswer({ text: target?.textContent || '' });
        }
      }
    } else {
      return;
    }
  };

  const focusEditor = (editorState: EditorState) => {
    if (editorRefs && id) {
      editorRefs.elementRef.current = stackRef.current;
      editorRefs.selectedIdRef.current = id;
      editorRefs.editorRef.current = editorRef;
      editorRefs.editorStateRef.current = editorState;
      editorRefs.setEditorStateRef.current = setEditorState;
    }
  };

  const getName = (editorState: EditorState) => {
    const emptyArr: unknown[] = [];
    return editorState
      .getCurrentContent()
      .getBlocksAsArray()
      .flatMap((block) => {
        const text = block.getText();
        if (text) {
          return text;
        }

        return emptyArr as string[];
      })
      .join(' ');
  };

  React.useEffect(() => {
    if (editing) {
      if (isSelected) {
        onChange(getForceLastBlockEditorState(editorStateRef.current));
      } else {
        handleEdit();
      }
      //console.log(text, '有執行1');
    }
  }, [editing, isSelected]);

  useEffect(() => {
    if (!isSelected) {
      //console.log(text, '有執行2');
      editorRef.current?.blur();
      clearUndoAndRedo();
    } else {
      updateSelect(editorState);
    }

    currentSelectionStateRef.current = null;
  }, [isSelected]);

  const divElRef = useRef<HTMLDivElement>();
  const spanElementsRef = useRef<
    { element: HTMLSpanElement; originFontSize: number; newFontSize: number }[]
  >([]);
  const spanElementsCopyRef = useRef<
    { element: HTMLSpanElement; originFontSize: number; newFontSize: number }[]
  >([]);
  const blocksIndexRef = useRef<number[]>([]);
  useLayoutEffect(() => {
    if (isSubDraggableRef.current) {
      return;
    }

    //console.log(text, '有執行3');
    const divEl = document.createElement('div');
    divEl.style.position = 'absolute';
    divEl.style.left = `${-(window.screen.availWidth * 2)}px`;
    divEl.style.top = `${-(window.screen.availHeight * 2)}px`;
    divEl.style.opacity = '0';
    // divEl.style.left = `${10}px`;
    // divEl.style.bottom = `${10}px`;
    const wrapperElement = stackRef.current?.parentElement?.parentElement;
    const wrapperElementCopy = stackRef.current?.parentElement?.parentElement?.cloneNode(
      true,
    ) as HTMLDivElement | undefined;
    const resizeHandle = wrapperElementCopy?.children?.item(1) as
      | HTMLDivElement
      | null
      | undefined;
    if (wrapperElement && wrapperElementCopy && resizeHandle) {
      wrapperElement.style.maxWidth = `${
        getWrapperDefaultMaxWidth() + (!maxWidth && isFromDoubleClickRef.current ? 1 : 0)
      }px`;
      isFromDoubleClickRef.current = false;
      wrapperElement.style.maxHeight = getWrapperDefaultMaxHeightPx();

      wrapperElementCopy.style.position = 'static';
      wrapperElementCopy.style.transform = 'none';

      if (!isTextCircleAnswer) {
        wrapperElement.style.width = maxWidth ? getWrapperDefaultMaxWidthPx() : 'auto';
        wrapperElement.style.height = 'auto';
        wrapperElementCopy.style.width = maxWidth
          ? getWrapperDefaultMaxWidthPx()
          : 'auto';
        wrapperElementCopy.style.height = 'auto';
      } else {
        const width = (Number.parseFloat(`${style?.width}`) / 100) * getDroppableWidth();
        if (Number.isFinite(width)) {
          wrapperElement.style.width = `${width}px`;
          wrapperElement.style.height = `${width}px`;
          wrapperElementCopy.style.width = `${width}px`;
          wrapperElementCopy.style.height = `${width}px`;
        }
      }

      divEl.style.maxWidth = getWrapperDefaultMaxWidthPx();
      divEl.style.maxHeight = getWrapperDefaultMaxHeightPx();

      resizeHandle.style.display = 'none';

      divEl.replaceChildren(wrapperElementCopy);
    }
    document.body.appendChild(divEl);
    divElRef.current = divEl;

    const spanElements: {
      element: HTMLSpanElement;
      originFontSize: number;
      newFontSize: number;
    }[] = [];
    const spanElementsCopy = [...spanElements];
    const spanElementArr = [spanElements, spanElementsCopy];
    blocksIndexRef.current = [];
    let blockIndex = 0;
    let minSizeNum = Number.MAX_SAFE_INTEGER;
    [
      stackRef.current?.getElementsByClassName('public-DraftStyleDefault-block'),
      divElRef.current?.getElementsByClassName('public-DraftStyleDefault-block'),
    ].forEach((blocks, index) => {
      if (!blocks) {
        return;
      }

      for (let i = 0; i < blocks.length; i++) {
        blocksIndexRef.current.push(blockIndex);
        for (let j = 0; j < (blocks.item(i)?.children.length ?? 0); j++) {
          const span = blocks.item(i)?.children.item(j) as
            | HTMLSpanElement
            | null
            | undefined;
          if (!span) {
            break;
          }

          blockIndex++;

          const fontSizeNum = Number(
            `${
              span
                .getAttribute('style')
                ?.match(/font-size:\s?\d+(\.\d+)?px/g)?.[0]
                ?.match(/\d+(\.\d+)?/g)?.[0]
            }`,
          );
          const originFontSize = Math.max(
            Math.round(
              fontSizeNum /
                ((droppableRef?.current?.offsetWidth ?? 0) /
                  getDroppableMaxWidth(layout)),
            ),
            12,
          );
          minSizeNum = Math.min(minSizeNum, originFontSize);
          spanElementArr[index].push({
            element: span,
            originFontSize,
            newFontSize: originFontSize,
          });
          if (fontSizeNum < 12) {
            span.style.fontSize = '12px';
          }
        }
      }
    });
    spanElementsRef.current = spanElements;
    spanElementsCopyRef.current = spanElementsCopy;

    const editorElement = (
      (stackRef.current?.getElementsByClassName('DraftEditor-root')?.length ?? 0) > 0
        ? stackRef.current?.getElementsByClassName('DraftEditor-root')?.item(0)
        : null
    ) as HTMLDivElement | null | undefined;
    if (editorElement) {
      editorElement.style.width = '100%';
    }

    const minDivEl = divEl.cloneNode(true) as HTMLDivElement;
    const adjustNum =
      minSizeNum <= 12 || minSizeNum === Number.MAX_SAFE_INTEGER ? 0 : minSizeNum - 12;
    [minDivEl.getElementsByClassName('public-DraftStyleDefault-block')].forEach(
      (blocks) => {
        if (!blocks) {
          return;
        }

        for (let i = 0; i < blocks.length; i++) {
          blocksIndexRef.current.push(blockIndex);
          for (let j = 0; j < (blocks.item(i)?.children.length ?? 0); j++) {
            const span = blocks.item(i)?.children.item(j) as
              | HTMLSpanElement
              | null
              | undefined;
            if (!span) {
              break;
            }

            const fontSizeNum = Number(
              `${
                span
                  .getAttribute('style')
                  ?.match(/font-size:\s?\d+(\.\d+)?px/g)?.[0]
                  ?.match(/\d+(\.\d+)?/g)?.[0]
              }`,
            );
            const originFontSize = Math.max(
              Math.round(
                fontSizeNum /
                  ((droppableRef?.current?.offsetWidth ?? 0) /
                    getDroppableMaxWidth(layout)),
              ),
              12,
            );

            span.style.fontSize = `${Math.max(originFontSize - adjustNum, 12)}px`;
          }
        }
      },
    );
    document.body.appendChild(minDivEl);
    if (minDivElInfoRef && id) {
      minDivElInfoRef.current = {
        id,
        minDivEl,
      };
    }

    return () => {
      document.body.removeChild(divEl);
      document.body.removeChild(minDivEl);
    };
  }, [isEditingText, editorState, maxWidth, style?.width]);

  const isFromMouseDownRef = useRef(false);
  const cursorRef = useRef({
    anchorKey: cursorRefs?.anchorKeyRef.current ?? newBlockKeyRef.current,
    anchorOffset: cursorRefs?.anchorOffsetRef.current ?? 0,
    focusKey: cursorRefs?.focusKeyRef.current ?? newBlockKeyRef.current,
    focusOffset: cursorRefs?.focusOffsetRef.current ?? 0,
    hasFocus: true,
  });
  const lastScrollHeightRef = useRef(
    stackRef.current?.parentElement?.parentElement?.scrollHeight ?? 0,
  );
  const lastClientHeightRef = useRef(
    stackRef.current?.parentElement?.parentElement?.clientHeight ?? 0,
  );
  const mouseMoveTimerRef = useRef<NodeJS.Timer | null>(null);
  useEffect(() => {
    if (isSubDraggableRef.current) {
      return;
    }

    //console.log(text, '有執行5');

    const mousemove = () => {
      const mouseMoveTimer = mouseMoveTimerRef.current;
      if (typeof mouseMoveTimer === 'number') {
        clearInterval(mouseMoveTimer);
      }

      if (
        (!isSelectedRef.current && !originId) ||
        !isFromMouseDownRef.current ||
        typeof textLockAspectRatioRef?.current !== 'boolean'
      ) {
        return;
      }

      const divEl = divElRef.current;
      const wrapperElement = stackRef.current?.parentElement?.parentElement;
      if (!divEl || !wrapperElement) {
        return;
      }

      divEl.style.maxWidth = textLockAspectRatioRef?.current
        ? `${getDroppableWidth() * 0.992}px`
        : 'none';
      divEl.style.maxHeight = 'none';
      divEl.style.width = `${getWrapperWidth()}px`;
      divEl.style.height = `${getWrapperHeight()}px`;
      wrapperElement.style.maxWidth = 'none';
      wrapperElement.style.maxHeight = 'none';
      const divElChild = divEl.children.item(0) as HTMLDivElement | null;
      if (divElChild) {
        divElChild.style.width = 'auto';
        divElChild.style.height = 'auto';
        divElChild.style.maxWidth = 'none';
        divElChild.style.maxHeight = 'none';
      }

      if (textLockAspectRatioRef?.current === false) {
        return;
      }

      let adjustNum = 0;
      let i = 0;
      while (
        (divElRef.current?.scrollHeight ?? 0) <= (divElRef.current?.clientHeight ?? 0) &&
        i++ < 32
      ) {
        adjustNum++;
        spanElementsCopyRef.current.forEach((span) => {
          span.element.style.fontSize = `${
            ++span.newFontSize *
            ((droppableRef?.current?.offsetWidth ?? 0) / getDroppableMaxWidth(layout))
          }px`;
        });
      }
      i = 0;
      while (
        (divElRef.current?.scrollHeight ?? 0) > (divElRef.current?.clientHeight ?? 0) &&
        i++ < 32
      ) {
        adjustNum--;
        spanElementsCopyRef.current.forEach((span) => {
          span.element.style.fontSize = `${
            --span.newFontSize *
            ((droppableRef?.current?.offsetWidth ?? 0) / getDroppableMaxWidth(layout))
          }px`;
        });
      }

      spanElementsRef.current.forEach((span) => {
        span.originFontSize += adjustNum;
        span.originFontSize = Math.max(span.originFontSize, 12);
        span.element.style.fontSize = `${
          span.originFontSize *
          ((droppableRef?.current?.offsetWidth ?? 0) / getDroppableMaxWidth(layout))
        }px`;
      });

      lastScrollHeightRef.current = wrapperElement.scrollHeight ?? 0;
      lastClientHeightRef.current = wrapperElement.clientHeight ?? 0;

      let scrollHeight = wrapperElement.scrollHeight ?? 0;
      let clientHeight = wrapperElement.clientHeight ?? 0;
      mouseMoveTimerRef.current = setInterval(() => {
        if (
          scrollHeight !== (wrapperElement.scrollHeight ?? 0) ||
          clientHeight !== (wrapperElement.clientHeight ?? 0)
        ) {
          scrollHeight = wrapperElement.scrollHeight ?? 0;
          clientHeight = wrapperElement.clientHeight ?? 0;

          return;
        }

        const wrapperClientHeight = wrapperElement.clientHeight ?? 0;
        const wrapperScrollHeight = wrapperElement.scrollHeight ?? 0;
        const editorClientHeight =
          (
            wrapperElement.getElementsByClassName('DraftEditor-root').item(0) as
              | HTMLDivElement
              | null
              | undefined
          )?.clientHeight ?? 0;
        const adjustNum =
          wrapperScrollHeight - 10 > wrapperClientHeight
            ? -1
            : lastScrollHeightRef.current > wrapperScrollHeight &&
              lastClientHeightRef.current > wrapperClientHeight &&
              wrapperClientHeight > editorClientHeight &&
              Math.abs(wrapperClientHeight - editorClientHeight) >= 10
            ? 1
            : 0;
        if (adjustNum !== 0) {
          spanElementsRef.current.forEach((span) => {
            span.originFontSize += adjustNum;
            span.originFontSize = Math.max(span.originFontSize, 12);
            span.element.style.fontSize = `${
              span.originFontSize *
              ((droppableRef?.current?.offsetWidth ?? 0) / getDroppableMaxWidth(layout))
            }px`;
          });
        }
      }, 0);
    };

    const mousedown = () => {
      isFromMouseDownRef.current = true;
      cursorRef.current = {
        anchorKey: editorStateRef.current.getSelection().getAnchorKey(),
        anchorOffset: editorStateRef.current.getSelection().getAnchorOffset(),
        focusKey: editorStateRef.current.getSelection().getFocusKey(),
        focusOffset: editorStateRef.current.getSelection().getFocusOffset(),
        hasFocus: editorStateRef.current.getSelection().getHasFocus(),
      };
      // focusEditor(editorStateRef.current);

      window.addEventListener('mousemove', mousemove);
    };
    stackRef.current?.parentElement?.parentElement?.addEventListener(
      'mousedown',
      mousedown,
    );

    const mouseup = () => {
      const mouseMoveTimer = mouseMoveTimerRef.current;
      if (typeof mouseMoveTimer === 'number') {
        clearInterval(mouseMoveTimer);
      }

      if (
        (isSelectedRef.current || originId) &&
        typeof textLockAspectRatioRef?.current === 'boolean'
      ) {
        if (textLockAspectRatioRef?.current) {
          const raw = getRaw(editorStateRef.current);
          raw.blocks = raw.blocks.map((block, index) => {
            block.inlineStyleRanges = block.inlineStyleRanges.map((inlineStyleRange) => {
              if (inlineStyleRange.style.indexOf(EditTextType.FontSize) > -1) {
                const fontSizeNum =
                  spanElementsRef.current?.[blocksIndexRef.current[index]]
                    ?.originFontSize ?? 12;
                inlineStyleRange.style =
                  `${EditTextType.FontSize}${fontSizeNum}px` as unknown as DraftInlineStyleType;
              }

              return inlineStyleRange;
            });

            index++;

            return block;
          });

          let newEditorState = EditorState.createWithContent(convertFromRaw(raw));
          newEditorState = EditorState.forceSelection(
            newEditorState,
            newEditorState.getSelection().merge(cursorRef.current),
          );

          updateSelect(newEditorState);
          focusEditor(newEditorState);

          if (originId && draggableMapRef && id) {
            const style = draggableMapRef.current[id].style;
            draggableMapRef.current[id].style = {
              ...style,
              color: JSON.stringify(convertToRaw(newEditorState.getCurrentContent())),
            };

            setEditorState(newEditorState);
          }
        } else {
          focusEditor(
            EditorState.forceSelection(
              editorStateRef.current,
              editorStateRef.current.getSelection().merge(cursorRef.current),
            ),
          );
        }
      }

      isFromMouseDownRef.current = false;
      window.removeEventListener('mousemove', mousemove);
    };
    window.addEventListener('mouseup', mouseup);

    return () => {
      stackRef.current?.parentElement?.parentElement?.removeEventListener(
        'mousedown',
        mousedown,
      );
      window.removeEventListener('mousemove', mousemove);
      window.removeEventListener('mouseup', mouseup);
    };
  }, []);

  const undoAndRedoCounterRef = useRef(0);
  useEffect(() => {
    if (undoAndRedoCounterRef.current === 1) {
      //console.log(text, '有執行6');
      editorRef.current?.focus();
      undoAndRedoCounterRef.current = 2;
    }
  }, [undoAndRedoCounterRef.current]);
  useEffect(() => {
    if (undoAndRedoCounterRef.current === 2) {
      //console.log(text, '有執行7');
      editorRef.current?.blur();
      undoAndRedoCounterRef.current = 0;
    }
  }, [undoAndRedoCounterRef.current]);
  useEffect(() => {
    const raw = getRaw(editorState);
    const rawJSON = JSON.stringify(raw);
    if (color && color !== rawJSON) {
      //console.log(text, '有執行8');
      setEditorState(getEditorStateFromJson(color));
      undoAndRedoCounterRef.current = 1;
    }
  }, [color]);

  const saveTimerRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    //console.log(text, '有執行18');
    if (saveTimerRef.current) {
      clearTimeout(saveTimerRef.current);
    }

    if (!isSelected || !isEditingText || !id) {
      return;
    }

    const raw = getRaw(editorState);
    const rawJSON = JSON.stringify(raw);
    if (lastRawStringRef.current === null) {
      lastRawStringRef.current = rawJSON;
    }

    if (lastRawStringRef.current === rawJSON) {
      return;
    }

    saveTimerRef.current = setTimeout(() => {
      const raw = getRaw(editorStateRef.current);
      const rawJSON = JSON.stringify(raw);
      lastRawStringRef.current = rawJSON;
      dispatch(updateDraggable(getDispatchObj(id, true)));
    }, 300);
  }, [isSelected, isEditingText, editorState]);

  useEffect(() => {
    if (originId) {
      return;
    }

    const raw = getRaw(editorState);
    const rawJSON = JSON.stringify(raw);
    if (!isEditingText && color !== rawJSON && id) {
      //console.log(text, '有執行9');
      const newName = getName(editorState);
      dispatch(
        updateDraggable({
          id,
          style: {
            color: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            ...getFontSizeAndFontWeight(layout, editorState),
            ...(!isSubDraggableRef.current
              ? {
                  width: getCurrentWidth(),
                  height: getCurrentHeight(),
                }
              : null),
          },
          // name: newName,
          answer: correctIsSameAsName
            ? {
                correct: newName,
              }
            : undefined,
        }),
      );
    }
  }, [editorState]);

  const [readOnly, setReadOnly] = useState(true);
  useEffect(() => {
    if (
      isSelected &&
      (!editorRefs?.editorStateRef.current ||
        JSON.stringify(
          convertToRaw(editorRefs.editorStateRef.current.getCurrentContent()),
        ) !== JSON.stringify(convertToRaw(editorState.getCurrentContent())) ||
        editorRefs?.setEditorStateRef.current !== setEditorState ||
        !isEditingText)
    ) {
      //console.log(text, '有執行10');
      focusEditor(editorState);
      setReadOnly(true);
    } else {
      //console.log(text, '有執行11');
      setReadOnly(false);

      updateSelect(editorState);
    }
  }, [
    isSelected,
    editorState,
    editorRefs?.editorStateRef.current,
    editorRefs?.setEditorStateRef.current,
  ]);

  useEffect(() => {
    if (!readOnly) {
      //console.log(text, '有執行12');
      editorRef.current?.focus();
    }
  }, [readOnly]);

  useEffect(() => {
    if (forceNextBlockCounterRef.current > 0) {
      //console.log(text, '有執行13');
      setEditorState(getForceNextBlockEditorState(editorState));
    }
  }, [editorState]);

  const resizeTimerRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    //console.log(text, '有執行14');
    const resize = () => {
      if (resizeTimerRef.current) {
        clearTimeout(resizeTimerRef.current);
      }

      resizeTimerRef.current = setTimeout(() => {
        setEditorState((editorState) => getForceNextBlockEditorState(editorState, true));
      }, 100);
    };

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    const rawFix = (() => {
      const raw: RawDraftContentState = JSON.parse(color ?? '');
      raw.blocks = raw.blocks.map((block) => {
        block.inlineStyleRanges = block.inlineStyleRanges.map((inlineStyleRange) => {
          const style = inlineStyleRange.style;
          if (style.indexOf(EditTextType.FontSize) === 0) {
            const unit = style.slice(-2);
            if (unit !== 'px') {
              inlineStyleRange.style = `${EditTextType.FontSize}${getDefaultFontSizeNum(
                layout,
              )}px` as unknown as any;
            } else {
              inlineStyleRange.style = `${EditTextType.FontSize}${getFontSizeNum(
                layout,
                style.slice(EditTextType.FontSize.length, -2),
              )}${unit}` as unknown as any;
            }
          }

          return inlineStyleRange;
        });

        return block;
      });

      return raw;
    })();

    if (JSON.stringify(rawFix) !== color) {
      //console.log(text, '有執行15');
      setEditorState(EditorState.createWithContent(convertFromRaw(rawFix)));
    }
  }, []);

  useEffect(() => {
    const blocks = editorState.getCurrentContent().getBlocksAsArray();
    if (blocks.length === 1 && blocks[0].getText().length === 1) {
      //console.log(text, '有執行16');
      const raw = getRaw(editorState);
      if (
        raw.blocks.length === 1 &&
        raw.blocks[0].inlineStyleRanges.length === 0 &&
        raw.blocks[0].text.length > 0
      ) {
        raw.blocks[0].inlineStyleRanges = [
          {
            offset: 0,
            length: 1,
            style: getFontSize(layout, getDefaultFontSizeNum(layout)) as unknown as any,
          },
          {
            offset: 0,
            length: 1,
            style: getFontWeight(FontWeightType.Regular) as unknown as any,
          },
        ];

        let newEditorState = EditorState.createWithContent(convertFromRaw(raw));
        newEditorState = EditorState.forceSelection(
          newEditorState,
          newEditorState.getSelection().merge({
            anchorKey: raw.blocks[0].key,
            anchorOffset: 1,
            focusKey: raw.blocks[0].key,
            focusOffset: 1,
            hasFocus: true,
          }),
        );

        setEditorState(newEditorState);
      }
    }
  }, [editorState]);

  useEffect(() => {
    if (
      !isFromUndoOrRedoRef.current &&
      JSON.stringify(getRaw(prevEditorStateRef.current)) !==
        JSON.stringify(getRaw(editorState))
    ) {
      addUndo(prevEditorStateRef.current);
    }
  }, [JSON.stringify(getRaw(editorState))]);

  // main
  return (
    <Stack
      ref={stackRef}
      direction="row"
      justifyContent="flex-start"
      alignItems={alignItems}
      onDoubleClick={onDoubleClick}
      onClick={adminViewMode === 'view' ? onClick : undefined}
      width="100%"
      height="100%"
      boxSizing="border-box"
      bgcolor={backgroundColor}
      borderRadius={borderRadius}
      borderColor={borderColor}
      sx={{
        wordBreak: 'break-all',
        '& .MuiInputBase-input.Mui-disabled': {
          color: '#000',
          WebkitTextFillColor,
          p: 0,
        },
        borderStyle,
        borderWidth,
        ...(adminViewMode === 'view' //### disable customtext init value
          ? {
              '& .public-DraftEditorPlaceholder-inner': {
                display: 'none',
              },
            }
          : null),
        // ...(originId || isEditingText ? { '& *': { userSelect: 'none' } } : null),
      }}
    >
      <Editor
        ref={editorRef}
        readOnly={!isEditingText || readOnly}
        editorState={editorState}
        onChange={onChange}
        customStyleFn={getCustomStyleFn(layout, droppableRef)}
        blockRenderMap={extendedBlockRenderMap}
        placeholder="Please enter text."
        keyBindingFn={(e) => {
          if (!isEditingText) {
            return null;
          }

          if (e.code === 'KeyZ' && KeyBindingUtil.hasCommandModifier(e)) {
            const undo = getUndo();
            if (undo) {
              setEditorState(undo);
              isFromUndoOrRedoRef.current = true;
            }

            return 'handled';
          } else if (e.code === 'KeyY' && KeyBindingUtil.hasCommandModifier(e)) {
            const redo = getRedo();
            if (redo) {
              setEditorState(redo);
              isFromUndoOrRedoRef.current = true;
            }

            return 'handled';
          } else if (e.code === 'KeyC' && KeyBindingUtil.hasCommandModifier(e)) {
            const copyTextInfo = copyTextInfoRef;
            const currentSelectionState = currentSelectionStateRef.current;
            if (
              copyTextInfo &&
              currentSelectionState &&
              (currentSelectionState.getAnchorKey() !==
                currentSelectionState.getFocusKey() ||
                Math.max(
                  currentSelectionState.getAnchorOffset(),
                  currentSelectionState.getFocusOffset(),
                ) > 0)
            ) {
              copyTextInfo.current = {
                raw: getRaw(editorStateRef.current),
                selection: currentSelectionState,
              };
            }

            return 'handled';
          } else if (e.code === 'KeyV' && KeyBindingUtil.hasCommandModifier(e)) {
            const getKeyAndOffsetForForward = (selection: SelectionState) => {
              let anchorKey = selection.getAnchorKey();
              let anchorOffset = selection.getAnchorOffset();
              let focusKey = selection.getFocusKey();
              let focusOffset = selection.getFocusOffset();

              if (selection.getIsBackward()) {
                anchorKey = selection.getFocusKey();
                anchorOffset = selection.getFocusOffset();
                focusKey = selection.getAnchorKey();
                focusOffset = selection.getAnchorOffset();
              }

              return { anchorKey, anchorOffset, focusKey, focusOffset };
            };

            const getNewEditorState = (
              editorState: EditorState,
              anchorKey: string,
              anchorOffset: number,
              focusKey: string,
              focusOffset: number,
            ) => {
              const removeSelection = new SelectionState({
                anchorKey,
                anchorOffset,
                focusKey,
                focusOffset,
              });

              const newContentState = Modifier.removeRange(
                editorState.getCurrentContent(),
                removeSelection,
                'forward',
              );

              const newEditorState = EditorState.push(
                editorState,
                newContentState,
                'remove-range',
              );

              return newEditorState;
            };

            const getFirstAndLastBlockAndTotalNum = (raw: RawDraftContentState) => {
              if (raw.blocks.length === 0) {
                return {
                  firstBlock: null,
                  lastBlock: null,
                  totalBlockNum: 0,
                };
              }

              const totalBlockNum = raw.blocks.length;
              const firstBlock = raw.blocks[0];
              const lastBlock = raw.blocks[totalBlockNum - 1];

              return {
                firstBlock,
                lastBlock,
                totalBlockNum,
              };
            };

            const copyTextInfo = copyTextInfoRef?.current;
            if (!copyTextInfo) {
              return 'not-handled';
            }

            const { raw: copyRaw, selection } = copyTextInfo;
            const copyTextEditorState = getEditorState(copyRaw);

            const { anchorKey, anchorOffset, focusKey, focusOffset } =
              getKeyAndOffsetForForward(selection);

            const { firstBlock, lastBlock } = getFirstAndLastBlockAndTotalNum(copyRaw);
            if (!firstBlock || !lastBlock) {
              return 'not-handled';
            }

            let newEditorState: EditorState | null = null;
            if (focusKey !== lastBlock.key || focusOffset < lastBlock.text.length) {
              newEditorState = getNewEditorState(
                copyTextEditorState,
                focusKey,
                focusOffset,
                lastBlock.key,
                lastBlock.text.length,
              );
            }
            if (focusOffset > 0) {
              newEditorState = getNewEditorState(
                newEditorState ?? copyTextEditorState,
                firstBlock.key,
                0,
                anchorKey,
                anchorOffset,
              );
            }

            const currentSelectionState = currentSelectionStateRef.current;
            let originHeadEditorState: EditorState | null = null;
            let originTailEditorState: EditorState | null = null;
            const originEditorState = editorStateRef.current;
            const originRaw = getRaw(originEditorState);
            if (currentSelectionState) {
              const { firstBlock, lastBlock } =
                getFirstAndLastBlockAndTotalNum(originRaw);
              if (!firstBlock || !lastBlock) {
                return 'not-handled';
              }

              const { anchorKey, anchorOffset, focusKey, focusOffset } =
                getKeyAndOffsetForForward(currentSelectionState);

              originHeadEditorState = getNewEditorState(
                originEditorState,
                anchorKey,
                anchorOffset,
                lastBlock.key,
                lastBlock.text.length,
              );

              originTailEditorState = getNewEditorState(
                originEditorState,
                firstBlock.key,
                0,
                focusKey,
                focusOffset,
              );

              if (newEditorState) {
                const contentRaw = getRaw(newEditorState);
                const resultRaw = getRaw(originHeadEditorState);
                const tailRaw = getRaw(originTailEditorState);

                let textLen = resultRaw.blocks[0].text.length;
                contentRaw.blocks[0].inlineStyleRanges.forEach((inlineStyleRange) => {
                  resultRaw.blocks[0].inlineStyleRanges.push({
                    ...inlineStyleRange,
                    offset: inlineStyleRange.offset + textLen,
                  });
                });
                resultRaw.blocks[0].text = resultRaw.blocks[0].text.concat(
                  contentRaw.blocks[0].text,
                );

                let i = resultRaw.blocks.length - 1;
                for (const block of contentRaw.blocks.slice(1)) {
                  resultRaw.blocks[++i] = block;
                  resultRaw.blocks[i].key = genKey();
                }

                textLen = resultRaw.blocks[i].text.length;
                tailRaw.blocks[0].inlineStyleRanges.forEach((inlineStyleRange) => {
                  resultRaw.blocks[i].inlineStyleRanges.push({
                    ...inlineStyleRange,
                    offset: inlineStyleRange.offset + textLen,
                  });
                });
                resultRaw.blocks[i].text = resultRaw.blocks[i].text.concat(
                  tailRaw.blocks[0].text,
                );

                for (const block of tailRaw.blocks.slice(1)) {
                  resultRaw.blocks[++i] = block;
                  resultRaw.blocks[i].key = genKey();
                }

                setEditorState(getEditorState(resultRaw));
                addUndo(editorStateRef.current);
              }
            }

            return 'handled';
          }

          if (
            e.code.indexOf('Key') > -1 ||
            e.code.indexOf('Digit') > -1 ||
            e.code.indexOf('Numpad') > -1
          ) {
            isFromUndoOrRedoRef.current = false;
          }

          return getDefaultKeyBinding(e);
        }}
      />
    </Stack>
  );
}
