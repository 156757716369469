import { useLayoutEffect } from 'react';

import { cloneDeep, defaultsDeep } from 'lodash-es';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import FallbackImage from './components/FallbackImage';
import WrapperRND from './components/WrapperRND';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import { useScripts } from '../../../../layout/utils/LanguageHelper';
import { useStyle } from '../../context/StyleContext';

const landscapeProps: DraggableInitProps = {
  type: DraggableType.Image,
  tags: [DraggableTag.Image],
  lockAspectRatio: true,
};

export const defaultImageProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
  },
};

export default function Image(props: DraggableOuterProps) {
  const scripts = useScripts();

  const { layout, draggablesPropsRef } = useStyle();
  const defaultProps = layout ? defaultImageProps[layout] : defaultImageProps.landscape;

  const defaultImage: DraggableInnerProps = {
    ...defaultProps,
    bounds: 'parent',
  };

  const dragProps: DraggableInnerProps = defaultsDeep(cloneDeep(props), defaultImage);

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep({
        ...props,
        selectedBar: {
          ...dragProps?.selectedBar,
          getDeleteLabel: () => scripts.deleteThisImage,
        },
      });
    }
  }, [dragProps]);

  return (
    <WrapperRND {...dragProps}>
      {dragProps.image?.imageUrl ? (
        <img
          crossOrigin={props?.isCORS ? 'anonymous' : undefined}
          src={dragProps.image?.imageUrl}
          loading="lazy"
          draggable={false}
          style={{
            height: '100%',
            width: '100%',
            objectFit: dragProps.style?.objectFit ?? 'cover', // fill, contain, cover
          }}
        />
      ) : (
        <FallbackImage />
      )}
    </WrapperRND>
  );
}
