import { useEffect, useRef, useState } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { cloneDeep } from 'lodash-es';
import { useParams } from 'react-router-dom';

import { DraggableInnerProps } from './DraggableOrigin';
import { useAuth } from '../../../../../globalHooks/useAuth';
import {
  updateDraggables,
  updateDraggables2,
  updateSelected,
  ClickedArea,
  UpdateDraggableInput,
} from '../../../../../redux/features/styleSlice';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { useFontEditorContext } from '../../../context/FontEditorContext';
import { useStyleAnswer } from '../../../context/StyleAnswerContext';
import { useStyle } from '../../../context/StyleContext';

interface PageBarProps {
  id?: string;
  subId?: string;
  page: number;
  maxPage: number;
  dragProps: DraggableInnerProps;
}

export default function PageBar({ id, subId, page, maxPage, dragProps }: PageBarProps) {
  const { draggables, draggableMapRef } = useStyle();
  const adminViewMode = useAppSelector((state) => state.adminViewMode.currentMode);

  const draggablesRef = useRef(draggables);
  draggablesRef.current = draggables;
  const dispatch = useAppDispatch();
  const { isManager } = useAuth();
  const { textContentRef } = useFontEditorContext();

  const pageNationRef = useRef<HTMLElement>(null);

  const { styleAnswers } = useStyleAnswer();

  const { styleId = '' } = useParams();

  const currentStyleview = styleAnswers.find((ele) => {
    return ele.styleId === styleId;
  });

  // func
  function handleChangePage(e: React.ChangeEvent<unknown>, value: number) {
    e.stopPropagation();
    dispatch(updateSelected({ id: '', clickedArea: ClickedArea.Style })); // We need to change selected first to update the textField in editing state
    setTimeout(() => {
      const newPageNum = value - 1;
      if (!id) {
        return;
      }

      const originPage = dragProps?.page;
      const group = dragProps?.group?.[originPage ?? 0];
      const updateDraggableInputArr: UpdateDraggableInput[] = [];
      if (typeof originPage === 'number' && group) {
        const groupId = group?.id;
        if (groupId) {
          updateDraggableInputArr.push({
            id: groupId,
            style: {
              ...group.style,
              width: dragProps?.style?.width,
              height: dragProps?.style?.height,
            },
          });
        }
      }

      updateDraggableInputArr.push({
        id,
        page: newPageNum,
        style: {
          ...dragProps.style,
          width: dragProps?.group?.[newPageNum]?.style?.width,
          height: dragProps?.group?.[newPageNum]?.style?.height,
        },
      });

      if (updateDraggableInputArr.length > 0) {
        dispatch(updateDraggables2(updateDraggableInputArr));
      }
    }, 50); // It is important to delay and not be discovered by the user
  }

  function handleDeleteCurrentPage(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    if (subId) {
      if (maxPage - 1 > 0 && id) {
        let draggable = draggables.find((draggable) => draggable.id === id);
        if (!draggable) {
          return;
        }

        draggable = cloneDeep(draggable);
        const newGroup = cloneDeep(draggable?.group ?? []).filter(
          (draggable, index) => index !== page,
        );
        const newPageNum = newGroup?.[page] ? page : page - 1;
        draggable.group = newGroup;
        draggable.page = newPageNum;
        draggable.style = {
          ...dragProps.style,
          width: newGroup?.[newPageNum]?.style?.width,
          height: newGroup?.[newPageNum]?.style?.height,
        };

        dispatch(
          updateDraggables(
            draggablesRef.current.map((d) => (d?.id === draggable?.id ? draggable : d)),
          ),
        );
      }
    }
  }

  const questionLabelRef = useRef<{ [key: number]: string }>({
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
    10: 'ten',
    11: 'Eleven',
    12: 'twelve',
    13: 'thirteen',
    14: 'fourteen',
    15: 'fifteen',
    16: 'sixteen',
    17: 'seventeen',
    18: 'eighteen',
    19: 'nineteen',
    20: 'twenty',
    21: 'twenty-one',
    22: 'twenty-two',
    23: 'twenty-three',
    24: 'twenty-four',
    25: 'twenty-five',
    26: 'twenty-six',
    27: 'twenty-seven',
    28: 'twenty-eight',
    29: 'twenty-nine',
    30: 'thirty',
  });

  useEffect(() => {
    if (adminViewMode === 'edit') {
      return;
    }
    dragProps?.group?.forEach((ele, index) => {
      const maxSelectable =
        ele.group?.reduce((acc, ele) => (ele.answer?.correct ? acc + 1 : acc), 0) || 0;
      const count =
        ele.group?.filter((group) => {
          return currentStyleview?.userAnswers.some(
            (userAnswer) => userAnswer.draggableId === group.id,
          );
        }).length ?? 0;
      const paginationElement = pageNationRef.current;
      if (paginationElement) {
        const buttonElements = paginationElement.querySelectorAll('button');
        if (maxSelectable === count) {
          buttonElements[index + 1].style.borderColor = '#21da21';
          buttonElements[index + 1].style.borderWidth = '0.2em';
        } else {
          buttonElements[index + 1].style.borderColor = '#0000003b';
          buttonElements[index + 1].style.borderWidth = '1px';
        }
      }
    });
  }, [styleAnswers, page]);

  const showComponent = maxPage > 1;
  const currentPage = page + 1;

  // main
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      flexWrap="nowrap"
      spacing={1}
      {...(!showComponent ? { display: 'none' } : null)}
      sx={{
        position: 'absolute',
        left: '-30px',
        top: '-34px',
      }}
    >
      {/* Delete current page button */}
      {isManager && adminViewMode === 'edit' && (
        <Tooltip
          title={`Delete question ${
            questionLabelRef.current?.[currentPage] ?? currentPage
          }.`}
        >
          <IconButton
            size="small"
            onClick={handleDeleteCurrentPage}
            onMouseDown={(e) => e.stopPropagation()}
            sx={{
              color: '#f33',
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      )}
      <Pagination
        ref={pageNationRef}
        size="small"
        variant="outlined"
        count={maxPage}
        page={currentPage}
        onChange={handleChangePage}
        onMouseDown={(e) => e.stopPropagation()}
        sx={{
          flexWrap: 'nowrap',
          '& ul': {
            flexWrap: 'nowrap',
          },
        }}
        renderItem={(item) => {
          const id = dragProps?.group?.[(item?.page ?? 0) - 1]?.group?.[0]?.id ?? '';
          const name = dragProps?.group?.[(item?.page ?? 0) - 1]?.group?.[0]?.name ?? '';
          const color = `${
            dragProps?.group?.[(item?.page ?? 0) - 1]?.group?.[0]?.style?.color ?? ''
          }`;

          const text1 = (
            draggableMapRef?.current?.[id]?.ref?.current
              ?.getSelfElement()
              ?.querySelector('span[data-text="true"]')?.textContent ?? ''
          ).trim();
          const text2 =
            (color.indexOf('blocks') > -1
              ? `${JSON.parse(color)?.blocks?.[0]?.text ?? ''}`.trim()
              : null) ?? '';
          const text3 = name.trim();

          const textContent =
            text1.length > 0
              ? text1
              : text2.length > 0
              ? text2
              : text3.length > 0
              ? text3
              : '';

          const page = item?.page;
          if (
            item.type === 'page' &&
            item.disabled === false &&
            typeof page === 'number'
          ) {
            if (textContent && textContentRef) {
              textContentRef.current[id] = textContent;
            }

            return (
              <Tooltip title={textContentRef?.current[id]}>
                <PaginationItem {...item} />
              </Tooltip>
            );
          }

          return <PaginationItem {...item} />;
        }}
      />
    </Stack>
  );
}
