import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import { useScripts } from './LanguageHelper';
import { SvgProps } from './MenuItem';
import { useAuth } from '../../globalHooks/useAuth';
import { ThemeMode } from '../components/MainMenu';

interface ThemeModeSvg extends SvgProps {
  currentThemeMode: ThemeMode;
}

const ThemeModeSvg = ({ width, height, currentThemeMode }: ThemeModeSvg) => {
  const scripts = useScripts();

  const webMode = localStorage.getItem('theme');

  const { managerRole } = useAuth();

  if (managerRole) {
    return (
      <Stack
        position="relative"
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0.5}
      >
        {webMode === 'Auto' && (
          <>
            <Stack
              position="absolute"
              top="-22px"
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
            >
              <SettingsBrightnessIcon sx={{ fontSize: `${width}px`, color: '#fff' }} />
            </Stack>
            <Stack
              position="absolute"
              bottom="-18px"
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
            >
              <Typography fontWeight="600" fontSize="1em" color="#090446">
                {scripts.auto}
              </Typography>
            </Stack>
          </>
        )}
        {webMode === 'Dark' && (
          <>
            <Stack
              position="absolute"
              top="-22px"
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
            >
              <DarkModeIcon
                sx={{
                  fontSize: `${width}px`,
                  color: webMode === 'Dark' ? '#fff' : '#090446',
                }}
              />
            </Stack>
            <Stack
              position="absolute"
              bottom="-26px"
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
            >
              <Typography fontWeight="600" fontSize="1em" color="#090446">
                {scripts.darkMode}
              </Typography>
            </Stack>
          </>
        )}
        {webMode === 'Light' && (
          <>
            <Stack
              position="absolute"
              top="-22px"
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
            >
              <LightModeIcon sx={{ fontSize: `${width}px`, color: '#090446' }} />
            </Stack>
            <Stack
              position="absolute"
              bottom="-26px"
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
            >
              <Typography fontWeight="600" fontSize="1em" color="#090446">
                {scripts.lightMode}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    );
  }

  if (webMode === 'Auto') {
    return <SettingsBrightnessIcon sx={{ fontSize: `${width}px`, color: '#6B6B6B' }} />;
  }

  if (webMode === 'Dark') {
    return <DarkModeIcon sx={{ fontSize: `${width}px`, color: '#6B6B6B' }} />;
  }

  if (webMode === 'Light') {
    return <LightModeIcon sx={{ fontSize: `${width}px`, color: '#6B6B6B' }} />;
  }

  return null;
};

export default ThemeModeSvg;
