import React, { useState } from 'react';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { colord, extend } from 'colord';
import namesPlugin from 'colord/plugins/names';
import { RgbaStringColorPicker } from 'react-colorful';

import useUpdateFontStyle, {
  EditTextType,
  isEditorJSONString,
} from './text/hooks/useUpdateFontStyle';
import CustomInputText from '../../../globalComponents/CustomInputText';
import CenterBox from '../../../globalContainers/CenterBox';
import { useScripts } from '../../../layout/utils/LanguageHelper';
import {
  updateDraggable,
  updateDraggableWithoutHistory,
  updateSideLayout,
  ColorPickerMode,
} from '../../../redux/features/styleSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import BorderWidthSelectIcon from '../../../svg/BorderWidthSelectIcon';
import FillColorIcon from '../../../svg/FillColorIcon';
import TextIcon from '../../../svg/TextIcon';
import { useStyle } from '../context/StyleContext';

extend([namesPlugin]);

// interface ColorPickerProps

export const defaultColorList = [
  'rgb(255, 255, 255)',
  'rgb(35, 24, 21)',
  'rgb(230, 0, 18)',
  'rgb(255, 241, 0)',
  'rgb(0, 153, 68)',
  'rgb(0, 160, 233)',
  'rgb(29, 32, 136)',
  'rgb(228, 0, 127)',
  'rgb(195, 13, 35)',
  'rgb(234, 85, 20)',
  'rgb(243, 152, 0)',
  'rgb(218, 224, 0)',
  'rgb(143, 195, 31)',
  'rgb(34, 172, 56)',
  'rgb(0, 105, 52)',
  'rgb(19, 174, 103)',
  'rgb(0, 162, 154)',
  'rgb(3, 110, 184)',
  'rgb(23, 28, 97)',
  'rgb(96, 25, 134)',
  'rgb(146, 7, 131)',
  'rgb(164, 11, 93)',
].map((color) => colord(color).toRgbString());

export default function ColorPicker({
  editText,
  showDefaultColor = true,
  showTitle = true,
}: {
  editText?: boolean;
  showDefaultColor?: boolean;
  showTitle?: boolean;
}) {
  const scripts = useScripts();

  const dispatch = useAppDispatch();
  const { selected, selectedId } = useStyle();
  const colorPickerMode = useAppSelector(
    (state) => state.style.present.sideLayout.colorPicker.mode,
  );
  const isColorMode = colorPickerMode === ColorPickerMode.Color;
  const isBackgroundMode = colorPickerMode === ColorPickerMode.BackgroundColor;
  const isBorderMode = colorPickerMode === ColorPickerMode.BorderColor;
  const defaultColor = 'rgb(179, 42, 169)';
  const getColor = (color: string | undefined) =>
    isEditorJSONString(color) ? defaultColor : color ?? defaultColor;
  const [color, setColor] = React.useState<string>(
    (isColorMode ? getColor(selected?.style?.color) : selected?.style?.backgroundColor) ??
      defaultColor,
  );
  const [inputColorfocused, setInputColorFocused] = useState(false);
  const rgbaString = React.useMemo(() => {
    return color.startsWith('rgba') ? color : colord(color).toRgbString();
  }, [color]);
  const [error, setError] = React.useState<boolean>(false);
  const [colorChanging, setColorChanging] = React.useState<boolean>(false);

  const { updateFontStyle } = useUpdateFontStyle(EditTextType.FontColor);

  const getFontColorStyleName = (color: string) => `${EditTextType.FontColor}${color}`;

  // func
  React.useEffect(() => {
    if (selectedId) {
      if (isColorMode && selected?.style?.color) {
        setColor(getColor(selected.style.color));
      } else if (isBackgroundMode && selected?.style?.backgroundColor) {
        setColor(selected.style.backgroundColor);
      } else if (isBorderMode && selected?.style?.borderColor) {
        setColor(selected.style.borderColor);
      }
    }
  }, [selectedId, colorPickerMode]);

  function colorPickerChange(newColor: string, isMouseUp = false) {
    const updateDrag = isMouseUp ? updateDraggable : updateDraggableWithoutHistory;
    setColor(newColor);

    isMouseUp ? setColorChanging(false) : setColorChanging(true);

    if (!selectedId) return;
    if (isColorMode) {
      if (editText) {
        updateFontStyle(getFontColorStyleName(newColor), EditTextType.FontColor);
      } else {
        dispatch(updateDrag({ id: selectedId, style: { color: newColor } }));
      }
    } else if (isBackgroundMode) {
      dispatch(
        updateDrag({
          id: selectedId,
          style: { backgroundColor: newColor },
        }),
      );
    } else if (isBorderMode) {
      dispatch(
        updateDrag({
          id: selectedId,
          style: {
            borderColor: newColor,
            ...((selected?.style?.borderStyle ?? 'none') === 'none'
              ? { borderStyle: 'solid' }
              : null),
            ...(!selected?.style?.borderWidth ? { borderWidth: '2' } : null),
          },
        }),
      );
    }
  }

  React.useEffect(() => {
    function mouseUp() {
      if (!colorChanging) return;
      colorPickerChange(rgbaString, true);
    }

    window.addEventListener('mouseup', mouseUp);
    return () => window.removeEventListener('mouseup', mouseUp);
  }, [rgbaString, colorPickerMode, selectedId, colorChanging]);

  function toColorMode() {
    dispatch(updateSideLayout({ colorPicker: { mode: ColorPickerMode.Color } }));
  }

  function toBackgroundMode() {
    dispatch(
      updateSideLayout({ colorPicker: { mode: ColorPickerMode.BackgroundColor } }),
    );
  }

  function toBorderMode() {
    dispatch(updateSideLayout({ colorPicker: { mode: ColorPickerMode.BorderColor } }));
  }

  const inputColorOnFocus = () => setInputColorFocused(true);
  const inputColorOnBlur = () => setInputColorFocused(false);

  // main
  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1.5}
        width="100%"
      >
        {showDefaultColor && (
          <>
            {showTitle && (
              <Typography variant="subtitle1" textAlign="left" width="100%">
                {scripts.defaultColor}
              </Typography>
            )}
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              flexWrap="wrap"
              width="100%"
            >
              {defaultColorList.map((color) => (
                <Stack
                  key={color}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  width="22px"
                  height="22px"
                  borderRadius="4px"
                  bgcolor={color}
                  mx={0.25}
                  mb={0.5}
                  onClick={() => colorPickerChange(color, true)}
                />
              ))}
            </Stack>
            <Divider sx={{ width: '100%' }} />
          </>
        )}
        {showTitle && (
          <Typography variant="subtitle1" textAlign="left" width="100%">
            {scripts.color}
          </Typography>
        )}
        {/* Switch Mode ButtonBar */}
        <CenterBox sx={{ height: 'fit-content', pb: 1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            width="100%"
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {/* Color Button */}
              <IconButton
                size="small"
                disabled={!selectedId}
                onClick={toColorMode}
                sx={{
                  backgroundColor: isColorMode ? 'primary.main' : undefined,
                  '&:hover': {
                    backgroundColor: isColorMode ? 'primary.dark' : undefined,
                  },
                  '&:disabled': {
                    backgroundColor: '#d9d9d9',
                  },
                }}
              >
                <TextIcon
                  width={20}
                  height={20}
                  color={isColorMode ? '#FFF' : 'rgba(0, 0, 0, 0.26)'}
                />
              </IconButton>
              {/* Background Button */}
              <IconButton
                size="small"
                disabled={!selectedId}
                onClick={toBackgroundMode}
                sx={{
                  backgroundColor: isBackgroundMode ? 'primary.main' : undefined,
                  '&:hover': {
                    backgroundColor: isBackgroundMode ? 'primary.dark' : undefined,
                  },
                  '&:disabled': {
                    backgroundColor: '#d9d9d9',
                  },
                }}
              >
                <FillColorIcon
                  width={20}
                  height={20}
                  color={isBackgroundMode ? '#FFF' : 'rgba(0, 0, 0, 0.26)'}
                />
              </IconButton>
              {/* Border Button */}
              <IconButton
                size="small"
                disabled={!selectedId}
                onClick={toBorderMode}
                sx={{
                  backgroundColor: isBorderMode ? 'primary.main' : undefined,
                  '&:hover': {
                    backgroundColor: isBorderMode ? 'primary.dark' : undefined,
                  },
                  '&:disabled': {
                    backgroundColor: '#d9d9d9',
                  },
                }}
              >
                <BorderWidthSelectIcon
                  width={20}
                  height={20}
                  color={isBorderMode ? '#FFF' : 'rgba(0, 0, 0, 0.26)'}
                />
              </IconButton>
            </Stack>
            <CustomInputText
              value={color}
              error={error}
              size="small"
              focused={inputColorfocused}
              onMouseDown={inputColorOnFocus}
              onFocus={inputColorOnFocus}
              onBlur={inputColorOnBlur}
              onChange={(e) => {
                const newColor = e.target.value;
                setColor(newColor);

                const isValidColor = colord(newColor).isValid();
                if (isValidColor) {
                  setError(false);
                  colorPickerChange(newColor, true);
                } else {
                  setError(true);
                }

                setInputColorFocused(true);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Enter') {
                  setInputColorFocused(false);
                }
              }}
              inputProps={{
                style: { paddingLeft: '8px', paddingRight: '8px', fontSize: '12px' },
              }}
              sx={{ ml: 2 }}
            />
          </Stack>
        </CenterBox>
      </Stack>
      {/* Color Picker */}
      <RgbaStringColorPicker
        color={rgbaString}
        onChange={colorPickerChange}
        style={{
          width: '100%',
        }}
      />
    </>
  );
}
