import { useLayoutEffect } from 'react';

import { cloneDeep, defaultsDeep } from 'lodash-es';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import FallbackImage from './components/FallbackImage';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import PlayIconX2 from '../../../../img/style/audio/play_icon_X2.png';
import { Draggable } from '../../../../redux/features/styleSlice';
import { useStyle } from '../../context/StyleContext';
import { getDraggables } from '../../utils/draggables';

export const imageAudioAnswerChild: Array<Draggable> = [
  {
    id: '',
    type: DraggableType.Audio,
    tags: [DraggableTag.Audio],
    name: '',
    style: {
      width: '33%',
      height: '33%',
      left: '0%',
      top: '0%',
    },
    lockAspectRatio: true,
  },
  {
    id: '',
    type: DraggableType.Image,
    tags: [DraggableTag.Image],
    name: '',
    style: {
      width: '100%',
      height: '67%',
      left: '0%',
      top: '33%',
    },
    lockAspectRatio: true,
  },
];

const landscapeProps: DraggableInitProps = {
  type: DraggableType.ImageAudioAnswer,
  tags: [DraggableTag.Group],
  style: {
    width: '18.70004%',
    height: '33.24453%',
  },
  lockAspectRatio: false,
  group: [
    {
      ...imageAudioAnswerChild[0],
      style: {
        ...imageAudioAnswerChild[0].style,
        width: '6.17101%',
        height: '10.97069%',
        left: '0%',
        top: '0%',
      },
    },
    {
      ...imageAudioAnswerChild[1],
      style: {
        ...imageAudioAnswerChild[1].style,
        width: '18.70004%',
        height: '22.27383%',
        left: '0%',
        top: '10.97069%',
      },
    },
  ],
};

export const defaultImageAudioAnswerProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '50%',
      height: '28.125377%',
    },
    group: [
      {
        ...imageAudioAnswerChild[0],
        style: {
          ...imageAudioAnswerChild[0].style,
          width: '16.5%',
          height: '9.281374%',
          left: '0%',
          top: '0%',
        },
      },
      {
        ...imageAudioAnswerChild[1],
        style: {
          ...imageAudioAnswerChild[1].style,
          width: '50%',
          height: '18.844003%',
          left: '0%',
          top: '9.281374%',
        },
      },
    ],
  },
};

export default function ImageAudioAnswer(props: DraggableOuterProps) {
  const { layout, draggablesPropsRef, defineCompletelyDeleteDraggable } = useStyle();
  const defaultProps = layout
    ? defaultImageAudioAnswerProps[layout]
    : defaultImageAudioAnswerProps.landscape;

  const defaultImageAudioAnswer: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '22.5vmin',
      height: '22.5vmin',
    },
    group: props.isClone ? undefined : imageAudioAnswerChild,
    originWrapperStyle: {
      backgroundColor: '#f3f3f3',
      borderRadius: '6px',
      padding: 1,
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultImageAudioAnswer,
  );

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep(props);
      defineCompletelyDeleteDraggable(id);
    }
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps}>
      {dragProps.isClone && dragProps.group ? (
        getDraggables(dragProps.group)
      ) : (
        <>
          <CenterBox
            sx={[
              {
                width: '6vmin',
                height: '6vmin',
              },
            ]}
          >
            <img
              src={PlayIconX2}
              style={{ width: '100%', height: '100%' }}
              draggable={false}
            />
          </CenterBox>
          <CenterBox
            sx={{
              mt: 0.5,
              width: '20.25vmin',
              height: '13.5vmin',
            }}
          >
            <FallbackImage />
          </CenterBox>
        </>
      )}
    </WrapperRND>
  );
}
