import React, { useLayoutEffect } from 'react';

import Grid2 from '@mui/material/Unstable_Grid2';
import { cloneDeep, defaultsDeep } from 'lodash-es';
import { useParams } from 'react-router-dom';

import { DraggableOuterProps, DraggableInnerProps } from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import { DraggableType, DraggableTag } from '../../../../graphql/resolver.types';
import { useStyleAnswer, StyleAnswerItem } from '../../context/StyleAnswerContext';
import { useStyle } from '../../context/StyleContext';
import { getSameGroupDraggableByType, shuffle } from '../../utils/draggables';

export default function RandomTextBoard(props: DraggableOuterProps) {
  const defaultRandomTextBoard: DraggableInnerProps = {
    type: DraggableType.RandomTextBoard,
    tags: [DraggableTag.Elements],
    style: {
      width: '10vmin',
      height: '10vmin',
      color: '#000000',
      backgroundColor: '#e6e6e6',
    },
    lockAspectRatio: false,
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultRandomTextBoard,
  );
  const widthNumber = 5; // limit 15 letters
  const heightNumber = 3;
  const { draggables, draggablesPropsRef, getParentId } = useStyle();
  const correct = getSameGroupDraggableByType(
    dragProps?.id,
    DraggableType.TextRectAnswer2,
    draggables,
  )?.answer?.correct;

  const { setStyleAnswers } = useStyleAnswer();

  const { styleId = '' } = useParams();

  // func
  function makeid(length: number) {
    const result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result;
  }

  function handleFillBlankAnswer({ text }: { text?: string }) {
    setStyleAnswers((prev) => {
      const prevStyleAnswers = cloneDeep(prev);

      const currentStyleAnswer = prevStyleAnswers.find(
        (styleAnswer) => styleAnswer.styleId === styleId,
      );

      const currentUserAnswer = currentStyleAnswer?.userAnswers.find(
        (ele) => ele.draggableId === getParentId(dragProps.id ?? ''),
      );

      if (
        text === '←' &&
        typeof currentUserAnswer?.answer === 'string' &&
        currentUserAnswer?.answer
      ) {
        currentUserAnswer.answer = currentUserAnswer?.answer.slice(0, -1);
      } else if (text === 'CLN' && currentUserAnswer?.answer) {
        currentUserAnswer.answer = '';
      } else if (text !== '←' && text !== 'CLN') {
        if (currentStyleAnswer) {
          const currentUserAnswer = currentStyleAnswer.userAnswers.find(
            (ele) => ele.draggableId === getParentId(dragProps.id ?? ''),
          );
          if (currentUserAnswer) {
            if (
              typeof currentUserAnswer.answer === 'string' &&
              currentUserAnswer.answer.length < correct.length
            ) {
              currentUserAnswer.answer += text;
            }
          } else {
            const updatedUserAnswer = {
              draggableId: getParentId(dragProps.id ?? '') || '',
              answer: text,
              type: 'FillBlank',
            };

            currentStyleAnswer.userAnswers.push(updatedUserAnswer);
          }
        } else {
          if (text === '←' || text === 'CLN') {
            return [...prevStyleAnswers];
          } else {
            const updatedAnswerItem = {
              styleId,
              userAnswers: [
                {
                  draggableId: getParentId(dragProps.id ?? '') || '',
                  answer: text,
                  type: 'FillBlank',
                },
              ],
              isDone: false,
            };
            return [...prevStyleAnswers, updatedAnswerItem] as StyleAnswerItem[]; //add new Answer
          }
        }
      }
      return [...prevStyleAnswers];
    });
  }

  const renderBoard = React.useMemo(() => {
    let board = [];
    const correctLength = correct?.length;
    if (correctLength && correctLength <= 15) {
      const extraLength = widthNumber * heightNumber - correctLength;
      board = shuffle(makeid(extraLength).concat([...correct]));
    } else {
      for (let w = 0; w < widthNumber; w++) {
        for (let h = 0; h < heightNumber; h++) {
          board.push('a');
        }
      }
    }
    const widthGrid = 12 / widthNumber;

    board.push('←', 'CLN');

    return (
      <Grid2
        container
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        {board.map((letter, index) => {
          return (
            <Grid2 key={`RandomTextBoard-${index}`} xs={widthGrid} sx={{ p: 0.5 }}>
              <CenterBox
                sx={{
                  color: dragProps?.style?.color,
                  backgroundColor: dragProps?.style?.backgroundColor,
                  borderRadius: '5px',
                  fontSize: '1.5vw',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  const target = e.target as HTMLElement;
                  if (target.textContent) {
                    handleFillBlankAnswer({ text: target.textContent });
                  }
                }}
              >
                {letter}
              </CenterBox>
            </Grid2>
          );
        })}
      </Grid2>
    );
  }, [correct, widthNumber, heightNumber]);

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep(props);
    }
  }, [dragProps]);

  // main
  return <WrapperRND {...dragProps}>{renderBoard}</WrapperRND>;
}
