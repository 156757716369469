import { SvgProps } from './MenuItem';
import { useAuth } from '../../globalHooks/useAuth';

const SchoolSvg = ({ width, height, colorForCustomer }: SvgProps) => {
  const { managerRole } = useAuth();

  const webMode = localStorage.getItem('theme');

  if (managerRole) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.05 2.53001L4.02999 6.46001C2.09999 7.72001 2.09999 10.54 4.02999 11.8L10.05 15.73C11.13 16.44 12.91 16.44 13.99 15.73L19.98 11.8C21.9 10.54 21.9 7.73001 19.98 6.47001L13.99 2.54001C12.91 1.82001 11.13 1.82001 10.05 2.53001Z"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.63 13.08L5.62 17.77C5.62 19.04 6.6 20.4 7.8 20.8L10.99 21.86C11.54 22.04 12.45 22.04 13.01 21.86L16.2 20.8C17.4 20.4 18.38 19.04 18.38 17.77V13.13"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.4 15V9"
          stroke={webMode === 'Light' ? '#090446' : '#fff'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={colorForCustomer} />
    </svg>
  );
};

export default SchoolSvg;
