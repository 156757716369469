import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useNavigate } from 'react-router-dom';

import ChineseMenuItem from './ChineseMenuItem';
import { useScripts } from './LanguageHelper';
import MenuItem from './MenuItem';
import ThemeModeMenuItem from './ThemeModeMenuItem';
import { client } from '../../apollo/apollo.client';
import { useAuth } from '../../globalHooks/useAuth';
import usePopoverAnchorEl from '../../globalHooks/usePopoverAnchorEl';
import { resetUser } from '../../redux/features/userSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { MenuItemProps, ThemeMode } from '../components/MainMenu';

interface OtherMenuItem extends MenuItemProps {
  currentThemeMode: ThemeMode;
  setCurrentThemeMode: React.Dispatch<React.SetStateAction<ThemeMode>>;
}

const OtherMenuItem = ({
  selected,
  isMobile,
  isMore,
  currentThemeMode,
  setCurrentThemeMode,
}: OtherMenuItem) => {
  const scripts = useScripts();

  const name = useAppSelector((state) => state.user.name) ?? '';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { roleText } = useAuth();

  const handleLogout = () => {
    dispatch(resetUser());

    // localStorage

    localStorage.removeItem('token');
    localStorage.removeItem('user');

    // clear store and refetch queries
    client.resetStore();
    client.clearStore();
    navigate('/');
  };

  const { anchorEl, open, handleClick, handleClose } = usePopoverAnchorEl();

  return (
    <>
      <MenuItem
        itemType="Other"
        initWidth={15}
        initHeight={24}
        selected={selected}
        isMobile={isMobile}
        isMore={isMore}
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ zIndex: 10000 }}
      >
        <MenuList dense={isMobile}>
          <MuiMenuItem
            sx={{ textTransform: 'capitalize', opacity: '1 !important' }}
            disabled
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            {!isMobile && scripts[roleText ?? '']}
            {isMobile && roleText && (
              <ListItemText primary={name} secondary={scripts[roleText ?? '']} />
            )}
          </MuiMenuItem>
          {isMobile && (
            <ThemeModeMenuItem
              isMobile={isMobile}
              currentThemeMode={currentThemeMode}
              setCurrentThemeMode={setCurrentThemeMode}
            />
          )}
          {isMobile && <ChineseMenuItem isMobile={isMobile} />}
          <Divider />
          <MuiMenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            {scripts.logout}
          </MuiMenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default OtherMenuItem;
