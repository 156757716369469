import { useRef, useEffect } from 'react';

import { PageInfo } from '../graphql/resolver.types';

type UseInfiniteScrollPageVariables = {
  increaseNum: number;
  search: string;
  extraQueryParameters: { [key: string]: any } | null;
  fieldName: string;
  keyFieldName: string;
  groupId: string;
  fetchMore: any;
  combineFetchMoreResult: (
    fieldName: string,
    prevResult: any,
    options: any,
    keyFieldName?: string,
  ) => any;
};

const useInfiniteScrollPageVariables = ({
  increaseNum,
  search,
  extraQueryParameters,
  fieldName,
  keyFieldName,
  groupId,
  fetchMore,
  combineFetchMoreResult,
}: UseInfiniteScrollPageVariables) => {
  const pageInfoRef = useRef<PageInfo | null>(null);

  const increaseNumRef = useRef(increaseNum);
  useEffect(() => {
    increaseNumRef.current = increaseNum;
    pageInfoRef.current = null;
  }, [increaseNum]);

  const searchTextRef = useRef(search.trim());
  useEffect(() => {
    searchTextRef.current = search.trim();
    pageInfoRef.current = null;
  }, [search]);

  const extraQueryParametersRef = useRef(extraQueryParameters);
  useEffect(() => {
    extraQueryParametersRef.current = extraQueryParameters;
    pageInfoRef.current = null;
  }, [extraQueryParameters]);

  const fieldNameRef = useRef(fieldName);
  useEffect(() => {
    fieldNameRef.current = fieldName;
  }, [fieldName]);

  const keyFieldNameRef = useRef(keyFieldName);
  useEffect(() => {
    keyFieldNameRef.current = keyFieldName;
  }, [keyFieldName]);

  const groupIdRef = useRef(groupId);
  useEffect(() => {
    groupIdRef.current = groupId;
  }, [groupId]);

  const fetchMoreRef = useRef(fetchMore);
  useEffect(() => {
    fetchMoreRef.current = fetchMore;
  }, [fetchMore]);

  const combineFetchMoreResultRef = useRef(combineFetchMoreResult);

  const combine = {
    increaseNumRef,
    searchTextRef,
    extraQueryParametersRef,
    fieldNameRef,
    keyFieldNameRef,
    groupIdRef,
    fetchMoreRef,
    pageInfoRef,
    combineFetchMoreResultRef,
  };

  return { ...combine, combine };
};

export default useInfiniteScrollPageVariables;
