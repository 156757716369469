import {
  isUndefined,
  isNull,
  isNaN,
  isEmpty,
  isObject,
  isArray,
  forOwn,
  pull,
  cloneDeep,
} from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { DraggableTag, DraggableType, SpecialId } from '../../../graphql/resolver.types';
import { Draggable } from '../../../redux/features/styleSlice';
/*eslint import/namespace: ['error', { allowComputed: true }]*/
import * as Draggables from '../components/draggable';

export type DraggableComponentType = typeof Draggables;
// export func for DraggableGroup
export function getDraggable<K extends keyof DraggableComponentType>(
  input: K | '',
): DraggableComponentType[K] | undefined {
  return input ? Draggables[input] : undefined;
}

export function getDraggables(draggables: Draggable[]) {
  //main
  return draggables.map((draggable, index) => {
    const { id, type } = draggable;
    const Draggable = getDraggable(type);
    return (
      Draggable && <Draggable key={id} index={index} {...draggable} isClone={true} />
    );
  });
}
// TODO: Refactor removeUnwantedFields, removeMediaFields, initStyleFields to base recursive func
export function removeUnwantedFields(draggables: any) {
  // Remove local use parameters that DraggableInput does not contain
  return (function removeUnwanted(current) {
    return current.map((draggable: any) => {
      // if (draggable?.id !== undefined) delete draggable.id; ###fix draggable ID
      if (draggable?.page !== undefined) delete draggable.page;
      if (draggable?.image !== undefined) {
        delete draggable.image?.name;
        delete draggable.image?.imageUrl;
        if (draggable?.image?.id === SpecialId.ThirdParty) {
          delete draggable.image.id;
        }
      }
      if (draggable?.audio?.audioUrl !== undefined) delete draggable.audio?.audioUrl;
      if (draggable?.video?.videoUrl !== undefined) delete draggable.video?.videoUrl;
      if (draggable?.answer?.videoAns !== undefined) {
        for (const ans of draggable.answer.videoAns) delete ans.id;
      }
      // recursive remove __typename in pruneEmpty function, so data in redux will not have __typename

      if ('group' in draggable && draggable.group) {
        draggable.group = removeUnwantedFields(draggable.group);
      }
      return draggable;
    });
  })(cloneDeep(draggables));
}

export function removeMediaFields(draggables: any) {
  // Based on removeUnwantedFields
  return (function removeMedia(current) {
    return current.map((draggable: any) => {
      if (draggable?.image !== undefined) delete draggable.image;
      if (draggable?.audio !== undefined) delete draggable.audio;
      if (draggable?.video !== undefined) delete draggable.video;

      if ('group' in draggable && draggable.group) {
        draggable.group = removeMediaFields(draggable.group);
      }
      return draggable;
    });
  })(cloneDeep(draggables));
}

export function flatStyleTemplateFields(draggables: any) {
  // Based on removeUnwantedFields
  return (function flatStyleTemplate(current) {
    return current.flatMap((draggable: any) => {
      if ('group' in draggable && draggable.group) {
        if (draggable?.type === DraggableType.StyleTemplate) {
          const left = parseFloat(draggable?.style?.left as string);
          const top = parseFloat(draggable?.style?.top as string);
          draggable = draggable.group.map((dragChild: any) => {
            dragChild.style.left = `${
              parseFloat(dragChild.style.left as string) + left
            }%`;
            dragChild.style.top = `${parseFloat(dragChild.style.top as string) + top}%`;
            return dragChild;
          });
        } else {
          draggable.group = flatStyleTemplate(draggable.group);
        }
      }
      return draggable;
    });
  })(cloneDeep(draggables));
}

export function initStyleFields(draggables: any) {
  // Based on removeUnwantedFields, init local use parameters that DraggableInput does not contain
  return (function initStyle(current) {
    return current.map((draggable: any) => {
      if (draggable?.tags?.includes(DraggableTag.GroupOfGroup)) draggable.page = 0;

      if ('group' in draggable && draggable.group) {
        draggable.group = initStyle(draggable.group);
      }
      return draggable;
    });
  })(cloneDeep(draggables));
}

export function pruneEmpty(obj: any) {
  return (function prune(current) {
    forOwn(current, function (value, key) {
      if (
        isUndefined(value) ||
        isNull(value) ||
        isNaN(value) ||
        // (isString(value) && isEmpty(value)) ||
        key === '__typename' ||
        (isObject(value) && isEmpty(prune(value)))
      ) {
        delete current[key];
      }
    });
    // remove any leftover undefined values from the delete
    // operation on an array
    if (isArray(current)) pull(current, undefined);

    return current;
  })(cloneDeep(obj)); // Do not modify the original object, create a clone instead
}

export function accept(
  acceptTags?: Array<DraggableTag>,
  selectedTags?: Array<DraggableTag>,
): boolean {
  if (!acceptTags || acceptTags?.length === 0) return true; // no constraint
  if (!selectedTags) return false;

  return acceptTags.some((accept) => selectedTags.includes(accept));
}

export function shuffle(array: Array<string | number>) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function transformGroup(
  draggables: Draggable[],
  widthScaling: number,
  heightScaling: number,
) {
  return draggables.map((draggable) => {
    draggable.id = uuidv4();
    // Scaling in equal proportions, transform to Container space
    const leftPercent = parseFloat(draggable.style.left as string);
    const topPercent = parseFloat(draggable.style.top as string);
    const widthPercent = parseFloat(draggable.style.width as string);
    const heightPercent = parseFloat(draggable.style.height as string);
    draggable.style.left = `${widthScaling * leftPercent}%`;
    draggable.style.top = `${heightScaling * topPercent}%`;
    draggable.style.width = `${widthScaling * widthPercent}%`;
    draggable.style.height = `${heightScaling * heightPercent}%`;
    if ('group' in draggable && draggable.group) {
      draggable.group = transformGroup(draggable.group, widthScaling, heightScaling);
    }
    return draggable;
  });
}

export function getDraggableById(
  targetId: string | undefined,
  draggables: Draggable[],
): Draggable | undefined {
  if (!targetId) return;
  for (const draggable of draggables) {
    if (draggable.id === targetId) return draggable;
    // start recursive
    if ('group' in draggable && draggable.group) {
      const target = getDraggableById(targetId, draggable.group);
      if (target) return target;
    }
  }
}

export function getSameGroupDraggableByType(
  targetId: string | undefined,
  targetType: DraggableType,
  draggables: Draggable[],
  layer = 0,
): Draggable | undefined {
  // Based on getDraggableById
  if (!targetId) return;
  for (const draggable of draggables) {
    if (draggable.id === targetId && layer > 0) return draggable; // Not allowed at the top layer
    // start recursive
    if ('group' in draggable && draggable.group) {
      const target = getSameGroupDraggableByType(
        targetId,
        targetType,
        draggable.group,
        layer + 1,
      );
      if (target) {
        if (target?.type === targetType) return target; // Find the final target, just return
        // else, Find the targetType draggable in the group
        for (const item of draggable.group) {
          if (item?.type === targetType) {
            return item;
          }
        }
      }
    }
  }
}

export function getDraggableIndexById(id: string, draggables: Draggable[]) {
  return draggables.findIndex((draggable) => draggable.id === id);
}

export function calcTotalPoint(draggables: Draggable[]): number {
  let point = 0;
  // let atLeastOneAnswer = false; // Situation: one style as one question: it contains at least one AnswerOption and no DraggbleGeroup or GroupOfGroup
  for (const draggable of draggables) {
    if (draggable.type !== DraggableType.StyleTemplate) {
      if (draggable.tags.includes(DraggableTag.Group)) {
        // console.log('group + 1', draggable.type); // TODO: can remove later
        //True/False & (image)FillBlank & ReOrder & Voice test
        point += 1;
      } else if (
        draggable.tags.includes(DraggableTag.GroupOfGroup) &&
        'group' in draggable &&
        draggable.group
      ) {
        //Cloze test
        for (const item of draggable.group) {
          if (item.group) {
            for (const ele of item.group) {
              ele?.answer?.correct === true ? (point += 1) : point;
            }
          }
        }
        // console.log('gog + ', draggable.group.length, draggable.type); // TODO: can remove later
        // point += draggable.group.length;
      } else if (draggable.answer) {
        if (draggable.answer.videoAns) {
          // video test
          draggable.answer.videoAns.forEach((videoAns) =>
            videoAns.options.forEach((option) => {
              if (option.correct) {
                point++;
              }
            }),
          );
        } else {
          //check test
          draggable.answer.correct === true ? (point += 1) : point;
        }
        // atLeastOneAnswer = true;
      }
    } else if ('group' in draggable && draggable.group) {
      // start recursive when StyleTemplate
      const groupPoint = calcTotalPoint(draggable.group);
      point += groupPoint;
    }
  }
  if (point === 0) {
    // console.log('one stlye, one question'); // TODO: can remove later
    point = 1;
  }

  return point;
}
