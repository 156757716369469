//ref: https://ithelp.ithome.com.tw/articles/10284495
import React, { CSSProperties, useState } from 'react';

import ExcelJs from 'exceljs';

import DownloadFileIcon from '../../../svg/DownloadFileIcon';

export type SheetData = {
  sheetName: string; // 工作表名稱
  thead: Array<string>; // 欄位標題
  tbody: Array<Array<string>>;
  columnWidths?: Array<{ number: number; width: number }>; //用來指定欄寬的
};

interface DownloadClassGradeOfTheBookButtonProps {
  fileName: string; // 檔案名稱
  //sheetDatas: Array<SheetData>; // 要匯出的表格資料
  disabled?: boolean; // 是不是要禁止按鈕動作
  buttonRef?: React.MutableRefObject<any>; // 外面用useRef傳進來
  style?: CSSProperties; // 按鈕的style
  classroomName: string;
  lessons: {
    __typename?: 'LessonGrade' | undefined;
    id: string;
    name: string;
    progress: number;
    averageScore: number;
    parts: {
      __typename?: 'Part' | undefined;
      id: string;
      name: string;
    }[];
    studentGrades: {
      id: string;
      name: string;
      username: string;
      parts: {
        id: string;
        name: string;
        progress: number;
        averageScore: number;
      }[];
    }[];
  }[];
}

export function DownloadClassGradeOfTheBookButton(
  props: DownloadClassGradeOfTheBookButtonProps,
) {
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleDownload = async () => {
    setLoading(true);

    try {
      const workbook = new ExcelJs.Workbook();
      // workbook.creator = '';
      // workbook.lastModifiedBy = '';
      // workbook.created = new Date();
      // workbook.modified = new Date();

      props.lessons
        .map(({ name, parts, studentGrades }) => {
          return {
            sheetName: name,
            thead: ['No.', 'username', 'name', ...parts?.map(({ name }) => name)],
            tbody: studentGrades.map((each, index) =>
              [
                (index + 1).toString(),
                each.username,
                each.name,
                each.parts.map(({ averageScore }) => averageScore.toString()),
              ].flat(),
            ),
          };
        })
        .forEach((sheetData: SheetData) => {
          const sheet = workbook.addWorksheet(sheetData.sheetName);

          const toLetters = (num: number): string => {
            const mod: number = num % 26;
            let pow: number = Math.floor(num / 26);
            const out: string = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
            return pow ? toLetters(pow) + out : out;
          };

          sheet.getCell(`A1:${toLetters(sheetData.thead.length)}1`).value = `${
            props.fileName + sheetData.sheetName
          }`;
          sheet.mergeCells(`A1:${toLetters(sheetData.thead.length)}1`);
          sheet.getCell(`A2:${toLetters(sheetData.thead.length)}2`).value = `
        ${props.classroomName} ${sheetData.tbody.length}位學生`;
          sheet.mergeCells(`A2:${toLetters(sheetData.thead.length)}2`);

          sheet.addTable({
            name: sheetData.sheetName.replace(/ /g, '').replace(/-/g, '_'), // remove space and replace "-"// TODO: fix other sign
            ref: `A3`, // 從A3開始
            headerRow: true,
            columns: sheetData.thead.map((s) => {
              return {
                name: s,
              };
            }),
            rows: sheetData.tbody,
          });
          if (sheetData.columnWidths) {
            sheetData.columnWidths.forEach((column) => {
              sheet.getColumn(column.number).width = column.width;
            });
          }

          //style
          const row = sheet.getRow(1);
          row.eachCell((cell: any, rowNumber: number) => {
            const col = sheet.getColumn(rowNumber);
            col.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };
            col.font = {
              size: 12,
              family: 2,
            };
            col.width = 20;
          });

          sheet.getRow(1).font = {
            family: 1,
            color: { argb: '3a5785' },
            size: 14,
            bold: true,
          };
          sheet.getRow(2).font = {
            bold: true,
            size: 14,
            family: 1,
            color: { argb: '374f49' },
          };
        });

      // 表格裡面的資料都填寫完成之後，訂出下載的callback function
      // 異步的等待他處理完之後，創建url與連結，觸發下載
      const content = await workbook.xlsx.writeBuffer();
      const link = document.createElement('a');
      const blob = new Blob([content], {
        type: 'applicationi/xlsx',
      });

      link.download = `${
        props.fileName.replace(/ /g, '').replace(/-/g, '_') +
        props.classroomName +
        ' Grades'
      }.xlsx`;
      link.href = URL.createObjectURL(blob);
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const style: CSSProperties = {
    ...props.style,
  };

  return (
    <button
      ref={props.buttonRef}
      disabled={loading || props.disabled}
      onClick={handleDownload}
      style={style}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <DownloadFileIcon
        color={isHovered ? '#00A591' : '#585858'}
        width={33}
        height={22}
      />
    </button>
  );
}

export default DownloadClassGradeOfTheBookButton;
