import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { ApolloError } from '@apollo/client';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import { client } from '../apollo/apollo.client';
import { useScripts } from '../layout/utils/LanguageHelper';
import { resetUser } from '../redux/features/userSlice';
import { useAppDispatch } from '../redux/store';

const CopyErrorTextButton = ({ errorText }: { errorText: string }) => {
  const scripts = useScripts();

  const [open, setOpen] = useState(false);
  const [copyResultText, setCopyResultText] = useState('');

  const handleTooltipOpen = () => setOpen(true);
  const handleTooltipClose = () => setOpen(false);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={copyResultText}
          placement="top"
          arrow
        >
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              navigator.clipboard
                .writeText(errorText)
                .then(() => setCopyResultText(scripts.copied))
                .catch(() => setCopyResultText(scripts.copyFailedTryAgain));
              handleTooltipOpen();
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}
            >
              <Typography variant="inherit">{scripts.copyErrorText}</Typography>
              <ContentCopyIcon />
            </Stack>
          </Button>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};

type OperationResultData = {
  data: any | null | undefined;
  error: ApolloError | AxiosError | undefined;
  loading: boolean;
};
export type Message = {
  successMessage?: string | JSX.Element;
  errorMessage?: string | JSX.Element;
};
type SnackbarState = {
  open: boolean;
  vertical: 'top' | 'bottom';
  horizontal: 'center' | 'left' | 'right';
};

export type UpdateOperationResult = {
  setSnackbarState: (style: {
    vertical?: 'top' | 'bottom';
    horizontal?: 'center' | 'left' | 'right';
  }) => void;
  operationLoading: (showDialog?: boolean) => void;
  operationSuccess: () => void;
  operationError: (msg?: string | JSX.Element, error?: ApolloError | AxiosError) => void;
  operationLoaded: () => void;
};

const OperationResultMessage = forwardRef<UpdateOperationResult, unknown>(
  (props, ref) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const scripts = useScripts();

    const defaultSuccessMessage = scripts.operationSuccessfully;
    const defaultErrorMessage = scripts.operationFailed;
    const [operationResultData, setOperationResultData] = useState<OperationResultData>({
      data: undefined,
      error: undefined,
      loading: false,
    });
    const [message, setMessage] = useState<Message>({
      successMessage: defaultSuccessMessage,
      errorMessage: defaultErrorMessage,
    });
    const [snackbarState, setSnackbarState] = useState<SnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
    const [open, setOpen] = useState(false);
    const operationResultDataRef = useRef({
      ...operationResultData,
      snackbarState: { ...snackbarState },
      open,
    });
    const [detailErrorMessage, setDetailErrorMessage] = useState('');

    useEffect(() => {
      operationResultDataRef.current = {
        ...operationResultData,
        snackbarState: { ...snackbarState },
        open,
      };
    }, [operationResultData, snackbarState, open]);

    useImperativeHandle(ref, () => ({
      setSnackbarState: (style) => {
        if (!style.horizontal && !style.vertical) {
          return;
        }
        setSnackbarState({
          ...snackbarState,
          ...Object.assign(
            style.vertical ? { vertical: style.vertical } : {},
            style.horizontal ? { horizontal: style.horizontal } : null,
          ),
        });
      },
      operationLoading: (showDialog = true) => {
        if (operationResultDataRef.current.loading) {
          return;
        }

        setOpen(showDialog);
        setOperationResultData({
          data: undefined,
          error: undefined,
          loading: true,
        });
        setSnackbarState({ ...snackbarState, open: false });
      },
      operationSuccess: () => {
        if (operationResultDataRef.current.data) {
          return;
        }

        setTimeout(() => {
          setOpen(false);
          setOperationResultData({
            data: true,
            error: undefined,
            loading: false,
          });
          setSnackbarState({ ...snackbarState, open: true });
        }, 100);
      },
      operationError: (msg, error) => {
        if (operationResultDataRef.current.error) {
          return;
        }
        setTimeout(() => {
          setOpen(true);
          setOperationResultData({
            data: undefined,
            error: error,
            loading: false,
          });
          if (msg) {
            setMessage((message) => ({
              ...message,
              errorMessage: msg,
            }));
          }
        }, 100);
      },
      operationLoaded: () => {
        if (
          !operationResultDataRef.current.open &&
          !operationResultDataRef.current.snackbarState.open
        ) {
          return;
        }

        setOpen(false);
        setOperationResultData({
          data: undefined,
          error: undefined,
          loading: false,
        });
        setSnackbarState({ ...snackbarState, open: false });
      },
    }));

    const getErrorText = () => {
      const error = operationResultData.error;
      if (error) {
        return JSON.stringify(error, null, 2);
      }

      return '';
    };

    return (
      <>
        {(open && !operationResultData.data && (
          <Dialog
            open={open}
            maxWidth={operationResultData.error ? 'sm' : 'xs'}
            fullWidth={true}
            PaperProps={{ sx: { alignItems: 'center' } }}
          >
            {(operationResultData.loading && (
              <DialogContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Stack position="relative" overflow="hidden">
                  <CircularProgress
                    variant="determinate"
                    sx={{
                      color: (theme) => theme.palette.grey[200],
                    }}
                    size={240}
                    thickness={4}
                    value={100}
                  />
                  <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                      position: 'absolute',
                      color: () => '#FEB95F',
                      animationDuration: '550ms',
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                      },
                    }}
                    size={240}
                    thickness={4}
                  />
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    width="100%"
                    height="100%"
                    position="absolute"
                  >
                    <Typography
                      variant="h5"
                      width="100%"
                      textAlign="center"
                      fontSize="32px"
                    >
                      {scripts.processing}
                    </Typography>
                    <Typography
                      variant="body2"
                      width="100%"
                      textAlign="center"
                      fontSize="24px"
                    >
                      {scripts.pleaseWait}
                    </Typography>
                  </Stack>
                </Stack>
              </DialogContent>
            )) ||
              (operationResultData.error && (
                <>
                  <DialogContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-start"
                      spacing={2}
                      width="100%"
                    >
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        width="100%"
                      >
                        <Box>
                          <CancelIcon sx={{ fontSize: 60 }} color="error" />
                        </Box>
                        <DialogContentText sx={{ marginBottom: 2 }} component={Box}>
                          {message.errorMessage
                            ? message.errorMessage
                            : defaultErrorMessage}
                        </DialogContentText>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            const textAreaElement = e.currentTarget?.parentElement
                              ?.nextElementSibling as HTMLDivElement | null;
                            if (!textAreaElement) {
                              return;
                            }

                            const cssCollection = window.getComputedStyle(
                              textAreaElement,
                              null,
                            );
                            const display = cssCollection.getPropertyValue('display');
                            if (display === 'none') {
                              textAreaElement.style.display = 'inline-flex';
                              setDetailErrorMessage(getErrorText());
                            } else {
                              textAreaElement.style.display = 'none';
                            }
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={1}
                          >
                            <Typography variant="inherit">{scripts.details}</Typography>
                            <ExpandMoreIcon />
                          </Stack>
                        </Button>
                        <CopyErrorTextButton errorText={getErrorText()} />
                      </Stack>
                      <TextField
                        fullWidth
                        multiline
                        rows={6}
                        sx={{ display: 'none' }}
                        InputProps={{
                          readOnly: true,
                          value: detailErrorMessage,
                        }}
                      />
                    </Stack>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpen(false);
                        const token = localStorage.getItem('token');
                        if (!token) {
                          dispatch(resetUser());
                          localStorage.clear();
                          client.resetStore();
                          client.clearStore();
                          navigate('/');
                        }
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </>
              ))}
          </Dialog>
        )) ||
          ''}
        {(snackbarState.open && (
          <Box
            sx={{
              width: '100%',
              position: 'absolute',
              zIndex: 10001,
              '@media print': { display: 'none' },
            }}
          >
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snackbarState.open}
              onClose={() => setSnackbarState({ ...snackbarState, open: false })}
              autoHideDuration={3000}
            >
              <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                {message.successMessage ? message.successMessage : defaultSuccessMessage}
              </Alert>
            </Snackbar>
          </Box>
        )) ||
          ''}
      </>
    );
  },
);

OperationResultMessage.displayName = 'OperationResultMessage';

export default OperationResultMessage;
