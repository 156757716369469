import { useLayoutEffect } from 'react';

import Stack from '@mui/material/Stack';
import { cloneDeep, defaultsDeep } from 'lodash-es';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import {
  DraggableType,
  DraggableTag,
  PositionAlign,
  Layout,
} from '../../../../graphql/resolver.types';
import CorrectIcon from '../../../../img/style/option/correct.png';
import IncorrectIcon from '../../../../img/style/option/incorrect.png';
import { Draggable } from '../../../../redux/features/styleSlice';
import { useStyle } from '../../context/StyleContext';
import { getDraggables } from '../../utils/draggables';

export const trueFalseAnswerChild: Array<Draggable> = [
  {
    id: '',
    type: DraggableType.TrueAnswer,
    tags: [DraggableTag.Button],
    name: '',
    style: { width: '30%', height: '80%', left: '10%', top: '10%', objectFit: 'contain' },
    lockAspectRatio: true,
    answer: {
      correct: false,
      correctCheckBoxPosition: PositionAlign.TopLeft,
    },
  },
  {
    id: '',
    type: DraggableType.FalseAnswer,
    tags: [DraggableTag.Button],
    name: '',
    style: { width: '30%', height: '80%', left: '60%', top: '10%', objectFit: 'contain' },
    lockAspectRatio: true,
    answer: {
      correct: false,
      correctCheckBoxPosition: PositionAlign.TopLeft,
    },
  },
];

const landscapeProps: DraggableInitProps = {
  type: DraggableType.TrueFalseAnswer,
  tags: [DraggableTag.Group],
  style: {
    width: '28.05163%',
    height: '22.16394%',
  },
  lockAspectRatio: false,
  group: [
    {
      ...trueFalseAnswerChild[0],
      style: {
        ...trueFalseAnswerChild[0].style,
        width: '8.41549%',
        height: '17.73115%',
        left: '2.80516%',
        top: '2.21639%',
      },
    },
    {
      ...trueFalseAnswerChild[1],
      style: {
        ...trueFalseAnswerChild[1].style,
        width: '8.41549%',
        height: '17.73115%',
        left: '16.83098%',
        top: '2.21639%',
      },
    },
  ],
};

export const defaultTrueFalseAnswerProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
    style: {
      ...landscapeProps.style,
      width: '75.001791%',
      height: '18.750251%',
    },
    group: [
      {
        ...trueFalseAnswerChild[0],
        style: {
          ...trueFalseAnswerChild[0].style,
          width: '22.500537%',
          height: '15.000201%',
          left: '7.500179%',
          top: '1.875025%',
        },
      },
      {
        ...trueFalseAnswerChild[1],
        style: {
          ...trueFalseAnswerChild[1].style,
          width: '22.500537%',
          height: '15.000201%',
          left: '45.001075%',
          top: '1.875025%',
        },
      },
    ],
  },
};

export default function TrueFalseAnswer(props: DraggableOuterProps) {
  const { layout, draggablesPropsRef, defineCompletelyDeleteDraggable } = useStyle();
  const defaultProps = layout
    ? defaultTrueFalseAnswerProps[layout]
    : defaultTrueFalseAnswerProps.landscape;

  const defaultTrueFalseAnswer: DraggableInnerProps = {
    ...defaultProps,
    style: {
      ...defaultProps.style,
      width: '33.75vmin',
      height: 'auto',
    },
    group: props.isClone ? undefined : trueFalseAnswerChild,
    originWrapperStyle: {
      backgroundColor: '#f3f3f3',
      borderRadius: '6px',
    },
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultTrueFalseAnswer,
  );

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep(props);
      defineCompletelyDeleteDraggable(id);
    }
  }, [dragProps]);

  const pseudoElement = {
    ':before': {
      content: '""',
      display: 'block',
      width: '18px',
      height: '18px',
      border: '2px solid rgba(0, 0, 0, 0.6)',
      borderRadius: '3px',
      mb: 1,
    },
  };

  // main
  return (
    <WrapperRND {...dragProps}>
      {dragProps.isClone && dragProps.group ? (
        getDraggables(dragProps.group)
      ) : (
        <CenterBox>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            width="30%"
            height="80%"
            py={1.5}
            sx={pseudoElement}
          >
            <img
              src={CorrectIcon}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              draggable={false}
            />
          </Stack>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            width="30%"
            height="80%"
            ml="20%"
            py={1.5}
            sx={pseudoElement}
          >
            <img
              src={IncorrectIcon}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              draggable={false}
            />
          </Stack>
        </CenterBox>
      )}
    </WrapperRND>
  );
}
