function getScrollInfo() {
  const bodyScrollTop = document.body.scrollTop;
  const documentScrollTop = document.documentElement.scrollTop;
  const scrollTop =
    bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;

  const bodyScrollHeight = document.body.scrollHeight;
  const documentScrollHeight = document.documentElement.scrollHeight;
  const scrollHeight =
    bodyScrollHeight - documentScrollHeight > 0 ? bodyScrollHeight : documentScrollHeight;

  const windowHeight =
    document.compatMode === 'CSS1Compat'
      ? document.documentElement.clientHeight
      : document.body.clientHeight;
  return {
    scrollTop,
    windowHeight,
    scrollHeight: scrollHeight * 0.8,
    originScrollHeight: scrollHeight,
  };
}

export default getScrollInfo;
