import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Class {
  barIcon: {
    open: boolean;
    type: string;
  };
  barIsOpen: boolean;
}
const initialState: Class = {
  barIcon: {
    open: false,
    type: '',
  },
  barIsOpen: false,
};

export const classSlice = createSlice({
  name: 'class',
  initialState,
  reducers: {
    updateBarIcon: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.barIcon = { ...state.barIcon, ...action.payload };
    },
    updateBarIsOpen: (state, action: PayloadAction<boolean>) => {
      state.barIsOpen = action.payload;
    },
    reset: () => initialState,
  },
});

export const { updateBarIcon, updateBarIsOpen } = classSlice.actions;

export default classSlice.reducer;
