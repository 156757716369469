import { useState } from 'react';

import Autocomplete, {
  AutocompleteInputChangeReason,
  // AutocompleteChangeDetails,
  // AutocompleteChangeReason,
  AutocompleteOwnerState,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { FilterOptionsState } from '@mui/material/useAutocomplete';

import CustomInputText from './CustomInputText';
import { useScripts } from '../layout/utils/LanguageHelper';
import KeyboardArrowDownIcon from '../svg/KeyboardArrowDownIcon';

export type Option = {
  label: string;
  value: string;
};

type CustomAutocompleteProps = {
  formLabel?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  customOpenBehavior?: {
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
  options: Option[];
  value: Option[] | string[];
  limitTags?: number;
  disableCloseOnSelect?: boolean;
  groupBy?: (option: Option) => string;
  renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Option,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  renderTags?: (
    value: Option[],
    getTagProps: AutocompleteRenderGetTagProps,
    ownerState: AutocompleteOwnerState<Option, boolean, boolean, boolean, 'div'>,
  ) => React.ReactNode;
  renderInputOnKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  getOptionDisabled?: (option: Option) => boolean;
  onChange?: (e: React.SyntheticEvent<Element, Event>, value: Option[]) => void;
  onFocusCapture?: (e: React.SyntheticEvent<Element, Event>) => void;
  setValue?: React.Dispatch<React.SetStateAction<string[]>>;
  onChangeForSingleValue?: (
    e: React.SyntheticEvent<Element, Event>,
    value: Option | undefined,
  ) => void;
  setValueForSingleValue?: React.Dispatch<React.SetStateAction<string>>;
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => void;
  startAdornment?: JSX.Element;
  freeSolo?: boolean;
  filterOptions?: (options: Option[], state: FilterOptionsState<Option>) => Option[];
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  borderRadius?: React.CSSProperties['borderRadius'];
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  inputValue?: string;
  inputSx?: SxProps<Theme>;
  label?: string;
  multiple?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
  loading?: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: 'medium' | 'small';
  dialogProps: {
    willSubmit: boolean;
  };
  backgroundColor?: string;
  borderLineColor?: string;
  width?: number;
  canClearInput?: boolean;
};

const CustomAutocomplete = ({
  backgroundColor = '#FFF',
  ...props
}: CustomAutocompleteProps): JSX.Element => {
  const scripts = useScripts();

  const [open, setOpen] = useState(false);

  const label = props.label ? props.label : '';
  const required = props.required ? { required: true } : { required: false };
  const fullWidth = props.fullWidth ? { fullWidth: true } : { fullWidth: false };
  const [isHovered, setIsHovered] = useState(false);

  const isOption = (
    options: NonNullable<string | Option> | (string | Option)[] | null,
  ): options is Option => {
    return !Array.isArray(options) && typeof options !== 'string' && !!options;
  };
  const isOptionArray = (
    options: NonNullable<string | Option> | (string | Option)[] | null,
  ): options is Option[] => {
    return (
      Array.isArray(options) &&
      options.reduce<boolean>((prev, curr) => {
        if (!prev) {
          return prev;
        }

        return typeof curr !== 'string';
      }, true)
    );
  };
  const isStringOption = (arr: Option[] | string[]): arr is string[] => {
    return typeof arr?.[0] === 'string';
  };
  const isEmptyFirstValue =
    (isStringOption(props.value) && !props.value?.[0]) ||
    (!isStringOption(props.value) && !props.value?.[0]?.value);
  const isEmptyValue =
    props.value.length === 0 ||
    (props.multiple && props.value.length === 1 && isEmptyFirstValue) ||
    (!props.multiple && isEmptyFirstValue);
  const error = props.error
    ? {
        error:
          (props.error || (props.required ? isEmptyValue : false)) &&
          props.dialogProps.willSubmit,
      }
    : {
        error: !!(props.required && isEmptyValue && props.dialogProps.willSubmit),
      };
  let helperText =
    props.required && isEmptyValue && props.dialogProps.willSubmit ? (
      <Typography variant="caption" sx={{ display: 'block' }}>
        {props.multiple
          ? scripts.PleaseSelectAtLeastOneOptions
          : scripts.pleaseSelectAnOption}
      </Typography>
    ) : (
      ''
    );
  helperText = props.helperText ? (
    <>
      <Typography variant="caption" sx={{ display: 'block' }}>
        {props.helperText}
      </Typography>
      {helperText}
    </>
  ) : (
    helperText
  );
  const readOnly = props.readOnly
    ? { readOnly: true, freeSolo: true }
    : { readOnly: false };
  const disableClearable = props.disableClearable
    ? { disableClearable: true }
    : { disableClearable: false };
  const disabled = props.disabled ? { disabled: true } : { disabled: false };

  const params = {
    ...(props?.customOpenBehavior
      ? props.customOpenBehavior
      : {
          open,
          onOpen: () => {
            setOpen(true);
            props.onOpen && props.onOpen();
          },
          onClose: () => {
            setOpen(false);
            props.onClose && props.onClose();
          },
        }),
    multiple: !!props?.multiple,
    size: props?.size ?? 'medium',
    options: props.options,
    getOptionLabel: (option: string | Option) =>
      typeof option === 'string' ? option : option.label,
    isOptionEqualToValue: (option: Option, value: Option) =>
      option?.label === value?.label && option?.value === value?.value,
    renderInput: (params: AutocompleteRenderInputParams) => (
      <CustomInputText
        {...(!props.formLabel ? { label } : null)}
        {...required}
        {...params}
        {...(props.renderInputOnKeyDown
          ? { onKeyDown: props.renderInputOnKeyDown }
          : null)}
        {...(props?.type ? { type: props.type } : null)}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {!!props.loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
          ...(props?.startAdornment ? { startAdornment: props.startAdornment } : null),
          ...(props?.type === 'number'
            ? {
                sx: {
                  '& input[type=number]::-webkit-outer-spin-button,input[type=number]::-webkit-inner-spin-button':
                    {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
              }
            : { sx: { position: 'relative', ...props.inputSx } }),
          ...(props?.placeholder ? { placeholder: props.placeholder } : null),
        }}
      />
    ),
    onChange: (
      event: React.SyntheticEvent<Element, Event>,
      options: NonNullable<string | Option> | (string | Option)[] | null,
      // reason: AutocompleteChangeReason,
      // details?: AutocompleteChangeDetails<Option>,
    ) => {
      const multiple = props?.multiple;
      const setValue = props?.setValue;
      const onChange = props?.onChange;
      const setValueForSingleValue = props?.setValueForSingleValue;
      const onChangeForSingleValue = props?.onChangeForSingleValue;
      const canClearInput = props?.canClearInput;

      if (multiple) {
        if (setValue) {
          Array.isArray(options) &&
            setValue(
              options.flatMap((option) =>
                typeof option !== 'string' ? option.value : [],
              ),
            );
        } else if (onChange && isOptionArray(options)) {
          onChange(event, options);
        }
      } else if (setValueForSingleValue) {
        typeof options !== 'string' &&
          !Array.isArray(options) &&
          setValueForSingleValue(options?.value ?? '');
      } else if (onChangeForSingleValue && isOption(options)) {
        onChangeForSingleValue(event, options);
      } else if (canClearInput && onChangeForSingleValue) {
        onChangeForSingleValue(event, undefined);
      }
    },
    ...(props?.onFocusCapture ? { onFocusCapture: props.onFocusCapture } : null),
    ...(props?.groupBy ? { groupBy: props.groupBy } : null),
    ...(props?.renderGroup ? { renderGroup: props.renderGroup } : null),
    ...(props?.renderOption
      ? { renderOption: props.renderOption }
      : {
          renderOption:
            props?.renderOption ??
            ((props, { label, value }) => (
              <li {...props} key={`${value}`}>
                {label}
              </li>
            )),
        }),
    ...(props?.renderTags ? { renderTags: props.renderTags } : null),
    ...(props?.getOptionDisabled ? { getOptionDisabled: props.getOptionDisabled } : null),
    ...(props?.onInputChange ? { onInputChange: props.onInputChange } : null),
    ...(props?.onKeyDown ? { onKeyDown: props.onKeyDown } : null),
    ...(props?.filterOptions ? { filterOptions: props.filterOptions } : null),
    ...(typeof props?.inputValue === 'string' ? { inputValue: props.inputValue } : null),
    ...(typeof props?.freeSolo === 'boolean' ? { freeSolo: props.freeSolo } : null),
    ...(typeof props?.limitTags === 'number' ? { limitTags: props.limitTags } : null),
    disableCloseOnSelect: !!props?.disableCloseOnSelect,
    value: props.multiple
      ? (isStringOption(props.value)
          ? props.options.length === 0
            ? []
            : props.value.flatMap(
                (id) => props.options?.find(({ value }) => id === value) ?? [],
              )
          : props.value) ?? []
      : (isStringOption(props.value)
          ? props.options.find(({ value }) => value === props.value[0])
          : props.value[0]) ?? { label: '', value: '' },
    ...readOnly,
    loading: !!props.loading,
    ...disableClearable,
    ...disabled,
    popupIcon: (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ padding: 0 }}
      >
        <KeyboardArrowDownIcon
          width={16}
          height={16}
          color={isHovered ? '#00A591' : 'rgba(0, 0, 0, 0.7)'}
        />
      </div>
    ),
  };

  return (
    <FormControl {...fullWidth} {...required} {...error} {...disabled}>
      {props.formLabel && (
        <FormLabel {...required} {...error} {...disabled}>
          {props.label}
        </FormLabel>
      )}
      <Autocomplete
        {...params}
        renderInput={(params) => (
          <CustomInputText
            {...params}
            {...error}
            {...(props.label ? { label: props.label } : null)}
            {...(props.required ? { required: props.required } : null)}
          />
        )}
        sx={{
          backgroundColor: backgroundColor,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderRadius: props?.borderRadius ?? '5px',
              borderColor: props?.borderLineColor ?? 'rgba(0, 0, 0, 0.23)',
            },
          },
          width: props.width,
        }}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomAutocomplete;
