import { ApolloError } from '@apollo/client/errors';
import { createSlice } from '@reduxjs/toolkit';

type Access = {
  canAllSchoolsAccess: boolean;
  accessRoles: Array<string>;
};

export type Part = {
  id: string;
  name: string;
  isHidden: boolean;
  isTrash: boolean;
  isHiddenToStudent: boolean;
  styleIds: Array<string>;
};

export type Lesson = {
  id: string;
  name: string;
  image?: {
    id: string;
    key: string;
    filename: string;
    imageUrl: string;
  };
  // access: {
  //   id: string;
  //   levelIds: Array<{
  //     id: string;
  //     isHidden: boolean;
  //     isTrash: boolean;
  //   }>;
  //   bookIds: Array<{
  //     id: string;
  //     isHidden: boolean;
  //     isTrash: boolean;
  //   }>;
  //   resourceIds: Array<{
  //     id: string;
  //     isHidden: boolean;
  //     isTrash: boolean;
  //   }>;
  //   schoolId: string;
  //   classroomId: string;
  //   userId: string;
  // };
  isHidden: boolean;
  isTrash: boolean;
  bookId: string;
  parts: Array<Part>;
  partTrashTotal: number;
};

export type Book = {
  id: string;
  name: string;
  lessonIds: Array<string>;
  isHidden: boolean;
  isTrash: boolean;
  image?: {
    id: string;
    filename: string;
    imageUrl: string;
  };
  access: Access;
};

export type Level = {
  id: string;
  name: string;
  stage: number;
  isHidden: boolean;
  isTrash: boolean;
  bookTrashTotal: number;
  books: Array<Book>;
  access: Access;
};

type InitialState = {
  isLoading: boolean;
  hasData: unknown | null | undefined;
  hasError: ApolloError | undefined;
  hasReachedBottom: boolean;
};

const initialState: InitialState = {
  isLoading: false,
  hasData: undefined,
  hasError: undefined,
  hasReachedBottom: false,
};

const levelsSlice = createSlice({
  name: 'levels',
  initialState,
  reducers: {
    setLoadingStatus: (state, action) => {
      state.isLoading = action.payload ? action.payload : false;
    },
    setDataStatus: (state, action) => {
      state.hasData = action.payload;
    },
    setErrorStatus: (state, action) => {
      state.hasError = action.payload;
    },
    setHasReachedBottom: (state, action) => {
      state.hasReachedBottom = action.payload;
    },
  },
});

export const { setLoadingStatus, setDataStatus, setErrorStatus, setHasReachedBottom } =
  levelsSlice.actions;
export default levelsSlice.reducer;
