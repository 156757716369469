import { SvgProps } from './MenuItem';
const LogoutSvg = ({ width, height }: SvgProps) => {
  const webMode = localStorage.getItem('theme');

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5920_11133)">
        <path
          d="M34.889 15.4845C34.8147 15.3066 34.7082 15.1447 34.5726 15.0091L30.199 10.6355C29.6288 10.0668 28.7072 10.0668 28.137 10.6355C27.5668 11.2057 27.5668 12.1289 28.137 12.6976L30.0226 14.5832H21.8763C21.0698 14.5832 20.418 15.2365 20.418 16.0415C20.418 16.8465 21.0698 17.4998 21.8763 17.4998H30.0225L28.1369 19.3855C27.5667 19.9557 27.5667 20.8788 28.1369 21.4475C28.4213 21.7333 28.7946 21.8748 29.168 21.8748C29.5413 21.8748 29.9147 21.7334 30.199 21.4475L34.5726 17.074C34.7082 16.9398 34.8147 16.7779 34.889 16.5985C35.0363 16.2429 35.0363 15.8404 34.889 15.4845Z"
          // fill="#090446"
          fill={webMode === 'Light' ? '#090446' : '#fff'}
        />
        <path
          d="M24.7916 20.4167C23.9852 20.4167 23.3333 21.07 23.3333 21.875V29.1667H17.5V5.83331C17.5 5.19019 17.0771 4.62144 16.4602 4.43625L11.3954 2.91669H23.3333V10.2084C23.3333 11.0134 23.9852 11.6667 24.7916 11.6667C25.5981 11.6667 26.2499 11.0134 26.2499 10.2084V1.45838C26.2499 0.65331 25.5981 0 24.7916 0H1.45831C1.40581 0 1.35912 0.021875 1.30813 0.0276855C1.23956 0.035 1.17687 0.0466211 1.11125 0.0626855C0.958125 0.102061 0.819561 0.161875 0.689814 0.243496C0.657754 0.263936 0.618379 0.265371 0.587754 0.288682C0.575996 0.2975 0.571621 0.313564 0.559932 0.322314C0.400996 0.447686 0.268311 0.600811 0.172061 0.783125C0.151621 0.8225 0.147246 0.864814 0.13125 0.905625C0.0845605 1.01644 0.0335645 1.12437 0.0160645 1.24687C0.00875 1.29062 0.021875 1.33144 0.0204395 1.37375C0.0190039 1.40294 0 1.42919 0 1.45831V30.625C0 31.3206 0.491436 31.9186 1.1725 32.0542L15.7558 34.9709C15.8506 34.9913 15.9469 35.0001 16.0416 35.0001C16.3756 35.0001 16.7037 34.8849 16.9662 34.669C17.3031 34.3919 17.4999 33.9792 17.4999 33.5417V32.0834H24.7916C25.5981 32.0834 26.2499 31.4301 26.2499 30.6251V21.875C26.2499 21.07 25.5981 20.4167 24.7916 20.4167Z"
          fill={webMode === 'Light' ? '#090446' : '#fff'}
        />
      </g>
      <defs>
        <clipPath id="clip0_5920_11133">
          <rect width="35" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutSvg;
