import { DraggableTag } from '../../../graphql/resolver.types';
import { updateSideLayout } from '../../../redux/features/styleSlice';
import { useAppDispatch } from '../../../redux/store';

const useSideBar = () => {
  const dispatch = useAppDispatch();

  const switchItemByTags = (tags: DraggableTag[]) => {
    if (Array.isArray(tags)) {
      if (tags.includes(DraggableTag.TextField)) {
        dispatch(updateSideLayout({ open: true, type: 'Text' }));
      } else if (tags.includes(DraggableTag.Elements)) {
        dispatch(updateSideLayout({ open: true, type: 'Elements' }));
      }
    }
  };

  return { switchItemByTags };
};

export default useSideBar;
