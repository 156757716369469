import { useLayoutEffect, useState, useRef, useEffect, SyntheticEvent } from 'react';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  Grid,
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  IconButton,
  Slider,
  Tooltip,
  Typography,
} from '@mui/material';
import { cloneDeep, defaultsDeep } from 'lodash-es';
import { useParams } from 'react-router-dom';

import { DraggableOuterProps, DraggableInnerProps } from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import { DraggableType, DraggableTag } from '../../../../graphql/resolver.types';
import VideoPlaceholder from '../../../../img/video_placeholder.png';
import { useScripts } from '../../../../layout/utils/LanguageHelper';
import { useAppSelector } from '../../../../redux/store';
import { StyleAnswerItem, useStyleAnswer } from '../../context/StyleAnswerContext';
import { useStyle } from '../../context/StyleContext';

export default function Video(props: DraggableOuterProps) {
  const defaultVideo: DraggableInnerProps = {
    type: DraggableType.Video,
    tags: [DraggableTag.Video],
    lockAspectRatio: false,
    bounds: '',
  };

  const scripts = useScripts();

  const videoRef = useRef<HTMLVideoElement>(null);
  const linearProgressTypographyRef = useRef<HTMLDivElement | null>(null);
  const sliderRef = useRef<HTMLSpanElement | null>(null);

  const { styleId = '' } = useParams();
  const adminViewMode = useAppSelector((state) => state.adminViewMode.currentMode);
  const [isQuestionModalOpen, setIsQuestionModal] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0); //紀錄影片第幾小題;
  const [currentQuestionId, setCurrentQuestionId] = useState<string | null>(); //紀錄影片第幾小題的ID

  const [isVideoFullscreen, setIsVideoFullscreen] = useState(false);
  const [userSeletedFullScreen, setUserSelectedFullScreen] = useState(true);

  const [isNextButtonOpen, setIsNextButtonOpen] = useState(false);

  const [videoDuration, setVideoDuration] = useState<number>();

  const [showVolumeBar, setShowVolumeBar] = useState(false);
  const [showVideoControl, setShowVideoControl] = useState(false);
  const [sliderValue, setSliderValue] = useState<number>(0);

  const [maxSelected, setMaxSelected] = useState<number>();

  const [isHasNestQuestion, setIsHasNestQuestion] = useState(false);

  const { styleAnswers, setStyleAnswers, disableSelect } = useStyleAnswer();

  const videoAns = props.answer?.videoAns;

  const dragProps: DraggableInnerProps = defaultsDeep(cloneDeep(props), defaultVideo);

  const { draggablesPropsRef, draggables } = useStyle();

  useEffect(() => {
    if (props.answer?.videoAns) {
      setCurrentQuestionId(props.answer.videoAns[currentQuestionIndex].id);
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsVideoFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const currentVideoData = videoAns?.[currentQuestionIndex ?? 0];

  useEffect(() => {
    if (currentQuestionIndex + 1 === videoAns?.length) {
      setIsHasNestQuestion(false);
    } else {
      if (currentVideoData?.time === videoAns?.[currentQuestionIndex + 1 ?? 0].time) {
        setIsHasNestQuestion(true);
      } else {
        setIsHasNestQuestion(false);
      }
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    let maxSelected = 0;
    let selected = 0;

    const currentDraggable = draggables.find((draggable) => draggable.id === props.id);

    if (currentDraggable?.answer?.videoAns) {
      const currentQuestion = currentDraggable.answer.videoAns.find(
        (answer) => answer.id === currentQuestionId,
      );

      currentQuestion?.options.forEach((option) => {
        if (option.correct) {
          maxSelected++;
        }
      });
    }

    const currentStyleAnswer = styleAnswers.find(
      (styleAnswer) => styleAnswer.styleId === styleId,
    );

    const currentUserAnswer = currentStyleAnswer?.userAnswers.find(
      (userAnswer) => userAnswer.draggableId === props.id,
    );

    if (Array.isArray(currentUserAnswer?.answer)) {
      selected =
        currentUserAnswer?.answer.reduce(
          (acc, curr) => (curr.parentId === currentQuestionId ? acc + 1 : acc),
          0,
        ) ?? 0;
    }

    setMaxSelected(maxSelected);

    if (maxSelected === selected && selected !== 0) {
      setIsNextButtonOpen(true);
    } else {
      setIsNextButtonOpen(false);
    }
  }, [styleAnswers, isQuestionModalOpen, currentQuestionIndex, currentQuestionId]);

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep({
        ...props,
        selectedBar: {
          ...dragProps?.selectedBar,
          getDeleteLabel: () => scripts.deleteThisVideo,
        },
      });
    }
  }, [dragProps]);

  const handleTimeUpdate = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    const target = event.target as HTMLVideoElement;

    setSliderValue(target.currentTime);
  };

  let animationFrameId: number | null = null;
  const checkTime = () => {
    if (videoRef.current && !videoRef.current.paused) {
      const currentTime = videoRef.current.currentTime;

      let foundQuestion = false;
      videoAns?.forEach((ele, index) => {
        if (Math.abs(currentTime - ele.time) < 0.01 && !foundQuestion) {
          setCurrentQuestionIndex(index);
          setIsQuestionModal(true);
          videoRef.current?.pause();
          cancelAnimationFrame(animationFrameId!);

          foundQuestion = true;
          return; // 找到題目後立即返回，不繼續迴圈
        }
      });

      animationFrameId = requestAnimationFrame(checkTime);
    }
  };

  const handleVideoPlay = () => {
    if (!animationFrameId) {
      animationFrameId = requestAnimationFrame(checkTime);
    }
  };

  const handleVideoPause = () => {
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
      animationFrameId = null;
    }
  };

  const handleRecordAnswer = ({
    // text,
    answer,
    draggableId,
    parentId,
  }: {
    // text: string;
    answer: boolean;
    draggableId: string;
    parentId: string;
  }) => {
    setStyleAnswers((prev) => {
      const newStyleAnswers = cloneDeep(prev);

      // 查找當前 styleId 對應的答案
      const existingStyleAnswer = newStyleAnswers.find((ele) => ele.styleId === styleId);

      if (existingStyleAnswer) {
        // 查找當前 draggableId 對應的 userAnswer
        const existingUserAnswer = existingStyleAnswer.userAnswers.find(
          (userAnswer) => userAnswer.draggableId === props.id,
        );

        if (existingUserAnswer) {
          if (Array.isArray(existingUserAnswer?.answer)) {
            // 查找或創建 answer 陣列中對應的影片答案
            const videoAnswerItem = existingUserAnswer?.answer.find(
              (ele) => ele.draggableId === draggableId,
            );

            if (videoAnswerItem) {
              //如果有找到，刪除他
              existingUserAnswer.answer = existingUserAnswer.answer.filter(
                (ele) => ele.draggableId !== draggableId,
              );
            } else {
              //如果没有找到，添加新的答案項,假如同時超過題數上限就不能新增

              const currentParentQuestions = existingUserAnswer.answer.filter(
                (ele) => ele.parentId === parentId,
              ).length;
              if (maxSelected) {
                if (currentParentQuestions < maxSelected) {
                  existingUserAnswer.answer.push({
                    parentId: parentId,
                    draggableId: draggableId || '',
                    answer: answer,
                  });
                }
              }
            }
          }
        } else {
          // 如果没有找到 userAnswer，創建一个新的
          existingStyleAnswer.userAnswers.push({
            draggableId: props.id || '',
            answer: [
              {
                parentId: parentId,
                draggableId: draggableId || '',
                answer: answer,
              },
            ],
            type: 'Video',
          });
        }
      } else {
        // 如果没有找到 styleAnswer，創建新的
        const newStyleAnswer = {
          styleId,
          userAnswers: [
            {
              draggableId: props.id, //最外層videoId
              answer: [
                {
                  parentId: parentId,
                  draggableId: draggableId,
                  answer: answer,
                },
              ],
              type: 'Video',
            },
          ],
          isDone: false,
        };
        return [...newStyleAnswers, newStyleAnswer] as StyleAnswerItem[];
      }

      return newStyleAnswers;
    });
  };

  const isOptionSelected = ({ optionId }: { optionId: string }) => {
    const currentStyleAnswer = styleAnswers.find(
      (styleAnswer) => styleAnswer.styleId === styleId,
    );

    const currentUserAnswer = currentStyleAnswer?.userAnswers.find(
      (userAnswer) => userAnswer.draggableId === props.id,
    );

    if (currentUserAnswer && Array.isArray(currentUserAnswer?.answer)) {
      return currentUserAnswer.answer.some((answer) => answer.draggableId === optionId);
    }

    return false;
  };

  const renderQuestionModal = () => {
    const currentVideoData = videoAns?.[currentQuestionIndex ?? 0];

    if (isVideoFullscreen && document.fullscreenElement) {
      document.exitFullscreen();
    }

    return (
      <Dialog
        open={isQuestionModalOpen}
        onClose={() => setIsQuestionModal(false)}
        PaperProps={{
          style: {
            width: '60%',
            height: 'calc(80% * 9 / 16)', // 計算高度為寬度的16:9比例
            maxWidth: 'unset', // 取消最大寬度限制
            maxHeight: 'unset', // 取消最大高度限制
            display: 'flex', // 使用flex布局
            justifyContent: 'center', // 水平置中
            alignItems: 'center', // 垂直置中
          },
        }}
      >
        <DialogContent
          style={{
            height: '100%',
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <DialogContentText sx={{ padding: '0.5em', height: '40%' }}>
            {currentVideoData?.question}
          </DialogContentText>

          <Grid container spacing={2}>
            {currentVideoData?.options.map((option, index) => {
              return (
                <Grid key={`option_${index}`} item xs={6}>
                  <Button
                    onClick={() => {
                      if (disableSelect) {
                        return;
                      }
                      handleRecordAnswer({
                        answer: true,
                        parentId: currentVideoData.id || '',
                        draggableId: option.id || '',
                      });
                    }}
                    style={{
                      border: '1px solid black',
                      borderRadius: '0.7em',
                      cursor: 'pointer',
                      backgroundColor: isOptionSelected({
                        optionId: option.id || '',
                      })
                        ? 'lightgreen'
                        : 'white', // 根據是否被選擇來設定背景顏色
                      width: '100%',
                      height: '100%',
                      fontSize: '1.2em',
                    }}
                  >
                    {option.text}
                  </Button>
                </Grid>
              );
            })}
          </Grid>

          <Grid container spacing={2} sx={{ height: '5%' }}>
            {isNextButtonOpen && (
              <Button
                onClick={() => {
                  if (isHasNestQuestion) {
                    if (videoAns?.length) {
                      setCurrentQuestionIndex((prev = 0) => {
                        if (prev === videoAns?.length - 1) {
                          return prev;
                        } else {
                          return prev + 1;
                        }
                      });
                    }
                  } else {
                    setIsQuestionModal(false);
                    setIsNextButtonOpen(false);

                    if (videoAns?.length) {
                      setCurrentQuestionIndex((prev = 0) => {
                        if (prev === videoAns?.length - 1) {
                          return prev;
                        } else {
                          return prev + 1;
                        }
                      });
                    }

                    if (userSeletedFullScreen) {
                      videoRef.current?.requestFullscreen();
                    }

                    videoRef.current?.play();
                  }
                }}
                style={{ width: '100%' }}
              >
                {scripts.nextQuestion}
              </Button>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const secondsToTime = (seconds: number) => {
    const min = `0${Math.floor(seconds / 60)}`.slice(-2);
    const sec = `0${Math.floor(seconds % 60)}`.slice(-2);

    return `${min}:${sec}`;
  };

  const handleLoadedData = () => {
    const video = videoRef.current;
    const linearProgressTypography = linearProgressTypographyRef.current;
    if (video && linearProgressTypography) {
      const durationTemp = video.duration;
      const currentTime = video.currentTime;
      linearProgressTypography.innerText = `${secondsToTime(currentTime)}/${secondsToTime(
        durationTemp,
      )}`;
    }
  };

  return (
    <WrapperRND {...dragProps}>
      <CenterBox>
        <div
          style={{
            position: 'relative',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
          onMouseEnter={() => setShowVideoControl(true)}
          onMouseLeave={() => setShowVideoControl(false)}
        >
          {isQuestionModalOpen && adminViewMode === 'view' && renderQuestionModal()}
          {dragProps.video?.videoUrl ? (
            <video
              ref={videoRef}
              // controls={!isQuestionModalOpen}
              controls={false}
              controlsList="nodownload"
              crossOrigin="anonymous"
              src={dragProps.video.videoUrl}
              preload="metadata"
              draggable={false}
              style={{
                height: '100%',
                width: '100%',
              }}
              onTimeUpdate={adminViewMode === 'view' ? handleTimeUpdate : undefined}
              onLoadedMetadata={() => {
                setVideoDuration(videoRef?.current?.duration);
              }}
              onLoadedData={() => {
                handleLoadedData();
              }}
              onPlay={adminViewMode === 'view' ? handleVideoPlay : undefined}
              onPause={adminViewMode === 'view' ? handleVideoPause : undefined}
            />
          ) : (
            <img
              src={VideoPlaceholder}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />
          )}

          {/*自定義video control*/}
          {showVideoControl && (
            <Grid
              container
              sx={{ height: '100%', width: '100%', position: 'absolute', bottom: '0%' }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  height: '75%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Tooltip title={scripts.play} placement="bottom">
                  <IconButton
                    onClick={() => {
                      if (videoRef.current?.paused) {
                        videoRef.current.play();
                        videoRef.current.requestFullscreen();
                      } else {
                        videoRef.current?.pause();
                      }
                    }}
                  >
                    {videoRef.current?.paused ? (
                      <PlayCircleFilledIcon sx={{ fontSize: '2.5em' }} />
                    ) : (
                      <PauseCircleIcon sx={{ fontSize: '2.5em' }} />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: '10%',
                }}
              >
                <Typography
                  ref={linearProgressTypographyRef}
                  variant="body1"
                  sx={{ marginLeft: '1em' }}
                >
                  {`${secondsToTime(videoRef?.current?.currentTime ?? 0)}/${secondsToTime(
                    videoDuration ?? 0,
                  )}`}
                </Typography>
              </Grid>

              {/* 自定義音量控制 */}
              <Grid item xs={3} sx={{ height: '10%' }}>
                {/* <div className="custom-volume-control">
                  <input
                    style={{ width: '50%' }}
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    // value={volume}
                    // onChange={(e) => setVolume(e.target.value)}
                  />
                </div>

                <IconButton
                  sx={{ width: '100%' }}
                  size="small"
                  // className="custom-volume-control"
                  onMouseEnter={() => setShowVolumeBar(true)}
                  onMouseLeave={() => setShowVolumeBar(false)}
                  style={
                    {
                      // position: 'relative',
                    }
                  }
                >
                  <VolumeUpIcon />

                  <Slider
                    size="small"
                    defaultValue={70}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                  />
                </IconButton> */}
              </Grid>

              <Grid
                item
                xs={3}
                sx={{ height: '10%', display: 'flex', justifyContent: 'flex-end' }}
              >
                {/* 自定義全螢幕按鈕 */}
                <Tooltip title={scripts.fullScreen} placement="bottom">
                  <IconButton onClick={() => videoRef.current?.requestFullscreen()}>
                    <FullscreenIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  height: '15%',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Slider
                  ref={sliderRef}
                  min={0}
                  max={videoDuration}
                  defaultValue={0}
                  value={sliderValue}
                  size="small"
                  onChange={(event: Event, newValue: number | number[]) => {
                    if (typeof newValue === 'number') {
                      setSliderValue(newValue);
                      const linearProgressTypography =
                        linearProgressTypographyRef.current;
                      if (linearProgressTypography && typeof videoDuration === 'number') {
                        const currentTime = newValue;
                        linearProgressTypography.innerText = `${secondsToTime(
                          currentTime,
                        )}/${secondsToTime(videoDuration)}`;
                      }
                    }
                  }}
                  onChangeCommitted={(
                    event: Event | SyntheticEvent<Element, Event>,
                    newValue: number | number[],
                  ) => {
                    if (typeof newValue === 'number' && videoRef.current) {
                      videoRef.current.currentTime = newValue;
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  sx={{
                    padding: 0,
                    height: '4px',
                    '& .MuiSlider-thumb': {
                      width: '8px',
                      height: '8px',
                      '&.Mui-active': {
                        width: '20px',
                        height: '20px',
                      },
                    },
                    color: '#00A591',
                  }}
                />
                {showVideoControl &&
                  videoDuration &&
                  props.answer?.videoAns?.map((videoAns, index, array) => {
                    const isFirstItemWithSameTime =
                      array.findIndex((ele) => ele.time === videoAns.time) === index;

                    if (isFirstItemWithSameTime) {
                      return (
                        <div
                          key={videoAns.id}
                          onClick={() => {
                            setCurrentQuestionIndex(index);
                            setIsQuestionModal(true);
                            videoRef.current?.pause();
                          }}
                          style={{
                            position: 'absolute',
                            bottom: '50.5%',
                            left: `calc(${(videoAns.time / videoDuration) * 100}%)`,
                            transform: 'translate(-50%, 50%)',
                            backgroundColor: 'darkgoldenrod',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            zIndex: 1,
                          }}
                        />
                      );
                    } else {
                      null;
                    }
                  })}
              </Grid>
            </Grid>
          )}
        </div>
      </CenterBox>
    </WrapperRND>
  );
}
