//ref: TeacherSchedule.tsx;
import { useState, useEffect, useRef } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
//import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { default as Grid } from '@mui/material/Unstable_Grid2';
import { useParams } from 'react-router-dom';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';

import CustomAutocomplete from '../../../globalComponents/CustomAutocomplete';
import CustomText from '../../../globalComponents/CustomText';
import useDialog from '../../../globalHooks/useDialog';
import {
  LevelType,
  useClassroomWithStudentsIdNameLazyQuery,
  useClassroomGradeLazyQuery,
  useStudentGradeLazyQuery,
  useWeakSubskillsLazyQuery,
  useSkillRatingLazyQuery,
} from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';
import NoResultFoundSvg from '../../../layout/utils/NoResultFoundSvg';
import { updateBarIcon } from '../../../redux/features/classSlice';
import { useAppDispatch } from '../../../redux/store';
import AssignSkillPracticeHomeworkButton from '../components/AssignSkillPracticeHomeworkButton';
import ClassAllStudentsGrades from '../components/ClassAllStudentsGrades';
import ClassSideBar from '../components/ClassSideBar';
import DownloadClassGradeOfAllBooksButton from '../components/DownloadClassGradeOfAllBooksButton';
import DownloadClassGradeOfTheBookButton from '../components/DownloadClassGradeOfTheBookButton';
import SelectBook from '../components/SelectBook';
import SelectClassroom from '../components/SelectClassroom';
import SelectLevel from '../components/SelectLevel';

enum CheckGradesBtn {
  CLASS,
  STUDENT,
}

const TeacherCheckGrades = (): JSX.Element => {
  const scripts = useScripts();

  const { classId = '', studentId = '' } = useParams();
  const [currentBtn, setCurrentBtn] = useState(
    studentId === '' ? CheckGradesBtn.CLASS : CheckGradesBtn.STUDENT,
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(updateBarIcon({ open: true, type: 'CheckGrades' }));
  }, []);
  const [clickedClassId, setClickedClassId] = useState(classId || '');
  const [clickedLevelId, setClickedLevelId] = useState('');
  const [clickedBookId, setClickedBookId] = useState('');
  const [clickedLessonId, setClickedLessonId] = useState('');
  const [selectedStudentId, setSelectedStudentId] = useState(studentId);
  const [openSkillRating, setOpenSkillRating] = useState(false);
  const [clickedLevelName, setClickedLevelName] = useState('');
  const [clickedClassSchoolId, setClickedClassSchoolId] = useState('');

  const [
    fetchClassroomGrade,
    {
      data: classroomGradeData,
      //loading: classroomGradeLoading,
      //error: classroomGradeError,
    },
  ] = useClassroomGradeLazyQuery({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const [
    fetchStudentGrade,
    {
      data: studentGradeData,
      //loading: studentGradeLoading,
      //error: studentGradeError,
    },
  ] = useStudentGradeLazyQuery();

  const [
    fetchWeakSubskills,
    {
      data: WeakSubskillsData,
      //loading: WeakSubskillsLoading,
      //error: WeakSubskillsError,
    },
  ] = useWeakSubskillsLazyQuery();

  const [
    fetchSkillRating,
    {
      data: SkillRatingData,
      //loading: SkillRatingLoading,
      //error: SkillRatingError,
    },
  ] = useSkillRatingLazyQuery();

  const data = SkillRatingData?.skillRating?.map(({ averageScore, name }) => {
    return {
      subject: name,
      A: averageScore,
      fullMark: 100,
    };
  });

  useEffect(() => {
    if (clickedLevelId && clickedClassId) {
      fetchClassroomGrade({
        variables: {
          levelId: clickedLevelId,
          classroomId: clickedClassId,
        },
      });
    }
  }, [clickedClassId, clickedLevelId]);

  useEffect(() => {
    if (clickedLessonId && selectedStudentId) {
      fetchStudentGrade({
        variables: {
          lessonId: clickedLessonId,
          userId: selectedStudentId,
        },
      });
    }
  }, [clickedLessonId, selectedStudentId]);

  useEffect(() => {
    if (selectedStudentId) {
      fetchWeakSubskills({
        variables: {
          userId: selectedStudentId,
        },
      });
    }
  }, [selectedStudentId]);

  useEffect(() => {
    if (clickedLevelId && clickedBookId && selectedStudentId) {
      fetchSkillRating({
        variables: {
          levelId: clickedLevelId,
          bookId: clickedBookId,
          userId: selectedStudentId,
        },
      });
    }
  }, [clickedLevelId, clickedBookId, selectedStudentId]);

  const isScreenDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isScreenDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const contentWrapper = useRef<HTMLDivElement>(null);

  const isWidthDown370 = useMediaQuery('(max-width:370px)');

  const [
    fetchClassroomStudents,
    { data: classroomStudentsData, loading: classroomStudentsLoading },
  ] = useClassroomWithStudentsIdNameLazyQuery();

  const [clickedClassroomName, setClickedClassroomName] = useState('');

  useEffect(() => {
    if (classroomStudentsData?.classroom) {
      setClickedClassroomName(classroomStudentsData?.classroom?.name);
    }
  }, [classroomStudentsData]);

  useEffect(() => {
    if (clickedClassId) {
      fetchClassroomStudents({
        variables: {
          classroomId: clickedClassId,
        },
      });
    }
  }, [clickedClassId]);

  const { dialogProps } = useDialog({
    dialogTitle: scripts.selectClassroom,
    resetInput: () => {
      setSelectedStudentId('');
    },
  });

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          position: 'relative',
          width: '90%',
        }}
        fixed
        disableGutters={isWidthDown370}
      >
        <Grid container spacing={0} rowSpacing={2} margin={0}>
          <Grid xs={12}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              //justifyContent="space-between"
              alignItems="center"
              spacing={{ sm: 1 }}
            >
              <Grid xs={12} md={3}>
                <Typography variant="h4" sx={{ fontWeight: 600, fontFamily: 'Poppins' }}>
                  {scripts.checkGrades}
                </Typography>
              </Grid>
              <Grid xs={12} md={5}>
                <Stack
                  direction="row"
                  //justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      setCurrentBtn(CheckGradesBtn.CLASS);
                    }}
                    //disabled={classScheduleLoading}
                    sx={{
                      backgroundColor:
                        currentBtn === CheckGradesBtn.CLASS ? '#00A591' : '#FFF',
                      color: currentBtn === CheckGradesBtn.CLASS ? '#FFF' : '#00A591',
                      borderColor: '#00A591',
                      ':hover': {
                        backgroundColor: '#00A591',
                        color: '#FFF',
                        borderColor: '#00A591',
                      },
                      //maxHeight: '58px',
                      textTransform: 'none',
                      borderRadius: '24px',
                      fontSize: '16px',
                      fontWeight: 400,
                      fontFamily: 'Poppins',
                    }}
                  >
                    {scripts.classGrade}
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => setCurrentBtn(CheckGradesBtn.STUDENT)}
                    sx={{
                      backgroundColor:
                        currentBtn === CheckGradesBtn.STUDENT ? '#00A591' : '#FFF',
                      color: currentBtn === CheckGradesBtn.STUDENT ? '#FFF' : '#00A591',
                      borderColor: '#00A591',
                      ':hover': {
                        backgroundColor: '#00A591',
                        color: '#FFF',
                        borderColor: '#00A591',
                      },
                      //maxHeight: '59px',
                      textTransform: 'none',
                      borderRadius: '24px',
                      fontSize: '16px',
                      fontWeight: 400,
                      fontFamily: 'Poppins',
                    }}
                    // disabled={classScheduleLoading}
                  >
                    {scripts.studentGrade}
                  </Button>
                </Stack>
              </Grid>
              <Grid xs={12} md={4}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid xs={currentBtn === CheckGradesBtn.STUDENT ? 6 : 12}>
                    <SelectClassroom
                      classId={clickedClassId}
                      onChildSetClickedClassId={setClickedClassId}
                      //reset={setClickedLevelId}
                      //startQuery={assignToClass !== ''}
                      startQuery={true}
                      onChildSetClickedClassSchoolId={setClickedClassSchoolId}
                      //willSubmit={dialogProps.willSubmit}
                    />
                  </Grid>
                  {currentBtn === CheckGradesBtn.STUDENT ? (
                    <Grid
                      xs={6}
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <CustomAutocomplete
                        disabled={
                          !clickedClassId ||
                          classroomStudentsData?.classroom?.students?.length === 0
                        }
                        loading={classroomStudentsLoading}
                        options={
                          classroomStudentsData?.classroom?.students?.map(
                            ({ id, name }) => ({
                              label: name,
                              value: id,
                            }),
                          ) ?? []
                        }
                        value={
                          classroomStudentsData?.classroom?.students
                            ?.filter(({ id }) => id === selectedStudentId)
                            ?.map(({ id, name }) => ({
                              label: name,
                              value: id,
                            })) ?? []
                        }
                        onChangeForSingleValue={(e, option) => {
                          setSelectedStudentId(option?.value ?? '');
                        }}
                        dialogProps={dialogProps}
                        label={scripts.student}
                        fullWidth={true}
                      />
                    </Grid>
                  ) : null}
                </Stack>
              </Grid>
            </Stack>
          </Grid>
          <Divider
            sx={{
              width: '100%',
              color: '#B8BBB9',
              marginBottom: '18px',
            }}
          />
          {/* SelectLevel + RYG bar */}
          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            // rowSpacing={currentBtn === CheckGradesBtn.STUDENT ? 4.5 : 1.5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              //justifyContent: 'center',
            }}
          >
            {currentBtn === CheckGradesBtn.STUDENT ? (
              <Grid xs={12} md={7} container>
                <Grid xs={3} sx={centerStyle}>
                  <Button
                    variant={'outlined'}
                    sx={{
                      backgroundColor: openSkillRating ? '#00A591' : '#FFF',
                      color: openSkillRating ? '#FFF' : '#00A591',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: 0,
                      textTransform: 'none',
                      borderRadius: '7px',
                      borderColor: '#00A591',
                      '&:hover': {
                        borderColor: '#00A591',
                        background: '#00A591',
                        color: '#fff',
                      },
                    }}
                    onClick={() => {
                      setOpenSkillRating(!openSkillRating);
                    }}
                  >
                    {scripts.skillRating}
                  </Button>
                </Grid>
                <Grid xs={4.5} sx={centerStyle}>
                  <SelectLevel
                    classId={clickedClassId}
                    levelType={LevelType.Learn}
                    onChildSetClickedLevelId={setClickedLevelId}
                    reset={setClickedBookId}
                  />
                </Grid>
                <Grid xs={4.5} sx={centerStyle}>
                  <SelectBook
                    levelId={clickedLevelId}
                    onChildSetClickedBookId={setClickedBookId}
                    reset={setClickedLessonId}
                    classId={clickedClassId}
                    levelType={LevelType.Learn}
                    willSubmit={dialogProps.willSubmit}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid xs={9} sm={3}>
                  <SelectLevel
                    classId={clickedClassId}
                    levelType={LevelType.Learn}
                    onChildSetClickedLevelId={setClickedLevelId}
                    onChildSetClickedLevelName={setClickedLevelName}
                    //reset={setClickedBookId}
                    //currentLevel={classroomCourseCurrentLevel}
                    //currentProductId={classroomCourseCurrentProductId}
                    //willSubmit={dialogProps.willSubmit}
                  />
                </Grid>
                <Grid
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  {classroomGradeData?.classroomGrade && (
                    <DownloadClassGradeOfAllBooksButton
                      fileName={`${clickedLevelName}`}
                      classroomName={clickedClassroomName}
                      classData={classroomGradeData?.classroomGrade}
                      style={{
                        border: 'none',
                        cursor: 'pointer',
                        background: 'rgba(248, 248, 248, 0.4)',
                      }}
                    />
                  )}
                </Grid>
              </>
            )}
            {/* RYG bar */}
            <Grid
              sx={{
                flexGrow: 1,
                p: 0,
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <Box fontSize={12} fontWeight={400} fontFamily={'Poppins'}>
                <Grid container spacing={isScreenDownMd ? 0 : 1} margin={0}>
                  <Grid sx={{ ...centerStyle, marginRight: '8px' }}>
                    <CircleIcon sx={{ color: '#FF6663' }} />0 - 60
                  </Grid>
                  <Grid sx={{ ...centerStyle, marginRight: '8px' }}>
                    <CircleIcon sx={{ color: '#FEB95F' }} />
                    61 - 79
                  </Grid>
                  <Grid sx={{ ...centerStyle, marginRight: '8px' }}>
                    <CircleIcon sx={{ color: '#00A591' }} />
                    80 - 100
                  </Grid>
                  <Grid sx={centerStyle}>
                    <CircleIcon sx={{ color: '#B4B3B3' }} />
                    {scripts.notStartedYet}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {/* grade data */}
          {currentBtn === CheckGradesBtn.STUDENT ? (
            // student grade
            openSkillRating ? (
              // RadarChart ref:StudentHomework
              <Grid xs={12} container columnGap={1} rowGap={4} spacing={0}>
                <Grid
                  xs={12}
                  lg={7.9}
                  sx={{
                    backgroundColor: '#FFF',
                    borderRadius: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  {data && data?.length > 0 ? (
                    <Stack direction="row" justifyContent="center" alignItems="center">
                      <RadarChart
                        cx={isScreenDownSm ? 150 : 225}
                        cy={isScreenDownSm ? 100 : 200}
                        outerRadius="75%"
                        width={isScreenDownSm ? 300 : 450}
                        height={isScreenDownSm ? 200 : 400}
                        data={data}
                      >
                        <PolarGrid gridType="circle" />
                        <PolarAngleAxis
                          dataKey="subject"
                          tick={{
                            fill: '#000',
                            fontWeight: 700,
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                          }}
                        />
                        <PolarRadiusAxis
                          tick={{
                            fill: '#000',
                            fontWeight: 400,
                            fontSize: '12px',
                            fontFamily: 'Poppins',
                          }}
                          angle={90}
                        />
                        <Radar
                          dataKey="A"
                          stroke="#00A591"
                          fill="#00A591"
                          fillOpacity={0.3}
                        />
                      </RadarChart>
                    </Stack>
                  ) : (
                    <NoResultFoundSvg />
                  )}
                </Grid>

                <Grid
                  xs={12}
                  lg={4}
                  sx={{
                    backgroundColor: '#FFF',
                    borderRadius: '10px',
                  }}
                  px={3}
                >
                  <CustomText
                    sx={{
                      fontWeight: 700,
                      fontSize: '16px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '16px',
                    }}
                  >
                    {scripts.overallWeakSkills}
                  </CustomText>
                  <Divider
                    sx={{ width: '100%', color: '#B8BBB9', margin: '6px 0 17px' }}
                  />
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    height="450px"
                    overflow="scroll"
                  >
                    <Stack
                      display="flex"
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      flexWrap="wrap"
                    >
                      {WeakSubskillsData?.weakSubskills?.map(
                        ({ id, name, averageScore, skill }) => (
                          <Stack
                            key={id}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={0.5}
                            sx={{
                              backgroundColor: '#F8F8F8',
                              borderRadius: '10px',
                              margin: isScreenDownSm ? '3.5px' : '15px 25px',
                              width: isScreenDownSm ? '130px' : '150px',
                            }}
                            pt={2}
                          >
                            <Stack
                              justifyContent="center"
                              alignItems="center"
                              width="60px"
                              height="60px"
                              sx={{
                                backgroundColor:
                                  averageScore >= 80
                                    ? '#00A591'
                                    : averageScore > 60 && averageScore < 80
                                    ? '#FEB95F'
                                    : averageScore > 0 && averageScore <= 60
                                    ? '#FF6663'
                                    : '#B4B3B3',
                                borderRadius: '50%',
                                boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
                              }}
                            >
                              <Typography
                                sx={{
                                  color: 'white',
                                  fontSize: '15px',
                                  fontWeight: 700,
                                  fontFamily: 'Inter',
                                }}
                              >
                                {averageScore + '%'}
                              </Typography>
                            </Stack>
                            <CustomText
                              sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                textAlign: 'center',
                              }}
                            >
                              {name}
                            </CustomText>
                            <CustomText sx={{ fontSize: '12px', fontWeight: 500 }}>
                              {skill?.name}
                            </CustomText>
                            <AssignSkillPracticeHomeworkButton
                              skill={skill?.name}
                              subSkill={name}
                              subSkillIds={[id]}
                              classroomId={clickedClassId}
                              studentIds={[selectedStudentId]}
                              schoolId={clickedClassSchoolId}
                            />
                          </Stack>
                        ),
                      )}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            ) : (
              <Grid container xs={12} spacing={2.5}>
                <Grid
                  xs={12}
                  sm={3}
                  sx={{
                    display: 'flex',
                    textAlign: 'center',
                  }}
                >
                  <Card
                    sx={{
                      backgroundColor: '#FFF',
                      maxHeight: isScreenDownSm ? '400px' : '850px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-around',
                      overflowY:
                        classroomGradeData &&
                        classroomGradeData?.classroomGrade?.books?.filter(
                          ({ id }) => id === clickedBookId,
                        )?.[0]?.lessons?.length > 10
                          ? 'scroll'
                          : 'null',
                      padding: '10px',
                    }}
                  >
                    {clickedLevelId && clickedBookId && (
                      <div
                        style={{
                          display: isScreenDownSm ? 'flex' : 'auto',
                        }}
                      >
                        <div
                          ref={contentWrapper}
                          style={{
                            display: 'flex',
                            flexDirection: isScreenDownSm ? 'row' : 'column',
                          }}
                        >
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {classroomGradeData?.classroomGrade?.books
                              ?.filter(({ id }) => id === clickedBookId)?.[0]
                              ?.lessons?.map(({ id, name }) => {
                                return (
                                  <Grid
                                    xs={6}
                                    sm={12}
                                    key={id}
                                    sx={{
                                      paddingY: 2,
                                      width: '100%',
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      sx={{
                                        backgroundColor:
                                          id === clickedLessonId ? '#00A591' : '#FFF',
                                        color:
                                          id === clickedLessonId ? '#FFF' : '#00A591',
                                        minWidth: 'auto',
                                        margin: 'auto',
                                        padding:
                                          isScreenDownMd && !isScreenDownSm ? 0 : 1,
                                        borderRadius: '7px',
                                        borderColor: '#00A591',
                                        '&:hover': {
                                          borderColor: '#00A591',
                                          background: '#00A591',
                                          color: '#fff',
                                        },
                                        textTransform: 'none',
                                        width: '100%',
                                      }}
                                      onClick={() => {
                                        setClickedLessonId(id);
                                      }}
                                    >
                                      {name}
                                    </Button>
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </div>
                      </div>
                    )}
                  </Card>
                </Grid>
                <Grid xs={12} sm={9}>
                  <Card
                    sx={{
                      textAlign: 'center',
                      backgroundColor: '#FFF',
                      padding: '3%',
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      height: '550px', //window.innerHeight,
                      borderRadius: '7px',
                      overflowY: 'scroll',
                    }}
                  >
                    <Grid container xs={12} spacing={3}>
                      {clickedLessonId &&
                        studentGradeData?.studentGrade?.parts?.map(
                          ({ id, name, averageScore, progress }) => (
                            <Grid xs={6} sm={6} md={4} lg={2.4} key={id}>
                              <Card
                                variant="outlined"
                                sx={{
                                  ...cardStyle,
                                  backgroundColor: '#F8F8F8',
                                  boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
                                  borderRadius: '10px',
                                }}
                              >
                                <CardContent
                                  sx={{ paddingTop: '20px', paddingBottom: '20px' }}
                                >
                                  <Box sx={centerStyle}>
                                    <CustomText
                                      sx={{ fontWeight: 500, fontSize: '16px' }}
                                    >
                                      {name}
                                    </CustomText>
                                  </Box>
                                  <Divider sx={{ margin: '11px 0px 26px 0px' }} />
                                  <Tooltip key={id} title={averageScore} arrow>
                                    <CircleIcon
                                      sx={{
                                        color:
                                          averageScore >= 80
                                            ? '#00A591'
                                            : averageScore > 60 && averageScore < 80
                                            ? '#FEB95F'
                                            : progress !== 0 &&
                                              averageScore >= 0 &&
                                              averageScore <= 60
                                            ? '#FF6663'
                                            : '#B4B3B3',
                                        fontSize: 48,
                                        width: '100%',
                                      }}
                                    />
                                  </Tooltip>
                                </CardContent>
                              </Card>
                            </Grid>
                          ),
                        )}
                    </Grid>
                    {/* TODO: add seeStudentRecord function */}
                    {/* <Box
                      sx={{
                        width: '100%',
                        margin: '5%',
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: '#F8F8F8',
                          color: 'black',
                          borderRadius: '10px',
                          ':hover': {
                            backgroundColor: '#00A591',
                            color: '#FFF',
                          },
                        }}
                      >
                        <CustomText
                          sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            textTransform: 'none',
                          }}
                        >
                          {scripts.seeStudentRecord}
                        </CustomText>
                      </Button>
                    </Box> */}
                  </Card>
                </Grid>
              </Grid>
            )
          ) : (
            //class grade
            <Grid
              container
              sx={{
                width: '100%',
                display: 'flex',
                height: '100%',
              }}
              spacing={2}
            >
              {classroomGradeData?.classroomGrade?.books?.map(
                ({ id, name: bookName, lessons }) => (
                  <Grid xs={12} sm={6} md={4} lg={3} key={id}>
                    <Card
                      variant="outlined"
                      sx={{
                        textAlign: 'center',
                        backgroundColor: '#FFF',
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px',
                        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
                      }}
                    >
                      <CardContent>
                        <Box sx={centerStyle}>
                          <Grid sx={{ width: '20%' }}></Grid>
                          <CustomText sx={{ fontWeight: 500, fontSize: '16px' }}>
                            {bookName}
                          </CustomText>
                          &nbsp;&nbsp;
                          <DownloadClassGradeOfTheBookButton
                            fileName={`${clickedLevelName}, ${bookName}, `}
                            classroomName={clickedClassroomName}
                            lessons={lessons}
                            style={{
                              border: 'none',
                              cursor: 'pointer',
                              background: '#FFF',
                            }}
                            disabled={lessons.length === 0}
                          />
                        </Box>
                        <Divider sx={{ marginTop: '11px' }} />
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            margin: '8px 24px',
                            padding: 0,
                          }}
                        >
                          {lessons.length === 0 && <NoResultFoundSvg />}
                          {lessons?.map(
                            ({
                              id,
                              name,
                              progress,
                              averageScore,
                              parts,
                              studentGrades,
                            }) => (
                              <Box
                                key={id}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  backgroundColor: '#F8F8F8',
                                  margin: 1,
                                  padding: 0,
                                  borderRadius: '5px',
                                  width: '100%',
                                  flexDirection: 'column',
                                }}
                              >
                                <Grid>
                                  <ClassAllStudentsGrades
                                    bookName={bookName}
                                    lessonName={name}
                                    levelName={clickedLevelName}
                                    parts={parts}
                                    lessonId={id}
                                    classroomId={clickedClassId}
                                    onChildSetClickedLessonId={setClickedLessonId}
                                    clickedLessonId={clickedLessonId}
                                    studentGrades={studentGrades}
                                  />
                                </Grid>
                                <Grid sx={{ padding: 0 }}>
                                  <Typography
                                    sx={{
                                      textAlign: 'center',
                                      color:
                                        averageScore >= 80
                                          ? '#00A591'
                                          : averageScore > 60 && averageScore < 80
                                          ? '#FEB95F'
                                          : progress !== 0 &&
                                            averageScore >= 0 &&
                                            averageScore <= 60
                                          ? '#FF6663'
                                          : '#B4B3B3',
                                      fontWeight: 700,
                                      fontSize: '25px',
                                    }}
                                  >
                                    {averageScore}
                                  </Typography>
                                </Grid>
                              </Box>
                            ),
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ),
              )}

              {classroomGradeData?.classroomGrade?.tests?.map(
                ({ id, name: testName, averageScore }) => (
                  <Grid xs={12} sm={6} md={4} lg={3} key={id}>
                    <Card
                      variant="outlined"
                      sx={{
                        textAlign: 'center',
                        backgroundColor: '#FFF',
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px',
                        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
                      }}
                    >
                      <CardContent>
                        <Box sx={centerStyle}>
                          <CustomText sx={{ fontWeight: 500, fontSize: '16px' }}>
                            {testName}
                          </CustomText>
                          {/* TODO: add download file function */}
                          {/* <FileDownloadIcon fontSize="large" /> */}
                        </Box>
                        <Divider sx={{ marginTop: '16px' }} />
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            backgroundColor: '#F8F8F8',
                            margin: '16px 26px 20px',
                            borderRadius: '5px',
                          }}
                        >
                          <Grid xs={3}>
                            <Typography
                              sx={{
                                textAlign: 'center',
                                color:
                                  averageScore >= 0 && averageScore <= 60
                                    ? '#FF6663'
                                    : averageScore > 60 && averageScore < 80
                                    ? '#FEB95F'
                                    : averageScore >= 80
                                    ? '#00A591'
                                    : '#B4B3B3',
                                fontWeight: 'bold',
                                fontSize: 20,
                              }}
                            >
                              {averageScore}
                            </Typography>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ),
              )}
            </Grid>
          )}
        </Grid>
      </Container>
      {!isScreenDownSm && <ClassSideBar />}
    </>
  );
};

export default TeacherCheckGrades;

const centerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const cardStyle = {
  textAlign: 'center',
  backgroundColor: '#D9D9D9',
  width: '100%',
  maxHeight: '200px',
};
