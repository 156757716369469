import { useState } from 'react';

import IconType from './IconType';

const EditIcon = ({
  width = 24,
  height = 24,
  color = '#FFF',
  style,
  hoverEffect = false,
}: IconType) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  color = hoverEffect && hovered ? '#FFF' : color;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <path
        d="M12.695 6.07931L6.5375 12.5968C6.305 12.8443 6.08 13.3318 6.035 13.6693L5.7575 16.0993C5.66 16.9768 6.29 17.5768 7.16 17.4268L9.575 17.0143C9.9125 16.9543 10.385 16.7068 10.6175 16.4518L16.775 9.93431C17.84 8.80931 18.32 7.52681 16.6625 5.95931C15.0125 4.40681 13.76 4.95431 12.695 6.07931Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6675 7.16675C11.99 9.23675 13.67 10.8192 15.755 11.0292"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 19.8794H18.5"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
