import IconType from './IconType';

const LayerSendToBackIcon = ({
  width = 23,
  height = 26,
  color = '#000',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10.7589 0.913324L10.7595 0.91305C11.2289 0.69565 11.7711 0.69565 12.2405 0.91305L12.2411 0.913324L22.061 5.45088L22.0628 5.45171C22.1748 5.50309 22.25 5.61648 22.25 5.74944C22.25 5.8824 22.1748 5.99579 22.0628 6.04717L22.061 6.048L12.2411 10.5856L12.2405 10.5858C11.7711 10.8032 11.2289 10.8032 10.7595 10.5858L10.7589 10.5856L0.942065 6.04941C0.822338 5.99227 0.75 5.87419 0.75 5.74944C0.75 5.61918 0.826827 5.50236 0.937208 5.45171L0.93901 5.45088L10.7589 0.913324ZM22.0608 11.2013L22.0628 11.2023C22.1748 11.2537 22.25 11.367 22.25 11.5C22.25 11.633 22.1748 11.7463 22.0628 11.7977L22.061 11.7986L12.2411 16.3361L12.2405 16.3364C11.7711 16.5538 11.2289 16.5538 10.7595 16.3364L10.7589 16.3361L0.943787 11.8008C0.943215 11.8005 0.942645 11.8003 0.942076 11.8C0.822343 11.7428 0.75 11.6248 0.75 11.5C0.75 11.3697 0.826827 11.2529 0.937209 11.2023L0.93922 11.2013L2.49009 10.4841C2.82276 10.3303 3.20618 10.3302 3.53892 10.4839L9.52789 13.2501L9.52806 13.2502C10.7787 13.8275 12.2213 13.8275 13.4719 13.2502L13.4721 13.2501L19.4611 10.4839C19.7938 10.3302 20.1772 10.3303 20.5099 10.4841L22.0608 11.2013ZM13.1576 12.5692C12.1064 13.0544 10.8936 13.0544 9.84238 12.5692L20.8247 9.8034C20.2924 9.55726 19.679 9.55711 19.1466 9.80301L13.1576 12.5692Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M10.2929 25.7071C10.6834 26.0976 11.3166 26.0976 11.7071 25.7071L18.0711 19.3431C18.4616 18.9526 18.4616 18.3195 18.0711 17.9289C17.6805 17.5384 17.0474 17.5384 16.6569 17.9289L11 23.5858L5.34315 17.9289C4.95262 17.5384 4.31946 17.5384 3.92893 17.9289C3.53841 18.3195 3.53841 18.9526 3.92893 19.3431L10.2929 25.7071ZM10 5L10 25H12L12 5H10Z"
        fill={color}
      />
    </svg>
  );
};

export default LayerSendToBackIcon;
