import { useId } from 'react';

import { Check } from '@mui/icons-material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useScripts } from './LanguageHelper';
import MenuItem from './MenuItem';
import { isDarkModeVar } from '../../apollo/cacheVar';
import { useAuth } from '../../globalHooks/useAuth';
import usePopoverAnchorEl from '../../globalHooks/usePopoverAnchorEl';
import { updateWebMode } from '../../redux/features/webModeSlice';
import { useAppDispatch } from '../../redux/store';
import { MenuItemProps, ThemeMode } from '../components/MainMenu';

export interface ThemeModeMenuItemProps extends MenuItemProps {
  currentThemeMode: ThemeMode;
  setCurrentThemeMode: React.Dispatch<React.SetStateAction<ThemeMode>>;
}

const ThemeModeMenuItem = ({
  selected,
  isMobile,
  isMore,
  currentThemeMode,
  setCurrentThemeMode,
}: ThemeModeMenuItemProps) => {
  const scripts = useScripts();

  const dispatch = useAppDispatch();

  const { managerRole } = useAuth();

  const menuId = useId();

  const matches = useMediaQuery('(prefers-color-scheme: dark)');

  const { anchorEl, open, handleClick, handleClose } = usePopoverAnchorEl();

  const webMode = localStorage.getItem('theme');

  if (managerRole) {
    return (
      <MenuItem
        itemType="ThemeMode"
        initWidth={22}
        initHeight={23}
        selected={selected}
        isMobile={isMobile}
        isMore={isMore}
        currentThemeMode={currentThemeMode}
      />
    );
  }

  const handleSystemClick = () => {
    dispatch(updateWebMode('Auto'));
    isDarkModeVar(matches);
    setCurrentThemeMode(ThemeMode.SYSTEM);
  };
  const handleDarkClick = () => {
    dispatch(updateWebMode('Dark'));
    isDarkModeVar(true);
    setCurrentThemeMode(ThemeMode.DARK);
  };
  const handleLightClick = () => {
    dispatch(updateWebMode('Light'));
    isDarkModeVar(false);
    setCurrentThemeMode(ThemeMode.LIGHT);
  };

  if (isMobile && !managerRole) {
    return [
      <Divider key={`${menuId}-3`}>{scripts.mode}</Divider>,
      <MuiMenuItem
        selected={currentThemeMode === ThemeMode.SYSTEM}
        key={`${menuId}-0`}
        onClick={handleSystemClick}
      >
        <ListItemIcon>
          <SettingsBrightnessIcon />
        </ListItemIcon>
        {scripts.auto}
      </MuiMenuItem>,
      <MuiMenuItem
        selected={currentThemeMode === ThemeMode.DARK}
        key={`${menuId}-1`}
        onClick={handleDarkClick}
      >
        <ListItemIcon>
          <DarkModeIcon />
        </ListItemIcon>
        {scripts.darkMode}
      </MuiMenuItem>,
      <MuiMenuItem
        selected={currentThemeMode === ThemeMode.LIGHT}
        key={`${menuId}-2`}
        onClick={handleLightClick}
      >
        <ListItemIcon>
          <LightModeIcon />
        </ListItemIcon>
        {scripts.lightMode}
      </MuiMenuItem>,
    ] as unknown as JSX.Element;
  }

  return (
    <>
      <Tooltip title={scripts.mode}>
        <IconButton onClick={handleClick}>
          <MenuItem
            itemType="ThemeMode"
            initWidth={24}
            initHeight={24}
            selected={selected}
            isMobile={isMobile}
            isMore={isMore}
            currentThemeMode={currentThemeMode}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ zIndex: 10000 }}
      >
        {webMode === 'Auto' ? (
          <MuiMenuItem selected onClick={handleSystemClick}>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            {scripts.auto}
          </MuiMenuItem>
        ) : (
          <MuiMenuItem onClick={handleSystemClick}>
            <ListItemText inset>{scripts.auto}</ListItemText>
          </MuiMenuItem>
        )}

        {webMode === 'Dark' ? (
          <MuiMenuItem selected onClick={handleDarkClick}>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            {scripts.darkMode}
          </MuiMenuItem>
        ) : (
          <MuiMenuItem onClick={handleDarkClick}>
            <ListItemText inset>{scripts.darkMode}</ListItemText>
          </MuiMenuItem>
        )}

        {webMode === 'Light' ? (
          <MuiMenuItem selected onClick={handleLightClick}>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            {scripts.lightMode}
          </MuiMenuItem>
        ) : (
          <MuiMenuItem onClick={handleLightClick}>
            <ListItemText inset>{scripts.lightMode}</ListItemText>
          </MuiMenuItem>
        )}
      </Menu>
    </>
  );
};

export default ThemeModeMenuItem;
