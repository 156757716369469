import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { cloneDeep, defaultsDeep } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import {
  DraggableOuterProps,
  DraggableInnerProps,
  DraggableInitProps,
  DefaultDraggableProps,
} from './components/DraggableOrigin';
import TextFieldBase from './components/TextFieldBase';
import WrapperRND from './components/WrapperRND';
import CenterBox from '../../../../globalContainers/CenterBox';
import { useAuth } from '../../../../globalHooks/useAuth';
import { DraggableType, DraggableTag, Layout } from '../../../../graphql/resolver.types';
import { useScripts } from '../../../../layout/utils/LanguageHelper';
import { useAppSelector } from '../../../../redux/store';
import AddObjectIcon from '../../../../svg/AddObjectIcon';
import {
  getDefaultFontSizeNum,
  useFontEditorContext,
} from '../../context/FontEditorContext';
import { useStyle } from '../../context/StyleContext';

const landscapeProps: DraggableInitProps = {
  type: DraggableType.CustomText,
  tags: [DraggableTag.TextField],
  name: 'New Text',
  style: {
    // width: '26vmin',
    // height: '10vmin',
    color: '#000000',
    textAlign: 'left',
    alignItems: 'flex-start',
    overflow: 'hidden',
    fontSize: `${getDefaultFontSizeNum(Layout.Landscape)}`,
    fontFamily: 'Roboto',
    borderStyle: 'none',
    borderWidth: 0,
    borderRadius: 0,
    borderColor: '#000000',
  },
  lockAspectRatio: false,
};

export const defaultTextFieldProps: DefaultDraggableProps = {
  [Layout.Landscape]: {
    ...landscapeProps,
  },
  [Layout.Portrait]: {
    ...landscapeProps,
  },
};

export default function CustomText(props: DraggableOuterProps) {
  const scripts = useScripts();

  const { layout, droppableRef, draggables, draggablesPropsRef } = useStyle();
  const defaultTextField: DraggableInnerProps = layout
    ? defaultTextFieldProps[layout]
    : defaultTextFieldProps.landscape;

  const { isStudent } = useAuth();
  const [serial, setSerial] = useState(uuidv4());

  const dragProps: DraggableInnerProps = defaultsDeep(cloneDeep(props), defaultTextField);
  const stackRef = useRef<HTMLDivElement>(null);

  const { getTextSizePercent } = useFontEditorContext();

  const isLandscape = layout === Layout.Landscape;
  const counter = useAppSelector((state) => state.style.present.counter[layout]);

  const serialRef = useRef(0);

  useEffect(() => {
    serialRef.current = (counter[DraggableType.CustomText] ?? 0) + 1;

    const sizePercent = getTextSizePercent(serialRef.current, isLandscape);

    const resize = () => {
      const stack = stackRef.current;
      if (stack) {
        stack.style.width = `${
          (droppableRef?.current?.offsetWidth ?? 0) * sizePercent.width + 2
        }px`;
        stack.style.height = `${
          (droppableRef?.current?.offsetHeight ?? 0) * sizePercent.height
        }px`;
      }
    };
    resize();

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [dragProps, draggables]);

  useEffect(() => {
    if (isStudent) {
      setSerial(uuidv4());
    }
  }, []);

  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = cloneDeep({
        ...props,
        selectedBar: {
          ...dragProps?.selectedBar,
          getDeleteLabel: () => scripts.deleteThisText,
        },
      });
    }
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps} {...(isStudent ? { key: serial } : null)}>
      {dragProps.isClone ? (
        <TextFieldBase {...dragProps} multiline={true} />
      ) : (
        // default TextField of origin for better dragging UX.
        <CenterBox
          sx={{
            borderWidth: 0,
          }}
        >
          <Stack
            ref={stackRef}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            bgcolor="#d9d9d9"
            borderRadius="6px"
          >
            <AddObjectIcon width={24} height={24} color="#000" />
            <Typography
              variant="subtitle1"
              sx={{
                color: dragProps.style?.color,
              }}
            >
              {scripts.newText}
            </Typography>
          </Stack>
        </CenterBox>
      )}
    </WrapperRND>
  );
}
