import IconType from './IconType';

const PauseIcon = ({ width = 19, height = 19, color = '#073015', style }: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19Z"
        fill={color}
      />
      <path
        d="M7.05 5C6.47031 5 6 5.50391 6 6.125V12.875C6 13.4961 6.47031 14 7.05 14H7.75C8.32969 14 8.8 13.4961 8.8 12.875V6.125C8.8 5.50391 8.32969 5 7.75 5H7.05ZM11.25 5C10.6703 5 10.2 5.50391 10.2 6.125V12.875C10.2 13.4961 10.6703 14 11.25 14H11.95C12.5297 14 13 13.4961 13 12.875V6.125C13 5.50391 12.5297 5 11.95 5H11.25Z"
        fill="white"
      />
    </svg>
  );
};

export default PauseIcon;
