import { useEffect, useMemo, useId } from 'react';

import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography/Typography';

import CustomButton from '../../../globalComponents/CustomButton';
import { useCustomTableContext } from '../../../globalContexts/CustomTableContext';
import { useInfiniteScrollPage } from '../../../globalHooks';
import {
  useLeadLazyQuery,
  CallsDocument,
  CallForCallsFragment,
  EventRecordsDocument,
  EventForEventRecordsFragment,
  CallsQuery,
  EventRecordsQuery,
} from '../../../graphql/resolver.types';
import { useScripts } from '../../../layout/utils/LanguageHelper';
import NoResultFoundSvg from '../../../layout/utils/NoResultFoundSvg';

type LeadInfoTableProps = {
  columnInfoArr: { columnName: string; columnText: string; width: string }[];
  keyFieldName?: string;
  color?: string;
  data: { node: any }[];
  loading?: boolean;
  fetchMoreNext?: () => void;
  hasNextPage?: boolean;
  itemTotal?: number;
  isEvent?: boolean;
  isCustomerData?: boolean;
};

const LeadInfoTable = ({
  columnInfoArr,
  keyFieldName = 'id',
  color = 'rgba(224, 224, 224, 1)',
  data,
  loading = false,
  fetchMoreNext = () => undefined,
  hasNextPage = false,
  itemTotal = 0,
  isEvent = false,
  isCustomerData = false,
}: LeadInfoTableProps) => {
  const scripts = useScripts();

  const isScreenDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const tableId = useId();

  const getValue = (node: any, columnName: string) => {
    const columnNameArr = columnName.split('.');
    let value = node?.[columnNameArr?.[0] ?? ''] ?? '';

    if (columnNameArr.length > 1) {
      value = getValue(
        node?.[columnNameArr?.[0] ?? ''],
        columnNameArr.slice(1).join('.'),
      );
    }

    return value;
  };

  if (isScreenDownMd) {
    return (
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        width="100%"
        p={2}
        sx={{
          backgroundColor: '#fdfdfd',
          border: `1px solid ${color}`,
          borderRadius: '8px',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
          width="100%"
          {...(isCustomerData ? { mb: -1 } : null)}
        >
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            sx={{ color: color === 'rgba(224, 224, 224, 1)' ? '#000' : color }}
          >
            {columnInfoArr?.[0]?.columnText}
          </Typography>
          {!isCustomerData && (
            <Typography
              variant="subtitle2"
              fontWeight="bold"
              sx={{ color: color === 'rgba(224, 224, 224, 1)' ? '#000' : color }}
            >
              {columnInfoArr?.[columnInfoArr.length - 1]?.columnText}
            </Typography>
          )}
        </Stack>
        {data?.map(({ node }) => (
          <Stack
            key={`${node?.[keyFieldName] ?? ''}-mobile`}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0.5}
            width="100%"
            pb={1}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
              width="100%"
            >
              <Typography variant="body2" width={isEvent ? '70%' : 'auto'}>
                {getValue(node, columnInfoArr?.[0]?.columnName ?? '')}
                {isEvent && ` - ${getValue(node, columnInfoArr?.[1]?.columnName ?? '')}`}
              </Typography>
              {!isCustomerData && (
                <Typography variant="body2">
                  {getValue(node, columnInfoArr?.[isEvent ? 3 : 2]?.columnName ?? '')}
                </Typography>
              )}
            </Stack>
            {isCustomerData && (
              <>
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={0}
                  width="100%"
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    sx={{ color: color === 'rgba(224, 224, 224, 1)' ? '#000' : color }}
                  >
                    {columnInfoArr?.[1]?.columnText}
                  </Typography>
                  <Typography variant="body2">
                    {getValue(node, columnInfoArr?.[1]?.columnName ?? '')}
                  </Typography>
                </Stack>
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={0}
                  width="100%"
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    sx={{ color: color === 'rgba(224, 224, 224, 1)' ? '#000' : color }}
                  >
                    {columnInfoArr?.[2]?.columnText}
                  </Typography>
                  <Typography variant="body2">
                    {getValue(node, columnInfoArr?.[2]?.columnName ?? '')}
                  </Typography>
                </Stack>
              </>
            )}
            {!isCustomerData && (
              <Typography variant="body2">
                {getValue(node, columnInfoArr?.[isEvent ? 2 : 1]?.columnName ?? '')}
              </Typography>
            )}
          </Stack>
        ))}
        {hasNextPage && (
          <Button
            variant="contained"
            size="small"
            disabled={loading}
            onClick={() => fetchMoreNext()}
            sx={{ backgroundColor: '#00A591' }}
            // {...(color ? { sx: { backgroundColor: color } } : null)}
            fullWidth
          >
            {loading && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <CircularProgress size={20} sx={{ color: '#FFF' }} />
                <Typography variant="inherit" color="#FFF">
                  {scripts.loading}
                </Typography>
              </Stack>
            )}
            {!loading && (
              <Typography variant="inherit" color="#FFF">
                {`${scripts.loadMore} (${itemTotal - (data?.length ?? 0)})`}
              </Typography>
            )}
          </Button>
        )}
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={0}
      width="100%"
      p={2}
      sx={{
        backgroundColor: '#fdfdfd',
        border: `1px solid ${color}`,
        borderRadius: '8px',
      }}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={4}
        width="100%"
        pb={0.5}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
          width="100%"
          mb={-3}
        >
          {columnInfoArr.map(({ columnText, width }, index) => (
            <Stack
              key={`${tableId}-${index}`}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={0}
              width={width}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{ color: color === 'rgba(224, 224, 224, 1)' ? '#000' : color }}
              >
                {columnText}
              </Typography>
            </Stack>
          ))}
        </Stack>
        {data?.map(({ node }) => (
          <Stack
            key={node?.[keyFieldName] ?? ''}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            width="100%"
            sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
            pt={1.5}
          >
            {columnInfoArr.map(({ columnName, width }, index) => (
              <Stack
                key={`${node?.[keyFieldName] ?? ''}-${index}`}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                width={width}
              >
                <Typography variant="body2">{getValue(node, columnName)}</Typography>
              </Stack>
            ))}
          </Stack>
        ))}
        {!data?.length && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
            width="100%"
            pt={1.5}
          />
        )}
        {hasNextPage && (
          <CustomButton
            variant="contained"
            size="small"
            disabled={loading}
            onClick={() => fetchMoreNext()}
            sx={{ backgroundColor: '#00A591' }}
            // {...(color ? { sx: { backgroundColor: color } } : null)}
            fullWidth
          >
            {loading && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <CircularProgress size={20} sx={{ color: '#FFF' }} />
                <Typography variant="inherit" color="#00A591">
                  {scripts.loading}
                </Typography>
              </Stack>
            )}
            {!loading && (
              <Typography variant="inherit" color="#FFF">
                {`${scripts.loadMore} (${itemTotal - (data?.length ?? 0)})`}
              </Typography>
            )}
          </CustomButton>
        )}
      </Stack>
    </Stack>
  );
};

type LeadInfoProps = {
  leadId: string;
  index: number;
};

const LeadInfo = ({ leadId, index }: LeadInfoProps) => {
  const { isShowDetailInfo } = useCustomTableContext();

  const scripts = useScripts();

  const [fetchLead, { data: leadData, loading: leadLoading, error: leadError }] =
    useLeadLazyQuery();
  useEffect(() => {
    if (!leadData && !leadLoading && isShowDetailInfo?.current?.[index]) {
      fetchLead({
        variables: {
          leadId,
        },
      });
    }
  }, [isShowDetailInfo]);

  const extraQueryParametersForCalls = useMemo(() => {
    return { leadId };
  }, [leadId]);

  const {
    data: callsData,
    error: callsError,
    loading: callsLoading,
    firstFetchDatas: firstCallsFetchDatas,
    fetchMoreNext: callsFetchMore,
    fetchMoreLoading: callsFetchMoreLoading,
  } = useInfiniteScrollPage<CallForCallsFragment, CallsQuery>({
    increaseNum: 5,
    document: CallsDocument,
    fieldName: 'calls',
    extraQueryParameters: extraQueryParametersForCalls,
    disableScrollLoadMore: true,
    pauseQuery: !leadData,
  });

  const {
    data: eventsData,
    error: eventsError,
    loading: eventsLoading,
    firstFetchDatas: firstEventsFetchDatas,
    fetchMoreNext: eventsFetchMore,
    fetchMoreLoading: eventsFetchMoreLoading,
  } = useInfiniteScrollPage<EventForEventRecordsFragment, EventRecordsQuery>({
    increaseNum: 5,
    document: EventRecordsDocument,
    fieldName: 'eventRecords',
    extraQueryParameters: extraQueryParametersForCalls,
    disableScrollLoadMore: true,
    pauseQuery: !leadData,
  });

  useEffect(() => {
    if (leadData) {
      if (!callsData && !callsLoading) {
        firstCallsFetchDatas && firstCallsFetchDatas();
      }
      if (!eventsData && !eventsLoading) {
        firstEventsFetchDatas && firstEventsFetchDatas();
      }
    }
  }, [leadData]);

  if (!leadData && !leadLoading) {
    return <></>;
  }

  if (
    !leadData ||
    !callsData ||
    !eventsData ||
    leadLoading ||
    callsLoading ||
    eventsLoading ||
    leadError ||
    callsError ||
    eventsError
  ) {
    return (
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
        py={6}
      >
        {leadError || callsError || eventsError ? (
          <NoResultFoundSvg />
        ) : (
          <Typography variant="subtitle1" fontWeight="bold">
            {scripts.pleaseWait}
          </Typography>
        )}
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      width="100%"
      pb={2}
    >
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        justifyContent={{ xs: 'flex-start', lg: 'space-between' }}
        alignItems="flex-start"
        spacing={{ xs: 1, lg: 0 }}
        width="100%"
        p={2}
        sx={{
          backgroundColor: '#fdfdfd',
          border: '1px solid rgba(224, 224, 224, 1)',
          borderRadius: '8px',
        }}
      >
        <Stack
          direction={{ xs: 'row', lg: 'column' }}
          justifyContent={{ xs: 'space-between', lg: 'center' }}
          alignItems="flex-start"
          spacing={1}
          width={{ xs: '100%', lg: 'auto' }}
        >
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            width={{ xs: '50%', lg: 'auto' }}
          >
            {scripts.address}
          </Typography>
          <Typography variant="body2" width={{ xs: '50%', lg: 'auto' }}>
            {leadData?.lead.address}
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: 'row', lg: 'column' }}
          justifyContent={{ xs: 'space-between', lg: 'center' }}
          alignItems="flex-start"
          spacing={1}
          width={{ xs: '100%', lg: 'auto' }}
        >
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            width={{ xs: '50%', lg: 'auto' }}
          >
            {scripts.leadBy}
          </Typography>
          <Typography variant="body2" width={{ xs: '50%', lg: 'auto' }}>
            {leadData?.lead.leadBy}
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: 'row', lg: 'column' }}
          justifyContent={{ xs: 'space-between', lg: 'center' }}
          alignItems="flex-start"
          spacing={1}
          width={{ xs: '100%', lg: 'auto' }}
        >
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            width={{ xs: '50%', lg: 'auto' }}
          >
            {scripts.leadSource}
          </Typography>
          <Typography variant="body2" width={{ xs: '50%', lg: 'auto' }}>
            {leadData?.lead.source?.name}
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: 'row', lg: 'column' }}
          justifyContent={{ xs: 'space-between', lg: 'center' }}
          alignItems="flex-start"
          spacing={1}
          width={{ xs: '100%', lg: 'auto' }}
        >
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            width={{ xs: '50%', lg: 'auto' }}
          >
            {scripts.dateAdded}
          </Typography>
          <Typography variant="body2" width={{ xs: '50%', lg: 'auto' }}>
            {leadData?.lead.createdAt}
          </Typography>
        </Stack>
        <Stack
          direction={{ xs: 'row', lg: 'column' }}
          justifyContent={{ xs: 'space-between', lg: 'center' }}
          alignItems="flex-start"
          spacing={1}
          width={{ xs: '100%', lg: 'auto' }}
        >
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            width={{ xs: '50%', lg: 'auto' }}
          >
            {scripts.addedBy}
          </Typography>
          <Typography variant="body2" width={{ xs: '50%', lg: 'auto' }}>
            {leadData?.lead.creator?.name}
          </Typography>
        </Stack>
      </Stack>
      <LeadInfoTable
        columnInfoArr={[
          {
            columnName: 'callAt',
            columnText: `${scripts.calls} (${
              callsData?.calls?.pageInfo?.itemTotal ?? 0
            })`,
            width: '20.71%',
          },
          {
            columnName: 'notes',
            columnText: scripts.callNotes,
            width: '66.85%',
          },
          {
            columnName: 'callBy',
            columnText: scripts.callBy,
            width: '12.44%',
          },
        ]}
        data={callsData?.calls?.edges ?? []}
        loading={callsFetchMoreLoading || callsLoading}
        fetchMoreNext={callsFetchMore}
        hasNextPage={callsData?.calls?.pageInfo?.hasNextPage}
        itemTotal={callsData?.calls?.pageInfo?.itemTotal}
      />
      <LeadInfoTable
        columnInfoArr={[
          {
            columnName: 'event.startsAt',
            columnText: `${scripts.events} (${
              eventsData?.eventRecords?.pageInfo?.itemTotal ?? 0
            })`,
            width: '20.71%',
          },
          {
            columnName: 'event.name',
            columnText: scripts.eventName,
            width: '15.69%',
          },
          {
            columnName: 'notes',
            columnText: scripts.eventNotes,
            width: '51.16%',
          },
          {
            columnName: 'callBy',
            columnText: scripts.callBy,
            width: '12.44%',
          },
        ]}
        color="#0097D8"
        data={eventsData?.eventRecords?.edges ?? []}
        loading={eventsFetchMoreLoading || eventsLoading}
        fetchMoreNext={eventsFetchMore}
        hasNextPage={eventsData?.eventRecords?.pageInfo?.hasNextPage}
        itemTotal={eventsData?.eventRecords?.pageInfo?.itemTotal}
        isEvent={true}
      />
      {leadData?.lead.transitions.map((transition) => {
        if (transition.status === 'prospect') {
          return null;
        }

        const isCustomer = transition.status === 'customer';
        const isLostCustomer = transition.status === 'lost_customer';
        const color = isCustomer
          ? '#25BA00'
          : isLostCustomer
          ? '#FF0101'
          : 'rgba(224, 224, 224, 1)';

        return (
          <LeadInfoTable
            key={transition.id}
            columnInfoArr={[
              {
                columnName: 'title',
                columnText: isCustomer
                  ? scripts.dateBecameCustomer
                  : isLostCustomer
                  ? scripts.dateCustomersLost
                  : '',
                width: '20.71%',
              },
              {
                columnName: 'notes',
                columnText: scripts.notes,
                width: '66.85%',
              },
              {
                columnName: 'creator.name',
                columnText: scripts.by,
                width: '12.44%',
              },
            ]}
            color={color}
            data={[
              {
                node: {
                  title: transition.createdAt,
                  notes: transition.notes,
                  creator: {
                    name: transition.creator?.name ?? '',
                  },
                },
              },
            ]}
            isCustomerData={true}
          />
        );
      })}
    </Stack>
  );
};

export default LeadInfo;
