import { useLayoutEffect } from 'react';

import { cloneDeep, defaultsDeep } from 'lodash-es';

import { DraggableOuterProps, DraggableInnerProps } from './components/DraggableOrigin';
import WrapperRND from './components/WrapperRND';
import { DraggableType, DraggableTag } from '../../../../graphql/resolver.types';
import { useStyle } from '../../context/StyleContext';
import { getDraggables } from '../../utils/draggables';

export default function StyleTemplate(props: DraggableOuterProps) {
  const defaultStyleTemplate: DraggableInnerProps = {
    type: DraggableType.StyleTemplate,
    tags: [DraggableTag.Group],
    style: {
      width: '100%',
      height: '100%',
      left: '0%',
      top: '0%',
    },
    lockAspectRatio: true,
    group: props.draggables,
    originWrapperStyle: {
      width: '100%',
      height: '100%',
    },
    bounds: '',
  };

  const dragProps: DraggableInnerProps = defaultsDeep(
    cloneDeep(props),
    defaultStyleTemplate,
  );

  const { draggablesPropsRef } = useStyle();
  useLayoutEffect(() => {
    const id = props.id;
    if (draggablesPropsRef && id) {
      draggablesPropsRef.current[id] = props;
    }
  }, [dragProps]);

  // main
  return (
    <WrapperRND {...dragProps}>
      {dragProps.isClone && dragProps.group ? (
        getDraggables(dragProps.group)
      ) : (
        <img
          crossOrigin={props?.isCORS ? 'anonymous' : undefined}
          src={dragProps.image?.imageUrl}
          loading="lazy"
          draggable={false}
          style={{
            height: '100%',
            width: '100%',
            objectFit: dragProps.style?.objectFit ?? 'cover', // fill, contain, cover
          }}
        />
      )}
    </WrapperRND>
  );
}
