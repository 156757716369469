import React, {
  useState,
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';

interface TimeContextProps {
  timeLeft: number; // store the remaining time
  setTimeLeft: Dispatch<SetStateAction<number>>;
  timeSpent: number;
  resetTimeSpent: () => void;
}
const defaultValue: TimeContextProps = {
  timeLeft: 0,
  setTimeLeft: () => undefined,
  timeSpent: 0,
  resetTimeSpent: () => undefined,
};

// Create a context to hold the time value
const TimeContext = createContext<TimeContextProps>(defaultValue);

// Custom hook to provide and consume the time context
export const useTime = () => {
  return useContext(TimeContext);
};

interface TimeProviderProps {
  children: React.ReactNode;
  timeLimit?: number;
}
export const TimeCounterProvider = ({ children, timeLimit }: TimeProviderProps) => {
  const [timeSpent, setTimeSpent] = useState(0);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [timeLeft, setTimeLeft] = useState(timeLimit || 0);

  const intervalRef = useRef<number | null>(null);
  useEffect(() => {
    if (timeLimit) {
      setTimeLeft(timeLimit);
      setTimeSpent(0);

      intervalRef.current = window.setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 1) {
            setTimeSpent((prevTimeSpent) => prevTimeSpent + 1);
            return prevTimeLeft - 1;
          } else {
            clearInterval(intervalRef.current as number);
            intervalRef.current = null;
            setTimeSpent((prevTimeSpent) => prevTimeSpent + 1);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(intervalRef.current as number);
    }
  }, [timeLimit]);

  // stop timer and reset timeSpent
  const resetTimeSpent = () => {
    if (timerId !== null) {
      clearInterval(timerId);
      setTimerId(null);
    }
    setTimeSpent(0);
  };

  return (
    <TimeContext.Provider
      value={{
        timeLeft,
        setTimeLeft,
        timeSpent,
        resetTimeSpent,
      }}
    >
      {children}
    </TimeContext.Provider>
  );
};
