import {
  ContentBlock,
  DraftInlineStyleType,
  EditorState,
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftInlineStyleRange,
  convertFromRaw,
  convertToRaw,
  genKey,
} from 'draft-js';

import {
  DraggableTag,
  DraggableType,
  Layout,
} from '../../../../../graphql/resolver.types';
import {
  getFontSize,
  getFontWeight,
  useFontEditorContext,
} from '../../../context/FontEditorContext';
import { useStyle } from '../../../context/StyleContext';
import { DraggableInitProps } from '../../draggable/components/DraggableOrigin';
import { FontWeightType } from '../FontWeightOption2';

type EditorJSON = string;

export enum EditTextType {
  FontSize = 'fontSize',
  FontColor = 'fontColor',
  FontAlign = 'fontAlign',
  FontFamily = 'fontFamily',
  FontWeight = 'fontWeight',
}
export enum EditTextAlignBlock {
  Left = 'box-text-left',
  Center = 'box-text-center',
  Right = 'box-text-right',
}

export type CursorInfo = {
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  blocks: ContentBlock[];
  inlineStyleIndexes: {
    [key: string]: number;
  };
  firstAnchorKey: string;
  firstAnchorOffset: number;
  lastFocusKey: string;
  lastFocusOffset: number;
  actualAnchorKey: string;
  actualAnchorOffset: number;
  actualFocusKey: string;
  actualFocusOffset: number;
  currentAnchorKey: string;
  currentAnchorOffset: number;
  currentFocusKey: string;
  currentFocusOffset: number;
  raw: RawDraftContentState;
  isSelectedAll: boolean;
  canResetStyle: boolean;
  currentAnchorIndex: number;
  currentFocusIndex: number;
};

export const getRaw = (editorState: EditorState): RawDraftContentState =>
  convertToRaw(editorState.getCurrentContent());
export const getEditorState = (raw: RawDraftContentState): EditorState =>
  EditorState.createWithContent(convertFromRaw(raw));
export const getEditorStateFromJson = (json: string): EditorState =>
  EditorState.createWithContent(convertFromRaw(JSON.parse(json)));
export const getDefaultBlock = (
  layout: Layout,
  name: string,
  fontSize: number | string,
  blockKey?: string,
) => {
  return {
    key: blockKey ?? genKey(),
    text: name,
    type: EditTextAlignBlock.Left,
    depth: 0,
    inlineStyleRanges: [
      {
        offset: 0,
        length: name.length,
        style: getFontSize(layout, fontSize) as unknown as any,
      },
      {
        offset: 0,
        length: name.length,
        style: getFontWeight(FontWeightType.Regular) as unknown as any,
      },
    ],
    entityRanges: [],
    data: {},
  };
};
export const getInitEditorJson = (
  layout: Layout,
  name: string,
  fontSize: number | string,
  blockKey?: string,
) => {
  return JSON.stringify({
    blocks: blockKey
      ? [getDefaultBlock(layout, name, fontSize, blockKey)]
      : [getDefaultBlock(layout, name, fontSize)],
    entityMap: {},
  });
};
export const getInitText = (type: DraggableType, name = '', isSubDraggable = false) => {
  const isCustomText = type === DraggableType.CustomText;
  const isTextCircleAnswer = type === DraggableType.TextCircleAnswer;
  const isTextRectAnswer = type === DraggableType.TextRectAnswer;
  const isTextRectAnswer2 = type === DraggableType.TextRectAnswer2;

  return (
    (isTextCircleAnswer || isTextRectAnswer
      ? ''
      : isCustomText && !isSubDraggable
      ? ''
      : isTextRectAnswer2 && name === 'New'
      ? 'word'
      : null) ??
    name ??
    ''
  );
};
export const updateInitText = (
  draggableObj: DraggableInitProps,
  isSubDraggable: boolean,
) => {
  const style = draggableObj.style;
  if (draggableObj.tags.indexOf(DraggableTag.TextField) > -1 && style) {
    style.color = `{"blocks":[{"key":"${genKey()}","text":"${getInitText(
      draggableObj.type,
      draggableObj.name,
      isSubDraggable,
    )}","type":"box-text-left","depth":0,"inlineStyleRanges":[{"offset":0,"length":19,"style":"fontSize29px"},{"offset":0,"length":19,"style":"fontWeight400"}],"entityRanges":[],"data":{}}],"entityMap":{}}`;
  }

  const group = draggableObj.group;
  if (group && Array.isArray(group)) {
    group.forEach((draggable) => {
      updateInitText(draggable, true);
    });
  }
};
export const getFirstAndLastCursorInfo = (editorState: EditorState) => {
  const blocks = editorState.getCurrentContent().getBlocksAsArray();
  const firstBlock = blocks[0];
  const lastBlock = blocks[blocks.length - 1];

  const firstAnchorKey = firstBlock.getKey();
  const firstAnchorOffset = 0;
  const lastFocusKey = lastBlock.getKey();
  const lastFocusOffset = lastBlock.getText().length;

  return {
    firstAnchorKey,
    firstAnchorOffset,
    lastFocusKey,
    lastFocusOffset,
  };
};
export const isEditorJSONString = (str: string | null | undefined): str is EditorJSON => {
  return !!(str && str.indexOf('blocks') > -1 && str.indexOf('entityMap') > -1);
};
const getBlockRange = (editorState: EditorState, anchorKey: string, focusKey: string) => {
  if (anchorKey === focusKey) {
    return [anchorKey];
  }

  const blocks = editorState.getCurrentContent().getBlocksAsArray();
  const anchorKeyIndex = blocks.findIndex((block) => block.getKey() === anchorKey);
  const focusKeyIndex = blocks.findIndex((block) => block.getKey() === focusKey);

  if (anchorKeyIndex === -1 || focusKeyIndex === -1) {
    return [anchorKey];
  }

  const minIndex = Math.min(anchorKeyIndex, focusKeyIndex);
  const maxIndex = Math.max(anchorKeyIndex, focusKeyIndex);

  return blocks.flatMap((block, index) => {
    if (index >= minIndex && index <= maxIndex) {
      return block.getKey();
    }

    return [];
  });
};

const useUpdateFontStyle = (editTextType: EditTextType) => {
  const { layout, selectedId, isEditingText } = useStyle();
  const { editorRefs, cursorRefs, currentUpdateType, addUndo } = useFontEditorContext();

  const getCursorInfo = (): CursorInfo | null => {
    const editorState = editorRefs?.editorStateRef?.current;
    const setEditorState = editorRefs?.setEditorStateRef?.current;
    if (!editorState || !setEditorState) {
      return null;
    }

    const blocks = editorState.getCurrentContent().getBlocksAsArray();
    const inlineStyleIndexes = blocks.reduce<{ [key: string]: number }>(
      (prev, block, index) => {
        prev[block.getKey()] = index;
        return prev;
      },
      {},
    );

    const { firstAnchorKey, firstAnchorOffset, lastFocusKey, lastFocusOffset } =
      getFirstAndLastCursorInfo(editorState);

    const actualAnchorKey =
      cursorRefs?.anchorKeyRef.current ?? editorState.getSelection().getAnchorKey();
    const actualAnchorOffset =
      cursorRefs?.anchorOffsetRef.current ?? editorState.getSelection().getAnchorOffset();
    const actualFocusKey =
      cursorRefs?.focusKeyRef.current ?? editorState.getSelection().getFocusKey();
    const actualFocusOffset =
      cursorRefs?.focusOffsetRef.current ?? editorState.getSelection().getFocusOffset();

    let currentAnchorKey = actualAnchorKey;
    let currentAnchorOffset = actualAnchorOffset;
    let currentFocusKey = actualFocusKey;
    let currentFocusOffset = actualFocusOffset;
    if (
      inlineStyleIndexes[currentAnchorKey] > inlineStyleIndexes[currentFocusKey] ||
      (currentAnchorKey === currentFocusKey && currentAnchorOffset > currentFocusOffset)
    ) {
      currentAnchorKey = actualFocusKey;
      currentAnchorOffset = actualFocusOffset;
      currentFocusKey = actualAnchorKey;
      currentFocusOffset = actualAnchorOffset;
    }

    const raw = getRaw(editorState);

    const isSelectedAll =
      !isEditingText ||
      (firstAnchorKey === currentAnchorKey &&
        firstAnchorOffset === currentAnchorOffset &&
        lastFocusKey === currentFocusKey &&
        lastFocusOffset === currentFocusOffset);

    const canResetStyle =
      isSelectedAll &&
      (raw?.blocks?.findIndex(
        ({ inlineStyleRanges }) =>
          !!inlineStyleRanges.find(({ style }) => style.indexOf(editTextType) !== 0),
      ) ?? -1) === -1;

    const currentAnchorIndex = inlineStyleIndexes[currentAnchorKey];
    const currentFocusIndex = inlineStyleIndexes[currentFocusKey];

    return {
      editorState,
      setEditorState,
      blocks,
      inlineStyleIndexes,
      firstAnchorKey,
      firstAnchorOffset,
      lastFocusKey,
      lastFocusOffset,
      actualAnchorKey,
      actualAnchorOffset,
      actualFocusKey,
      actualFocusOffset,
      currentAnchorKey,
      currentAnchorOffset,
      currentFocusKey,
      currentFocusOffset,
      raw,
      isSelectedAll,
      canResetStyle,
      currentAnchorIndex,
      currentFocusIndex,
    };
  };

  const isNeedToRemoveStyle = (
    preCheckNeedToRemoveStyle: boolean,
    cursorInfo: CursorInfo,
    block: RawDraftContentBlock,
    inlineStyle: RawDraftInlineStyleRange,
  ) => {
    if (preCheckNeedToRemoveStyle) {
      return true;
    }

    const {
      inlineStyleIndexes,
      currentAnchorKey,
      currentAnchorOffset,
      currentFocusKey,
      currentFocusOffset,
      currentAnchorIndex,
      currentFocusIndex,
    } = cursorInfo;

    const blockIndex = inlineStyleIndexes[block.key];
    const startOffset = inlineStyle.offset;
    const length = inlineStyle.length;
    const endOffset = startOffset + length;

    const sameBlockAsAnchor = currentAnchorKey === block.key;
    const sameBlockAsFocus = currentFocusKey === block.key;

    if (
      /*
        -------
        *******
        -------
      */
      blockIndex > currentAnchorIndex &&
      blockIndex < currentFocusIndex
    ) {
      return true;
    }
    if (
      // ---***---
      sameBlockAsAnchor &&
      sameBlockAsFocus &&
      startOffset >= currentAnchorOffset &&
      endOffset <= currentFocusOffset
    ) {
      return true;
    }
    if (
      /*
        ----***
        *****--
        -------
      */
      sameBlockAsAnchor &&
      currentAnchorOffset <= startOffset &&
      blockIndex < currentFocusIndex
    ) {
      return true;
    }
    if (
      /*
        ----***
        *****--
        -------
      */
      sameBlockAsFocus &&
      currentFocusOffset >= endOffset &&
      blockIndex > currentAnchorIndex
    ) {
      return true;
    }

    return false;
  };

  const updateFontStyle = (newStyle: string, updateType: EditTextType) => {
    const emptyArr: unknown[] = [];
    const cursorInfo = getCursorInfo();
    if (!selectedId || !cursorInfo) {
      return;
    }

    const {
      editorState,
      setEditorState,
      blocks,
      inlineStyleIndexes,
      currentAnchorKey,
      currentAnchorOffset,
      currentFocusKey,
      currentFocusOffset,
      raw,
      isSelectedAll,
      canResetStyle,
      currentAnchorIndex,
      currentFocusIndex,
    } = cursorInfo;

    if (
      isEditingText &&
      currentAnchorKey === currentFocusKey &&
      currentAnchorOffset === currentFocusOffset
    ) {
      return;
    }

    addUndo(editorState);

    const style = newStyle as unknown as any;

    if (canResetStyle) {
      raw.blocks = raw.blocks.map((block) => ({
        ...block,
        inlineStyleRanges: [
          {
            offset: 0,
            length: block.text.length,
            style,
          },
        ],
      }));
    } else {
      const inlineStyleRangesList: { [key: string]: RawDraftInlineStyleRange[] } = {};

      raw.blocks = raw.blocks.map((block) => {
        const inlineStyleRanges = block.inlineStyleRanges.flatMap((inlineStyle) => {
          const blockIndex = inlineStyleIndexes[block.key];
          const startOffset = inlineStyle.offset;
          const length = inlineStyle.length;
          const endOffset = startOffset + length;

          const sameBlockAsAnchor = currentAnchorKey === block.key;
          const sameBlockAsFocus = currentFocusKey === block.key;

          const inlineStyleSeparate: RawDraftInlineStyleRange[] = [];
          const preCheckNeedToRemoveStyle =
            isSelectedAll && inlineStyle.style.indexOf(editTextType) === 0;

          if (preCheckNeedToRemoveStyle) {
            return emptyArr as RawDraftInlineStyleRange[];
          } else if (
            inlineStyle.style.indexOf(editTextType) !== 0 ||
            (sameBlockAsAnchor && endOffset <= currentAnchorOffset) ||
            (sameBlockAsFocus && startOffset >= currentFocusOffset) ||
            blockIndex > currentFocusIndex ||
            blockIndex < currentAnchorIndex
          ) {
            return inlineStyle;
          } else if (
            isNeedToRemoveStyle(preCheckNeedToRemoveStyle, cursorInfo, block, inlineStyle)
          ) {
            return emptyArr as RawDraftInlineStyleRange[];
          } else {
            if (
              sameBlockAsAnchor &&
              endOffset > currentAnchorOffset &&
              startOffset < currentAnchorOffset
            ) {
              inlineStyleSeparate.push({
                offset: startOffset,
                length:
                  length -
                  Math.abs(endOffset - (sameBlockAsAnchor ? currentAnchorOffset : 0)),
                style: inlineStyle.style,
              });
            }
            if (
              sameBlockAsFocus &&
              startOffset < currentFocusOffset &&
              endOffset > currentFocusOffset
            ) {
              inlineStyleSeparate.push({
                offset: currentFocusOffset,
                length:
                  length -
                  Math.abs(startOffset - (sameBlockAsFocus ? currentFocusOffset : 0)),
                style: inlineStyle.style,
              });
            }
          }

          return inlineStyleSeparate;
        });

        inlineStyleRangesList[block.key] = inlineStyleRanges;

        return {
          ...block,
          inlineStyleRanges,
        };
      });

      Object.entries(inlineStyleIndexes).forEach(([blockKey, blockIndex]) => {
        const sameBlockAsAnchor = currentAnchorKey === blockKey;
        const sameBlockAsFocus = currentFocusKey === blockKey;
        if (
          (blockIndex > currentAnchorIndex && blockIndex < currentFocusIndex) ||
          !isEditingText
        ) {
          inlineStyleRangesList[blockKey].push({
            offset: 0,
            length: blocks[blockIndex].getText().length,
            style,
          });
        } else if (sameBlockAsAnchor && sameBlockAsFocus) {
          inlineStyleRangesList[blockKey].push({
            offset: currentAnchorOffset,
            length: Math.abs(currentFocusOffset - currentAnchorOffset),
            style,
          });
        } else if (sameBlockAsAnchor) {
          inlineStyleRangesList[blockKey].push({
            offset: currentAnchorOffset,
            length: Math.abs(blocks[blockIndex].getText().length - currentAnchorOffset),
            style,
          });
        } else if (sameBlockAsFocus) {
          inlineStyleRangesList[blockKey].push({
            offset: 0,
            length: currentFocusOffset,
            style,
          });
        }
      });
    }

    let newEditorState = EditorState.createWithContent(convertFromRaw(raw));
    if (isEditingText) {
      newEditorState = EditorState.forceSelection(
        newEditorState,
        newEditorState.getSelection().merge({
          anchorKey: currentAnchorKey,
          anchorOffset: currentAnchorOffset,
          focusKey: currentFocusKey,
          focusOffset: currentFocusOffset,
        }),
      );
    }

    currentUpdateType(updateType);
    if (updateType === EditTextType.FontColor) {
      setEditorState(newEditorState);
    } else {
      setTimeout(() => {
        setEditorState(newEditorState);
      }, 100);
    }
  };

  const updateContentBlockAlign = (align: EditTextAlignBlock) => {
    const cursorInfo = getCursorInfo();
    if (!selectedId || !cursorInfo) {
      return;
    }

    const {
      editorState,
      setEditorState,
      blocks,
      inlineStyleIndexes,
      currentAnchorKey,
      currentAnchorOffset,
      currentFocusKey,
      currentFocusOffset,
      raw,
    } = cursorInfo;

    addUndo(editorState);

    raw.blocks = raw.blocks.map((block, index) => {
      const blockKey = blocks[index].getKey();

      return {
        ...block,
        type:
          !isEditingText ||
          (inlineStyleIndexes[currentAnchorKey] <= inlineStyleIndexes[blockKey] &&
            inlineStyleIndexes[currentFocusKey] >= inlineStyleIndexes[blockKey])
            ? align
            : block.type,
      };
    });

    let newEditorState = EditorState.createWithContent(convertFromRaw(raw));
    if (isEditingText) {
      newEditorState = EditorState.forceSelection(
        newEditorState,
        newEditorState.getSelection().merge({
          anchorKey: currentAnchorKey,
          anchorOffset: currentAnchorOffset,
          focusKey: currentFocusKey,
          focusOffset: currentFocusOffset,
        }),
      );
    }

    setEditorState(newEditorState);
  };

  const updateFontSize = (fontSizeNum: number | null, isAdd = true) => {
    const cursorInfo = getCursorInfo();
    if (!selectedId || !cursorInfo) {
      return;
    }

    const {
      editorState,
      setEditorState,
      currentAnchorKey,
      currentFocusKey,
      firstAnchorKey,
      lastFocusKey,
      raw,
    } = cursorInfo;

    if (typeof fontSizeNum === 'number') {
      const blockRange = getBlockRange(
        editorState,
        !isEditingText ? firstAnchorKey : currentAnchorKey,
        !isEditingText ? lastFocusKey : currentFocusKey,
      );
      raw.blocks = raw.blocks.map((block) => {
        block.inlineStyleRanges =
          blockRange.indexOf(block.key) === -1
            ? block.inlineStyleRanges
            : [
                ...block.inlineStyleRanges.flatMap((inlineStyleRange) =>
                  inlineStyleRange.style.indexOf(EditTextType.FontSize) > -1
                    ? []
                    : inlineStyleRange,
                ),
                {
                  offset: 0,
                  length: block.text.length,
                  style: getFontSize(layout, fontSizeNum) as DraftInlineStyleType,
                },
              ];

        return block;
      });
    } else {
      raw.blocks = raw.blocks.map((block) => {
        block.inlineStyleRanges = block.inlineStyleRanges.map((inlineStyleRange) => {
          if (inlineStyleRange.style.indexOf(EditTextType.FontSize) > -1) {
            inlineStyleRange.style = getFontSize(
              layout,
              Number.parseFloat(
                inlineStyleRange.style.slice(EditTextType.FontSize.length),
              ) + (isAdd ? 1 : -1),
            ) as DraftInlineStyleType;
          }

          return inlineStyleRange;
        });

        return block;
      });
    }

    let newEditorState = EditorState.createWithContent(convertFromRaw(raw));
    if (isEditingText) {
      const selection = editorState.getSelection();
      newEditorState = EditorState.forceSelection(
        newEditorState,
        selection.merge({
          anchorKey: selection.getAnchorKey(),
          anchorOffset: selection.getAnchorOffset(),
          focusKey: selection.getFocusKey(),
          focusOffset: selection.getFocusOffset(),
          hasFocus: selection.getHasFocus(),
        }),
      );
    }

    setEditorState(newEditorState);
  };

  const forceSelection = (editorState: EditorState) => {
    const cursorInfo = getCursorInfo();
    if (!cursorInfo) {
      return;
    }

    const {
      setEditorState,
      actualAnchorKey,
      actualAnchorOffset,
      actualFocusKey,
      actualFocusOffset,
    } = cursorInfo;

    const newEditorState = EditorState.forceSelection(
      editorState,
      editorState.getSelection().merge({
        anchorKey: actualAnchorKey,
        anchorOffset: actualAnchorOffset,
        focusKey: actualFocusKey,
        focusOffset: actualFocusOffset,
      }),
    );

    setEditorState(newEditorState);
  };

  return {
    updateFontStyle,
    updateContentBlockAlign,
    forceSelection,
    updateFontSize,
  };
};

export default useUpdateFontStyle;
