import { forwardRef } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';

type CustomInputTextProps = Omit<TextFieldProps, 'ref'>;

interface CustomInputTextPropsWithNewField extends CustomInputTextProps {
  borderline?: string;
}

const CustomInputText = forwardRef<HTMLInputElement, CustomInputTextPropsWithNewField>(
  (props, ref) => {
    const propsCopy = {
      ...props,
      sx: {
        //阻止自動填寫表單樣式
        '& input:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 1000px #fff inset !important',
        },
        '& label.Mui-focused': {
          color: '#00A591',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor:
              props?.borderline === 'false' ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.23)',
          },
          '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#00A591',
          },
          '& .MuiAutocomplete-clearIndicator': {
            color: '#00A591',
          },
          '& .MuiAutocomplete-tag svg': {
            color: 'rgba(0, 0, 0, 0.3)',
            ':hover': {
              color: '#00A591',
            },
          },
        },
        '& .MuiFilledInput-underline': {
          '&:before': {
            borderBottomColor: '#00A591',
          },
          '&:after': {
            borderBottomColor: '#00A591',
          },
        },
        ...props.sx,
      },
    };

    return <TextField {...propsCopy} ref={ref} />;
  },
);
CustomInputText.displayName = 'CustomInputText';

export default CustomInputText;
