import { useState } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import { Divider, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import CustomText from '../../../globalComponents/CustomText';
import { useScripts } from '../../../layout/utils/LanguageHelper';

type ClassAllStudentsGradesProps = {
  bookName: string;
  lessonName: string;
  levelName: string;
  parts: {
    __typename?: 'Part' | undefined;
    id: string;
    name: string;
  }[];
  lessonId: string;
  classroomId: string;
  onChildSetClickedLessonId: (arg: string) => void;
  clickedLessonId: string;
  studentGrades: {
    id: string;
    name: string;
    parts: {
      id: string;
      name: string;
      averageScore: number;
      progress: number;
    }[];
  }[];
};
const ClassAllStudentsGrades = ({
  bookName,
  lessonName,
  levelName,
  parts,
  lessonId,
  onChildSetClickedLessonId,
  studentGrades,
}: ClassAllStudentsGradesProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  //const isScreenDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isScreenDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const scripts = useScripts();

  const handleDialogClose = () => {
    // if (schoolAccessControlLoading || schoolLoading) {
    //   return;
    // }

    setDialogOpen(false);
  };

  return (
    <>
      <Button
        size="small"
        onClick={() => {
          onChildSetClickedLessonId(lessonId);
          setDialogOpen(true);
        }}
        sx={{
          color: '#6B6B6B',
          fontSize: '12px',
          fontWeight: 500,
          fontFamily: 'Poppins',
          '&:hover': {
            borderColor: '#00A591',
            background: '#00A591',
            color: '#fff',
          },
        }}
      >
        {lessonName}
      </Button>
      {dialogOpen && (
        <Dialog
          maxWidth="xl"
          fullWidth={true}
          open={dialogOpen}
          onClose={handleDialogClose}
          //sx={{ zIndex: 10001, margin: 0 }}
        >
          <DialogTitle sx={{ zIndex: 10001, margin: '16px 27px 0' }}>
            <Stack justifyContent="center" alignItems="center" mb={0}>
              <CustomText sx={{ fontWeight: 500, fontSize: '20px' }}>
                {levelName ? levelName + ',' : ''} {bookName ? bookName + ',' : ''}{' '}
                {lessonName}
              </CustomText>
            </Stack>
          </DialogTitle>

          <Grid
            sx={{
              p: 0,
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '11px',
            }}
          >
            <Box fontSize={14} fontWeight={400} fontFamily={'Poppins'}>
              <Grid container spacing={0} margin={0}>
                <Grid
                  sx={{
                    ...centerStyle,
                    marginRight: isScreenDownSm ? 0 : '8px',
                  }}
                >
                  <CircleIcon sx={{ color: '#FF6663' }} />0 - 60
                </Grid>
                <Grid
                  sx={{
                    ...centerStyle,
                    marginRight: isScreenDownSm ? 0 : '8px',
                  }}
                >
                  <CircleIcon sx={{ color: '#FEB95F' }} />
                  61 - 79
                </Grid>
                <Grid
                  sx={{
                    ...centerStyle,
                    marginRight: isScreenDownSm ? 0 : '8px',
                  }}
                >
                  <CircleIcon sx={{ color: '#00A591' }} />
                  80 - 100
                </Grid>
                <Grid sx={centerStyle}>
                  <CircleIcon sx={{ color: '#B4B3B3' }} />
                  {scripts.notStartedYet}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Divider
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0 26px',
            }}
          />
          <br />

          <Table
            sx={{
              backgroundColor: '#D9D9D9',
              borderRadius: '10px 10px 0 0',
              //margin: '0 26px 6px 28px',
              width: 'auto',
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  width={`${100 / (parts?.length + 1)}%`}
                  sx={{
                    fontSize: '12px',
                    fontWeight: 700,
                    fontFamily: 'Martel Sans',
                    p: 0.5,
                  }}
                >
                  {scripts.name}
                </TableCell>
                {parts.map(({ id, name }) => (
                  <TableCell
                    align="center"
                    key={id}
                    width={`${100 / (parts?.length + 1)}%`}
                    sx={{
                      fontSize: '12px',
                      fontWeight: 700,
                      fontFamily: 'Martel Sans',
                      p: 0.5,
                    }}
                  >
                    {name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>

          <DialogContent
            sx={{
              position: 'relative',
              padding: 0,
              //margin: '0 26px',
              width: 'auto',
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  {studentGrades?.map(({ id, name, parts }) => (
                    <TableRow
                      hover
                      key={id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={{
                          width: `${100 / (parts?.length + 1)}%`,
                          fontSize: '12px',
                          fontWeight: 400,
                          fontFamily: 'Martel Sans',
                          p: 0.5,
                        }}
                      >
                        {name}
                      </TableCell>
                      {parts?.map(({ id, averageScore, progress }) => (
                        <TableCell align="center" key={id} sx={{ p: 0.5 }}>
                          <Tooltip
                            key={id}
                            title={`Average Score: ${averageScore}`}
                            arrow
                          >
                            {handleConvertColor(averageScore, progress)}
                          </Tooltip>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <Stack justifyContent="center" alignItems="center">
            <Button
              variant="outlined"
              onClick={handleDialogClose}
              sx={{
                backgroundColor: '#FFF',
                color: '#00A591',
                borderColor: '#00A591',
                borderRadius: '10px',
                fontSize: '14px',
                fontWeight: 400,
                textTransform: 'none',
                '&:hover': {
                  borderColor: '#00A591',
                  background: '#00A591',
                  color: '#fff',
                },
                marginBottom: '29px',
                marginTop: '41px',
              }}
            >
              {scripts.close}
            </Button>
          </Stack>
        </Dialog>
      )}
    </>
  );
};

export default ClassAllStudentsGrades;

const centerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const handleConvertColor = (score: number, progress: number) => {
  switch (true) {
    case progress == 0:
      return <CircleIcon sx={{ color: '#B4B3B3' }} />;
    case score >= 0 && score <= 60:
      return <CircleIcon sx={{ color: '#FF6663' }} />;
    case score > 60 && score < 80:
      return <CircleIcon sx={{ color: '#FEB95F' }} />;
    case score >= 80:
      return <CircleIcon sx={{ color: '#00A591' }} />;
    default:
      return <CircleIcon sx={{ color: '#B4B3B3' }} />;
  }
};
