import IconType from './IconType';

const KeyboardArrowDownIcon = ({
  width = 10,
  height = 6,
  color = '#073015',
  style,
  ...props
}: IconType) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M9.09326 1L5.04659 5.04667L0.999929 1"
        stroke={color}
        strokeOpacity="0.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default KeyboardArrowDownIcon;
