import IconType from './IconType';

const CheckGradesIcon = ({
  width = 15,
  height = 15,
  color = '#090446',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M13.7454 1.78983H11.1152V0.354622C11.1152 0.158567 10.9541 0 10.7549 0H4.05309C3.85386 0 3.69273 0.158567 3.69273 0.354622V1.78983H1.25562C0.563201 1.78983 0 2.34405 0 3.02543V11.5794C0 12.2608 0.563201 12.815 1.25562 12.815H5.50125V14.2908H4.05309C3.85386 14.2908 3.69273 14.4493 3.69273 14.6454C3.69273 14.8414 3.85386 15 4.05309 15H10.9469C11.1461 15 11.3073 14.8414 11.3073 14.6454C11.3073 14.4493 11.1461 14.2908 10.9469 14.2908H9.49875V12.815H13.7444C14.4368 12.815 15 12.2608 15 11.5794V3.02543C15 2.34405 14.4368 1.78983 13.7444 1.78983H13.7454ZM4.41346 0.709244H10.3945V3.66426L10.3415 7.7956H4.41346V0.709244ZM1.25562 2.49907H3.69221V8.15022C3.69221 8.34628 3.85335 8.50485 4.05258 8.50485H10.6967C10.8939 8.50485 11.0545 8.34881 11.0571 8.15478L11.1147 3.67084C11.1147 3.67084 11.1147 3.6678 11.1147 3.66628V2.49907H13.7449C14.0399 2.49907 14.2798 2.73515 14.2798 3.02543V9.77794H0.720733V3.02543C0.720733 2.73515 0.960634 2.49907 1.25562 2.49907ZM8.77853 14.2913H6.2225V12.8155H8.77853V14.2913ZM13.7449 12.1063H1.25562C0.960634 12.1063 0.720733 11.8702 0.720733 11.5799V10.4872H14.2803V11.5799C14.2803 11.8702 14.0404 12.1063 13.7454 12.1063H13.7449Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckGradesIcon;
