import React, { CSSProperties } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

import CustomButton from '../../../globalComponents/CustomButton';
import { useScripts } from '../../../layout/utils/LanguageHelper';
import CancelIcon from '../../../svg/CancelIcon';
import CheckCircleIcon from '../../../svg/CheckCircleIcon';
import FinalScoreIcon from '../../../svg/FinalScoreIcon';

type ConfirmDialogProps = {
  open: boolean;
  onClose?: () => void;
  onSubmit: () => void;
  title: string;
  titleStyle?: CSSProperties;
  correct: number;
  wrong: number;
  score: number;
};

export default function StudentScoreBoard({
  open,
  onClose,
  onSubmit,
  title,
  titleStyle = { color: '#ff0000' },
  correct,
  wrong,
  score,
}: ConfirmDialogProps) {
  const [disabled, setDisabled] = React.useState(false);
  const scripts = useScripts();
  const isWidthDown420 = useMediaQuery('(max-width:420px)');

  return open ? (
    <Dialog maxWidth="xs" fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle sx={titleStyle}>
        <Box
          sx={{
            backgroundColor: '#D9D9D9',
            borderRadius: '15px',
            padding: 0.5,
            width: '25%',
            textAlign: 'center',
          }}
        >
          {title}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: isWidthDown420 ? '20px 30px' : '20px 75px' }}>
        <Stack spacing={2}>
          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                width: '295px',
                height: '270px',
                backgroundColor: '#F4F4F4',
                position: 'relative',
                zIndex: 1,
                marginTop: '10px',
                borderRadius: '15px',
                boxShadow: '3px 3px 0px rgba(0, 0, 0, 0.15)',
              }}
            ></Box>
            <Box
              sx={{
                position: 'absolute',
                top: '5px',
                right: '30px',
                zIndex: 2,
                width: '259px',
                height: '233px',
              }}
            >
              <FinalScoreIcon score={score} />
            </Box>
          </Stack>

          <Grid
            container
            sx={{
              height: '165px',
            }}
          >
            <Grid
              sx={{
                flexGrow: 1,
                backgroundColor: '#F4F4F4',
                marginRight: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                borderRadius: '15px',
                boxShadow: '3px 3px 0px rgba(0, 0, 0, 0.15)',
                alignItems: 'center',
              }}
            >
              <CheckCircleIcon color="#00A591" />

              <Box
                sx={{
                  backgroundColor: '#FFF',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '15px',
                  height: '89px',
                  width: '107px',
                  alignItems: 'center',
                  fontSize: '40px',
                  fontWeight: 700,
                  fontFamily: 'Poppins',
                  marginTop: 1.5,
                }}
              >
                {correct}
              </Box>
            </Grid>
            <Grid sx={{ flexGrow: 0.1 }}></Grid>
            <Grid
              sx={{
                flexGrow: 1,
                backgroundColor: '#F4F4F4',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                borderRadius: '15px',
                boxShadow: '3px 3px 0px rgba(0, 0, 0, 0.15)',
                alignItems: 'center',
              }}
            >
              <CancelIcon color="#FF6663" />
              <Box
                sx={{
                  backgroundColor: '#FFF',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '15px',
                  height: '89px',
                  width: '107px',
                  alignItems: 'center',
                  fontSize: '40px',
                  fontWeight: 700,
                  fontFamily: 'Poppins',
                  marginTop: 1.5,
                }}
              >
                {wrong}
              </Box>
            </Grid>
          </Grid>

          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            {/* <CustomButton
              variant="outlined"
              sx={{ width: 92 }}
              disabled={disabled}
              onClick={onClose}
            >
              {scripts.cancel}
            </CustomButton> */}
            <CustomButton
              variant="outlined"
              sx={{ width: 92, borderRadius: '15px' }}
              disabled={disabled}
              onClick={() => {
                setDisabled(true);
                onSubmit();
                setDisabled(false);
              }}
            >
              {scripts.ok}
            </CustomButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  ) : null;
}
