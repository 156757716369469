import { createTheme } from '@mui/material/styles';

const adminLightTheme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#00A591',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#00A591',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#00A591',
            },
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0)',
            },
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          fontWeight: 600,
          fontFamily: 'Poppins',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#00A591',
          '&.Mui-checked': {
            color: '#00A591',
          },
        },
      },
    },
  },
});
export default adminLightTheme;
