import IconType from './IconType';

const DownloadFileIcon = ({
  width = 22,
  height = 17,
  color = '#073015',
  style,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M4.95 15.9375C2.21719 15.9375 0 13.7959 0 11.1562C0 9.07109 1.38187 7.29805 3.30687 6.64395C3.30344 6.5543 3.3 6.46465 3.3 6.375C3.3 3.43984 5.76125 1.0625 8.8 1.0625C10.8384 1.0625 12.6156 2.13164 13.5678 3.72539C14.0903 3.38672 14.7228 3.1875 15.4 3.1875C17.2219 3.1875 18.7 4.61523 18.7 6.375C18.7 6.78008 18.6209 7.16523 18.48 7.52383C20.4875 7.91563 22 9.63223 22 11.6875C22 14.035 20.0303 15.9375 17.6 15.9375H4.95ZM7.66563 10.3926L10.4156 13.0488C10.7388 13.3609 11.2613 13.3609 11.5809 13.0488L14.3309 10.3926C14.6541 10.0805 14.6541 9.57578 14.3309 9.26699C14.0078 8.9582 13.4853 8.95488 13.1656 9.26699L11.825 10.5619V6.10938C11.825 5.66777 11.4572 5.3125 11 5.3125C10.5428 5.3125 10.175 5.66777 10.175 6.10938V10.5619L8.83438 9.26699C8.51125 8.95488 7.98875 8.95488 7.66906 9.26699C7.34938 9.5791 7.34594 10.0838 7.66906 10.3926H7.66563Z"
        fill={color}
      />
    </svg>
  );
};

export default DownloadFileIcon;
