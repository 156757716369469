import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';

type SubMenuProps = {
  height?: number;
  popperRef: React.RefObject<HTMLDivElement>;
  children: React.ReactElement;
};

export type SubMenuFunc = {
  open: (element: HTMLElement, height: number) => void;
  close: () => void;
};

const SubMenu = forwardRef<SubMenuFunc, SubMenuProps>(
  ({ height, popperRef, children }, ref) => {
    const [toolsMenuAnchorEl, setToolsMenuAnchorEl] = useState<HTMLElement | null>(null);
    const [toolsMenuOpen, setToolsMenuOpen] = useState(false);
    const [parentHeight, setParentHeight] = useState(0);
    const subPopperRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      open: (element: HTMLElement, height: number) => {
        setToolsMenuAnchorEl(element);
        setParentHeight(height);
        setToolsMenuOpen(true);
      },
      close: () => {
        setToolsMenuAnchorEl(null);
        setToolsMenuOpen(false);
      },
    }));

    return (
      <Popper
        ref={subPopperRef}
        open={toolsMenuOpen}
        anchorEl={toolsMenuAnchorEl}
        placement="right"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                position: 'absolute',
                top: (() => {
                  const defaultTop = `-${parentHeight / 2}px`;

                  if (typeof height === 'undefined' || (height ?? 0) < 1) {
                    return defaultTop;
                  }

                  const subPopper = subPopperRef.current;
                  if (subPopper) {
                    const cssCollection = window.getComputedStyle(subPopper, null);
                    const matrix = new WebKitCSSMatrix(cssCollection.transform);
                    const parentPopper = popperRef.current;
                    if (parentPopper) {
                      const cssCollection = window.getComputedStyle(parentPopper, null);
                      const parentPopperMatrix = new WebKitCSSMatrix(
                        cssCollection.transform,
                      );

                      const f = matrix.f;
                      const parentPopperF = Math.abs(parentPopperMatrix.f);
                      const parentPopperTop = Number.parseFloat(
                        cssCollection.getPropertyValue('top'),
                      );

                      if (
                        (f === 0 &&
                          parentPopperF +
                            (Number.isFinite(parentPopperTop) ? parentPopperTop : 0) +
                            (parentPopper.offsetHeight - parentHeight - 8) + //8 is parentPopper paddingBottom.
                            height >=
                            window.innerHeight) ||
                        f + height >= window.innerHeight
                      ) {
                        return `-${height - parentHeight / 2}px`;
                      }
                    }
                  }

                  return defaultTop;
                })(),
                right: 0,
              }}
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <Box sx={{ position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: 0 }}>{children}</Box>
              </Box>
            </Box>
          </Fade>
        )}
      </Popper>
    );
  },
);
SubMenu.displayName = 'SubMenu';

export default SubMenu;
