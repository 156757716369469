import { ElementType, useState, useEffect, useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { CircularProgress, SxProps, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { combineFetchMoreResult } from '../globalComponents/CombineFetchMoreResult';
import CustomInputText from '../globalComponents/CustomInputText';
import CustomTable, { CustomTableForwardRefProps } from '../globalComponents/CustomTable';
import { PageInfo } from '../graphql/resolver.types';
import { useScripts } from '../layout/utils/LanguageHelper';
import NoResultFoundSvg from '../layout/utils/NoResultFoundSvg';
import SearchIcon from '../svg/SearchIcon';

type UseDataGridProps<T> = {
  increaseNum?: number;
  fetchDatas: (options: { variables: any }) => Promise<any>;
  fetchMore?: any;
  data: any;
  error: ApolloError | undefined;
  loading: boolean;
  extraQueryParameters?: { [key: string]: any };
  fieldName: string;
  keyFieldName?: string;
  noSerial?: boolean;
  canSearch?: boolean;
  title?: JSX.Element | null;
  noHeadComponent?: boolean;
  extraComponent?: JSX.Element | null;
  type:
    | (
        | { fieldName: keyof T; fieldNameLabel: string }
        | { cellName: string; label?: string }
      )[]
    | ((
        count: number,
      ) => (
        | { fieldName: keyof T; fieldNameLabel: string }
        | { cellName: string; label?: string }
      )[]);
  customCell?: {
    [key: string]: (node: T, showDetailInfoButton: JSX.Element | null) => any;
  };
  detailInfo?: (node: T, index: number) => any;
  getDetailInfoExpandButton?: (
    action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  ) => JSX.Element;
  getDetailInfoCloseButton?: (
    action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  ) => JSX.Element;
  sx?: SxProps<Theme>;
  size?: 'medium' | 'small';
  component?: ElementType<any>;
  pauseQuery?: boolean;
  tableRowOnMouseDown?: (node: T) => void;
  hasLastColumnButtonGroup?: boolean;
};

type SearchTextFieldProps = {
  defaultValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  extraComponent?: JSX.Element | null;
  borderColor?: string;
};

const SearchTextField = ({
  defaultValue,
  onChange,
  extraComponent,
  borderColor = '#073015',
}: SearchTextFieldProps): JSX.Element => {
  const scripts = useScripts();

  const isScreenDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      ml={{ xs: 0, md: 'auto' }}
      width={{
        xs: '100%',
        md: 'auto', //TODO: add arg for edit resource
      }}
      sx={{ backgroundColor: 'transparent' }}
    >
      <CustomInputText
        fullWidth
        size="small"
        placeholder={scripts.search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color={borderColor} />
            </InputAdornment>
          ),
        }}
        defaultValue={defaultValue}
        onChange={onChange}
        autoFocus={false}
        sx={{
          //阻止自動填寫表單樣式
          '& input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #fff inset !important',
          },
          backgroundColor: '#F8F8F8',
          borderRadius: '7px',
        }}
        borderline="false"
      />
      {!isScreenDownMd && extraComponent}
    </Stack>
  );
};

function useDataGrid<T>({
  increaseNum = 10,
  fetchDatas,
  fetchMore,
  data,
  error,
  loading,
  extraQueryParameters,
  fieldName,
  keyFieldName = '',
  noSerial = false,
  canSearch = false,
  title = null,
  noHeadComponent = false,
  extraComponent = null,
  type,
  customCell = {},
  detailInfo,
  getDetailInfoExpandButton,
  getDetailInfoCloseButton,
  sx = { width: '100%', maxHeight: '470px' },
  size = 'small',
  component = Box,
  pauseQuery = false,
  tableRowOnMouseDown,
  hasLastColumnButtonGroup = false,
}: UseDataGridProps<T>) {
  const scripts = useScripts();

  const [dataGrid, setDataGrid] = useState(<></>);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const loadMoreButtonRef = useRef<HTMLDivElement>(null);
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [searchPreText, setSearchPreText] = useState('');
  const searchTimer = useRef<NodeJS.Timeout>();

  const customTableRef = useRef<CustomTableForwardRefProps>(null);

  const edges: any[] = data && fieldName in data ? data[fieldName].edges : [];
  const pageInfo: PageInfo = edges.length > 0 ? data[fieldName].pageInfo : null;
  const datasCurrentNum = edges.length;
  const datasItemTotal = pageInfo?.itemTotal ?? 0;
  const loadMoreNum = datasItemTotal - datasCurrentNum;
  const showLoadMoreButton = loadMoreNum > 0;

  const getQueryVariables = (afterAndSearch?: { after?: string; search?: string }) => {
    const variables = Object.assign(
      {
        first: increaseNum,
      },
      afterAndSearch?.after ? { after: afterAndSearch.after } : null,
      afterAndSearch?.search ? { search: afterAndSearch.search } : null,
      extraQueryParameters ? extraQueryParameters : null,
    );

    return variables;
  };

  useEffect(() => {
    if (!pauseQuery) {
      const variables = getQueryVariables();
      fetchDatas({ variables });
    }
  }, [pauseQuery, extraQueryParameters]);

  useEffect(() => {
    if (data && !loading) {
      setFetchMoreLoading(false);
      tableRef.current?.scroll(0, prevScrollTop);
    }
  }, [data, loading]);

  useEffect(() => {
    if (searchText !== '') {
      const variables = getQueryVariables({ search: searchText });
      fetchDatas({ variables });
    }

    if (searchText === '' && searchPreText !== '') {
      const variables = getQueryVariables();
      fetchDatas({ variables });
    }

    setSearchPreText(searchText);
  }, [searchText]);

  const titleHeight = titleRef.current?.clientHeight ?? 0;
  const tableHeight = tableRef.current?.clientHeight ?? 0;
  const loadMoreButtonHeight = loadMoreButtonRef.current?.clientHeight ?? 0;
  const isScreenDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const handleSearchTextChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    //阻止自動填寫表單功能
    if (!(e.nativeEvent instanceof InputEvent)) {
      e.preventDefault();
      e.target.value = searchText;
      e.target.blur();

      return;
    }

    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }
    searchTimer.current = setTimeout(() => {
      setSearchText(e.target.value.trim());
    }, 1000);
  };
  useEffect(() => {
    if (error) {
      setDataGrid(
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          sx={{
            height: tableHeight > 0 ? `${tableHeight + loadMoreButtonHeight}px` : 'auto',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            pb={2}
            sx={{ width: '100%' }}
            ref={titleRef}
          >
            {title}
          </Stack>
          <Typography variant="h6" gutterBottom>
            {scripts.dataLoadFailed}
          </Typography>
        </Stack>,
      );
    } else if (!data && loading) {
      setDataGrid(
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={0}>
          {!noHeadComponent && (
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems="center"
              pb={2}
              sx={{ width: '100%' }}
              ref={titleRef}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ minHeight: '60px' }}
              >
                {title}
                {isScreenDownMd && extraComponent}
              </Stack>
              {(canSearch || extraComponent) && (
                <SearchTextField
                  defaultValue={searchText}
                  onChange={handleSearchTextChange}
                  extraComponent={extraComponent}
                />
              )}
            </Stack>
          )}
          <Typography variant="h6" gutterBottom>
            {scripts.pleaseWait}
          </Typography>
          <CircularProgress sx={{ marginBottom: 6 }} />
        </Stack>,
      );
    } else if (edges.length === 0) {
      setDataGrid(
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={0}>
          {!noHeadComponent && (
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems="center"
              pb={2}
              sx={{ width: '100%' }}
              ref={titleRef}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ minHeight: '60px' }}
              >
                {title}
                {isScreenDownMd && extraComponent}
              </Stack>
              {(canSearch || extraComponent) && (
                <SearchTextField
                  defaultValue={searchText}
                  onChange={handleSearchTextChange}
                  extraComponent={extraComponent}
                />
              )}
            </Stack>
          )}
          <NoResultFoundSvg />
        </Stack>,
      );
    } else {
      setDataGrid(
        <Stack sx={{ position: 'relative', backgroundColor: 'transparent' }}>
          {!noHeadComponent && (
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems="center"
              pb={2}
              sx={{ width: '100%', backgroundColor: 'transparent' }}
              ref={titleRef}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ minHeight: '60px', backgroundColor: 'transparent' }}
              >
                {title}
                {isScreenDownMd && extraComponent}
              </Stack>
              {(canSearch || extraComponent) && (
                <SearchTextField
                  defaultValue={searchText}
                  onChange={handleSearchTextChange}
                  extraComponent={extraComponent}
                />
              )}
            </Stack>
          )}
          <CustomTable
            stickyHeader
            ref={customTableRef}
            sx={sx}
            size={size}
            component={component}
            lastBorderNone={detailInfo ? true : false}
            tableHead={[
              '',
              ...(Array.isArray(type)
                ? type
                : type(data?.[fieldName]?.pageInfo?.itemTotal ?? 0)
              ).map((typeTemp) =>
                'fieldNameLabel' in typeTemp
                  ? typeTemp.fieldNameLabel
                  : typeTemp?.label ?? '',
              ),
            ].slice(noSerial || isScreenDownMd ? 1 : 0)}
            tableBody={edges.map(({ node }: any, index: number) => {
              const handleClickDetailInfoButton = () =>
                //e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                {
                  const customTable = customTableRef.current;
                  if (!customTable) {
                    return;
                  }

                  const expandBtnElement = document.getElementById(
                    `${node.id}-expand-button`,
                  );
                  const closeBtnElement = document.getElementById(
                    `${node.id}-close-button`,
                  );

                  if (!expandBtnElement || !closeBtnElement) {
                    return;
                  }

                  if (customTable.isShowDetailInfo(index)) {
                    customTable.close(index);
                    expandBtnElement.style.display = 'block';
                    closeBtnElement.style.display = 'none';
                  } else {
                    customTable.expand(index);
                    expandBtnElement.style.display = 'none';
                    closeBtnElement.style.display = 'block';
                  }
                };

              return Object.assign(
                {
                  key: node.id,
                  content: [
                    `${index + 1}`,
                    ...(Array.isArray(type)
                      ? type
                      : type(data?.[fieldName]?.pageInfo?.itemTotal ?? 0)
                    ).map((typeTemp) => {
                      if ('fieldName' in typeTemp) {
                        return (
                          <Typography
                            key={index + 1}
                            sx={{
                              maxWidth:
                                // leads page: name gets squished on smaller screens
                                // isScreenDownMd ? '200px' :
                                '100px',
                            }}
                          >
                            {node[typeTemp.fieldName]}
                          </Typography>
                        );
                      } else if (customCell && customCell[typeTemp.cellName]) {
                        return customCell[typeTemp.cellName](
                          node,
                          <>
                            {getDetailInfoExpandButton && getDetailInfoCloseButton ? (
                              <>
                                <Box id={`${node.id}-expand-button`}>
                                  {getDetailInfoExpandButton(handleClickDetailInfoButton)}
                                </Box>
                                <Box
                                  id={`${node.id}-close-button`}
                                  sx={{ display: 'none' }}
                                >
                                  {getDetailInfoCloseButton(handleClickDetailInfoButton)}
                                </Box>
                              </>
                            ) : null}
                          </>,
                        );
                      }
                    }),
                    ...(detailInfo ? [detailInfo(node, index)] : []),
                  ].slice(noSerial || isScreenDownMd ? 1 : 0),
                  hasDetailInfo: !!detailInfo,
                },
                tableRowOnMouseDown
                  ? { onMouseDown: () => tableRowOnMouseDown(node) }
                  : null,
              );
            })}
            tableRef={tableRef}
            hasLastColumnButtonGroup={hasLastColumnButtonGroup}
          />
          {showLoadMoreButton && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              pt={2}
              ref={loadMoreButtonRef}
              sx={{ backgroundColor: 'transparent' }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  const endCursor = pageInfo?.endCursor ?? '';
                  if (!endCursor || !fetchMore) {
                    return;
                  }

                  const variables = getQueryVariables({ after: endCursor });
                  fetchMore({
                    variables,
                    updateQuery: (prevResult: any, options: any) => {
                      if (keyFieldName) {
                        return combineFetchMoreResult(
                          fieldName,
                          prevResult,
                          options,
                          keyFieldName,
                        );
                      }
                      return combineFetchMoreResult(fieldName, prevResult, options);
                    },
                  });
                  setFetchMoreLoading(true);
                  setPrevScrollTop(tableRef.current?.scrollTop ?? 0);
                }}
                sx={{
                  textTransform: 'none',
                  color: '#00A591',
                  border: '1px solid',
                  borderColor: '#00A591',
                  borderRadius: '10px',
                  '&:hover': {
                    borderColor: '#00A591',
                    background: '#00A591',
                    color: '#fff',
                  },
                }}
              >
                {scripts.loadMore} ({loadMoreNum})
              </Button>
            </Stack>
          )}
          {fetchMoreLoading && (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height:
                  tableHeight > 0
                    ? `${titleHeight + tableHeight + loadMoreButtonHeight + 3}px`
                    : 'auto',
                backgroundColor: '#fff',
                opacity: 0.5,
                zIndex: 1000,
              }}
            >
              <Typography variant="h6" gutterBottom>
                {scripts.pleaseWait}
              </Typography>
              <CircularProgress sx={{ marginBottom: 6 }} />
            </Stack>
          )}
        </Stack>,
      );
    }
  }, [data, error, loading, fetchMoreLoading, isScreenDownMd]);

  return { dataGrid };
}

export default useDataGrid;
