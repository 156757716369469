import { useEffect, useRef } from 'react';

import { Check } from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';

import ChineseMenuItem from './ChineseMenuItem';
import { Language, useLanguage } from './LanguageHelper';
import usePopoverAnchorEl from '../../globalHooks/usePopoverAnchorEl';
import { MenuItemProps } from '../components/MainMenu';

const ChineseRightNavigationAction = ({ isMobile }: MenuItemProps) => {
  const { anchorEl, open, handleClick, handleClose } = usePopoverAnchorEl();

  const boxRef = useRef<HTMLDivElement | null>(null);

  const { language, changeLanguage } = useLanguage();
  const createLanguageHandler = (code: Language) => () => changeLanguage(code);

  useEffect(() => {
    const button = boxRef.current?.parentElement?.parentElement as HTMLButtonElement;
    if (!button) {
      return;
    }

    const customClick = handleClick as unknown as (event: MouseEvent) => void;

    button.addEventListener('click', customClick);

    return () => {
      button.removeEventListener('click', customClick);
    };
  }, []);

  return (
    <Stack
      ref={boxRef}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={0}
    >
      <ChineseMenuItem isMobile={isMobile} isMore={true} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        sx={{ zIndex: 10000 }}
      >
        <MenuList dense>
          {language === 'US' && (
            <MuiMenuItem onClick={createLanguageHandler('US')}>
              <ListItemIcon>
                <Check />
              </ListItemIcon>
              English
            </MuiMenuItem>
          )}
          {language !== 'US' && (
            <MuiMenuItem onClick={createLanguageHandler('US')}>
              <ListItemText inset>English</ListItemText>
            </MuiMenuItem>
          )}
          {language === 'TW' && (
            <MuiMenuItem onClick={createLanguageHandler('TW')}>
              <ListItemIcon>
                <Check />
              </ListItemIcon>
              繁體中文
            </MuiMenuItem>
          )}
          {language !== 'TW' && (
            <MuiMenuItem onClick={createLanguageHandler('TW')}>
              <ListItemText inset>繁體中文</ListItemText>
            </MuiMenuItem>
          )}
        </MenuList>
      </Menu>
    </Stack>
  );
};

export default ChineseRightNavigationAction;
