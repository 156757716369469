import { useCallback, useEffect, useRef, useState } from 'react';

import {
  ApolloError,
  DocumentNode,
  TypedDocumentNode,
  useLazyQuery,
  useReactiveVar,
} from '@apollo/client';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import useInfiniteScrollPageVariables from './useInfiniteScrollPageVariables';
import { pageInfoVar } from '../apollo/apollo.client';
import { combineFetchMoreResult } from '../globalComponents/CombineFetchMoreResult';
import { default as getScrollInfo } from '../globalComponents/ScrollInfo';
import { PageInfo } from '../graphql/resolver.types';
import { useScripts } from '../layout/utils/LanguageHelper';

type UseInfiniteScrollPageProps<T, U extends { [key: string]: any }, Y> = {
  increaseNum?: number;
  search?: string;
  document: DocumentNode | TypedDocumentNode<U>;
  extraQueryParameters?: (Y extends { [key: string]: any } ? Y : null) | null;
  fieldName: string;
  keyFieldName?: string;
  groupId?: string;
  content?: (
    node: T,
    index: number,
    updateQuery: (mapFn: (previousQueryResult: U) => U) => void,
  ) => JSX.Element;
  disableScrollLoadMore?: boolean;
  pauseQuery?: boolean | null;
};

function useInfiniteScrollPage<T, U extends { [key: string]: any }, Y = any>({
  increaseNum = 10,
  search = '',
  document,
  extraQueryParameters = null,
  fieldName,
  keyFieldName = '',
  groupId = '',
  content,
  disableScrollLoadMore = false,
  pauseQuery = false,
}: UseInfiniteScrollPageProps<T, U, Y>): {
  data: U | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  refetch: (params: any) => Promise<any>;
  pageInfo: PageInfo | null;
  fetchMoreLoading: boolean;
  updateQuery: (mapFn: (previousQueryResult: U) => U) => void;
  Content: JSX.Element;
  CircleLoading: JSX.Element | null;
  BottomElement: JSX.Element | null;
  increaseNum: number;
  search: string;
  extraQueryParameters: Y | null;
  firstFetchDatas: (() => void) | null;
  fetchMorePrev: () => void;
  fetchMoreNext: () => void;
} {
  const [pauseQueryOnce, setPauseQueryOnce] = useState<boolean | null>(pauseQuery);
  useEffect(() => {
    if (pauseQueryOnce === null && typeof pauseQuery === 'boolean') {
      setPauseQueryOnce(pauseQuery);
    }
  }, [pauseQuery]);

  const lastPageInfo = useReactiveVar(pageInfoVar);

  const scripts = useScripts();

  const fetchMoreLoadingRef = useRef(false);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [fetchDatas, { data, error, loading, fetchMore, refetch, updateQuery }] =
    useLazyQuery(document, {
      onCompleted() {
        fetchMoreLoadingRef.current = false;
        setFetchMoreLoading(false);
      },
      onError() {
        fetchMoreLoadingRef.current = false;
        setFetchMoreLoading(false);
      },
    });
  const { pageInfoRef, combine } = useInfiniteScrollPageVariables({
    increaseNum,
    search,
    extraQueryParameters,
    fieldName,
    keyFieldName,
    groupId,
    fetchMore,
    combineFetchMoreResult,
  });
  const [pageInfo, setPageInfo] = useState(pageInfoRef.current);

  const noDataRef = useRef<HTMLDivElement>(null);
  const endCursorRef = useRef<string>('');
  const nowFetchMoreEndCursorRef = useRef('');
  const circularProgressRef = useRef<HTMLDivElement>(null);
  const counterRef = useRef(0);

  const getQueryVariables = useCallback(
    (function () {
      const combineCopy = combine;

      return function (isBefore = false) {
        const variables = Object.assign(
          {
            first: combineCopy.increaseNumRef.current,
          },
          isBefore && combineCopy.pageInfoRef.current?.hasPreviousPage
            ? { before: combineCopy.pageInfoRef.current.startCursor }
            : null,
          !isBefore && combineCopy.pageInfoRef.current?.hasNextPage
            ? { after: combineCopy.pageInfoRef.current.endCursor }
            : null,
          combineCopy.extraQueryParametersRef.current
            ? combineCopy.extraQueryParametersRef.current
            : null,
        );

        return variables;
      };
    })(),
    [],
  );

  const customFetchMore = useCallback(
    (function () {
      const combineCopy = combine;
      const circularProgressRefCopy = circularProgressRef;
      const fetchMoreLoadingRefCopy = fetchMoreLoadingRef;
      const setFetchMoreLoadingCopy = setFetchMoreLoading;
      const setPageInfoCopy = setPageInfo;
      const pageInfoVarCopy = pageInfoVar;

      return function (isBefore = false) {
        fetchMoreLoadingRefCopy.current = true;
        setFetchMoreLoadingCopy(true);

        combineCopy.fetchMoreRef.current({
          variables: getQueryVariables(isBefore),
          updateQuery: (prevResult: any, options: any) => {
            const fieldName = combineCopy.fieldNameRef.current;
            const keyFieldName = combineCopy.keyFieldNameRef.current;
            const groupId = combineCopy.groupIdRef.current;
            const pageInfoRef = combineCopy.pageInfoRef;
            const combineFetchMoreResult = combineCopy.combineFetchMoreResultRef.current;

            fetchMoreLoadingRefCopy.current = false;
            setFetchMoreLoadingCopy(false);
            if (circularProgressRefCopy.current) {
              circularProgressRefCopy.current.style.display = 'none';
            }

            const newPageInfo = options.fetchMoreResult[fieldName].pageInfo;
            pageInfoRef.current = newPageInfo;
            setPageInfoCopy(newPageInfo);
            if (groupId) {
              pageInfoVarCopy({
                ...pageInfoVarCopy(),
                [groupId]: newPageInfo,
              });
            }

            if (keyFieldName) {
              return combineFetchMoreResult(fieldName, prevResult, options, keyFieldName);
            }
            return combineFetchMoreResult(fieldName, prevResult, options);
          },
        });
      };
    })(),
    [],
  );

  const getFetchMoreFunc = useCallback(function (type: 'prev' | 'next') {
    const fetchMoreLoadingRefCopy = fetchMoreLoadingRef;
    const customFetchMoreCopy = customFetchMore;
    const pageInfoRefCopy = pageInfoRef;
    const typeCopy = type;

    return function () {
      if (fetchMoreLoadingRefCopy.current) {
        return;
      }

      const isPrev = typeCopy === 'prev' ? true : false;
      if (
        isPrev &&
        (!pageInfoRefCopy.current?.hasPreviousPage ||
          !pageInfoRefCopy.current?.startCursor)
      ) {
        return;
      }

      if (
        !isPrev &&
        (!pageInfoRefCopy.current?.hasNextPage || !pageInfoRefCopy.current?.endCursor)
      ) {
        return;
      }

      customFetchMoreCopy(isPrev);
    };
  }, []);

  const fetchMorePrev = useCallback(getFetchMoreFunc('prev'), []);
  const fetchMoreNext = useCallback(getFetchMoreFunc('next'), []);

  const loadMore = useCallback(() => {
    if (fetchMoreLoadingRef.current || !pageInfoRef.current?.hasNextPage) {
      return;
    }

    const { scrollTop, windowHeight, scrollHeight } = getScrollInfo();
    const circularProgress = circularProgressRef.current ?? { style: { display: '' } };
    if (scrollTop + windowHeight >= scrollHeight) {
      const endCursor = endCursorRef.current;
      if (
        nowFetchMoreEndCursorRef.current === endCursor &&
        !(pageInfoRef.current?.hasNextPage && !endCursor)
      ) {
        return;
      }
      circularProgress.style.display = 'block';
      nowFetchMoreEndCursorRef.current = endCursor;

      customFetchMore();
    }
  }, []);

  useEffect(() => {
    if (!disableScrollLoadMore) {
      window.addEventListener('scroll', loadMore);
      return () => {
        window.removeEventListener('scroll', loadMore);
      };
    }
  }, []);

  const firstFetchDatas = () => {
    if (loading || fetchMoreLoadingRef.current || typeof pauseQueryOnce !== 'boolean') {
      return false;
    }

    fetchDatas({
      variables: Object.assign(
        {
          first: increaseNum,
        },
        search ? { search: search.trim() } : null,
        extraQueryParameters ? extraQueryParameters : null,
      ),
    });
    nowFetchMoreEndCursorRef.current = '';
    counterRef.current = 0;

    return true;
  };
  useEffect(() => {
    if (typeof pauseQueryOnce === 'boolean' && !pauseQueryOnce) {
      firstFetchDatas();
    }
  }, [increaseNum, search, extraQueryParameters, pauseQueryOnce]);

  useEffect(() => {
    const noDataChip = noDataRef.current;
    if (!data || !(fieldName in data)) {
      if (noDataChip) {
        noDataChip.style.display = 'none';
      }

      return;
    }

    let newPageInfo = pageInfoRef.current ?? data[fieldName].pageInfo;
    if (!pageInfoRef.current) {
      pageInfoRef.current = newPageInfo;
      setPageInfo(newPageInfo);

      if (groupId) {
        if (groupId in lastPageInfo && lastPageInfo[groupId]) {
          pageInfoRef.current = lastPageInfo[groupId];
          setPageInfo(lastPageInfo[groupId]);
          newPageInfo = lastPageInfo[groupId];
        } else {
          pageInfoVar({ ...lastPageInfo, [groupId]: newPageInfo });
        }
      }
    } else {
      newPageInfo = data[fieldName].pageInfo;
    }

    if (data[fieldName] && data[fieldName].edges) {
      endCursorRef.current = newPageInfo.endCursor;
      if (newPageInfo.hasNextPage && noDataChip) {
        noDataChip.style.display = 'none';
      } else if (noDataChip) {
        noDataChip.style.display = 'block';
      }
      if (circularProgressRef.current) {
        circularProgressRef.current.style.display = 'none';
      }
    }

    if (disableScrollLoadMore) {
      return;
    }

    //如果資料量太少不足以產生scrollbar則自動載入更多資料，最多自動載入10次。
    const { scrollTop, windowHeight, scrollHeight } = getScrollInfo();
    if (scrollTop + windowHeight >= scrollHeight) {
      if (counterRef.current <= 10 || counterRef.current === 0) {
        if (newPageInfo.hasNextPage) {
          loadMore();
          counterRef.current++;
        }
      }
    }
  }, [data && data[fieldName]?.edges, loading]);

  const CircleLoading = (
    <Stack
      sx={{ height: '65px' }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {fetchMoreLoading && <CircularProgress />}
      {/* <CircularProgress /> */}
    </Stack>
  );

  const BottomElement = (
    <>
      <Box
        ref={circularProgressRef}
        sx={{
          display: fetchMoreLoadingRef.current || fetchMoreLoading ? 'block' : 'none',
          position: 'fixed',
          left: 'calc(50% - 20px)',
          bottom: '60px',
        }}
      >
        <CircularProgress />
      </Box>
      <Chip
        ref={noDataRef}
        sx={{
          display:
            loading ||
            fetchMoreLoadingRef.current ||
            fetchMoreLoading ||
            pageInfo?.hasNextPage ||
            ((data?.[fieldName]?.edges ?? []) as any[]).length === 0
              ? 'none'
              : 'block',
          position: 'absolute',
          left: 'calc(50% - 65px)',
          bottom: '0px',
          paddingTop: '4px',
        }}
        label={scripts.hasReachedBottom}
        size="small"
      />
    </>
  );

  return {
    data,
    error,
    loading,
    refetch,
    pageInfo,
    fetchMoreLoading: fetchMoreLoadingRef.current || fetchMoreLoading,
    updateQuery,
    Content: (
      <>
        {data && fieldName in data && content
          ? (data[fieldName].edges as any[]).map(({ node }, index) =>
              content(node, index, updateQuery),
            )
          : null}
      </>
    ),
    CircleLoading: !disableScrollLoadMore ? CircleLoading : null,
    BottomElement: !disableScrollLoadMore ? BottomElement : null,
    increaseNum,
    search,
    extraQueryParameters,
    firstFetchDatas: pauseQueryOnce ? firstFetchDatas : null,
    fetchMorePrev,
    fetchMoreNext,
  };
}

export default useInfiniteScrollPage;
